import { Component, OnInit, Inject } from '@angular/core';
import { Product } from '../product';
import { ProductService } from '../productservice';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer";
import { CustomerService } from "../CustomerService";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { BilldeskService } from '../billdesk.service';
import { ActivatedRoute } from '@angular/router';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';

import { printDiv } from './print-div';
export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor: string;

}
@Component({
  selector: 'app-innvoicedetail',
  templateUrl: './innvoicedetail.component.html',
  styleUrls: ['./innvoicedetail.component.css']
})
export class InnvoicedetailComponent implements OnInit {
  buttonhide = true;
  productDialog: boolean;
  stagelist: any;
  products: Product[];
  InvoiceID: any = '';
  product: Product;
  id: any;
  StageID: any;
  StageName: any;
  createddata: any;
  TrackingID: any;
  status: any;
  paymentupdateDialog: boolean;
  statusupdateDialog: boolean;
  form: FormGroup;
  statusform: FormGroup;
  paymentform: FormGroup;

  notificationform: FormGroup;
  description: any;
  selectedProducts: any;
  yearsdetail: any;
  dialogdata: any;
  trackingdata: any;
  submitted: boolean;

  imageUrl: string = "/assets/images/noimage.png";
  fileToUpload: File = null;
  //   InvoiceID:any;
  statuses: any[];
  cities: City[];
  customers: Customer[];
  successDialog: boolean;
  representatives: Representative[];
  updateDialog: boolean;
  loading: boolean = true;
  inventoryStatus: Representative[];
  selectedCities: City[];
  selectedStageId: any;
  UserTypeID: any;
  UserID: any;
  selectedStageName = "Please Select Stage";
  buttonaccess: any;
  RespTransactionID: any;
  stagename: any;
  visibleloader: any = false;

  ServiceUrl = environment.baseUrl;

  isBilldesk:any;
  buttonhidenew: any;


  notificationerror=false;
  constructor(private fb: FormBuilder, private http: HttpClient, private route: Router, private productService: ProductService, private messageService: MessageService, private _Activatedroute: ActivatedRoute, private confirmationService: ConfirmationService, private billdeskService: BilldeskService, public dialog: MatDialog, private location: Location) {


    this.buttonhidenew = false;
    // this.cities = [
    //     {name: 'New York', code: 'NY'},
    //     {name: 'Rome', code: 'RM'},
    //     {name: 'London', code: 'LDN'},
    //     {name: 'Istanbul', code: 'IST'},
    //     {name: 'Paris', code: 'PRS'}
    // ];

  }

  ngOnInit() {
this.isBilldesk=false;

    this.createddata = true;

    this.UserTypeID = localStorage.getItem('UserTypeID');
    console.log('Header UserTypeID');
    console.log(this.UserTypeID);
    console.log('Header UserTypeID');
    if(this.UserTypeID==5){
      this.isBilldesk=true;
     }



    
    if (this.UserTypeID == '1' || this.UserTypeID == '2' || this.UserTypeID == '4') {

      this.buttonaccess = true;
      // alert(this.UserTypeID);
    }



    this.paymentform = this.fb.group({

      RespTransactionID: [null, Validators.required],
      // RespCode: [null, Validators.required],
      RespMessage: [null, Validators.required],
      RespTransactionDate: [null, Validators.required],
      RespTransactionMode: [null, Validators.required],
      Remark: [null, Validators.required],
      RespTransactionAmount: [null, Validators.required],

      // additionaldiscount: [null, Validators.required], 
      ReceiptImageUrl: [],
      
    });
this.notificationform=this.fb.group({
  Message:[null,Validators.required]
 });

   
 
 
 this.statusform = this.fb.group({

      StageID: [null, Validators.required],
      Message: [null, Validators.required]

 
    });

    


    this.statuses = [

      { label: "Pending", value: "Pending" },
      { label: "Success", value: "Success" }

    ];


    this.id = this._Activatedroute.snapshot.paramMap.get("id");



    localStorage.setItem('invoice_id', this.id);

    console.log('invoice id');

    console.log(this.billdeskService.getInvoiceByid(this.id).then((res: any) => {

      this.products = res;
      console.log("res");
      console.log(res);
      console.log(this.UserTypeID);

      console.log(res[0].StageID);

      //  if(this.UserTypeID==1){
      //   if(res[0].StageID==1 ||res[0].StageID==8  ){
      //       this.buttonhidenew=true;
      //    }
      //   }   

      //   if(this.UserTypeID==2){
      //     if(res[0].StageID==2 ){
      //       this.buttonhidenew=true;
      //     }
      //  } 

      //   if(this.UserTypeID==5){
      //     if(res[0].StageID==3){
      //           this.buttonhidenew=true;
      //    }
      //  }




      if (this.UserTypeID == 1 && (res[0].StageID == 1 || res[0].StageID == 8)) {
        this.buttonhidenew = true;
      }


      if (this.UserTypeID == 2 && res[0].StageID == 2) {
        this.buttonhidenew = true;
      }


      if (this.UserTypeID == 5) {
        this.buttonhidenew = true;
        if (res[0].StageID == 6) {
          this.buttonhidenew = false;
        }
        if (res[0].StageID == 7) {
          this.buttonhidenew = false;
        }
      }



      this.stagename = res[0].StageName;

      console.log(this.stagename);

      console.log(this.stagename);
      // this.StageID=res[0].StageID;

      console.log('this.stagename');
      console.log(this.stagename);


      if (this.stagename == 'PO Batch List Created') {
        this.createddata = false;
        console.log('created');
      }

      this.billdeskService.getStageListByRoleType(res[0].StageID).then(res => this.stagelist = res);
    }





    ));








    // for hiddenbutton
    this.id = this._Activatedroute.snapshot.paramMap.get("id");

    this.http.get(this.ServiceUrl + '/invoicetracking/invoicetrackinglist?InvoiceID=' + this.id).subscribe((profileres: any) => {
      console.log('profileres');
      console.log('stage');
      console.log(profileres.invoicetrackinglistResult);
      //       this.StageID=profileres.invoicetrackinglistResult.dataobject[1].StageID;
      //       this.StageName=profileres.invoicetrackinglistResult.dataobject[1].StageName;
      //       this.TrackingID=profileres.invoicetrackinglistResult.dataobject[1].TrackingID;
      // console.log(this.StageID);
      // if(this.UserTypeID==1){
      // if(this.StageID==2){
      // console.log('Hod');
      // if(this.TrackingID==0){
      //   console.log('Buttonshow');
      // }else{
      //   console.log('Buttonhide');
      //   this.buttonhide=false;
      // }
      // }
      // }
      // if(this.UserTypeID==2){
      // if(this.StageID==3){
      // console.log('Approver');
      // if(this.TrackingID==0){
      //   console.log('Buttonshow');
      // }else{
      //   console.log('Buttondhide');
      //   this.buttonhide=false;
      // }
      // }
      // }
      // if(this.UserTypeID==4){
      //     console.log('Buttonhide');
      //     this.buttonhide=false;
      //    }


      // console.log(this.StageName);

      // console.log(this.TrackingID);

    });
    // for hidden button end



  }


  back(): void {
    this.location.back()
  }

  openNew() {
    this.product = {};
    this.submitted = false;
    this.productDialog = true;
  }
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 


 


  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  deleteSelectedProducts() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected products?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.products = this.products.filter(val => !this.selectedProducts.includes(val));
        this.selectedProducts = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
      }
    });
  }

  editProduct(product: Product) {
    this.product = { ...product };
    this.productDialog = true;
  }

  viewProduct(InvoiceId: any) {
    // this.product = {...product};
    // this.productDialog = true;
    console.log(InvoiceId);


    console.log(this.billdeskService.getTrackingStatus(InvoiceId).then(res => this.trackingdata = res));

    this.productDialog = true;


  }

 

  statusUpdateDialog() {
      this.statusupdateDialog = true;
  }

  paymentUpdateDialog() {
    this.paymentupdateDialog = true;
  }
  successButton() {
     this.successDialog = true;
  }



  statusupdateButton() {
     this.updateDialog = true;
  }




  deleteProduct(product: Product) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + product.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.successButton();
      }
    });
  }

  hideDialog() {
    this.productDialog = false;
    this.statusupdateDialog = false;
    this.paymentupdateDialog = false;
    this.submitted = false;
   }



  saveProduct() {
  const formData = new FormData();
    console.log(formData);
    console.log(formData.append('name', this.status));
    console.log(formData.append('description', this.description));
    console.log('test');
    console.log(this.product);
    console.log('test');
    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id) {
        this.products[this.findIndexById(this.product.id)] = this.product;
        //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
        this.statusupdateButton();
      }
      else {
        this.product.id = this.createId();
        this.product.image = 'product-placeholder.svg';
        this.products.push(this.product);
        //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
      }

      this.productDialog = false;
      this.product = {};
    }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }


  StatusUpdatePopup() {


    localStorage.removeItem('statusupdate');
    console.log('remove');// this code for open dialog after 5 second
    setTimeout(() => {
      // this.insertDialog = true;
      this.openDialogStatusUpdate();
      this.hideDialog();

    }, 500);
  }


  Notification_UpdatePopup() {


    localStorage.removeItem('notification_update');
    console.log('remove');// this code for open dialog after 5 second
    setTimeout(() => {
      // this.insertDialog = true;
      this.openDialogNotificationUpdate();
      this.hideDialog();

    }, 500);
  }



  openDialogStatusUpdate(): void {



    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Invoice", Detail: "Status Update Successfully", Backgroundcolor: "#0288d1" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }



  openDialogNotificationUpdate(): void {



    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Notification", Detail: "Sent Successfully", Backgroundcolor: "darksalmon" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }
  PaymentUpdatePopup() {
    localStorage.removeItem('paymentstatusupdate');
    console.log('remove');// this code for open dialog after 5 second
    setTimeout(() => {
      // this.insertDialog = true;
      this.openDialogPaymentUpdate();
      this.hideDialog();

    }, 500);
  }

  openDialogPaymentUpdate(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Payment", Detail: "Status Update Successfully", Backgroundcolor: "#47c9a2" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }


  changeStage(e) {
    console.log(e);
    this.selectedStageName = e.value.StageName;
    console.log(this.selectedStageName);
    this.selectedStageId = e.value.StageID;
  }


  getYear() {

    this.yearsdetail = ["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"];

  }



  paymentupdate() {
    // const PaymentformData = new FormData();
    const formData = new FormData();
    console.log('PaymentformData');

    console.log(this.paymentform.get('RespTransactionID').value);
    // console.log(this.paymentform.get('RespCode').value);
    console.log(this.paymentform.get('RespMessage').value);
    console.log(this.paymentform.get('RespTransactionDate').value);
    console.log(this.paymentform.get('RespTransactionMode').value);
    console.log(this.paymentform.get('Remark').value);
    console.log(this.paymentform.get('RespTransactionAmount').value);
    console.log(this.paymentform.get('ReceiptImageUrl').value);





    formData.append('RespTransactionID', this.paymentform.get('RespTransactionID').value);
    // formData.append('RespTransactionID','75'); 
    console.log('1');
    // formData.append('RespCode',this.paymentform.get('RespCode').value); 
    formData.append('RespCode', '200');


    // formData.append('RespCode','1'); 
    console.log('2');
    formData.append('RespMessage', this.paymentform.get('RespMessage').value);
    // formData.append('RespMessage','hello'); 
    console.log('3');




    formData.append('RespTransactionDate', this.paymentform.get('RespTransactionDate').value);
    // formData.append('RespTransactionDate','01/01/2021'); 

    console.log('4');
    formData.append('RespTransactionMode', this.paymentform.get('RespTransactionMode').value);
    // formData.append('RespTransactionMode','rtgs'); 
    console.log('5');
    formData.append('Remark', this.paymentform.get('Remark').value);
    // formData.append('Remark','hellotest'); 
    console.log('6');
    formData.append('RespTransactionAmount', this.paymentform.get('RespTransactionAmount').value);

    // formData.append('RespTransactionAmount', '555');
    console.log('7');
    formData.append('ReceiptImageUrl', this.paymentform.get('ReceiptImageUrl').value);
    console.log(this.paymentform.get('ReceiptImageUrl').value);
    // formData.append('ReceiptImageUrl', '0');
    // formData.append('ReceiptImageUrl', '1');
    console.log('8');
    const invoice_id = localStorage.getItem('invoice_id');
    console.log(invoice_id);
    formData.append('PaymentTransactionID', '0');
    formData.append('UserRoleTypeID', this.UserTypeID);
    formData.append('POOrderID', '2');
    formData.append('OrderDetailID', '1');
    formData.append('OrderID', '2');
    formData.append('InvoiceID', invoice_id);
    formData.append('RespTransactionType', '2');
    //formData.append('UserID', this.UserID);
    formData.append('flag', '0');


    console.log(formData);


    console.log('9');

    //https://billdeskapi.aisect.org/api/billdesk/paymenttransaction/paymenttransactionregistration

    //this.ServiceUrl+'/PaymentTransaction?UserID='+this.UserID+'&UserTypeID='+this.UserTypeID
    this.http.post(this.ServiceUrl + '/paymenttransaction/paymenttransactionregistration', formData)
      .subscribe(res => {
        console.log(res);


        // alert('Uploaded Successfully.');
        this.PaymentUpdatePopup();
        // time for refresh
        setInterval(() => {
          window.location.reload();
        }, 3000);



        localStorage.setItem('paymentstatusupdate', 'Success');
      })




  }


  onFileChange(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      console.log(file);
      this.paymentform.patchValue({
        ReceiptImageUrl: file
      });

    }
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);

    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }


  statusupdate(InvoiceID: any, VendorInvoiceNo: any) {
    console.log(InvoiceID);
    console.log("Vendor");
    console.log(VendorInvoiceNo);

    console.log(this.statusform.value);


    var obj2 = this.statusform.value;


    console.log(obj2);



    this.UserTypeID = localStorage.getItem('UserTypeID');
    console.log('invoicedetail');
    console.log(this.UserTypeID);

    this.UserID = localStorage.getItem('UserID');
    console.log('invoicedetailuser');
    console.log(this.UserID);


    var obj1 =
    {

      "flag": "MSTItemCatagory_INSERT",
      "UserID": this.UserID,
      "UserRoleTypeID": this.UserTypeID,
      "InvoiceID": InvoiceID,
      "VendorInvoiceNo": VendorInvoiceNo,
      "CreatedBy": "1",
      "CreatedByID": "2",
      "UpdatedDate": "",
      "UpdatedBy": "",
    };
    var obj2 = this.statusform.value;


    console.log(obj2);

    var form_data = Object.assign(obj1, obj2);
    console.log("merge");
    console.log(form_data);
    console.log("merge");
    console.log(this.statusform);
    console.log(this.statusform.value.flag);

    const datarequest = {
      "CheckSum": "",
      "lead_id": "",
      "ActionFor": "Insert",
      "dataobject": [

        form_data

      ]
    }
      ;
    console.log("datarequest1");
    console.log(datarequest);
    console.log("datarequest1");
    console.log(this.form);


    if (this.statusform.valid) {


 

      this.visibleloader = true;
      console.log('form submitted');

      let url = this.ServiceUrl + "/invoicetracking/invoicetrackingregistration";
      this.http.post(url, datarequest).toPromise().then((data: any) => {


        console.log(data.invoicetrackingregistrationResult);

        if (data.invoicetrackingregistrationResult.Message == 'Sucess') {
          this.visibleloader = false;
          console.log('Insert Success');

          this.StatusUpdatePopup();
          localStorage.setItem('notification_update', 'Success');
          setTimeout(() => {
            setTimeout(() => {
              this.route.navigate(['/InvoiceList']);
            });
          }, 2000);

        } else {

          console.log('Please Try Again Later');

        }

      });



    } else {
      this.validateAllFormFields(this.statusform);
    }
  }



  
  sendnotification() {
    this.UserTypeID = localStorage.getItem('UserTypeID');
   this.UserID = localStorage.getItem('UserID'); 

   
this.InvoiceID=localStorage.getItem('invoice_id');
   var obj1 =
    { 
      "UserID": this.UserID,
      "UserRoleTypeID": this.UserTypeID,
      "InvoiceID": this.InvoiceID,
    };
    var obj2 = this.notificationform.value;


    console.log(obj2);

    var form_data = Object.assign(obj1, obj2);
   console.log(form_data);
 
    const datarequest = {
      "CheckSum": "",
      "lead_id": "",
      "ActionFor": "Insert",
      "dataobject": [

        form_data

      ]
    } ;
    
    console.log(datarequest);
    console.log("datarequest1"); 


    if (this.notificationform.valid) {
      this.visibleloader = true;
     let url = this.ServiceUrl + "/scheduler/invoicealertlogregistration";
      this.http.post(url, datarequest).toPromise().then((data: any) => {


        console.log(data.invoicealertlogregistrationResult);

        if (data.invoicealertlogregistrationResult.Message == 'Sucess') {


          // this.notificationerror=false;
          this.visibleloader = false;
          console.log('Send Success');
           this.Notification_UpdatePopup();
          localStorage.setItem('statusupdate', 'Success');
          setTimeout(() => {
            setTimeout(() => {
              this.route.navigate(['/View-Invoice/',this.InvoiceID]);
            });
          }, 2000);

        } else {

          console.log('Please Try Again Later');

        }

      });



    } else {
      
 this.notificationerror=true; 
    }
  }

 
  

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  print_page() {
    setTimeout(() => printDiv('content'), 3000);
  }


}
interface City {
  name: string,
  code: string
}



@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
    window.location.reload();


  }

}