<div class="card-body mobileresponsive" style="background-color:#fff;">
    <h1 class="card-title"  style="font-weight:bold;">
       Vendor Wise Invoice List
    </h1>
      
<p-toast></p-toast>






<div class="card" style=" overflow-x: scroll;">
    <!-- <p-toolbar styleClass="p-mb-4"> -->
        <!-- <ng-template pTemplate="left"> -->
            <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button> -->
        <!-- </ng-template> -->

        <!-- <ng-template pTemplate="right"> -->

            <!-- <p-multiSelect style="width:200px;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
            <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
        <!-- </ng-template> -->
    <!-- </p-toolbar> -->

    <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="['name','country.name','inventoryStatus']"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">

        
        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
             
            <div class="position-relative  form-group col-sm-3">
                <label for="InvoiceYear" class="col-form-label">Vendor<span>*</span></label>
                <div class="">
                    <!-- <input type="text" name="InvoiceYear"  placeholder="Please Enter  Invoice Year." id="InvoiceYear" class="form-control"  formControlName="InvoiceYear"> -->
                    
                    <select  name="vendor"    id="vendor" class="form-control"    (change)="getDetail($event.target.value)" >
                      <option [ngValue]="null" disabled selected>Select Vendor</option>
                        <option *ngFor="let detail of vendorlist" [value]="detail.VendorID">{{detail.VendorName}}</option>
                    

                    </select>

                    
                    <!-- <app-field-error-display [displayError]="isFieldValid('InvoiceYear')" errorMsg="Please Enter  Year"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display> -->
                </div>
            </div>
            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
  
        </ng-template>
   
     
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width: 50px">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th> -->
      
              
         
                <th pSortableColumn="InvoiceID " style="width: 150px">Bill Id.<p-sortIcon field="InvoiceID"></p-sortIcon></th>
                <th pSortableColumn="inventoryStatus"  style="width: 150px" > Invoice Status <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
                <th pSortableColumn="InvoiceID " style="width: 150px">Invoice No.<p-sortIcon field="InvoiceID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Invoice Date<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">ValidityExpireOn<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskMobileNo" style="width: 150px">	Item Name <p-sortIcon field="DivDeskMobileNo"></p-sortIcon></th>
                <th pSortableColumn="InvoiceID " style="width: 150px">Invoice Name<p-sortIcon field="InvoiceID"></p-sortIcon></th>
                <th pSortableColumn="DivState" style="width: 150px">Vendor Name <p-sortIcon field="DivState"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Net Payable Amount<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Payment Status<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>

                <th pSortableColumn="DivName" style="width: 150px">Invoice Document<p-sortIcon field="DivName"></p-sortIcon></th>
                <th pSortableColumn="Headname" style="width: 150px">Head Name<p-sortIcon field="Headname"></p-sortIcon></th>
                 
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Invoice For Cycle<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>
         
                <th pSortableColumn="itemcategoryname" style="width: 150px">Item Category Name<p-sortIcon field="itemcategoryname"></p-sortIcon></th>
                
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Organization Name<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivGSTN" style="width: 150px"> Division Name <p-sortIcon field="DivGSTN"></p-sortIcon></th>
                <th pSortableColumn="DivGSTN" style="width: 150px"> Department Name <p-sortIcon field="DivGSTN"></p-sortIcon></th>
               
                    
            </tr>


            <tr>
         
   
                <td>
    
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceID' ,'contains')" placeholder="Search Bill ID" />
                    </td>  

        <td>
    
            <input pInputText type="text" (input)="dt.filter($event.target.value,'StageName' ,'contains')" placeholder="Search Invoice Status" />
            </td>   
        
           
<td>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'VendorInvoiceNo' ,'contains')" placeholder="Search Vendor Invoice No." />
</td>


<td>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')" placeholder="Search Invoice Date" />
</td>
<td>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'ValidityExpireOn' ,'contains')" placeholder="Search Validity Expire On" />
</td>

<th>	
        
  
    <input pInputText type="text" (input)="dt.filter($event.target.value,'ItemName' ,'contains')" placeholder="Search Item Name" />
    
    
      
        
    
     
    
    </th>
<th>	
        
  
<input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceName' ,'contains')" placeholder="Search Invoice Name" />


  
    

 

</th>
 
      
<td>
    
    <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorName' ,'contains')" placeholder="Search Vendor Name" />
    </td>

    <td>
    
        <input pInputText type="text" (input)="dt.filter($event.target.value,'NetPayableAmount' ,'contains')" placeholder="Search NetPayable Amount" />
        </td>

        <td>
    
            <input pInputText type="text" (input)="dt.filter($event.target.value,'paymentstatus' ,'contains')" placeholder="Search Payment Status" />
            </td>
        
    <td>
        <input pInputText type="text" (input)="dt.filter($event.target.value,'Document' ,'contains')" placeholder="Document" readonly />

          </td>
        <td>
    
            <input pInputText type="text" (input)="dt.filter($event.target.value,'Headname' ,'contains')" placeholder="Search Head Name" />
            </td>
            <td>
    
                <input pInputText type="text" (input)="dt.filter($event.target.value,'invoiceForName' ,'contains')" placeholder="Search Invoice For Name" />
                </td>
               

           
               
                    
                    <td>
    
                        <input pInputText type="text" (input)="dt.filter($event.target.value,'itemcategoryname' ,'contains')" placeholder="Search Item Category Name" />
                        </td>
                        <td>
    
                            <input pInputText type="text" (input)="dt.filter($event.target.value,'OrgName' ,'contains')" placeholder="Search Org Name" />
                            </td>
                            <td>
    
                                <input pInputText type="text" (input)="dt.filter($event.target.value,'DivName' ,'contains')" placeholder="Search Div Name" />
                                </td>
                       
                                <td>
    
                                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DeptName' ,'contains')" placeholder="Search Dept Name" />
                                    </td>
                                                
                                                                                    

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr  >
  
             
                   <td>{{product.InvoiceID}}</td>

                   <td  >
                       
                    {{product.StageName}}
                
                    <!-- <div *ngIf="product.StageName=='Paid';" style="color:green">Success</div>
                    
                    <div *ngIf="product.StageName=='Registered';" style="color:orangered">In Process</div> -->
                
                </td>
                   <td>{{product.VendorInvoiceNo}}</td>
                   <td>{{product.InvoiceDate}} </td>
                    
                <td>{{product.ValidityExpireOn}}</td>
                <td>{{product.ItemName}}</td>

                
                   <td>{{product.InvoiceName}}</td>
                   
                <td>{{product.VendorName}} </td>


                <td>{{product.NetPayableAmount}} </td>
                <td class="col2"> 


                    <div *ngIf="product.RespMessage=='success'; else elseBlock" style="color:green">Paid</div>
                    <ng-template #elseBlock ><span   style="color: #ec5729;">Unpaid</span></ng-template> 
                </td>
                
                <td><a href="http://services.aisect.org/{{product.InvoiceAttechmentURL}}" target="_blank" ><i class="fa fa-file-pdf-o" style="font-size:48px;color:red" title="Click For Open Document"></i></a></td>
                
                <td>{{product.InvoiceHeadItemName}}</td>
                <td>{{product.InvoiceForName}}</td>
        
                <td>{{product.ItemCategoryName}} </td>
                
                <td>{{product.OrgName }} </td>
                <td>{{product.DivName}} </td>
                <td>{{product.DeptName}} </td>
                
               

            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} PO Batch    {{i}}.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '450px'}" header="Product Details" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image">
        <div class="p-field">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" [(ngModel)]="product.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !product.name">Name is required.</small>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea id="description" pInputTextarea [(ngModel)]="product.description" required rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="inventoryStatus">Inventory Status</label>
            <p-dropdown [(ngModel)]="product.inventoryStatus" inputId="inventoryStatus" [options]="statuses" placeholder="Select">
                <ng-template let-option pTemplate="item">
                    <span [class]="'product-badge status-' + option.value">{{option.label}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="p-field">
            <label class="p-mb-3">Category</label>
            <div class="p-formgrid p-grid">
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category1" name="category" value="Accessories" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category1">Accessories</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category2" name="category" value="Clothing" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category2">Clothing</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category3" name="category" value="Electronics" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category3">Electronics</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category4" name="category" value="Fitness" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category4">Fitness</label>
                </div>
            </div>
        </div>

        <div class="p-formgrid p-grid">
            <div class="p-field p-col">
                <label for="price">Price</label>
                <p-inputNumber id="price" [(ngModel)]="product.price" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
            </div>
            <div class="p-field p-col">
                <label for="quantity">Quantity</label>
                <p-inputNumber id="quantity" [(ngModel)]="product.quantity"></p-inputNumber>
            </div>
        </div>
    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>



</div>
