
<br>&nbsp;&nbsp; <a     [routerLink]="['/Sheet-A-Month-Wise-PO-Summary']"  class="noprint"><button class="btn btn-info btn-lg">Back</button>
</a>    

<div class="card"   style="background-color:#fff; margin:50px;" >
 
    <form    [formGroup]="paymentform" (ngSubmit)="getPODetail()" class="noprint" style="text-align:center;padding:10px;"   >

     <div class="row"   style="padding:10px;">
        <div class="position-relative  form-group col-sm-3">
            <!-- <label for="InvoiceYear" class="col-form-label">Year<span>*</span></label> -->
            <div class="">
                <label style="color:#f1592a;">From</label>
                <input type="month" id="bdaymonth"   class="form-control"  name="bdaymonth" formControlName="InvoiceYear"> 
            </div>
        </div>
    
    <div class="position-relative  form-group col-sm-3">
        <!-- <label for="InvoiceMonth" class="col-form-label">Month<span>*</span></label> -->
        <div class="">
            <label style="color:#f1592a;" >To</label>
            <input type="month" id="bdaymonth"   class="form-control"  name="bdaymonth" formControlName="InvoiceMonth"> 
         </div>
    </div>
      
    <div class="position-relative  form-group col-sm-3">

        <label style="color:#f1592a;" >Group</label>
           <div class="form-check">
            <input class="form-check-input" type="radio" name="grouptype"   [(ngModel)]="group"    value="MSTPaymentOrderBatch_LISTDateWiseGroup1"   formControlName="grouptype">
            <label class="form-check-label" for="flexRadioDisabled">
                Summary Group 1
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="grouptype"   [(ngModel)]="group"   value="MSTPaymentOrderBatch_LISTDateWiseGroup2"   formControlName="grouptype" >
            <label class="form-check-label" for="flexRadioDisabled">
                Summary Group 2
            </label>
          </div>
    </div>
    
 


    <div class="position-relative  form-group col-sm-2">     
       <!-- <label for="InvoiceYear" class="col-form-label">&nbsp;</label> -->

        <div class="">
          
    <button type="submit" class="btn btn-info btn-sm"  pButton pRipple     [style]="{width:'35%',padding:'2%',marginTop:'30px',background:'#f1592a',border:'1px solid #f1592a',borderRadius:'10px'}" >Search</button> 
        </div>
          
    </div>

    <div class="position-relative  form-group col-sm-4">
    </div>
</div>
</form>


  </div>  
  
  <div  id="content" #content> 

    
<div class="card-body" style="background-color:#fff; margin:50px;">
 
  <img src="../../assets/images/3.png"  class="logocss" />
 

<h2 class="card-title"  style="font-weight:bold;text-align: center;" *ngIf="group=='MSTPaymentOrderBatch_LISTDateWiseGroup2'">
   Month Wise PO-Batch List  (SHEET-B-2)
 </h2>
 <h2 class="card-title"  style="font-weight:bold;text-align: center;" *ngIf="group=='MSTPaymentOrderBatch_LISTDateWiseGroup1'">
  Month Wise PO-Batch List  (SHEET-B-1)
 </h2>
 
<div  *ngFor="let item of newArr"  style="margin: 2%;" >
    
  
    <h3 class="card-title"   style="font-weight:bold;text-align: center;"  *ngIf="showmonth"  > 
      
      
      <a     [routerLink]="['/Sheet-B-I',item.MonthYear]" >  Month of {{item.MonthYear}} </a> 

      <!-- <a     [routerLink]="['/Sheet-B-I',item.MonthYear]"><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"   style="margin-right: 4px;" ></button> -->
      <!-- </a>     -->
     
    
    
    </h3>  
 
<div class="card">


  

 




<p-table [value]="item.groupItem" responsiveLayout="scroll" >
    <ng-template pTemplate="header">
        <tr> 
                  <th  class="tabletd"  style="width:50px">SN  </th>
                  <th  class="tabletd" style="width: 150px">PO Batch Number </th>
                  <th  class="tabletd"  style="width: 150px">Created Date </th>
                  <th  class="tabletd" style="width: 150px">Organization </th>
                  <th  class="tabletd" style="width: 150px">NO of Invoice  </th>
                  <th  class="tabletd" style="width: 150px">Bill ID  </th>
                   <th  class="tabletd" style="width: 200px">Total Amount </th>
                   <th  class="tabletd" style="width: 200px">IGST Amount </th>
                   <th  class="tabletd" style="width: 100px">Discount </th>
                   <th  class="tabletd"  style="width: 200px">Total Net Payable </th>
                  
  
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product  let-i="rowIndex" >
        <tr>
         <td   >{{i + 1}}</td>
            <!-- <td class="tabletd"> {{product.OrgName}}</td> -->
          
            <!-- <td>{{product.POOrderID}}</td> -->
   
         <td><a   [routerLink]="['/PO-Detail',product.POOrderID]" >{{product.DisplayBatchNo }}</a></td>
  
         <td>
          <!-- {{product.CreatedDate}} -->
           {{product.CreatedDate | date: 'dd/MM/yyyy'}}</td>
         <td>{{product.OrgName }}</td>
       
         <td>{{product.TotInvoice}}</td>  
  
         
         <td>{{product.InvoiceID}}</td>
         
         <td>{{product.TotalCostWithoutGST}}</td>  
         <td>{{product.IGSTAMT}}</td>  
         <td>{{product.DiscountAmount}}</td>  
         <td>{{product.TotalPayableAmount}}</td>  
          
  
      
  
    
        </tr>
  
        
    </ng-template>
   
  
  
  <ng-template pTemplate="footer" >
  <tr>
  <th style="padding: 1%;text-align: right;" colspan="4" class="tabletd"> &nbsp; &nbsp; &nbsp;Grand Total </th>
   
    <th style="padding: 1%;" class="tabletd">{{TotInvoice}}</th>
   <th></th>
     <th style="padding: 1%;" class="tabletd">  {{TotBIllAmount}} </th>
    <th style="padding: 1%;" class="tabletd">  {{TotTdsAmount}} </th>
    <th style="padding: 1%;" class="tabletd">  {{TotDiscount}} </th>
    <th style="padding: 1%;" class="tabletd">  {{TotNetPayble}} </th>
   
  
  
  </tr>
  </ng-template>
  </p-table>
  
</div>


 
</div> 



  
</div>

<!-- <div *ngFor="let item of groupArr">
  <ng-container>
<tr >
<td colspan="2"><b>{{item.MonthYear}}</b></td>
</tr>
<tr *ngFor="let value of item.groupItem">
<td>{{value.InvoiceID}}</td>
<td>{{value.name}}</td>
</tr>
</ng-container></div> -->
<div style="text-align: center;"> <button class="btn btn-info noprint" id="printbtn"  (click)="print_page()">Print&nbsp;&nbsp;<i class="fa fa-print" style="font-size:32px"></i></button></div>
  