
<form class="form-horizontal" [formGroup]="form" *ngFor="let data of products"> 

    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">EDIT STAGE</h1>
    <div class="card-body" style="background-color:#fff;">
        <h1 class="card-title">
           Edit Stage
        </h1>
        <div class="row">
          
    
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="StageName" class="col-form-label">Stage Name  <span>*</span></label>
                <div class="">
                    <input type="text" name="StageName" id="StageName" class="form-control" formControlName="StageName" [(ngModel)]="data.StageName" >
         <app-field-error-display [displayError]="isFieldValid('StageName')" errorMsg="Please Enter Stage Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
 
            <div class="position-relative  form-group col-sm-6">
                <label for="RoleID" class="col-form-label">Role Name  <span>*</span></label>
                <div class="">
                         <select  class="form-control" name="RoleID" id="RoleID"     formControlName="RoleID" [(ngModel)]="data.RoleID"  > 
                        
                            <option [ngValue]="null" disabled selected >Please Select Role</option>
                        
                            <option *ngFor="let rolelists of rolelist"      [value]="rolelists.RoleTypeID" >{{rolelists.RoleTypeName}}</option>
                        

                        </select>



                    <app-field-error-display [displayError]="isFieldValid('RoleID')" errorMsg="Please Select Role"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
                <div class="">

        <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus"  [(ngModel)]="data.ActiveStatus">
            <option value="1">Active</option>
            <option value="0">InActive</option></select>
                     <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>

            
          
    
            <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    
    