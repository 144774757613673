<div *ngIf="visibleloader"
    style="position: fixed;left: 0px;top: 0px;width: 100%;height: 100%;margin-left: 37%;margin-top: 10%; z-index: 9999;">
    <img src="../../../assets/images/loading.gif">
</div> 
<br> 
<br> 
<div class="container" style="background-color: #ffffff;" id="content" #content>
    <p-toast></p-toast>
    <div class="row">
        <div class="col-xs-12">
            <h2><span class="pull-right">
                    <span style="border: 1px dotted black;">SALES/ISSUE ENTRY</span>
                </span>
            </h2>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="invoice-title">
                <div class="row">
                    <div class="position-relative  form-group col-sm-4">
                        <label for="City" class="col-form-label">Organization<span>*</span></label>
                        <div class="">
                            <input type="text" style="width: 100%;" pInputText [disabled]="true" class="form-select"
                                value="{{UserOrgName}}" />
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-4">
                        <label for="DivID" class="col-form-label">Division Name<span>*</span></label>
                        <div class="">
                            <input type="text" style="width: 100%;" pInputText [disabled]="true" class="form-select"
                                value="{{UserDivName}}" />
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-4">
                        <label for="DeptID" class="col-form-label">Department Name<span>*</span></label>
                        <div class="">
                            <input type="text" style="width: 100%;" pInputText [disabled]="true" class="form-select"
                                value="{{UserDeptName}}" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="position-relative  form-group col-sm-4">
                        <br><br><br>
                        <strong>Seller</strong><br>
                        <ng-container *ngIf="departmentdetail && departmentdetail[0]">
                            {{departmentdetail[0].DivName}}<br>
                            {{departmentdetail[0].DeptName}}<br>
                            {{departmentdetail[0].DeptAddress}}
                        </ng-container>
                        <ng-container *ngIf="departmentdetail && departmentdetail[0]">
                            GSTIN: {{departmentdetail[0].GSTN}}<br>
                        </ng-container>
                    </div>
                    <div class="position-relative  form-group col-sm-4"></div>
                    <div class="position-relative  form-group col-sm-4"></div>
                </div>
            </div>
            <div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div>
            <span> <br> <label>Please Select Sale/Issue TO,</label></span>
            <br>
            <span style="display:inline-flex;">
                <p-radioButton name="groupname" value="Vendor"
                    [(ngModel)]="SaleTo"></p-radioButton>&nbsp;Vendor&nbsp;&nbsp;
                <p-radioButton name="groupname" value="Organization"
                    [(ngModel)]="SaleTo"></p-radioButton>&nbsp;Organization
                <p-radioButton name="groupname" value="Customer" [(ngModel)]="SaleTo"></p-radioButton>&nbsp;Customer
            </span>
            <br>
            <div *ngIf="SaleTo=='Vendor'">

                <div class="position-relative  form-group col-sm-6" style="padding-left:0;">
                    <label for="VendorID" class="col-form-label">Vendor Name<span>*</span></label>
                    <div>
                        <p-dropdown [options]="vendorlist" [(ngModel)]="selectedVendorId" optionLabel="VendorName"
                            [filter]="true" filterBy="VendorName" [showClear]="true" [placeholder]="selectedVendorName"
                            (onChange)="changeVendor($event)" id="VendorID" formControlName="VendorID"
                            [style]="{ width: '100%' }">
                            <ng-template let-country pTemplate="item">
                                <div class="country-item">
                                    <div>{{country.VendorName}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <h3 class="pull-right"> <img src="..\assets\images\3.png" width="150" /></h3>
                <strong>BILL TO,</strong>
                <h4 *ngIf="SaleTo=='Vendor'"> {{VendorName}}, <br>

                    {{VendorBillingAddress}} {{VendorCity}}-{{VendorPinCode}},<br>
                    India GSTIN-{{GSTN}}, {{VendorState}}<br>
                    E-mail:{{VendorEmailID}}, Mobile No-{{VendorMobileNo}}</h4>

            </div>
            <div *ngIf="SaleTo=='Organization'">
                <div class="row">
                    <div class="position-relative  form-group col-sm-4">
                        <label for="City" class="col-form-label">Organization<span>*</span></label>
                        <div class="">

                            <p-dropdown [options]="organizaionlist" [(ngModel)]="selectedOrgId" optionLabel="OrgName"
                                [filter]="true" filterBy="OrgName" [showClear]="true" [placeholder]="selectedOrgName"
                                (onChange)="getDivisionByOrg($event)" name="UserOrgID" id="UserOrgID"
                                [style]="{ width: '100%' }">

                                <ng-template let-country pTemplate="item">
                                    <div class="country-item">
                                        <div>{{country.OrgName}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>


                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-4">
                        <label for="DivID" class="col-form-label">Division Name<span>*</span></label>
                        <div class="">


                            <p-dropdown [options]="divisionlist" [(ngModel)]="selectedDiv" optionLabel="DivName"
                                [filter]="true" filterBy="DivName" [showClear]="true" [placeholder]="selectedDivName"
                                (onChange)="changeDivision($event)" name="UserDivID" id="UserDivID"
                                [style]="{ width: '100%' }">

                                <ng-template let-country pTemplate="item">
                                    <div class="country-item">
                                        <div>{{country.DivName}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>


                        </div>
                    </div>



                    <div class="position-relative  form-group col-sm-4">
                        <label for="DeptID" class="col-form-label">Department Name<span>*</span></label>
                        <div class="">

                            <p-dropdown [options]="departmentlist" [(ngModel)]="selectedDeptID" optionLabel="DeptName"
                                [filter]="true" filterBy="DeptName" [showClear]="true" [placeholder]="selectedDeptName"
                                (onChange)="changeDepartment($event)" name="UserDeptID" id="UserDeptID"
                                [style]="{ width: '100%' }">

                                <ng-template let-country pTemplate="item">
                                    <div class="country-item">
                                        <div>{{country.DeptName}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>


                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="position-relative  form-group col-sm-4">
                        <br><br><br>

                        <strong>BILL TO,</strong>

                        <!-- {{divisiondetail|json}} -->

                        <h4> <ng-container *ngIf="departmentdetailbyselection && departmentdetailbyselection[0]">


                                {{divisiondetail[0].DivName}}<br>
                                {{departmentdetailbyselection[0].DeptName}}<br>
                                {{divisiondetail[0].DivBillingAddress}}<br>
                                {{divisiondetail[0].DistrictName}}<br>
                                {{divisiondetail[0].StateName}}<br>
                            </ng-container>
                            <ng-container *ngIf="divisiondetail && divisiondetail[0]">
                                GSTIN: {{divisiondetail[0].DivGSTN}}<br>
                            </ng-container>


                        </h4>
                    </div>
                    <div class="position-relative  form-group col-sm-4"></div>

                    <div class="position-relative  form-group col-sm-4"></div>
                </div>

            </div>
            <div *ngIf="SaleTo=='Customer'">
                <div class="position-relative  form-group col-sm-6" style="padding-left:0;">
                    <label for="VendorID" class="col-form-label">Customer List<span>*</span></label>
                    <p-dropdown [options]="customerList" [(ngModel)]="selectedCustomerId" optionLabel="VendorName"
                        [filter]="true" filterBy="VendorName" [showClear]="true" [placeholder]="selectedCustomerName"
                        (onChange)="changeCustomer($event)" formControlName="VendorID" [style]="{ width: '100%' }">
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.VendorName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>

                    <button type="button" class="btn btn-primary" (click)="CustomerDialogButton()">
                        <i class="fa fa-plus"></i>
                    </button>
                    <!-- <h1 style="font-weight: 700;" *ngIf="VendorShow">{{VendorName}}</h1> -->


                </div>
                <h3 class="pull-right"> <img src="..\assets\images\3.png" width="150" /></h3>
                <strong>BILL TO,</strong>
                <h4 *ngIf="SaleTo=='Customer'"> {{VendorName}}, India<br>
                    E-mail:{{VendorEmailID}}, Mobile No-{{VendorMobileNo}},
                    {{VendorCity}}({{VendorState}})
                </h4>
            </div>
        </div>
    </div>

    <div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div>

    <div class="row mt-3">

        <div class="position-relative  form-group col-sm-4">

            <span style="display:inline"><strong> Sales Type :</strong> </span>
            <select [(ngModel)]="purchasetype" class="form-control">
                <option selected disabled>Please Select</option>
                <option value="1">Sales</option>
                <option value="2">Sales Order</option>
            </select>

        </div>
        <div class="position-relative  form-group col-sm-4">
        </div>
        <div class="position-relative  form-group col-sm-4">
            <strong>Invoice Date:</strong>

            <input type="date" class="form-control" [(ngModel)]="invdate"><br><br>

        </div>
    </div>

    <div class="row mb-5">


        <div *ngIf="!dynamicArray?.length">
            <div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div>
            <h1 style="color:red!important;text-align:center">Container is empty Please Add New Product.</h1>
        </div>
        <div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a"></div>


        <!-- {{dynamicArray|json}} -->

        <div class="container">
            <div class="col-md-12 p-0 mb-5">
                <div class="panel panel-default">

                    <div class="panel-body">
                        <table class="table">
                            <tbody>
                                 <tr *ngFor="let dynamic of dynamicArray; let i = index">
                                    <td class="p-0"> 
                                        <div class="panel-body">
                                            <table class="table">

                                          
                                                    <tr style="width:100%">
                                                        <td class="text-center"><strong>Box List</strong></td>
                                                        <!-- <td class="text-center"><strong> Box Description</strong></td> -->
                                                        <td class="text-center"><strong>Quantity</strong></td>
                                                        <td class="text-center"><strong>Rate</strong></td>
                                                        <td class="text-center"><strong>Tax(%)</strong></td>
                                                        <td class="text-center"><strong>Total Amount</strong></td>
                                                        <td class="text-center" style="width: 150px;text-align: center;"><strong>Action</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <!-- (ngModelChange)="getBoxDetail($event) -->
                                                        <td>
                                                            <select class="form-control mb" name="BoxID"
                                                                [(ngModel)]="dynamicArray[i].BoxID"
                                                                data-placeholder="Please Select Product"
                                                                (change)="getBoxDetail($event)">
                                                                <option selected value="" disabled>
                                                                    Please Select Product
                                                                </option>
                                                                <option *ngFor='let item of itemcategorylist'
                                                                    [value]="item.BoxID"
                                                                    data-category="item.ItemCategoryID">
                                                                    {{item.BoxName}} 
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <!-- <td>
                                                            <select class="form-control mb" name="BatchNo"
                                                                [(ngModel)]="dynamicArray[i].BatchNo"
                                                                (ngModelChange)="getStockDetailByBatchNo($event,i)">
                                                                <option selected value="" disabled>Please Select Batch No
                                                                </option>
                                                                <option *ngFor='let batch of dynamicArray[i].BatchDetail'
                                                                    [value]="batch.StockID">
                                                                    {{batch.PurchaseBatchNumber}}
                                                                </option>

                                                            </select>
                                                        </td> -->
                                                        <td>
                                                            <input name="BoxSaleQuantity"
                                                                [(ngModel)]="dynamicArray[i].BoxSaleQuantity" type="text"
                                                                class="form-control mb"
                                                                  />
                                                        </td>
                                                        <td>
                                                            <input name="BoxSalePrice" [(ngModel)]="dynamicArray[i].BoxSalePrice"
                                                                type="number" class="form-control mb" />
                                                        </td>
                                                        <td>
                                                            <select name="BoxTaxPercentage" id="BoxTaxPercentage"
                                                                class="form-control mb"
                                                                [(ngModel)]="dynamicArray[i].BoxTaxPercentage"
                                                                (ngModelChange)="getValueform(dynamicArray[i].BoxSaleQuantity,dynamicArray[i].MRP,dynamicArray[i].BoxSalePrice,dynamicArray[i].WholeBoxSalePrice,dynamicArray[i].BoxSalePrice,dynamicArray[i].BoxSalePriceType,dynamicArray[i].BoxTaxPercentage,i)">
                                                                <option *ngFor="let gstdetails of gstdetail"
                                                                    [value]="gstdetails">
                                                                    {{gstdetails}}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input name="BoxTotalAmount"
                                                                [(ngModel)]="dynamicArray[i].BoxTotalAmount" readonly
                                                                type="number" class="form-control mb" />
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <span data-toggle="collapse" [attr.data-target]="'#test' + i"
                                                                class="accordion-toggle  fa fa-eye"
                                                                (click)="showmoredetail(i)"
                                                                style="font-size: 21px;color: skyblue;"></span> &nbsp;
                                                            <span class="fa fa-trash" style="font-size: 21px; color: red;"
                                                                (click)="deleteRow(i)"></span>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colspan="12" class="hiddenRow">
                                                            <div id="test{{i}}"
                                                                style="border: 2px inset #ffb26266; padding: 1%;"
                                                                *ngIf="visibleIndex === i">
                                                                <div class="row">
                                                                    <div class="col-md-2">
                                                                        <strong>Brand :</strong>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <input type="hidden" name="{{BrandName}}"
                                                                            [(ngModel)]="dynamicArray[i].BrandName"
                                                                            class="form-control mb" />
                                                                        {{dynamicArray[i].BrandName}}
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <strong>Description :</strong>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <input name="Description"
                                                                            [(ngModel)]="dynamicArray[i].Description"
                                                                            type="hidden" class="form-control mb" />
                                                                        {{dynamicArray[i].Description}}
                                                                    </div>
                                                                    <div class="col-md-1">
                                                                        <strong>Tax Amt. :</strong>
                                                                    </div>
                                                                    <div class="col-md-1">
                                                                        <input name="TaxAmount"
                                                                            [(ngModel)]="dynamicArray[i].TaxAmount"
                                                                            type="hidden" class="form-control mb" />
                                                                        {{dynamicArray[i].TaxAmount| number : '1.2-2'}}
                                                                    </div>
                                                                    <div class="col-md-1">
                                                                        <strong>Taxable Amt. :</strong>
                                                                    </div>
                                                                    <div class="col-md-1">
                                                                        <input name="TaxableAmount"
                                                                            [(ngModel)]="dynamicArray[i].TaxableAmount"
                                                                            type="hidden" class="form-control mb" />
                                                                        {{dynamicArray[i].TaxableAmount| number : '1.2-2'}}
                                                                    </div>

                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-2">
                                                                        <strong>Pkd. Date :</strong>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <input name="hidden"
                                                                            [(ngModel)]="dynamicArray[i].PackedDate"
                                                                            type="hidden" class="form-control mb" />
                                                                        {{dynamicArray[i].PackedDate | date}}
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <strong>Exp. Date :</strong>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <input name="ExpiryDate"
                                                                            [(ngModel)]="dynamicArray[i].ExpiryDate"
                                                                            type="hidden" class="form-control mb" />
                                                                        {{dynamicArray[i].ExpiryDate | date}}
                                                                    </div>
                                                                    <div class="col-md-1">
                                                                        <strong>Available Quantity :</strong>
                                                                    </div>
                                                                    <div class="col-md-1">
                                                                        <input type="hidden" name="StockQuantity" readonly
                                                                            [(ngModel)]="dynamicArray[i].StockQuantity"
                                                                            class="form-control mb" />
                                                                        {{dynamicArray[i].StockQuantity}}
                                                                    </div>
                                                                    <div class="col-md-1"></div>
                                                                    <div class="col-md-1"></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                  
                        </table>
                    </div>
                                    </td>
                                 </tr>
                            </tbody>
                        </table>
                </div>
                <button style="float:right;" class=" btn fa fa-plus btn-info btn-sm" (click)="addRow(i)"> Add</button>&nbsp;&nbsp;

           
            </div>

        </div>
        <div style="max-width:110px; margin: 20px auto; min-height: 40px; display: inherit;">
            <button type="submit"   class="btn btn-primary btn-block btn-sm" (click)="onSubmit()"><i class="fa fa-floppy-o" aria-hidden="true"></i> Submit</button>
        </div>
    </div>

<!-- import excell  -->
<!-- <div class="importexcell mt-5">
<input type="file" (change)="onFileChange($event)" multiple="false" class="form-control" />
<div class="container">
   <h2>Import Excell Box Sale Stock List Shown Below </h2>    
   <table class="table table-striped"> 
   <tbody>
       <tr *ngFor="let row of data">
       <td *ngFor="let val of row">{{val}}</td> 
       </tr>
   
   </tbody>
   </table>
</div> 
<button (click)="export()" class="btn btn-success">Export!</button>
</div> -->


<!-- <hello name="{{ name }}"></hello>
<p>
	Start editing to see some magic happen :)
</p> -->

<!-- <input type="file" (change)="onFileChange($event)" />

<div id="output"></div>
<br>
<div *ngIf="willDownload">
	<a id="download"> Download JSON File </a>
</div> -->


<!-- Verified Excell data -->
 

 <h2 style="font-weight: bold;" class="text-center" >Import Box By Excel:</h2>
 <div class="card border-0">  
    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left">
            
            <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2"   (change)="onFileChange($event)"></button>
            <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button> -->
        </ng-template>

        <ng-template pTemplate="right"> 

            <input type="file" (change)="onFileChange($event)"  class="form-control"/>
            &nbsp;&nbsp;&nbsp;
            <!-- <p-fileUpload mode="basic"    label="Import" (change)="onFileChange($event)" chooseLabel="Import" class="mr-2 inline-block"></p-fileUpload> -->
           <a href="assets/images/BoxSaleFormate.xlsx" download> <button pButton pRipple label="Excel Formate" style="width:10rem;" icon="pi pi-upload" class="p-button-success"></button></a>
        </ng-template>
    </p-toolbar>
    <p-table #dt [value]="verifiedExcellData" class="border" [rows]="10" [paginator]="true" [globalFilterFields]="['name','country.name','representative.name','status']"  
         [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
        
        <!-- <ng-template pTemplate="caption">
            <div class="flex">

                
               <a href=""><button type="button" pButton pRipple icon="pi pi-file" (click)="dt.exportCSV()" class="mr-2" pTooltip="CSV" tooltipPosition="bottom"></button></a>
            </div>
        </ng-template> -->
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="BoxName" style="min-width:15rem"  style="width: 4rem">
                    S.No 
                <p-sortIcon field="BoxName"></p-sortIcon> 
                </th>
                <th pSortableColumn="BoxName" style="min-width:15rem">
                    Box Name
                <p-sortIcon field="BoxSaleQuantity"></p-sortIcon>
                </th>
                <th  pSortableColumn="BoxSaleQuantity">
                    Box Sale Quantity
                <p-sortIcon field="BoxSaleQuantity"></p-sortIcon>
                </th>
                <th pSortableColumn="BoxSalePrice">
                    Box Sale Price
                <p-sortIcon field="BoxSalePrice"></p-sortIcon>
                </th>
                <th pSortableColumn="BoxTaxPercentage" style="min-width:10rem">
                    Box Tax Percentage 
                <p-sortIcon field="BoxTaxPercentage"></p-sortIcon>
                </th> 
                <th pSortableColumn="BoxAvailableStock" style="min-width:10rem">
                    Box Available Stock
                <p-sortIcon field="BoxAvailableStock"></p-sortIcon>
                </th> 
                <th pSortableColumn="BoxAvailableOrNot" style="min-width:10rem">
                    Box Status
                <p-sortIcon field="BoxAvailableOrNot"></p-sortIcon>
                </th> 
             </tr>
        </ng-template>
        <ng-template pTemplate="body"   let-product let-rowIndex="rowIndex">
                <tr> 
                <td> {{rowIndex}}</td>
                <td>{{product.BoxName}}</td>
                <td>{{product.BoxSaleQuantity}}</td>
                <td>{{product.BoxSalePrice}}</td>
                <td>{{product.BoxTaxPercentage}}</td> 
                <td>
                    {{product.BoxAvailableStock}}
                </td> 
                <td> 
                    <span  *ngIf="product.BoxAvailableOrNot=='Available'" class="text-success">{{product.BoxAvailableOrNot}}</span>
                    <span  *ngIf="product.BoxAvailableOrNot=='Not Available'" class="text-warning">{{product.BoxAvailableOrNot}}</span>
                    <span  *ngIf="product.BoxAvailableOrNot=='Box Not Match'" class="text-danger">{{product.BoxAvailableOrNot}}</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>


</div>

<!-- {{verifiedExcellData|json}} -->
 
 
<div style="max-width:222px; margin: 20px auto; min-height: 40px; display: inherit;" >
    <button type="submit"  (click)="onSubmitExcel()"class="btn btn-primary btn-block btn-sm"><i class="fa fa-floppy-o" aria-hidden="true"></i> Submit With Available Box</button>
</div>
<!-- Verified Excell Data eof -->
<!-- <div class="importexcell mt-5">
<input type="file" (change)="onFileChange($event)" multiple="false" class="form-control" />
<div class="container">
   <h2>Import Excell Box Sale Stock List Shown Below </h2>    
   <table class="table table-striped"> 
   <tbody>
       <tr *ngFor="let row of data">
       <td *ngFor="let val of row">{{val}}</td> 
       </tr>
   
   </tbody>
   </table>
</div> 
<button (click)="export()" class="btn btn-success">Export!</button>
</div> -->

<!-- eof import excell -->
    <div class="row">
        <div class="col-md-12">
            <div class="panel panel-default">
                <div class="panel-body">
                    <div class="">
                        <br><br><br>
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-2"></div>
                            <div class="col-md-1"></div>
                            <div class="col-md-2">
                                <!-- <button *ngIf="dynamicArray[0].BoxID>0 && dynamicArray[0].BoxTotalAmount>0"
                                    type="submit" class="btn btn-info btn-lg" (click)="onSubmit()"
                                    [disabled]="isDisabled"> <i class="fa fa-floppy-o" aria-hidden="true"></i>
                                    &nbsp;&nbsp;Submit
                                </button> -->
                            </div>
                            <div class="col-md-1"></div>
                            <div class="col-md-2"></div>
                            <div class="col-md-2"></div>
                        </div>

                        <br><br><br><br><br><br><br><br>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</div>




<p-dialog [(visible)]="customerDialog" [style]="{width: '800px',height: '800px'}" header="Customer Registration"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">

        <form class="form-horizontal" [formGroup]="form">
            <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">
                CUSTOMER REGISTRATION</h1>
            <div class="card-body" style="background-color:#fff;  ">
                <div class="row">
                    <div class="position-relative  form-group col-sm-6">
                        <label for="VendorName" class="col-form-label">Customer Name<span>*</span></label>
                        <div [ngClass]="displayFieldCss('VendorName')">
                            <input type="text" name="VendorName" id="VendorName" class="form-control"
                                formControlName="VendorName">
                            <app-field-error-display [displayError]="isFieldValid('VendorName')"
                                errorMsg="Please Enter Vendor  Name" style="background-color: #791820;
									border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="VendorMobileNo" class="col-form-label">Mobile No<span>*</span></label>
                        <div class="">
                            <input type="number" name="VendorMobileNo" id="VendorMobileNo" class="form-control"
                                formControlName="VendorMobileNo">
                            <app-field-error-display [displayError]="isFieldValid('VendorMobileNo')"
                                errorMsg="Please Enter Mobile No" style="background-color: #791820;
								border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="VendorEmailID" class="col-form-label">Emailid <span>*</span></label>
                        <div class="">
                            <input type="text" name="VendorEmailID" id="VendorEmailID" class="form-control"
                                formControlName="VendorEmailID">
                            <app-field-error-display [displayError]="isFieldValid('VendorEmailID')"
                                errorMsg="Please Enter Email Id" style="background-color: #791820;
									border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="VendorBillingAddress" class="col-form-label">Address <span>*</span></label>
                        <div class="">
                            <textarea name="VendorBillingAddress" id="VendorBillingAddress" class="form-control"
                                formControlName="VendorBillingAddress"></textarea>
                            <app-field-error-display [displayError]="isFieldValid('VendorBillingAddress')"
                                errorMsg="Please Enter Address" style="background-color: #791820;
							border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="VendorState" class="col-form-label">State <span>*</span></label>
                        <div class="">
                            <p-dropdown [options]="stateList" [(ngModel)]="selectedStateID" optionLabel="StateName"
                                [filter]="true" filterBy="StateName" [showClear]="true"
                                [placeholder]="selectedStateName" (onChange)="changeState($event)" name="VendorState"
                                id="VendorState" formControlName="VendorState" [style]="{ width: '100%' }">
                                <ng-template let-country pTemplate="item">
                                    <div class="country-item">
                                        <div>{{country.StateName}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <app-field-error-display [displayError]="isFieldValid('VendorState')"
                                errorMsg="Please Enter State" style="background-color: #791820;
									border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="VendorCity" class="col-form-label">City <span>*</span></label>
                        <div class="">
                            <p-dropdown [options]="cityList" [(ngModel)]="selectedCityID" optionLabel="DistrictName"
                                [filter]="true" filterBy="DistrictName" [showClear]="true"
                                [placeholder]="selectedCityName" (onChange)="changeCity($event)" name="VendorCity"
                                id="VendorCity" formControlName="VendorCity" [style]="{ width: '100%' }">
                                <ng-template let-country pTemplate="item">
                                    <div class="country-item">
                                        <div>{{country.DistrictName}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <app-field-error-display [displayError]="isFieldValid('VendorCity')"
                                errorMsg="Please Enter VendorCity" style="background-color: #791820;
									border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-check col-sm-6 offset-sm-6">
                        <div class="">
                            <button class="btn btn-primary" (click)="onCustomerRegister()">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">

    </ng-template>
</p-dialog>






 