import { Component, OnInit } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http'; 

import { ActivatedRoute } from '@angular/router';
import { Billdesk } from '../billdesk';

import { Product } from '../product';
import { ProductService } from '../productservice';
import { BilldeskService } from '../billdesk.service';

import { environment } from '../../environments/environment';


import { printDiv } from './print-div';
@Component({
  selector: 'app-instituewisesummary',
  templateUrl: './instituewisesummary.component.html',
  styleUrls: ['./instituewisesummary.component.css']
})
export class InstituewisesummaryComponent implements OnInit {
  products: Product[];
  Totalamount:any;
  Totalinvoice:any;
  empList:any[];
  DiscountAmount:any;
  TDSInAmount:any;
  invoiceCount:any[];
  product: Product;
  id:any;
  i:any=0;

  TotalTdsinamount:any;
  TotalDiscountAmount:any;
  PoBatchNo:any;
  ServiceUrl=environment.baseUrl;
  CreatedDate:any;

  constructor(private http: HttpClient,private billdeskService: BilldeskService,private _Activatedroute:ActivatedRoute) { }
 
  ngOnInit(): void {

 

    
  
    this.id=this._Activatedroute.snapshot.paramMap.get("id");
    this._Activatedroute.paramMap.subscribe(params => { 
      this.id = params.get('id');

      console.log(this.id);

      console.log(this.billdeskService.getPoInstutueWise(this.id).then(res => this.products = res));
 
        this.http.get(this.ServiceUrl+"/InstituteWiseSummary?UserID=1&UserTypeID=1&POOrderID="+this.id).subscribe((profileres:any)=>{
          console.log('profileres');

          console.log(profileres);
        console.log(profileres.GetInstituteWiseSummaryResult.data);
const total =profileres.GetInstituteWiseSummaryResult.data.length;

this.PoBatchNo=profileres.GetInstituteWiseSummaryResult.data[0].DisplayBatchNo;
this.CreatedDate=profileres.GetInstituteWiseSummaryResult.data[0].CreatedDate;
console.log(total);
               this.empList=[];
               this.invoiceCount=[];
               this.TDSInAmount=[];
               this.DiscountAmount=[];
       
        for (let i = 0; i < total; i++) {
          console.log(profileres.GetInstituteWiseSummaryResult.data[i].TotPayment);
      
          this.empList.push(profileres.GetInstituteWiseSummaryResult.data[i].TotPayment);
         
          this.TDSInAmount.push(profileres.GetInstituteWiseSummaryResult.data[i].TDSInAmount);
          this.DiscountAmount.push(profileres.GetInstituteWiseSummaryResult.data[i].DiscountAmount);
          this.invoiceCount.push(profileres.GetInstituteWiseSummaryResult.data[i].TotInvoice);


          
        } 
      console.log('hello');
        console.log(this.empList);
        console.log('invoiceCount');
        console.log(this.invoiceCount);
      //   var toNumber = ['1','2','3','4','5'];
      
      console.log('hello');
        var sumNumber = this.empList.reduce((acc, cur) => acc + Number(cur), 0)
        console.log(sumNumber);
        this.Totalamount=sumNumber;
        console.log('profileres');

        console.log(this.Totalamount);
        var sumTdsinamount = this.TDSInAmount.reduce((acc, cur) => acc + Number(cur), 0)
        console.log(sumTdsinamount);
        this.TotalTdsinamount=sumTdsinamount;
        console.log(this.TotalTdsinamount);
     
        var sumDiscountAmount = this.DiscountAmount.reduce((acc, cur) => acc + Number(cur), 0)
        console.log(sumDiscountAmount);
        this.TotalDiscountAmount=sumDiscountAmount;
        console.log('profileres');

        console.log(this.TotalDiscountAmount);

        var sumInvoice = this.invoiceCount.reduce((acc, cur) => acc + Number(cur), 0)
        console.log(sumInvoice);
        this.Totalinvoice=sumInvoice;
        console.log('Totalinvoice');

        console.log(this.Totalinvoice);
});
 
      
  
 
    }); 
    console.log(this.billdeskService.getPoInstutueWise(this.id).then(res => this.products = res));
 

  }


  print_page(){

   var batch_no= this.PoBatchNo;
    // setTimeout(() => window.print(), 300);
    var batchno='SHEET_A_'+batch_no;
    // this.buttonhide=false;
    setTimeout(() =>printDiv('content',batchno), 3000);
  }
  

}
