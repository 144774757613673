<p-toast></p-toast>






<div class="card" style=" overflow-x: scroll;">


    <p-table #dt [value]="products" [rows]="50" [paginator]="true" [globalFilterFields]="['ActiveStatus',
    'OrgName',
    'DivName',
    'DeptName',
    'HODName']" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]">


        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1  class="headingclass">
                    DEPARTMENT LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />
                    &nbsp; &nbsp; <a [routerLink]="['/Department-Registration']" class="btn btn-success">Add <i
                            class="fa fa-plus" aria-hidden="true"></i></a>

                </span>
            </div>

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->

        </ng-template>


        <ng-template pTemplate="header">
            <tr>

                <th pSortableColumn="rating" class="actionclass"> Action</th>
                <th pSortableColumn="price" style="width: 150px">Active Status<p-sortIcon field="price"></p-sortIcon>
                </th>

                <th pSortableColumn="name" style="width: 150px">Organization Name <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th pSortableColumn="price" style="width: 150px">Division Name <p-sortIcon field="price"></p-sortIcon>
                </th>
                <th pSortableColumn="DeptName" style="width: 150px">Department Name<p-sortIcon
                        field="DeptName"></p-sortIcon></th>
                <th pSortableColumn="HODName" style="width: 150px">Hod Name <p-sortIcon field="HODName"></p-sortIcon>
                </th>



            </tr>



            <tr>
                <th>
                    <!-- <input pInputText type="text" style="width:100px;" (input)="dt.filter($event.target.value,'action' ,'contains')" placeholder="Action" readonly /> -->

                </th>
                <th>
                    <!-- <input pInputText type="text" (input)="dt.filter($event.target.value,'ActiveStatus' ,'contains')" placeholder="Active Status" /> -->
                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')"
                        styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>
                </th>

                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'OrgName' ,'contains')"
                        placeholder="Division Name" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DivName' ,'contains')"
                        placeholder="Department Name" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DeptName' ,'contains')"
                        placeholder="HOD Name" />
                </th>


                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'HODName' ,'contains')"
                        placeholder="Status" />
                </th>



            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">
                <td style=" 
                position: sticky;
                left: 0;
                z-index: 2;background:#ffffff;">


 

                        <span class="p-column-title">Action:</span>
                        <span style="display: flex;align-items: center;justify-content: center;">
                           <a [routerLink]="['/Edit-Department',product.DeptID]" class="mr-1 fs-2"><i class='fa fa-edit'  style='color: #2459a6;'   pTooltip="Edit Department Detail" ></i></a>
                          <span  pTooltip="View Department Detail"> <i class='fa fa-eye'  style='color: #f09639; zoom: 1.1;
                           border: 2px solid;
                           border-radius: 5px;' (click)="viewProduct(product.DeptID)"  ></i> </span>
                        </span>



                </td>
                <td>
 
                    
    <span class="p-column-title">Status:</span>
    <span *ngIf="product.ActiveStatus=='1'" class="statusactive"  >Active</span>
    <span *ngIf="product.ActiveStatus!='1'"  class="statusinactive"  >Deactive</span>


                </td>
                <td>
                    
                    <span class="p-column-title">Organization Name:</span>{{product.OrgName}}</td>
                <td>
                    <span class="p-column-title">Division Name:</span>{{product.DivName}}</td>
                <td>
                    <span class="p-column-title">Department Name:</span>{{product.DeptName}}</td>
                <td>    
                    <span class="p-column-title">Hod Name :</span>{{product.HODName}}</td>


            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>



<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<p-dialog [(visible)]="productDialog" [style]="{width: '600px'}" header="Department Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">

<br>

<div class="container">
    <table class="table table-striped">

        <tbody>
            <tr>
                <td><label for="Organization_Name" class="col-form-label">Department Id :</label></td>
                <td> <label for="Organization_Name" class="col-form-label"> {{productsbyid?.DeptID}} </label></td>

            </tr>
            <tr>
                <td><label for="PANCard" class="col-form-label">Department Name:</label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.DeptName}} </label></td>

            </tr>
            <tr>
                <td> <label for="Organization_Name" class="col-form-label">Division Name:</label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.DivName}} </label></td>

            </tr>
            <tr>
                <td><label for="Organization_Name" class="col-form-label">Organizaion Name:</label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.OrgName}} </label></td>

            </tr>
            <tr>
                <td><label for="GSTN" class="col-form-label">HOD Name:</label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.HODName}} </label></td>

            </tr>
            <tr>
                <td><label for="Contact_Number" class="col-form-label">Status:</label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.ActiveStatus}} </label></td>

            </tr>
            <tr>
                <td><label for="Emailid" class="col-form-label">Email </label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.HODEmailID}} </label></td>

            </tr>
            <tr>
                <td><label for="Address" class="col-form-label">Contact No: </label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.HODMobileNo}} </label></td>

            </tr>
            <tr>
                <td><label for="City" class="col-form-label">Created Date: </label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.CreatedDate}} </label></td>

            </tr>
            <tr>
                <td>

                    <label for="City" class="col-form-label">Update Date: </label></td>
                <td>
                    <label for="Organization_Name" class="col-form-label"> {{productsbyid?.UpdatedDate}} </label></td>

            </tr>
            <tr>
                <td>

                    <label for="State" class="col-form-label">Update By: </label></td>
                <td>
                    <label for="Organization_Name" class="col-form-label"> {{productsbyid?.UpdatedBy}} </label></td>

            </tr>

        </tbody>
    </table>
</div>
          
  
        <!-- <div class="form-check-inline">
            <label class="form-check-label">

                Status
            </label>
        </div>
        <div class="form-check-inline">
            <label class="form-check-label"> 
                <input type="radio" class="form-check-input" checked="!checked" name="radio"
                    [(ngModel)]="productsbyid?.ActiveStatus" (change)="statusupdate($event,productsbyid?.DeptID)"
                    [value]="1">active  
            </label>
        </div>
        <div class="form-check-inline">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                    [(ngModel)]="productsbyid?.ActiveStatus" (change)="statusupdate($event,productsbyid?.DeptID)"
                    [value]="0">Deactive
                    
            </label>
        </div> -->
      <!-- <button style="color: white;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                (click)="saveProduct()"></button>  this is unused -->
    
    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
        <span style="float: left;padding-top: 2%;" *ngIf="productsbyid">

            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
    
                    Status  
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff"> 
                <label class="form-check-label">
                    <!-- {{productsbyid?.ActiveStatus}} -->
                    <input type="radio" class="form-check-input" checked="!checked" name="radio"
                        [(ngModel)]="productsbyid.ActiveStatus" (change)="statusupdate($event,productsbyid?.DeptID)"
                        [value]="1">active
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                        [(ngModel)]="productsbyid.ActiveStatus" (change)="statusupdate($event,productsbyid?.DeptID)"
                        [value]="0">Deactive
                </label>
            </div>
    
        </span>
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" style="float: right;"></button>
           
       
       
        <!-- <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button> -->
        
    </ng-template>
</p-dialog>


<p-dialog [(visible)]="successDialog" style="    background: #47c9a2;" header="Message" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Department Deleted Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>


<p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Status Update Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>