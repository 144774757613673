import { Component, OnInit , Inject} from '@angular/core';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer";  

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';


import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
export interface DialogData {


  Service: string;
  Detail: string;
  Backgroundcolor:string;
}
@Component({
  selector: 'app-pocreation',
  templateUrl: './pocreation.component.html',
  styleUrls: ['./pocreation.component.css']
})
export class PocreationComponent implements OnInit { 
 
  productDialog: boolean;

  products: Billdesk[];
  productsbyid: Billdesk[];

  products1: Billdesk[];
  Delete:any;
  data:number=0;

  dialogdata: string;
 

  
  organizationList: Billdesk[];

  product: Billdesk;
  billdesk:any;
  selectedProducts:any;
  insertdata:any;
  updatedata:any;
  submitted: boolean;
//   OrganizationListResult:any[];
  statuses: any[];
  cities: City[];
  customers: Customer[];
  successDialog:boolean;
  insertDialog:boolean;
  representatives: Representative[];
  updateDialog:boolean;
  loading: boolean = true;
  inventoryStatus: Representative[];
  selectedCities: City[];
baseUrl=environment.baseUrl;
pririty:any;

prioritylist:any;
  empList:any[];
//   OrganizationListResult:any;
  constructor(private billdeskService: BilldeskService,private http:HttpClient, private messageService: MessageService, private confirmationService: ConfirmationService,public dialog: MatDialog,private route : Router) { 

      this.cities = [
          {name: 'New York', code: 'NY'},
          {name: 'Rome', code: 'RM'},
          {name: 'London', code: 'LDN'},
          {name: 'Istanbul', code: 'IST'},
          {name: 'Paris', code: 'PRS'}
      ];

  }

  ngOnInit() {


    // for getting Prority

    this.http.get(this.baseUrl + "/priority/prioritylist").subscribe((res: any) => {

      var arr = Object.keys(res.prioritylistResult.dataobject).map(key => ({ label: res.prioritylistResult.dataobject[key].PriorityName, value: res.prioritylistResult.dataobject[key].PriorityName }));

      this.pririty = arr;
    });


    // for getting Prority

    
    this.insertdata=  localStorage.getItem('insertdata');
    
    this.updatedata=  localStorage.getItem('updatedata');
 
    
    if(this.insertdata=='Success'){
    
      this.organizationRegister();
 
    
    } 

    if(this.updatedata=='Success'){
    
      this.organizationUpdate(); 
    } 
 
    
   
  //  var  organizationList  =  this.billdeskService.getOrganization();
 this.billdeskService.getInvoiceDetailForPoCreation().then(res => this.products = res);
 
      this.representatives = [
          { inventoryStatus: "INSTOCK", image: "INSTOCK" },
          { inventoryStatus: "LOWSTOCK", image: "LOWSTOCK" },
          { inventoryStatus: "OUTOFSTOCK", image: "OUTOFSTOCK" },
          
        ];



        this.statuses = [

          { label: "Pending", value: "Pending" },
          { label: "Success", value: "Success" } 

        ];
  }

  openNew() {
      // this.product = {};
      this.submitted = false;
      this.productDialog = true;
  }



  createPobox(){
 
  //  const selecteddata= this.selectedProducts.InvoiceID;
  if(this.selectedProducts === undefined){  
    alert("Please Select Any One");
                                        }


  let total = this.selectedProducts.length;
  this.empList=[];
  for (let i = 0; i < total; i++) { 
    this.empList.push(this.selectedProducts[i].InvoiceID);
  } 

 
var invoiceNos = this.empList.join(","); //"10,9" convert array to string function  
 
    // this.products1 = this.products.filter(val =>this.selectedProducts.includes(val));

    var request = 
    {
      "flag":"",
      "InvoiceID":invoiceNos   
    };
 
        
const datarequest={
  "CheckSum": "",
  "lead_id": "",
  "ActionFor": "Insert",           
"dataobject":[
  
  request

]
 }
;
 
 
 
   
   let url = this.baseUrl+"/pobatchregistration/pobatchregistration";
   this.http.post(url, datarequest).toPromise().then((resp:any)=>{
      
  console.log(resp);
  if(resp.pobatchregistrationResult.Message=='Sucess'){
 
    
 
    this.route.navigate(['/Sheet-A-Month-Wise-PO-Summary'])
 
 localStorage.setItem('insertdata', 'Success');


  }else{

    // console.log('Please Try Again Later');

  }
  
  });
   
  }

  deleteSelectedProducts() {
 

    this.products1 = this.products.filter(val =>this.selectedProducts.includes(val));
 
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete the selected products?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.products = this.products.filter(val => !this.selectedProducts.includes(val));
              this.selectedProducts = null;
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
          }
      });
  }

//   editProduct(product: Billdesk ) {
//       this.Billdesk = {...product};
//       this.productDialog = true;
//   }

  viewProduct(product: Billdesk) {
    // this.product = {...product};
    this.productDialog = true;


this.billdeskService.getOrganizationByid(product.OrgID).then(res => this.productsbyid = res);
}

successButton() {
   
    this.successDialog = true;
}








 
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Invoice", Detail: "Register Successfully",Backgroundcolor: "#47c9a2"}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }


  openDialogUpdate(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Organization", Detail: "Update Successfully" ,Backgroundcolor: "#0288d1"}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }

  
  openDialogDelete(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Organization", Detail: "Delete Successfully" ,Backgroundcolor: "#ff4141"}
    });

    dialogRef.afterClosed().subscribe(result => { 
      window.location.reload();
      this.dialogdata = result;
    });
  }
 


organizationRegister() {
  localStorage.removeItem('insertdata'); 
  // this code for open dialog after 5 second
  setTimeout(() => {
    // this.insertDialog = true;
this.openDialog();

}, 500);
}
organizationUpdate() {
  localStorage.removeItem('updatedata'); 
 // this code for open dialog after 5 second
  setTimeout(() => {
    // this.insertDialog = true;
this.openDialogUpdate();

}, 500);
}
 



statusupdateButton() {
  this.updateDialog = true;
}



  deleteProduct(product: Billdesk) {

    
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + product.OrgID + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {

  
 this.billdeskService.DeleteOrganizationByid(product.OrgID).then(res => this.products = res);
      
            // this.successButton();

            this.openDialogDelete();
        
        }
      });
  }

  hideDialog() {
      this.productDialog = false;
      this.submitted = false;
  }
  
  saveProduct() {
      this.submitted = true;

      if (this.product.name.trim()) {
          if (this.product.id) {
              this.products[this.findIndexById(this.product.id)] = this.product;                
            //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
            this.statusupdateButton();
          }
          else {
              this.product.id = this.createId();
              this.product.image = 'product-placeholder.svg';
              this.products.push(this.product);
            //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
          }

        //   this.products = [...this.products];
          this.productDialog = false;
          // this.product = {};
      }
  }

  findIndexById(id: string): number {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
              index = i;
              break;
          }
      }

      return index;
  }

  createId(): string {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
  }
}
interface City {
  name: string,
  code: string
}



// same sa new page

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}