import { Component, OnInit, ElementRef, VERSION, Inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../../billdesk';
import { BilldeskService } from '../../billdesk.service';

import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InvoiceItem } from '../../invoiceitem';
import { datatable } from '../../model/datatable.model';
import { environment } from '../../../environments/environment';
// import { ToastrService } from 'ngx-toastr';

import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
//import * as $ from "jquery";
declare var $: any;

import { SaleinvoiceEntry, BatchDetail, BoxSaleDetail } from '../../model/coman.model';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
type AOA = any[][];

import * as XLSX from 'xlsx';
@Component({
  selector: 'app-box-stock-out',
  templateUrl: './box-stock-out.component.html',
  styleUrls: ['./box-stock-out.component.css']
})
export class BoxStockOutComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  visibleloader: boolean;
  isDisabled: boolean = false;
  newDynamic:any;
  // VendorDetailId: T = <T>{};
  // interface
  verifiedExcellData=[];
  willDownload = false;
  dialogdata: string;
  VendorDetailId: any;
  datatable = new datatable();
  UserTypeID: any;
  selectedOrgId: string;
  departmentdetailbyselection: any;
  customerList: any;
  answer: any;
  showdetail: boolean;
  DataField: Billdesk[];
  purchasetype: any; 
  selectedStateID:any;
  

  selectedCityID:any; 
  selectedOrgName: string = 'Please Select Organization';

  selectedDivName: string = 'Please Select Division';
  selectedDiv: string;

  selectedDeptID: string;
  selectedDeptName: string = 'Please Select Department';

  ServiceUrl = environment.baseUrl;

  selectedRoleID: string;
  selectedRoleName: string = 'Please Select Role'; 
  VendorShow: boolean;

  sidebarVisible2: boolean;
  organizaionlist: Billdesk[];
  statekey: any;
  stateList: Billdesk[];
  rolelist: Billdesk[];
  departmentlist: Billdesk[];
  divisionlist: Billdesk[];
  cityList: Billdesk[];

  rows: FormArray;
  type: string;

  // stateList:any[];
json
  // cityList:any[];
  selectedCity: string;

  selectedCityName: string = 'Please Select City';
  itemHeadITemlist: any;
  selectedStateName: string = 'Please Select State';
  StateID: number;
  DistrictID: number;
  importExcellData=[];
  
  importExcelObj=[];
  
  importExcelAllDetail:any;
  selectedState: string;
  departmentdetail: any;
  customerDialog: boolean;
  Batchlist = [];
  itemlist: Billdesk[];
  itemcategorylist: Billdesk[];
  selectedItemCategoryID: string;
  selectedItemName = 'Please Select Item';
  selectedItemId: string;
  selectedItemCategoryName = 'Please Select Category';
  venderidsession: any;
  dataArray = [];
  amount_i = 0;
  UserID: any;

  invoiceitem: InvoiceItem[];
  gstdetail: any;

  a: any;
  b: any;
  c: any;
  d: any;
  e: any;
  f: any;
  g: any;

  text = 'default value';
  h: any;
  i: any;
  j: any;
  k: any;
  l: any;
  m: any;
  n: any;
  o: any;
  p: any;
  q: any;
  BoxStockQuantity:any;
  BoxTaxAmount: any;
  cBoxTaxAmount: any;
  sBoxTaxAmount: any;
  totalwithoutgst: any;
  frightcharge: any;
  discount: any;
  Totalwithtax: any;
  receiveamt: any;
  balance: any;
  previousbalance: any;
  currentbalance: any;
  amountInWords: any;
  invdate: any;
  duedate: any;
  discountamt: any;

  invno: any;
  purchasebatchDetail: any;
  BatchNo: any;
  SaleTo: any;

  BoxTotalAmountAfterDiscount: any;
  TDSInPercent: any;
  discountedcost: any;

  TDSInAmount: any;
  ctaxper: any;
  staxper: any;
  amtAfterTds: any;
  TotalPayableAmount: any;

  vendorlist: Billdesk[];
  form: FormGroup;
  addForm: FormGroup;
  OrgID: any;
  Key: any;
  withouttax: any;
  pricetypelist: any;
  VendorName: any;
  VendorBillingAddress: any;
  VendorCity: any;
  VendorPinCode: any;
  GSTN: any;
  VendorState: any;
  VendorEmailID: any;
  VendorMobileNo: any;
  brandlist: any;
  unitlist: any;
  MinWholeBoxSaleQuantity: any;
  vendordetail: any;
  selectedVendorName: string = 'Please Select Vendor';
  selectedCustomerName: string = 'Please Select Customer';
  selectedVendorId: any;
  selectedCustomerId: any;
  UserOrgName: any;
  obj1: any;
  UserDeptName: any;
  StockID: any;
  UserDivName: any;
  DepartmentID: any;
  ProductID: any;

  divisiondetail: any;
  InstitutionID: any;
  OrganizationID: any;


  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  public listObj: DropDownListComponent;

  public height: string = '220px';
  public waterMark: string = 'Select a City';
  public value: string = '3';
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private messageService: MessageService,
    private route: Router,
    private billdeskService: BilldeskService,
    private elementRef: ElementRef,
    public dialog: MatDialog
  ) {
    this.SaleTo = 'Vendor';
    // Current Session   Details
    this.UserOrgName = localStorage.getItem('UserOrgName');

    this.UserDeptName = localStorage.getItem('UserDeptName');

    this.UserDivName = localStorage.getItem('UserDivName');

    this.DepartmentID = localStorage.getItem('DepartmentID');

    this.InstitutionID = localStorage.getItem('InstitutionID');

    this.OrganizationID = localStorage.getItem('OrganizationID');

    // Current Session Details

    this.venderidsession = localStorage.getItem('VendorId');

    console.log('this.venderidsession');
    console.log(this.venderidsession);
    this.addForm = this.fb.group({
      items: [null, Validators.required],
      items_value: ['no', Validators.required],
      VendorID: [null, Validators.required],
    });
    this.billdeskService
      .getOrganizationsLisInvoicetDDL()
      .then((res) => (this.organizaionlist = res));
    this.billdeskService.getState().then((res) => (this.stateList = res));
    this.billdeskService.getUserRole().then((res) => (this.rolelist = res));

   
    this.billdeskService
      .getBoxDLL()
      .then((res: any) => {
        console.log('resitembox',res)
        this.itemcategorylist = res;

        console.log('this is item cateogylist', this.itemcategorylist);
      });

    this.billdeskService
      .getDepartmentByid(this.DepartmentID)
      .then((res) => (this.departmentdetail = res));

    this.billdeskService
      .getBrandListDLL()
      .then((res) => (this.brandlist = res));

    this.billdeskService.getUnitListDLL().then((res) => (this.unitlist = res));

    this.billdeskService
      .getPrice_Type_ListDLL()
      .then((res) => (this.pricetypelist = res));

    this.gstdetail = ['0', '5', '12', '18', '28'];

    this.receiveamt = 0;
    this.discount = 0;
    this.frightcharge = 0;
  }
  ngOnInit() {
    this.form = this.fb.group({
      VendorName: [null, Validators.required],
      GSTN: '',
      CIN: '',

      PANNo: '',

      // ItemCategoryID: [null, Validators.required],
      Website: '',
      SupportUrl: '',

      SupportLoginID: '',

      UserPassword: '',
      UserLoginID: '',
      SupportPassword: '',
      VendorEmailID: [null, Validators.required],
      VendorMobileNo: [null, Validators.required],

      VendorBillingAddress: [null, Validators.required],
      VendorCity: '',
      VendorState: '',

      VendorPinCode: '',
      // VendorUPIID: [null, Validators.required],
      BankAcNo: '',
      BankHolderName: '',
      BankName: '',
      BankBranchName: '',
      IFSCCode: '',
      SwiftCode: '',

      AuthPersonName: '',
      AuthPersonEmailID: '',
      AuthPersonMobileNo: '',
      ActiveStatus: '1',
      IsCustomer: '1',
    });

    this.form.get('VendorMobileNo').valueChanges.subscribe((x) => {
      console.log('value has changed: ', x);
      this.form.patchValue({ UserLoginID: x });
    });
    this.showdetail = false;

    this.VendorShow = false;
    this.answer = 'hello';
    setTimeout(() => {
      console.log('DDDDD', $('.select'));

      $(document).ready(function () {
        $('.select').select2({
          placeholder: 'Select a project...',
        });
      });
    }, 7000);

    this.UserID = localStorage.getItem('UserID');
    if (this.UserTypeID != 5) {
      this.http
        .get(this.ServiceUrl + '/vendor/vendordetail/' + this.UserID)
        .subscribe((response: any) => {
          if (response.vendordetailResult.Status != 400) {
            console.log('responsedetailvenderid', response);
            this.VendorDetailId =
              response.vendordetailResult.dataobject[0].VendorID;

            this.VendorName =
              response.vendordetailResult.dataobject[0].VendorName;
            this.VendorBillingAddress =
              response.vendordetailResult.dataobject[0].VendorBillingAddress;

            this.VendorCity =
              response.vendordetailResult.dataobject[0].VendorCity;
            this.VendorPinCode =
              response.vendordetailResult.dataobject[0].VendorPinCode;
            this.GSTN = response.vendordetailResult.dataobject[0].GSTN;
            this.VendorState =
              response.vendordetailResult.dataobject[0].VendorState;
            this.VendorEmailID =
              response.vendordetailResult.dataobject[0].VendorEmailID;
            this.VendorMobileNo =
              response.vendordetailResult.dataobject[0].VendorMobileNo;
            localStorage.setItem('VendorId', this.VendorDetailId);
          }
        });
    }
    
    this.UserTypeID = localStorage.getItem('UserTypeID'); 
    if (this.UserTypeID == 1) {
      this.VendorShow = false;
      this.billdeskService.getVendorDDLInvoiceList().then((res: any) => {
        this.vendorlist = res;

        console.log(this.vendorlist);
      });
    } else {
      this.VendorShow = true;
    }
    this.getCustomerList();
  }

  getCustomerList(){ 
    const url = '/vendor/customerlistddl';
    this.billdeskService.getList(url).then((res: any) => {
      console.log('rest', res);
      if (res.customerlistddlResult.Message == 'Sucess') {
        this.customerList = res.customerlistddlResult.dataobject; 
      }  
    });
  } 
  dynamicArray: BoxSaleDetail[] = [
    {
      
      BoxSaleDetailID:0,
      BoxID:0,
      BoxSaleQuantity:0,
      BoxSalePrice:0, 
      BoxTaxableAmount:0, 
      BoxTaxAmount:0,
      BoxStockQuantity:0,
      BoxTotalAmount:0,
    },
  ];      

 

  changeVendor(e) { 
      this.selectedVendorName = e.value.VendorName;
      var Userid = e.value.UserID;
      this.selectedVendorId = e.value.VendorID; 
      this.billdeskService.getVendorByid(Userid).then((res: any) => { 
      this.vendordetail = res; 
      this.VendorDetailId = this.vendordetail[0].VendorID;
      this.VendorName = this.vendordetail[0].VendorName;
      this.VendorBillingAddress = this.vendordetail[0].VendorBillingAddress; 
      this.VendorCity = this.vendordetail[0].VendorCity;
      this.VendorPinCode = this.vendordetail[0].dataobject[0].VendorPinCode;
      this.GSTN = this.vendordetail[0].GSTN;
      this.VendorState = this.vendordetail[0].VendorState;
      this.VendorEmailID = this.vendordetail[0].VendorEmailID;
      this.VendorMobileNo = this.vendordetail[0].VendorMobileNo; 
      localStorage.setItem('VendorId', this.VendorDetailId);
    });
  }

  changeCustomer(e) {  
    this.VendorName=''; 
    this.VendorMobileNo='';
    this.VendorEmailID='';
    this.selectedCustomerName = e.value.VendorName;
    var Userid = e.value.UserID;
    console.log('Userid',Userid)
    this.selectedCustomerId = e.value.VendorID; 
    const url="/vendor/vendordetail/";
    console.log('url',url);
    this.billdeskService.getDetail(Userid,url).then((res:any) =>  {  
      console.log('url',res);
       if(res.vendordetailResult.Message=='Sucess'){ 
        alert('tests')
        this.vendordetail = res.vendordetailResult.dataobject[0];
        this.VendorName = this.vendordetail.VendorName;
        this.VendorEmailID = this.vendordetail.VendorEmailID;
        this.VendorMobileNo = this.vendordetail.VendorMobileNo;
        this.VendorDetailId = this.vendordetail.VendorID;
        this.VendorState = this.vendordetail.VendorState;
        this.VendorCity = this.vendordetail.VendorCity;
        localStorage.setItem('VendorId', this.VendorDetailId);
       } 
    });
  }
 

  onSubmit() {
    // this.BoxStockQuantity=100; 
    this.discountamt = 0;
    this.totalwithoutgst = this.dynamicArray.reduce(
      (sum, item) => sum + item.BoxSaleQuantity * item.BoxSalePrice,
      0
    );
    this.h = this.dynamicArray.reduce(
      (sum, item) => sum + Number(item.BoxTaxPercentage),
      0
    );
    this.BoxTaxAmount = this.dynamicArray.reduce(
      (sum, item) => sum + Number(item.BoxTaxAmount),
      0
    );
    this.BoxTaxAmount = this.dynamicArray.reduce(
      (sum, item) => sum + Number(item.BoxTaxAmount),
      0
    );
    this.ctaxper = this.h / 2;
    this.staxper = this.h / 2;

    this.cBoxTaxAmount = this.BoxTaxAmount / 2;
    this.sBoxTaxAmount = this.BoxTaxAmount / 2;

    this.Totalwithtax =
      Number(this.BoxTaxAmount) +
      Number(this.totalwithoutgst) +
      Number(this.frightcharge) -
      Number(this.discount);

    this.balance = Number(this.Totalwithtax) - Number(this.receiveamt);

    this.TotalPayableAmount = this.dynamicArray.reduce(
      (sum, item) => sum + Number(item.BoxTotalAmount),
      0
    );

    this.previousbalance = 0;
    this.currentbalance = Number(this.balance) + Number(this.previousbalance);

    this.discountamt = this.totalwithoutgst * (this.discount / 100);

    this.BoxTotalAmountAfterDiscount = this.TotalPayableAmount - this.discountamt;
    // this.TDSInAmount= this.BoxTotalAmountAfterDiscount*(this.TDSInPercent/100);

    this.TDSInAmount = 0;
    this.TDSInPercent = 0;
    this.amtAfterTds = this.discountamt - this.TDSInAmount;

    this.UserID = localStorage.getItem('UserID');

    this.venderidsession = localStorage.getItem('VendorId');

    // this.TotalPayableAmount =this.amtAfterTds+this.cBoxTaxAmount+this.sBoxTaxAmount;
    console.log('this detail data base 2' + this.dynamicArray);

    if (this.SaleTo == 'Vendor') {
      console.log(this.dynamicArray);

      if (this.invdate == undefined) {
        alert('Please Enter Invoice Date');
      } else if (this.VendorDetailId == undefined) {
        alert('Please Select Vendor From List');
      } else if (this.purchasetype == undefined) {
        alert('Please Select Sale Type');
      } else if (this.VendorDetailId == undefined) {
        alert('Please Select Vendor From List');
      } else {
        this.obj1 = {
          BoxSaleID: 0,
          // "SaleInvoiceDate" : this.invno.toString(),
          BoxSaleInvoiceDate: this.invdate.toString(),
          BoxSaleTo: this.SaleTo,
          BoxToVendorID: this.VendorDetailId,
          BoxToOrgID: '0',
          BoxToDivID: '0',
          BoxToDeptID: '0',
          BoxFromOrgID: this.OrganizationID,
          BoxFromDivID: this.InstitutionID,
          BoxFromDeptID: this.DepartmentID,
          BoxTaxableAmountGrandTotal: this.totalwithoutgst.toString(),
          BoxTaxAmountGrandTotal: this.BoxTaxAmount.toString(),
          BoxGrandTotal: this.TotalPayableAmount.toString(),
          BoxDiscountAmount: this.discountamt.toString(),
          BoxDiscountPercent: this.discount.toString(),
          BoxTDSAmount: this.TDSInAmount.toString(),
          BoxTDSInPercent: this.TDSInPercent.toString(),
          BoxFrightChargeAmount: this.frightcharge.toString(),
          BoxFrightChargePercentage: this.frightcharge.toString(),
          BoxRoundOff: '0',
          BoxPayableAmount: this.TotalPayableAmount.toString(),
          BoxReceivedAmount: '0', 
          BoxBalanceAmount: this.TotalPayableAmount.toString(),
          BoxSaleType: Number(this.purchasetype),
          BoxSaleDetailJson: this.dynamicArray.map((d: any) => {
            d['BoxSaleDetailID'] = 0;
            d['BoxID'] = parseInt(d.BoxID);
            d['BoxSaleQuantity'] = parseInt(d.BoxSaleQuantity);
            d['BoxSalePrice'] = parseInt(d.BoxSalePrice);
            d['BoxTaxableAmount'] =d.BoxTaxableAmount;
            d['BoxTaxPercentage'] = d.BoxTaxPercentage.toString();
            d['BoxTaxAmount'] = d.BoxTaxAmount.toString();
            d['BoxTotalAmount'] = d.BoxTotalAmount.toString();
            
            return d;
          }),
        };
      }

      console.log('VendorsDetail' + this.obj1);
    }
    if (this.SaleTo == 'Customer') {
      console.log(this.dynamicArray);
      if (this.invdate == undefined) {
        alert('Please Enter Invoice Date');
      } else if (this.VendorDetailId == undefined) {
        alert('Please Select Vendor From List');
      } else if (this.purchasetype == undefined) {
        alert('Please Select Sale Type');
      } else if (this.VendorDetailId == undefined) {
        alert('Please Select Vendor From List');
      } else {
        this.obj1 = {
          SaleID: 0,
          // "SaleInvoiceDate" : this.invno.toString(),
          SaleInvoiceDate: this.invdate.toString(),
          SaleTo: 'Vendor',
          ToVendorID: this.VendorDetailId,
          ToOrgID: '0',
          ToDivID: '0',
          ToDeptID: '0',
          FromOrgID: this.OrganizationID,
          FromDivID: this.InstitutionID,
          FromDeptID: this.DepartmentID,
          BoxTaxableAmountGrandTotal: this.totalwithoutgst.toString(),
          BoxTaxAmountGrandTotal: this.BoxTaxAmount.toString(),
          GrandTotal: this.TotalPayableAmount.toString(),
          DiscountAmount: this.discountamt.toString(),
          DiscountPercent: this.discount.toString(),
          TDSAmount: this.TDSInAmount.toString(),
          TDSInPercent: this.TDSInPercent.toString(),
          FrightChargeAmount: this.frightcharge.toString(),
          FrightChargePercentage: this.frightcharge.toString(),
          RoundOff: '0',
          PayableAmount: this.TotalPayableAmount.toString(),
          ReceivedAmount: '0',
          // "BoxTotalAmountAfterDiscount": this.BoxTotalAmountAfterDiscount.toString(),
          BalanceAmount: this.TotalPayableAmount.toString(),
          SaleType: Number(this.purchasetype),
          SaleDetailJson: this.dynamicArray.map((d: any) => {
            d['ProductID'] = parseInt(d.ProductID);
            d['BrandID'] = parseInt(d.BrandID);
            d['StockID'] = parseInt(d.StockID);
            d['SaleUnit'] = parseInt(d.SaleUnit);
            d['BoxSalePriceType'] = parseInt(d.BoxSalePriceType);
            d['BoxStockQuantity'] = d.BoxStockQuantity.toString();
            d['BoxSaleQuantity'] = d.BoxSaleQuantity.toString();
            d['MRP'] = d.MRP.toString();
            d['WholeBoxSalePrice'] = d.WholeBoxSalePrice.toString();
            d['BoxTaxableAmount'] = d.BoxTaxableAmount.toString();
            d['BoxTaxPercentage'] = d.BoxTaxPercentage.toString();
            d['BoxTaxAmount'] = d.BoxTaxAmount.toString();
            d['BoxTotalAmount'] = d.BoxTotalAmount.toString();
            return d;
          }),
        };
      }

      console.log('VendorsDetail' + this.obj1);
    } 
    if (this.SaleTo == 'Organization') {
      if (this.invdate == undefined) {
        alert('Please Enter Invoice Date');
      } else if (this.purchasetype == undefined) {
        alert('Please Select Sale Type');
      } else if (this.selectedOrgId == undefined) {
        alert('Please Select Organization From ');
      } else if (this.selectedDiv == undefined) {
        alert('Please Select Division From List');
      } else if (this.selectedDeptID == undefined) {
        alert('Please Select Department From List');
      } else {
        this.obj1 = {
          SaleID: 0,
          // "SaleInvoiceDate" : this.invno.toString(),
          SaleTo: this.SaleTo,
          SaleInvoiceDate: this.invdate.toString(),

          ToVendorID: '0',
          ToOrgID: this.selectedOrgId,
          ToDivID: this.selectedDiv,
          ToDeptID: this.selectedDeptID,

          FromOrgID: this.OrganizationID,
          FromDivID: this.InstitutionID,
          FromDeptID: this.DepartmentID,

          BoxTaxableAmountGrandTotal: this.totalwithoutgst.toString(),
          BoxTaxAmountGrandTotal: this.BoxTaxAmount.toString(),
          GrandTotal: this.TotalPayableAmount.toString(),
          DiscountAmount: this.discountamt.toString(),
          DiscountPercent: this.discount.toString(),
          TDSAmount: this.TDSInAmount.toString(),
          TDSInPercent: this.TDSInPercent.toString(),
          FrightChargeAmount: this.frightcharge.toString(),
          FrightChargePercentage: this.frightcharge.toString(),
          RoundOff: '0',
          PayableAmount: this.TotalPayableAmount.toString(),
          ReceivedAmount: '0',
          // "BoxTotalAmountAfterDiscount": this.BoxTotalAmountAfterDiscount.toString(),
          BalanceAmount: this.TotalPayableAmount.toString(),
          SaleType: Number(this.purchasetype),
          SaleDetailJson: this.dynamicArray.map((d: any) => {
            d['ProductID'] = parseInt(d.ProductID);
            d['BrandID'] = parseInt(d.BrandID);
            d['StockID'] = parseInt(d.StockID);
            d['SaleUnit'] = parseInt(d.SaleUnit);
            d['BoxSalePriceType'] = parseInt(d.BoxSalePriceType);
            d['BoxStockQuantity'] = d.BoxStockQuantity.toString();
            d['BoxSaleQuantity'] = d.BoxSaleQuantity.toString();
            d['MRP'] = d.MRP.toString();
            d['WholeBoxSalePrice'] = d.WholeBoxSalePrice.toString();
            d['BoxTaxableAmount'] = d.BoxTaxableAmount.toString();
            d['BoxTaxPercentage'] = d.BoxTaxPercentage.toString();
            d['BoxTaxAmount'] = d.BoxTaxAmount.toString();
            d['BoxTotalAmount'] = d.BoxTotalAmount.toString();
            return d;
          }),
        };
      }
    }

    if (this.obj1 != null) {
      this.visibleloader = true;
      this.isDisabled = true; 
      const datarequest = {
        CheckSum: '',
        lead_id: '',
        ActionFor: 'Insert',
        dataobject: [this.obj1],
      };

      console.log(datarequest);
      let registerurl = '/boxsale/save';
      this.billdeskService
        .RegisterData(registerurl, datarequest)
        .subscribe((res: any) => {
          console.log('res');
          console.log(res);
          console.log('res');
          if (res.boxsaleregistrationResult.Message == 'Sucess') {
            this.visibleloader = false;
            this.route.navigate(['/box-stock-in']);
            localStorage.setItem('insertdata', 'Success');
          } else {
            // console.log('Please Try Again Later');
          }
        });
    }
  }

  onSubmitExcel() { 
    this.discountamt = 0;
    this.totalwithoutgst = this.dynamicArray.reduce(
      (sum, item) => sum + item.BoxSaleQuantity * item.BoxSalePrice,
      0
    );
    this.h = this.dynamicArray.reduce(
      (sum, item) => sum + Number(item.BoxTaxPercentage),
      0
    );
    this.BoxTaxAmount = this.dynamicArray.reduce(
      (sum, item) => sum + Number(item.BoxTaxAmount),
      0
    );
    this.BoxTaxAmount = this.dynamicArray.reduce(
      (sum, item) => sum + Number(item.BoxTaxAmount),
      0
    );
    this.ctaxper = this.h / 2;
    this.staxper = this.h / 2;

    this.cBoxTaxAmount = this.BoxTaxAmount / 2;
    this.sBoxTaxAmount = this.BoxTaxAmount / 2;

    this.Totalwithtax =
      Number(this.BoxTaxAmount) +
      Number(this.totalwithoutgst) +
      Number(this.frightcharge) -
      Number(this.discount);

    this.balance = Number(this.Totalwithtax) - Number(this.receiveamt);

    this.TotalPayableAmount = this.dynamicArray.reduce(
      (sum, item) => sum + Number(item.BoxTotalAmount),
      0
    );

    this.previousbalance = 0;
    this.currentbalance = Number(this.balance) + Number(this.previousbalance);

    this.discountamt = this.totalwithoutgst * (this.discount / 100);

    this.BoxTotalAmountAfterDiscount = this.TotalPayableAmount - this.discountamt; 
    this.TDSInAmount = 0;
    this.TDSInPercent = 0;
    this.amtAfterTds = this.discountamt - this.TDSInAmount;

    this.UserID = localStorage.getItem('UserID');

    this.venderidsession = localStorage.getItem('VendorId');

    // this.TotalPayableAmount =this.amtAfterTds+this.cBoxTaxAmount+this.sBoxTaxAmount;
    console.log('this detail data base 2' + this.dynamicArray);

    if (this.SaleTo == 'Vendor') {
      console.log('this.SaleTo')
      console.log(this.dynamicArray);

      if (this.invdate == undefined) {
        alert('Please Enter Invoice Date');
      } else if (this.VendorDetailId == undefined) {
        alert('Please Select Vendor From List');
      } else if (this.purchasetype == undefined) {
        alert('Please Select Sale Type');
      } else if (this.VendorDetailId == undefined) {
        alert('Please Select Vendor From List');
      } else {
 
        this.verifiedExcellData.forEach((item, index) => {
          var obj;
          if(item.BoxID>0 && item.BoxAvailableOrNot=='Available'){
            let totalTaxableAmount=item.BoxSaleQuantity*item.BoxSalePrice;
            let taxAmount=totalTaxableAmount*(item.BoxTaxPercentage/100);
            let totalAmount=totalTaxableAmount+taxAmount;
            obj = {
              BoxSaleDetailID:0,
              BoxID: item.BoxID,
              BoxSaleQuantity:item.BoxSaleQuantity, 
              BoxSalePrice:item.BoxSalePrice , 
              BoxTaxableAmount:totalTaxableAmount,//calculate internaly
              BoxTaxPercentage: item.BoxTaxPercentage,
              BoxTaxAmount:taxAmount, //calculate internaly
              BoxTotalAmount:totalAmount  //calculate internaly
            };
            this.importExcelObj.push(obj);
          } 
         });
      
        this.obj1 = {
          BoxSaleID: 0,
          // "SaleInvoiceDate" : this.invno.toString(),
          BoxSaleInvoiceDate: this.invdate.toString(),
          BoxSaleTo: this.SaleTo,
          BoxToVendorID: this.VendorDetailId,
          BoxToOrgID: '0',
          BoxToDivID: '0',
          BoxToDeptID: '0',
          BoxFromOrgID: this.OrganizationID,
          BoxFromDivID: this.InstitutionID,
          BoxFromDeptID: this.DepartmentID,
          BoxTaxableAmountGrandTotal: this.totalwithoutgst.toString(),
          BoxTaxAmountGrandTotal: this.BoxTaxAmount.toString(),
          BoxGrandTotal: this.TotalPayableAmount.toString(),
          BoxDiscountAmount: this.discountamt.toString(),
          BoxDiscountPercent: this.discount.toString(),
          BoxTDSAmount: this.TDSInAmount.toString(),
          BoxTDSInPercent: this.TDSInPercent.toString(),
          BoxFrightChargeAmount: this.frightcharge.toString(),
          BoxFrightChargePercentage: this.frightcharge.toString(),
          BoxRoundOff: '0',
          BoxPayableAmount: this.TotalPayableAmount.toString(),
          BoxReceivedAmount: '0', 
          BoxBalanceAmount: this.TotalPayableAmount.toString(),
          BoxSaleType: Number(this.purchasetype),
          BoxSaleDetailJson:  this.importExcelObj ,
        };
      }

      
    }
    console.log('VendorsDetailDeta' + this.obj1);
    if (this.SaleTo == 'Customer') {
      console.log(this.dynamicArray);
      if (this.invdate == undefined) {
        alert('Please Enter Invoice Date');
      } else if (this.VendorDetailId == undefined) {
        alert('Please Select Vendor From List');
      } else if (this.purchasetype == undefined) {
        alert('Please Select Sale Type');
      } else if (this.VendorDetailId == undefined) {
        alert('Please Select Vendor From List');
      } else {
        this.obj1 = {
          SaleID: 0,
          // "SaleInvoiceDate" : this.invno.toString(),
          SaleInvoiceDate: this.invdate.toString(),
          SaleTo: 'Vendor',
          ToVendorID: this.VendorDetailId,
          ToOrgID: '0',
          ToDivID: '0',
          ToDeptID: '0',
          FromOrgID: this.OrganizationID,
          FromDivID: this.InstitutionID,
          FromDeptID: this.DepartmentID,
          BoxTaxableAmountGrandTotal: this.totalwithoutgst.toString(),
          BoxTaxAmountGrandTotal: this.BoxTaxAmount.toString(),
          GrandTotal: this.TotalPayableAmount.toString(),
          DiscountAmount: this.discountamt.toString(),
          DiscountPercent: this.discount.toString(),
          TDSAmount: this.TDSInAmount.toString(),
          TDSInPercent: this.TDSInPercent.toString(),
          FrightChargeAmount: this.frightcharge.toString(),
          FrightChargePercentage: this.frightcharge.toString(),
          RoundOff: '0',
          PayableAmount: this.TotalPayableAmount.toString(),
          ReceivedAmount: '0',
          // "BoxTotalAmountAfterDiscount": this.BoxTotalAmountAfterDiscount.toString(),
          BalanceAmount: this.TotalPayableAmount.toString(),
          SaleType: Number(this.purchasetype),
          SaleDetailJson: this.dynamicArray.map((d: any) => {
            d['ProductID'] = parseInt(d.ProductID);
            d['BrandID'] = parseInt(d.BrandID);
            d['StockID'] = parseInt(d.StockID);
            d['SaleUnit'] = parseInt(d.SaleUnit);
            d['BoxSalePriceType'] = parseInt(d.BoxSalePriceType);
            d['BoxStockQuantity'] = d.BoxStockQuantity.toString();
            d['BoxSaleQuantity'] = d.BoxSaleQuantity.toString();
            d['MRP'] = d.MRP.toString();
            d['WholeBoxSalePrice'] = d.WholeBoxSalePrice.toString();
            d['BoxTaxableAmount'] = d.BoxTaxableAmount.toString();
            d['BoxTaxPercentage'] = d.BoxTaxPercentage.toString();
            d['BoxTaxAmount'] = d.BoxTaxAmount.toString();
            d['BoxTotalAmount'] = d.BoxTotalAmount.toString();
            return d;
          }),
        };
      }

      console.log('VendorsDetail' + this.obj1);
    } 
    if (this.SaleTo == 'Organization') {
      if (this.invdate == undefined) {
        alert('Please Enter Invoice Date');
      } else if (this.purchasetype == undefined) {
        alert('Please Select Sale Type');
      } else if (this.selectedOrgId == undefined) {
        alert('Please Select Organization From ');
      } else if (this.selectedDiv == undefined) {
        alert('Please Select Division From List');
      } else if (this.selectedDeptID == undefined) {
        alert('Please Select Department From List');
      } else {
        this.obj1 = {
          SaleID: 0,
          // "SaleInvoiceDate" : this.invno.toString(),
          SaleTo: this.SaleTo,
          SaleInvoiceDate: this.invdate.toString(),

          ToVendorID: '0',
          ToOrgID: this.selectedOrgId,
          ToDivID: this.selectedDiv,
          ToDeptID: this.selectedDeptID,

          FromOrgID: this.OrganizationID,
          FromDivID: this.InstitutionID,
          FromDeptID: this.DepartmentID,

          BoxTaxableAmountGrandTotal: this.totalwithoutgst.toString(),
          BoxTaxAmountGrandTotal: this.BoxTaxAmount.toString(),
          GrandTotal: this.TotalPayableAmount.toString(),
          DiscountAmount: this.discountamt.toString(),
          DiscountPercent: this.discount.toString(),
          TDSAmount: this.TDSInAmount.toString(),
          TDSInPercent: this.TDSInPercent.toString(),
          FrightChargeAmount: this.frightcharge.toString(),
          FrightChargePercentage: this.frightcharge.toString(),
          RoundOff: '0',
          PayableAmount: this.TotalPayableAmount.toString(),
          ReceivedAmount: '0',
          // "BoxTotalAmountAfterDiscount": this.BoxTotalAmountAfterDiscount.toString(),
          BalanceAmount: this.TotalPayableAmount.toString(),
          SaleType: Number(this.purchasetype),
          SaleDetailJson: this.dynamicArray.map((d: any) => {
            d['ProductID'] = parseInt(d.ProductID);
            d['BrandID'] = parseInt(d.BrandID);
            d['StockID'] = parseInt(d.StockID);
            d['SaleUnit'] = parseInt(d.SaleUnit);
            d['BoxSalePriceType'] = parseInt(d.BoxSalePriceType);
            d['BoxStockQuantity'] = d.BoxStockQuantity.toString();
            d['BoxSaleQuantity'] = d.BoxSaleQuantity.toString();
            d['MRP'] = d.MRP.toString();
            d['WholeBoxSalePrice'] = d.WholeBoxSalePrice.toString();
            d['BoxTaxableAmount'] = d.BoxTaxableAmount.toString();
            d['BoxTaxPercentage'] = d.BoxTaxPercentage.toString();
            d['BoxTaxAmount'] = d.BoxTaxAmount.toString();
            d['BoxTotalAmount'] = d.BoxTotalAmount.toString();
            return d;
          }),
        };
      }
    }

    if (this.obj1 != null) {
      this.visibleloader = true;
      this.isDisabled = true; 
      const datarequest = {
        CheckSum: '',
        lead_id: '',
        ActionFor: 'Insert',
        dataobject: [this.obj1],
      };

      console.log(datarequest);
      let registerurl = '/boxsale/save';
      this.billdeskService
        .RegisterData(registerurl, datarequest)
        .subscribe((res: any) => {
          console.log('res');
          console.log(res);
          console.log('res');
          if (res.boxsaleregistrationResult.Message == 'Sucess') {
            this.visibleloader = false;
            this.route.navigate(['/box-stock-in']);
            localStorage.setItem('insertdata', 'Success');
          } else {
            // console.log('Please Try Again Later');
          }
        });
    }
  }

  addRow(i) {
    console.log('testdetial', this.dynamicArray);

    console.log('testdetial base', this.dynamicArray);
    this.dynamicArray.push({
      "BoxSaleDetailID" : 0,
      "BoxID" : 0,
      "BoxSaleQuantity" : 0,
      "BoxSalePrice" : 0,
      "BoxTaxableAmount" : 0,
      "BoxTotalAmount" : 0,
      "BoxTaxAmount" :0,
      "BoxStockQuantity":0 

    });

    console.log('console.detail', this.dynamicArray);
  }

  AddItem() {
    // this.sidebarVisible2 = true;
  }

  deleteRow(index) {
    // this.dynamicArray.splice(index, 1);

    if (this.dynamicArray.length == 1) {
      // this.toastr.error("Can't delete the row when there is only one row", 'Warning');
      alert('Cant Not Delete');
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      // this.toastr.warning('Row deleted successfully', 'Delete row');
      alert(' Deleted Row');
      return true;
    }
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  getDivisionByOrg(e) {
    this.selectedOrgId = e.value.OrgID;
    this.selectedOrgName = e.value.OrgName;
    this.selectedDivName = 'Please Select Division';
    this.selectedDeptName = 'Please Select Department';
    this.billdeskService
      .getDivisionByOrgListForInvoice(e.value.OrgID)
      .then((res) => (this.divisionlist = res));
  }

  changeDivision(e) {
    this.selectedDivName = e.value.DivName;

    this.selectedDiv = e.value.DivID;
    const orgId = e.value.OrgID;
    const divId = e.value.DivID;
    this.selectedDeptName = 'Please Select Department';
    this.billdeskService
      .getDepartmentlistforinvoice(orgId, divId)
      .then((res) => (this.departmentlist = res));

    this.billdeskService
      .getDivisionByid(divId)
      .then((res) => (this.divisiondetail = res));
  }

  changeDepartment(e) {
    this.selectedDeptName = e.value.DeptName;
    var DeptID = e.value.DeptID;
    this.selectedDeptName;
    this.selectedDeptID = e.value.DeptID;
    this.billdeskService
      .getDepartmentByid(DeptID)
      .then((res) => (this.departmentdetailbyselection = res));
  }

  getValue(rate: any, qty: any, taxper: any, i: any) {
    this.withouttax = rate * qty;

    this.dynamicArray[i].BoxTaxAmount = (taxper / 100) * qty * rate;
    this.dynamicArray[i].BoxTotalAmount =
      rate * qty + this.dynamicArray[i].BoxTaxAmount;

    this.dynamicArray[i].BoxTaxableAmount = rate * qty;
  }

  getValueform( qty: any, mrp: any, sale: any, wholesale: any, BoxSalePrice: any, saletype: any, taxper: any, i: any ) {
    this.dynamicArray[i].BoxTaxableAmount = BoxSalePrice * qty;
    this.dynamicArray[i].BoxTaxAmount = (taxper / 100) * qty * BoxSalePrice;
    this.dynamicArray[i].BoxTotalAmount =
      BoxSalePrice * qty + this.dynamicArray[i].BoxTaxAmount;
  }

  visibleIndex = -1;
  showmoredetail(ind) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
  }


  
  changeBoxSaleQuantity(e, i) {
    var stockqty = parseInt(this.dynamicArray[i].BoxStockQuantity);
    if (parseInt(e) > stockqty) {
      alert(
        'Available Quantity is : ' +
          this.dynamicArray[i].BoxStockQuantity +
          ' Please Enter  Valid Quantity of Sale'
      );
      this.dynamicArray[i].BoxSaleQuantity = '';
    }

    console.log('this is BoxTaxPercentage', this.dynamicArray[i].BoxTaxPercentage);
    console.log('this is sale qty', this.dynamicArray[i].BoxSaleQuantity);
    console.log('this is Sale Price  ', this.dynamicArray[i].BoxSalePrice);

    var BoxTaxPercentage =
      this.dynamicArray[i].BoxSaleQuantity *
      this.dynamicArray[i].BoxSalePrice *
      (this.dynamicArray[i].BoxTaxPercentage / 100);
    // taxable amonut for view
    this.dynamicArray[i].BoxTaxableAmount =
      this.dynamicArray[i].BoxSaleQuantity * this.dynamicArray[i].BoxSalePrice;
    // tax amount for view
    this.dynamicArray[i].BoxTaxAmount = BoxTaxPercentage;

    // this is BoxTotalAmount included tax
    this.dynamicArray[i].BoxTotalAmount =
      BoxTaxPercentage +
      this.dynamicArray[i].BoxSaleQuantity * this.dynamicArray[i].BoxSalePrice;
    console.log('this is BoxTotalAmount', this.dynamicArray[i].BoxTotalAmount);
  }

  CustomerDialogButton() {
    this.customerDialog = true;
  }

  onCustomerRegister() {
    var obj1 = {
      flag: '',
      CreatedDate: '',
      CreatedByID: '2',
      CreatedBy: '5',
      RoleTypeID: '4',
      UpdatedDate: '',
      UpdatedBy: '',
      ItemCategoryID: '',
      IsMobileVerified: '',
      VerificationMobileOTP: '',
      IsEmailVerified: '',
      VerificationEmailOT: '',
      VerificationEmailUR: '',
      VendorUPIID: '',
    };
    var obj2 = this.form.value;
    var form_data = Object.assign(obj1, obj2);
    const datarequest = {
      CheckSum: '',
      lead_id: '',
      ActionFor: 'Insert',
      dataobject: [form_data],
    };

    console.log('ddd+', datarequest);
    console.log('validation', this.form.valid);
    console.log('validation', this.form);
    if (this.form.valid) {
      let registerurl = '/vendor/vendorregistration';
      this.billdeskService
        .RegisterData(registerurl, datarequest)
        .subscribe((res: any) => {
          if (res.vendorregistrationResult.Message == 'Sucess') {
            this.route.navigate(['/sales_entry']);
            this.customerDialog = false;
            this.openCustomerDialog();
            this.getCustomerList();
          }
          if (res.vendorregistrationResult.Message == 'Login ID Already Exist'){
            this.route.navigate(['/sales_entr']);
            localStorage.setItem('exist', 'exist');
          }
        });
    } else {
      // this.validateAllFormFields(this.form);
    }
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field),
    };
  }

  openCustomerDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {
        Service: 'Customer',
        Detail: 'Registered Successfully',
        Backgroundcolor: '#47c9a2',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.dialogdata = result;
      console.log('this.dialogdata');
      console.log(this.dialogdata);
    });
  }

  //  for get Box detail
  getBoxDetail(event:any){
console.log('eventbox',event);


let text1 = event.target.options[event.target.options.selectedIndex].text; 

console.log('ibox',text1);
 

// let text1 = event.target?.options[event.target?.options.selectedIndex].text; 
// console.log('text1',text1)
  }
  // eof getBoxDetail
  // excell management
  // onFileChange(evt: any) {
  //   /* wire up file reader */
  //   const target: DataTransfer = <DataTransfer>(evt.target);
  //   if (target.files.length !== 1) throw new Error('Cannot use multiple files');
  //   const reader: FileReader = new FileReader();
  //   reader.onload = (e: any) => {
  //     /* read workbook */
  //     const bstr: string = e.target.result;
  //     const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

  //     /* grab first sheet */
  //     const wsname: string = wb.SheetNames[0];
  //     const ws: XLSX.WorkSheet = wb.Sheets[wsname];

  //     /* save data */
  //     this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
  //     console.log("data:",this.data);
  //     this.data.map(res=>{
  //       if(res[0] === "no"){
  //         console.log(res[0]);
  //       }else{
  //         console.log(res[0]);
  //       }
  //     })
  //   };
  //   reader.readAsBinaryString(target.files[0]);
  // }

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});


    
      const dataString = JSON.stringify(jsonData);
 
  console.log('jsonData',jsonData);
this.importExcelAllDetail=jsonData;

  jsonData.dataobject.forEach((item, index) => {
    var obj;
    obj = {
      BoxName: item.BoxName,
      BoxSaleQuantity: item.BoxSaleQuantity,
      BoxSalePrice:item.BoxSalePrice, 
      BoxTaxPercentage:item.BoxTaxPercentage  
    };
    this.importExcellData.push(obj);
  });


  const datarequest = { 
    dataobject: this.importExcellData,
  };
  console.log(JSON.stringify(datarequest)); 
        this.visibleloader = true;
        this.isDisabled = true;  
        let registerurl = '/boxsale/checkexcelsave';
        this.billdeskService
          .RegisterData(registerurl, datarequest)
          .subscribe((res: any) => {
            console.log('res');
            console.log(res);
            console.log('res');
            if (res.checkexcelboxsaleResult.Message == 'Sucess') {
              this.visibleloader = false;
              this.verifiedExcellData=res.checkexcelboxsaleResult.dataobject;
            } else {
              // console.log('Please Try Again Later');
            }
          });
 
      document.getElementById('output').innerHTML = dataString;
      this.setDownload(dataString);
    }
    reader.readAsBinaryString(file);



  }




  
  setDownload(data) {
    this.willDownload = true;
    setTimeout(() => {
      const el = document.querySelector("#download");
      el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
      el.setAttribute("download", 'xlsxtojson.json');
    }, 1000)
  }



  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  // eof excell management

  changeState(e) {
    this.selectedStateName = e.value.StateName;
    this.selectedStateID=e.value.StateID; 
    this.billdeskService.getCity(e.value.StateID).then(res => this.cityList = res);
    }
  
    
    changeCity(e){
      this.selectedCityName = e.value.DistrictName;
      this.selectedCityID=e.value.DistrictID;
     }


}
// for open dialog box
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor: string;
}
