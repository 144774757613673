<div class="card" style=" overflow-x: scroll;">


    <div class="p-d-flex p-ai-center p-jc-between">
        <h1 class="headingclass">
            STOCK LIST BY DATE</h1>
     
    </div>

    
    <p-table #dt [value]="products" [rows]="10" [paginator]="true"
        [globalFilterFields]="['OrgName','GSTN','StateName','DistrictName','DeskMobileNo','DeskEmailID','AuthPersonName','AuthPersonMobileNo']"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"  styleClass="p-datatable-striped" >
        <!-- <p-table #dt [value]="products" [(selection)]="selectedProducts" dataKey="id" styleClass="p-datatable-customers" [rowHover]="true"
   
              [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
           [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [filterDelay]="0" [globalFilterFields]="['name','country.name','representative.name','status']"> -->

        <!-- <p-table [value]="products" [tableStyle]="{'min-width': '50rem'}"> -->
 




        <ng-template pTemplate="caption">
            <form [formGroup]="paymentform" (ngSubmit)="GetStockSummary()">
                <div class="row">


                    <div class="position-relative  form-group col-sm-3">
                        <label for="InvoiceMonth" class="col-form-label">From Date
                            
                 
                            
                            
                            <span>*</span></label>
                        <div class="">
                            <input type="date" class="form-control" formControlName="fromdate" />
                        </div>
                    </div>


                    <div class="position-relative  form-group col-sm-3">
                        <label for="InvoiceYear" class="col-form-label ">To Date<span>*</span></label>
                        <div class="">


                            <input type="date" class="form-control" formControlName="todate" />
                        </div>
                    </div>

                    <div class="position-relative  form-group col-sm-3"> <label for="InvoiceYear"
                            class="col-form-label">&nbsp;</label>

                        <div class="">
                            <button type="submit" class="btn btn-success" pButton pRipple>Submit</button>
                        </div>

                    </div>

                    <div class="position-relative  form-group col-sm-3">
                        
                        <div class="flex">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                                 class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                             <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()"
                                 class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                        </div>
              
                    </div>
                </div>
            </form>
            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->

        </ng-template>
        
        <ng-template pTemplate="header">



            <tr class="padding-head">
              
                <th pSortableColumn="PurchaseID"> Product ID <p-sortIcon field="PurchaseID"></p-sortIcon></th>

                <th pSortableColumn="ProductName"> Product Name.<p-sortIcon field="ProductName"></p-sortIcon></th>

                <th pSortableColumn="PurchaseQuantity">Purchase Qty. <p-sortIcon field="PurchaseQuantity"></p-sortIcon></th>
                
                <th pSortableColumn="PurchaseAmount">Purchase Amt. <p-sortIcon field="PurchaseAmount"></p-sortIcon></th>

                <th pSortableColumn="SaleQuantity">Sale Qty. <p-sortIcon field="SaleQuantity"></p-sortIcon></th>
                
                <th pSortableColumn="SaleAmount">Sale Amt. <p-sortIcon field="SaleQuantity"></p-sortIcon></th>
           
                <th pSortableColumn="InStockQuantity">InStock <p-sortIcon field="InStockQuantity"></p-sortIcon></th>
                <th pSortableColumn="PurchaseInvoiceDate"> Purchase Invoice Date<p-sortIcon
                        field="PurchaseInvoiceDate"></p-sortIcon></th>

            </tr>
            <tr class="padding-head">

                 

                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'ProductID' ,'contains')"
                        placeholder="Search Product ID " />
                </th>


                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'ProductName' ,'contains')"
                        placeholder="Search" class="fullwidth" />
                </th>
                <th>

                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'PurchaseQuantity' ,'contains')" placeholder="Search"
                        class="fullwidth" />
                </th>



                <th>

                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'PurchaseAmount' ,'contains')" placeholder="Search"
                        class="fullwidth" />
                </th>




                
                <th>


                    <input pInputText type="text" (input)="dt.filter($event.target.value,'SaleQuantity' ,'contains')"
                        placeholder="Search " class="fullwidth" />

  </th> 


  <th>

    <input pInputText type="text"
        (input)="dt.filter($event.target.value,'SaleAmount' ,'contains')" placeholder="Search"
        class="fullwidth" />
</th>

                <th>


                    <input pInputText type="text" (input)="dt.filter($event.target.value,'InStockQuantity' ,'contains')"
                        placeholder="Search " class="fullwidth" />







                </th>
                <th>


                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'PurchaseInvoiceDate' ,'contains')" placeholder="Search "
                        class="fullwidth" />

                </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-index="rowIndex" let-product>
            <tr class="responsivecss">
 
                <td>

                    <span class="p-column-title">Product ID:</span>
                    {{product.ProductID}}
                </td>

                <td>

                    <span class="p-column-title">Product Name:</span>
                    {{product.ProductName}}
                </td>

                <td>
                    <span class="p-column-title">Purchase Qty:</span>
                    {{product.PurchaseQuantity}}
                </td>


                <td>
                    <span class="p-column-title">Purchase Amount:</span>
                    {{product.PurchaseAmount}}
                </td>



                <td>

                    <span class="p-column-title">Sale Qty:</span>
                    {{product.SaleQuantity}}
                </td>

                
                <td>

                    <span class="p-column-title">Sale Amount:</span>
                    {{product.SaleAmount}}
                </td>

                <td>

                    <span class="p-column-title">InStock:</span>
                  

                    <span *ngIf="product.InStockQuantity<10 else greaterthan" style="color:red;">
                             {{product.InStockQuantity}}
                     </span>
              
                    <ng-template #greaterthan >

                        <span   style="color:green;">
                            {{product.InStockQuantity}}
                        </span> 
                  </ng-template>
                </td>
                <td>

                    <span class="p-column-title">Purchase Invoice Date:</span>
                    {{product.PurchaseInvoiceDate}}
                </td>





            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '800px'}" header="Organization Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <br>
        <div class="container">
            <table class="table table-striped">

                <tbody>
                    <tr>
                        <td>
                            <label for="Organization_Name" class="col-form-label">Organization Id :</label>
                        </td>
                        <td>
                            <label for="OrgID" class="col-form-label"> {{productsbyid[0].OrgID}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="Organization_Name" class="col-form-label">Organization Name:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].OrgName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="PANCard" class="col-form-label">PANCard:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].PANNo}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="GSTN" class="col-form-label">GSTN:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].GSTN}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="Contact_Number" class="col-form-label">Contact Number:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DeskMobileNo}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="Emailid" class="col-form-label">Email </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DeskEmailID}}
                            </label></td>

                    </tr>
                    <tr>
                        <td> <label for="Address" class="col-form-label">Address </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BillingAddress}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="City" class="col-form-label">City </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].City}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td><label for="State" class="col-form-label">State </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].State}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="PinCode" class="col-form-label">PinCode</label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].PinCode}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="ContactPersonName" class="col-form-label">Contact Person Name </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].AuthPersonName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <label for="accountname" class="col-form-label">Account Name </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankAcName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="BanckAccountNo" class="col-form-label">Banck Account No </label></td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankAcNo}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="bankname" class="col-form-label">Bank Name </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="BanckBranch" class="col-form-label">Banck Branch </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankBranchName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Bank IFSC Code </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].IFSCCode}} </label>
                        </td>

                    </tr>

                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Created Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedDate}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Created By </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedBy}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Updated Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedDate}}
                            </label>
                        </td>

                    </tr>

                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Updated By </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedBy}}
                            </label>
                        </td>

                    </tr>

                </tbody>
            </table>
        </div>

        <!-- <div class="form-check-inline">
              <label class="form-check-label">
  
                  Status
              </label>
          </div>
          <div class="form-check-inline">
              <label class="form-check-label"> 
                  <input type="radio" class="form-check-input" checked="!checked" name="radio"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="1">active
              </label>
          </div>
          <div class="form-check-inline">
              <label class="form-check-label">
                  <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="0">Deactive
              </label>
          </div> -->



    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
        <span style="float: left;padding-top: 2%;">

            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">

                    Status
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <!-- {{productsbyid[0].ActiveStatus}} -->
                    <input type="radio" class="form-check-input" checked="!checked" name="radio"
                        [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                        [value]="1">active
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                        [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                        [value]="0">Deactive
                </label>
            </div>


        </span>
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"
            style="float: right;"></button>
        <!-- <button style="color: white;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
              (click)="saveProduct()"></button>  this is unused -->
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Organization Deleted Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>


<p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Status Update Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>