import { Component, OnInit } from '@angular/core';
import { Product } from '../product';
import { ProductService } from '../productservice';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';


import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { Customer, Representative } from "../customer"; 
 
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { ActivatedRoute } from '@angular/router';
import {formatDate} from '@angular/common';


import { Router } from '@angular/router';

@Component({
  selector: 'app-po-detail-fullpage',
  templateUrl: './po-detail-fullpage.component.html',
  styleUrls: ['./po-detail-fullpage.component.css']
})
export class PoDetailFullpageComponent implements OnInit {
  PayInvoiceDate:boolean;
  productDialog: boolean;
  datalenght:any;
  products: Product[];
  Totalamount:any;
  product: Product;
  currentdate:any;
   id:any;
  selectedProducts:any;
  empList:any[];
  submitted: boolean;
  hidenave:boolean;
  statuses: any[];
  cities: City[];
  customers: Customer[];
  session:boolean;
  representatives: Representative[];

  loading: boolean = true;
  inventoryStatus: Representative[];
  selectedCities: City[];
  constructor(private billdeskService: BilldeskService,private http: HttpClient,private _Activatedroute:ActivatedRoute,private productService: ProductService, private messageService: MessageService, private confirmationService: ConfirmationService,private route : Router ) { 

      this.cities = [
          {name: 'New York', code: 'NY'},
          {name: 'Rome', code: 'RM'},
          {name: 'London', code: 'LDN'},
          {name: 'Istanbul', code: 'IST'},
          {name: 'Paris', code: 'PRS'}
      ];

  }

  ngOnInit() { 




      this.id=this._Activatedroute.snapshot.paramMap.get("id");
      this._Activatedroute.paramMap.subscribe(params => { 
        this.id = params.get('id');
  
        console.log(this.id);
 
        console.log(this.billdeskService.getPoDetail(this.id).then(res => this.products = res));
   
          this.billdeskService.getPoDetailForCount(this.id).subscribe((profileres:any)=>{
            console.log('profileres');
          console.log(profileres.PODetailsResult.data);


          this.PayInvoiceDate=profileres.PODetailsResult.data[0].InvoiceDate;


          console.log(this.PayInvoiceDate);
const total =profileres.PODetailsResult.data.length;
console.log(total);
                 this.empList=[];
          for (let i = 0; i < total; i++) {
            console.log(profileres.PODetailsResult.data[i].TotalPayableAmount);
        
            this.empList.push(profileres.PODetailsResult.data[i].TotalPayableAmount);
          } 
        console.log('hello');
          console.log(this.empList);
        //   var toNumber = ['1','2','3','4','5'];
        
        console.log('hello');
          var sumNumber = this.empList.reduce((acc, cur) => acc + Number(cur), 0)
          console.log(sumNumber);


          this.Totalamount=sumNumber;
          console.log('profileres');
});

    //       console.log(total);
    //       this.empList=[];
    //       for (let i = 0; i < total; i++) {
    //         console.log(this.products[i].TotalPayableAmount);
        
    //         this.empList.push(this.products[i].TotalPayableAmount);
    //       } 
    //     console.log('hello');
    //       console.log(this.empList);
    //     //   var toNumber = ['1','2','3','4','5'];
        
    //     console.log('hello');
    //       var sumNumber = this.empList.reduce((acc, cur) => acc + Number(cur), 0)
    //       console.log(sumNumber);
   
      }); 
    

      this.representatives = [
          { inventoryStatus: "INSTOCK", image: "INSTOCK" },
          { inventoryStatus: "LOWSTOCK", image: "LOWSTOCK" },
          { inventoryStatus: "OUTOFSTOCK", image: "OUTOFSTOCK" },
          
        ];



        this.statuses = [

          { label: "Pending", value: "Pending" },
          { label: "Success", value: "Success" } 

        ];



         this.currentdate= formatDate(new Date(), 'dd/MM/YYYY', 'en');
 


         window.onafterprint = function(){
          console.log("Printing completed...");

          window.location.reload();
       }
  }



  print_page(){

    // window.print();
    // this.session=false;

    // this.hidenave = true;

    // window.print();
    setTimeout(() => window.print(), 300);
 
  }
  openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
  }

  deleteSelectedProducts() {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete the selected products?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.products = this.products.filter(val => !this.selectedProducts.includes(val));
              this.selectedProducts = null;
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
          }
      });
  }

  editProduct(product: Product) {
      this.product = {...product};
      this.productDialog = true;
  }

  deleteProduct(product: Product) {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + product.name + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.products = this.products.filter(val => val.id !== product.id);
              this.product = {};
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
          }
      });
  }

  hideDialog() {
      this.productDialog = false;
      this.submitted = false;
  }
  
  saveProduct() {
      this.submitted = true;

      if (this.product.name.trim()) {
          if (this.product.id) {
              this.products[this.findIndexById(this.product.id)] = this.product;                
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
          }
          else {
              this.product.id = this.createId();
              this.product.image = 'product-placeholder.svg';
              this.products.push(this.product);
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
          }
 
          this.productDialog = false;
          this.product = {};
      }
  }

  findIndexById(id: string): number {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
              index = i;
              break;
          }
      }

      return index;
  }

  createId(): string {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
  }
}


interface City {
  name: string,
  code: string
}

 
 