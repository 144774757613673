<form class="form-horizontal" [formGroup]="form">
    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">USER REGISTRATION</h1>

<div class="card-body" style="background-color:#fff; ">
    <h1 class="card-title">
       Organization Detail
    </h1>
    <div class="row">
        <div class="position-relative  form-group col-sm-6">
            <label for="City" class="col-form-label">Organization<span>*</span></label>
            <div class="">
              
               <p-dropdown [options]="organizaionlist" [(ngModel)]="selectedOrgId" optionLabel="OrgName" [filter]="true" filterBy="OrgName"
                    [showClear]="true" [placeholder]="selectedOrgName"  (onChange)="getDivisionByOrg($event)" name="UserOrgID" id="UserOrgID"      formControlName="UserOrgID"  [style]="{ width: '100%' }" >
                 
                    <ng-template let-country pTemplate="item">
                        <div class="country-item">
                            <div>{{country.OrgName}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
               
                <app-field-error-display [displayError]="isFieldValid('UserOrgID')" errorMsg="Please Select Organization Name"   style="background-color: #f8d7da;
                border-color: #f5c6cb;" class="alert-danger">
                </app-field-error-display>
            
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="DivID" class="col-form-label">Division Name<span>*</span></label>
            <div class="">
               
                <!-- <select  class="form-control" name="DivID" id="DivID"   (change)="changeOrganization($event)"  formControlName="DivID"> 
                    
                     
                    <option [ngValue]="null" disabled selected >Select Division</option>
                    
                     <option *ngFor="let divlist of divisionlist" [ngValue]="divlist.DivID">{{divlist.DivName}}</option>
                
                </select> -->
            <p-dropdown [options]="divisionlist"  [(ngModel)]="selectedDiv" optionLabel="DivName" [filter]="true" filterBy="DivName"
                [showClear]="true" [placeholder]="selectedDivName"  (onChange)="changeDivision($event)" name="UserDivID" id="UserDivID"     formControlName="UserDivID"  [style]="{ width: '100%' }" >
             
                <ng-template let-country pTemplate="item">
                    <div class="country-item">
                        <div>{{country.DivName}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            
                <app-field-error-display [displayError]="isFieldValid('UserDivID')" errorMsg="Please Select Division Name"   style="background-color: #f8d7da;
                border-color: #f5c6cb;" class="alert-danger">
                </app-field-error-display>
            
            </div>
        </div>



        <div class="position-relative  form-group col-sm-6">
            <label for="DeptID" class="col-form-label">Department Name<span>*</span></label>
            <div class="">
                <!-- <input type="text" name="City" id="City" class="form-control"> -->

                <!-- <select  class="form-control" name="DeptID" id="DeptID"   (change)="changeOrganization($event)"  formControlName="DeptID"> 
                    
                     
                    <option [ngValue]="null" disabled selected >Select Department</option>

                    <option *ngFor="let diplist of departmentlist" [ngValue]="diplist.DeptID">{{diplist.DeptName}}</option>
                
                </select> -->
                <p-dropdown [options]="departmentlist"  [(ngModel)]="selectedDeptID" optionLabel="DeptName" [filter]="true" filterBy="DeptName"
                [showClear]="true" [placeholder]="selectedDeptName"  (onChange)="changeDepartment($event)" name="UserDeptID" id="UserDeptID"     formControlName="UserDeptID"  [style]="{ width: '100%' }" >
             
                <ng-template let-country pTemplate="item">
                    <div class="country-item">
                        <div>{{country.DeptName}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            
                <app-field-error-display [displayError]="isFieldValid('UserDeptID')" errorMsg="Please Select Deparment Name"   style="background-color: #f8d7da;
                border-color: #f5c6cb;" class="alert-danger">
                </app-field-error-display>
            
            </div>
        </div>
</div>
</div>

<div class="card-body" style="background-color:#fff; ">
    <h1 class="card-title">
        User Detail
    </h1>
    <div class="row">
        <div class="position-relative  form-group col-sm-6">
            <label for="UserCode" class="col-form-label">User Role<span>*</span></label>
            <div class="">
                <!-- <select name="UserCode" id="UserCode" class="form-control"  formControlName="UserCode">
                    <option value="">Please Select</option>
                    <option value="1">HOD</option>
                    <option value="2">BillDesk</option>
                    <option value="3">Approver-1</option>
                </select> -->


                <p-dropdown [options]="rolelist"  [(ngModel)]="selectedRoleID" optionLabel="DeptName" [filter]="true" filterBy="DeptName"
                [showClear]="true" [placeholder]="selectedRoleName"  (onChange)="changeRole($event)" name="RoleTypeID" id="RoleTypeID"     formControlName="RoleTypeID"  [style]="{ width: '100%' }" >
             
                <ng-template let-country pTemplate="item">
                    <div class="country-item">
                        <div>{{country.RoleTypeName}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
                <app-field-error-display [displayError]="isFieldValid('RoleTypeID')" errorMsg="Please Select UserCode"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="UserCode" class="col-form-label">User Code<span>*</span></label>
            <div class="">
                <input type="text" name="UserCode" id="UserCode" class="form-control" formControlName="UserCode">
                <app-field-error-display [displayError]="isFieldValid('UserCode')" errorMsg="Please Enter User Code"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>


        <div class="position-relative  form-group col-sm-6">
            <label for="UserFullName" class="col-form-label">User Full Name<span>*</span></label>
            <div class="">
                <input type="text" name="UserFullName" id="UserFullName" class="form-control" formControlName="UserFullName">
                <app-field-error-display [displayError]="isFieldValid('UserFullName')" errorMsg="Please Enter Full Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>

        <div class="position-relative  form-group col-sm-6">
            <label for="UserEmailID" class="col-form-label">User Email<span>*</span></label>
            <div class="">
                <input type="text" name="UserEmailID" id="UserEmailID" class="form-control" formControlName="UserEmailID">
                <app-field-error-display [displayError]="isFieldValid('UserEmailID')" errorMsg="Please Enter Email"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>

        <div class="position-relative  form-group col-sm-6">
            <label for="UserMobileNo" class="col-form-label">User Mobile<span>*</span></label>
            <div class="">
                <input type="text" name="UserMobileNo" class="form-control" formControlName="UserMobileNo"     (ngModelChange)="validateMobile($event)"  >
                <app-field-error-display [displayError]="isFieldValid('UserMobileNo')" errorMsg="Please Enter Valid Mobile No"    style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
 
<span *ngIf="mobilevalidata">Mobile Number already exist, Please Try another</span>  
            </div>
        </div> 
        <div class="position-relative  form-group col-sm-6">
            <label for="UserLoginID" class="col-form-label">Login ID<span>*</span></label>
            <div class="">
                <input type="text" name="UserLoginID" id="UserLoginID" class="form-control" formControlName="UserLoginID" disabled=""   >
                <app-field-error-display [displayError]="isFieldValid('UserLoginID')" errorMsg="Please Enter User  LoginID"   style="background-color: #91696c;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>


        <div class="position-relative  form-group col-sm-6">
            <label for="UserPassword" class="col-form-label">Password<span>*</span></label>
            <div class="">
                <input type="password" name="UserPassword" id="UserPassword" class="form-control" formControlName="UserPassword">
                <app-field-error-display [displayError]="isFieldValid('UserPassword')" errorMsg="Please Enter User Password"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>
        </div>
        </div>
        <div class="card-body" style="background-color:#fff; ">
            <h1 class="card-title">
                User Address
            </h1>
            <div class="row">

        <div class="position-relative  form-group col-sm-6">
            <label for="UserAddress" class="col-form-label">User Address<span>*</span></label>
            <div class="">
  
               
               <textarea name="UserAddress" id="UserAddress" class="form-control" formControlName="UserAddress"></textarea>
               <app-field-error-display [displayError]="isFieldValid('UserAddress')" errorMsg="Please Enter User Address"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>
 

        <div class="position-relative  form-group col-sm-6">
            <label for="UserState" class="col-form-label">User State<span>*</span></label>
            <div class="">
   

                <p-dropdown [options]="stateList" [(ngModel)]="selectedState" optionLabel="StateName" [filter]="true" filterBy="StateName"
                [showClear]="true" [placeholder]="selectedStateName"  (onChange)="changeState($event)"  name="UserState" id="UserState"  formControlName="UserState"   [style]="{ width: '100%' }">
              
                <ng-template let-state pTemplate="item">
                    <div class="country-item">
                        <div>{{state.StateName}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
                
                <app-field-error-display [displayError]="isFieldValid('UserState')" errorMsg="Please Select User State"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        <div class="position-relative  form-group col-sm-6">
            <label for="City" class="col-form-label">City<span>*</span></label>
            <div class="">
              
               <p-dropdown [options]="cityList" [(ngModel)]="selectedCity" optionLabel="DistrictName." [filter]="true" filterBy="DistrictName"
                    [showClear]="true" [placeholder]="selectedCityName"  (onChange)="changeCity($event)"  name="UserCity" id="UserCity"  formControlName="UserCity"  [style]="{ width: '100%' }" >
                 
                    <ng-template let-country pTemplate="item">
                        <div class="country-item">
                            <div>{{country.DistrictName}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <app-field-error-display [displayError]="isFieldValid('UserCity')" errorMsg="Please Select City"   style="background-color: #f8d7da;
                border-color: #f5c6cb;" class="alert-danger">
                </app-field-error-display>
            
            </div>
        </div>


        <div class="position-relative  form-group col-sm-6">
            <label for="UserPinCode" class="col-form-label">Pincode<span>*</span></label>
            <div class="">
                <input type="text" name="UserPinCode" id="UserPinCode" class="form-control" formControlName="UserPinCode">
                <app-field-error-display [displayError]="isFieldValid('UserPinCode')" errorMsg="Please Enter User Pincode"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>


      


        <!-- <div class="position-relative  form-group col-sm-6">
            <label for="IsEmailVerified" class="col-form-label">IsEmailVerified<span>*</span></label>
            <div class="">
                <input type="text" name="IsEmailVerified" id="IsEmailVerified" class="form-control" formControlName="IsEmailVerified">
                <app-field-error-display [displayError]="isFieldValid('IsEmailVerified')" errorMsg="Please Enter User IsEmailVerified"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>


        <div class="position-relative  form-group col-sm-6">
            <label for="VerificationMobileOTP" class="col-form-label">Verification Mobile OTP<span>*</span></label>
            <div class="">
                <input type="text" name="VerificationMobileOTP" id="VerificationMobileOTP" class="form-control" formControlName="VerificationMobileOTP">
                <app-field-error-display [displayError]="isFieldValid('VerificationMobileOTP')" errorMsg="Please Enter User Verification Mobile OTP"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>

        <div class="position-relative  form-group col-sm-6">
            <label for="IsMobileVerified" class="col-form-label">Is Mobile Verified <span>*</span></label>
            <div class="">
                <input type="text" name="IsMobileVerified" id="IsMobileVerified" class="form-control" formControlName="IsMobileVerified">
                <app-field-error-display [displayError]="isFieldValid('IsMobileVerified')" errorMsg="Please Enter  IsMobileVerified"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div> -->


        <div class="position-relative  form-group col-sm-6">
            <label for="Remark" class="col-form-label">Remark<span>*</span></label>
            <div class="">
                <input type="text" name="Remark" id="Remark" class="form-control" formControlName="Remark">
                <app-field-error-display [displayError]="isFieldValid('Remark')" errorMsg="Please Enter  Remark"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>
            
        <div class="position-relative  form-group col-sm-6">
            <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
            <div class="">

    <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus"  >
        <option value="1">Active</option>
        <option value="0">InActive</option></select>
                 <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                border-color: #f5c6cb;" class="alert-danger">
                </app-field-error-display>
            </div>
        </div>

        <div class="position-relative  form-check col-sm-6 offset-sm-6">
            <div class="">
                <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
            </div>
        </div>
        
    
</div>

