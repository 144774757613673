<div class="background">
  <div class="shape"></div>
  <div class="shape"></div>
</div>
<form #userloginData="ngForm">
  <h3><img src="../assets/images/login.png" width="50%">
    <h3>
      <h3>Login Here</h3>

      <input type="text" placeholder="Please Enter Mobile Number" id="mobile" name="mobile" ngModel [hidden]="isOtp"
        maxlength="10" required (keypress)="numberOnly($event)" autocomplete="new-password" autofocus >

      <h5 [hidden]="isErrorBlank" class="text-danger text-center  alert alert-danger"
        style="width:100%;margin-top:1% !important">Please Enter Mobile Number</h5>

      <h5 [hidden]="isError" class="text-danger text-center   alert alert-danger"
        style="width:100%;margin-top:1% !important">Invalid Mobile Number</h5>



      <button [type]="buttontype" (click)="sendOtp(userloginData.value)" [hidden]="isOtp">Send OTP</button>
      <!-- <div class="social"> 
            <div class="go"><i class="fa fa-google"></i> Google</div>
            <div class="fb"><i class="fa fa-facebook-f"></i> Facebook</div>
          </div> -->

      <div [hidden]="isShow">
        <div class="form-outline mb-4">

          <h6 class="labelsuggession">We have sent you <b>One Time Password </b>to your Mobile</h6>
          <h4 class="form-label labelsuggest" for="password">Please Enter OTP</h4>
          <!-- <h5>{{mobile2|CarecterhidePipe}}</h5> -->


          <showcharacter name="{{mobile2|carecterhide}}"></showcharacter>



          <div style="text-align: center;font-size: 20px;">
            <countdown [config]="config"></countdown>
           </div>


          <div class="d-flex flex-row mt-5" style="width:90%;">
            <input
              style="background: transparent;border-bottom: 2px solid #fff;border-top: none;border-left: none;border-right: none;border-radius: revert;text-align: center; margin-right: 2% !important;"
              type="text" name="otp1"  [datoAutoFocus]="f" (ngModel)="otp1" #otp1  id="otp1" class="form-control" maxlength="1"
              (keyup)="move($event,'',otp1,otp2)" (keypress)="numberOnly($event)"      />
            <input
              style="background: transparent;border-bottom: 2px solid #fff;border-top: none;border-left: none;border-right: none;border-radius: revert;text-align: center;margin-right: 2% !important;"
              type="text" name="otp2" (ngModel)="otp2" #otp2 class="form-control" maxlength="1"
              (keyup)="move($event,otp1,otp2,otp3)" (keypress)="numberOnly($event)" />
            <input
              style="background: transparent;border-bottom: 2px solid #fff;border-top: none;border-left: none;border-right: none;border-radius: revert;text-align: center;margin-right: 2% !important;"
              type="text" name="otp3" (ngModel)="otp3" #otp3 class="form-control" maxlength="1"
              (keyup)="move($event,otp2,otp3,otp4)" (keypress)="numberOnly($event)" />
            <input
              style="background: transparent;border-bottom: 2px solid #fff;border-top: none;border-left: none;border-right: none;border-radius: revert;text-align: center;"
              type="text" name="otp4" (ngModel)="otp4" #otp4 class="form-control" maxlength="1"
              (keyup)="move($event,otp3,otp4,'')" (keypress)="numberOnly($event)" />
          </div>

          <h6 [hidden]="isOtpInvalidError" class="text-danger text-center alert alert-danger"
            style="width:94%;margin-top:1% !important">Please Enter Valid OTP</h6>
          <h6 [hidden]="isOtpEmptyError" class="text-danger text-center alert alert-danger"
            style="width:94%;margin-top:1% !important">All field Is Require</h6>
        </div>
        <div class="d-flex flex-row mt-5" style="margin: 60px auto !important;">
          <button style="border: 2px solid #2196f357;color: #247bff;" [type]="buttontype2" [hidden]="isResend"
            class="btn btn-default btn-block " (click)="resendotp(userloginData.value)"
            style="background-color: #1e448f;border: 0px;width:94%;padding: 10px;color: #fff;margin-top: 10px;border-radius: 8px;">Resend
            OTP</button>
          <button [type]="buttontype1" class="btn btn-primary btn-block " style="margin: 0px 0px 0px 5px;"
            (click)="onClicklogin(userloginData.value)"
            style="background-color: #1e448f;border: 0px;width:94%;padding: 10px;color: #fff;margin-top: 10px;border-radius: 8px;">Sign
            in</button>
 
        </div>
      </div>
      
<br>  
<br>  
<br>  
 
<br>


<!-- <textarea appHinglishTyping placeholder="Enter Allocation Target" autocomplete="off" style="color:#000"></textarea>    -->
      <!-- <textarea hindiTyping style="color:#000;">  </textarea> -->


      <!-- <h3>Welcome to my angular project</h3>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>

<div class="dropdown h-100" class="statusDropdownContainer">
	<a class="btn btn-white dropdown-toggle mb-2 statusFilterDropdown h-100 flex-middle" href="#" role="button"
		id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
		<span class="text-truncate">Expert: {{selectedValue ? selectedValue : 'All'}}</span>
	</a>
	<div class="dropdown-menu w-100 pt-0" aria-labelledby="dropdownMenuButton">
		<input type="text" class="w-100 p-2 searchInput" [ngModel]="searchValue" (ngModelChange)="filterDropdown($event)">
		<a *ngFor="let option of filteredList; let i=index" class="dropdown-item pointer text-contain"
			[ngClass]="{'alternateBackground': i%2 == 0 }" (click)="selectValue(option.name)">
			{{option.name}}
		</a>
		<div *ngIf="filteredList.length <=0" class="text-center text-muted mt-1">No expert found</div>
	</div>
</div> -->