<form class="form-horizontal" [formGroup]="form"  *ngFor="let data of products">
    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">EDIT VENDOR</h1>
    <div class="card-body" style="background-color:#fff;  ">
        <h1 class="card-title">
           Vendor Registration
        </h1>
        <div  class="row">
            <div class="position-relative  form-group col-sm-6">
                <label for="VendorName" class="col-form-label">Vendor Name<span>*</span></label>
                <div   [ngClass]="displayFieldCss('VendorName')">
                    <input type="text" name="VendorName" id="VendorName" class="form-control" formControlName="VendorName" [(ngModel)]="data.VendorName" >
    
    
                    <app-field-error-display [displayError]="isFieldValid('VendorName')" errorMsg="Please Enter Vendor  Name"   style="background-color: #791820;
                            border-color: #f5c6cb;" class="alert-danger">
                   </app-field-error-display>
                </div>
            </div>
         
            <div class="position-relative  form-group col-sm-6">
                <label for="VendorMobileNo" class="col-form-label">Mobile No<span>*</span></label>
                <div class="">
                    <input type="number" name="VendorMobileNo" id="VendorMobileNo" class="form-control" formControlName="VendorMobileNo" [(ngModel)]="data.VendorMobileNo">
                    <app-field-error-display [displayError]="isFieldValid('VendorMobileNo')" errorMsg="Please Enter Mobile No"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="VendorEmailID" class="col-form-label">Emailid <span>*</span></label>
                <div class="">
                    <input type="text" name="VendorEmailID" id="VendorEmailID" class="form-control" formControlName="VendorEmailID" [(ngModel)]="data.VendorEmailID">
                    <app-field-error-display [displayError]="isFieldValid('VendorEmailID')" errorMsg="Please Enter Email Id"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>       
            <div class="position-relative  form-group col-sm-6">
                <label for="Website" class="col-form-label">Website <span>*</span></label>
                <div class="">
                    <input type="text" name="Website" id="Website" class="form-control"  formControlName="Website" [(ngModel)]="data.Website">
    
                    <app-field-error-display [displayError]="isFieldValid('Website')" errorMsg="Please Enter Website"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="GSTN" class="col-form-label">GISTN <span>*</span></label>
                <div class="">
                    <input type="text" name="GSTN" id="GSTN" class="form-control" formControlName="GSTN" [(ngModel)]="data.GSTN">
           <app-field-error-display [displayError]="isFieldValid('GSTN')" errorMsg="Please Enter GSTN"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            
            <div class="position-relative  form-group col-sm-6">
                <label for="PANNo" class="col-form-label">PANCard <span>*</span></label>
                <div class="">
                    <input type="text" name="PANNo" id="PANNo" class="form-control" formControlName="PANNo" [(ngModel)]="data.PANNo">
                    <app-field-error-display [displayError]="isFieldValid('PANNo')" errorMsg="Please Enter PANNo"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>   
            <div class="position-relative  form-group col-sm-6">
                <label for="UserLoginID" class="col-form-label">Login ID<span>*</span></label>
                <div class="">
                    <input type="text" name="UserLoginID" id="UserLoginID" class="form-control" formControlName="UserLoginID" [(ngModel)]="data.UserLoginID">
                    <app-field-error-display [displayError]="isFieldValid('UserLoginID')" errorMsg="Please Enter User  LoginID"   style="background-color: #91696c;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display>
                </div>
            </div>
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="UserPassword" class="col-form-label">Password<span>*</span></label>
                <div class="">
                    <input type="password" name="UserPassword" id="UserPassword" class="form-control" formControlName="UserPassword"  [(ngModel)]="data.UserPassword">
                    <app-field-error-display [displayError]="isFieldValid('UserPassword')" errorMsg="Please Enter User Password"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display>
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="CIN" class="col-form-label">CIN <span>*</span></label>
                <div class="">
                    <input type="text" name="CIN" id="CIN" class="form-control" formControlName="CIN" [(ngModel)]="data.CIN">
                    <app-field-error-display [displayError]="isFieldValid('CIN')" errorMsg="Please Enter CIN"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> 
            <div class="position-relative  form-group col-sm-6">
                <!-- <label for="UserPassword" class="col-form-label">User Type: Vendor</label> -->
                <!-- <div class="">
                    <input type="password" name="UserPassword" id="UserPassword" class="form-control" formControlName="UserPassword">
                    <app-field-error-display [displayError]="isFieldValid('UserPassword')" errorMsg="Please Enter User Password"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display>
                </div> -->
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="UserPassword" class="col-form-label">User Type: Vendor</label>
                <!-- <div class="">
                    <input type="password" name="UserPassword" id="UserPassword" class="form-control" formControlName="UserPassword">
                    <app-field-error-display [displayError]="isFieldValid('UserPassword')" errorMsg="Please Enter User Password"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display>
                </div> -->
            </div>
        </div>
        </div>
        <div class="card-body" style="background-color:#fff;  ">
            <h1 class="card-title">
               Bank Details
            </h1>
            <div  class="row">
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemCategoryID" class="col-form-label">ItemCategoryID <span>*</span></label>
                <div class="">
                    <input type="number" name="ItemCategoryID" id="ItemCategoryID" class="form-control" formControlName="ItemCategoryID">
    
                    <app-field-error-display [displayError]="isFieldValid('ItemCategoryID')" errorMsg="Please Enter ItemCategoryID"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>  -->
            
            
            <div class="position-relative  form-group col-sm-6">
                <label for="BankHolderName" class="col-form-label">Bank Holder Name <span>*</span></label>
                <div class="">
                    <input type="text" name="BankHolderName" id="BankHolderName" class="form-control" formControlName="BankHolderName" [(ngModel)]="data.BankHolderName">
    
                    <app-field-error-display [displayError]="isFieldValid('BankHolderName')" errorMsg="Please Enter BankHolder Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
                
            <div class="position-relative  form-group col-sm-6">
                <label for="BankName" class="col-form-label">Bank Name <span>*</span></label>
                <div class="">
                    <input type="text" name="BankName" id="BankName" class="form-control" formControlName="BankName" [(ngModel)]="data.BankName">
    
                    <app-field-error-display [displayError]="isFieldValid('BankName')" errorMsg="Please Enter Bank Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
            <div class="position-relative  form-group col-sm-6">
                <label for="BankBranchName" class="col-form-label">Bank Branch <span>*</span></label>
                <div class="">
                    <input type="text" name="BankBranchName" id="BankBranchName" class="form-control" formControlName="BankBranchName"  [(ngModel)]="data.BankBranchName">
    
                    <app-field-error-display [displayError]="isFieldValid('BankBranchName')" errorMsg="Please Enter BanckBranch  Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>          
            <div class="position-relative  form-group col-sm-6">
                <label for="BankAcNo" class="col-form-label">Bank AccountNo <span>*</span></label>
                <div class="">
                    <input type="number" name="BankAcNo" id="BankAcNo" class="form-control" formControlName="BankAcNo" [(ngModel)]="data.BankAcNo">
    
                    <app-field-error-display [displayError]="isFieldValid('BankAcNo')" errorMsg="Please Enter Bank Account Number"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>   
            <div class="position-relative  form-group col-sm-6">
                <label for="IFSCCode" class="col-form-label">Bank IFSC Code <span>*</span></label>
                <div class="">
                    <input type="text" name="IFSCCode" id="IFSCCode" class="form-control"  formControlName="IFSCCode" [(ngModel)]="data.IFSCCode">
    
                    
                    <app-field-error-display [displayError]="isFieldValid('IFSCCode')" errorMsg="Please Enter IFSC Code"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>   
            <div class="position-relative  form-group col-sm-6">
                <label for="SwiftCode" class="col-form-label">Swift Code </label>
                <div class="">
                    <input type="text" name="SwiftCode" id="SwiftCode" class="form-control" formControlName="SwiftCode" [(ngModel)]="data.SwiftCode">
                    <app-field-error-display [displayError]="isFieldValid('SwiftCode')" errorMsg="Please Enter SwiftCode"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
    
            </div></div> <div class="card-body" style="background-color:#fff;  ">
                <h1 class="card-title">
                   Contact Details
                </h1>
                <div  class="row">
            <div class="position-relative  form-group col-sm-6">
                <label for="AuthPersonName" class="col-form-label">Contact Person Name</label>
                <div class="">
                    <input type="text" name="AuthPersonName" id="AuthPersonName" class="form-control" formControlName="AuthPersonName" [(ngModel)]="data.AuthPersonName">
                    <app-field-error-display [displayError]="isFieldValid('AuthPersonName')" errorMsg="Please Enter Contact Person Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="AuthPersonMobileNo" class="col-form-label">Contact PersonNo </label>
                <div class="">
                    <input type="text" name="AuthPersonMobileNo" id="AuthPersonMobileNo" class="form-control" formControlName="AuthPersonMobileNo" [(ngModel)]="data.AuthPersonMobileNo">
    
                    <app-field-error-display [displayError]="isFieldValid('AuthPersonMobileNo')" errorMsg="Please Enter Contact Person Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="AuthPersonEmailID" class="col-form-label">Auth Person EmailID </label>
                <div class="">
                    <input type="text" name="AuthPersonEmailID" id="AuthPersonEmailID" class="form-control" formControlName="AuthPersonEmailID"  [(ngModel)]="data.AuthPersonEmailID">
    
                    <app-field-error-display [displayError]="isFieldValid('AuthPersonEmailID')" errorMsg="Please Enter Contact Email"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="SupportUrl" class="col-form-label">Support Url</label>
                <div class="">
                    <input type="text" name="SupportUrl" id="SupportUrl" class="form-control"  formControlName="SupportUrl"  [(ngModel)]="data.SupportUrl">
                    <app-field-error-display [displayError]="isFieldValid('SupportUrl')" errorMsg="Please Enter Support Url"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>   
            <div class="position-relative  form-group col-sm-6">
                <label for="SupportLoginID" class="col-form-label">Support Login</label>
                <div class="">
                    <input type="text" name="SupportLoginID" id="SupportLoginID" class="form-control" formControlName="SupportLoginID"  [(ngModel)]="data.SupportLoginID">
    
                    <app-field-error-display [displayError]="isFieldValid('SupportLoginID')" errorMsg="Please Enter Support Login"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="SupportPassword" class="col-form-label">Support Password</label>
                <div class="">
                    <input type="text" name="SupportPassword" id="SupportPassword" class="form-control" formControlName="SupportPassword"  [(ngModel)]="data.SupportPassword">
    
                    <app-field-error-display [displayError]="isFieldValid('SupportPassword')" errorMsg="Please Enter Support Password"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="VendorState" class="col-form-label">State <span>*</span></label>
                <div class="">
                    <!-- <select name="VendorState" id="VendorState" class="form-control" formControlName="VendorState" (change)="changeState($event.target.value)" >
                    <option [ngValue]="null" disabled selected >Please Select State</option>
                                
                    <option *ngFor="let statelist of stateList"      [value]="statelist.StateID"    >{{statelist.StateName}}</option>
                
                    </select> -->


                    <!-- <select  class="form-control" name="VendorState" id="VendorState"     formControlName="VendorState" (ngModelChange)="changeState($event)"  [ngModel]="data.StateID"> 
                            
                        <option [ngValue]="null" disabled selected >Please Select State</option>
                    
                        <option *ngFor="let statelist of stateList"      [value]="statelist.StateID" >{{statelist.StateName}}</option>
                    
    
                    </select> -->
                    <!-- <div *ngIf="stateList">
 
                        <p-dropdown [options]="stateList" (ngModelChange)="changeState($event)"  [ngModel]="data.StateID"
                            [autoDisplayFirst]="false" optionLabel="StateName" formControlName="VendorState"
                            [placeholder]="data.VendorState" optionValue="StateName" [filter]="true"
                            filterBy="StateName">
                        </p-dropdown>

                    </div> -->


                    
                    <div *ngIf="stateList">


                        <p-dropdown [options]="stateList" [(ngModel)]="data.StateID"
                            [autoDisplayFirst]="false" optionLabel="StateName" formControlName="VendorState"
                            [placeholder]="data.VendorState" optionValue="StateID" [filter]="true" (ngModelChange)="changeState($event)" 
                            filterBy="StateName">
                        </p-dropdown>

                    </div>
                    
                    <app-field-error-display [displayError]="isFieldValid('VendorState')" errorMsg="Please Enter State"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="VendorCity" class="col-form-label">City <span>*</span></label>
                <div class="">
                <!-- <select  name="VendorCity" id="VendorCity" class="form-control" formControlName="VendorCity">
    
                    <option [ngValue]="null" disabled selected >Please Select City</option>
                    <option *ngFor="let citylist of cityList"       [value]="citylist.DistrictID"       >{{citylist.DistrictName}}</option>
                </select> -->

<!-- 
                <select  class="form-control" name="VendorCity" id="VendorCity"    formControlName="VendorCity"  [ngModel]="data.DistrictID">
                    <option [ngValue]="null" disabled selected >Please Select City</option>
                    <option *ngFor="let citylist of cityList"       [value]="citylist.DistrictID"       >{{citylist.DistrictName}}</option>
                  
                </select> -->

   
                <div *ngIf="cityList">


                    <p-dropdown [options]="cityList" [(ngModel)]="data.DistrictID"
                        [autoDisplayFirst]="false" optionLabel="DistrictName" formControlName="VendorCity"
                        [placeholder]="data.DistrictName" optionValue="DistrictID" [filter]="true"  
                        filterBy="DistrictName">
                    </p-dropdown>

                </div>
                

                    <app-field-error-display [displayError]="isFieldValid('VendorCity')" errorMsg="Please Enter VendorCity"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="VendorPinCode" class="col-form-label">PinCode</label>
                <div class="">
                    <input type="text" name="VendorPinCode" id="VendorPinCode" class="form-control"  formControlName="VendorPinCode" [(ngModel)]="data.VendorPinCode">
    
                    <app-field-error-display [displayError]="isFieldValid('VendorPinCode')" errorMsg="Please Enter PinCode"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="VendorBillingAddress" class="col-form-label">Address  <span>*</span></label>
                <div class="">
                    <textarea name="VendorBillingAddress" id="VendorBillingAddress" class="form-control" formControlName="VendorBillingAddress"  [(ngModel)]="data.VendorBillingAddress"></textarea>
                   
                   
                   <app-field-error-display [displayError]="isFieldValid('VendorBillingAddress')" errorMsg="Please Enter Address"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="VendorUPIID" class="col-form-label">VendorUPIID  <span>*</span></label>
                <div class="">
                     
                   
               <input type="number" name="VendorUPIID" id="VendorUPIID" class="form-control" formControlName="VendorUPIID" >     
                   <app-field-error-display [displayError]="isFieldValid('VendorUPIID')" errorMsg="Please Enter VendorUPIID"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="VerificationEmailUR" class="col-form-label">VerificationEmailUR  <span>*</span></label>
                <div class="">
                    
                    <input type="text" name="VerificationEmailUR" id="VerificationEmailUR" class="form-control" formControlName="VerificationEmailUR">
      
                  
                  <app-field-error-display [displayError]="isFieldValid('VerificationEmailUR')" errorMsg="Please Enter VerificationEmailUR"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="VerificationEmailOT" class="col-form-label">VerificationEmailOT  <span>*</span></label>
                <div class="">
                     
                    <input type="text" name="VerificationEmailOT" id="VerificationEmailOT" class="form-control" formControlName="VerificationEmailOT">
        
                    
                    <app-field-error-display [displayError]="isFieldValid('VerificationEmailOT')" errorMsg="Please Enter VerificationEmailOT"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="IsEmailVerified" class="col-form-label">IsEmailVerified  <span>*</span></label>
                <div class="">
                     <input type="text" name="IsEmailVerified" id="IsEmailVerified" class="form-control" formControlName="IsEmailVerified">
        
                  
                  
                  <app-field-error-display [displayError]="isFieldValid('IsEmailVerified')" errorMsg="Please Enter IsEmailVerified"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="VerificationMobileOTP" class="col-form-label">VerificationMobileOTP  <span>*</span></label>
                <div class="">
                     <input type="text" name="VerificationMobileOTP" id="VerificationMobileOTP" class="form-control" formControlName="VerificationMobileOTP">
         
                    <app-field-error-display [displayError]="isFieldValid('VerificationMobileOTP')" errorMsg="Please Enter VerificationMobileOTP"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
    
            
            <div class="position-relative  form-group col-sm-6">
                <label for="IsMobileVerified" class="col-form-label">IsMobileVerified  <span>*</span></label>
                <div class="">
                    <textarea name="IsMobileVerified" id="IsMobileVerified" class="form-control" formControlName="IsMobileVerified"></textarea>
                    <app-field-error-display [displayError]="isFieldValid('IsMobileVerified')" errorMsg="Please Enter IsMobileVerified"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
                <div class="">

        <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus"  [(ngModel)]="data.ActiveStatus">

            <option selected disabled >Please Select </option>
            <option value="1">Active</option>
            <option value="0">InActive</option></select>
                     <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>

    
    
            <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary" (click)="onSubmit()" >Submit</button>
                </div>
            </div>
        <!-- </form> -->
    </div>
    
    