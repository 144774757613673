import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { ApiserviceService } from '../api/apiservice.service';
@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {
  //Create FormGroup 


  
  selectedOrgId:string;



  selectedOrgName:string='Please Select Organization';

  selectedDivName:string='Please Select Division';
  selectedDiv:string;

  selectedDeptID:string;
  selectedDeptName:string='Please Select Department';


  selectedRoleID:string;
  selectedRoleName:string='Please Select Role';


  


  organizaionlist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];
  rolelist:Billdesk[];
  departmentlist:Billdesk[];
  divisionlist:Billdesk[];
  cityList:Billdesk[];


  type: string;

  // stateList:any[];

  // cityList:any[];
  selectedCity: string;
  id:any;
  products:Billdesk[];
  mobilevalidata:any;
  selectedCityName:string='Please Select City';
  
  selectedStateName: string='Please Select State';
  StateID:number;
  DistrictID:number;
  selectedState: string;
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef,private _Activatedroute:ActivatedRoute,private api:ApiserviceService) {
   
    console.log('orgiststart');


    // console.log(this.billdeskService.getOrganization().then(res => this.organizaionlist = res));
    // console.log('stateliststart'); 
    console.log('orgiststart');


    console.log(this.billdeskService.getOrganizationsList().then(res => this.organizaionlist = res));

    console.log(this.billdeskService.getState().then(res => this.stateList = res));
    
    console.log(this.billdeskService.getUserRole().then(res => this.rolelist = res));
 
    
    console.log('statelistend');

    this.id=this._Activatedroute.snapshot.paramMap.get("id");
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  
  ngOnInit() {




    // code for get detail by Id
this.id=this._Activatedroute.snapshot.paramMap.get("id");
this._Activatedroute.paramMap.subscribe(params => { 
  this.id = params.get('id');

  console.log(this.id);

  console.log(this.billdeskService.getUserByid(this.id).then(res => this.products = res ));



});

// code for get detail by Id




    this.form = this.fb.group({  
      RoleTypeID: [null, Validators.required],
      UserOrgID: [null, Validators.required],
      UserCode:[null, Validators.required],
      UserDivID: [null, Validators.required],
      UserDeptID: [null, Validators.required],
      UserFullName: [null, Validators.required],
      UserEmailID:[null, Validators.required],
      UserMobileNo: [null, Validators.required],

      
      UserAddress: [null, Validators.required],
      UserCity: [null, Validators.required],
      UserState: [null, Validators.required],
      UserPinCode: [null, Validators.required],
      // VerificationEmailURL: [null, Validators.required],
      // VerificationEmailOTP: [null, Validators.required],
      // IsEmailVerified: [null, Validators.required],
      // VerificationMobileOTP: [null, Validators.required],
      ActiveStatus: [null, Validators.required],
      UserPassword: [null, Validators.required],
      UserLoginID: [null, Validators.required],
      Remark: [null, Validators.required] 


    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) { 
    console.log(e); 
    // this.form.controls['State'].setValue(e.value.StateID); 
    
    
    // this.selectedState=e.value.StateID;
    
    
    // this.selectedStateName=e.value.StateName;
    console.log(this.billdeskService.getCity(e).then(res => this.cityList = res));
    
      }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
        "flag":"",
        "UserID":this.id,
       
"CreatedDate":"10/13/2021",
"CreatedBy":"2",
"UpdatedDate":"",
"UpdatedBy":"",
"VerificationEmailURL":"",
"VerificationEmailOTP":"",
"IsEmailVerified":"",
"VerificationMobileOTP":"",
"IsMobileVerified":"",
     
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);


if (this.form.valid) {
 
let updateurl= "/userprofile/userprofileregistrationupdate";
this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{
  console.log("responce data");
  console.log(res);
  console.log("responce data");
   console.log(res.userprofileregistrationupdateResult);
  if(res.userprofileregistrationupdateResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/User-List'])
// localStorage.setItem('insertdata', 'Success');
localStorage.setItem('updatedata', 'Success');
 }else{
   console.log('Please Try Again Later');
 }



 if(res.userprofileregistrationupdateResult.Status=='400'){
  alert(res.userprofileregistrationupdateResult.Message);
  }
  
 });

} 
else {
  this.validateAllFormFields(this.form);
}
 }


 
 getDivisionByOrg(e){
 
console.log(this.billdeskService.getDivisionByOrgbYDLLList(e).then(res => this.divisionlist = res));

localStorage.setItem('organizationid',e);
}
changeDivision(e){
 console.log(e); 
 console.log("event"); 
 

       const orgId= localStorage.getItem('organizationid'); 
   console.log(orgId);
  const divId=e;
 
  console.log(this.billdeskService.getDepartmentListDLLByOrgAndDivId(orgId,divId).then(res => this.departmentlist = res));
  console.log('stateliststart'); 
    }


changeRole(e){
console.log(e); 
  this.selectedRoleName=e.value.RoleTypeName;
console.log(this.selectedRoleName);
    this.selectedRoleID=e.value.RoleTypeID;
    }  


changeDepartment(e){
console.log(e); 
  this.selectedDeptName=e.value.DeptName;
console.log(this.selectedDeptName);
    this.selectedDeptID=e.value.DeptID;
    } 
    
    
     changeCity(e) {

console.log(e); 
 
this.selectedCityName=e.value.DistrictName;



console.log(this.selectedCityName);
    this.selectedCity=e.value.DistrictID;

 
//  this.form.controls['City'].setValue(e.value.DistrictID); 
// console.log(e);
  }


  validateMobile(mobile){
    this.form.controls['UserLoginID'].setValue(mobile); 
    var uname = new String(mobile) ;
     if(uname.length==10){
       var request="/userprofile/checkuserloginid?UserLoginID="+mobile+"&ChkUserID="+this.id;
       var response="checkuserloginidResult"; 
           this.api.geExistData(request,response).then((res:any) =>{
     if(res.Status==200){
         this.mobilevalidata = false;
       }else{
         this.mobilevalidata = true;
           }}); 
      }
     }
 }
 