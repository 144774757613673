<!-- <mat-spinner *ngIf="visibleloader"  style="position: fixed;left: 0px;top: 0px;width: 100%;height: 100%;margin-left: 48%;margin-top: 20%; z-index: 9999;" > <img src="../../assets/images/loading.gif" width="300"></mat-spinner> -->
<style>
    @media print {
        @page {
            size: A4 landscape;
            overflow: scroll;
        }

        .printbutton {
            display: none !important;
        }
    }


    /* #print-section {
    overflow-y: visible !important;
    position: relative;
  } */
    #content {
        zoom: 80%;
    }


    /* .h2, h2 {
    font-size: 1.5rem !important;
} */
</style>

<div *ngIf="visibleloader"
    style="position: fixed;left: 0px;top: 0px;width: 100%;height: 100%;margin-left: 37%;margin-top: 10%; z-index: 9999;">
    <img src="../../assets/images/loading.gif">
</div>

<br>
<!-- &nbsp;&nbsp;&nbsp;&nbsp; <a     [routerLink]="['/InvoiceList']"><button class="btn btn-info btn-lg">Back</button> -->
<!-- &nbsp;&nbsp;&nbsp;&nbsp; <a   (click)="back()"><button class="btn btn-info btn-lg">Back</button> -->
<!-- </a>   -->

<!-- this is for  UserVerify Model -->

<div class="container" *ngIf="otpDialog">
   
    <form [formGroup]="otpform"  (ngSubmit)="onClicklogin()">

        <h1 [hidden]="isOtpInvalidError" class="text-danger text-center alert alert-danger"
        style="width:94%;margin-top:1% !important">Please Enter Valid Code</h1>
      <h1 [hidden]="isOtpEmptyError" class="text-danger text-center alert alert-danger"
        style="width:94%;margin-top:1% !important">All field Is Require</h1>
        <div class="container height-100 d-flex justify-content-center align-items-center">
            <div class="position-relative">
                <div class="card p-2 text-center">
                    <h6>Please enter Secret PIN <br> to verify your account</h6>
                    <div> <span>This Code Generate By Your Dashboard</span> <small>*******</small> </div>
                    <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2"> <input
                            class="m-2 text-center form-control rounded" type="text" #first  formControlName="first" maxlength="1"  (keyup)="move($event,'',first,second)" (keypress)="numberOnly($event)"   /> <input
                            class="m-2 text-center form-control rounded" type="text"  #second    formControlName="second"  maxlength="1" (keyup)="move($event,first,second,third)" (keypress)="numberOnly($event)"  /> <input
                            class="m-2 text-center form-control rounded" type="text"   #third  formControlName="third" maxlength="1" (keyup)="move($event,second,third,fourth)" (keypress)="numberOnly($event)" /> <input
                            class="m-2 text-center form-control rounded" type="text"  #fourth    formControlName="fourth"  maxlength="1" (keyup)="move($event,third,fourth,'')" (keypress)="numberOnly($event)"    /> </div>
                    <div class="mt-4"> <button  class="btn btn-danger px-4 validate" type="submit" >Validate</button> </div>
                </div>


            </div>
        </div>


    </form>





</div>
<!-- this is for  UserVerify Model -->

<div *ngIf="!otpDialog">
    <form class="form-horizontal" *ngFor="let product of products;" id="content" #content>


        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">
            INVOICE
            DETAIL </h1>
        <div class="card-body" style="background-color:#fff; ">
            <h1 class="card-title">
                Vendor Details
                <p id="demo"></p>
            </h1>




            <div class="table-responsive table_css">
                <table class="table">

                    <tbody>
                        <tr>
                            <td>Vendor/Supplier Name</td>
                            <td> : {{product.VendorName}}</td>
                            <td></td>
                            <td> </td>
                        </tr>


                    </tbody>
                </table>
            </div>





            <br>

        </div>


        <div class="card-body" style="background-color:#fff; ">




            <h1 class="card-title">
                Organization Details<p id="demo"></p>
            </h1>




            <div class="table-responsive table_css">
                <table class="table">

                    <tbody>
                        <tr>
                            <td>Organization Name </td>
                            <td>: {{product.OrgName}}</td>
                            <td> Division Name</td>
                            <td> : {{product.DivName}}</td>
                        </tr>
                        <tr>

                            <td>Department Name</td>
                            <td>: {{product.DeptName}}</td>
                            <td> </td>
                            <td></td>
                        </tr>

                    </tbody>
                </table>
            </div>





            <br>

        </div>


        <div class="card-body" style="background-color:#fff; ">
            <h1 class="card-title">
                Product details

            </h1>





            <div class="table-responsive table_css">
                <table class="table">

                    <tbody>

                        <tr>
                            <td>Product Category</td>
                            <td>:{{product.ItemCategoryName}}</td>
                            <td> Product</td>
                            <td>:{{product.ItemName}}</td>

                        </tr>

                        <tr>
                            <td> Invoice Purpose</td>
                            <td> :{{product.InvoiceHeadItemName}}</td>
                            <td> Payment Frequency</td>
                            <td> :{{product.InvoiceForName}}</td>
                        </tr>





                    </tbody>
                </table>
            </div>








        </div>
        <div class="card-body" style="background-color:#fff; ">
            <h1 class="card-title">
                Invoice details

            </h1>





            <div class="table-responsive table_css">
                <table class="table">

                    <tbody>

                        <tr>
                            <td>
                                Bill ID</td>
                            <td> :{{product.InvoiceID}}</td>
                            <td>Vendor Invoice No</td>
                            <td>:{{product.VendorInvoiceNo}}</td>
                        </tr>
                        <tr>
                            <td>InvoiceName</td>
                            <td>:{{product.InvoiceName}}</td>
                            <td>Invoice Date </td>
                            <td>:{{product.InvoiceDate}}</td>
                        </tr>

                        <tr>
                            <td> Attachment Invoice</td>
                            <td> :<a href="https://billdeskapi.aisect.org{{product.InvoiceAttechmentURL}}"
                                    target="_blank"><i class="fa fa-file-pdf-o" style="font-size:48px;color:red"
                                        title="Click For Open Document"></i></a>
                            </td>
                            <td>Prior Approval Letter </td>
                            <td> :
                                <span *ngIf="product.ApprovalLetterAttachmentURL;"><a
                                        href="https://billdeskapi.aisect.org/{{product.ApprovalLetterAttachmentURL}}"
                                        title="Click Here to View" target="_blank"> <i class="fa fa-file-pdf-o"
                                            style="font-size:48px;color:red"></i></a></span>

                                <span *ngIf="!product.ApprovalLetterAttachmentURL;"><a
                                        href="../assets/images/imagenotavailable.png" title="Image" target="_blank"> <i
                                            class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i></a></span>
                                <!-- <a href="http://services.aisect.org/{{product.ApprovalLetterAttachmentURL}}" target="_blank" ><i class="fa fa-file-pdf-o" style="font-size:48px;color:red" title="Click For Open Document"></i></a> -->
                            </td>
                        </tr>
                        <tr>
                            <td>Validity ExpireOn </td>
                            <td> :{{product.ValidityExpireOn}}</td>
                            <td>Year</td>
                            <td>:{{product.InvoiceYear}}</td>
                        </tr>
                        <tr>
                            <td>Month</td>
                            <td> :{{product.InvoiceMonth}}</td>
                            <td>Warranty/AMC/Any other imp. caption</td>
                            <td>:{{product.WarrantyImp}}</td>
                        </tr>
                        <tr>
                            <td>Dept. Contact Person Name </td>
                            <td> :{{product.DepttContactPerson}}</td>
                            <td>Priority</td>
                            <td>:{{product.PriorityName}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>








        </div>







        <div class="card-body table_css">
            <h1 class="card-title">
                Payment Details
            </h1>


            <div class="table-responsive" style="">
                <table class="table">

                    <tbody>
                        <tr>
                            <td>Total Cost Without GST(A)</td>
                            <td> :{{product.TotalCostWithoutGST}} </td>
                            <td>Discount Percent(B)</td>
                            <td>:{{product.DiscountPercent}}</td>
                        </tr>
                        <tr>
                            <td>Discount Amount(C=A*B/100)</td>
                            <td> :{{product.DiscountAmount}} </td>
                            <td>Discounted Cost (D=A-C)*</td>
                            <td>:{{product.TotalAmountAfterDiscount}}</td>
                        </tr>
                        <tr>
                            <td> TDS In Percent(E)*</td>
                            <td> :{{product.TDSInPercent}} </td>
                            <td>TDS In Amount(F=D*E/100)*</td>
                            <td>:{{product.TDSInAmount}}</td>
                        </tr>
                        <tr>
                            <td> Amount After TDS(G=D-F) *</td>
                            <td> :{{product.TotalAmountAfterTDS}} </td>


                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td> IGST in Percent(H)*</td>
                            <td>:{{product.IGSTPer}} </td>
                            <td> Total IGST Amount(K=D*H/100)*</td>
                            <td> :{{product.IGSTAmount}} </td>

                        </tr>
                        <tr>
                            <td> CGST in Percent(I)*</td>
                            <td> :{{product.CGSTPer}} </td>

                            <td>Total CGST Amount(L=D*I/100)*</td>
                            <td>:{{product.CGSTAmount}} </td>

                        </tr>
                        <tr>
                            <td>SGST in Percent(J)*</td>
                            <td>:{{product.SGSTPer}} </td>
                            <td>Total SGST Amount(M=D*J/100)*</td>
                            <td> :{{product.SGSTAmount}} </td>


                        </tr>
                        <tr>

                            <td>Total Amt Payable to Vendor(N=G+L+M)*</td>
                            <td>:{{product.TotalPayableAmount}} </td>
                            <td> TDS Payable of Behalf of Vendor to govt(O=F)*</td>
                            <td> :{{product.TDSInAmountToGovt}} </td>

                        </tr>

                        <tr>
                            <td>Total Invoice Value (Q=N+O+P)*</td>
                            <td>:{{product.TotalInvoiceValue}} </td>
                            <td> </td>
                            <td> </td>

                        </tr>

                    </tbody>
                </table>






            </div>


        </div>

        <div class="card-body table_css">
            <h1 class="card-title">
                Transaction Details
            </h1>
            <div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>PO Batch No.</td>
                            <td>: {{product.DisplayBatchNo}}</td>
                            <td>Payment Mode</td>
                            <td>: {{product.RespTransactionMode}}</td>

                        </tr>
                        <tr>
                            <td>PO Date</td>
                            <td>: {{product.RespTransactionDate}}</td>
                            <td>Payment Status</td>
                            <td>: {{product.RespMessage}}</td>

                        </tr>
                        <tr>
                            <td>Transaction ID</td>
                            <td>: {{product.RespTransactionID}}</td>
                            <td>Remark</td>
                            <td>: {{product.Remark}}</td>

                        </tr>

                        <tr>
                            <td>Transaction Date</td>
                            <td>: {{product.RespTransactionDate}}</td>
                            <td>Receipt</td>
                            <td>: <span *ngIf="product.ReceiptImageUrl"><a
                                        href="https://billdeskapi.aisect.org/{{product.ReceiptImageUrl}}"
                                        title="Click Here to View" target="_blank"> <i class="fa fa-file-pdf-o"
                                            style="font-size:48px;color:red"></i></a></span>
                            </td>

                        </tr>
                        <tr>
                            <td>Transaction Amount</td>
                            <td>: {{product.RespTransactionAmount}}</td>
                            <td> </td>
                            <td> </td>

                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <div class="col-sm-3">
Payment Transaction ID
        </div>
        <div class="col-sm-3">
    :{{product.PaymentTransactionID}}
    </div> -->



            <div class="row">
                <!-- <div class="col-sm-3">
Resp Code
            </div>
            <div class="col-sm-3">
        :{{product.RespCode}}
        </div> -->
                <!-- <div class="col-sm-3">
Resp Message
            </div>
            <div class="col-sm-3">
:{{product.RespMessage}}
    
            </div> -->
            </div>









        </div>

        <div class="card-body" style="background-color:#fff; ">
            <div class="row" style="    text-align: center;">
                <div class="col-sm-4  ">
                    <div class="">
                        <button pButton pRipple class="p-button-rounded p-button-info btn-lg printbutton"
                            (click)="viewProduct(product.InvoiceID)">View Tracking</button>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="" *ngIf="buttonhidenew">
                        <div><button pButton pRipple class="p-button-rounded p-button-success btn-lg printbutton"
                                (click)="statusUpdateDialog()">Click Here to Approve</button></div>
                    </div>
                </div>
                <div class="col-sm-4" [hidden]="buttonaccess">
                    <div class="" *ngIf="product.POOrderID">
                        <button pButton pRipple class="p-button-rounded p-button-primary btn-lg printbutton"
                            *ngIf="product.PaymentTransactionID==''" (click)="paymentUpdateDialog()">Payment
                            Update</button>
                    </div>
                </div>
            </div>







            <p-dialog [(visible)]="productDialog" [style]="{width: '800px'}" header="Tracking Invoice" [modal]="true"
                styleClass="p-fluid">
                <ng-template pTemplate="content">


                    <div class="container">

                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Sr.No</th>
                                    <th>Date Time</th>
                                    <th>Invoice Stage</th>
                                    <th>Status</th>
                                    <th> Comment</th>
                                    <th>Sender</th>
                                    <th>Receiver </th>
                                    <th>Forward to</th>
                                </tr>
                            </thead>
                            <tbody>

                                <!-- <div *ngIf="product.StageName=='Paid';" style="color:green">Success</div>
                    
                    <div *ngIf="product.StageName=='Registered';" style="color:orangered">In Process</div> -->
                                <tr *ngFor="let data of trackingdata;let i = index">


                                    <!-- <td *ngIf="data.Message!='';" >{{i+1}}</td>
            <td *ngIf="data.Message!='';" >{{data.CreatedDate}}</td><td *ngIf="data.Message!='';">{{data.StageName}} </td><td *ngIf="data.Message!='';">{{data.Description}}</td> <td *ngIf="data.Message!='';">  {{data.Message}}</td><td *ngIf="data.Message!='';">{{data.sendername}}</td><td *ngIf="data.Message!='';" >{{data.ReciverName}}</td><td *ngIf="data.Message!='';">{{data.ForwordTo}}</td> -->
                                    <td>{{i+1}}</td>
                                    <td>

                                        <!-- {{data.CreatedDate}} -->


                                        {{ data.CreatedDate | date:'dd-MMMM-yyyy h:mm' }}
                                    </td>
                                    <td>{{data.StageName}} </td>
                                    <td>{{data.Description}}</td>
                                    <td> {{data.Message}}</td>
                                    <td>{{data.sendername}}</td>
                                    <td>{{data.ReciverName}}</td>
                                    <td>{{data.ForwordTo}}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>



                </ng-template>

                <ng-template pTemplate="footer" style="color:#fff;background: maroon;">



                    <button pButton pRipple label="Cancel" [style]="{color:'#fff'}" icon="pi pi-times"
                        class="p-button-text" (click)="hideDialog()"></button>
                    <!-- <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button> -->
                </ng-template>
            </p-dialog>


            <!-- <p-dialog [(visible)]="statusupdateDialog" [style]="{width: '800px'}" header="Tracking Invoice" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        
       
<div class="container">
          
   <div class="card-body" style="background-color:#fff; ">
    
    <form class="form-horizontal" [formGroup]="form2">
        <div class="row">
            <div class="position-relative  form-group col-sm-4"> </div> 
            <div class="position-relative  form-group col-sm-4">  <h1 style="text-align: center;">Update Status</h1>  </div> 
            <div class="position-relative  form-group col-sm-4"> 
    
    
            </div> 
              
                               
        <div class="row">
    <div class="position-relative  form-group col-sm-6">
        <label for="status" class="col-form-label">Update Invoice Status<span>*</span></label>
        <div class="">
         
    
    
    <select  name="status" id="status" class="form-control"   formControlName="status">
    
    <option value="1">Active</option>
    <option value="0">In Active</option>
    
    
    </select>
    <app-field-error-display [displayError]="isFieldValid('status')" errorMsg="Please Enter Is Active "   style="background-color: #791820;
            border-color: #f5c6cb;" class="alert-danger">
    </app-field-error-display> 
        </div>
    </div> 
    
    <div class="position-relative  form-group col-sm-6">
        <label for="comment" class="col-form-label">Comment<span>*</span></label>
        <div class="">
         
     <textarea class="form-control" name="comment" id="comment"  formControlName="comment"> </textarea>
    <app-field-error-display [displayError]="isFieldValid('comment')" errorMsg="Please Enter Is Active "   style="background-color: #791820;
            border-color: #f5c6cb;" class="alert-danger">
    </app-field-error-display> 
        </div>
    </div> 
    </div>
    
    <div class="row">
        <div class="position-relative  form-group col-sm-4"> </div> 
        <div class="position-relative  form-group col-sm-4" style="text-align: center;">        
        <div class="">
            <button class="btn btn-success"  (click)="statusupdate()">Update</button>
        </div>
    
    </div>
       <div class="position-relative  form-group col-sm-4">  </div>
        </div>
    
    </div>
    
      
    </form>
    </div>
  </div>
  

 
    </ng-template>
    
    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;">


        
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
    </ng-template>
</p-dialog> -->








            <p-dialog [(visible)]="paymentupdateDialog" [style]="{width: '450px',color:'#fff'}"
                header="Payment Status Update" [modal]="true" styleClass="p-fluid">
                <ng-template pTemplate="content">


                    <form [formGroup]="paymentform" (ngSubmit)="paymentupdate()">

                        <br>
                        <br>

                        <div class="row">



                            <!-- <div class="position-relative form-group col-sm-6">
                    <label for="InvoiceName" class="col-form-label">Invoice Name <span>*</span></label>
                    <div class="">
                        <input name="InvoiceName"  id="InvoiceName" placeholder="Please Enter Invoice Name" type="text" class="form-control" formControlName="InvoiceName">
                        <app-field-error-display [displayError]="isFieldValid('InvoiceName')" errorMsg="Please Enter Invoice Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div> -->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="RespTransactionID">TransactionID:</label>
                                    <input type="text" class="form-control" name="RespTransactionID"
                                        id="RespTransactionID" formControlName="RespTransactionID">
                                </div>
                                <!-- <div class="form-group">
                <label for="RespCode">Code:</label>
                <input type="text" class="form-control" id="RespCode" name="RespCode" formControlName="RespCode">
              </div> -->
                                <div class="form-group">
                                    <label for="RespCode"> Transaction Status:</label>
                                    <!-- <input type="text" class="form-control" id="RespMessage" name="RespMessage"  formControlName="RespMessage"> -->

                                    <select class="form-control" id="RespMessage" name="RespMessage"
                                        formControlName="RespMessage">
                                        <option [ngValue]="null" disabled selected>Please Select</option>
                                        <option value="success">Paid</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="RespTransactionDate">Transaction Date :</label>
                                    <input type="date" class="form-control" id="RespTransactionDate"
                                        name="RespTransactionDate" formControlName="RespTransactionDate">
                                </div>
                                <div class="form-group">
                                    <label for="ReceiptImageUrl"> Receipt Acknowledgement :</label>
                                    <!-- <input type="file" class="form-control" id="ReceiptImageUrl" name="ReceiptImageUrl" formControlName="ReceiptImageUrl" > -->
                                    <!-- <input type="file" #Image1 accept="*" (change)="onFileChange($event)"    name="ReceiptImageUrl" id="ReceiptImageUrl"    class="form-control" formControlName="ReceiptImageUrl">
      -->
                                    <div class="">
                                        <i class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i>
                                        <input type="file" #Image accept="*" (change)="onFileChange($event)"
                                            name="ReceiptImageUrl" id="ReceiptImageUrl" class="form-control"
                                            formControlName="ReceiptImageUrl">
                                    </div>
                                </div>

                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="RespTransactionMode">Transaction Mode :</label>
                                    <select class="form-control" id="RespTransactionMode" name="RespTransactionMode"
                                        formControlName="RespTransactionMode">
                                        <option [ngValue]="null" disabled selected>Select Payment Mode</option>
                                        <option value="neft">NEFT</option>
                                        <option value="rgts">RTGS</option>
                                        <option value="upi"> UPI</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="RespTransactionAmount">Transaction Amount :</label>
                                    <input type="number" class="form-control" id="RespTransactionAmount"
                                        name="RespTransactionAmount" formControlName="RespTransactionAmount">
                                </div>
                                <div class="form-group">
                                    <label for="Remark">Remark:</label>
                                    <input type="text" class="form-control" id="Remark" name="Remark"
                                        formControlName="Remark">
                                </div>

                            </div>


                        </div>

                        <div class="row" style="margin-left: 40%;margin-top: 5%;">

                            <button type="submit" class="btn btn-info btn-sm" pButton pRipple
                                [style]="{width:'35%',padding:'5%',background:'#f1592a',border:'1px solid #f1592a'}">Submit</button>
                        </div>
                    </form>

                </ng-template>




                <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;">



                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                        [style]="{color:'#fff'}" (click)="hideDialog()">
                    </button>
                </ng-template>

            </p-dialog>



            <p-dialog [(visible)]="statusupdateDialog" [style]="{width: '450px',color:'#fff',background:'red'}"
                header="Invoice Status Update" [modal]="true" styleClass="p-fluid">
                <ng-template pTemplate="content">


                    <form [formGroup]="statusform">

                        <br><br>
                        <div class="row">

                            <div class="form-group">
                                <label for="StageID" class="col-form-label">Stage<span>*</span></label>
                                <div class="">
                                    <!-- <input type="text" name="InvoiceYear"  placeholder="Please Enter  Invoice Year." id="InvoiceYear" class="form-control"  formControlName="InvoiceYear"> -->

                                    <select name="StageID" id="StageID" class="form-control" formControlName="StageID">
                                        <option [ngValue]="null" disabled selected>Select Stage</option>
                                        <option *ngFor="let stagelists of stagelist" [ngValue]="stagelists.StageID">
                                            {{stagelists.StageName}}</option>


                                    </select>


                                    <!-- <app-field-error-display [displayError]="isFieldValid('InvoiceYear')" errorMsg="Please Enter  Year"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display> -->
                                </div>
                            </div>

                            <!-- <div class="form-group">
                <label for="StageID">StageID:</label>
                <input type="text" class="form-control" name="StageID" id="StageID"  formControlName="StageID">
              </div> -->

                            <div class="form-group">
                                <label for="Message"> Description:</label>
                                <input type="text" class="form-control" id="Message" name="Message"
                                    formControlName="Message">
                            </div>




                        </div>



                        <div class="row" style="margin-left: 40%;
    margin-top: 5%;">

                            <button type="submit" class="btn btn-info btn-sm"
                                (click)="statusupdate(product.InvoiceID,product.VendorInvoiceNo)"
                                [style]="{width:'35%',padding:'5%',background:'#f1592a',border:'1px solid #f1592a'}">Submit</button>
                            <!-- <button class="btn btn-primary btn-lg" type="submit"  >Submit</button> -->
                            <!-- <button type="submit" class="btn btn-info btn-sm"  pButton pRipple     [style]="{width:'35%',padding:'5%',background:'#f1592a',border:'1px solid #f1592a'}" >Submit</button>  -->

                        </div>
                    </form>

                </ng-template>

                <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;">



                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                        (click)="hideDialog()" [style]="{color:'#fff'}"></button>
                    <!-- <button pButton pRipple label="Save" type="submit" icon="pi pi-check" class="p-button-text"  [style]="{color:'#fff'}"></button> -->
                </ng-template>

            </p-dialog>
        </div>
        <br><br>
        <div style="text-align:center;">
            <!-- <span class="printbutton" style="text-align:center;"><button  type="button"   class="btn btn-info  btn-sm" (click)="print_page()"  style="text-align:center;font-size:20px;color:white;width: 25%;border-radius: 30%; " >Print <button class="print-button"><span class="print-icon"></span></button></button>
</span>    -->
            <button class="print-button" (click)="print_page()"><span class="print-icon printbutton"></span></button>
        </div>


        </form>

        </div>