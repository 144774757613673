import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'carecterhide'
})
export class CarecterhidePipe implements PipeTransform {

  // transform(value: string): string {
  //   let newVal = value.replace(/\d{2}$/, 'XX');
  //   return newVal;
  // }

  transform(value: string): string {
    return `${'X'.repeat(value.length - 2)}${value.slice(-2)}`;
  }
}
