import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router'; 
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
 

 
export class Country {
  constructor(public name: string, public code: string) {}
}

@Component({
  selector: 'app-edititem',
  templateUrl: './edititem.component.html',
  styleUrls: ['./edititem.component.css']
})
export class EdititemComponent implements OnInit {


  // under construction select option
  length: number;
 
  categoryCtrl: FormControl;
  categoryselected="Computer data"; 
  filteredCategory:Observable<any[]>;
 
  
  // under construction select option
  //Create FormGroup 
  itemdeail = [
    {
      productId: 446,
      artName: "CASUAL",
      shoppifyFlag: false,
      productPrice: 32.0
    },
    {
      productId: 459,
      artName: "test",
      shoppifyFlag: false,
      productPrice: 37.0
    },
    {
      productId: 461,
      artName: "test",
      shoppifyFlag: false,
      productPrice: 54.0
    },
    {
      productId: 465,
      artName: "test2212",
      shoppifyFlag: false,
      productPrice: 88.0
    },
    {
      productId: 469,
      artName: "test444",
      shoppifyFlag: false,
      productPrice: 57.0
    }
  ];
  itemdeails =  469;
  id:any;
  products:Billdesk[];

  organizaionlist: Billdesk[];
  categorylist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];
  inputcategoryid:any;
  inputcategoryname:any;
  cityList:Billdesk[];
  statuses:any;
  testproducts:any;
  boxvalue:string;
  type: string;
  items: SelectItem[] = [
    { label: 'Accessories', value: 'Accessories' },
    { label: 'Fitness', value: 'Fitness' },
    { label: 'Clothing', value: 'Clothing' },
  ];

 
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef,private _Activatedroute:ActivatedRoute
    ) {
   



      
    console.log('orgiststart');
    this.statuses = [
      { label: 'INSTOCK', value: 'instock' },
      { label: 'LOWSTOCK', value: 'lowstock' },
      { label: 'OUTOFSTOCK', value: 'outofstock' }
  ];





 

  this.billdeskService.getProducts().then((data) => (this.testproducts = data));

    // console.log(this.billdeskService.getOrganization().then(res => this.organizaionlist = res));
    console.log(this.billdeskService.getItemCategorylist().then((res:any) =>{

this.categorylist = res

 
    } ));
    console.log('stateliststart'); 

    console.log(this.billdeskService.getState().then(res => this.stateList = res));
    
    console.log('statelistend');





     
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  


 

  onSelectEvent(data:any){


    console.log("eventonclick",data);
  }
  ngOnInit() {


    
// code for get detail by Id
this.id=this._Activatedroute.snapshot.paramMap.get("id");
this._Activatedroute.paramMap.subscribe(params => { 
this.id = params.get('id');
console.log(this.id);
console.log(this.billdeskService.getItemByid(this.id).then((res:any) =>{
  this.products = res

  this.inputcategoryid=this.products[0].ItemCategoryID;
  this.inputcategoryname=this.products[0].ItemCategoryName;
}  ));
});

// code for get detail by Id




    this.form = this.fb.group({ 
      ItemName:[null, Validators.required],
      // ItemType:[null, Validators.required],
      // UnderOfParentID:[null, Validators.required],
      ItemCategoryID:[null, Validators.required],
      // ItemAbstract:[null, Validators.required],
      // ItemDescription:[null, Validators.required],
      // FAQListID:[null, Validators.required],
      // ItemImageUrl:[null, Validators.required],
      // ItemImageUrl2:[null, Validators.required],
      // ItemImageUrl3:[null, Validators.required],
      // ItemImageUrl4:[null, Validators.required],
      // ItemVideoSource:[null, Validators.required],
      // ItemVideoUrl:[null, Validators.required],
      // ItemVisibilityMode:[null, Validators.required],
      ActiveStatus:[null, Validators.required],
      // MetaTitle:[null, Validators.required],
      // MetaKeyWord:[null, Validators.required],
      // MetaDiscription:[null, Validators.required],
      // SchemaCodes:[null, Validators.required],
      // OGTags:[null, Validators.required],
      // UrlOptimization:[null, Validators.required],
      // FilterValue1:[null, Validators.required],
      // FilterValue2:[null, Validators.required],
      // WholeSalePriceMax:[null, Validators.required],
      // WholeSalePriceMin:[null, Validators.required],
      // ItemUnitPurchasePrice:[null, Validators.required],
      // ItemUnitSalesPrice:[null, Validators.required],
      // MSP:[null, Validators.required],
      // MRP:[null, Validators.required],
      // ItemDiscountInPercentage:[null, Validators.required],
      // ItemDiscountInAmount:[null, Validators.required],
      // Tax1Amount:[null, Validators.required],
      // Tax2Amount:[null, Validators.required],
      // GST:[null, Validators.required],
      // NetPayAmount:[null, Validators.required],
      // CoopenCode:[null, Validators.required],
      // GSTInPercentage:[null, Validators.required],
      // OpeningStock:[null, Validators.required],
      // AsofDate:[null, Validators.required],
      // IsLowStockWarning:[null, Validators.required],
      // LowStockQuantity:[null, Validators.required],
      // ItemCode:[null, Validators.required],
      // Barcode:[null, Validators.required],
      // ItemBrandName:[null, Validators.required],
      // ItemMedium:[null, Validators.required],
      // ItemSize:[null, Validators.required],
      // ItemWorkType:[null, Validators.required],
      // ItemBase:[null, Validators.required],
      // ItemColor:[null, Validators.required],
      // ItemMaterial:[null, Validators.required],
      // ItemEntryDate:[null, Validators.required],
      // ItemAddedBy:[null, Validators.required],
      // ItemToTopTen:[null, Validators.required],
      // ItemToHome:[null, Validators.required],
      // DisplayOrder:[null, Validators.required],
      // Comments:[null, Validators.required] 
    });

  

    this.form.valueChanges.subscribe((data) => {
      console.log("form change data", data.ItemCategoryID);//output 3,4 or 5
      })
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {
  
console.log(this.billdeskService.getCity(20).then(res => this.cityList = res));
  }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }
  valueChanged(e) {
    console.log('e', e);
 
this.boxvalue=e; 
}


  onSubmit() {
    var obj1 = 
   {
       
    "flag":"MSTItemCatagory_INSERT",
    "ItemID":this.id,
    "CreatedBy":"2",
    "CreatedByID":"4",
    "UpdatedDate":"10/22/21",
    "UpdatedBy":"", 
    "ItemType":"",
"UnderOfParentID":"",
"ItemAbstract":"",
"ItemDescription":"",
"FAQListID":"",
"ItemImageUrl":"",
"ItemImageUrl2":"",
"ItemImageUrl3":"",
"ItemImageUrl4":"",
"ItemVideoSource":"",
"ItemVideoUrl":"",
"ItemVisibilityMode":"",
"ActiveStatus":"",
"MetaTitle":"", 
"MetaKeyWord":"",
"MetaDiscription":"",
"SchemaCodes":"",
"OGTags":"",
"UrlOptimization":"",
"FilterValue1":"",
"FilterValue2":"",
"WholeSalePriceMax":"",
"WholeSalePriceMin":"",
"ItemUnitPurchasePrice":"",
"ItemUnitSalesPrice":"",
"MSP":"",
"MRP":"",
"ItemDiscountInPercentage":"",
"ItemDiscountInAmount":"",
"Tax1Amount":"",
"Tax2Amount":"",
"GST":"",
"NetPayAmount":"",
"CoopenCode":"",
"GSTInPercentage":"",
"OpeningStock":"",
"AsofDate":"",
"IsLowStockWarning":"",
"LowStockQuantity":"",
"ItemCode":"",
"Barcode":"",
"ItemBrandName":"",
"ItemMedium":"",
"ItemSize":"",
"ItemWorkType":"",
"ItemBase":"",
"ItemColor":"",
"ItemMaterial":"",
"ItemEntryDate":"",
"ItemAddedBy":"",
"ItemToTopTen":"",
"ItemToHome":"",
"DisplayOrder":"",
"Comments":"",
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);


if (this.form.valid) {
  console.log('form submitted');
 let updateurl= "/item/itemregistrationupdate";
this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{
  console.log("depin");
  console.log(res);
  console.log("depin");
  
   console.log(res.itemregistrationupdateResult);
  if(res.itemregistrationupdateResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/Item-List'])
    localStorage.setItem('updatedata', 'Success');
 }else{
   console.log('Please Try Again Later');
 }
 });

} else {
  this.validateAllFormFields(this.form);
}
 }

changeCategory(event:any){

console.log(event.value.ItemCategoryID);
console.log(event.value.ItemCategoryName);


this.form.get('ItemCategoryID').setValue(event.value.ItemCategoryID);  
}
 
 


// getSeverity(status: string) {
//   switch (status) {
//       case 'INSTOCK':
//           return 'success';
//       case 'LOWSTOCK':
//           return 'warning';
//       case 'OUTOFSTOCK':
//           return 'danger';
//   }
//  }




 
filterCategory(ItemCategoryName: string) {

console.log('filtercateogyr',this.categorylist);
  let arr = this.categorylist.filter(
    (category) => category.ItemCategoryName.toLowerCase().indexOf(ItemCategoryName.toLowerCase()) === 0
  );

  return arr.length ? arr : [{ ItemCategoryName: 'No Item found', ItemCategoryID: 'null' }];
}




setSelectedOption(event:any){
console.log(event);
this.inputcategoryid=event;
}

}