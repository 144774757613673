export function printDiv(divId: string,title:string) {

    console.log(title);
     console.log('hello world');
        const css = `<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css"
        integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4" crossorigin="anonymous">`;
        const printContents = document.getElementById(divId).innerHTML;
        const pageContent = `<!DOCTYPE html><html><head>${css} <style> 
        
        table, th, td {
          border: 2px outset;
          word-wrap: break-word;
          margin-right: auto;
            margin-left: auto;
          }
        .noprint{
    
          display:none;
        }
      
        .logocss{
    
          display: block;
      margin-left: auto;
      margin-right: auto;
        }
          </style> <title>${title}</title></head><body onload="window.print()">${printContents}</html>`;
        let popupWindow: Window;
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
          popupWindow = window.open(
            '',
            '_blank',
            'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
          );
          popupWindow.window.focus();
          popupWindow.document.write(pageContent);
            popupWindow.document.close();
          popupWindow.onbeforeunload = event => {
            popupWindow.close();
          };
          popupWindow.onabort = event => {
            popupWindow.document.close();
            popupWindow.close();
          };
        } else {
          popupWindow = window.open('', '_blank', 'width=600,height=600');
          popupWindow.document.open();
          popupWindow.document.write(pageContent);
          popupWindow.document.close();
        }
        
      }
      
      