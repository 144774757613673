import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer";
import { PrimeNGConfig } from 'primeng/api';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SelectItem } from 'primeng/api';
import { environment } from '../../environments/environment';
export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor: string;
}

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss', './invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {

  productDialog: boolean;
  statuses: any[];
  products: Billdesk[];
  productsbyid: Billdesk[];

  data: number = 0;
  pririty: any;
  dialogdata: string;
  itemcategory: any
  itemhead: any;
  invforcycle: any;
  organizationList: Billdesk[];
  ExistInv: any;
  InvoiceHeadItemNameList: any;
  product: Billdesk;
  billdesk: any;
  selectedProducts: any;
  insertdata: any;
  updatedata: any;
  submitted: boolean;
  //   OrganizationListResult:any[];

  cities: City[];
  customers: Customer[];
  successDialog: boolean;
  insertDialog: boolean;
  representatives: Representative[];
  updateDialog: boolean;
  paymentstatuses
  loading: boolean = true;
  inventoryStatus: Representative[];
  selectedCities: City[];

  ServiceUrl = environment.baseUrl;
  UserTypeID: any;
  UserID: any;
  screenHeight: any;
  screenWidth: any;

  first: number = 0;

  rows: number = 10;
  iterations: SelectItem[];
  onPageChange(event) {

    console.log('detai' + event);
    this.first = event.first;
    this.rows = event.rows;
  }
  //   OrganizationListResult:any;
  constructor(private billdeskService: BilldeskService, private messageService: MessageService, private confirmationService: ConfirmationService, public dialog: MatDialog, private primengConfig: PrimeNGConfig, public http: HttpClient) {

    this.UserTypeID = localStorage.getItem('UserTypeID');


    this.UserID = localStorage.getItem('UserID');
    // this.onResize();
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event?) {
  //   this.screenHeight = window.innerHeight;
  //   this.screenWidth = window.innerWidth;
  //   if (this.screenWidth < 600) {
  //     this.scroller = false;
  //     console.log('mobilescreen');
  //   }else{

  //     this.scroller = true;
  //     console.log('desktop');
  //   }


  //   console.log(this.screenHeight);
  //   console.log(this.screenWidth);
  // }

  ngOnInit() {

    console.log("window.screen.width");
    console.log(window.screen.width);
    console.log(localStorage);


    this.primengConfig.ripple = true;


    this.insertdata = localStorage.getItem('insertdata');

    this.updatedata = localStorage.getItem('updatedata');

    this.ExistInv = localStorage.getItem('exist');




    if (this.insertdata == 'Success') {

      this.organizationRegister();


    }

    if (this.updatedata == 'Success') {

      this.organizationUpdate();


    }


    if (this.ExistInv == 'exist') {

      this.organizationExist();

    }

    //  var  organizationList  =  this.billdeskService.getOrganization();
    //  this.billdeskService.getInvoiceDetail().then(res => this.products = res);

    this.billdeskService.getInvoicelist().then(res => this.products = res);



    // commented 09022022
    this.http.get(this.ServiceUrl + "/mststage/mststagelist").subscribe((res: any) => {

      var arr = Object.keys(res.mststagelistResult.dataobject).map(key => ({ label: res.mststagelistResult.dataobject[key].StageName, value: res.mststagelistResult.dataobject[key].StageName }));

      this.statuses = arr;
    });




    // commented 09022022
    this.http.get(this.ServiceUrl + "/priority/prioritylist").subscribe((res: any) => {

      var arr = Object.keys(res.prioritylistResult.dataobject).map(key => ({ label: res.prioritylistResult.dataobject[key].PriorityName, value: res.prioritylistResult.dataobject[key].PriorityName }));

      this.pririty = arr;
    });



    // commented 1372023
    this.http.get(this.ServiceUrl + "/invoiceheaditem/invoiceheaditemlist").subscribe((res: any) => {
      var arr1 = Object.keys(res.invoiceheaditemlistResult.dataobject).map(key => ({ label: res.invoiceheaditemlistResult.dataobject[key].InvoiceHeadItemName, value: res.invoiceheaditemlistResult.dataobject[key].InvoiceHeadItemName }));
      this.InvoiceHeadItemNameList = arr1;
    });









    this.http.get(this.ServiceUrl + "/invoicefor/invoiceforlist").subscribe((res: any) => {

      var arr = Object.keys(res.invoiceforlistResult.dataobject).map(key => ({ label: res.invoiceforlistResult.dataobject[key].InvoiceForName, value: res.invoiceforlistResult.dataobject[key].InvoiceForName }));

      this.invforcycle = arr;
    });





    this.http.get(this.ServiceUrl + "/itemcategory/itemcategorylist").subscribe((res: any) => {

      var arr = Object.keys(res.itemcategorylistResult.dataobject).map(key => ({ label: res.itemcategorylistResult.dataobject[key].ItemCategoryName, value: res.itemcategorylistResult.dataobject[key].ItemCategoryName }));

      this.itemcategory = arr;
    });




    this.http.get(this.ServiceUrl + "/invoiceheaditem/invoiceheaditemlist").subscribe((res: any) => {

      var arr = Object.keys(res.invoiceheaditemlistResult.dataobject).map(key => ({ label: res.invoiceheaditemlistResult.dataobject[key].InvoiceHeadItemName, value: res.invoiceheaditemlistResult.dataobject[key].InvoiceHeadItemName }));

      this.itemhead = arr;
    });




  }

  openNew() {
    // this.product = {};
    this.submitted = false;
    this.productDialog = true;
  }

  deleteSelectedProducts() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected products?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.products = this.products.filter(val => !this.selectedProducts.includes(val));
        this.selectedProducts = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
      }
    });
  }

  //   editProduct(product: Billdesk ) {
  //       this.Billdesk = {...product};
  //       this.productDialog = true;
  //   }

  viewProduct(product: Billdesk) {
    // this.product = {...product};
    this.productDialog = true;


    this.billdeskService.getOrganizationByid(product.OrgID).then(res => this.productsbyid = res);
  }

  successButton() {

    this.successDialog = true;
  }









  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Invoice", Detail: "Registered Successfully", Backgroundcolor: "#47c9a2" }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogdata = result;
    });
  }





  openDialogExist(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Invoice", Detail: "Already Exist", Backgroundcolor: "#FF0000" }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogdata = result;
    });
  }


  openDialogUpdate(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Invoice ", Detail: "Updated Successfully", Backgroundcolor: "#0288d1" }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogdata = result;
    });
  }


  openDialogDelete(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Invoice ", Detail: "Delete Successfully", Backgroundcolor: "#ff4141" }
    });

    dialogRef.afterClosed().subscribe(result => {
      window.location.reload();
      this.dialogdata = result;
    });
  }



  organizationRegister() {
    localStorage.removeItem('insertdata');
    setTimeout(() => {
      // this.insertDialog = true;
      this.openDialog();

    }, 500);
  }


  organizationUpdate() {
    localStorage.removeItem('updatedata');
    setTimeout(() => {
      // this.insertDialog = true;
      this.openDialogUpdate();

    }, 500);
  }


  organizationExist() {
    localStorage.removeItem('exist');
    setTimeout(() => {
      // this.insertDialog = true;
      this.openDialogExist();

    }, 500);
  }

  statusupdateButton() {
    this.updateDialog = true;
  }



  deleteProduct(product: Billdesk) {

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + product.OrgID + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {


        this.billdeskService.DeleteOrganizationByid(product.OrgID).then(res => this.products = res);

        // this.successButton();

        this.openDialogDelete();

      }
    });
  }

  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  saveProduct() {
    this.submitted = true;

    if (this.product.name.trim()) {
      if (this.product.id) {
        this.products[this.findIndexById(this.product.id)] = this.product;
        //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
        this.statusupdateButton();
      }
      else {
        this.product.id = this.createId();
        this.product.image = 'product-placeholder.svg';
        this.products.push(this.product);
        //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
      }

      //   this.products = [...this.products];
      this.productDialog = false;
      // this.product = {};
    }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }
}
interface City {
  name: string,
  code: string
}



// same sa new page

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {
    constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}