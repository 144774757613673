 
    
    
<p-progressSpinner strokeWidth="5" fill="#fff" 
animationDuration="1s" style="    z-index: 58;
margin-top: unset;
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
margin-left: 43%;
margin-top: 20%;"  *ngIf="visibleloader" >  </p-progressSpinner>




    <form class="form-horizontal" [formGroup]="form">


        
        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">UNIT ENTRY</h1>
        <div class="card-body" style="background-color:#fff; ">
        
        
        
              
            <h1 class="card-title" >
                Unit  Detail
            </h1>
         
        <div class="row">


          
                <div class="position-relative  form-group col-sm-6">
                    <label for="UnitName" class="col-form-label">Unit Name <span>*</span></label>
                    <div   [ngClass]="displayFieldCss('UnitName')">
                     
                     
                        <input name="UnitName" id="UnitName" placeholder="" type="text" class="form-control" formControlName="UnitName">
                     
                        
                         
                        <app-field-error-display [displayError]="isFieldValid('UnitName')" errorMsg="Please Enter Unit Name"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            
        
        
                    <div class="position-relative  form-group col-sm-6">
                        <label for="IFSCCode" class="col-form-label">Active Status <span>*</span></label>
                        <div class="">
                            <!-- <input type="text" name="IFSCCode" id="IFSCCode" class="form-control" formControlName="IFSCCode"> -->
        
                <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus">
                    <option [ngValue]="null" disabled selected >Please  Select Status</option>
                    <option [ngValue]="1">Active</option><option [ngValue]="0">InActive</option></select>
                             <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                 
                     
            
                    <div class="position-relative  form-check col-sm-6 offset-sm-6 ">
                        <div class="">
        
                            <br>
                            <br>
                            <br>
                            <button class="btn btn-primary btn-lg" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>  
            
                    </div>
           
          
                