import { Component, OnInit, Inject,ViewChild,ElementRef } from '@angular/core';
import { Product } from '../product';
import { ProductService } from '../productservice';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer"; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { printDiv } from './print-div';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 

import { environment } from '../../environments/environment';

import { ActivatedRoute } from '@angular/router';
@Component({

  selector: 'app-po-list-by-month-year',
  templateUrl: './po-list-by-month-year.component.html',
  styleUrls: ['./po-list-by-month-year.component.css']
})
export class PoListByMonthYearComponent implements OnInit {
  products: Product[];
  Totalamount:any;
  Totalinvoice:any;
  empList:any[];
  UserID:any;
  UserTypeID:any;
  DiscountAmount:any;
  TDSInAmount:any;
  TotInvoice:any;
  invoiceCount:any[];
  product: Product;
  id:any;
  i:any=0;
  ServiceUrl=environment.baseUrl;
  TotalTdsinamount:any;
  TotalDiscountAmount:any;
  PoBatchNo:any;
  // ServiceUrl=environment.baseUrl;
  CreatedDate:any;
  productDialog: boolean;
 
  dialogdata: string;
 
 
  selectedProducts:any;

  submitted: boolean;

  statuses: any[];
 
  customers: Customer[];
  insertdata:any;
  updatedata:any;
  representatives: Representative[];

  loading: boolean = true;
 
  yearsdetail:any;
  months:any;
 
  paymentform: FormGroup;
  MonthShow:any;
  YearShow:any;
  TotBIllAmount:any
TotTdsAmount:any
TotDiscount:any
TotNetPayble:any
  showmonth=false;
  monthyearstring:any;
  constructor(private http:HttpClient,private fb: FormBuilder,private productService: ProductService, private messageService: MessageService, private confirmationService: ConfirmationService,public dialog: MatDialog,public billdeskservice:BilldeskService,private route: ActivatedRoute) { 
 
    this.UserTypeID=localStorage.getItem('UserTypeID'); 
 
   this.UserID=localStorage.getItem('UserID'); 
   


    this.monthyearstring = this.route.snapshot.paramMap.get('id');



    if(this.monthyearstring!=1){
    
      var splitted = this.monthyearstring.split("-", 2); 
      
      this.getPODetailBySheet1(splitted[1],splitted[0]);
    }
  
    
  }
 
  ngOnInit(): void {

 
    this.paymentform = this.fb.group({ 
      
      InvoiceYear: [null, Validators.required],
      InvoiceMonth: [null, Validators.required]
 
      }); 
    this.yearsdetail=["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"];
    this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
 

  }

  print_page(){
    // this.buttonhide=false;

 
    const title = "Sheet-B-I"+this.MonthShow+this.YearShow;
//  const title="myfiledownload";
    setTimeout(() =>printDiv('content',title), 3000);
    
       }


  getPODetail(){
    this.TotInvoice=0; 
       
    this.TotBIllAmount=0; 

    
    this.TotTdsAmount=0; 
    
    this.TotDiscount=0; 
    
    this.TotNetPayble=0; 

     
    // console.log(localStorage.getItem('vendor_id'));
    // const vendor_id=localStorage.getItem('vendor_id');

var InvoiceMonth =this.paymentform.get('InvoiceMonth').value;

var InvoiceYear =this.paymentform.get('InvoiceYear').value;
 
        this.showmonth=true;
      this.YearShow=InvoiceYear;
    this.MonthShow=InvoiceMonth;
this.billdeskservice.getPOReportYearMonthWise(InvoiceYear,InvoiceMonth).then(data => this.products = data); 
        this.http.get(this.ServiceUrl+'/pobatchregistration/pobatchregistrationlistbyyearmonth?Year='+InvoiceYear+'&Month='+InvoiceMonth).subscribe((profileres:any)=>{
        const total =profileres.pobatchregistrationlistbyyearmonthResult.dataobject.length;
 
         

               this.TotInvoice=[]; 
       
               this.TotBIllAmount=[]; 

               
               this.TotTdsAmount=[]; 
               
               this.TotDiscount=[]; 
               
               this.TotNetPayble=[]; 

        for (let i = 0; i < total; i++) {
    
          this.TotInvoice.push(profileres.pobatchregistrationlistbyyearmonthResult.dataobject[i].TotInvoice);
          
          this.TotBIllAmount.push(profileres.pobatchregistrationlistbyyearmonthResult.dataobject[i].TotalCostWithoutGST);
          
          this.TotTdsAmount.push(profileres.pobatchregistrationlistbyyearmonthResult.dataobject[i].IGSTAMT);
          
          this.TotDiscount.push(profileres.pobatchregistrationlistbyyearmonthResult.dataobject[i].DiscountAmount);
          
          this.TotNetPayble.push(profileres.pobatchregistrationlistbyyearmonthResult.dataobject[i].TotalPayableAmount);
    
        }  
      
 
        var sumNumber = this.TotInvoice.reduce((acc, cur) => acc + Number(cur), 0)
  
        this.TotInvoice=sumNumber;

        var sumNumber1 = this.TotBIllAmount.reduce((acc, cur) => acc + Number(cur), 0)
 
        this.TotBIllAmount=sumNumber1;

        var sumNumber2 = this.TotTdsAmount.reduce((acc, cur) => acc + Number(cur), 0)
      
        this.TotTdsAmount=sumNumber2;


        var sumNumber3 = this.TotDiscount.reduce((acc, cur) => acc + Number(cur), 0)
   
        this.TotDiscount=sumNumber3;

        var sumNumber4 = this.TotNetPayble.reduce((acc, cur) => acc + Number(cur), 0)
    
        this.TotNetPayble=sumNumber4;
  
 
}); 
} 


getPODetailBySheet1(InvoiceYear:any,InvoiceMonth:any){

 
  this.TotInvoice=0; 
     
  this.TotBIllAmount=0; 

  
  this.TotTdsAmount=0; 
  
  this.TotDiscount=0; 
  
  this.TotNetPayble=0; 

 
      this.showmonth=true;
    this.YearShow=InvoiceYear;
  this.MonthShow=InvoiceMonth;
 this.billdeskservice.getPOReportYearMonthWise(InvoiceYear,InvoiceMonth).then(data => this.products = data); 
      this.http.get(this.ServiceUrl+'/pobatchregistration/pobatchregistrationlistbyyearmonth?Year='+InvoiceYear+'&Month='+InvoiceMonth).subscribe((profileres:any)=>{
      const total =profileres.pobatchregistrationlistbyyearmonthResult.dataobject.length;

       

             this.TotInvoice=[]; 
     
             this.TotBIllAmount=[]; 

             
             this.TotTdsAmount=[]; 
             
             this.TotDiscount=[]; 
             
             this.TotNetPayble=[]; 

      for (let i = 0; i < total; i++) {
  
        this.TotInvoice.push(profileres.pobatchregistrationlistbyyearmonthResult.dataobject[i].TotInvoice);
        
        this.TotBIllAmount.push(profileres.pobatchregistrationlistbyyearmonthResult.dataobject[i].TotalCostWithoutGST);
        
        this.TotTdsAmount.push(profileres.pobatchregistrationlistbyyearmonthResult.dataobject[i].IGSTAMT);
        
        this.TotDiscount.push(profileres.pobatchregistrationlistbyyearmonthResult.dataobject[i].DiscountAmount);
        
        this.TotNetPayble.push(profileres.pobatchregistrationlistbyyearmonthResult.dataobject[i].TotalPayableAmount);
  
      }  
     




      var sumNumber = this.TotInvoice.reduce((acc, cur) => acc + Number(cur), 0)
  
      this.TotInvoice=sumNumber;

      var sumNumber1 = this.TotBIllAmount.reduce((acc, cur) => acc + Number(cur), 0)
 
      this.TotBIllAmount=sumNumber1;

      var sumNumber2 = this.TotTdsAmount.reduce((acc, cur) => acc + Number(cur), 0)
 
      this.TotTdsAmount=sumNumber2;


      var sumNumber3 = this.TotDiscount.reduce((acc, cur) => acc + Number(cur), 0)
 
      this.TotDiscount=sumNumber3;

      var sumNumber4 = this.TotNetPayble.reduce((acc, cur) => acc + Number(cur), 0)
   
      this.TotNetPayble=sumNumber4;


}); 
} 

}
