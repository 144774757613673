
<form class="form-horizontal" [formGroup]="form"  *ngFor="let data of products"> 


    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">EDIT INVOICE HEAD ITEM</h1>
    <div class="card-body" style="background-color:#fff; ">
        <h1 class="card-title">
          Edit Invoice Head Item
        </h1>
        <div class="row">
            <div class="position-relative  form-group col-sm-6">
                <label for="HeadID" class="col-form-label">Invoice Head Category<span>*</span></label>
                <div class="">
           
                    <!-- <select  class="form-control" name="InvoiceHeadCategoryID" id="InvoiceHeadCategoryID"     formControlName="InvoiceHeadCategoryID" (ngModelChange)="getDivisionByOrg($event)"  [ngModel]="data.InvoiceHeadCategoryID"> 
                            
                        <option [ngValue]="null" disabled selected >Please Select Category</option>
                         
                        <option *ngFor="let head of headlist" [value]="head.InvoiceHeadCategoryID">{{head.HeadName}}</option>
                    </select>
                 -->



                    <div *ngIf="headlist">


                        <p-dropdown [options]="headlist" [(ngModel)]="data.InvoiceHeadCategoryID"
                            [autoDisplayFirst]="false" optionLabel="HeadName" formControlName="InvoiceHeadCategoryID"
                            [placeholder]="data.HeadName" optionValue="InvoiceHeadCategoryID" [filter]="true"
                            filterBy="HeadName"   >
                        </p-dropdown>

                    </div>

                    <app-field-error-display [displayError]="isFieldValid('InvoiceHeadCategoryID')" errorMsg="Please Select Head Category"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                
                </div>
            </div> 
    
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="InvoiceHeadName" class="col-form-label">Invoice Head Item Name  <span>*</span></label>
                <div class="">
                    <input type="text" name="InvoiceHeadName" id="InvoiceHeadName" class="form-control" formControlName="InvoiceHeadName" [(ngModel)]="data.InvoiceHeadItemName" >
         <app-field-error-display [displayError]="isFieldValid('InvoiceHeadName')" errorMsg="Please Enter Invoice Head Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
           
    
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="IsActive" class="col-form-label">Is Active<span>*</span></label>
                <div class="">
                     <select  name="IsActive" id="IsActive" class="form-control" formControlName="IsActive" [(ngModel)]="data.IsActive" >
                        <option [ngValue]="null" disabled selected >Select Status</option>
                                    
            <option value="1">Active</option>
            <option value="0">In Active</option>

         </select>
         <app-field-error-display [displayError]="isFieldValid('IsActive')" errorMsg="Please Enter Is Active "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->
    
            
          
            
    
            
            
    
            
          
    
            <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    
    