<br>&nbsp;&nbsp; <a     [routerLink]="['/PO-List']"  class="noprint"><button class="btn btn-info btn-lg">Back</button>
</a>    
 
<div class="card"   style="background-color:#fff; margin:50px;" >
 
    <form    [formGroup]="paymentform" (ngSubmit)="getPODetail()" class="noprint" style="text-align:center;padding:10px;"   >

     <div class="row"   style="padding:10px;">
        <div class="position-relative  form-group col-sm-3">
            <!-- <label for="InvoiceYear" class="col-form-label">Year<span>*</span></label> -->
            <div class="">
                  
                <select  name="InvoiceYear"    id="InvoiceYear" class="form-control" formControlName="InvoiceYear">
                    <option [ngValue]="null" disabled selected>Select Year</option>
                    <option *ngFor="let year of yearsdetail" [value]="year">{{year}}</option>
                

                </select>

        
            </div>
        </div>
    
    <div class="position-relative  form-group col-sm-3">
        <!-- <label for="InvoiceMonth" class="col-form-label">Month<span>*</span></label> -->
        <div class="">
              
            <select  name="InvoiceMonth"    id="InvoiceMonth" class="form-control"  formControlName="InvoiceMonth">
              <option [ngValue]="null" disabled selected>Select Month</option>
                <option *ngFor="let month of months" [value]="month">{{month}}</option>
            

            </select>
   
        </div>
    </div>




    <div class="position-relative  form-group col-sm-2">     
       <!-- <label for="InvoiceYear" class="col-form-label">&nbsp;</label> -->

        <div class="">
    <button type="submit" class="btn btn-info btn-sm"  pButton pRipple     [style]="{width:'35%',padding:'2%',background:'#f1592a',border:'1px solid #f1592a',borderRadius:'10px'}" >Search</button> 
        </div>
          
    </div>

    <div class="position-relative  form-group col-sm-4">
    </div>
</div>
</form>


  </div>
<div class="card-body" style="background-color:#fff; margin:50px;" id="content" #content>

    <img src="../../assets/images/3.png"  class="logocss" />
    <h2 class="card-title"  style="font-weight:bold;text-align: center;">
        Month Wise PO Batch List  (SHEET - B)
        
     </h2>
    <h3 class="card-title"   style="font-weight:bold;text-align: center;"  *ngIf="showmonth" > Month of {{MonthShow}} {{YearShow}}  </h3>  
   
<div class="card">
<p-table [value]="products" responsiveLayout="scroll" >
  <ng-template pTemplate="header">
      <tr> 
                <th  class="tabletd"  style="width:50px">SN  </th>
                <th  class="tabletd" style="width: 150px">PO Batch Number </th>
                <th  class="tabletd"  style="width: 150px">Created Date </th>
                <th  class="tabletd" style="width: 150px">Organization </th>
                <th  class="tabletd" style="width: 150px">NO of Invoice  </th>
                <th  class="tabletd" style="width: 150px">Bill ID  </th>
                 <th  class="tabletd" style="width: 200px">Total Amount </th>
                 <th  class="tabletd" style="width: 200px">IGST Amount </th>
                 <th  class="tabletd" style="width: 100px">Discount </th>
                 <th  class="tabletd"  style="width: 200px">Total Net Payable </th>
                

      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product  let-i="rowIndex" >
      <tr>
       <td   >{{i + 1}}</td>
          <!-- <td class="tabletd"> {{product.OrgName}}</td> -->
        
          <!-- <td>{{product.POOrderID}}</td> -->
 
       <td><a   [routerLink]="['/PO-Detail',product.POOrderID]" >{{product.DisplayBatchNo }}</a></td>

       <td>
        <!-- {{product.CreatedDate}} -->
         {{product.CreatedDate | date: 'dd/MM/yyyy'}}</td>
       <td>{{product.OrgName }}</td>
     
       <td>{{product.TotInvoice}}</td>  

       
       <td>{{product.InvoiceID}}</td>
       
       <td>{{product.TotalCostWithoutGST}}</td>  
       <td>{{product.IGSTAMT}}</td>  
       <td>{{product.DiscountAmount}}</td>  
       <td>{{product.TotalPayableAmount}}</td>  
        

    

  
      </tr>

      
  </ng-template>
 


<ng-template pTemplate="footer" >
<tr>
<th style="padding: 1%;text-align: right;" colspan="4" class="tabletd"> &nbsp; &nbsp; &nbsp;Grand Total </th>
 
  <th style="padding: 1%;" class="tabletd">{{TotInvoice}}</th>
 <th></th>
   <th style="padding: 1%;" class="tabletd">  {{TotBIllAmount}} </th>
  <th style="padding: 1%;" class="tabletd">  {{TotTdsAmount}} </th>
  <th style="padding: 1%;" class="tabletd">  {{TotDiscount}} </th>
  <th style="padding: 1%;" class="tabletd">  {{TotNetPayble}} </th>
 


</tr>
</ng-template>
</p-table>


</div> 

 
</div>


<div style="text-align: center;"> <button class="btn btn-info noprint" id="printbtn"  (click)="print_page()">Print&nbsp;&nbsp;<i class="fa fa-print" style="font-size:32px"></i></button></div>
  