import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-stage-registration',
  templateUrl: './stage-registration.component.html',
  styleUrls: ['./stage-registration.component.css']
})
export class StageRegistrationComponent implements OnInit {
  //Create FormGroup 


  organizaionlist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];

  cityList:Billdesk[];
  rolelist:Billdesk[];

  type: string;
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef) {
   
    // console.log('orgiststart');


    // console.log(this.billdeskService.getOrganization().then(res => this.organizaionlist = res));
    // console.log('stateliststart'); 

    console.log(this.billdeskService.getState().then(res => this.stateList = res));
    
    console.log('statelistend');

    console.log(this.billdeskService.getUserRole().then(res => this.rolelist = res));
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  
  ngOnInit() {
    this.form = this.fb.group({ 
      
      StageName: [null, Validators.required],
      // OwnerOf:[null, Validators.required],
      RoleID: [null, Validators.required],   ActiveStatus: [null, Validators.required]
      
      // Pending: [null, Validators.required],
      
      // History: [null, Validators.required],
      // Descp:[null, Validators.required] 
    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {
  
console.log(this.billdeskService.getCity(e).then(res => this.cityList = res));
  }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
       
"flag":"MSTItemCatagory_INSERT",
"StageID":"0",
"CreatedBy":"1",
"CreatedByID":"2",
"UpdatedDate":"",
"UpdatedBy":"", 
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);


if (this.form.valid) {
  console.log('form submitted');
let registerurl= "/mststage/mststageregistration";
this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
  console.log("depin");
  console.log(res);
  console.log("depin");
    console.log(res.mststageregistrationResult);
  if(res.mststageregistrationResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/Stage-List'])
   localStorage.setItem('insertdata', 'Success');
 }else{
   console.log('Please Try Again Later');
 }
 });


} else {
  this.validateAllFormFields(this.form);
}
 }


 }
 