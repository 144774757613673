import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BilldeskService } from '../billdesk.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {


  constructor(private billdeskService: BilldeskService,private route:Router) {


  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.billdeskService.IsMenuShow().toPromise().then((res: any) => {        
        let strUrl = (state.url).replace(/\//g,''); //"InvoiceRegistration";
        let isFound = ((res || {}).dataobject || []).find(x => x.url == strUrl);   
       console.log('state.url',state.url,'res',res)

        if(isFound==undefined){
          this.route.navigate(['/unauthrized'])

        }
        return (isFound && isFound.url) ? true : false;

      }).catch(()=>{
        return false;
      })
  }

}
