import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';
@Component({
  selector: 'app-userrolerightmapping',
  templateUrl: './userrolerightmapping.component.html',
  styleUrls: ['./userrolerightmapping.component.css']
})
export class UserrolerightmappingComponent implements OnInit {
 
  //Create FormGroup 


  menulist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];
  isShow = true;
  cityList:Billdesk[];
  headlist:Billdesk[];
  rolelist:Billdesk[];
  type: string;
  selectedMenuId:string;
  selectedMenuName:string='Please Select Menu';
  selectedRoleID:string;
  selectedRoleName:string='Please Select Role';

  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef) {
   
    
    // console.log(this.billdeskService.getUser().then(res => this.menulist = res));

    console.log(this.billdeskService.getUserRole().then(res => this.rolelist = res));
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  
  ngOnInit() {
    this.form = this.fb.group({ 
   
RoleTypeID: [null, Validators.required] , 

UserID: [null, Validators.required] ,
    
 
 ActiveStatus: [null, Validators.required]

    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {
    // this.Key.setValue(e.target.value, {
    //   onlySelf: true
    // })


    // let messageId = e.getAttribute('data-key');


    // this.type = this.elementRef.nativeElement.getAttribute('data-key');
    //let messageId = el.dataset.messageId;
//     console.log("Message Id: ", messageId);
// console.log(e); 
// console.log(e.target.dataset.Key);
 
// console.log(e.target.value);
console.log(this.billdeskService.getCity(20).then(res => this.cityList = res));
  }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
    "RoleMenuID":"0",
    "LastUpdate":"",                
    "LastDelete":"",  
    "StatusUpdate":"1",  
    "CreatedDate":"",                
    "CreatedBy":"",                        
    "CreatedByID":"1",                
    "UpdatedDate":"",                
    "UpdatedBy":""   
   };

   
   var obj2 = this.form.value;
console.log('netformdata');
console.log(obj2);
console.log('netformdata');
   
    // alert('false');
    var form_data = Object.assign(obj1, obj2);
 

       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"user_column_data":[
  form_data
]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);


if (this.form.valid) {
  console.log('form submitted');
  
  let url ="http://services.aisect.org/api/UserRoleRightsMapping";
  this.http.post(url, datarequest).toPromise().then((data:any)=>{
    
    
   console.log(data.UserRoleRightsMappingRegDetails);
 
 if(data.UserRoleRightsMappingRegDetails.message=='success'){

   console.log('Insert Success');
  
   this.route.navigate(['/User-Role-Right-List'])
localStorage.setItem('insertdata', 'Success');


 }
 else{

   console.log('Please Try Again Later');

 }
 
 });
} else {
  this.validateAllFormFields(this.form);
}
 }


 changeMenu(e){
  console.log(e); 
    this.selectedMenuName=e.value.UserFullName;
  console.log(this.selectedMenuName);
      this.selectedMenuId=e.value.UserID;
      }  



getMenuType(e){
  console.log(e); 
  
  console.log(e.target.value); 

  if(e.target.value=='secondary'){

    this.isShow=false;
  }else{

    this.isShow=true;
  }
    
      }  


      changeRole(e){
        console.log(e); 
          this.selectedRoleName=e.value.RoleTypeName;
        console.log(this.selectedRoleName);
            this.selectedRoleID=e.value.RoleTypeID;
            }     
      
 }
 