 
    
    
<p-progressSpinner strokeWidth="5" fill="#fff" 
animationDuration="1s" style="    z-index: 58;
margin-top: unset;
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
margin-left: 43%;
margin-top: 20%;"  *ngIf="visibleloader" >  </p-progressSpinner>




    <form class="form-horizontal" [formGroup]="form">


        
        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">ORGANIZATION REGISTRATION</h1>
        <div class="card-body" style="background-color:#fff; ">
        
        
        
              
            <h1 class="card-title" >
                Organization  Detail
            </h1>
         
        <div class="row">


          
                <div class="position-relative  form-group col-sm-6">
                    <label for="OrgName" class="col-form-label">Organization Name <span>*</span></label>
                    <div   [ngClass]="displayFieldCss('OrgName')">
                     
                     
                        <input name="OrgName" id="OrgName" placeholder="" type="text" class="form-control" formControlName="OrgName">
                     
                        
                         
                        <app-field-error-display [displayError]="isFieldValid('OrgName')" errorMsg="Please Enter Organization  Name"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="PANNo" class="col-form-label">PANCard<span>*</span></label>
                    <div class="">
                        <input type="text" name="PANNo" id="PANNo" class="form-control" formControlName="PANNo">
                        <app-field-error-display [displayError]="isFieldValid('PANNo')" errorMsg="Please Enter Pancard Number"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
 
        
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="GSTN" class="col-form-label">GSTN<span>*</span></label>
                    <div class="">
                        <input type="text" name="GSTN" id="GSTN" class="form-control" formControlName="GSTN">
        
                        <app-field-error-display [displayError]="isFieldValid('GSTN')" errorMsg="Please Enter GSTN"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            </div>
        
           
        
        </div>
        
            <div class="card-body" style="background-color:#fff; ">
                <h1 class="card-title">
                    Contact Detail
                </h1>
            <div class="row">
                <div class="position-relative form-group col-sm-6">
                    <label for="DeskMobileNo" class="col-form-label">Contact Number<span>*</span></label>
                    <div class="">
                        <input name="DeskMobileNo" id="DeskMobileNo" placeholder="" type="number" class="form-control" formControlName="DeskMobileNo">
                        <app-field-error-display [displayError]="isFieldValid('DeskMobileNo')" errorMsg="Please Enter Contact Number"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="DeskEmailID" class="col-form-label">Email<span>*</span></label>
                    <div class="">
                        <input type="email" name="DeskEmailID" id="DeskEmailID" class="form-control" formControlName="DeskEmailID">
         
                        <app-field-error-display [displayError]="isFieldValid('DeskEmailID')" errorMsg="Please Enter Email Id"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                
        
                
                <div class="position-relative  form-group col-sm-6">
                    <label for="BillingAddress" class="col-form-label">Address <span>*</span></label>
                    <div class="">
                        <textarea name="BillingAddress" id="BillingAddress" class="form-control" formControlName="BillingAddress"></textarea>
         
         
                        <app-field-error-display [displayError]="isFieldValid('BillingAddress')" errorMsg="Please Enter Address"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>


                <div class="position-relative  form-group col-sm-6">
                    <label for="State" class="col-form-label">State <span>*</span></label>
                    <div class="">
                     
        
                        <!-- <select  class="form-control" name="State" id="State"  formControlName="State"  (change)="changeState($event.target.value)"  >
                            <option [ngValue]="null" disabled selected >Please Select State</option>
                            
                            <option *ngFor="let statelist of stateList"      [value]="statelist.StateID"    >{{statelist.StateName}}</option>
                        


                        </select> -->


                        <p-dropdown [options]="stateList" [(ngModel)]="selectedState" optionLabel="StateName" [filter]="true" filterBy="StateName"
                        [showClear]="true" [placeholder]="selectedStateName"  (onChange)="changeState($event)"  name="State" id="State"  formControlName="State"   [style]="{ width: '100%' }">
                        <!-- <ng-template pTemplate="selectedItem">
                            <div class="country-item country-item-value" *ngIf="selectedState">
                                <div>{{selectedState}}</div>
                            </div>
                        </ng-template> -->
                        <ng-template let-state pTemplate="item">
                            <div class="country-item">
                                <div>{{state.StateName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>


                    <!-- <p-dropdown [options]="stateList" [(ngModel)]="selectedState" optionLabel="StateName" [filter]="true" filterBy="StateName"
                         placeholder="Select State"  (onChange)="changeState($event)"  name="State" id="State"  formControlName="State"  >
                     	<ng-template pTemplate="selectedItem">
                            <div class="country-item country-item-value" *ngIf="selectedState">
                                <div>nne{{selectedState}}</div>
                            </div>
                        </ng-template>
                         
                        <ng-template let-state pTemplate="item">
                            <div class="state-item">
                                <div>{{state.StateName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown> -->
                        <app-field-error-display [displayError]="isFieldValid('State')" errorMsg="Please Select State"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div> 
                <!-- <div class="position-relative  form-group col-sm-6">
                    <label for="City" class="col-form-label">City<span>*</span></label>
                    <div class="">
                         
                        <select  class="form-control" name="City" id="City"  formControlName="City">
                            <option [ngValue]="null" disabled selected >Please Select City</option>
                         <option *ngFor="let citylist of cityList"       [value]="citylist.DistrictID"       >{{citylist.DistrictName}}</option>
                        </select>
                    
                    
                        <app-field-error-display [displayError]="isFieldValid('City')" errorMsg="Please Select City"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
             -->


                <div class="position-relative  form-group col-sm-6">
                    <label for="City" class="col-form-label">City<span>*</span></label>
                    <div class="">
                      
                       <p-dropdown [options]="cityList" [(ngModel)]="selectedCity" optionLabel="DistrictName." [filter]="true" filterBy="DistrictName"
                            [showClear]="true" [placeholder]="selectedCityName"  (onChange)="changeCity($event)"  name="City" id="City"  formControlName="City"  [style]="{ width: '100%' }" >
                         
                            <ng-template let-country pTemplate="item">
                                <div class="country-item">
                                    <div>{{country.DistrictName}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <app-field-error-display [displayError]="isFieldValid('City')" errorMsg="Please Select City"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="PinCode" class="col-form-label">PinCode</label>
                    <div class="">
                        <input type="text" name="PinCode" id="PinCode" class="form-control" formControlName="PinCode">
        
                        <app-field-error-display [displayError]="isFieldValid('PinCode')" errorMsg="Please Enter PinCode"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
              
                 
                <div class="position-relative  form-group col-sm-6">
                    <label for="AuthPersonName" class="col-form-label">Contact Person Name<span>*</span></label>
                    <div class="">
                        <input type="text" name="AuthPersonName" id="AuthPersonName" class="form-control"  formControlName="AuthPersonName">
        
                        <app-field-error-display [displayError]="isFieldValid('AuthPersonName')" errorMsg="Please Enter  ContactPersonName"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="AuthPersonMobileNo" class="col-form-label">Contact Person No<span>*</span></label>
                    <div class="">
                        <input type="number" name="AuthPersonMobileNo" id="AuthPersonMobileNo" class="form-control"  formControlName="AuthPersonMobileNo">
                        <app-field-error-display [displayError]="isFieldValid('AuthPersonMobileNo')" errorMsg="Please Enter  ContactPersonNo"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            
        
                
                </div>
                </div>
        
                   
        
                <div class="card-body" style="background-color:#fff; ">
                    <h1 class="card-title">
                        Bank Account Detail
                    </h1>
                <div class="row">
                     
                    <div class="position-relative  form-group col-sm-6">
                        <label for="BankAcName" class="col-form-label">Account Name<span>*</span></label>
                        <div class="">
                            <input type="text" name="BankAcName" id="BankAcName" class="form-control"  formControlName="BankAcName"> 
                            <app-field-error-display [displayError]="isFieldValid('BankAcName')" errorMsg="Please Enter  Account Name"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="BankAcNo" class="col-form-label">Bank Account No <span>*</span></label>
                        <div class="">
                            <input type="number" name="BankAcNo" id="BankAcNo" class="form-control"  formControlName="BankAcNo">
                            <app-field-error-display [displayError]="isFieldValid('BankAcNo')" errorMsg="Please Enter  Bank Account"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
        
        
                    <div class="position-relative  form-group col-sm-6">
                        <label for="BankName" class="col-form-label">Bank Name<span>*</span></label>
                        <div class="">
                            <input type="text" name="BankName" id="BankName" class="form-control" formControlName="BankName">
        
                            
                            <app-field-error-display [displayError]="isFieldValid('BankName')" errorMsg="Please Enter  Bank Name"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
        
                    <div class="position-relative  form-group col-sm-6">
                        <label for="BankBranchName" class="col-form-label">Bank Branch<span>*</span></label>
                        <div class="">
                            <input type="text" name="BankBranchName" id="BankBranchName" class="form-control" formControlName="BankBranchName">
                            <app-field-error-display [displayError]="isFieldValid('BankBranchName')" errorMsg="Please Enter  Bank Branch"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="IFSCCode" class="col-form-label">Bank IFSC Code <span>*</span></label>
                        <div class="">
                            <input type="text" name="IFSCCode" id="IFSCCode" class="form-control" formControlName="IFSCCode">
        
        
                             <app-field-error-display [displayError]="isFieldValid('IFSCCode')" errorMsg="Please Enter  Bank IFSC Code"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="IFSCCode" class="col-form-label">Active Status <span>*</span></label>
                        <div class="">
                            <!-- <input type="text" name="IFSCCode" id="IFSCCode" class="form-control" formControlName="IFSCCode"> -->
        
                <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus">
                    <option [ngValue]="null" disabled selected >Please  Select Status</option>
                    <option [ngValue]="1">Active</option><option [ngValue]="0">InActive</option></select>
                             <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                 
                     
            
                    <div class="position-relative  form-check col-sm-6 offset-sm-6 ">
                        <div class="">
        
                            <br>
                            <br>
                            <br>
                            <button class="btn btn-primary btn-lg" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>  
            
                    </div>
           
          
                