 
<p-toast></p-toast>






<div class="card" style=" overflow-x: scroll;">
    <!-- <p-toolbar styleClass="p-mb-4"> -->
        <!-- <ng-template pTemplate="left"> -->
            <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button> -->
        <!-- </ng-template> -->

        <!-- <ng-template pTemplate="right"> -->

            <!-- <p-multiSelect style="width:200px;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
            <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
        <!-- </ng-template> -->
    <!-- </p-toolbar> -->
 
    <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="['ItemName' ,
    'ItemCategoryName',
 
    'CreatedDate']"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"  [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]">

        
        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 class="headingclass">PRODUCT LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                    &nbsp; &nbsp;  <a     [routerLink]="['/Item-Registration']" class="btn btn-success">Add <i class="fa fa-plus" aria-hidden="true"></i></a>
                </span>
            </div>

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
  
        </ng-template>
   
     
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="rating"  class="actionclass" >	Action </th>
              
                <th pSortableColumn="ActiveStatus" style="width: 150px">Active Status<p-sortIcon field="ActiveStatus"></p-sortIcon></th>
                <th pSortableColumn="ItemName" style="width: 150px">Product   Name<p-sortIcon field="ItemName"></p-sortIcon></th>
                 <th pSortableColumn="ItemCategoryName" style="width: 150px">Product Category  Name <p-sortIcon field="ItemCategoryName"></p-sortIcon></th>
                <!-- <th pSortableColumn="Comments" style="width: 150px">Comments<p-sortIcon field="Comments"></p-sortIcon></th>
                <th pSortableColumn="ActiveStatus" style="width: 150px">Active Status<p-sortIcon field="ActiveStatus"></p-sortIcon></th> -->
                          <th pSortableColumn="CreatedDate" style="width: 150px">	Created Date<p-sortIcon field="CreatedDate"></p-sortIcon></th>
                
            </tr>


            <tr>
                <th>

                </th>
                <th> 
                    <!-- <input pInputText type="text" (input)="dt.filter($event.target.value,'ActiveStatus' ,'contains')" placeholder="Active Status" /> -->
                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')" styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>
           
                </th>
<th>  
    <input pInputText type="text" (input)="dt.filter($event.target.value,'ItemName' ,'contains')" placeholder="Search ItemName" />
    </th>

<th>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'ItemCategoryName' ,'contains')" placeholder="Search Category" />
</th>

<!-- <th>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'ActiveStatus' ,'contains')" placeholder="Search Active Status" />
</th> -->
<th>
<input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')" placeholder="Search CreatedDate" />
</th>
 
   
                                                                                    

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">


                <td>
                    <!-- <a     [routerLink]="['/Edit-Item',product.ItemID]"><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"   style="margin-right: 4px;" ></button>
                    </a>    
            <button pButton pRipple icon="pi pi-eye"   class="p-button-rounded p-button-info" (click)="viewProduct(product.ItemID)"></button> 
                     
                 -->
                       <span class="p-column-title">Action:</span>
                       <span style="display: flex;align-items: center;justify-content: center;">
                           <a [routerLink]="['/Edit-Item',product.ItemID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                   style='color: #2459a6;' pTooltip="Edit Product Detail"></i></a>
                           <span pTooltip="View Product Detail"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                           border: 2px solid;
                           border-radius: 5px;' (click)="viewProduct(product.ItemID)"></i> </span>
                       </span>    
                    
                    </td>
          
                    
                    <td> 
<!--                         
                        <span *ngIf="product.ActiveStatus=='1'"  class="btn btn-success" style="color:white">Active</span>
                        <span  *ngIf="product.ActiveStatus!='1'"   class="btn btn-danger"  style="color:white" >In Active</span> -->
                        <span class="p-column-title">Status:</span>
                        <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
                        <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>
    
    
                 
                </td>
 
                <td>   <span class="p-column-title">Product Name </span> {{product.ItemName}}</td> 
                <td>   <span class="p-column-title">Product Category Name:</span> {{product.ItemCategoryName}}</td>
                <!-- <td>{{product.Comments}}</td>
                 <td>{{product.ActiveStatus}}</td>  -->
                <td>   <span class="p-column-title">Created On:</span> {{product.CreatedDate}} </td> 
                

            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>


<p-dialog [(visible)]="productDialog" [style]="{width: '600px'}" header="Product  Details" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
         
<!-- model change here -->
<br>
<div class="container">
    <table class="table table-striped">

        <tbody>
            <tr>
                <td>
                    <label for="Organization_Name" class="col-form-label">Product  ID</label>
                </td>
                <td>
                    <label for="OrgID" class="col-form-label"> {{productsbyid?.ItemID}} </label>
                </td>

            </tr>
            <tr>
                <td>

                    <label for="Organization_Name" class="col-form-label">Product Name</label>
                </td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.ItemName}} </label>
                </td>

            </tr>
            <tr>
                <td> <label for="PANCard" class="col-form-label">UnderOfParentID</label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.UnderOfParentID}} </label>
                </td>

            </tr>
            <tr>
                <td>

                    <label for="GSTN" class="col-form-label">Product Category Name</label>
                </td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.ItemCategoryName}} </label>
                </td>

            </tr>
            <tr>
                <td> <label for="Contact_Number" class="col-form-label">Description</label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.ItemDescription}}
                    </label></td>

            </tr>
            <tr>
                <td><label for="Emailid" class="col-form-label">UpdatedBy </label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.CreatedBy}}
                    </label></td>

            </tr>
            <tr>
                <td> <label for="Address" class="col-form-label">UpdatedOn </label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.CreatedDate}}
                    </label></td>

            </tr>
            <tr>
                <td><label for="City" class="col-form-label">CreatedBy </label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.CreatedByID}} </label>
                </td>

            </tr>
            <tr>
                <td><label for="State" class="col-form-label">CreatedOn </label></td>
                <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.UpdatedDate}} </label>
                </td>

            </tr>
            <tr>
                <td>

                    <label for="PinCode" class="col-form-label">Updated By</label>
                </td>
                <td>
                    <label for="Organization_Name" class="col-form-label"> {{productsbyid?.UpdatedBy}} </label>
                </td>

            </tr>
            
        </tbody>
    </table>
</div>                
          
 
              
 
        <!-- <div class="form-check-inline">
            <label class="form-check-label">

                Status
                   </label>
        </div>
        <div class="form-check-inline">
            <label class="form-check-label">
                
            <input type="radio" class="form-check-input"  checked="!checked" name="radio"  [(ngModel)]="productsbyid?.ActiveStatus"   (change)="handleChange($event,productsbyid?.ItemID)"  [value]="1" >active
          </label>
        </div>
        <div class="form-check-inline">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" 
                              checked="!checked"name="radio1"  [(ngModel)]="productsbyid?.ActiveStatus"     (change)="handleChange($event,productsbyid?.ItemID)"  [value]="0">Deactive
          </label>
        </div> -->



        
         
    </ng-template>
    
    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
        <span style="float: left;padding-top: 2%;" *ngIf="productsbyid">

            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
    
                    Status
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <!-- {{productsbyid?.ActiveStatus}} -->
                    <input type="radio" class="form-check-input" checked="!checked" name="radio"
                        [(ngModel)]="productsbyid.ActiveStatus" (change)="handleChange($event,productsbyid?.ItemID)"
                        [value]="1">active
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                        [(ngModel)]="productsbyid.ActiveStatus" (change)="handleChange($event,productsbyid?.ItemID)"
                        [value]="0">Deactive
                </label>
            </div>
    
            
        </span>
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" style="float: right;"></button>
            <!-- <button style="color: white;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                (click)="saveProduct()"></button>  this is unused -->
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


