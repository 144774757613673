import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';
@Component({
  selector: 'app-edititemcategory',
  templateUrl: './edititemcategory.component.html',
  styleUrls: ['./edititemcategory.component.css']
})
export class EdititemcategoryComponent implements OnInit {
  //Create FormGroup 


  organizaionlist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];

  cityList:Billdesk[];


  type: string;

  id:any;
  products:Billdesk[];
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef,private _Activatedroute:ActivatedRoute) {
   
    console.log('orgiststart');


    console.log(this.billdeskService.getOrganization().then(res => this.organizaionlist = res));
    console.log('stateliststart'); 

    console.log(this.billdeskService.getState().then(res => this.stateList = res));
    
    console.log('statelistend');
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  
  ngOnInit() {


    
// code for get detail by Id
this.id=this._Activatedroute.snapshot.paramMap.get("id");
this._Activatedroute.paramMap.subscribe(params => { 
  this.id = params.get('id');

  console.log(this.id);

  console.log(this.billdeskService.getItemCategoryByid(this.id).then(res => this.products = res ));



});

// code for get detail by Id

    this.form = this.fb.group({ 
      
      ItemCategoryName: [null, Validators.required],
      CategoryDisplayName:[null, Validators.required],
      Description: [null, Validators.required]
      // ,
      
      // CategoryIcon: [null, Validators.required],
      
      // ImageUrl: [null, Validators.required],
      // CategoryUrl:[null, Validators.required],
      // CategoryImageUrl: [null, Validators.required],
      
      // Comments: [null, Validators.required]
      ,


      ActiveStatus: [null, Validators.required]
 
      // OrderBy: [null, Validators.required],
      // EstStartDate: [null, Validators.required],
      
      // EstEndDate: [null, Validators.required],
      // ActualStartDate: [null, Validators.required],
      // ActualEndDate: [null, Validators.required] 
    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {
    // this.Key.setValue(e.target.value, {
    //   onlySelf: true
    // })


    // let messageId = e.getAttribute('data-key');


    // this.type = this.elementRef.nativeElement.getAttribute('data-key');
    //let messageId = el.dataset.messageId;
//     console.log("Message Id: ", messageId);
// console.log(e); 
// console.log(e.target.dataset.Key);
 
// console.log(e.target.value);
console.log(this.billdeskService.getCity(e).then(res => this.cityList = res));
  }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
       
"flag":"MSTItemCatagory_INSERT",
"ItemCategoryID":this.id,
"UpdatedBy":"1", 
"UpdatedOn":"10/22/2021", 
"CreatedBy":"1", 
"CreatedOn":"", 
"CategoryIcon": "",
      
"ImageUrl": "",
"CategoryUrl":"",
"CategoryImageUrl": "",
"Comments": "",


"ActiveStatus": "",
"OrderBy": "",
"EstStartDate": "",
  
"EstEndDate": "",
"ActualStartDate": "",
"ActualEndDate": "" 
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);


if (this.form.valid) {
  console.log('form submitted');


  
 let updateurl= "/itemcategory/itemcategoryregistrationupdate"; 
 
this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{
  console.log("depin");
  console.log(res);
  console.log("depin");
  
   console.log(res.itemcategoryregistrationupdateResult);
  if(res.itemcategoryregistrationupdateResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/Category-List'])
    localStorage.setItem('updatedata', 'Success');
 }else{
   console.log('Please Try Again Later');
 }
 });



} else {
  this.validateAllFormFields(this.form);
}
 }


 }
 