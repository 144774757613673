import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'app-editrole',
  templateUrl: './editrole.component.html',
  styleUrls: ['./editrole.component.css']
})
export class EditroleComponent implements OnInit {
  //Create FormGroup 


  organizaionlist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];
  id:any;
  products:Billdesk[];

  cityList:Billdesk[];
  headlist:Billdesk[];

  type: string;
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef,private _Activatedroute:ActivatedRoute) {
   
    

  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  
  ngOnInit() {


    // code for get detail by Id
this.id=this._Activatedroute.snapshot.paramMap.get("id");
this._Activatedroute.paramMap.subscribe(params => { 
  this.id = params.get('id');

  console.log(this.id);
console.log('getdtalrole');
  console.log(this.billdeskService.getRoleByid(this.id).then(res => this.products = res ));
  console.log('getdtalrole');


});

// code for get detail by Id

    this.form = this.fb.group({ 
      
      RoleTypeName: [null, Validators.required],
      
      RoleDesc: [null, Validators.required] 
    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {
    // this.Key.setValue(e.target.value, {
    //   onlySelf: true
    // })


    // let messageId = e.getAttribute('data-key');


    // this.type = this.elementRef.nativeElement.getAttribute('data-key');
    //let messageId = el.dataset.messageId;
//     console.log("Message Id: ", messageId);
// console.log(e); 
// console.log(e.target.dataset.Key);
 
// console.log(e.target.value);
console.log(this.billdeskService.getCity(20).then(res => this.cityList = res));
  }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
    "flag":"",  
    "RoleTypeID":this.id,
    "CreatedDate":"",
   "CreatedBy":"1",
  "CreatedByID":"1", 
 "UpdatedDate":"",
 "UpdatedBy":""
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);


if (this.form.valid) {
  console.log('form submitted');
 

let updateurl= "/userroletype/userroletyperegistrationupdate";
this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{
  console.log("depin");
  console.log(res);
  console.log("depin");
  
   console.log(res.userroletyperegistrationupdateResult);
  if(res.userroletyperegistrationupdateResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/User-Role-List'])
    localStorage.setItem('updatedata', 'Success');
 }else{
   console.log('Please Try Again Later');
 }
 });


} else {
  this.validateAllFormFields(this.form);
}
 }


 }
 