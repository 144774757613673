<br>

<br>

 


<div class="container" style="background-color: #ffffff;" id="content" #content>
	<p-toast></p-toast>
	<div class="row">
		<div class="col-xs-12">
			<h2><span class="pull-right"><span style="border: 1px dotted black;">GROUP WISE SALE  
					</span>&nbsp;&nbsp; </span></h2>
		</div>
	</div>
	<div class="row">
		<div class="col-xs-12">
			<!-- <div class="invoice-title"> -->
<!-- vendor code -->
				<!-- <div class="position-relative  form-group col-sm-6" style="padding-left:0;">
					<label for="VendorID" class="col-form-label">Vendor Name<span>*</span></label>
					<div>

						<p-dropdown [options]="vendorlist" [(ngModel)]="selectedVendorId" optionLabel="VendorName"
							[filter]="true" filterBy="VendorName" [showClear]="true" [placeholder]="selectedVendorName"
							(onChange)="changeVendor($event)" name="VendorID" id="VendorID" formControlName="VendorID"
							[style]="{ width: '100%' }">

							<ng-template let-country pTemplate="item">
								<div class="country-item">
									<div>{{country.VendorName}}</div>
								</div>
							</ng-template>
						</p-dropdown>


						<h1 style="font-weight: 700;" *ngIf="VendorShow">{{VendorName}}</h1>
 

					</div>
				</div> -->



				<!-- <h3 class="pull-right"> <img src="..\assets\images\3.png" width="150" /></h3>

				<h4>{{VendorBillingAddress}} {{VendorCity}}-{{VendorPinCode}},<br>
					India GSTIN-{{GSTN}}, {{VendorState}}<br>
					E-mail:{{VendorEmailID}}, Mobile No-{{VendorMobileNo}}</h4> -->
			<!-- </div> -->
			<!-- <div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div> -->


			<!-- <div class="row">
				<div class="position-relative  form-group col-sm-4">
					<label for="City" class="col-form-label">Organization<span>*</span></label>
					<div class="">

						<p-dropdown [options]="organizaionlist" [(ngModel)]="selectedOrgId" optionLabel="OrgName"
							[filter]="true" filterBy="OrgName" [showClear]="true" [placeholder]="selectedOrgName"
							(onChange)="getDivisionByOrg($event)" name="UserOrgID" id="UserOrgID"
							formControlName="UserOrgID" [style]="{ width: '100%' }">

							<ng-template let-country pTemplate="item">
								<div class="country-item">
									<div>{{country.OrgName}}</div>
								</div>
							</ng-template>
						</p-dropdown>


					</div>
				</div>
				<div class="position-relative  form-group col-sm-4">
					<label for="DivID" class="col-form-label">Division Name<span>*</span></label>
					<div class="">


						<p-dropdown [options]="divisionlist" [(ngModel)]="selectedDiv" optionLabel="DivName"
							[filter]="true" filterBy="DivName" [showClear]="true" [placeholder]="selectedDivName"
							(onChange)="changeDivision($event)" name="UserDivID" id="UserDivID"
							formControlName="UserDivID" [style]="{ width: '100%' }">

							<ng-template let-country pTemplate="item">
								<div class="country-item">
									<div>{{country.DivName}}</div>
								</div>
							</ng-template>
						</p-dropdown>


					</div>
				</div>



				<div class="position-relative  form-group col-sm-4">
					<label for="DeptID" class="col-form-label">Department Name<span>*</span></label>
					<div class="">

						<p-dropdown [options]="departmentlist" [(ngModel)]="selectedDeptID" optionLabel="DeptName"
							[filter]="true" filterBy="DeptName" [showClear]="true" [placeholder]="selectedDeptName"
							(onChange)="changeDepartment($event)" name="UserDeptID" id="UserDeptID"
							formControlName="UserDeptID" [style]="{ width: '100%' }">

							<ng-template let-country pTemplate="item">
								<div class="country-item">
									<div>{{country.DeptName}}</div>
								</div>
							</ng-template>
						</p-dropdown>


					</div>
				</div>
			</div> -->
			<!-- <div class="row">
			 
				<div class="position-relative  form-group col-sm-4">

					<span style="display:inline"><strong> Sales Type :</strong> </span>
					<select [(ngModel)]="purchasetype" class="form-control">
						<option selected disabled>Please Select</option>
						<option value="1">Sales</option>
						<option value="2">Sales Order</option> 
					</select>

				</div>
                <div class="position-relative  form-group col-sm-4">
                </div>
				<div class="position-relative  form-group col-sm-4">
					<strong>Invoice Date:</strong>

					<input type="date" class="form-control" [(ngModel)]="invdate"><br><br>

				</div>
			</div>
 -->

			<div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;margin-bottom: 5%;"></div>
			<div class="row">
				<div class="position-relative  form-group col-sm-4">
 

					<strong>Group Name</strong>
					<!-- <ng-container *ngIf="departmentdetail && departmentdetail[0]">
						{{departmentdetail[0].DeptName}}<br>
						{{departmentdetail[0].DeptAddress}}
					</ng-container>
					<ng-container *ngIf="departmentdetail && departmentdetail[0]">
						GSTIN: {{departmentdetail[0].GSTN}}<br>
					</ng-container> --> 


					<select class="form-control mb" [(ngModel)]="ProductID"
												data-placeholder="Please Select Product" (ngModelChange)="getBatchNoByProductId($event)">
												<option selected value="" disabled>Please Select Product</option>
												<optgroup *ngFor='let grp of itemcategorylist'
													label="{{grp.ItemCategoryName}}">
													<option *ngFor='let item of grp.SubMenuList' [value]="item.ItemID"
														data-category="item.ItemCategoryID">{{item.ItemName}} </option>
												</optgroup>
											</select>
                
				</div>
				<div class="position-relative  form-group col-sm-4">
					<strong></strong><br>
					<span class="btn btn-info">Add</span></div>

				<div class="position-relative  form-group col-sm-4"></div>
			</div>


			
		</div>
		<div class="row">
		 
  
			<div *ngIf="!dynamicArray?.length">
				<div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div>
				<h1 style="color:red!important;text-align:center">Container is empty Please Add New GROUP.</h1>
			  </div>
			<div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a"></div>
			<div class="row">
				<div class="col-md-12">
					<div class="panel panel-default">
						<div class="panel-body">
							<div class="">
								<!-- <table> -->
									<!-- <thead>
									<tr>
								 
										


										<td class="text-center"><strong>PRODUCT</strong></td>
                                        <td class="text-center"><strong>BRAND</strong></td>
										<td class="text-center"><strong>  UNIT</strong></td>

                                        <td class="text-center"><strong>  PRICE TYPE</strong></td>
										
										<td class="text-center"><strong>DESC</strong></td>
                                        <td class="text-center"><strong>PKD. DATE</strong></td>
                                        <td class="text-center"><strong>EXP. DATE</strong></td> 
										<td class="text-center"><strong>PURCHASE QTY.</strong></td>
										
                                        <td class="text-center"><strong>PURCHAE PRICE</strong></td> 
                                        <td class="text-center"><strong>SALE PRICE</strong></td>

                                        <td class="text-center"><strong>MRP</strong></td>

                                        <td class="text-center"><strong>WHOLE SALE PRICE</strong></td>



										<td class="text-center"><strong>TAX(%)</strong></td>
										<td class="text-center"><strong>TAX(amt))</strong></td>
										<td class="text-center"><strong>TOTAL AMOUNT</strong></td>
										<td class="text-center"></td>
									</tr>
									</thead> -->
									<!-- <tbody> -->

					 
										<div *ngFor="let dynamic of dynamicArray; let i = index"
										class="card"   style="padding: 15px;margin: 15px;"  >
		
											<div class="row">
		
												<div class="col-md-1">
													<label>Product ID:</label>
												</div>
		
												<div class="col-md-2">
		
													<!-- <select readonly class="form-control"
														[(ngModel)]="dynamicArray[i].ProductID" style="width:200px"
														data-placeholder="Please Select Product">
														<option selected value="" disabled>Please Select Product</option>
														<optgroup *ngFor='let grp of itemcategorylist'
															label="{{grp.ItemCategoryName}}">
															<option *ngFor='let item of grp.SubMenuList' [value]="item.ItemID"
																data-category="item.ItemCategoryID">
																{{item.ItemName}} </option>
														</optgroup>
													</select> -->
		
		
													{{dynamicArray[i].ProductID}}
												</div>
											 
		
		 
		
												<div class="col-md-1">
													<label>Sale Qty:</label>
		
		
												</div>
		
												<div class="col-md-2">
													<!-- <input style="width:80px" readonly
														name="{{ dynamicArray[i].PurchaseQuantity}}"
														[(ngModel)]="dynamicArray[i].PurchaseQuantity" type="number"
														class="form-control" /> -->
		
														{{dynamicArray[i].SaleQuantity}}
		
												</div>
		
												<div class="col-md-1">
		
													<label>Purchase Price:</label>
												</div>
												<div class="col-md-2">
		<!-- 
													<input style="width:80px" readonly name="{{ dynamicArray[i].SalePrice}}"
														[(ngModel)]="dynamicArray[i].SalePrice" type="number"
														class="form-control" /> -->
		
		
														{{dynamicArray[i].SalePrice}}
		
		
		
												</div>
												<div class="col-md-1">
		
													<label>Brand ID:</label>
		
												</div>
												<div class="col-md-1">
													<!-- <select readonly class="form-control" [(ngModel)]="dynamicArray[i].BrandID"
														style="width:200px" data-placeholder="Please Select Product">
														<option selected value="" disabled>Please Select Brand</option>
														<option *ngFor='let item of  brandlist' [value]="item.BrandID">
															{{item.BrandName}} </option>
		
													</select> -->
		
													{{dynamicArray[i].BrandID}}
												</div>
												<div class="col-md-1">
															 
		
												</div>
		
												</div>
		
		<div class="row">
		
		 
												
												<div class="col-md-1">
		
		
		
													<label>Sale Price:</label>
												</div>
												<div class="col-md-2">
													<!-- <input style="width:80px" readonly name="{{ dynamicArray[i].SalePrice}}"
														[(ngModel)]="dynamicArray[i].SalePrice" type="number"
														class="form-control" /> -->
		
														
													{{dynamicArray[i].SalePrice}}
												</div>
		
												<div class="col-md-1">
		
													<label>MRP:</label>
		
												</div>
		
		
												<div class="col-md-2">
													<!-- <input style="width:80px" readonly name="{{ dynamicArray[i].MRP}}"
														[(ngModel)]="dynamicArray[i].MRP" type="number" class="form-control" /> -->
		
		
														{{dynamicArray[i].MRP}}
		
												</div>
		
												<div class="col-md-1">
		
		
													<label>Sales Unit:</label>
		
												</div>
		
												<div class="col-md-2">
													<!-- <select readonly class="form-control"
														[(ngModel)]="dynamicArray[i].SaleUnit" style="width:200px">
		
														<option selected value="" disabled>Please Select Unit</option>
		
														<option *ngFor='let item of unitlist' [value]="item.UnitID">
															{{item.UnitName}}</option>
		
													</select> -->
		
		
													{{dynamicArray[i].SaleUnit}}
												</div>
		
		
		
												<div class="col-md-1">
		
													<label>Whole sale Price:</label>
												</div>
		
												<div class="col-md-1">
													<!-- <input style="width:80px" readonly
														name="{{ dynamicArray[i].WholesalePrice}}"
														[(ngModel)]="dynamicArray[i].WholesalePrice" type="number"
														class="form-control" /> -->
		
		
														{{dynamicArray[i].WholesalePrice}}
												</div>
										<div class="col-md-1">
													<i class="fa fa-trash"  style="color:red;font-size:18px;" (click)="deleteRow(i)" ></i>
		
										</div>
		
												</div>
		
		
												<div class="row">
												<div class="col-md-1">
													<label>Tax:</label>
												</div>
												<div class="col-md-2">
													<!-- <select readonly name="IGSTPer" id="IGSTPer" style="width:80px"
														class="form-control" [(ngModel)]="dynamicArray[i].TaxPercentage"
														(ngModelChange)="getValue(dynamicArray[i].PurchaseQuantity,dynamicArray[i].SalePrice,dynamicArray[i].TaxPercentage,i)">
		
														<option *ngFor="let gstdetails of gstdetail" [value]="gstdetails">
															{{gstdetails}} </option>
		
													</select> -->
		
		
													
													{{dynamicArray[i].TaxPercentage}}
												</div>
		
		
												<div class="col-md-1">
		
		
		
													<label >Sales Price Type:</label>
		
												</div>
		
												<div class="col-md-2">
													<!-- <select readonly class="form-control"
														[(ngModel)]="dynamicArray[i].SalePriceType" style="width:200px">
		
														<option selected value="" disabled>Please Select Price Type</option>
		
														<option *ngFor='let item of pricetypelist' [value]="item.PriceTypeID">
															{{item.PriceTypeName}}</option>
		
													</select> -->
		
		
													{{dynamicArray[i].SalePriceType}}
		
												</div>
												<div class="col-md-1">
		
													<label>TaxAmount:</label>
		
												</div>
		
												<div class="col-md-2">
													<!-- <input style="width:100px" readonly name="{{ dynamicArray[i].TaxAmount }}"
														[(ngModel)]="dynamicArray[i].TaxAmount" type="text"
														class="form-control" /> -->
		
		
														{{dynamicArray[i].TaxAmount}}
												</div>
												<div class="col-md-1">
		
													<label>Total Amount:</label>
		
												</div>
		
												<div class="col-md-1">
		<!-- 
													<input style="width:100px" readonly name="{{ dynamicArray[i].TotalAmount}}"
														[(ngModel)]="dynamicArray[i].TotalAmount" type="number"
														class="form-control" /> -->
		
		
														{{dynamicArray[i].TotalAmount}}
												</div>
		
		
		
												<div class="col-md-1">
													 
		
										</div>
												</div>
		
												<div class="row">										
													<div class="col-md-1">
		
													<label>Description:</label>
		
												</div>
												<div class="col-md-2">
		<!-- 
													<input readonly style="width:150px" name="{{ dynamicArray[i].Description}}"
														[(ngModel)]="dynamic.Description" type="text" class="form-control" /> -->
		
		
														
														{{dynamicArray[i].Description}}
		
												</div>
												<div class="col-md-1">
													<label>Packed Date:</label>
												</div>
												<div class="col-md-2">
													<!-- <input readonly style="width:150px" name="{{ dynamicArray[i].PackedDate}}"
														[(ngModel)]="dynamic.PackedDate" type="date" class="form-control" /> -->
		
		
														{{dynamicArray[i].PackedDate}}
												</div>
		
												<div class="col-md-1">
													<label>Expiry Date:</label>
		
												</div>
												<div class="col-md-2">
													<!-- <input readonly style="width:150px" name="{{ dynamicArray[i].ExpiryDate}}"
														[(ngModel)]="dynamic.ExpiryDate" type="date" class="form-control" /> -->
														{{dynamicArray[i].ExpiryDate}}
												</div>
												<div class="col-md-1">
												 
												</div>
												<div class="col-md-1">
													<!-- <button class="form-control fa fa-trash btn btn-danger"
														(click)="deleteRow(i)"></button> -->
												 </div>
		
												 <div class="col-md-1">
													 
		
										</div>
												</div>
		
		
		
		
		
		
		
		
		
		
		
		
												<!-- this for model -->
		
												<!-- <p-sidebar [(visible)]="sidebarVisible2" position="right"
													style="z-index: 0 !important;">
		
		
		
													<h2>Add Purchase Item</h2>
		
		
											 		<label>Product</label>
		
													<select class="form-control mb" [(ngModel)]="ProductID"
														data-placeholder="Please Select Product">
														<option selected value="" disabled>Please Select Product</option>
														<optgroup *ngFor='let grp of itemcategorylist'
															label="{{grp.ItemCategoryName}}">
															<option *ngFor='let item of grp.SubMenuList' [value]="item.ItemID"
																data-category="item.ItemCategoryID">{{item.ItemName}} </option>
														</optgroup>
													</select>
		
		
		
													<label>Brand</label>
		
		
		
													<select class="form-control mb" [(ngModel)]="BrandID"
														data-placeholder="Please Select Product">
														<option selected value="" disabled>Please Select Brand</option>
														<option *ngFor='let item of  brandlist' [value]="item.BrandID">
															{{item.BrandName}} </option>
		
													</select>
		
		
		
													<label>Purchase Unit</label>
		
													<select class="form-control mb" [(ngModel)]="SaleUnit">
		
														<option selected value="" disabled>Please Select Unit</option>
		
														<option *ngFor='let item of unitlist' [value]="item.UnitID">
															{{item.UnitName}}</option>
		
													</select>
		
													<label> Purchase Price Type</label>
													<select class="form-control mb" [(ngModel)]="SalePriceType">
		
														<option selected value="" disabled>Please Select Price Type</option>
		
														<option *ngFor='let item of pricetypelist' [value]="item.PriceTypeID">
															{{item.PriceTypeName}}</option>
		
													</select>
													<label>Description</label>
		
													<input name="{{ Description}}" [(ngModel)]="Description" type="text"
														class="form-control mb" />
		
													<label>PackedDate</label>
													<input name="{{ PackedDate}}" [(ngModel)]="PackedDate" type="date"
														class="form-control mb" />
		
		
													<label>ExpiryDate</label>
		
													<input name="{{ ExpiryDate}}" [(ngModel)]="ExpiryDate" type="date"
														class="form-control mb" />
													<label>Purchase Quantity</label>
		
													<input name="{{SaleQuantity}}" [(ngModel)]="SaleQuantity"
														type="number" class="form-control mb" />
		
		 
													<label>Sale Price </label>
		
													<input name="{{SalePrice}}" [(ngModel)]="SalePrice" type="number"
														class="form-control mb" />
		
		
													<label>MRP</label>
		
													<input name="{{MRP}}" [(ngModel)]="MRP" type="number"
														class="form-control mb" />
		
		
													<label>Whole sale Price</label>
		
		
		
													<input name="{{ WholesalePrice}}" [(ngModel)]="WholesalePrice" type="number"
														class="form-control mb" />
		
													<label>Tax Percentage</label>
		
													<select name="IGSTPer" id="IGSTPer" class="form-control mb"
														[(ngModel)]="TaxPercentage"
														(ngModelChange)="getValueform(SaleQuantity,SalePrice,TaxPercentage,i)">
		
														<option *ngFor="let gstdetails of gstdetail" [value]="gstdetails">
															{{gstdetails}} </option>
		
													</select>
		
													<label>Tax Amount</label>
		
													<input name="{{ TaxAmount }}" [(ngModel)]="TaxAmount" type="text"
														class="form-control mb" />
													<label>Taxable Amount</label>
													<input name="{{ TaxableAmount }}" [(ngModel)]="TaxableAmount" type="hidden"
														class="form-control mb" />
		
		
		
		
													<label>Total Amount</label>
		
		
													<input name="{{ TotalAmount}}" [(ngModel)]="TotalAmount" type="number"
														class="form-control mb" />
		
		
													<button class="btn-info form-control mb" (click)="SubmitItemDetail()"> <i
															class="fa fa-floppy-o" aria-hidden="true"></i> &nbsp;&nbsp;
														Add</button>&nbsp;&nbsp;
													<br> <br> <br> <br> <br>
												 
												</p-sidebar> -->
											</div>




<br><br><br>

										<!-- this for model -->

										<p-sidebar [(visible)]="sidebarVisible2" position="right"
											style="z-index: 0 !important;">



											<h2>Add  Item</h2>


											<!-- <div *ngFor="let dynamic of NewdynamicArray; let i = index"> -->
											<label>Product</label>

											<select class="form-control mb" [(ngModel)]="ProductID"
												data-placeholder="Please Select Product" (ngModelChange)="getBatchNoByProductId($event)">
												<option selected value="" disabled>Please Select Product</option>
												<optgroup *ngFor='let grp of itemcategorylist'
													label="{{grp.ItemCategoryName}}">
													<option *ngFor='let item of grp.SubMenuList' [value]="item.ItemID"
														data-category="item.ItemCategoryID">{{item.ItemName}} </option>
												</optgroup>
											</select>

                                            <label> Batch No</label>
											<select class="form-control mb" [(ngModel)]="BatchNo"  (ngModelChange)="getStockDetailByBatchNo($event)">

												<option selected value="" disabled>Please Select Batch No</option>

												<option *ngFor='let batch of batchlistbyproduct' [value]="batch.PurchaseBatchNumber">
													{{batch.PurchaseBatchNumber}}</option>

											</select>

                                            <label>Brand</label>

<br>

										<span  class="form-control mb">  {{BrandName}}</span>	
                                        <label>Available Quantity</label>

                                        <br>
                                        
                                                                                <span  class="form-control mb">  {{StockQuantity}}</span>	
											<!-- <label>Brand</label>



											<select class="form-control mb" [(ngModel)]="BrandID"
												data-placeholder="Please Select Product">
												<option selected value="" disabled>Please Select Brand</option>
												<option *ngFor='let item of  brandlist' [value]="item.BrandID">
													{{item.BrandName}} </option>

											</select> -->



											<label>Sale Unit</label>

											<select class="form-control mb" [(ngModel)]="SaleUnit">

												<option selected value="" disabled>Please Select Unit</option>

												<option *ngFor='let item of unitlist' [value]="item.UnitID">
													{{item.UnitName}}</option>

											</select>




											<label> Sale Price Type</label>
											<select class="form-control mb" [(ngModel)]="SalePriceType">

												<option selected value="" disabled>Please Select Price Type</option>

												<option *ngFor='let item of pricetypelist' [value]="item.PriceTypeID">
													{{item.PriceTypeName}}</option>

											</select>


                                         
											<label>Description</label>

											<input name="{{ Description}}" [(ngModel)]="Description" type="text"
												class="form-control mb" />

											<label>PackedDate</label>
									 
                                            <span class="form-control mb" >{{PackedDate}}</span>

											<label>ExpiryDate</label>
                                            <span class="form-control mb" >{{ExpiryDate}}</span>
											<!-- <input name="{{ ExpiryDate}}" readonly  [(ngModel)]="ExpiryDate" type="date"
												class="form-control mb" /> -->
											<label>Sale Quantity</label>

											<input name="{{SaleQuantity}}" [(ngModel)]="SaleQuantity"
												type="number" class="form-control mb" />


											<label>Sale Price</label>

											<input name="{{ SalePrice}}" [(ngModel)]="SalePrice" type="number"
												class="form-control mb" />
										 

											<label>MRP</label>

											<input name="{{MRP}}" [(ngModel)]="MRP" type="number"
												class="form-control mb" />


											<label>Whole sale Price</label>



											<input name="{{ WholesalePrice}}" [(ngModel)]="WholesalePrice" type="number"
												class="form-control mb" />

											<label>Tax Percentage</label>

											<select name="IGSTPer" id="IGSTPer" class="form-control mb"
												[(ngModel)]="TaxPercentage"
												(ngModelChange)="getValueform(SaleQuantity,SalePrice,TaxPercentage,i)">

												<option *ngFor="let gstdetails of gstdetail" [value]="gstdetails">
													{{gstdetails}} </option>

											</select>

											<label>Tax Amount</label>

											<input name="{{ TaxAmount }}" [(ngModel)]="TaxAmount" type="text"
												class="form-control mb" />


                                                

											<!-- <label>Taxable Amount</label> -->

											<input name="{{ TaxableAmount }}" [(ngModel)]="TaxableAmount" type="hidden"
												class="form-control mb" />




											<label>Total Amount</label>


											<input name="{{ TotalAmount}}" [(ngModel)]="TotalAmount" type="number"
												class="form-control mb" />


											<button class="btn-info form-control mb" (click)="SubmitItemDetail()"> <i
													class="fa fa-floppy-o" aria-hidden="true"></i> &nbsp;&nbsp;
												Add</button>&nbsp;&nbsp;
											<br> <br> <br> <br> <br>
							 
										</p-sidebar>

			
 										<div class="row" >

											 <div class="col-md-2"> 

	<button class=" btn fa fa-plus btn-warning" (click)="AddItem()" style="color:#fff;"> &nbsp;
		Add New</button>

</div>

<div class="col-md-2"></div>
<div class="col-md-2"></div>
<div class="col-md-2"></div>
<div class="col-md-2"></div>

<div class="col-md-2"> 
	<button *ngIf="dynamicArray?.length"  type="submit" class="btn btn-success" (click)="onSubmit()"> <i
		class="fa fa-floppy-o" aria-hidden="true"></i>
	&nbsp;&nbsp;Submit</button>
</div>
											 
					 
												
												</div>
							 
								<br><br><br><br><br><br><br><br>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

	</div>