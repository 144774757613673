import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';
@Component({
  selector: 'app-department-registration',
  templateUrl: './department-registration.component.html',
  styleUrls: ['./department-registration.component.css']
})
export class DepartmentRegistrationComponent implements OnInit {
  //Create FormGroup 


  organizaionlist: Billdesk[];
  divisionlist: Billdesk[];
  OrgID:any;

  selectedOrgId:string;
  selectedOrgName:string='Please Select Organization';
  selectedDivName:string='Please Select Division';
  selectedDiv:string;

  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef) {
     

    console.log('orgiststart');


    console.log(this.billdeskService.getOrganizationsListDDL().then(res => this.organizaionlist = res));
    console.log('statelistend'); 

    console.log('divisionstart');


    // this.billdeskService.getDivision().then(res => this.divisionlist = res);
    console.log('divisionend'); 
  }
 
  //Create required field validator for name
  
  form: FormGroup;
 
  
  ngOnInit() {
    this.form = this.fb.group({ 
      
      DeptName: [null, Validators.required],
      OrgID:[null, Validators.required], 
      HODName:[null, Validators.required],
      DivID:[null, Validators.required],
 
      HODMobileNo: [null, Validators.required],
     
      
      ActiveStatus: [null, Validators.required],
 
      HODEmailID: [null, [Validators.required, Validators.email]],
      
    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }


  
  changeOrganization(e) {
    // this.OrgID.setValue(e.target.value, {
    //   onlySelf: true
    // })


    console.log(e);


    
  }


  
  onSubmit(){
    var obj1 = 
   {
        "flag":"",
     "DivID" :0,
     "CreatedDate"  : "",
     "CreatedBy" :"5",
     "UpdatedDate"  : "",
     "UpdatedBy" :""
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);


if (this.form.valid) {
  console.log('form submitted');
  
 
let registerurl= "/department/departmentregistration";
this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
  console.log("depin");
  console.log(res);
  console.log("depin");
    console.log(res.departmentregistrationResult);
  if(res.departmentregistrationResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/Department-List'])
   localStorage.setItem('insertdata', 'Success');
 }else{
   console.log('Please Try Again Later');
 }
 });



} else {
  this.validateAllFormFields(this.form);
}
 }


 getDivisionByOrg(e){
  this.selectedOrgId=e.value.OrgID;
   this.selectedOrgName=e.value.OrgName; 
console.log(this.billdeskService.getDivisionByOrgbYDLLList(e.value.OrgID).then(res => this.divisionlist = res));
}


changeDivision(e){
  console.log(e); 
   this.selectedDivName=e.value.DivName;

 
  console.log(this.selectedDivName);
      this.selectedDiv=e.value.DivID;
    
    }
 }
 