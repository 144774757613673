 <br>&nbsp;&nbsp; <a     [routerLink]="['/PO-List']"  class="noprint"><button class="btn btn-info btn-lg">Back</button>
  </a>    
<!-- <div class="container"  style="height:500px; font-weight: 800;margin-top: 32px;font-family: ui-monospace;color:#f1592a !important;">
    <h1 style="text-align: center;">Institute Wise Summary Of PO</h1>
         
    <table class="table table-bordered"  >
      <thead>
        <tr>
          <th>S.No</th>
          <th>Institute Name</th>
          <th>Total No. of Invoice</th>
          
          <th>Total Bill Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of products;let i = index" >
      <td>{{i+1}}</td>
          <td>{{data.DivName}}</td>
          <td>{{data.TotInvoice}}</td>
          
          <td>{{data.TotPayment}}</td>
        </tr>
        <tr style="border-top: 2px  solid black;border-bottom: 2px  solid black;">
          <td><b></b></td>
          <td><b>Total</b></td>
          <td>{{Totalinvoice}}</td>
          
          <td>{{Totalamount}}</td>
        </tr>


        
      </tbody>
    </table>
  </div> -->



  <br><br><br><br>
  <div class="card-body" style="background-color:#fff; margin:50px;" id="content" #content>
    <div class="noprint">PO NUMBER :<b>{{PoBatchNo}}  &nbsp;&nbsp;&nbsp;&nbsp;For The Month  : </b>     {{CreatedDate | date:'MMMM-yyyy' }} <br>  
    </div>
    <h2 class="card-title"  style="font-weight:bold;text-align: center;">
      Organization Wise Summary Sheet - A   
    </h2>
 
  <p-table [value]="products" responsiveLayout="scroll"  >
    <ng-template pTemplate="header">
        <tr>
            <th class="tabletd">S.No.</th>
            <th class="tabletd">Organization</th>
            <th class="tabletd" >Total Bill Amount</th>
            <th class="tabletd" >TDS Amount</th>
            <th class="tabletd" >Discount</th>
            <th class="tabletd" >Total Net Payble</th>
            <th class="tabletd">Total Invoice</th>

        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product  let-i="rowIndex" >
        <tr>
         <td  class="tabletd">{{i + 1}}</td>
            <td class="tabletd"> {{product.OrgName}}</td>
            <td class="tabletd">{{product.TotPayment}}</td>
            <td class="tabletd">{{product.TDSInAmount}}</td>
            <td class="tabletd">{{product.DiscountAmount}}</td>
            <td class="tabletd">{{product.TotPayment-product.TDSInAmount}}</td>
            <td class="tabletd">{{product.TotInvoice}}</td>

        </tr>

        
    </ng-template>
   


  <ng-template pTemplate="footer" >
 <tr>
  <th style="padding: 1%;" colspan="2" class="tabletd"> &nbsp; &nbsp; &nbsp;Grand Total </th>
    <th style="padding: 1%;" class="tabletd">{{Totalamount}}</th>
     <th style="padding: 1%;" class="tabletd">  {{TotalTdsinamount}} </th>
    <th style="padding: 1%;" class="tabletd">  {{TotalDiscountAmount}} </th>
    <th style="padding: 1%;" class="tabletd">  {{Totalamount-TotalTdsinamount}} </th>
    <th style="padding: 1%;" class="tabletd">  {{Totalinvoice}} </th>
 

  </tr>
  </ng-template>
  </p-table>


 
 
   
</div>

<div style="text-align: center;"> <button class="btn btn-info noprint"   (click)="print_page()">Print&nbsp;&nbsp;<i class="fa fa-print" style="font-size:18px"></i></button></div>
  
  