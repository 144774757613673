



 
<br>

<br> 
<div class="container" style="background-color: #ffffff;"  id="content"  #content> 

	<div class="row"><div class="col-xs-12"><h2><span class="pull-right"><span style="border: 1px dotted black;">ORIGINAL FOR RECEIPIENT </span>&nbsp;&nbsp;<span>TAX INVOICE</span> </span></h2></div></div>
    <div class="row">
        <div class="col-xs-12">
    		<div class="invoice-title">
    		
				<div class="position-relative  form-group col-sm-6" style="padding-left:0;">
					<label for="VendorID" class="col-form-label">Vendor Name<span>*</span></label>
					<div   >
	
					 <p-dropdown  *ngIf="!VendorShow" [options]="vendorlist"  [(ngModel)]="selectedVendorId" optionLabel="VendorName" [filter]="true" filterBy="VendorName"
						[showClear]="true" [placeholder]="selectedVendorName"  (onChange)="changeVendor($event)" name="VendorID" id="VendorID"     formControlName="VendorID"  [style]="{ width: '100%' }" >
					 
						<ng-template let-country pTemplate="item">
							<div class="country-item">
								<div>{{country.VendorName}}</div>
							</div>
						</ng-template>
					</p-dropdown>  
					
	
					<h1 style="font-weight: 700;"  *ngIf="VendorShow">{{VendorName}}</h1>
	   
<!-- 				 
						<app-field-error-display [displayError]="isFieldValid('VendorID')" errorMsg="Please Select Vendor Name"   style="background-color: #f8d7da;
						border-color: #f5c6cb;" class="alert-danger">
						</app-field-error-display> -->
					
					</div>
				</div>
			
			
		
                <h3 class="pull-right"> <img src="..\assets\images\3.png" width="150"/></h3>

                <h4>{{VendorBillingAddress}} {{VendorCity}}-{{VendorPinCode}},<br>
                    India GSTIN-{{GSTN}}, {{VendorState}}<br>
                E-mail:{{VendorEmailID}}, Mobile No-{{VendorMobileNo}}</h4>
    		</div>
    		<div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div>


			<div class="row">
				<div class="position-relative  form-group col-sm-4">
					<label for="City" class="col-form-label">Organization<span>*</span></label>
					<div class="">
					  
					   <p-dropdown [options]="organizaionlist" [(ngModel)]="selectedOrgId" optionLabel="OrgName" [filter]="true" filterBy="OrgName"
							[showClear]="true" [placeholder]="selectedOrgName"  (onChange)="getDivisionByOrg($event)" name="UserOrgID" id="UserOrgID"      formControlName="UserOrgID"  [style]="{ width: '100%' }" >
						 
							<ng-template let-country pTemplate="item">
								<div class="country-item">
									<div>{{country.OrgName}}</div>
								</div>
							</ng-template>
						</p-dropdown>
 
					
					</div>
				</div>
				<div class="position-relative  form-group col-sm-4">
					<label for="DivID" class="col-form-label">Division Name<span>*</span></label>
					<div class="">
					   
			   
					<p-dropdown [options]="divisionlist"  [(ngModel)]="selectedDiv" optionLabel="DivName" [filter]="true" filterBy="DivName"
						[showClear]="true" [placeholder]="selectedDivName"  (onChange)="changeDivision($event)" name="UserDivID" id="UserDivID"     formControlName="UserDivID"  [style]="{ width: '100%' }" >
					 
						<ng-template let-country pTemplate="item">
							<div class="country-item">
								<div>{{country.DivName}}</div>
							</div>
						</ng-template>
					</p-dropdown>
 
					
					</div>
				</div>
		
		
		
				<div class="position-relative  form-group col-sm-4">
					<label for="DeptID" class="col-form-label">Department Name<span>*</span></label>
					<div class="">
		
						<p-dropdown [options]="departmentlist"  [(ngModel)]="selectedDeptID" optionLabel="DeptName" [filter]="true" filterBy="DeptName"
						[showClear]="true" [placeholder]="selectedDeptName"  (onChange)="changeDepartment($event)" name="UserDeptID" id="UserDeptID"     formControlName="UserDeptID"  [style]="{ width: '100%' }" >
					 
						<ng-template let-country pTemplate="item">
							<div class="country-item">
								<div>{{country.DeptName}}</div>
							</div>
						</ng-template>
					</p-dropdown>
	 
					
					</div>
				</div>
		</div>
    		<div class="row" >
    			<div class="col-sm-4">
    				<address>

                        <span><strong> Invoice No:</strong> 0</span><br><br>

    				<strong>BILL TO:</strong>
                    <br>
    	 
						<ng-container *ngIf="divisiondetail && divisiondetail[0]"> 
							{{divisiondetail[0].DivName}}<br> 
							
							<span *ngIf="selectedDeptName!='Please Select Department'">{{selectedDeptName}}</span>
							<br>
					 
							{{divisiondetail[0].DivBillingAddress}} 
							{{divisiondetail[0].DistrictName}}	{{divisiondetail[0].DivPinCode}} 

							
						</ng-container>  
						
    				</address>
    			</div>
    			<div class="col-sm-4">
  

                        <div class="row"> <div class="col-sm-4"> Invoice Date:</div> <div class="col-sm-8"><input type="date"  class="form-control"    [(ngModel)]="invdate" ></div>  </div><br>
						<ng-container *ngIf="divisiondetail && divisiondetail[0]"> 
    					GSTIN: {{divisiondetail[0].DivGSTN}}<br>
						</ng-container>
 
    			</div>

                <div class="col-sm-4">
					<div class="row"> <div class="col-sm-4"> Due Date:</div> <div class="col-sm-8"><input type="date"  class="form-control"  [(ngModel)]="duedate" ></div>  </div><br>
    			</div>
    		</div>
  
    	</div>
    </div>
	<div class="row">
    	<div class="col-md-12">
    		<div class="panel panel-default">
    			 
    			<div class="panel-body">
    				<div class="table-responsive">
					 
							
						<!-- <form [formGroup]="addForm"  > -->
					 
		 
					
										   
			  
							 
						
							
					 
						</div>
					 
    		</div>

 

    	</div>
    </div>


	<div class="row">
    	<div class="col-md-12">
    		<div class="panel panel-default">
				<div class="panel-body">
    				<div class="table-responsive">
								<table>
									<thead>
									<tr>
								 
										

										<td class="text-center"><strong>PRODUCT</strong></td>
										<td class="text-center"><strong>  PURPOSE</strong></td>
										
										<td class="text-center"><strong>REMARK</strong></td>
										
										<td class="text-center"><strong>HSN/SAC</strong></td>
										<td class="text-center"><strong>QTY.</strong></td>
										<td class="text-center"><strong>MRP</strong></td>
										<td class="text-center"><strong>RATE</strong></td>
										<td class="text-center"><strong>TAX(%)</strong></td>
										<td class="text-center"><strong>TAX(amt))</strong></td>
										
										<td class="text-center"><strong>AMOUNT</strong></td>
										
										<td class="text-center"><strong>ACTION</strong></td>
										<td class="text-center"></td>
									</tr>
									</thead>
									<tbody>
									<tr *ngFor="let dynamic of dynamicArray; let i = index">
										
										<td> 
											<!-- <select   class="form-control" [(ngModel)]="dynamicArray[i].ItemID"   style="width:200px" data-placeholder="Please Select Product">
												<option selected value=""  disabled  >Please Select Product</option>
												<optgroup *ngFor = 'let grp of itemcategorylist' label="{{grp.ItemCategoryName}}">
												<option *ngFor = 'let item of grp.SubMenuList' [value]="item.ItemID" data-category="item.ItemCategoryID">{{item.ItemName}} </option> 
											</optgroup>
										    </select> -->

<!-- {{clientReports|json}} -->
<p-dropdown [options]="ProductListByGroup"  placeholder="Please Select Product" [group]="true" [filter]="true"   filterBy="label"  [(ngModel)]="dynamicArray[i].ItemID"  [autoDisplayFirst]="false" optionLabel="label"    >

</p-dropdown>
 

 
 										</td>
										<td>
											<!-- <select   class="form-control" [(ngModel)]="dynamicArray[i].InvoiceHeadItemID"    style="width:200px">

												<option selected value=""  disabled  >Please Select Purpose</option>
												<optgroup *ngFor = 'let grp of itemHeadITemlist' label="{{grp.HeadName}}">
												  <option *ngFor = 'let item of grp.SubMenuList' [value]="item.InvoiceHeadItemID" data-category="item.InvoiceHeadItemID">{{item.InvoiceHeadItemName}}</option> 
												</optgroup>
										   </select> -->



										   <p-dropdown [options]="PurposeListByGroup" 
										   placeholder="Please Select Purpose" [group]="true" [filter]="true"   filterBy="label"  [(ngModel)]="dynamicArray[i].InvoiceHeadItemID"  [autoDisplayFirst]="false" optionLabel="label"    >
									   
										   </p-dropdown>
										</td>

										<td>
										<input
											style="width:150px"
											name="{{ dynamicArray[i].InvoiceDesc}}"
											[(ngModel)]="dynamic.InvoiceDesc"
											type="email" 
											class="form-control" 
										/>
										</td>
										<td>
										<input
											style="width:150px"
											name="{{ dynamicArray[i].HSN }}"
											[(ngModel)]="dynamicArray[i].HSN"
											type="text"
											 class="form-control" 
										/>
										</td>
										<td>
										<input
											style="width:80px"
											name="{{ dynamicArray[i].Quantity }}"
											[(ngModel)]="dynamicArray[i].Quantity"
											type="number" 
											class="form-control" 
										/>
										</td>

										<td>
											<input
												style="width:80px"
												name="{{ dynamicArray[i].MRP}}"
												[(ngModel)]="dynamicArray[i].MRP"
												type="number"
												class="form-control" 
											/>
										</td>
										<td>
										<input
											style="width:80px"
											name="{{ dynamicArray[i].Rate}}"
											[(ngModel)]="dynamicArray[i].Rate"
											type="number" 
											class="form-control"  
										/>
										</td>

										<td> 
											<select  name="IGSTPer"    id="IGSTPer"     style="width:80px" class="form-control"   [(ngModel)]="dynamicArray[i].TaxPercentage"       (ngModelChange)="getValue(dynamicArray[i].Quantity,dynamicArray[i].Rate,dynamicArray[i].TaxPercentage,i)" >
													
												   <option *ngFor="let gstdetails of gstdetail" [value]="gstdetails"> {{gstdetails}}  </option>
												
											   </select>
										</td>
										<td>
											
											<input
												style="width:100px"

											
												name="{{ dynamicArray[i].TaxAmount }}"
												[(ngModel)]="dynamicArray[i].TaxAmount"
												type="text" 
												class="form-control"    
											/>
											
										</td>


										<!-- (dynamic.TaxPercentage/100)*dynamic.Quantity*dynamic.Rate -->

								
										<td>
										           <input 
													style="width:100px"
													name="{{ dynamicArray[i].Amount}}"
													[(ngModel)]="dynamicArray[i].Amount" 
													
													type="number"
													class="form-control" 
												 
													  
												   />
										</td>
										<td>
											<button class="fa fa-trash btn btn-danger" (click)="deleteRow(i)"></button>
										</td>
										</tr>
										<tr>
										<td>

											
										<button  class=" btn fa fa-plus btn-info" (click)="addRow()">Add</button>&nbsp;&nbsp;
										<!-- <button type="submit" class="btn btn-primary" (click)="calculate()">  <i class="fa fa-floppy-o" aria-hidden="true"></i> &nbsp;&nbsp;Calculate</button> -->
										</td>
									 
										</tr>
							 
										<tr>
											<td class="no-line" colspan="3"><strong>  </strong></td>
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center" colspan="3"> TAXABLE AMOUNT</td>
											<td class="no-line text-right">Rs. {{totalwithoutgst}}</td>
										</tr>
										<tr>
											<td class="no-line" colspan="3"> </td>
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center" colspan="3"> CGST@{{ctaxamount}}% </td>
											<td class="no-line text-right">Rs. {{ctaxamount | number : '1.2-2'}}</td>
										</tr>
										<tr>
											<td class="no-line" colspan="3"></td>
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center" colspan="3"> SGST@9.0% </td>
											<td class="no-line text-right">Rs. {{staxamount | number : '1.2-2'}}</td>
										</tr>
		
										<tr>
											<td class="no-line" colspan="3"></td> 
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center" colspan="3"> FC </td>
											<td class="no-line text-right">Rs. + <input type="text"  class="form-control" [(ngModel)]="frightcharge" /> </td>	
										</tr>
										<tr>
											<td class="no-line" colspan="3"><strong></strong></td>
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center" colspan="3"> Discount (%)</td>
											<td class="no-line text-right">Rs. -  <input type="text"  class="form-control" [(ngModel)]="discount" />  </td>
										</tr>
										<tr>
											<td class="no-line" colspan="3"><strong> </strong></td>
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center" colspan="3"> Discount(amt)</td>
											<td class="no-line text-right">Rs. - {{discountamt}}   </td>
										</tr>
										<tr>
											<td class="no-line"  colspan="3" ></td> 
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center"  colspan="3"><strong><b>TOTAL</b></strong></td>
											<td class="no-line text-right">Rs. {{Totalwithtax | number : '1.2-2'}}</td>
										</tr>
										<tr>
											<td class="no-line" colspan="3"></td> 
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center" colspan="3">Received Amount </td>
											<td class="no-line text-right">Rs. <input type="text"  class="form-control" [(ngModel)]="receiveamt" /> </td>
										</tr>
		
										<tr>
											<td class="no-line" colspan="3"></td> 
											<td class="no-line text-right"><strong> </strong></td> 
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center" colspan="3"><strong>BALANCE</strong></td>
											<td class="no-line text-right">Rs. {{balance}}</td>
										</tr>
		
		
										<tr>
											<td class="no-line" colspan="3"></td> 
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center"  colspan="3"> Previous Balance </td>
											<td class="no-line text-right">Rs. {{previousbalance}}</td>
										</tr>
		
										<tr>
											<td class="no-line" colspan="3"></td> 
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line text-center" colspan="3"><strong>Current  Balance</strong>  </td>
											<td class="no-line text-right">Rs. {{currentbalance}}</td>
										</tr>
										
										<tr>
											<td class="no-line" colspan="3"></td> 
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td colspan="2" class="no-line text-center" colspan="3"> <strong> Invoice Amount (in words)</strong><br>{{amountInWords}} Only</td>
											 
										</tr>
		
												
										<tr class="no-line">
											<td class="no-line" colspan="3"></td> 
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td colspan="2" class="no-line text-center" colspan="3"><strong> AUTHORISED SIGNATORY FOR  </strong></td>
											 
										</tr>
										<tr>
											<td class="no-line" colspan="3"></td> 
											<td class="no-line text-right"> </td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td colspan="2" class="no-line text-center" colspan="3"> {{VendorName}}  </td>
											 
										</tr>



										<tr>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line"> </td>
											<td class="no-line text-center"><strong> <button type="submit" class="btn btn-success" (click)="onSubmit()">  <i class="fa fa-floppy-o" aria-hidden="true"></i> &nbsp;&nbsp;Submit</button>
											</strong></td>
											
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td colspan="2"> </td>
											
											 
										</tr>
		
										</tbody>
								</table>
	  <!-- <div>{{ dynamicArray | json }}</div> -->

	  			</div>
			</div>
		</div>	
	</div>
</div>
    
</div> 

</div>


 
  