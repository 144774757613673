
<form class="form-horizontal" [formGroup]="form"> 


    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">  INVOICE PURPOSE HEAD ITEM REGISTRATION</h1>



    <div class="card-body" style="background-color:#fff; ">
        <h1 class="card-title">
           Invoice Purpose Head Item Registration
        </h1>
        <div class="row">
            <div class="position-relative  form-group col-sm-6">
                <label for="HeadID" class="col-form-label">Invoice Purpose Head Category<span>*</span></label>
                <div class="">
                    <!-- <input type="text" name="City" id="City" class="form-control"> -->
    
                    <select  class="form-control" name="InvoiceHeadCategoryID" id="InvoiceHeadCategoryID"   (change)="changeOrganization($event)"  formControlName="InvoiceHeadCategoryID"> 
                        
                         
                        <option [ngValue]="null" disabled selected >Select Head</option>
                        
                        <!-- <option value="88" >Test</option> -->
                        <option *ngFor="let head of headlist" [ngValue]="head.InvoiceHeadCategoryID">{{head.HeadName}}</option>
                    
                    </select>
                
                
                    <app-field-error-display [displayError]="isFieldValid('InvoiceHeadCategoryID')" errorMsg="Please Select Head Category"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                
                </div>
            </div> 
    
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="InvoiceHeadName" class="col-form-label">Invoice Purpose Head Item Name  <span>*</span></label>
                <div class="">
                    <input type="text" name="InvoiceHeadName" id="InvoiceHeadName" class="form-control" formControlName="InvoiceHeadName">
         <app-field-error-display [displayError]="isFieldValid('InvoiceHeadName')" errorMsg="Please Enter Invoice Head Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
           
    
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="IsActive" class="col-form-label">Is Active<span>*</span></label>
                <div class="">
                    <select  name="IsActive" id="IsActive" class="form-control" formControlName="IsActive">
                            <option value="1">Active</option>
                            <option value="0">In Active</option>
                    </select>
         <app-field-error-display [displayError]="isFieldValid('IsActive')" errorMsg="Please Enter Is Active "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->
    
            
          
            
    
            
            
    
            
          
    
            <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    
    