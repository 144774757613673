
import { Component, OnInit, ElementRef, VERSION } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';

import { InvoiceItem } from '../invoiceitem';
import { datatable } from '../model/datatable.model';
import { environment } from '../../environments/environment';
// import { ToastrService } from 'ngx-toastr';
import { SelectItemGroup } from 'primeng/api';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';   
//import * as $ from "jquery";  
declare var $: any;

@Component({

  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  dynamicArray = [];
  newDynamic;
  VendorDetailId: any;
  datatable = new datatable();
  UserTypeID: any;
  selectedOrgId: string;
  groupedCities: SelectItemGroup[];
  DataField: Billdesk[];
  divisiondetail:any;
  selectedOrgName: string = 'Please Select Organization';

  selectedDivName: string = 'Please Select Division';
  selectedDiv: string;

  selectedDeptID: string;
  selectedDeptName: string = 'Please Select Department';


  ServiceUrl = environment.baseUrl;
 
  selectedRoleID: string;
  selectedRoleName: string = 'Please Select Role';


  VendorShow: boolean;


  organizaionlist: Billdesk[];
  statekey: any;
  stateList: Billdesk[];
  rolelist: Billdesk[];
  departmentlist: Billdesk[];
  divisionlist: Billdesk[];
  cityList: Billdesk[];

  rows: FormArray;
  type: string;

  // stateList:any[];

  // cityList:any[];
  // selectedCity: string;

  selectedCityName: string = 'Please Select City';
  itemHeadITemlist: any;
  selectedStateName: string = 'Please Select State';
  StateID: number;
  DistrictID: number;
  selectedState: string;
  departmentdetail: any;

  itemlist: Billdesk[];
  itemcategorylist: SelectItemGroup[];
  selectedItemCategoryID: string;
  selectedItemName = 'Please Select Item';
  selectedItemId: string;
  selectedItemCategoryName = 'Please Select Category';
  venderidsession: any;
  dataArray = [];
  amount_i = 0;
  UserID: any;
  ProductListByGroup: SelectItemGroup[] = [];
  PurposeListByGroup: SelectItemGroup[] = [];
  selectedReport: any;
  invoiceitem: InvoiceItem[];
  gstdetail: any;

  a: any;
  b: any;
  c: any;
  d: any;
  e: any;
  f: any;
  g: any;
  h: any;
  i: any;
  j: any;
  k: any;
  l: any;
  m: any;
  n: any;
  o: any;
  p: any;
  q: any;
  taxamount: any;
  ctaxamount: any;
  staxamount: any;
  totalwithoutgst: any;
  frightcharge: any;
  discount: any;
  Totalwithtax: any;
  receiveamt: any;
  balance: any;
  previousbalance: any;
  currentbalance: any;
  amountInWords: any;
  invdate: any;
  duedate: any;
  discountamt: any;


  TotalAmountAfterDiscount: any;
  TDSInPercent: any;
  discountedcost: any
  TDSInAmount: any;
  ctaxper: any;
  staxper: any;
  amtAfterTds: any;
  TotalPayableAmount: any;
  selectedProduct:any;
  vendorlist: Billdesk[];
  form: FormGroup;
  addForm: FormGroup;
  OrgID: any;
  Key: any;
  withouttax: any;


  selectedCity: string | undefined;
  VendorName: any;
  VendorBillingAddress: any;
  VendorCity: any;
  VendorPinCode: any;
  GSTN: any;
  VendorState: any;
  VendorEmailID: any;
  VendorMobileNo: any;



  vendordetail: any;
  selectedVendorName:string='Please Select Vendor';
  selectedVendorId: any;
  productlistdata:any;
  public listObj: DropDownListComponent;  
  
  public CityData: Object[] = [  
      { Id: '1', City: 'Jaipur' },  
      { Id: '2', City: 'Delhi' },  
      { Id: '3', City: 'Noida' },  
      { Id: '4', City: 'Gurgoan' },  
      { Id: '5', City: 'Pune' },  
      { Id: '6', City: 'Mumbai' },  
      { Id: '7', City: 'Ajmer' },  
      { Id: '8', City: 'Bangalore' },  
      { Id: '9', City: 'Hyderabad' },  
      { Id: '10', City: 'Vizag' }  
  ];  

  public fields: Object = { text: 'City', value: 'Id' };  
  public height: string = '220px';  
  public waterMark: string = 'Select a City';  
  public value: string = '3';  
  constructor(private fb: FormBuilder, private http: HttpClient, private route: Router, private billdeskService: BilldeskService, private elementRef: ElementRef) {


    this.groupedCities = [
      {
          label: 'Germany',
          value: 'de',
          items: [
              { label: 'Berlin', value: 'Berlin' },
              { label: 'Frankfurt', value: 'Frankfurt' },
              { label: 'Hamburg', value: 'Hamburg' },
              { label: 'Munich', value: 'Munich' }
          ]
      },
      {
          label: 'USA',
          value: 'us',
          items: [
              { label: 'Chicago', value: 'Chicago' },
              { label: 'Los Angeles', value: 'Los Angeles' },
              { label: 'New York', value: 'New York' },
              { label: 'San Francisco', value: 'San Francisco' }
          ]
      },
      {
          label: 'Japan',
          value: 'jp',
          items: [
              { label: 'Kyoto', value: 'Kyoto' },
              { label: 'Osaka', value: 'Osaka' },
              { label: 'Tokyo', value: 'Tokyo' },
              { label: 'Yokohama', value: 'Yokohama' }
          ]
      }
  ];
    this.venderidsession = localStorage.getItem('VendorId');

    console.log("this.venderidsession");
    console.log(this.venderidsession);
    this.addForm = this.fb.group({
      items: [null, Validators.required],
      items_value: ['no', Validators.required],
      VendorID: [null, Validators.required]

    });
    this.billdeskService.getOrganizationsLisInvoicetDDL().then(res => this.organizaionlist = res)
    this.billdeskService.getState().then(res => this.stateList = res)
    this.billdeskService.getUserRole().then(res => this.rolelist = res)
    this.billdeskService.getItemCategoryCombinelist().then((res:any) =>{
            this.itemcategorylist = res
            this.ProductListByGroup = res.map(g => {
              return { label: g.ItemCategoryName,value:g.ItemID, items: g.SubMenuList.map(i => ({ label: i.ItemName, value: i })) };
          });
          console.log('DDDDDXXX:::',this.ProductListByGroup );
    })
    this.billdeskService.getHeadItemCombinelist().then((res:any) =>{

console.log('restdata',res);
      this.itemHeadITemlist = res
      this.PurposeListByGroup = res.map(g => {
        return { label: g.HeadName,value:g.InvoiceHeadItemID, items: g.SubMenuList.map(i => ({ label: i.InvoiceHeadItemName, value: i })) };
    });
    } )

    this.gstdetail = ["0", "5", "12", "18", "28"]; 

    this.receiveamt = 0;
    this.discount = 0;
    this.frightcharge = 0;
  }
  ngOnInit() {
    

    console.log(this.dynamicArray);
    this.dynamicArray.push({
      InvoiceCreationDetailsID: 0,
      ItemID: '',
      InvoiceHeadItemID: '',
      InvoiceDesc: '',
      HSN: '',
      Quantity: '',
      MRP: '',
      Rate: '',
      TaxPercentage: '',
      TaxAmount: '',
      Amount: ''

    });



    setTimeout(()=>{
      console.log('DDDDD',$('.select'))


      $(document).ready(function () {
        $(".select").select2({
            placeholder: "Select a project..."
        })
        
    });


    },7000)
 
    




    this.UserID = localStorage.getItem('UserID');
    if (this.UserTypeID != 5) {
      this.http.get(this.ServiceUrl + '/vendor/vendordetail/' + this.UserID).subscribe(
        (response: any) => {
          this.VendorDetailId = response.vendordetailResult.dataobject[0].VendorID;


          this.VendorName = response.vendordetailResult.dataobject[0].VendorName;
          this.VendorBillingAddress = response.vendordetailResult.dataobject[0].VendorBillingAddress;

          this.VendorCity = response.vendordetailResult.dataobject[0].VendorCity;
          this.VendorPinCode = response.vendordetailResult.dataobject[0].VendorPinCode;
          this.GSTN = response.vendordetailResult.dataobject[0].GSTN;
          this.VendorState = response.vendordetailResult.dataobject[0].VendorState;
          this.VendorEmailID = response.vendordetailResult.dataobject[0].VendorEmailID;
          this.VendorMobileNo = response.vendordetailResult.dataobject[0].VendorMobileNo;
          localStorage.setItem('VendorId', this.VendorDetailId);
        });
    }

    // this is for billdesk 

    this.UserTypeID = localStorage.getItem('UserTypeID');
    console.log("this.UserTypeID");
    console.log(this.UserTypeID);
    if (this.UserTypeID == 5) {
      this.VendorShow = false;
      this.billdeskService.getVendorDDLInvoiceList().then((res: any) => {
        this.vendorlist = res

        console.log(this.vendorlist);
      }

      );
    } else {

      this.VendorShow = true;
    }


    // this is for billdesk 

  }


  changeVendor(e) {

console.log(e);
    this.selectedVendorName = e.value.VendorName;

    var Userid = e.value.UserID;
    this.selectedVendorId = e.value.VendorID;

    this.billdeskService.getVendorByid(Userid).then((res: any) => {

      this.vendordetail = res

      console.log(this.vendordetail);


      this.VendorDetailId = this.vendordetail[0].VendorID;

      console.log(this.VendorDetailId);
      this.VendorName =  this.vendordetail[0].VendorName;
      this.VendorBillingAddress =  this.vendordetail[0].VendorBillingAddress;

      this.VendorCity = this.vendordetail[0].VendorCity;
      this.VendorPinCode =  this.vendordetail.dataobject[0].VendorPinCode;
      this.GSTN =  this.vendordetail[0].GSTN;
      this.VendorState =  this.vendordetail[0].VendorState;
      this.VendorEmailID = this.vendordetail[0].VendorEmailID;
      this.VendorMobileNo =  this.vendordetail[0].VendorMobileNo;

      localStorage.setItem('VendorId', this.VendorDetailId);
    });


  }

  onSubmit() {


    if(this.invdate==undefined||this.invdate==''){
        alert('Please Enter Invoice Date');

        location.reload();
    }
   if(this.duedate==''||this.duedate==undefined ){
      alert('Please Enter Due Date');
      location.reload();
    }



    console.log(this.invdate);
    
    console.log(this.duedate);
console.log('test',this.dynamicArray);





   this.dynamicArray.map((todo, i) => {    
       if (typeof todo.ItemID === 'object'){
         this.dynamicArray[i].ItemID = todo.ItemID.value.ItemID;
       }       
     });



     
   this.dynamicArray.map((todo, i) => {    
    if (typeof todo.InvoiceHeadItemID === 'object'){
      this.dynamicArray[i].InvoiceHeadItemID = todo.InvoiceHeadItemID.value.InvoiceHeadItemID;
    }       
  });


     

    this.totalwithoutgst = this.dynamicArray.reduce((sum, item) => sum + (item.Quantity * item.Rate), 0);


    this.h = this.dynamicArray.reduce((sum, item) => sum + Number(item.TaxPercentage), 0);


    this.taxamount = this.dynamicArray.reduce((sum, item) => sum + Number(item.TaxAmount), 0);




    this.taxamount = this.dynamicArray.reduce((sum, item) => sum + Number(item.TaxAmount), 0);




    this.ctaxper = (this.h) / 2;
    this.staxper = (this.h) / 2;



    this.ctaxamount = (this.taxamount) / 2;
    this.staxamount = (this.taxamount) / 2;


    this.Totalwithtax = Number(this.taxamount) + Number(this.totalwithoutgst) + Number(this.frightcharge) - Number(this.discount);

    this.balance = Number(this.Totalwithtax) - Number(this.receiveamt);

    this.TotalPayableAmount = this.dynamicArray.reduce((sum, item) => sum + Number(item.Amount), 0);

    this.previousbalance = 0;
    this.currentbalance = Number(this.balance) + Number(this.previousbalance);

    this.discountamt = this.totalwithoutgst * (this.discount / 100)



    this.TotalAmountAfterDiscount = this.TotalPayableAmount - this.discountamt;
    // this.TDSInAmount= this.TotalAmountAfterDiscount*(this.TDSInPercent/100);

    this.TDSInAmount = 0;
    this.TDSInPercent = 0;
    this.amtAfterTds = this.discountamt - this.TDSInAmount;



    this.UserID = localStorage.getItem('UserID');

    this.venderidsession = localStorage.getItem('VendorId');
    
    // this.TotalPayableAmount =this.amtAfterTds+this.ctaxamount+this.staxamount;
    var obj1 =
    {
      "InvoiceCreationID": 0,
      "InvoiceDate": this.invdate,
      "OrgID": this.selectedOrgId,
      "DivID": this.selectedDiv,
      "DeptID": this.selectedDeptID,
      "UserID": this.UserID,
      "VendorID": this.venderidsession,
      "DueDate": this.duedate,
      "ValidityExpireOn": this.duedate,
      "TotalCostWithoutGST": this.totalwithoutgst,
      "DiscountPercent": Number(this.discount),
      "DiscountAmount": this.discountamt,
      "TotalAmountAfterDiscount": this.TotalAmountAfterDiscount,
      "TDSInPercent": this.TDSInPercent,
      "TDSInAmount": this.TDSInAmount,
      "TotalAmountAfterTDS": this.amtAfterTds,
      "CGSTPer": this.ctaxper,
      "CGSTAmount": this.ctaxamount,
      "SGSTPer": this.staxper,
      "SGSTAmount": this.staxamount,
      "IGSTPer": this.h,
      "IGSTAmount": this.taxamount,
      "TotalPayableAmount": this.TotalPayableAmount,
      "TDSInAmountToGovt": this.TDSInAmount,
      "FreightCharges": Number(this.frightcharge),
      "LateFeeIfApplicable": 0,
      "TotalInvoiceValue": this.TotalPayableAmount + Number(this.frightcharge) - this.discountamt,
      "PreferdablePaymentMode": "online",
      "InvoiceCreationDetailsJson": this.dynamicArray
    };


    const datarequest = {
      "CheckSum": "",
      "lead_id": "",
      "ActionFor": "Insert",
      "dataobject": [
        obj1
      ]
    };






    // if (this.form.valid) {

    let registerurl = "/invoicecreation/invoicecreationregistration";
    this.billdeskService.RegisterData(registerurl, datarequest).subscribe((res: any) => {

      if (res.invoicecreationregistrationResult.Message == 'Sucess') {

        this.route.navigate(['/InvoiceList'])
        localStorage.setItem('insertdata', 'Success');
      } else {
        // console.log('Please Try Again Later');
      }

    });



  }

  addRow() {


    this.dynamicArray.push({
      InvoiceCreationDetailsID: 0,
      ItemID: '',
      InvoiceHeadItemID: '',
      InvoiceDesc: '',
      HSN: '',
      Quantity: '',
      MRP: '',
      Rate: '',
      TaxPercentage: '',
      TaxAmount: '',
      Amount: ''
    });


  }
  deleteRow(index) {

    // this.dynamicArray.splice(index, 1);

    if (this.dynamicArray.length == 1) {
      // this.toastr.error("Can't delete the row when there is only one row", 'Warning');
      alert("Cant Not Delete");
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      // this.toastr.warning('Row deleted successfully', 'Delete row');
      alert(" Deleted Row");
      return true;
    }
  }






  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  getDivisionByOrg(e) {
    this.selectedOrgId = e.value.OrgID;
    this.selectedOrgName = e.value.OrgName;
    this.selectedDivName = 'Please Select Division';
    this.selectedDeptName = 'Please Select Department';
    this.billdeskService.getDivisionByOrgListForInvoice(e.value.OrgID).then(res => this.divisionlist = res);
  }

  changeDivision(e) {
    this.selectedDivName = e.value.DivName;


    this.selectedDiv = e.value.DivID;
    const orgId = e.value.OrgID;
    const divId = e.value.DivID;

    
    this.billdeskService.getDivisionByid(divId).then(res => this.divisiondetail = res);
    this.selectedDeptName = 'Please Select Department';
    this.billdeskService.getDepartmentlistforinvoice(orgId, divId).then(res => this.departmentlist = res);
  }




  changeDepartment(e) {

    this.selectedDeptName = e.value.DeptName;
    var DeptID = e.value.DeptID;
    this.selectedDeptName;
    this.selectedDeptID = e.value.DeptID;
    this.billdeskService.getDepartmentByid(DeptID).then(res => this.departmentdetail = res);

  }



  getValue(rate: any, qty: any, taxper: any, i: any) {
    //do your stuff

    this.withouttax = rate * qty;


    // dynamic.TaxPercentage/100)*dynamic.Quantity*dynamic.Rate 
    this.dynamicArray[i].TaxAmount = (taxper / 100) * qty * rate;
    this.dynamicArray[i].Amount = rate * qty + this.dynamicArray[i].TaxAmount;
  }


  onProductChange(event:any,productid){
console.log('product',productid);
    this.selectedProduct = event.value.ItemID;
    console.log('this.item detial',this.selectedProduct);
    
console.log('this.detail',event);
  }
}





