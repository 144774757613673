import { Component, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import {
  from,
  Observable
} from 'rxjs';

import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
@Component({
  selector: 'app-editinvoiceregistration',
  templateUrl: './editinvoiceregistration.component.html',
  styleUrls: ['./editinvoiceregistration.component.css']
})
export class EditinvoiceregistrationComponent implements OnInit {  //Create FormGroup 

  now: any;
  pipe: any
  organizaionlist: Billdesk[];
  visibleloader: boolean;
  divisionlist: Billdesk[];
  departmentlist: Billdesk[];
  vendorlist: Billdesk[];
  invforlist: Billdesk[];
  headlist: Billdesk[];
  itemcategorylist: Billdesk[];
  UserTypeID: any;
  prioritylist: Billdesk[];
  itemlist: Billdesk[];
  statekey: any;
  stateList: Billdesk[];
  A: any;
  B: any;
  C: any;
  products: any;
  cityList: Billdesk[];
  months: any;
  years: any;
  yearsdetail: any;
  type: string;
  value_c: any;
  UserID: any;
  selectedOrgId: string;

  id: any;
  application: any;
  selectedOrgName: string = 'Please Select Organization';

  selectedDivName: string = 'Please Select Division';
  selectedDiv: string;
  selectedItemCategoryID: string;
  selectedItemName = 'Please Select Item';
  selectedItemId: string;
  selectedItemCategoryName = 'Please Select Category';
  imageUrl: string = "/assets/images/noimage.png";
  imageUrl1: string = "/assets/images/noimage.png";
  fileToUpload: File = null;
  selectedPriorityId: string;
  selectedPriorityName: string = 'Please Select Priority';
  selectedDeptID: string;
  selectedDeptName: string = 'Please Select Department';
  selectedVendorId: string;
  selectedVendorName: string = 'Please Select Vendor';
  selectedInvoiceForID: string;
  selectedInvoiceForName: string = 'Please Select Inv.For';
  selectedInvoiceHeadID: string;
  selectedInvoiceHeadName: string = 'Please Select Head';
  showinvoiceattachment: boolean;
  hideattachmentupdatebutton: boolean;
  closeinvattachment: boolean;
  gstdetail: any;
  showapproveattachment: boolean;
  hideapproveupdatebutton: boolean;
  closeapprove: boolean;
  // TotalGSTAmount:any;


  ServiceUrl = environment.baseUrl;

  ImageUrl = environment.imgUrl;

  constructor(private fb: FormBuilder, private http: HttpClient, private route: Router, private billdeskService: BilldeskService, private elementRef: ElementRef, private _Activatedroute: ActivatedRoute) {
    // this.visibleloader=true;
    console.log('orgiststart');


    console.log(this.billdeskService.getOrganizationsLisInvoicetDDL().then(res => this.organizaionlist = res));

    console.log('divisionstart');


    // console.log(this.billdeskService.getDivision().then(res => this.divisionlist = res));

    console.log('departmentstart');


    // console.log(this.billdeskService.getDepartment().then(res => this.departmentlist = res));
    // console.log('stateliststart'); 


    console.log(this.billdeskService.getVendorList().then(res => this.vendorlist = res));
    console.log('stateliststart');

    console.log(this.billdeskService.getInvoiceFor().then(res => this.invforlist = res));
    console.log('invfor');

    console.log(this.billdeskService.getItemCategorylist().then(res => this.itemcategorylist = res));
    console.log('category');
 

    console.log(this.billdeskService.getHeaditemlist().then(res => this.headlist = res));
    console.log('head');

    console.log(this.billdeskService.getPriority().then(res => this.prioritylist = res));
    console.log('Priority');



    console.log(this.billdeskService.getState().then(res => this.stateList = res));

    console.log('statelistend');
  }

  form: FormGroup;
  ItemCategoryID: any;
  Key: any;
  applicant: any;
  ngOnInit() {



    this.gstdetail = ["0", "5", "12", "18", "28"];
    this.UserID = localStorage.getItem('UserID');
    console.log('userid');
    console.log(this.UserID);
    // 
    // return this.http.get<any>()


    this.id = this._Activatedroute.snapshot.paramMap.get("id");
 

    this._Activatedroute.paramMap.subscribe(params => {
      this.id = params.get('id');
      console.log(this.id);


      console.log("invoiceData")
      console.log(this.billdeskService.getInvoiceByid(this.id).then(res => this.products = res));
    });



    console.log("invoiceData")
    this.form = this.fb.group({
      VendorInvoiceNo: [null, Validators.required],
      InvoiceDate: [null, Validators.required],
      DivID: [null, Validators.required],
      OrgID: [null, Validators.required],
      DeptID: [null, Validators.required],
      VendorID: [null, Validators.required],
      InvoiceName: [null, Validators.required],
      InvoiceForID: [null, Validators.required],
      ItemCategoryID: [null, Validators.required],
      ItemID: [null, Validators.required],
      HeadID: [null, Validators.required],

      ValidityExpireOn: [],
      WarrantyImp: [],
      Priority: [null, Validators.required],
      TotalCostWithoutGST: [null, Validators.required],
      IGSTAmount: [null, Validators.required],
      TDSInAmountToGovt: [null, Validators.required],
      TDSInPercent: [null, Validators.required],
      TDSInAmount: [null, Validators.required],
      TotalAmountAfterTDS: [null, Validators.required],
      DiscountPercent: [null, Validators.required],
      TotalAmountAfterDiscount: [null, Validators.required],
      TotalInvoiceValue: [null, Validators.required],
      TotalPayableAmount: [null, Validators.required],
      IGSTPer: [null, Validators.required],
      CGSTPer: [null, Validators.required],
      SGSTPer: [null, Validators.required],
      SGSTAmount: [null, Validators.required],
      CGSTAmount: [null, Validators.required],
      // 220202022

      DiscountAmount: [null, Validators.required],
      InvoiceAttechmentURL: [],
      ApprovalLetterAttachmentURL: [],
      DepttContactPerson: [null, Validators.required],
    });



  }



  isFieldValid(field: string) {


    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
 

    
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.ItemCategoryID.setValue(e.target.value, {
      onlySelf: true
    })
  }



  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);

    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  handleFileInput1(file: FileList) {
    this.fileToUpload = file.item(0);

    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl1 = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }



 




  changeState(e) {
 
    console.log(this.billdeskService.getCity(20).then(res => this.cityList = res));
  }




  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  reset() {
    this.form.reset();
  }

  getYear() {

 

    this.yearsdetail = ["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"];

  }

  getMonth() {
    this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  }


  valueChangedD(G, L, M) {
    const value_a = parseFloat(G);
    console.log(value_a);
    console.log("value_a");
    const value_b = parseFloat(L);
    console.log(value_b);
    console.log("value_b");

    const value_d = parseFloat(M);

    console.log(value_d);
    console.log("value_d");

    const value_c = value_a + value_b + value_d;

    console.log('vendorvalue');
    console.log(value_c);
    this.form.controls['TotalPayableAmount'].setValue(value_c);
  }


  valueChangedF(A, E) {
    const value_a = parseFloat(A);
    const value_b = parseFloat(E);
    const value_c = value_a * value_b;
    const tds_amount = value_c / 100;
    this.form.controls['TDSInAmount'].setValue(tds_amount);
    this.form.controls['TDSInAmountToGovt'].setValue(tds_amount);
    // this.resetfield2();

  }



  valueChangeddiscountreceive(N, O, P) {
    console.log('n');
    console.log(N);
    console.log('o');
    console.log(O);
    console.log('p');
    console.log(P);
    const value_a = parseFloat(N);
    console.log(value_a);
    const value_b = parseFloat(O);
    console.log(value_b);
    const value_d = parseFloat(P);
    console.log(value_d);
    const value_c = value_a + value_b + value_d;
    console.log(value_c);
    this.form.controls['TotalInvoiceValue'].setValue(value_c);
    // this.form.controls['TotalPayableAmount'].setValue(value_c); 
  }

  valueChangedI(A, H) {
    const value_a = parseFloat(A);

    const value_b = parseFloat(H);
    const value_c = value_a * value_b;

    const tds_amount = value_c / 100;
    this.form.controls['DiscountAmount'].setValue(tds_amount);
    this.resetfield1();
    // this.form.controls['DiscountAmount'].setValue(tds_amount); 

  }





  valueChangedJ(G, I) {
    const value_a = parseFloat(G);
    const value_b = parseFloat(I);
    const value_c = value_a - value_b;
    this.form.controls['TotalAmountAfterDiscount'].setValue(value_c);
  }


  // valueChangedM(J,K,L){
  //   const value_a=parseFloat(J);

  //   const value_b=parseFloat(K);
  //   const value_c=parseFloat(L);
  //   const value_d=value_a+value_b+value_c;

  // this.form.controls['TotalPayableAmount'].setValue(value_d); 
  // }

  valueChangedG(A, F) {
    const value_a = parseFloat(A);

    const value_b = parseFloat(F);
    const value_c = value_a - value_b;

    this.form.controls['TotalAmountAfterTDS'].setValue(value_c);
  }

  valueChangedA(A, B) {



    console.log("changevaluechangeGST");
    const value_a = parseFloat(A);

    const value_b = parseFloat(B);
    const value_c = value_a * value_b;

    const gst_amount = value_c / 100;


    const cgst_amount = value_b / 2;


    const sgst_amount = value_b / 2;
    this.form.controls['IGSTAmount'].setValue(gst_amount);
    this.form.controls['CGSTPer'].setValue(cgst_amount);
    this.form.controls['SGSTPer'].setValue(sgst_amount);

  }


  valueChangedgsti(A, B) {

    const value_a = parseFloat(A);

    const value_b = parseFloat(B);
    const value_c = value_a * value_b;

    const gst_amount = value_c / 100;
    this.form.controls['CGSTAmount'].setValue(gst_amount);

    // this.form.controls['TotalPayableAmount'].setValue(B); 
  }



  valueChangedgstj(A, B) {

    const value_a = parseFloat(A);

    const value_b = parseFloat(B);
    const value_c = value_a * value_b;

    const gst_amount = value_c / 100;
    this.form.controls['SGSTAmount'].setValue(gst_amount);

    // this.form.controls['TotalPayableAmount'].setValue(B); 
  }


  valueChangedH1(A, B) {
    const value_a = parseFloat(A);
    console.log(value_a);
    const value_b = parseFloat(B);
    console.log(value_b);
    const value_c = value_a + value_b;

    // this.form.controls['TobePaidAfterTDS'].setValue(value_c); 

    // this.form.controls['TotalPayableAmount'].setValue(B); 
  }




  submit() {

    if (this.form.valid) {
      // this.visibleloader=true;
      this.visibleloader = true;
      //    setTimeout(function () {
      //     // ...
      // }, 10000);


      const formData = new FormData();
      console.log('divisionid');
      console.log(this.form.get('DivID').value);

      console.log('divisionid');
      // formData.append('DivID',this.form.get('DivID').value); 
      console.log(this.form.get('VendorInvoiceNo').value);


      // this.form.get('').value)
      console.log('divisionid');
      // formData.append('DivID',this.form.get('DivID').value); 
      console.log(this.form.get('HeadID').value);


      console.log('InvoiceDate');

      var mydate1 = this.form.get('InvoiceDate').value;

      console.log(mydate1);


      let position = mydate1.search("/");

      if (position != -1) {

        var splitted = mydate1.split("/");
        console.log('splitted');
        console.log(splitted);
        console.log('splitted date');
        console.log(splitted[2] + "-" + splitted[1] + "-" + splitted[0]);
        var InvoiceDate_converted = splitted[2] + "-" + splitted[1] + "-" + splitted[0];
        formData.append('InvoiceDate', InvoiceDate_converted);
      } else {
        console.log('not splitted date');
        formData.append('InvoiceDate', mydate1);
      }





      console.log('vendorid');
      console.log(this.form.get('VendorID').value);
      formData.append('VendorID', this.form.get('VendorID').value);

      formData.append('InvoiceName', this.form.get('InvoiceName').value);

      formData.append('ItemID', this.form.get('ItemID').value);


      formData.append('InvoiceHeadItemID', this.form.get('HeadID').value);

      // formData.append('InvoiceDesc',this.form.get('InvoiceDesc').value); 


      formData.append('InvoiceDesc', 'Description');
      console.log('InvoiceDesc');
      // formData.append('InvoiceMonth',this.form.get('InvoiceMonth').value); 


      formData.append('InvoiceMonth', 'FEB');

      console.log('InvoiceMonth');



      var mydate2 = this.form.get('ValidityExpireOn').value;





      let position1 = mydate2.search("/");


      if (position1 != -1) {

        var splitted1 = mydate2.split("/");

        console.log('splitted date');
        console.log(splitted1);
        console.log(splitted1[2] + "-" + splitted1[1] + "-" + splitted1[0]);
        var ValidityExpireOn_converted = splitted1[2] + "-" + splitted1[1] + "-" + splitted1[0];
        formData.append('ValidityExpireOn', ValidityExpireOn_converted);
      } else {
        console.log('validity not splitted date');
        formData.append('ValidityExpireOn', mydate2);
      }








      formData.append('WarrantyImp', this.form.get('WarrantyImp').value);


      formData.append('Priority', this.form.get('Priority').value);

      formData.append('TotalCostWithoutGST', this.form.get('TotalCostWithoutGST').value);

      console.log('TotalCostWithoutGST');
      console.log(this.form.get('TotalCostWithoutGST').value);
      formData.append('IGSTAmount', this.form.get('IGSTAmount').value);
      console.log('IGSTAmount');
      console.log(this.form.get('IGSTAmount').value);

      formData.append('TDSInAmountToGovt', this.form.get('TDSInAmountToGovt').value);

      console.log('TDSInAmountToGovt');
      console.log(this.form.get('TDSInAmountToGovt').value);

      formData.append('TDSInPercent', this.form.get('TDSInPercent').value);
      console.log('TDSInPercent');
      console.log(this.form.get('TDSInPercent').value);



      formData.append('TDSInAmount', this.form.get('TDSInAmount').value);
      console.log('TDSInAmount');
      console.log(this.form.get('TDSInAmount').value);
      formData.append('TotalAmountAfterTDS', this.form.get('TotalAmountAfterTDS').value);
      console.log('TotalAmountAfterTDS');
      console.log(this.form.get('TotalAmountAfterTDS').value);
      formData.append('DiscountPercent', this.form.get('DiscountPercent').value);
      console.log('DiscountPercent');
      console.log(this.form.get('DiscountPercent').value);
      formData.append('DiscountAmount', this.form.get('DiscountAmount').value);
      console.log('DiscountAmount');
      console.log(this.form.get('DiscountAmount').value);

      formData.append('TotalAmountAfterDiscount', this.form.get('TotalAmountAfterDiscount').value);
      console.log('TotalAmountAfterDiscount');
      console.log(this.form.get('TotalAmountAfterDiscount').value);
      formData.append('TotalInvoiceValue', this.form.get('TotalInvoiceValue').value);
      console.log('TotalInvoiceValue');
      console.log(this.form.get('TotalInvoiceValue').value);



      // formData.append('LateFeeIfApplicable',this.form.get('LateFeeIfApplicable').value);
      // console.log('LateFeeIfApplicable');
      // console.log(this.form.get('LateFeeIfApplicable').value);
      formData.append('TotalPayableAmount', this.form.get('TotalPayableAmount').value);
      console.log('TotalPayableAmount');
      console.log(this.form.get('TotalPayableAmount').value);

      formData.append('IGSTPer', this.form.get('IGSTPer').value);
      console.log('IGSTPer');
      console.log(this.form.get('IGSTPer').value);


      formData.append('CGSTPer', this.form.get('CGSTPer').value);
      console.log('CGSTPer');
      console.log(this.form.get('CGSTPer').value);


      formData.append('SGSTPer', this.form.get('SGSTPer').value);
      console.log('SGSTPer');
      console.log(this.form.get('SGSTPer').value);




      formData.append('CGSTAmount', this.form.get('CGSTAmount').value);
      console.log('CGSTAmount');
      console.log(this.form.get('CGSTAmount').value);


      formData.append('FreightCharges', '');
      console.log('FreightCharges');
      // console.log(this.form.get('FreightCharges').value);




      formData.append('SGSTAmount', this.form.get('SGSTAmount').value);
      console.log('SGSTAmount');
      console.log(this.form.get('SGSTAmount').value);


      formData.append('InvoiceAttechmentURL', this.form.get('InvoiceAttechmentURL').value);


      formData.append('ApprovalLetterAttachmentURL', this.form.get('ApprovalLetterAttachmentURL').value);
      console.log('InvoiceAttechmentURL  attachment url');
      console.log(this.form.get('InvoiceAttechmentURL').value);
      console.log('ApprovalLetterAttachmentURL');
      console.log(this.form.get('ApprovalLetterAttachmentURL').value);




      formData.append('DepttContactPerson', this.form.get('DepttContactPerson').value);

      formData.append('VendorInvoiceNo', this.form.get('VendorInvoiceNo').value);


      formData.append('InvoiceID', this.id);
      console.log('OrgID');
      console.log(this.form.get('OrgID').value);
      formData.append('OrgID', this.form.get('OrgID').value);
      console.log('DivID');
      console.log(this.form.get('DivID').value);
      formData.append('DivID', this.form.get('DivID').value);
      console.log('DeptID');
      console.log(this.form.get('DeptID').value);
      formData.append('DeptID', this.form.get('DeptID').value);


      this.UserID = localStorage.getItem('UserID');
      console.log('userid');
      console.log(this.UserID);


      formData.append('UserID', this.UserID);


      formData.append('InvoiceForID', this.form.get('InvoiceForID').value);

      formData.append('PaymentPriorityID', '1');  //hardcord

      formData.append('ItemCategoryID', this.form.get('ItemCategoryID').value);
      formData.append('InvoiceHeadId', '1');

      // formData.append('InvoiceYear',this.form.get('InvoiceYear').value); 


      formData.append('InvoiceYear', '2022');  //change after meeting 
      console.log('InvoiceYear');


      formData.append('DayRemaining', '1'); //hardcord



      console.log(this.form.get('ApprovalLetterAttachmentURL').value)

      console.log(this.form.get('InvoiceAttechmentURL').value)
      formData.append('ItemName', 'actualformtestdata');
      formData.append('ApprovalLetterAttachmentURL', this.form.get('ApprovalLetterAttachmentURL').value);
      formData.append('InvoiceAttechmentURL', this.form.get('InvoiceAttechmentURL').value);

      this.UserTypeID = localStorage.getItem('UserTypeID');

      this.id = this._Activatedroute.snapshot.paramMap.get("id");




      // invoiceregistration/invoiceregistration/130
      // this.http.post(this.ServiceUrl+'/InvoiceRegistration?UserTypeID='+this.UserTypeID+'&Userid='+this.UserID+'&InvoiceType=1&InvoiceId='+this.id, formData)
      // .subscribe(res => {
      // this.visibleloader=false;
      // console.log(res); 
      // this.route.navigate(['/InvoiceList']) 
      //  localStorage.setItem('updatedata', 'Success');
      // })


      this.http.post(this.ServiceUrl + '/invoiceregistration/invoiceregistrationupdate', formData)
        .subscribe((res: any) => {
          if (res.invoiceregistrationupdateResult.Message == 'Sucess') {
            this.visibleloader = false;
            console.log(res);
            this.route.navigate(['/InvoiceList'])
            localStorage.setItem('updatedata', 'Success');
          } else {
            console.log('Please Try Again Later');
          }
        })


    } else {
      this.validateAllFormFields(this.form);
    }
  }





  onFileChange1(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      console.log(file);
      this.form.patchValue({
        ApprovalLetterAttachmentURL: file
      });

    }
  }

  onFileChange(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      this.form.patchValue({
        InvoiceAttechmentURL: file
      });

    }
  }






  getDivisionByOrg(e) {

    console.log('eventonDFchange')

    console.log(e);
    localStorage.setItem('organizationid', e);
    console.log(this.billdeskService.getDivisionByOrgListForInvoice(e).then(res => this.divisionlist = res));
  }
  changeDivision(e) {
    console.log(e);
    console.log("event detail", e);


    const orgId = localStorage.getItem('organizationid');
    console.log('orgid', orgId);
    const divId = e;
    console.log('stateliststart');
    console.log(this.billdeskService.getDepartmentlistforinvoice(orgId, divId).then(res => this.departmentlist = res));
    console.log('stateliststart');
  }


  getItemByCategory(e) {

    console.log(this.billdeskService.getItemByCategory(e).then(res => this.itemlist = res));
    console.log('item');
  }
  changeItemStage(e) {
    console.log(e);
    this.selectedItemName = e.value.ItemName;
    console.log(this.selectedItemName);
    this.selectedItemId = e.value.ItemID;
  }


  changePriority(e) {
    console.log(e);
    this.selectedPriorityName = e.value.PriorityName;
    console.log(this.selectedPriorityName);
    this.selectedPriorityId = e.value.PriorityID;
  }



  changeDepartment(e) {
    console.log(e);
    this.selectedDeptName = e.value.DeptName;
    console.log(this.selectedDeptName);
    this.selectedDeptID = e.value.DeptID;
  }


 

  changeInvoiceFor(e) {
    console.log(e);
    this.selectedInvoiceForName = e.value.InvoiceForName;
    console.log(this.selectedInvoiceForName);
    this.selectedInvoiceForID = e.value.InvoiceForID;
  }

  changeHeadName(e) {
    console.log(e);
    this.selectedInvoiceHeadName = e.value.InvoiceHeadItemName;
    console.log(this.selectedInvoiceHeadName);
    this.selectedInvoiceHeadID = e.value.InvoiceHeadItemID;
  }



  showinvattachmentfunction() {
    this.showinvoiceattachment = true;
    this.hideattachmentupdatebutton = true;
    this.closeinvattachment = true;
  }
  closeinvattachmentfunction() {
    this.showinvoiceattachment = false;
    this.closeinvattachment = false;
    this.hideattachmentupdatebutton = false;
  }



  showapproveattachmentfunction() {
    this.showapproveattachment = true;
    this.hideapproveupdatebutton = true;
    this.closeapprove = true;
  }
  closeapproveattachmentfunction() {
    this.showapproveattachment = false;
    this.closeapprove = false;
    this.hideapproveupdatebutton = false;
  }





  resetfield() {
    const reset_amount = '';
    this.form.controls['DiscountPercent'].setValue(reset_amount);
    this.form.controls['DiscountAmount'].setValue(reset_amount);

    this.form.controls['TotalAmountAfterDiscount'].setValue(reset_amount);

    this.form.controls['TDSInPercent'].setValue(reset_amount);

    this.form.controls['TDSInAmount'].setValue(reset_amount);

    this.form.controls['TotalAmountAfterTDS'].setValue(reset_amount);

    this.form.controls['IGSTPer'].setValue(reset_amount);

    this.form.controls['CGSTPer'].setValue(reset_amount);

    this.form.controls['SGSTPer'].setValue(reset_amount);
    this.form.controls['IGSTAmount'].setValue(reset_amount);
    this.form.controls['CGSTAmount'].setValue(reset_amount);
    this.form.controls['SGSTAmount'].setValue(reset_amount);
    this.form.controls['TotalPayableAmount'].setValue(reset_amount);
    this.form.controls['TDSInAmountToGovt'].setValue(reset_amount);
    this.form.controls['TotalInvoiceValue'].setValue(reset_amount); 

  }


  resetfield1() {
    const reset_amount = '';
 
    this.form.controls['TDSInPercent'].setValue(reset_amount);

    this.form.controls['TDSInAmount'].setValue(reset_amount);

    this.form.controls['TotalAmountAfterTDS'].setValue(reset_amount);

    this.form.controls['IGSTPer'].setValue(reset_amount);

    this.form.controls['CGSTPer'].setValue(reset_amount);

    this.form.controls['SGSTPer'].setValue(reset_amount);
    this.form.controls['IGSTAmount'].setValue(reset_amount);
    this.form.controls['CGSTAmount'].setValue(reset_amount);
    this.form.controls['SGSTAmount'].setValue(reset_amount);
    this.form.controls['TotalPayableAmount'].setValue(reset_amount);
    this.form.controls['TDSInAmountToGovt'].setValue(reset_amount);
    this.form.controls['TotalInvoiceValue'].setValue(reset_amount);
  }


  resetfield2() {
    const reset_amount = '';
 
    this.form.controls['IGSTPer'].setValue(reset_amount);
    this.form.controls['CGSTPer'].setValue(reset_amount);
    this.form.controls['SGSTPer'].setValue(reset_amount);
    this.form.controls['IGSTAmount'].setValue(reset_amount);
    this.form.controls['CGSTAmount'].setValue(reset_amount);
    this.form.controls['SGSTAmount'].setValue(reset_amount);
    this.form.controls['TotalPayableAmount'].setValue(reset_amount);
    this.form.controls['TDSInAmountToGovt'].setValue(reset_amount);
    // this.form.controls['TotalInvoiceValue'].setValue(reset_amount);    


  }




}
