import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; 
import { HomeComponent } from './home/home.component'; 
import{ LoginComponent  } from './login/login.component';
import{ InvoiceRegistrationComponent  } from './invoice-registration/invoice-registration.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceHistoryListComponent } from './invoice-history-list/invoice-history-list.component';
import { InvoicehistoryComponent } from './invoicehistory/invoicehistory.component';
import { EditinvoiceregistrationComponent } from './editinvoiceregistration/editinvoiceregistration.component';
import {PocreationComponent} from'./pocreation/pocreation.component';
import {PocreationpartComponent }from './pocreationpart/pocreationpart.component'; 
import { PoListComponent  } from './po-list/po-list.component';
import  { PoDetailsComponent} from './po-details/po-details.component';
import { OrganizationregistrationdetailComponent } from './organizationregistrationdetail/organizationregistrationdetail.component';
import { OrganizationlistComponent} from './organizationlist/organizationlist.component';
import{ InstituteRegistrationComponent } from './institute-registration/institute-registration.component';
import{ InstituteListComponent } from './institute-list/institute-list.component';
import{ DepartmentRegistrationComponent } from './department-registration/department-registration.component';
import{ DepartmentListComponent } from './department-list/department-list.component';
import{ DomainRegistrationComponent } from './domain-registration/domain-registration.component';
import{ DomainListComponent } from './domain-list/domain-list.component';
import{ VendorRegistrationComponent } from './vendor-registration/vendor-registration.component';
import{VendorListComponent} from './vendor-list/vendor-list.component';
import{InvoiceHeadsRegistrationComponent} from './invoice-heads-registration/invoice-heads-registration.component';
import{InvoiceHeadsListComponent} from './invoice-heads-list/invoice-heads-list.component';
import{CategoryRegistrationComponent} from './category-registration/category-registration.component';
import{CategoryListComponent} from './category-list/category-list.component';
import{UserRegistrationComponent} from './user-registration/user-registration.component';
import{UserListComponent} from './user-list/user-list.component';
import { EditorganizationComponent} from './editorganization/editorganization.component';
import { InnvoicedetailComponent} from './innvoicedetail/innvoicedetail.component';
import {StageRegistrationComponent } from './stage-registration/stage-registration.component';
import {StageListComponent } from './stage-list/stage-list.component';
import {InvoiceForListComponent } from './invoice-for-list/invoice-for-list.component';
import { InvoiceForRegistrationComponent } from './invoice-for-registration/invoice-for-registration.component';
import {  UserRoleRegistrationComponent } from './user-role-registration/user-role-registration.component';
import {UserRoleComponent} from './user-role/user-role.component';
import {InstituewisesummaryComponent} from './instituewisesummary/instituewisesummary.component';
import { PrioritylistComponent } from './prioritylist/prioritylist.component';
import { PriorityregisterComponent } from './priorityregister/priorityregister.component';
import { MstmenulistComponent} from './mstmenulist/mstmenulist.component';
import { MstmenuregistrationComponent} from './mstmenuregistration/mstmenuregistration.component';
import { RolemenumappingComponent }from './rolemenumapping/rolemenumapping.component';
import { RolemenumappinglistComponent} from './rolemenumappinglist/rolemenumappinglist.component';
import {UserrolerightmappingComponent} from './userrolerightmapping/userrolerightmapping.component';
import {UserrolerightmappinglistComponent} from './userrolerightmappinglist/userrolerightmappinglist.component';
import { InvoicedetailComponent} from './invoicedetail/invoicedetail.component';
import {InvoiceheaditemregistrationComponent} from './invoiceheaditemregistration/invoiceheaditemregistration.component';
import {InvoiceheaditemlistComponent} from './invoiceheaditemlist/invoiceheaditemlist.component';



import { ReportMonthWiseComponent } from './report-month-wise/report-month-wise.component';
import { ReportInvoiceYearlyComponent } from './report-invoice-yearly/report-invoice-yearly.component';
import { ReportVendorWiseComponent} from './report-vendor-wise/report-vendor-wise.component';
import {ReportDepartmentWiseComponent} from './report-department-wise/report-department-wise.component';
import {ReportMonthVendorWiseComponent} from './report-month-vendor-wise/report-month-vendor-wise.component';
import { ReportInstituteWiseComponent} from './report-institute-wise/report-institute-wise.component';
import {ReportVendorInstituteWiseComponent} from './report-vendor-institute-wise/report-vendor-institute-wise.component';
import {ReportVendorServiceWiseComponent} from './report-vendor-service-wise/report-vendor-service-wise.component';

import { EditdivisionComponent } from './editdivision/editdivision.component';
import { EditdepartmentComponent} from './editdepartment/editdepartment.component';

import { EdititemcategoryComponent} from './edititemcategory/edititemcategory.component';

import { EdititemComponent} from './edititem/edititem.component';

import { EditinvoiceforComponent} from './editinvoicefor/editinvoicefor.component';

import { EditinvoiceheadcategoryComponent} from './editinvoiceheadcategory/editinvoiceheadcategory.component';

import { EditinvoiceheaditemComponent} from './editinvoiceheaditem/editinvoiceheaditem.component';

import { EditvendorComponent} from './editvendor/editvendor.component';
import { EditstageComponent} from './editstage/editstage.component';
import { EditpriorityComponent} from './editpriority/editpriority.component';
import { EdituserComponent} from './edituser/edituser.component';
import { EditroleComponent} from './editrole/editrole.component';
import { EditmenuComponent} from './editmenu/editmenu.component';
 
import { GenerateinvoiceComponent } from './Invoice/generateinvoice/generateinvoice.component';
import { PoDetailFullpageComponent } from './po-detail-fullpage/po-detail-fullpage.component';


import { PolistByMonthComponent } from './polist-by-month/polist-by-month.component';


import { PoListByMonthYearComponent } from './po-list-by-month-year/po-list-by-month-year.component'; 
import { BillInvoiceRegistrationComponent } from './bill-invoice-registration/bill-invoice-registration.component';
import { InvoicegenerationprocessedComponent } from './invoicegenerationprocessed/invoicegenerationprocessed.component';
import { AuthGuard } from './guard/auth.guard';

import { PobathlistyearandmonthComponent } from './pobathlistyearandmonth/pobathlistyearandmonth.component';
import { PoSummaryComponent } from './po-summary/po-summary.component';
import { PosummarysheetagroupbyComponent } from './posummarysheetagroupby/posummarysheetagroupby.component';
import { Pobatchlistsheetb2Component } from './pobatchlistsheetb2/pobatchlistsheetb2.component';
import { TableShowComponent } from './table-show/table-show.component';
import { RoleGuard } from './guard/role.guard';
import { PurchaseListComponent } from './inventory/purchase-list/purchase-list.component';
import { PurchaseEntryComponent } from './inventory/purchase-entry/purchase-entry.component';
import { BrandListComponent } from './inventory/brand-list/brand-list.component';
import { PriceTypeListComponent } from './inventory/price-type-list/price-type-list.component';
import { PriceTypeEntryComponent } from './inventory/price-type-entry/price-type-entry.component';
import { UnitListComponent } from './inventory/unit-list/unit-list.component';
import { UnitEntryComponent } from './inventory/unit-entry/unit-entry.component';
import { EditBrandComponent } from './inventory/edit-brand/edit-brand.component';
import { BrandEntryComponent } from './inventory/brand-entry/brand-entry.component';
import { SalesListComponent } from './inventory/sales-list/sales-list.component';
import { SalesEntryComponent } from './inventory/sales-entry/sales-entry.component';
import { StockListComponent } from './inventory/stock-list/stock-list.component';
import { ExportStockComponent } from './inventory/export-stock/export-stock.component';
import { InstocksummaryComponent } from './inventory/instocksummary/instocksummary.component';
import { ProductcreatebyitemComponent } from './inventory/productcreatebyitem/productcreatebyitem.component';
import { ProductcreatebyitemlistComponent } from './inventory/productcreatebyitemlist/productcreatebyitemlist.component';
import { GroupbysaleComponent } from './inventory/groupbysale/groupbysale.component';
import { ColumnhideinvoicelistComponent } from './columnhideinvoicelist/columnhideinvoicelist.component';
import { YearwisesummaryComponent } from './yearwisesummary/yearwisesummary.component';
import { EditPriceTypeComponent } from './inventory/edit-price-type/edit-price-type.component'; 
import { SaleinvoiceComponent } from './inventory/saleinvoice/saleinvoice.component';
import { EditUnitComponent } from './inventory/edit-unit/edit-unit.component';
import { SaletypelistComponent } from './saletypelist/saletypelist.component';
import { SaletyperegistrationComponent } from './saletyperegistration/saletyperegistration.component';
import { SalereportdatewiseComponent } from './inventory/salereportdatewise/salereportdatewise.component';
import { BoxMakingComponent } from './inventory/box-making/box-making.component';
import { BoxMakingListComponent } from './inventory/box-making-list/box-making-list.component';
import { BoxStockInComponent } from './inventory/box-stock-in/box-stock-in.component';
import { BoxStockOutComponent } from './inventory/box-stock-out/box-stock-out.component';
import { BoxsalelistComponent } from './inventory/boxsalelist/boxsalelist.component';
 
const routes: Routes = [
  
 

{ path: '',  component: LoginComponent}, 
{ path: 'dashboard', component: HomeComponent ,data : {  
  title: 'Invoice List',
  role: 5  
  }},



{ path: 'Instituewisesummary/:id', component: InstituewisesummaryComponent,data : {  
    title: 'Institute Wise Summary'  
} },   
   
 
{ path: 'Priority-List', component: PrioritylistComponent,data : {  
  title: 'Priority List'  
},
canActivate: [RoleGuard] },

{ path: 'Priority-Registration', component: PriorityregisterComponent,data : {  
  title: 'Priority Registration'  
}
 },


 { path: 'InvoiceRegistration', component: InvoiceRegistrationComponent,data : {  
      title: 'Invoice Registration'  
  } ,
  canActivate: [RoleGuard]
},

// { path: 'InvoiceList', component: InvoiceListComponent,data : {  
//       title: 'Invoice List detail',
//       role: "InvoiceList detail"  
//       },
//      canActivate: [RoleGuard]
// },

{ path: 'InvoiceList', component: InvoiceListComponent,data : {  
  title: 'Invoice List detail',
  role: "InvoiceList detail"  
  },
  canActivate: [RoleGuard] 
},
    
    { path: 'InvoiceHistoryList', component: InvoiceHistoryListComponent,
    canActivate: [RoleGuard]},

       { path: 'Invoice-History-List', component: InvoicehistoryComponent,
       canActivate: [RoleGuard]},
       
       { path: 'Edit-Invoice-Registration/:id', component: EditinvoiceregistrationComponent,data : {  
        title: 'Edit Invoice'  
    } },
       

 { path:'Po-Creation' ,component:PocreationComponent,data : {  
  title: 'PO Creation'  
},
canActivate: [RoleGuard]},

 { path:'Po-Creation-Next-Step/:id' ,component:PocreationpartComponent ,
 canActivate: [RoleGuard]}
,
 
 { path:'PO-List' ,component:PoListComponent,data : {  
  title: 'PO List'  
}

// ,
// canActivate: [RoleGuard]

},

{ path:'PO-Detail/:id' ,component:PoDetailsComponent,data : {  
  title: 'PO Detail'  
} },


{ path:'Organization-Registration' ,component:OrganizationregistrationdetailComponent,data : {  
  title: 'Organization Registration'  
} },

{ path: 'Division-Registration', component: InstituteRegistrationComponent,data : {  
  title: 'Division Registration'  
} },
{ path: 'Division-List', component: InstituteListComponent,data : {  
  title: 'Division List'  
},
canActivate: [RoleGuard] },
{ path: 'Department-Registration', component: DepartmentRegistrationComponent,data : {  
  title: 'Department Registration'  
} },
{ path: 'Department-List', component: DepartmentListComponent,data : {  
  title: 'Department List'  
},
canActivate: [RoleGuard] },
{ path: 'Item-Registration', component: DomainRegistrationComponent,data : {  
  title: 'Item Registration'  
} },
{ path: 'Item-List', component: DomainListComponent,data : {  
  title: 'Item List'  
},
// canActivate: [RoleGuard]

},
{ path: 'Vendor-Registration', component: VendorRegistrationComponent,data : {  
  title: 'Vendor Registration'  
} },
{ path: 'Vendor-List', component: VendorListComponent,data : {  
  title: 'Vendor List'  
} ,
canActivate: [RoleGuard] },
{ path: 'Invoice-Heads-Registration', component: InvoiceHeadsRegistrationComponent,data : {  
  title: 'Invoice Heads Registration'  
}},
{ path: 'InvoiceHeadsList', component: InvoiceHeadsListComponent,data : {  
  title: 'Heads List'  
},
canActivate: [RoleGuard]},
{ path: 'Category-Registration', component: CategoryRegistrationComponent,data : {  
  title: 'Category Registration'  
}
// ,
// canActivate: [RoleGuard]
},

{ path: 'User-List', component: UserListComponent,
// canActivate: [RoleGuard]
},


{ path: 'Organization-List', component: OrganizationlistComponent,data : {  
  title: 'Organization List'  
},
     canActivate: [RoleGuard]
},


// { path: 'InvoiceList', component: InvoiceListComponent,data : {  
//       title: 'Invoice List detail',
//       role: "InvoiceList detail"  
//       },
//      canActivate: [RoleGuard]
// },

{ path:'Category-List',component: CategoryListComponent,data : {  
  title: 'Category List'  
},
canActivate: [RoleGuard]
},

{ path:'User-Registration',component: UserRegistrationComponent,data : {  
  title: 'User Registration'  
}},

{ path:'Edit-Organization/:id',component: EditorganizationComponent,data : {  
  title: 'Edit Organization'  
}},

{ path:'View-Invoice/:id',component: InnvoicedetailComponent,data : {  
  title: 'View Invoice'  
}},

{path:'Stage-List',component:  StageListComponent,data : {  
  title: 'Stage List'  
},
canActivate: [RoleGuard]},

{path:'Stage-Registration',component:StageRegistrationComponent,data : {  
  title: 'Stage Registration'  
}},


{path:'Invoice-For-List',component:  InvoiceForListComponent,data : {  
  title: 'Invoice For List'  
},
canActivate: [RoleGuard]},
{path:'Invoice-For-Registration',component:InvoiceForRegistrationComponent,data : {  
  title: 'Invoice For Registration'  
}},
{path:'User-Role-List',component:UserRoleComponent,data : {  
  title: 'User Role List'  
},
canActivate: [RoleGuard]},
{path:'User-Role-Registration',component:UserRoleRegistrationComponent,data : {  
  title: 'User Role Registration'  
}}

,
{path:'Menu-List',component:MstmenulistComponent,data : {  
  title: 'Menu List'  
},
canActivate: [RoleGuard]}

,
{path:'Menu-Registration',component:MstmenuregistrationComponent,data : {  
  title: 'Menu Registration'  
}}

,
{path:'Role-Menu-Register',component:RolemenumappingComponent ,data : {  
  title: 'Role Menu Register'  
}}

,
{path:'Role-Menu-List',component:RolemenumappinglistComponent ,data : {  
  title: 'Role Menu List'  
},
canActivate: [RoleGuard]}
,
{path:'User-Role-Right-Register',component:UserrolerightmappingComponent ,data : {  
  title: 'User Role Right Register'  
}}

,
{path:'User-Role-Right-List',component:UserrolerightmappinglistComponent ,data : {  
  title: 'User Role Right List'  
}}
,
{path:'InvoiceDetail/:id/:id1/:id2/:id3',component:InvoicedetailComponent ,data : {  
  title: 'Invoice Detail'  
}}

,
{path:'Invoice-Head-Item-List',component:InvoiceheaditemlistComponent ,data : {  
  title: 'Invoice Head Item List'  
},
canActivate: [RoleGuard]}
,
{path:'Invoice-Head-Item-Registration',component:InvoiceheaditemregistrationComponent ,data : {  
  title: 'Invoice Head Item Registration'  
}}

,
{path:'Month-Wise-Report',component:ReportMonthWiseComponent ,data : {  
  title: 'Month Wise Report'  
},
canActivate: [RoleGuard]}


,
{path:'Year-Wise-Report',component:ReportInvoiceYearlyComponent ,data : {  
  title: 'Year Wise Report'  
  ,
  canActivate: [RoleGuard]}}


,
{path:'Vendor-Wise-Report',component:ReportVendorWiseComponent ,data : {  
  title: 'Vendor Wise Report'  
},
canActivate: [RoleGuard]}



,
{path:'Department-Wise-Report',component:ReportDepartmentWiseComponent ,data : {  
  title: 'Department Wise Report'  
},
canActivate: [RoleGuard]}



,
{path:'Month-Vendor-Wise-Report',component:ReportMonthVendorWiseComponent ,data : {  
  title: 'Month & Vendor Wise Report'  
},
canActivate: [RoleGuard]}



,
{path:'Institue-Wise-Report',component:ReportInstituteWiseComponent ,data : {  
  title: 'Institute Wise Report'  
},
canActivate: [RoleGuard]}


,
{path:'Vendor-Institute-Wise-Report',component:ReportVendorInstituteWiseComponent ,data : {  
  title: 'Vendor Institute Wise Report'  
},
canActivate: [RoleGuard]}


,
{path:'Vendor-Service-Wise-Report',component:ReportVendorServiceWiseComponent ,data : {  
  title: 'Vendor Service Wise Report'  
},
canActivate: [RoleGuard]}
,
{path:'Edit-Division/:id',component:EditdivisionComponent ,data : {  
  title: 'Edit Division'  
}}
,
{path:'Edit-Department/:id',component:EditdepartmentComponent ,data : {  
  title: 'Edit Department'  
}}
,
{path:'Edit-ItemCategory/:id',component:EdititemcategoryComponent ,data : {  
  title: 'Edit ItemCategory'  
}}
,
{path:'Edit-Item/:id',component:EdititemComponent ,data : {  
  title: 'Edit Item'  
}}
,
{path:'Edit-Invoice-For/:id',component:EditinvoiceforComponent ,data : {  
  title: 'Edit Invoice For'  
}}
,
{path:'Edit-Invoice-Head-Category/:id',component:EditinvoiceheadcategoryComponent ,data : {  
  title: 'Edit Invoice For'  
}}
,
{path:'Edit-Invoice-Head-Item/:id',component:EditinvoiceheaditemComponent ,data : {  
  title: 'Edit Invoice For'  
}}

,
{path:'Edit-Vendor/:id',component:EditvendorComponent ,data : {  
  title: 'Edit Vendor'  
}},
{path:'Edit-Stage/:id',component:EditstageComponent ,data : {  
  title: 'Edit Stage'  
}},
{path:'Edit-Priority/:id',component:EditpriorityComponent ,data : {  
  title: 'Edit Priority'  
}},
{path:'Edit-User/:id',component:EdituserComponent ,data : {  
  title: 'Edit User'  
}},
{path:'Edit-Role/:id',component:EditroleComponent ,data : {  
  title: 'Edit Role'  
}},
{path:'Edit-Menu/:id',component:EditmenuComponent ,data : {  
  title: 'Edit Menu'  
}},
 
{path:'InvoiceGeneration',component:GenerateinvoiceComponent,data:{

  title:"Invoice Generate"
}},
 
{path:'InvoiceFullpage',component:PoDetailFullpageComponent,data:{

  title:"Invoice Fullpage"
}},
 
{path:'pobymonth',component:PolistByMonthComponent,data:{

  title:"Po List By MOnth"
}},
 
{path:'Sheet-B-I/:id',component:PoListByMonthYearComponent,data:{
  title:"Sheet-B-I"
}}
,

{path:'CreateVendorInvoice',component:InvoicegenerationprocessedComponent,data:{

  title:"Create Vendor Invoice"
},
canActivate: [RoleGuard]}
,

{path:'BillInvoice',component:BillInvoiceRegistrationComponent,data:{ 
  title:"BillInvoice",
  role: 8  
},
canActivate:[AuthGuard]}
, 

{path:'Po-Bath-List-Year-Month',component:PobathlistyearandmonthComponent,data:{

  title:"Year & Month Wise"
},
canActivate: [RoleGuard]}, 

{path:'Sheet-A-Month-Wise-PO-Summary',component:PoSummaryComponent,data:{

  title:"Sheet-A-Month-Wise-PO-Summary"
},
canActivate: [RoleGuard]}, 

{path:'Sheet-A-GroupBy-Month-Wise-Po-Summary',component:PosummarysheetagroupbyComponent,data:{

  title:"Sheet-A-GroupBy-Month-Wise-Po-Summary"
},
canActivate: [RoleGuard]}, 

{path:'Sheet-B-GroupBy-Month-Wise-Po-List',component:Pobatchlistsheetb2Component,data:{

  title:"Sheet-B-GroupBy-Month-Wise-Po-List"
},
canActivate: [RoleGuard]},
{path:'TableShow',component:TableShowComponent,data:{

  title:"TableShow"
}},





{ path: 'purchase_list', component: PurchaseListComponent,data : {  
  title: 'Purchase List'  
}
// ,canActivate: [RoleGuard] 
}

,
{ path: 'purchase_entry', component: PurchaseEntryComponent,data : {  
  title: 'Purchase List'  
}
// ,canActivate: [RoleGuard] 
},
{ path: 'brand_list', component: BrandListComponent,data : {  
  title: 'Brand List'  
}
// ,canActivate: [RoleGuard] 
}
,
{ path: 'price_type_list', component: PriceTypeListComponent,data : {  
  title: 'Price Type List'  
}
// ,canActivate: [RoleGuard] 
},
{ path: 'price_type_entry', component: PriceTypeEntryComponent,data : {  
  title: 'Price Type Entry'  
}
// ,canActivate: [RoleGuard] 
}
,
{ path: 'unit_list', component: UnitListComponent,data : {  
  title: 'Unit List'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
},

{ path: 'unit_entry', component: UnitEntryComponent,data : {  
  title: 'Unit Entry'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
},
 



{ path: 'edit_brand/:id', component: EditBrandComponent,data : {  
  title: 'Edit Brand'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
}
,



{ path: 'brand_entry', component: BrandEntryComponent,data : {  
  title: 'Brand Entry'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
},
{ path: 'sales_list', component: SalesListComponent,data : {  
  title: 'Sales List'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
},
{ path: 'sales_entry', component: SalesEntryComponent,data : {  
  title: 'Sales Entry'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
}
,
{ path: 'stock_list', component: StockListComponent,data : {  
  title: 'Stock List'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
}
,
{ path: 'stock_list_export', component: ExportStockComponent,data : {  
  title: 'Stock List Export'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
},
{ path: 'in_stock_summary', component: InstocksummaryComponent,data : {  
  title: 'In Stock Summary'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
}
,
{ path: 'group_create', component: ProductcreatebyitemComponent,data : {  
  title: 'group Create'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
},
{ path: 'group_create_list', component: ProductcreatebyitemlistComponent,data : {  
  title: 'Group Create List'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
},
{ path: 'group_wise_sale', component: GroupbysaleComponent,data : {  
  title: 'Group Wise Sale'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
},
{path:'invwithcol',component:ColumnhideinvoicelistComponent},
{ path: 'Year-Wise-Summary', component: YearwisesummaryComponent,data : {  
  title: 'Year-Wise-Summarye'  
}
// ,canActivate: [RoleGuard]  PurchaseEntryComponent
} ,
{path:'Edit_Brand/:id',component:EditBrandComponent}, 

{path:'Edit_PriceType/:id',component:EditPriceTypeComponent},

{path:'Edit_Unit/:id',component:EditUnitComponent},
 
{path:'SaleInvoice/:id',component:SaleinvoiceComponent},

{path:'Sale-Type-List',component:SaletypelistComponent},

{path:'Sale-Type-Registration',component:SaletyperegistrationComponent},

{path:'Sale-Type-Edit/:id',component:SaletyperegistrationComponent},

{path:'Sale-Report-Date-Wise',component:SalereportdatewiseComponent},

{path:'box-making',component:BoxMakingComponent},

{path:'box-list',component:BoxMakingListComponent},
{path:'box-stock-in',component:BoxStockInComponent},
{path:'box-stock-out',component:BoxStockOutComponent}, 
{path:'box-sale-list',component:BoxsalelistComponent},
 



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
