import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'app-editvendor',
  templateUrl: './editvendor.component.html',
  styleUrls: ['./editvendor.component.css']
})
export class EditvendorComponent implements OnInit {
  //Create FormGroup 


  organizaionlist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];

  cityList:Billdesk[];
  id:any;
  products:Billdesk[];
  selectedStateName:any;
    
  selectedStateID:any;
  selectedCityName:any;
  
  selectedCityID:any;

  type: string;
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef,private _Activatedroute:ActivatedRoute) {
   
    console.log('orgiststart');


    console.log(this.billdeskService.getOrganization().then(res => this.organizaionlist = res));
    console.log('stateliststart'); 

    console.log(this.billdeskService.getState().then(res => this.stateList = res));
    
    console.log('statelistend');
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  
  ngOnInit() {

    
// code for get detail by Id
this.id=this._Activatedroute.snapshot.paramMap.get("id");
this._Activatedroute.paramMap.subscribe(params => { 
  this.id = params.get('id');

  console.log(this.id);

  console.log(this.billdeskService.getVendorByid(this.id).then(res => this.products = res ));



});

// code for get detail by Id

    this.form = this.fb.group({ 
      
      VendorName: [null, Validators.required],
      GSTN:[null, Validators.required],
      CIN: [null, Validators.required],
      
      PANNo: [null, Validators.required],
      
      // ItemCategoryID: [null, Validators.required],
      Website:[null, Validators.required],
      SupportUrl: [null, Validators.required],
      
      SupportLoginID: [null, Validators.required],

      UserPassword: [null, Validators.required],
      UserLoginID: [null, Validators.required],
      SupportPassword: [null, Validators.required],
      VendorEmailID: [null, Validators.required],
      VendorMobileNo: [null, Validators.required],
      
      VendorBillingAddress: [null, Validators.required],
      VendorCity: [null, Validators.required],
      VendorState: [null, Validators.required],
      
      VendorPinCode: [null, Validators.required],
      // VendorUPIID: [null, Validators.required],
      BankAcNo: [null, Validators.required],
      BankHolderName: [null, Validators.required],
      BankName: [null, Validators.required],
      BankBranchName: [null, Validators.required],
      IFSCCode: [null, Validators.required],
      SwiftCode: [null, Validators.required],


      AuthPersonName: [null, Validators.required],
      AuthPersonEmailID: [null, Validators.required],
      AuthPersonMobileNo: [null, Validators.required],
      ActiveStatus: [null, Validators.required],
      // VerificationEmailUR: [null, Validators.required],
      // VerificationEmailOT: [null, Validators.required],
      // IsEmailVerified: [null, Validators.required],
      // VerificationMobileOTP: [null, Validators.required],
      // IsMobileVerified: [null, Validators.required]
      IsCustomer:'0'
       
      
    });




    this.form.valueChanges.subscribe((data) => {
      console.log("form change data", data.VendorState);//output 3,4 or 5
      })
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {

   console.log('e',e);

    // this.selectedStateName = e.value.StateName;
    // this.selectedStateID=e.value.StateID; 
    // this.Key.setValue(e.target.value, {
    //   onlySelf: true
    // })


    // let messageId = e.getAttribute('data-key');


    // this.type = this.elementRef.nativeElement.getAttribute('data-key');
    //let messageId = el.dataset.messageId;
//     console.log("Message Id: ", messageId);
// console.log(e); 
// console.log(e.target.dataset.Key);
 
// console.log(e.target.value);
console.log(this.billdeskService.getCity(e).then(res => this.cityList = res));
  }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
        "flag":"",
         "CreatedDate"  : "",
         "CreatedByID":"2",
     "CreatedBy" :"5",
     "RoleTypeID" :"4",
     "UpdatedDate"  : "",
     "UpdatedBy" :"",
     "ItemCategoryID":"",
     "IsMobileVerified":"",
     "VerificationMobileOTP":"",
     "IsEmailVerified":"",
     "VerificationEmailOT":"",
     "VerificationEmailUR":"",
     "VendorUPIID":"",
     "UserID":this.id
     
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);


if (this.form.valid) {
  console.log('form submitted');
  
 
let updateurl= "/vendor/vendorregistrationupdate";
this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{
  console.log("depin");
  console.log(res);
  console.log("depin");
     console.log(res.vendorregistrationupdateResult);
//   if(res.vendorregistrationupdateResult.Message=='Sucess'){
//    console.log('Insert Success');
//    this.route.navigate(['/Vendor-List'])
//     localStorage.setItem('updatedata', 'Success');
//  }else{
//    console.log('Please Try Again Later');
//  }



 
if (res.vendorregistrationupdateResult.Message == "Login ID Already Exist") {

  console.log('exit');
  // alert('Uploaded Successfully.');
  this.route.navigate(['/Vendor-List'])
  localStorage.setItem('exist', 'exist');

}
else {

  console.log('update');
  if(res.vendorregistrationupdateResult.Message=='Sucess'){ 
  // alert('Uploaded Successfully.');
  this.route.navigate(['/Vendor-List'])
  localStorage.setItem('updatedata', 'Success');

  }


}
 });
} else {
  this.validateAllFormFields(this.form);
}
 }




 
  
  changeCity(e){


    this.selectedCityName = e.value.DistrictName;
    this.selectedCityID=e.value.DistrictID;
   }
 

 }
 