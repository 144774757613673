 
    <form class="form-horizontal" [formGroup]="form" *ngFor="let data of products">

        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">EDIT DEPARTMENT </h1>
        <div class="card-body" style="background-color:#fff; ">
        
        
        
              
            <h1 class="card-title">
               Edit Department
            </h1>
         
        <div class="row">
                <div class="position-relative  form-group col-sm-6">
                    <label for="DeptName" class="col-form-label">Department Name <span>*</span></label>
                    <div   [ngClass]="displayFieldCss('DeptName')">
                     
                     
                        <input name="DeptName" id="DeptName" placeholder="" type="text" class="form-control" formControlName="DeptName" [(ngModel)]="data.DeptName">
                    
                        <app-field-error-display [displayError]="isFieldValid('DeptName')" errorMsg="Please Enter Department"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <!-- <div class="position-relative  form-group col-sm-6">
                    <label for="OrgID" class="col-form-label">Organization Name<span>*</span></label>
                    <div class="">
               
                        <p-dropdown [options]="organizaionlist" [(ngModel)]="selectedOrgId" optionLabel="OrgName" [filter]="true" filterBy="OrgName"
                        [showClear]="true" [placeholder]="selectedOrgName"  (onChange)="getDivisionByOrg($event)" name="OrgID" id="OrgID"      formControlName="OrgID"  [style]="{ width: '100%' }" >
                     
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.OrgName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    
                        <app-field-error-display [displayError]="isFieldValid('OrgID')" errorMsg="Please Select Organization Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
        


                <div class="position-relative  form-group col-sm-6">
                    <label for="DivID" class="col-form-label">Division Name<span>*</span></label>
                    <div class="">
                      
         
                <p-dropdown [options]="divisionlist"  [(ngModel)]="selectedDiv" optionLabel="DivName" [filter]="true" filterBy="DivName"
                        [showClear]="true" [placeholder]="selectedDivName"  (onChange)="changeDivision($event)" name="DivID" id="DivID"     formControlName="DivID"  [style]="{ width: '100%' }" >
                     
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.DivName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>  
                        <app-field-error-display [displayError]="isFieldValid('DivID')" errorMsg="Please Select Division Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div> -->
                 
                <div class="position-relative  form-group col-sm-6">
                    <label for="OrgID" class="col-form-label">Organization Name  <span>*</span></label>
                    <div class="">
                             <!-- <select  class="form-control" name="OrgID" id="OrgID"     formControlName="OrgID" (ngModelChange)="getDivisionByOrg($event)"  [ngModel]="data.OrgID"> 
                            
                                <option [ngValue]="null" disabled selected >Please Select Organizaion</option>
                            
                                <option *ngFor="let organizaionlists of organizaionlist"      [value]="organizaionlists.OrgID" >{{organizaionlists.OrgName}}</option>
                            
    
                            </select> -->


                            <div *ngIf="organizaionlist">


                                <p-dropdown [options]="organizaionlist" [(ngModel)]="data.OrgID"
                                    [autoDisplayFirst]="false" optionLabel="OrgName" formControlName="OrgID"
                                    [placeholder]="data.OrgName" optionValue="OrgID" [filter]="true"
                                    filterBy="OrgName" (ngModelChange)="getDivisionByOrg($event)"  >
                                </p-dropdown>
        
                            </div>

                        <app-field-error-display [displayError]="isFieldValid('OrgID')" errorMsg="Please Select Organizaion"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivID" class="col-form-label">Division<span>*</span></label>
                    <div class="">
                    
        
                        <!-- <select  class="form-control" name="DivID" id="DivID"    formControlName="DivID"  [ngModel]="data.DivID">
                            <option [ngValue]="null" disabled selected >Please Select Division</option>
                            <option *ngFor="let divisionlists of divisionlist"       [value]="divisionlists.DivID"       >{{divisionlists.DivName}}</option>
                          
                        </select> -->
                    
                    
                        <div *ngIf="divisionlist">


                            <p-dropdown [options]="divisionlist" [(ngModel)]="data.DivID"
                                [autoDisplayFirst]="false" optionLabel="DivName" formControlName="DivID"
                                [placeholder]="data.DivName" optionValue="DivID" [filter]="true"
                                filterBy="DivName" (ngModelChange)="getDivisionByOrg($event)"  >
                            </p-dropdown>
    
                        </div>


                        <app-field-error-display [displayError]="isFieldValid('DivID')" errorMsg="Please Select Division"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="HODName" class="col-form-label">HOD Name<span>*</span></label>
                    <div class="">
                        <input type="text" name="HODName" id="HODName" class="form-control" formControlName="HODName" [(ngModel)]="data.HODName">
        
                        <app-field-error-display [displayError]="isFieldValid('HODName')" errorMsg="Please Enter Name Of HOD"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            </div>
        
           
        
        </div>
        
            <div class="card-body" style="background-color:#fff; ">
                <h1 class="card-title">
                    Contact Detail
                </h1>
            <div class="row">
                <div class="position-relative form-group col-sm-6">
                    <label for="HODMobileNo" class="col-form-label">HOD Contact Number<span>*</span></label>
                    <div class="">
                        <input name="HODMobileNo" id="HODMobileNo" placeholder="" type="number" class="form-control" formControlName="HODMobileNo"  [(ngModel)]="data.HODMobileNo">
                        <app-field-error-display [displayError]="isFieldValid('HODMobileNo')" errorMsg="Please Enter Contact Number"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="HODEmailID" class="col-form-label">HOD Email<span>*</span></label>
                    <div class="">
                        <input type="email" name="HODEmailID" id="HODEmailID" class="form-control" formControlName="HODEmailID" [(ngModel)]="data.HODEmailID">
         
                        <app-field-error-display [displayError]="isFieldValid('HODEmailID')" errorMsg="Please Enter Email Id"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                
        
                
                 
                <div class="position-relative  form-group col-sm-6">
                    <label for="ActiveStatus" class="col-form-label">Active Status<span>*</span></label>
                    <div class="">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
 
                        <select  class="form-control" name="ActiveStatus" id="ActiveStatus"  formControlName="ActiveStatus"  [(ngModel)]="data.ActiveStatus">
                      
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    
                    
                        <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Select Status"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
                
                <div class="position-relative  form-check col-sm-6 offset-sm-6 ">
                    <div class="">
    
                        <br>
                        <br>
                        <br>
                        <button class="btn btn-primary btn-lg" (click)="onSubmit()">Submit</button>
                    </div>
                </div>  
        
                
                </div>
                </div>
        
                   
         