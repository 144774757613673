 
    <form class="form-horizontal" [formGroup]="form">

        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">DIVISION REGISTRATION</h1>
        <div class="card-body" style="background-color:#fff; ">
        
        
        
              
            <h1 class="card-title">
                Division Detail
            </h1>
         
        <div class="row">
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivName" class="col-form-label">Division Name <span>*</span></label>
                    <div   [ngClass]="displayFieldCss('DivName')">
                     
                     
                        <input name="DivName" id="DivName" placeholder="Please Enter Division  Name" type="text" class="form-control" formControlName="DivName">
                    
                        <app-field-error-display [displayError]="isFieldValid('DivName')" errorMsg="Please Enter Division  Name"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="OrgID" class="col-form-label">Organization Name<span>*</span></label>
                    <div class="">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <select  class="form-control" name="OrgID" id="OrgID"   (change)="changeOrganization($event)"  formControlName="OrgID"> 
                            
                             
                            <option [ngValue]="null" disabled selected >Choose Your Organizaion</option>
                            
                            <!-- <option value="" disabled selected ></option> -->
                            
                            <!-- <option value="88" >Test</option> -->
                            <option *ngFor="let list of organizaionlist" [ngValue]="list.OrgID">{{list.OrgName}}</option>
                        
                        </select>
                    
                    
                        <app-field-error-display [displayError]="isFieldValid('OrgID')" errorMsg="Please Select Organization Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivPANNo" class="col-form-label">PAN No<span>*</span></label>
                    <div class="">
                        <input type="text" placeholder="Please Enter Pancard Number" name="DivPANNo" id="DivPANNo" class="form-control" formControlName="DivPANNo">
                        <app-field-error-display [displayError]="isFieldValid('DivPANNo')" errorMsg="Please Enter DivPANNo Number"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
        
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivGSTN" class="col-form-label">GSTN<span>*</span></label>
                    <div class="">
                        <input type="text"  placeholder="Please Enter GSTN Number" name="DivGSTN" id="DivGSTN" class="form-control" formControlName="DivGSTN">
        
                        <app-field-error-display [displayError]="isFieldValid('DivGSTN')" errorMsg="Please Enter DivGSTN"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            </div>
        
           
        
        </div>
        
            <div class="card-body" style="background-color:#fff; ">
                <h1 class="card-title">
                    Contact Detail
                </h1>
            <div class="row">
                <div class="position-relative form-group col-sm-6">
                    <label for="DivDeskMobileNo" class="col-form-label">Contact Number<span>*</span></label>
                    <div class="">
                        <input name="DivDeskMobileNo"  id="DivDeskMobileNo" placeholder="Please Enter Contact Number" type="number" class="form-control" formControlName="DivDeskMobileNo">
                        <app-field-error-display [displayError]="isFieldValid('DivDeskMobileNo')" errorMsg="Please Enter Contact Number"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivDeskEmailID" class="col-form-label">Email<span>*</span></label>
                    <div class="">
                        <input type="email" name="DivDeskEmailID" id="DivDeskEmailID" placeholder="Please Enter Valid  Email"  class="form-control" formControlName="DivDeskEmailID">
         
                        <app-field-error-display [displayError]="isFieldValid('DivDeskEmailID')" errorMsg="Please Enter Email Id"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                
        
                
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivBillingAddress" class="col-form-label">Address <span>*</span></label>
                    <div class="">
                        <textarea name="DivBillingAddress" id="DivBillingAddress" class="form-control" formControlName="DivBillingAddress"></textarea>
         
         
                        <app-field-error-display [displayError]="isFieldValid('DivBillingAddress')" errorMsg="Please Enter DivBillingAddress"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>


                <div class="position-relative  form-group col-sm-6">
                    <label for="DivState" class="col-form-label">State <span>*</span></label>
                    <div class="">
                             <select  class="form-control" name="DivState" id="DivState"  (change)="changeState($event.target.value)"   formControlName="DivState"> 
                            
                                <option [ngValue]="null" disabled selected >Please Select State</option>
                            
                                <option *ngFor="let statelist of stateList"      [value]="statelist.StateID"    >{{statelist.StateName}}</option>
                            
    
                            </select>



                        <app-field-error-display [displayError]="isFieldValid('DivState')" errorMsg="Please Select State"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivCity" class="col-form-label">City<span>*</span></label>
                    <div class="">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <select  class="form-control" name="DivCity" id="DivCity"    formControlName="DivCity">
                            <option [ngValue]="null" disabled selected >Please Select City</option>
                            <option *ngFor="let citylist of cityList"       [value]="citylist.DistrictID"       >{{citylist.DistrictName}}</option>
                          
                        </select>
                    
                    
                        <app-field-error-display [displayError]="isFieldValid('DivCity')" errorMsg="Please Select DivCity"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
                
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivPinCode" class="col-form-label">PinCode</label>
                    <div class="">
                        <input type="text" name="DivPinCode"   placeholder="Please Enter  PinCode"  id="DivPinCode" class="form-control" formControlName="DivPinCode">
        
                        <app-field-error-display [displayError]="isFieldValid('DivPinCode')" errorMsg="Please Enter DivPinCode"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
              
                 
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivAuthPersonName" class="col-form-label">Contact Person Name<span>*</span></label>
                    <div class="">
                        <input type="text" name="DivAuthPersonName" placeholder="Please Enter  Contact Person Name"  id="DivAuthPersonName" class="form-control"  formControlName="DivAuthPersonName">
        
                        <app-field-error-display [displayError]="isFieldValid('DivAuthPersonName')" errorMsg="Please Enter  DivAuthPersonName"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivAuthPersonMobileNo" class="col-form-label">Contact Person No<span>*</span></label>
                    <div class="">
                        <input type="number" name="DivAuthPersonMobileNo"  placeholder="Please Enter  Contact Person No." id="DivAuthPersonMobileNo" class="form-control"  formControlName="DivAuthPersonMobileNo">
                        <app-field-error-display [displayError]="isFieldValid('DivAuthPersonMobileNo')" errorMsg="Please Enter  DivAuthPersonMobileNo"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            
        
                
                </div>
                </div>
        
                   
        
                <div class="card-body" style="background-color:#fff; ">
                    <h1 class="card-title">
                        Bank Account Detail
                    </h1>
                <div class="row">
                     
                    <div class="position-relative  form-group col-sm-6">
                        <label for="DivBankAcName" class="col-form-label">Account Name </label>
                        <div class="">
                            <input type="text" name="DivBankAcName" id="DivBankAcName"   placeholder="Please Enter Bank A/c Name"  class="form-control"  formControlName="DivBankAcName"> 
                            <app-field-error-display [displayError]="isFieldValid('DivBankAcName')" errorMsg="Please Enter  Account Name"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="DivBankAcNo" class="col-form-label">Bank Account No </label>
                        <div class="">
                            <input type="number" name="DivBankAcNo" id="DivBankAcNo" class="form-control"   placeholder="Please Enter Bank A/c No." formControlName="DivBankAcNo">
                            <app-field-error-display [displayError]="isFieldValid('DivBankAcNo')" errorMsg="Please Enter  Bank Account"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
        
        
                    <div class="position-relative  form-group col-sm-6">
                        <label for="DivBankName" class="col-form-label">Bank Name</label>
                        <div class="">
                            <input type="text" name="DivBankName" id="DivBankName" class="form-control"   placeholder="Please Enter Bank Name" formControlName="DivBankName">
        
                            
                            <app-field-error-display [displayError]="isFieldValid('DivBankName')" errorMsg="Please Enter  Bank Name"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
        
                    <div class="position-relative  form-group col-sm-6">
                        <label for="DivBankBranchName" class="col-form-label">Bank Branch</label>
                        <div class="">
                            <input type="text" name="DivBankBranchName" id="DivBankBranchName"  placeholder="Please Enter Bank Branch" class="form-control" formControlName="DivBankBranchName">
                            <app-field-error-display [displayError]="isFieldValid('DivBankBranchName')" errorMsg="Please Enter  Bank Branch"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="DivIFSCCode" class="col-form-label">Bank IFSC Code </label>
                        <div class="">
                            <input type="text" name="DivIFSCCode" id="DivIFSCCode"   placeholder="Please Enter Bank IFSC Code" class="form-control" formControlName="DivIFSCCode">
        
        
                             <app-field-error-display [displayError]="isFieldValid('DivIFSCCode')" errorMsg="Please Enter  Bank IFSC Code"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                   
                    <div class="position-relative  form-group col-sm-6">
                        <label for="IFSCCode" class="col-form-label">Active Status <span>*</span></label>
                        <div class="">
                            <!-- <input type="text" name="IFSCCode" id="IFSCCode" class="form-control" formControlName="IFSCCode"> -->
        
                <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus">
                    <option [ngValue]="null" disabled selected >Please  Select Status</option>
                    <option [ngValue]="1">Active</option><option [ngValue]="0">InActive</option></select>
                             <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>  
                     
            
                    <div class="position-relative  form-check col-sm-6 offset-sm-6 ">
                        <div class="">
        
                            <br>
                            <br>
                            <br>
                            <button class="btn btn-primary btn-lg" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>  
            
                    </div>
           
          
                    
        