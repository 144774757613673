
<style>
    .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #47c9a2 !important;
    color: #f9f9f9;
    padding: 1.0rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
/* .col1{
        position: absolute;
        width: 10em;
        left: 0em;
        top: auto;
    }
    .col2{
        position: absolute;
        width: 5em;
        left: 10em;
        top: auto;
    }
 */

    /* table tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
} */


.p-datatable .p-datatable-tbody > tr > td:first-child{
    position: sticky;
  left: 0;
  z-index: 2;
}
.p-dialog header {

    background: #c947c5 !important;
}


</style>

<p-toast></p-toast>



<!-- <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button> -->

<!-- {{selectedItemsList | json}} -->
<div class="card" style=" overflow-x: scroll;">
  

<p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="['OrgID','DivName',
    'DivGSTN',
    'DivState',
    'DivCity',
    'PriorityName',
    'DivDeskMobileNo',
    'DivDeskEmailID',
    'DivAuthPersonName',
    'DivAuthPersonMobileNo']"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="InvoiceID"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"  responsiveLayout="scroll"  [showCurrentPageReport]="true"  [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]">

        
        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 style="text-align: center;color:rebeccapurple;font-weight: bold;">INVOICE LIST FOR PO BATCH CREATION</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..."   />
                </span>
            </div>

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
  
        </ng-template>
   
     
        <ng-template pTemplate="header">
            <tr>
            
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="StageName"  style="width: 150px" >Invoice Stage <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
                <th pSortableColumn="RespMessage"  style="width: 150px" >Payment Status <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
                <th pSortableColumn="InvoiceID " style="width: 150px">Bill Id.<p-sortIcon field="InvoiceID"></p-sortIcon></th>
                <th pSortableColumn="PriorityName " style="width: 150px">Priority Name<p-sortIcon field="PriorityName"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Organization Name<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="VendorInvoiceNo " style="width: 150px">Invoice No.<p-sortIcon field="VendorInvoiceNo"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Invoice Date<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">ValidityExpireOn<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskMobileNo" style="width: 150px">	Item Name <p-sortIcon field="DivDeskMobileNo"></p-sortIcon></th>
                <th pSortableColumn="InvoiceName " style="width: 150px">Invoice Name<p-sortIcon field="InvoiceName"></p-sortIcon></th>
                <th pSortableColumn="DivState" style="width: 150px">Vendor Name <p-sortIcon field="DivState"></p-sortIcon></th>
               
                <th pSortableColumn="DivName" style="width: 150px">Invoice Document<p-sortIcon field="DivName"></p-sortIcon></th>
                <th pSortableColumn="Headname" style="width: 150px">Head Name<p-sortIcon field="Headname"></p-sortIcon></th>
                 
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Invoice For Cycle<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Net Payable Amount<p-sortIcon field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="itemcategoryname" style="width: 150px">Item Category Name<p-sortIcon field="itemcategoryname"></p-sortIcon></th>
                 <th pSortableColumn="DivGSTN" style="width: 150px"> Division Name <p-sortIcon field="DivGSTN"></p-sortIcon></th>
                <th pSortableColumn="DivGSTN" style="width: 150px"> Department Name <p-sortIcon field="DivGSTN"></p-sortIcon></th>
               
                    
            </tr>


            <tr>
                <th>

                </th>
               
        
        <th>
             
            <input pInputText type="text" (input)="dt.filter($event.target.value,'StageName' ,'contains')" placeholder="Search Status" />
            </th>  

                <th>
             
            <input pInputText type="text" (input)="dt.filter($event.target.value,'RespMessage' ,'contains')" placeholder="Search Payment Status" />
            </th>  

            
        <th>
            
            <input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceID' ,'contains')" placeholder="Search Bill Id" />
            </th>    
            
            
            <th>
<!--     
                <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorInvoiceNo' ,'contains')" placeholder="Search Invoice No" /> -->


                <p-dropdown [options]="pririty" (onChange)="dt.filter($event.value, 'PriorityName', 'contains')"
                styleClass="p-column-filter" placeholder="Select a Priority" [showClear]="true" style="width: 100px;">
                <ng-template let-option pTemplate="item">
                    <span
                        [class]="'customer-badge status-' + option.value">&#123;&#123;option.value&#125;&#125;</span>
                </ng-template>
            </p-dropdown>
                </th>
        <th>
            

            <input pInputText type="text" (input)="dt.filter($event.target.value,'OrgName' ,'contains')" placeholder="Search Org. Name" />
                
        
        </th>


        <th>
    
                <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorInvoiceNo' ,'contains')" placeholder="Search Invoice No" />

            </th>


<th>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceDate' ,'contains')" placeholder="Search Date" />
</th>
<th>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'ValidityExpireOn' ,'contains')" placeholder="Search Validity" />
</th>

 
<th>    
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'ItemName' ,'contains')" placeholder="Search Item Name" />
 </th>
 
      
<th>
    
    <input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceName' ,'contains')" placeholder="Search Invoice Name" />
    </th>
    <th>
    
        <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorName' ,'contains')" placeholder="Search Vendor Name" />
        </th>

        <th>    <input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceHeadItemName' ,'contains')" placeholder="No Search"  disabled/> </th>
        <th>
    
            <input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceHeadItemName' ,'contains')" placeholder="Search Head Name" />
            </th>
            <th>
    
                <input pInputText type="text" (input)="dt.filter($event.target.value,'invoiceForName' ,'contains')" placeholder="Search invoice For Name" />
                </th>
               

                <th>
    
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'NetPayableAmount' ,'contains')" placeholder="Search Net Payable Amount" />
                    </th>
               
                    
                    <th>
    
                        <input pInputText type="text" (input)="dt.filter($event.target.value,'itemcategoryname' ,'contains')" placeholder="Search item category name" />
                        </th>
                     


                            <th>
    
                                <input pInputText type="text" (input)="dt.filter($event.target.value,'DivName' ,'contains')" placeholder="Search Div. Name" />
                                </th>


                                <th>
    
                                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DeptName' ,'contains')" placeholder="Search Dept. Name" />
                                    </th>
                       
                     
                                                                

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">
                <td>

                    <span class="p-column-title">Select For Create Batch:</span>
                    <p-tableCheckbox [value]="product" id="{{product.InvoiceID}}"></p-tableCheckbox>
                </td>
                
                   <td class="col2">  <span class="p-column-title">Stage Name:</span> {{product.StageName}}</td>
                   <td class="col2"> 

                    <span class="p-column-title">Payment Status:</span>
                    <div *ngIf="product.RespMessage=='success'; else elseBlock" style="color:green">Paid</div>
                    <ng-template #elseBlock ><span   style="color: #ec5729;">Unpaid</span></ng-template> 
                </td>
                   <td> <span class="p-column-title">Bill Id:</span>{{product.InvoiceID}}</td>
                   
                   <td> <span class="p-column-title">Priority Name:</span>{{product.PriorityName}}</td>
                   <td> <span class="p-column-title">Organization Name:</span>{{product.OrgName }} </td>
                   <td> <span class="p-column-title">Invoice No.:</span>{{product.VendorInvoiceNo}}</td> 
                   <td> <span class="p-column-title">Invoice Date:</span>{{product.InvoiceDate}} </td> 
                   <td> <span class="p-column-title">ValidityExpireOn:</span>{{product.ValidityExpireOn}} </td>
                   <td> <span class="p-column-title">Item Name:</span>{{product.ItemName}}</td>
                   <td> <span class="p-column-title">Invoice Name:</span>{{product.InvoiceName}}</td> 
                   <td> <span class="p-column-title">Vendor Name:</span>{{product.VendorName}} </td>
                   <td> <span class="p-column-title">Invoice Document:</span><a href="http://services.aisect.org/{{product.InvoiceAttechmentURL}}" target="_blank" ><i class="fa fa-file-pdf-o" style="font-size:48px;color:red" title="Click For Open Document"></i></a></td>

                    <td> <span class="p-column-title">Head Name:</span>{{product.InvoiceHeadItemName}}</td>
                    <td> <span class="p-column-title">Invoice For Cycle:</span>{{product.InvoiceForName}}</td>
                    <td> <span class="p-column-title">Net Payable Amount:</span>{{product.NetPayableAmount}} </td>
                    <td> <span class="p-column-title">Item Category Name:</span>{{product.ItemCategoryName}} </td>

                <td> <span class="p-column-title">Division Name :</span>{{product.DivName}} </td>
                <td> <span class="p-column-title">Department Name:</span>{{product.DeptName}} </td>
                
               

            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>

    <!-- <a   style="margin-left:80%;" routerLink="/Po-Creation-Next-Step"   class="btn btn-info btn-lg" (click)="createPobox()"> Proceed  </a> -->
 <a   style="margin-left:25%;margin-right:25%;"   class="btn btn-info btn-lg" (click)="createPobox()"> Click Here For Create PO Batch  </a>
 
</div>

 
 