import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';
@Component({
  selector: 'app-domain-registration',
  templateUrl: './domain-registration.component.html',
  styleUrls: ['./domain-registration.component.css']
})
export class DomainRegistrationComponent implements OnInit {
  //Create FormGroup 


  // organizaionlist: Billdesk[];
  categorylist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];

  cityList:Billdesk[];

  boxvalue:string;
  pHindi:string='';
  type: string;
  showHindi:boolean=false;
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef) {
 
 this.billdeskService.getItemCategorylist().then(res => this.categorylist = res);
  this.billdeskService.getState().then(res => this.stateList = res); 
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  
  ngOnInit() {
    this.form = this.fb.group({ 
      ItemName:[null, Validators.required], 
      ItemCategoryID:[null, Validators.required],
     ActiveStatus:[null, Validators.required],
     });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {
  this.billdeskService.getCity(20).then(res => this.cityList = res);
  }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => { 
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }
  valueChanged(e) {
    // console.log('e', e);
    var itemnameeng=this.form.get('ItemName').value;
    // setTimeout(() => 
    //   {
    //     this.form.controls['ItemName'].setValue(itemnameeng+'+'+e);
    //   },
    //   5000);
   

}


  onSubmit() {
    var obj1 = 
   {
       
    "flag":"MSTItemCatagory_INSERT",
    "ItemID":"0",
    "CreatedBy":"2",
    "CreatedByID":"4",
    "UpdatedDate":"10/22/21",
    "UpdatedBy":"", 
    "ItemType":"",
"UnderOfParentID":"",
"ItemAbstract":"",
"ItemDescription":"",
"FAQListID":"",
"ItemImageUrl":"",
"ItemImageUrl2":"",
"ItemImageUrl3":"",
"ItemImageUrl4":"",
"ItemVideoSource":"",
"ItemVideoUrl":"",
"ItemVisibilityMode":"",
"ActiveStatus":"",
"MetaTitle":"", 
"MetaKeyWord":"",
"MetaDiscription":"",
"SchemaCodes":"",
"OGTags":"",
"UrlOptimization":"",
"FilterValue1":"",
"FilterValue2":"",
"WholeSalePriceMax":"",
"WholeSalePriceMin":"",
"ItemUnitPurchasePrice":"",
"ItemUnitSalesPrice":"",
"MSP":"",
"MRP":"",
"ItemDiscountInPercentage":"",
"ItemDiscountInAmount":"",
"Tax1Amount":"",
"Tax2Amount":"",
"GST":"",
"NetPayAmount":"",
"CoopenCode":"",
"GSTInPercentage":"",
"OpeningStock":"",
"AsofDate":"",
"IsLowStockWarning":"",
"LowStockQuantity":"",
"ItemCode":"",
"Barcode":"",
"ItemBrandName":"",
"ItemMedium":"",
"ItemSize":"",
"ItemWorkType":"",
"ItemBase":"",
"ItemColor":"",
"ItemMaterial":"",
"ItemEntryDate":"",
"ItemAddedBy":"",
"ItemToTopTen":"",
"ItemToHome":"",
"DisplayOrder":"",
"Comments":"",
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
 
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
 

if (this.form.valid) { 
let registerurl= "/item/itemregistration";
this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
 
  if(res.itemregistrationResult.Message=='Sucess'){ 
   this.route.navigate(['/Item-List'])
   localStorage.setItem('insertdata', 'Success');
 }else{
  //  console.log('Please Try Again Later');
 }
 });

 } else {
  this.validateAllFormFields(this.form);
}
 }


 enableHindi(e) {
    if(e.target.checked){  
      console.log('checked')    
      this.showHindi=true;  
    }else{
    console.log('uncheck')
      
    this.showHindi=false;  
    } 
  }

}
 