 
<!-- <p-progressSpinner strokeWidth="5" fill="#fff" 
animationDuration="1s" style="position: fixed;left: 0px;top: 0px;width: 100%;height: 100%;margin-left: 43%;margin-top: 20%; z-index: 9999;"  *ngIf="visibleloader" >  </p-progressSpinner>
 --><style>
    @media print {
        /* @page {
            size: A4 landscape;      overflow: scroll;
        } */

    .no-print, .p-sortable-column-icon , .no-print *
    {
        display: none !important;
    }
    }
 

    /* #print-section {
    overflow-y: visible !important;
    position: relative;
  } */
  #content{
  zoom: 80%;
}


/* .h2, h2 {
    font-size: 1.5rem !important;
} */
</style>


<div *ngIf="visibleloader"  style="position: fixed;left: 0px;top: 0px;width: 100%;height: 100%;margin-left: 37%;margin-top: 10%; z-index: 9999;" > <img src="../../assets/images/loading.gif" ></div>
   <form class="form-horizontal" [formGroup]="form" (ngSubmit)="submit()">


        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">INVOICE SUBMISSION</h1>


        <div class="card-body"  style="background-color:#fff;margin-top: 0px; ">
        
        
        
              
            <h1 class="card-title" >
                Vendor  Details 
            </h1>

            <div class="row " >
            <div class="position-relative  form-group col-sm-6">
                <label for="VendorID" class="col-form-label">Vendor Name<span>*</span></label>
                <div class="">

                 <p-dropdown  *ngIf="!VendorShow" [options]="vendorlist"  [(ngModel)]="selectedVendorId" optionLabel="VendorName" [filter]="true" filterBy="VendorName"
                    [showClear]="true" [placeholder]="selectedVendorName"  (onChange)="changeVendor($event)" name="VendorID" id="VendorID"     formControlName="VendorID"  [style]="{ width: '100%' }" >
                 
                    <ng-template let-country pTemplate="item">
                        <div class="country-item">
                            <div>{{country.VendorName}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>  
                

                
   
                <input   *ngIf="VendorShow" name="VendorID"  id="VendorID"  readonly  type="text" class="form-control" formControlName="VendorID"  (ngModelChange)="changeVendorstatic($event)"  [ngModel]="VendorDetailName">
                      

                    <app-field-error-display [displayError]="isFieldValid('VendorID')" errorMsg="Please Select Vendor Name"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                
                </div>
            </div>

            </div>
        <div class="row "  *ngIf="vendordetail">
            
                <div class="position-relative  form-group col-sm-6">

                    <div class="row">

                    <label style="font-weight:500;"  class="position-relative  form-group col-sm-6">Bank Name </label>
                    <div  class="position-relative  form-group col-sm-6">

                        {{vendordetail[0].BankName}}
                      
                    </div>

                </div>
                </div>
              
                <div class="position-relative  form-group col-sm-6">
                    <div class="row">
                    <label style="font-weight:500;"  class="position-relative  form-group col-sm-6">Bank Branch </label>
                    <div class="position-relative  form-group col-sm-6">
                      
                        
                        {{vendordetail[0].BankBranchName}}
                    </div>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">


                    <div class="row">
                    <label  style="font-weight:500;"   class="position-relative  form-group col-sm-6" >Bank A/C Number </label>
                    <div class="position-relative  form-group col-sm-6">
                       
                        
                        {{vendordetail[0].BankAcNo}}
                    </div>
                    </div>
                </div>



                <div class="position-relative  form-group col-sm-6">


                    <div class="row">
                    <label  style="font-weight:500;"   class="position-relative  form-group col-sm-6">Account Holder Name </label>
                    <div class="position-relative  form-group col-sm-6">
                       
                        
                        {{vendordetail[0].BankHolderName}}
                    </div>

                    </div>
                </div>


                
                <div class="position-relative  form-group col-sm-6">


                    <div class="row">
                    <label style="font-weight:500;"  class="position-relative  form-group col-sm-6">IFSC Code </label>
                    <div class="position-relative  form-group col-sm-6">
                       
                        
                        {{vendordetail[0].IFSCCode}}
                    </div>
                    </div>
                </div>


                <div class="position-relative  form-group col-sm-6">

                    <div class="row">
                    <label  style="font-weight:500;"   class="position-relative  form-group col-sm-6" >Swift Code </label>
                    <div class="position-relative  form-group col-sm-6" >
                       
                        

                        {{vendordetail[0].SwiftCode}}
                    </div>
                    </div>
                </div>

                
        

                
             
            </div>
        
           
        
        </div>   
        <div class="card-body" style="background-color:#fff; ">
        
        
        
              
            <h1 class="card-title" >
                Organization Details
            </h1>
         
        <div class="row">
              
   
               
                <div class="position-relative  form-group col-sm-6">
                    <label for="City" class="col-form-label">Organization<span>*</span></label>
                    <div class="">
                      
                       <p-dropdown [options]="organizaionlist" [(ngModel)]="selectedOrgId" optionLabel="OrgName" [filter]="true" filterBy="OrgName"
                            [showClear]="true" [placeholder]="selectedOrgName"  (onChange)="getDivisionByOrg($event)" name="OrgID" id="OrgID"      formControlName="OrgID"  [style]="{ width: '100%' }" >
                         
                            <ng-template let-country pTemplate="item">
                                <div class="country-item">
                                    <div>{{country.OrgName}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                       
                        <app-field-error-display [displayError]="isFieldValid('OrgID')" errorMsg="Please Select Organization Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="DivID" class="col-form-label">Division Name<span>*</span></label>
                    <div class="">
                       
                        <!-- <select  class="form-control" name="DivID" id="DivID"   (change)="changeOrganization($event)"  formControlName="DivID"> 
                            
                             
                            <option [ngValue]="null" disabled selected >Select Division</option>
                            
                             <option *ngFor="let divlist of divisionlist" [ngValue]="divlist.DivID">{{divlist.DivName}}</option>
                        
                        </select> -->
                    <p-dropdown [options]="divisionlist"  [(ngModel)]="selectedDiv" optionLabel="DivName" [filter]="true" filterBy="DivName"
                        [showClear]="true" [placeholder]="selectedDivName"  (onChange)="changeDivision($event)" name="DivID" id="DivID"     formControlName="DivID"  [style]="{ width: '100%' }" >
                     
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.DivName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    
                        <app-field-error-display [displayError]="isFieldValid('DivID')" errorMsg="Please Select Division Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>



                <div class="position-relative  form-group col-sm-6">
                    <label for="DeptID" class="col-form-label">Department Name<span >* (Note: User Registration is complusary For Each Department.)</span></label>
                    <div class="">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <!-- <select  class="form-control" name="DeptID" id="DeptID"   (change)="changeOrganization($event)"  formControlName="DeptID"> 
                            
                             
                            <option [ngValue]="null" disabled selected >Select Department</option>
 
                            <option *ngFor="let diplist of departmentlist" [ngValue]="diplist.DeptID">{{diplist.DeptName}}</option>
                        
                        </select> -->
                        <p-dropdown [options]="departmentlist"  [(ngModel)]="selectedDeptID" optionLabel="DeptName" [filter]="true" filterBy="DeptName"
                        [showClear]="true" [placeholder]="selectedDeptName"  (onChange)="changeDepartment($event)" name="DeptID" id="DeptID"     formControlName="DeptID"  [style]="{ width: '100%' }" >
                     filter
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.DeptName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    
                        <app-field-error-display [displayError]="isFieldValid('DeptID')" errorMsg="Please Select Deparment Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                        <br>
                        <!-- <h2 style="color: #791820;"> Note: User Registration is complusary For Each Department </h2>     -->
                    </div>
                </div>

                
        

                
             
            </div>
        
           
        
        </div>
        
            <div class="card-body" style="background-color:#fff; ">
                <h1 class="card-title">
                    Invoice Details 
                </h1>
            <div class="row">

                
                <div class="position-relative  form-group col-sm-6">
                    <label for="ItemCategoryID" class="col-form-label">Product Category<span>*</span></label>
                    <div class="" style="display:flex;">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <!-- <select  class="form-control" name="ItemCategoryID" id="ItemCategoryID"   (change)="changeOrganization($event)"  formControlName="ItemCategoryID"> 
                            
                             
                            <option [ngValue]="null" disabled selected >Select Category</option>
                            
                              <option *ngFor="let itemCat of itemcategorylist" [ngValue]="itemCat.ItemCategoryID">{{itemCat.ItemCategoryName}}</option>
                        
                        </select> -->

                      
                   <p-dropdown [options]="itemcategorylist" [(ngModel)]="selectedItemCategoryID" optionLabel="ItemCategoryName" [filter]="true" filterBy="ItemCategoryName"
                        [showClear]="true" [placeholder]="selectedItemCategoryName"  style="width:100% !important;" (onChange)="getItemByCategory($event)" name="ItemCategoryID" id="ItemCategoryID"    formControlName="ItemCategoryID"   >
                     
                        <ng-template let-country pTemplate="item" style="width:100%;">
                            <div class="country-item"  >
                                <div>{{country.ItemCategoryName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <button type="button" class="btn btn-primary" (click)="ProductCategoryDialogButton()">
                        <i class="fa fa-plus"></i>
                    </button>
                  </div>
                    <app-field-error-display [displayError]="isFieldValid('ItemCategoryID')" errorMsg="Please Select Item Category ID"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div> 


                <div class="position-relative  form-group col-sm-6">
                    <label for="ItemID" class="col-form-label">Product<span>*</span></label>
                    <div class="" style="display:flex;">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <!-- <select  class="form-control" name="ItemID" id="ItemID"   (change)="changeOrganization($event)"  formControlName="ItemID"> 
                            
                             
                            <option [ngValue]="null" disabled selected >Select Item</option>
                            
                             <option *ngFor="let item of itemlist" [ngValue]="item.ItemID">{{item.ItemName}}</option>
                        
                        </select> -->
                        <p-dropdown [options]="itemlist"  [(ngModel)]="selectedItemId" optionLabel="ItemName" [filter]="true" filterBy="ItemName"
                        [showClear]="true" [placeholder]="selectedItemName"  (onChange)="changeItemStage($event)" name="ItemID" id="ItemID"    formControlName="ItemID"     style="width:100% !important;">
                     

                        
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.ItemName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>


                    <button type="button" class="btn btn-primary" (click)="ProductDialogButton()">
                        <i class="fa fa-plus"></i>
                      </button>
                    
          
                    
                    </div>


                    <app-field-error-display [displayError]="isFieldValid('ItemID')" errorMsg="Please Select Item"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div> 

                <div class="position-relative  form-group col-sm-6">
                    <label for="HeadID" class="col-form-label">Invoice Purpose<span>*</span></label>
                    <div class="" style="display:flex;" >
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <!-- <select  class="form-control" name="HeadID" id="HeadID"   (change)="changeOrganization($event)"  formControlName="HeadID"> 
                            
                             
                            <option [ngValue]="null" disabled selected >Select Head</option>
                            
                           
                            <option *ngFor="let head of headlist" [ngValue]="head.InvoiceHeadID">{{head.InvoiceHeadName}}</option>
                        
                        </select> -->
                        <p-dropdown [options]="headlist"  [(ngModel)]="selectedInvoiceHeadID" optionLabel="InvoiceHeadItemName" [filter]="true" filterBy="InvoiceHeadItemName"
                        [showClear]="true" [placeholder]="selectedInvoiceHeadName"  (onChange)="changeHeadName($event)" name="HeadID" id="HeadID"     formControlName="HeadID"  style="width:100% !important;"  >
                     
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.InvoiceHeadItemName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>  
                    

                    <button type="button" class="btn btn-primary" (click)="InvoicePurposeDialogButton()">
                        <i class="fa fa-plus"></i>
                      </button>
                    
          
                 
                    </div>

                    <app-field-error-display [displayError]="isFieldValid('HeadID')" errorMsg="Please Select Head Category"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                
                </div>

                <div class="position-relative  form-group col-sm-6">
                    <label for="InvoiceForID" class="col-form-label">Payment Frequency<span>*</span></label>
                    <div class="">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <!-- <select  class="form-control" name="InvoiceForID" id="InvoiceForID"   (change)="changeOrganization($event)"  formControlName="InvoiceForID"> 
                            
                             
                            <option [ngValue]="null" disabled selected >Select Invoice For</option>
                         
                            <option *ngFor="let invFor of invforlist" [ngValue]="invFor.InvoiceForID">{{invFor.InvoiceForName}}</option>
                        
                        </select> -->
                        <p-dropdown [options]="invforlist"  [(ngModel)]="selectedInvoiceForID" optionLabel="InvoiceForName" [filter]="true" filterBy="InvoiceForName"
                        [showClear]="true" [placeholder]="selectedInvoiceForName"  (onChange)="changeInvoiceFor($event)" name="InvoiceForID" id="InvoiceForID"     formControlName="InvoiceForID"  [style]="{ width: '100%' }" >
                     
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.InvoiceForName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>  
                    
                        <app-field-error-display [displayError]="isFieldValid('InvoiceForID')" errorMsg="Please Select Invoice For ID"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
               
                <div class="position-relative form-group col-sm-6">
                    <label for="InvoiceName" class="col-form-label">Remark </label>
                    <div class="">
                        <input name="InvoiceName"  id="InvoiceName" placeholder="Please Enter Remark" type="text" class="form-control" formControlName="InvoiceName">
                   </div>
                </div>
           
                <div class="position-relative  form-group col-sm-6">
                    <label for="VendorInvoiceNo" class="col-form-label">Vendor Invoice No   <span>*</span></label>
                    <div   [ngClass]="displayFieldCss('VendorInvoiceNo')">
                     
                     
                        <input name="VendorInvoiceNo" id="VendorInvoiceNo" placeholder="Please Enter  Vendor Invoice No" type="text" class="form-control" formControlName="VendorInvoiceNo">
                    
                        <app-field-error-display [displayError]="isFieldValid('VendorInvoiceNo')" errorMsg="Please Enter Vendor Invoice No"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="InvoiceAttechmentURL" class="col-form-label">Attachment Invoice<span>*</span></label>
                    <div class="">
                        <!-- <input type="file" name="InvoiceAttechmentURL" id="InvoiceAttechmentURL"   placeholder="Please Enter InvoiceAttechmentURL" class="form-control" formControlName="InvoiceAttechmentURL"> -->
    
                        <!-- <img [src]="imageUrl"  style="width:250px;height:200px"> -->
                        <i class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i>
                        <input type="file" #Image accept="*"    (change)="onFileChange($event)"    accept="application/pdf, image/jpeg, image/png"   name="InvoiceAttechmentURL" id="InvoiceAttechmentURL"   placeholder="Please Enter InvoiceAttechmentURL" class="form-control" formControlName="InvoiceAttechmentURL">
                        <span>Only JPEG,PNG,PDF is Allowed</span>



                        
                         <app-field-error-display [displayError]="isFieldValid('InvoiceAttechmentURL')" errorMsg="Please Enter  InvoiceAttechmentURL"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
               

                <div class="position-relative form-group col-sm-6">
                    <label for="InvoiceDate" class="col-form-label"> Date Of Invoice <span>*</span></label>

                   
                    <div class="">

                        <!-- <p-calendar [(ngModel)]="value"  formControlName="InvoiceDate" ></p-calendar> [disabledDays]="[0,6]"-->
                    <!-- <p-calendar   [disabledDates]="invalidDates"   [(ngModel)]="value"  placeholder="Please Enter Invoice Date" [showIcon]="true" formControlName="InvoiceDate" dateFormat="dd-mm-yy"  [readonlyInput]="true" inputId="disabledays"></p-calendar> -->
                    <input     id="InvoiceDate" placeholder="Please Enter InvoiceDate" type="date"  formControlName="InvoiceDate"  class="form-control"  >
                        <app-field-error-display [displayError]="isFieldValid('InvoiceDate')" errorMsg="Please Enter InvoiceDate"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
 
                    </div>
                </div>



                <div class="position-relative  form-group col-sm-6">
                    <label for="ValidityExpireOn" class="col-form-label">Warranty/Validity ExpireOn</label>
                    <div class="">

                        <!-- <p-calendar   [disabledDates]="invalidDates"  [showIcon]="true" formControlName="ValidityExpireOn"  placeholder="Please Enter Warranty/Validity ExpireOn"  dateFormat="dd-mm-yy"    [readonlyInput]="true" inputId="disabledays"></p-calendar> -->
                  

                        <input type="date" name="ValidityExpireOn"  id="ValidityExpireOn"   placeholder="Please Enter Validity ExpireOn"  class="form-control"  formControlName="ValidityExpireOn"> 
                        <!-- <app-field-error-display [displayError]="isFieldValid('ValidityExpireOn')" errorMsg="Please Enter  Validity ExpireOn"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display> -->
                    </div>
                </div>


               


                <div class="position-relative  form-group col-sm-6">
                    <label for="WarrantyImp" class="col-form-label">Warranty/AMC/Any other imp. caption <span>*</span></label>
                    <div class="">
                        <input type="text" name="WarrantyImp" id="WarrantyImp" class="form-control"   placeholder="Please Enter Warranty Imp" formControlName="WarrantyImp">
    
                        
                        <app-field-error-display [displayError]="isFieldValid('WarrantyImp')" errorMsg="Please Enter  Warranty Imp"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
    


                <div class="position-relative  form-group col-sm-6">
                    <label for="DepttContactPerson" class="col-form-label">Dept. Contact Person<span>*</span></label>
                    <div class="">
                        <input type="text" name="DepttContactPerson" id="DepttContactPerson"   placeholder="Please Enter Dept. Contact Person" class="form-control" formControlName="DepttContactPerson">
    
    
                         <app-field-error-display [displayError]="isFieldValid('DepttContactPerson')" errorMsg="Please Enter  Dept Contact Person"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
               
                <div class="position-relative  form-group col-sm-6">
                    <label for="Priority" class="col-form-label">Payment Priority<span>*</span></label>
                    <div class="">
                        <!-- <input type="text" name="Priority" id="Priority"  placeholder="Please Enter Priority" class="form-control" formControlName="Priority">
                         -->
                        <p-dropdown [options]="prioritylist" [(ngModel)]="selectedPriorityId" optionLabel="PriorityName" [filter]="true" filterBy="PriorityName"
                        [showClear]="true" [placeholder]="selectedPriorityName"  (onChange)="changePriority($event)"name="Priority" id="Priority"     formControlName="Priority" [style]="{ width: '100%' }" >
                     
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.PriorityName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                        
                        <app-field-error-display [displayError]="isFieldValid('Priority')" errorMsg="Please Enter  Priority"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>

                <div class="position-relative  form-group col-sm-6">
                    <label for="ApprovalLetterAttachmentURL" class="col-form-label">Prior Approval Letter </label>
                    <div class="">
                        <!-- <input type="file" name="ApprovalLetterAttachmentURL" id="ApprovalLetterAttachmentURL"   placeholder="Please Enter ApprovalLetterAttachmentURL" class="form-control" formControlName="ApprovalLetterAttachmentURL"> -->
    
                        <!-- <img [src]="imageUrl1"  style="width:250px;height:200px"> -->

                        <i class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i>
                        <input type="file" #Image1 accept="*" (change)="onFileChange1($event)"   accept="application/pdf, image/jpeg, image/png"    name="ApprovalLetterAttachmentURL" id="ApprovalLetterAttachmentURL"    class="form-control" formControlName="ApprovalLetterAttachmentURL">
<span>Only JPEG,PNG,PDF is Allowed</span>
                         <app-field-error-display [displayError]="isFieldValid('ApprovalLetterAttachmentURL')" errorMsg="Please Enter  ApprovalLetterAttachmentURL"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
             </div>
                </div>
        
               
        
                <div class="card-body" style="background-color:#fff; ">
                    <h1 class="card-title">
                      Payment Details
                    </h1>
                <div class="row">
                     
                    <div class="position-relative  form-group col-sm-6">
                        <label for="TotalCostWithoutGST" class="col-form-label">Total Cost Without GST(A)<span>*</span></label>
                        <div class="">
                            <input type="number" name="TotalCostWithoutGST" id="TotalCostWithoutGST"    #num1   placeholder="Please Enter Total Cost Without GST" class="form-control" formControlName="TotalCostWithoutGST"  (input)="resetfield()"  >
        
        
                             <app-field-error-display [displayError]="isFieldValid('TotalCostWithoutGST')" errorMsg="Please Enter  TotalCostWithoutGSTe"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <!-- <div class="position-relative  form-group col-sm-6">
                    </div> -->

                    <div class="position-relative  form-group col-sm-6">
                        <label for="DiscountPercent" class="col-form-label">Discount Percent(B)<span>*</span></label>
                        <div class="">
                            <input type="number" name="DiscountPercent" id="DiscountPercent"    #num6 placeholder="Please Enter Discount Percent" class="form-control" formControlName="DiscountPercent" (input)="valueChangedI(num1.value,num6.value)">
        
        
                             <app-field-error-display [displayError]="isFieldValid('DiscountPercent')" errorMsg="Please Enter  Discount Percent"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>

                    <!-- <div class="position-relative  form-group col-sm-6">
                    </div> -->
                    <div class="position-relative  form-group col-sm-6">
                        <label for="DiscountAmount" class="col-form-label">Discount Amount(C=A*B/100)<span>*</span></label>
                        <div class="">
                            <input type="text" readonly  name="DiscountAmount" id="DiscountAmount"  #num8  placeholder="Please Enter Discount Amount" class="form-control" formControlName="DiscountAmount" (ngModelChange)="valueChangedJ(num1.value,num8.value)">
        
        
                             <app-field-error-display [displayError]="isFieldValid('DiscountAmount')" errorMsg="Please Enter  Discount Amount"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>

                    <div class="position-relative  form-group col-sm-6">
                        <label for="TotalAmountAfterDiscount" class="col-form-label">Discounted Cost (D=A-C)<span>*</span></label>
                        <div class="">
                            <input type="text"  readonly name="TotalAmountAfterDiscount" id="TotalAmountAfterDiscount"    #num9 placeholder="Please Enter Discounted Cost" class="form-control" formControlName="TotalAmountAfterDiscount">
        
        
                             <app-field-error-display [displayError]="isFieldValid('TotalAmountAfterDiscount')" errorMsg="Please Enter   Discounted Cost"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>




                    <div class="position-relative  form-group col-sm-6">
                        <label for="TDSInPercent" class="col-form-label">TDS In Percent(E)<span>*</span></label>
                        <div class="">
                            <input type="number" name="TDSInPercent" id="TDSInPercent"  #num4  placeholder="Please Enter TDS In Percent" class="form-control" formControlName="TDSInPercent" (input)="valueChangedF(num9.value,num4.value)">
        
        
                             <app-field-error-display [displayError]="isFieldValid('TDSInPercent')" errorMsg="Please Enter  TDS In Percent"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <!-- <div class="position-relative  form-group col-sm-6">
                    </div> -->

                    <div class="position-relative  form-group col-sm-6">
                        <label for="TDSInAmount" class="col-form-label">TDS In Amount(F=D*E/100)<span>*</span></label>
                        <div class="">
                            <input type="text"  readonly name="TDSInAmount" id="TDSInAmount"   #num5 placeholder="Please Enter TDS In Amount" class="form-control" formControlName="TDSInAmount"  (ngModelChange)="valueChangedG(num9.value,num5.value)">
        
        
                             <app-field-error-display [displayError]="isFieldValid('TDSInAmount')" errorMsg="Please Enter  TDS In Amount"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>

                    <div class="position-relative  form-group col-sm-6">
                        <label for="TotalAmountAfterTDS" class="col-form-label">Amount After TDS(G=D-F) <span>*</span></label>
                        <div class="">
                            <input type="text"  readonly name="TotalAmountAfterTDS" id="TotalAmountAfterTDS" #num7  placeholder="Please Enter Total Amount After TDS" class="form-control" formControlName="TotalAmountAfterTDS"   >
        
        
                             <app-field-error-display [displayError]="isFieldValid('TotalAmountAfterTDS')" errorMsg="Please Enter  Total Amount After TDS"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>

                    <div class="position-relative  form-group col-sm-6">
                        <label for="IGSTPer" class="col-form-label">IGST in Percent(H)<span>*</span></label>
                        <div class="">
                            <!-- <input type="number" name="IGSTPer"   #num2   placeholder="Please Enter GST Per" class="form-control" (input)="valueChangedA(num9.value,num2.value)"  formControlName="IGSTPer"  >  -->
        
                            <select  name="IGSTPer"    id="IGSTPer"   #num2  class="form-control"  formControlName="IGSTPer"   (ngModelChange)="valueChangedA(num9.value,num2.value)" >
                                <!-- <option [ngValue]="null" disabled selected >Please Select Gst</option> -->
                                <!-- <option value="0">0</option> -->
                                <option *ngFor="let gstdetails of gstdetail" [value]="gstdetails">{{gstdetails}}</option>
                             
                            </select>

                             <!-- <app-field-error-display [displayError]="isFieldValid('IGSTPer')" errorMsg="Please Enter  IGST Percent"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display> -->
                        </div>
                    </div>


                    <div class="position-relative  form-group col-sm-6">
                        <label for="IGSTAmount" class="col-form-label">Total IGST Amount(K=D*H/100)<span>*</span></label>
                        <div class="">
                            <input type="text"  readonly  name="IGSTAmount" id="IGSTAmount"  #num3    placeholder="Please Enter IGST Amount" class="form-control" formControlName="IGSTAmount"   >
        
        
                             <app-field-error-display [displayError]="isFieldValid('IGSTAmount')" errorMsg="Please Enter  IGST Amount"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <!-- <div class="position-relative  form-group col-sm-6">
                    </div> -->
                    <div class="position-relative  form-group col-sm-6">
                        <label for="CGSTPer" class="col-form-label">CGST in Percent(I)<span>*</span></label>
                        <div class="">
                            <input type="number" name="CGSTPer"  readonly  #num21   placeholder="Please Enter CGST In Percent" class="form-control" (ngModelChange)="valueChangedgsti(num9.value,num21.value)"  formControlName="CGSTPer" > 
        
        
                             <app-field-error-display [displayError]="isFieldValid('CGSTPer')" errorMsg="Please Enter CGST In Percentt"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="CGSTAmount" class="col-form-label">Total CGST Amount(L=D*I/100)<span>*</span></label>
                        <div class="">
                            <input type="text"  readonly  name="CGSTAmount" id="CGSTAmount"  #num31    placeholder="Please Enter Total CGST Amount" class="form-control" formControlName="CGSTAmount"   >
        
        
                             <app-field-error-display [displayError]="isFieldValid('CGSTAmount')" errorMsg="Please Enter  Total CGST Amount"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>



                    <div class="position-relative  form-group col-sm-6">
                        <label for="SGSTPer" class="col-form-label">SGST in Percent(J)<span>*</span></label>
                        <div class="">
                            <input type="number" name="SGSTPer"  readonly   #num22   placeholder="Please Enter SGST Percent" class="form-control" (ngModelChange)="valueChangedgstj(num9.value,num22.value)"  formControlName="SGSTPer" > 
        
        
                             <app-field-error-display [displayError]="isFieldValid('SGSTPer')" errorMsg="Please Enter  SGST Percent"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="SGSTAmount" class="col-form-label">Total SGST Amount(M=D*J/100)<span>*</span></label>
                        <div class="">
                            <input type="text"  readonly  name="SGSTAmount" id="SGSTAmount"  #num32    placeholder="Please Enter Total SGST Amount" class="form-control" formControlName="SGSTAmount"  (ngModelChange)="valueChangedD(num7.value,num31.value, num32.value)">
        
        
                             <app-field-error-display [displayError]="isFieldValid('SGSTAmount')" errorMsg="Please Enter  Total SGST Amount"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div> 
                    
                    <div class="position-relative  form-group col-sm-6">
                        <label for="TotalPayableAmount" class="col-form-label">Total Amt Payable to Vendor(N=G+L+M)<span>*</span></label>
                        <div class="">
                            <input type="text"  readonly name="TotalPayableAmount" #numn123 id="TotalPayableAmount"   placeholder="Please Enter Total Payable To Vendor" class="form-control" formControlName="TotalPayableAmount"  (ngModelChange)="valueChangeddiscountreceive(numn123.value,num123.value, num8.value)">
        
        
                             <app-field-error-display [displayError]="isFieldValid('TotalPayableAmount')" errorMsg="Please Enter  Total Payable To Vendor"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>



                    <div class="position-relative  form-group col-sm-6">
                        <label for="TDSInAmountToGovt" class="col-form-label">TDS Payable of Behalf of Vendor to govt(O=F)<span>*</span></label>
                        <div class="">
                            <input type="text" readonly  name="TDSInAmountToGovt" #num123 id="TDSInAmountToGovt"   placeholder="Please Enter TDS Payable of Behalf of Vendor" class="form-control" formControlName="TDSInAmountToGovt">
        
        
                             <app-field-error-display [displayError]="isFieldValid('TDSInAmountToGovt')" errorMsg="Please Enter TDS Payable of Behalf of Vendor"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>



              


                

             
                    
                    <!-- <div class="position-relative  form-group col-sm-6">
                        <label for="DiscountPercent" class="col-form-label">Discount Received (P=C)<span>*</span></label>
                        <div class=""> 
                            <input type="number"  readonly name="DiscountAmount" id="DiscountAmount"  #discountrec    placeholder="Please Enter DiscountAmount" class="form-control" formControlName="DiscountAmount"    >
        
        
                             <app-field-error-display [displayError]="isFieldValid('DiscountAmount')" errorMsg="Please Enter  DiscountAmount"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div> -->



                   

               


               
                    <div class="position-relative  form-group col-sm-6">
                        <label for="TotalInvoiceValue" class="col-form-label">Total Invoice Value (Q=N+O+P)<span>*</span></label>
                        <div class="">
                            <input type="number" readonly  name="TotalInvoiceValue" id="TotalInvoiceValue"  #num10  placeholder="Please Enter Total Invoice Value" class="form-control" formControlName="TotalInvoiceValue">
        
        
                             <app-field-error-display [displayError]="isFieldValid('TotalInvoiceValue')" errorMsg="Please Enter  Total Invoice Value"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>



<!-- 
                    <div class="position-relative  form-group col-sm-6">
                        <label for="LateFeeIfApplicable" class="col-form-label">Late Fee (if application)(L)<span>*</span></label>
                        <div class="">
                            <input type="number" name="LateFeeIfApplicable" id="LateFeeIfApplicable"    #num11   placeholder="Please Enter LateFeeIfApplicable" class="form-control" formControlName="LateFeeIfApplicable" (input)="valueChangedM(num9.value,num10.value,num11.value)">
                                         
                             <app-field-error-display [displayError]="isFieldValid('LateFeeIfApplicable')" errorMsg="Please Enter  LateFeeIfApplicable"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
              -->

                    <!-- <div class="position-relative  form-group col-sm-6">
                        <label for="PreferdablePaymentMode" class="col-form-label">PreferdablePaymentMode<span>*</span></label>
                        <div class="">
                            <input type="text" name="PreferdablePaymentMode" id="PreferdablePaymentMode"   placeholder="Please Enter PreferdablePaymentMode" class="form-control" formControlName="PreferdablePaymentMode">
        
        
                             <app-field-error-display [displayError]="isFieldValid('PreferdablePaymentMode')" errorMsg="Please Enter  PreferdablePaymentMode"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div> -->
                   
                    <!-- <div class="position-relative  form-group col-sm-6">
                        <label for="TDSCertificateAttachmentURL" class="col-form-label">TDSCertificateAttachmentURL<span>*</span></label>
                        <div class="">
                            <input type="file" name="TDSCertificateAttachmentURL" id="TDSCertificateAttachmentURL"   placeholder="Please Enter TDSCertificateAttachmentURL" class="form-control" formControlName="TDSCertificateAttachmentURL">
        
        
                             <app-field-error-display [displayError]="isFieldValid('TDSCertificateAttachmentURL')" errorMsg="Please Enter  TDSCertificateAttachmentURL"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div> 


                    <div class="position-relative  form-group col-sm-6">
                        <label for="AccountNo" class="col-form-label">AccountNo<span>*</span></label>
                        <div class="">
                            <input type="text" name="AccountNo" id="AccountNo"   placeholder="Please Enter AccountNo" class="form-control" formControlName="AccountNo">
        
        
                             <app-field-error-display [displayError]="isFieldValid('AccountNo')" errorMsg="Please Enter  AccountNo"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                   



                    <div class="position-relative  form-group col-sm-6">
                        <label for="Department" class="col-form-label">Department<span>*</span></label>
                        <div class="">
                            <input type="text" name="Department" id="Department"   placeholder="Please Enter Department" class="form-control" formControlName="Department">
        
        
                             <app-field-error-display [displayError]="isFieldValid('Department')" errorMsg="Please Enter  Department"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                   


                    <div class="position-relative  form-group col-sm-6">
                        <label for="IRNNo" class="col-form-label">IRNNo<span>*</span></label>
                        <div class="">
                            <input type="text" name="IRNNo" id="IRNNo"   placeholder="Please Enter IRNNo" class="form-control" formControlName="IRNNo">
        
        
                             <app-field-error-display [displayError]="isFieldValid('IRNNo')" errorMsg="Please Enter  IRNNo"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                   


                    <div class="position-relative  form-group col-sm-6">
                        <label for="Scheme" class="col-form-label">Scheme<span>*</span></label>
                        <div class="">
                            <input type="text" name="Scheme" id="Scheme"   placeholder="Please Enter Scheme" class="form-control" formControlName="Scheme">
        
        
                             <app-field-error-display [displayError]="isFieldValid('Scheme')" errorMsg="Please Enter  Scheme"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="DayRemaining" class="col-form-label">Day Remaining <span>*</span></label>
                        <div class="">
                            <input type="number" name="DayRemaining" id="DayRemaining" class="form-control"   placeholder="Please Enter DayRemaining." formControlName="DayRemaining">
                            <app-field-error-display [displayError]="isFieldValid('DayRemaining')" errorMsg="Please Enter  Day Remaining"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div> -->

                <!-- <div class="position-relative  form-group col-sm-6">
                    <label for="UserID" class="col-form-label">User<span>*</span></label>
                    <div class="">
                       
                        <select  class="form-control" name="UserID" id="UserID"   (change)="changeOrganization($event)"  formControlName="UserID"> 
                            
                             
                            <option [ngValue]="null" disabled selected >Choose User</option>
                             
                            <option value="1">Test</option>
                        
                        </select>
                    
                    
                        <app-field-error-display [displayError]="isFieldValid('UserID')" errorMsg="Please Select User"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div> -->




              


              
               
                <!-- <div class="position-relative  form-group col-sm-6">
                    <label for="PaymentPriorityID" class="col-form-label">Payment Priority ID<span>*</span></label>
                    <div class="">
                        <input type="text" name="PaymentPriorityID" id="PaymentPriorityID" placeholder="Please Enter Valid  PaymentPriorityID"  class="form-control" formControlName="PaymentPriorityID">
         
                        <app-field-error-display [displayError]="isFieldValid('PaymentPriorityID')" errorMsg="Please Enter PaymentPriorityID"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div> -->
                
        

             
                
               

            


 


               
                
                
                <!-- <div class="position-relative  form-group col-sm-6">
                    <label for="InvoiceHeadId" class="col-form-label">Invoice Head Id</label>
                    <div class="">
                        <input type="text" name="InvoiceHeadId"   placeholder="Please Enter  PinCode"  id="InvoiceHeadId" class="form-control" formControlName="InvoiceHeadId">
        
                        <app-field-error-display [displayError]="isFieldValid('InvoiceHeadId')" errorMsg="Please Enter Invoice Head Id"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div> -->
              
                 
             
 
                   


                 
                     
            
                    <div class="position-relative  form-check col-sm-6 offset-sm-6 ">
                        <div class="">
        
                            <br>
                            <br>
                            <br>
                            <button class="btn btn-info btn-lg" type="submit">Submit</button>&nbsp;&nbsp;<button class="btn btn-warning btn-lg" (click)="resetform()" type="reset" style="color:white;" >Reset</button>
                        </div>
                    </div>  

                     
   
            
                    </div>
           
          
                    
        

 


 <p-dialog [(visible)]="ProductCategoryDialog" [style]="{width: '800px'}" header="Product Category Registration" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
    
<form class="form-horizontal" [formGroup]="categoryform"> 


    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">PRODUCT CATEOGORY REGISTRATION</h1>
<div class="card-body" style="background-color:#fff; ">
    <h1 class="card-title">
       Product Category Registration
    </h1>
    <div class="row">
      



        <div class="position-relative  form-group col-sm-6">
            <label for="ItemCategoryName" class="col-form-label">Category Name  <span>*</span></label>
            <div class="">
                <input type="text" name="ItemCategoryName" id="ItemCategoryName" class="form-control" formControlName="ItemCategoryName">
     <!-- <app-field-error-display [displayError]="isFieldValid('ItemCategoryName')" errorMsg="Please Enter Category Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>  -->
            </div>
        </div>
        
        <div class="position-relative  form-group col-sm-6">
            <label for="CategoryDisplayName" class="col-form-label">Display Name  <span>*</span></label>
            <div class="">
                <input type="text" name="CategoryDisplayName" id="CategoryDisplayName" class="form-control" formControlName="CategoryDisplayName">
     <!-- <app-field-error-display [displayError]="isFieldValid('CategoryDisplayName')" errorMsg="Please Enter Display Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>  -->
            </div>
        </div>


        
        <div class="position-relative  form-group col-sm-6">
            <label for="Description" class="col-form-label">Description  <span>*</span></label>
            <div class="">
                <input type="text" name="Description" id="Description" class="form-control" formControlName="Description">
     <!-- <app-field-error-display [displayError]="isFieldValid('Description')" errorMsg="Please Enter Description Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>  -->
            </div>
        </div>

        <div class="position-relative  form-group col-sm-6">
            <label for="ActiveStatus" class="col-form-label">Active Status<span>*</span></label>
            <div class="">
                <!-- <input type="text" name="City" id="City" class="form-control"> -->

                <select  class="form-control" name="ActiveStatus" id="ActiveStatus"  formControlName="ActiveStatus">
                    <option [ngValue]="null" disabled selected >Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </select>
            
            
                <!-- <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Select Status"   style="background-color: #f8d7da;
                border-color: #f5c6cb;" class="alert-danger">
                </app-field-error-display> -->
            
            </div>
        </div>
        
     
      

        <div class="position-relative  form-check col-sm-6 offset-sm-6">
            <div class="">
                <button class="btn btn-primary"  (click)="ProductCategoryRegister()">Submit</button>
            </div>
        </div>
   
</div>

</div>
</form>


    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
 
    </ng-template>
</p-dialog>


<p-dialog [(visible)]="ProductDialog" [style]="{width: '800px'}" header="Product  Registration" [modal]="true"
styleClass="p-fluid">
<ng-template pTemplate="content">


    <form class="form-horizontal" [formGroup]="productform"> 

        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">PRODUCT REGISTRATION</h1>
        <div class="card-body" style="background-color:#fff; ">
    
    
    
    
            <h1 class="card-title">
               Product Registration
            </h1>
            <div class="row">
              
        
        
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="ItemName" class="col-form-label">Product Name  <span>*</span></label>
                    <div class="">
                        <input type="text" name="ItemName" id="ItemName" class="form-control" formControlName="ItemName"  (input)="valueChanged($event.target.value)">
             <!-- <app-field-error-display [displayError]="isFieldValid('ItemName')" errorMsg="Please Enter Item Name"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
               </app-field-error-display>  -->
    
               <!-- {{boxvalue}} -->
                    </div>
                </div>
                
             
                <div class="position-relative  form-group col-sm-6">
                    <label for="ItemCategoryID" class="col-form-label">Product Category Name<span>*</span></label>

                    <!-- {{itemcategorylist|json}}  -->
                    <div class="" >


                        <!-- {{selectedItemCategoryID}} -->
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <select  class="form-control" name="ItemCategoryID" id="ItemCategoryID"    formControlName="ItemCategoryID" [ngModel]="selectedItemCategoryID"> 
                            
                             
                            <option [ngValue]="null"   disabled selected >Choose Your Category</option>
                            
                            <!-- <option value="88" >Test</option> -->
                            <option *ngFor="let list of itemcategorylist"    [ngValue]="list.ItemCategoryID">{{list.ItemCategoryName}}</option>
                        
                        </select>
                    
<!--                     
                        <app-field-error-display [displayError]="isFieldValid('ItemCategoryID')" errorMsg="Please Select Category Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display> -->
                    
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
                    <div class="">
    
            <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus"  >
                <option value="1">Active</option>
                <option value="0">InActive</option></select>
                         <!-- <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display> -->
                    </div>
                </div>
        
 
              
        
                <div class="position-relative  form-check col-sm-6 offset-sm-6">
                    <div class="">
                        <button class="btn btn-primary"  (click)="ProductRegister()">Submit</button>
                    </div>
                </div>
           
        </div>
        
        

        </div>
    </form>


</ng-template>

<ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">

</ng-template>
</p-dialog>



<p-dialog [(visible)]="InvoicePurposeDialog" [style]="{width: '800px'}" header="Invoice Purpose  Registration" [modal]="true"
styleClass="p-fluid">
<ng-template pTemplate="content">


    <form class="form-horizontal" [formGroup]="InvoicePurposeform"> 


        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">  INVOICE PURPOSE HEAD ITEM REGISTRATION</h1>
    
    
    
        <div class="card-body" style="background-color:#fff; ">
            <h1 class="card-title">
               Invoice Purpose Head Item Registration
            </h1>
            <div class="row">
                <div class="position-relative  form-group col-sm-6">
                    <label for="HeadID" class="col-form-label">Invoice Purpose Head Category<span>*</span></label>
                    <div class="">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <select  *ngIf="headlist" class="form-control" name="InvoiceHeadCategoryID" id="InvoiceHeadCategoryID"   (change)="changeOrganization($event)"  formControlName="InvoiceHeadCategoryID"> 
                            
                             
                            <option [ngValue]="null" disabled selected >Select Head</option>
                            
                            <!-- <option value="88" >Test</option> -->
                            <option *ngFor="let head of headlist" [ngValue]="head.InvoiceHeadCategoryID">{{head.HeadName}}</option>
                        
                        </select>
                    
<!--                     
                        <app-field-error-display [displayError]="isFieldValid('InvoiceHeadCategoryID')" errorMsg="Please Select Head Category"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display> -->
                    
                    </div>
                </div> 
        
        
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="InvoiceHeadName" class="col-form-label">Invoice Purpose Head Item Name  <span>*</span></label>
                    <div class="">
                        <input type="text" name="InvoiceHeadName" id="InvoiceHeadName" class="form-control" formControlName="InvoiceHeadName">
             <!-- <app-field-error-display [displayError]="isFieldValid('InvoiceHeadName')" errorMsg="Please Enter Invoice Head Name"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
               </app-field-error-display>  -->
                    </div>
                </div>
               
        
   
        
                
              
        
                <div class="position-relative  form-check col-sm-6 offset-sm-6">
                    <div class="">
                        <button class="btn btn-primary"  (click)="InvoicePurposeRegister()">Submit</button>
                    </div>
                </div>
           
        </div>
        
        
    </div>

    </form>

</ng-template>

<ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">

</ng-template>
</p-dialog>