 
<div class="card" style=" overflow-x: scroll;">
    <p-table #dt [value]="products" [rows]="10" [paginator]="true"
          [globalFilterFields]="['OrgName','GSTN','StateName','DistrictName','DeskMobileNo','DeskEmailID','AuthPersonName','AuthPersonMobileNo']"
          [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"  styleClass="p-datatable-striped" >
          <!-- <p-table #dt [value]="products" [(selection)]="selectedProducts" dataKey="id" styleClass="p-datatable-customers" [rowHover]="true"
   
              [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
           [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [filterDelay]="0" [globalFilterFields]="['name','country.name','representative.name','status']"> -->
  
          <!-- <p-table [value]="products" [tableStyle]="{'min-width': '50rem'}"> -->
  
          <ng-template pTemplate="caption">
              <div class="p-d-flex p-ai-center p-jc-between">
                  <h1 class="headingclass">
                      BOX LIST</h1>
                  <span class="p-input-icon-left">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search..." />
                      &nbsp; &nbsp; <a [routerLink]="['/box-making']" class="btn btn-success">Add <i
                              class="fa fa-plus" aria-hidden="true"></i></a>
                  </span>
              </div>
          </ng-template>
  
  
          <ng-template pTemplate="header">
  
  
  
              <tr class="padding-head">
                  <th pSortableColumn="ActiveStatus">Status <p-sortIcon field="ActiveStatus"></p-sortIcon></th>
                  <th pSortableColumn="BoxID"> Box ID <p-sortIcon field="BoxID"></p-sortIcon></th>  
                  <th pSortableColumn="BoxName">Box Name <p-sortIcon field="BoxName"></p-sortIcon></th>
                  <th pSortableColumn="BoxMakingProductDetail">Product List<p-sortIcon field="BoxMakingProductDetail"></p-sortIcon></th>
                  <th pSortableColumn="CreatedDate">CreatedDate <p-sortIcon field="CreatedDate"></p-sortIcon></th>
                 </tr>
              <tr class="padding-head">
                 
                  <th>
                      <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')"
                          styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                          <ng-template let-option pTemplate="item">
                              <span
                                  [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                          </ng-template>
                      </p-dropdown>
  
  
                  </th>
  
  
                  <th style="width: 150px !important;" > 
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'BoxID' ,'contains')" placeholder="SalesID"  style="width: 50% !important;" />
                  </th> 
                  <th>
                     <input pInputText type="text" (input)="dt.filter($event.target.value,'BoxName' ,'contains')" placeholder="Search" />
                  </th>
                  <th> 
                     <input pInputText type="text" (input)="dt.filter($event.target.value,'BoxMakingProductDetail' ,'contains')"  placeholder="Search " class="fullwidth" />
                  </th>
                  <th> 
                     <input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')"  placeholder="Search" />
                  </th>
                 
  
  
  
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">
                <td> 
                    <span class="p-column-title">Status:</span>
                      <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
                      <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>
                  </td>
                  <td>
  
                      <span class="p-column-title">Box ID:</span>
                      {{product.BoxID}}
                  </td> 
                  <td>
                    <span class="p-column-title">Box Name:</span>
                      {{product.BoxName}}
                  </td>  
                  <td>
                    <span class="p-column-title">Box Product Date:</span>
                    <span pTooltip="View Product Detail"> <i class='fa fa-list' style='color: #f09639; zoom: 1.1;
                            border: 2px solid;
                            border-radius: 5px;' (click)="viewProduct(product.BoxMakingProductDetail)"></i>
                    </span>  
                    <p-dialog [(visible)]="productListDialog"  header=" Product in this Box" [modal]="true" styleClass="p-fluid">
                        <ng-template pTemplate="content">
                          <div class="container table-responsive">
                          <table class="table">
                            <tr>
                                <th>BoxMakingProductID</th>
                                <th>BoxID</th>
                                <th>ItemName</th>
                                <th>BrandName</th>
                                <th>UnitName</th>
                                <th>Quantity</th>
                                <th>CreatedDate</th>
                            </tr>
                            <tr *ngFor="let productlist of productListInBox">

                                <td>{{productlist.BoxMakingProductID}}</td>
                                <td>{{productlist.BoxID}}</td>
                                <td>{{productlist.ItemName}}</td>
                                <td>{{productlist.BrandName}}</td>
                                <td>{{productlist.UnitName}}</td>
                                <td>{{productlist.Quantity}}</td>
                                <td>{{productlist.CreatedDate}}</td> 
                            </tr>
                    
                          </table>
                            </div>
                    
                        </ng-template>
                    
                        <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
                    
                        </ng-template>
                    </p-dialog>
                    
                    </td>
                  
                  <td> 
                    <span class="p-column-title">Created Date:</span>
                      {{product.CreatedDate }}
                  </td> 
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
              <div class="p-d-flex p-ai-center p-jc-between">
                  In total there are {{products ? products.length : 0 }} products.
              </div>
          </ng-template>
      </p-table>
  </div>
  
 
  <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
  <!-- success message -->
  <p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="content">
          <div class="modal-dialog modal-confirm">
              <div class="modal-content">
                  <div class="modal-header justify-content-center">
                      <div class="icon-box">
                          <i class="material-icons">&#xE876;</i>
                      </div>
  
                  </div>
                  <div class="modal-body text-center">
                      <h4>Success!</h4>
                      <p>Organization Deleted Successfully.</p>
                  </div>
              </div>
          </div>
      </ng-template>
  
  
  </p-dialog>
  <!-- EOF success message -->
  
  <!-- update success -->
  <p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="content">
          <div class="modal-dialog modal-confirm">
              <div class="modal-content">
                  <div class="modal-header justify-content-center">
                      <div class="icon-box">
                          <i class="material-icons">&#xE876;</i>
                      </div>
  
                  </div>
                  <div class="modal-body text-center">
                      <h4>Success!</h4>
                      <p>Status Update Successfully.</p>
                  </div>
              </div>
          </div>
      </ng-template>
  
  
  </p-dialog>
  <!--eof update success  -->


