
<form class="form-horizontal" [formGroup]="form"> 

    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">PRODUCT REGISTRATION</h1>
    <div class="card-body" style="background-color:#fff; ">




        <h1 class="card-title">
           Product Registration
        </h1>
        <div class="row">
          
            <div class="position-relative  form-group col-sm-6" *ngIf="!showHindi">
                <label for="ItemName" class="col-form-label">Product Name (In English)  <span>*</span></label>
                <div class="">
                    <input type="text" name="ItemName" id="ItemName" class="form-control" formControlName="ItemName"   >
                    <app-field-error-display [displayError]="isFieldValid('ItemName')" errorMsg="Please Enter Item Name"     style="background-color: #791820;
                            border-color: #f5c6cb;" class="alert-danger">
                   </app-field-error-display> 
                </div>
            </div> 


            <div class="position-relative  form-group col-sm-6"  *ngIf="showHindi">
                <label for="ItemName" class="col-form-label">Product Name (In Hindi) <span>*</span></label>
                <div class="input-group mb-3">
                    <input type="text" name="ItemName" id="ItemName" class="form-control"  formControlName="ItemName"    appHinglishTyping  > 
                     <app-field-error-display [displayError]="isFieldValid('ItemName')" errorMsg="Please Enter Item Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
                     </app-field-error-display>  
                 </div>
            </div>

            
    
            
            <div class="position-relative  form-group col-sm-6" >
                 <label for="ItemName" class="col-form-label">Is Hindi</label>
                 <div class="input-group mb-3">
                    <input type="checkbox"  id="isCheckBox" (change)="enableHindi($event)"  class="form-check"/>
                 </div>
           </div>
          
            <div class="position-relative  form-group col-sm-6">
                <label for="ItemCategoryID" class="col-form-label">Product Category Name<span>*</span></label>
                <div class="" >
                    <select  class="form-control" name="ItemCategoryID" id="ItemCategoryID"    formControlName="ItemCategoryID"> 
                        <option [ngValue]="null"   disabled selected >Choose Your Category</option>
                        <option *ngFor="let list of categorylist" [ngValue]="list.ItemCategoryID">{{list.ItemCategoryName}}</option>
                   </select>
                   <app-field-error-display [displayError]="isFieldValid('ItemCategoryID')" errorMsg="Please Select Category Name"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
                <div class="">

        <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus"  >
            <option value="1">Active</option>
            <option value="0">InActive</option></select>
                     <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemAbstract" class="col-form-label">Item Abstract <span>*</span></label>
                <div class="">
                    <input type="text" name="ItemAbstract" id="ItemAbstract" class="form-control" formControlName="ItemAbstract">
         <app-field-error-display [displayError]="isFieldValid('ItemAbstract')" errorMsg="Please Enter ItemAbstract"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemDescription" class="col-form-label">Item Description<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemDescription" id="ItemDescription" class="form-control" formControlName="ItemDescription">
         <app-field-error-display [displayError]="isFieldValid('ItemDescription')" errorMsg="Please Enter ItemDescription "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="FAQListID" class="col-form-label">FAQ List ID<span>*</span></label>
                <div class="">
                    <input type="text" name="FAQListID" id="FAQListID" class="form-control" formControlName="FAQListID">
         <app-field-error-display [displayError]="isFieldValid('FAQListID')" errorMsg="Please Enter   FAQListID "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    
    
            
            
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemImageUrl" class="col-form-label">Item Image Url<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemImageUrl" id="ItemImageUrl" class="form-control" formControlName="ItemImageUrl">
         <app-field-error-display [displayError]="isFieldValid('ItemImageUrl')" errorMsg="Please Enter ItemImageUrl "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemImageUrl2" class="col-form-label">Item Image Url2 <span>*</span></label>
                <div class="">
                    <input type="text" name="ItemImageUrl2" id="ItemImageUrl2" class="form-control" formControlName="ItemImageUrl2">
         <app-field-error-display [displayError]="isFieldValid('ItemImageUrl2')" errorMsg="Please Enter ItemImageUrl2 "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemImageUrl3" class="col-form-label">Item Image Url3<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemImageUrl3" id="ItemImageUrl3" class="form-control" formControlName="ItemImageUrl3">
         <app-field-error-display [displayError]="isFieldValid('ItemImageUrl3')" errorMsg="Please EnterItemImageUrl3 "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->
    
           
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemImageUrl4" class="col-form-label">Item Image Url4<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemImageUrl4" id="ItemImageUrl4" class="form-control" formControlName="ItemImageUrl4">
         <app-field-error-display [displayError]="isFieldValid('ItemImageUrl4')" errorMsg="Please Enter ItemImageUrl4"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemVideoSource" class="col-form-label">Item Video Source<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemVideoSource" id="ItemVideoSource" class="form-control" formControlName="ItemVideoSource">
         <app-field-error-display [displayError]="isFieldValid('ItemVideoSource')" errorMsg="Please Enter ItemVideoSource"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
     -->
      
<!--             
            <div class="position-relative  form-group col-sm-6">
                <label for="ItemVideoUrl" class="col-form-label">Item Video Url<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemVideoUrl" id="ItemVideoUrl" class="form-control" formControlName="ItemVideoUrl">
         <app-field-error-display [displayError]="isFieldValid('ItemVideoUrl')" errorMsg="Please Enter ItemVideoUrl"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->




            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemVisibilityMode" class="col-form-label">Item Visibility Mode<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemVisibilityMode" id="ItemVisibilityMode" class="form-control" formControlName="ItemVisibilityMode">
         <app-field-error-display [displayError]="isFieldValid('ItemVideoSource')" errorMsg="Please Enter ItemVisibilityMode"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->

            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ActiveStatus" class="col-form-label">ActiveStatus<span>*</span></label>
                <div class="">
                    <input type="text" name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus">
         <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter ActiveStatus"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->


            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="MetaTitle" class="col-form-label">MetaTitle<span>*</span></label>
                <div class="">
                    <input type="text" name="MetaTitle" id="MetaTitle" class="form-control" formControlName="MetaTitle">
         <app-field-error-display [displayError]="isFieldValid('MetaTitle')" errorMsg="Please Enter MetaTitle"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    



            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="MetaKeyWord" class="col-form-label">MetaKeyWord<span>*</span></label>
                <div class="">
                    <input type="text" name="MetaKeyWord" id="MetaKeyWord" class="form-control" formControlName="MetaKeyWord">
         <app-field-error-display [displayError]="isFieldValid('MetaKeyWord')" errorMsg="Please Enter MetaKeyWord"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    


            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="MetaDiscription" class="col-form-label">MetaDiscription<span>*</span></label>
                <div class="">
                    <input type="text" name="MetaDiscription" id="MetaDiscription" class="form-control" formControlName="MetaDiscription">
         <app-field-error-display [displayError]="isFieldValid('MetaDiscription')" errorMsg="Please Enter MetaDiscription"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    



            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="SchemaCodes" class="col-form-label">SchemaCodes<span>*</span></label>
                <div class="">
                    <input type="text" name="SchemaCodes" id="SchemaCodes" class="form-control" formControlName="SchemaCodes">
         <app-field-error-display [displayError]="isFieldValid('SchemaCodes')" errorMsg="Please Enter SchemaCodes"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->



            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="OGTags" class="col-form-label">OGTags<span>*</span></label>
                <div class="">
                    <input type="text" name="OGTags" id="OGTags" class="form-control" formControlName="OGTags">
         <app-field-error-display [displayError]="isFieldValid('OGTags')" errorMsg="Please Enter OGTags"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->



            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="UrlOptimization" class="col-form-label">UrlOptimization<span>*</span></label>
                <div class="">
                    <input type="text" name="UrlOptimization" id="UrlOptimization" class="form-control" formControlName="UrlOptimization">
         <app-field-error-display [displayError]="isFieldValid('UrlOptimization')" errorMsg="Please Enter UrlOptimization"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    


            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="FilterValue1" class="col-form-label">FilterValue1<span>*</span></label>
                <div class="">
                    <input type="text" name="FilterValue1" id="FilterValue1" class="form-control" formControlName="FilterValue1">
         <app-field-error-display [displayError]="isFieldValid('FilterValue1')" errorMsg="Please Enter FilterValue1"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->



            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="FilterValue2" class="col-form-label">FilterValue2<span>*</span></label>
                <div class="">
                    <input type="text" name="FilterValue2" id="FilterValue2" class="form-control" formControlName="FilterValue2">
         <app-field-error-display [displayError]="isFieldValid('FilterValue2')" errorMsg="Please Enter FilterValue2"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    



            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="WholeSalePriceMax" class="col-form-label">WholeSalePriceMax<span>*</span></label>
                <div class="">
                    <input type="text" name="WholeSalePriceMax" id="WholeSalePriceMax" class="form-control" formControlName="WholeSalePriceMax">
         <app-field-error-display [displayError]="isFieldValid('WholeSalePriceMax')" errorMsg="Please Enter WholeSalePriceMax"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->




            

            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="WholeSalePriceMin" class="col-form-label">WholeSalePriceMin<span>*</span></label>
                <div class="">
                    <input type="text" name="WholeSalePriceMin" id="WholeSalePriceMin" class="form-control" formControlName="WholeSalePriceMin">
         <app-field-error-display [displayError]="isFieldValid('WholeSalePriceMin')" errorMsg="Please Enter WholeSalePriceMin"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    


            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemUnitPurchasePrice" class="col-form-label">ItemUnitPurchasePrice<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemUnitPurchasePrice" id="ItemUnitPurchasePrice" class="form-control" formControlName="ItemUnitPurchasePrice">
         <app-field-error-display [displayError]="isFieldValid('ItemUnitPurchasePrice')" errorMsg="Please Enter ItemUnitPurchasePrice"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->


            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemUnitSalesPrice" class="col-form-label">ItemUnitSalesPrice<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemUnitSalesPrice" id="ItemUnitSalesPrice" class="form-control" formControlName="ItemUnitSalesPrice">
         <app-field-error-display [displayError]="isFieldValid('ItemUnitSalesPrice')" errorMsg="Please Enter ItemUnitSalesPrice"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->


            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="MSP" class="col-form-label">MSP<span>*</span></label>
                <div class="">
                    <input type="text" name="MSP" id="MSP" class="form-control" formControlName="MSP">
         <app-field-error-display [displayError]="isFieldValid('MSP')" errorMsg="Please Enter MSP"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->



            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="MRP" class="col-form-label">MRP<span>*</span></label>
                <div class="">
                    <input type="text" name="MRP" id="MRP" class="form-control" formControlName="MRP">
         <app-field-error-display [displayError]="isFieldValid('MRP')" errorMsg="Please Enter MRP"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->


<!-- 
            <div class="position-relative  form-group col-sm-6">
                <label for="ItemDiscountInPercentage" class="col-form-label">ItemDiscountInPercentage<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemDiscountInPercentage" id="ItemDiscountInPercentage" class="form-control" formControlName="ItemDiscountInPercentage">
         <app-field-error-display [displayError]="isFieldValid('MSP')" errorMsg="Please Enter ItemDiscountInPercentage"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    


            
<!-- 
            <div class="position-relative  form-group col-sm-6">
                <label for="ItemDiscountInAmount" class="col-form-label">ItemDiscountInAmount<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemDiscountInAmount" id="ItemDiscountInAmount" class="form-control" formControlName="ItemDiscountInAmount">
         <app-field-error-display [displayError]="isFieldValid('ItemDiscountInAmount')" errorMsg="Please Enter ItemDiscountInAmount"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->


            

            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="Tax1Amount" class="col-form-label">Tax1Amount<span>*</span></label>
                <div class="">
                    <input type="text" name="Tax1Amount" id="Tax1Amount" class="form-control" formControlName="Tax1Amount">
         <app-field-error-display [displayError]="isFieldValid('Tax1Amount')" errorMsg="Please Enter Tax1Amount"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    




            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="Tax2Amount" class="col-form-label">Tax2Amount<span>*</span></label>
                <div class="">
                    <input type="text" name="Tax2Amount" id="Tax2Amount" class="form-control" formControlName="Tax2Amount">
         <app-field-error-display [displayError]="isFieldValid('Tax2Amount')" errorMsg="Please Enter Tax2Amount"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->



            
<!--             
            <div class="position-relative  form-group col-sm-6">
                <label for="GST" class="col-form-label">GST<span>*</span></label>
                <div class="">
                    <input type="text" name="GST" id="GST" class="form-control" formControlName="GST">
         <app-field-error-display [displayError]="isFieldValid('GST')" errorMsg="Please Enter GST"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->




            
<!--             
            <div class="position-relative  form-group col-sm-6">
                <label for="NetPayAmount" class="col-form-label">NetPayAmount<span>*</span></label>
                <div class="">
                    <input type="text" name="NetPayAmount" id="NetPayAmount" class="form-control" formControlName="NetPayAmount">
         <app-field-error-display [displayError]="isFieldValid('NetPayAmount')" errorMsg="Please Enter NetPayAmount"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->




            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="CoopenCode" class="col-form-label">CoopenCode<span>*</span></label>
                <div class="">
                    <input type="text" name="CoopenCode" id="CoopenCode" class="form-control" formControlName="CoopenCode">
         <app-field-error-display [displayError]="isFieldValid('CoopenCode')" errorMsg="Please Enter CoopenCode"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->




            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="GSTInPercentage" class="col-form-label">GSTInPercentage<span>*</span></label>
                <div class="">
                    <input type="text" name="GSTInPercentage" id="GSTInPercentage" class="form-control" formControlName="GSTInPercentage">
         <app-field-error-display [displayError]="isFieldValid('GSTInPercentage')" errorMsg="Please Enter GSTInPercentage"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->




            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="OpeningStock" class="col-form-label">OpeningStock<span>*</span></label>
                <div class="">
                    <input type="text" name="OpeningStock" id="OpeningStock" class="form-control" formControlName="OpeningStock">
         <app-field-error-display [displayError]="isFieldValid('OpeningStock')" errorMsg="Please Enter OpeningStock"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    




            
            
            <div class="position-relative  form-group col-sm-6">
                <label for="AsofDate" class="col-form-label">AsofDate<span>*</span></label>
                <div class="">
                    <input type="text" name="AsofDate" id="AsofDate" class="form-control" formControlName="AsofDate">
         <app-field-error-display [displayError]="isFieldValid('AsofDate')" errorMsg="Please Enter AsofDate"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    




            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="IsLowStockWarning" class="col-form-label">IsLowStockWarning<span>*</span></label>
                <div class="">
                    <input type="text" name="IsLowStockWarning" id="IsLowStockWarning" class="form-control" formControlName="IsLowStockWarning">
         <app-field-error-display [displayError]="isFieldValid('IsLowStockWarning')" errorMsg="Please Enter IsLowStockWarning"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->






            
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="LowStockQuantity" class="col-form-label">LowStockQuantity<span>*</span></label>
                <div class="">
                    <input type="text" name="LowStockQuantity" id="LowStockQuantity" class="form-control" formControlName="LowStockQuantity">
         <app-field-error-display [displayError]="isFieldValid('LowStockQuantity')" errorMsg="Please Enter LowStockQuantity"  style="background-color: #791820;
         border-color: #f5c6cb;" >
           </app-field-error-display> 
                </div>
            </div>
     -->



            
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemCode" class="col-form-label">ItemCode<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemCode" id="ItemCode" class="form-control" formControlName="ItemCode">
         <app-field-error-display [displayError]="isFieldValid('ItemCode')" errorMsg="Please Enter ItemCode"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    


            
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="Barcode" class="col-form-label">Barcode<span>*</span></label>
                <div class="">
                    <input type="text" name="Barcode" id="Barcode" class="form-control" formControlName="Barcode">
         <app-field-error-display [displayError]="isFieldValid('Barcode')" errorMsg="Please Enter Barcode"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    


            
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemBrandName" class="col-form-label">ItemBrandName<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemBrandName" id="ItemBrandName" class="form-control" formControlName="ItemBrandName">
         <app-field-error-display [displayError]="isFieldValid('ItemBrandName')" errorMsg="Please Enter ItemBrandName"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->


            
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemMedium" class="col-form-label">ItemMedium<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemMedium" id="ItemMedium" class="form-control" formControlName="ItemMedium">
         <app-field-error-display [displayError]="isFieldValid('ItemMedium')" errorMsg="Please Enter ItemMedium"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    


            
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemSize" class="col-form-label">ItemSize<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemSize" id="ItemSize" class="form-control" formControlName="ItemSize">
         <app-field-error-display [displayError]="isFieldValid('ItemSize')" errorMsg="Please Enter ItemSize"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    


            
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemWorkType" class="col-form-label">ItemWorkType<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemWorkType" id="ItemWorkType" class="form-control" formControlName="ItemWorkType">
         <app-field-error-display [displayError]="isFieldValid('ItemWorkType')" errorMsg="Please Enter ItemWorkType"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    


            
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemBase" class="col-form-label">ItemBase<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemBase" id="ItemBase" class="form-control" formControlName="ItemBase">
         <app-field-error-display [displayError]="isFieldValid('ItemBase')" errorMsg="Please Enter ItemBase"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->

            
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemColor" class="col-form-label">ItemColor<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemColor" id="ItemColor" class="form-control" formControlName="ItemColor">
         <app-field-error-display [displayError]="isFieldValid('ItemColor')" errorMsg="Please Enter ItemColor"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    




                      
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemMaterial" class="col-form-label">ItemMaterial<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemMaterial" id="ItemMaterial" class="form-control" formControlName="ItemMaterial">
         <app-field-error-display [displayError]="isFieldValid('ItemMaterial')" errorMsg="Please Enter ItemMaterial"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    




                      
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemEntryDate" class="col-form-label">ItemEntryDate<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemEntryDate" id="ItemEntryDate" class="form-control" formControlName="ItemEntryDate">
         <app-field-error-display [displayError]="isFieldValid('ItemEntryDate')" errorMsg="Please Enter ItemEntryDate"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->


                      
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemAddedBy" class="col-form-label">ItemAddedBy<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemAddedBy" id="ItemAddedBy" class="form-control" formControlName="ItemAddedBy">
         <app-field-error-display [displayError]="isFieldValid('ItemAddedBy')" errorMsg="Please Enter ItemAddedBy"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->


                      
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemToTopTen" class="col-form-label">ItemToTopTen<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemToTopTen" id="ItemToTopTen" class="form-control" formControlName="ItemToTopTen">
         <app-field-error-display [displayError]="isFieldValid('ItemToTopTen')" errorMsg="Please Enter ItemToTopTen"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->


                      
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ItemToHome" class="col-form-label">ItemToHome<span>*</span></label>
                <div class="">
                    <input type="text" name="ItemToHome" id="ItemToHome" class="form-control" formControlName="ItemToHome">
         <app-field-error-display [displayError]="isFieldValid('ItemToHome')" errorMsg="Please Enter ItemToHome"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->


                      
            
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="DisplayOrder" class="col-form-label">DisplayOrder<span>*</span></label>
                <div class="">
                    <input type="text" name="DisplayOrder" id="DisplayOrder" class="form-control" formControlName="DisplayOrder">
         <app-field-error-display [displayError]="isFieldValid('DisplayOrder')" errorMsg="Please Enter DisplayOrder"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->


            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="Comments" class="col-form-label">Comments <span>*</span></label>
                <div class="">
     
        
        <textarea  name="Comments" id="Comments" class="form-control" formControlName="Comments"></textarea>
        
        <app-field-error-display [displayError]="isFieldValid('Comments')" errorMsg="Please Enter Comments"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
     -->

                      
             

          
    
            <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    
    