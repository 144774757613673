import { Component, OnInit , Inject} from '@angular/core';
import { Billdesk } from '../../billdesk';
import { BilldeskService } from '../../billdesk.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as XLSX from 'xlsx';//for download excell
import { Customer, Representative } from "../../customer";  
import * as fileSaver from 'file-saver'; //for download excell

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { map } from 'rxjs/operators'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
 

export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor:string;
} 
@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.css']
})
export class StockListComponent implements OnInit {
  
  productDialog: boolean;

  products: Billdesk[];
  productsbyid: Billdesk[];

  data:number=0;

  dialogdata: string;
 
  radio=false;
  radio1=false;
  
  organizationList: Billdesk[];

  product: Billdesk;
  billdesk:any;
  selectedProducts:any;
  insertdata:any;
  updatedata:any;
  submitted: boolean;
//   OrganizationListResult:any[];
  statuses: any[];
 
  cities: City[];
  customers: Customer[];
  successDialog:boolean;
  insertDialog:boolean;
  representatives: Representative[];
  updateDialog:boolean;
  loading: boolean = true;
  cols: any[];

  paymentform:FormGroup;
  arr_2:any;
  exportColumns: any[];
  inventoryStatus: Representative[];
  selectedCities: City[];

  DepartmentID:any;
  
  InstitutionID:any;
  
  OrganizationID:any;
  
  UserInfo:any;
//   OrganizationListResult:any;
  constructor(private billdeskService: BilldeskService, private fb:FormBuilder,private messageService: MessageService, private confirmationService: ConfirmationService,public dialog: MatDialog,private route:Router) { 
 this.cities = [
          {name: 'New York', code: 'NY'},
          {name: 'Rome', code: 'RM'},
          {name: 'London', code: 'LDN'},
          {name: 'Istanbul', code: 'IST'},
          {name: 'Paris', code: 'PRS'}
      ];

       
    this.DepartmentID=localStorage.getItem('DepartmentID');

    this.InstitutionID=localStorage.getItem('InstitutionID');
    
    this.OrganizationID=localStorage.getItem('OrganizationID');
    
        this.UserInfo='&OrgID='+this.OrganizationID+'&DivID='+this.InstitutionID+'&DeptID='+ this.DepartmentID;

  }

  ngOnInit() {




     
    this.paymentform = this.fb.group({ 
      fromdate: [null, Validators.required],
      todate: [null, Validators.required]
      }); 

    this.cols = [
      { field: 'S.No.', header: 'S.No.', customExportHeader: 'Product Code' },
      { field: 'ProductID', header: 'Product ID', customExportHeader: 'Product Code' },
      { field: 'ProductName', header: 'Product Name' },
      { field: 'Purchase Qty', header: 'Purchase Qty' },
      { field: 'Sale Qty', header: 'Sale Qty' } ,
      { field: 'InStock', header: 'InStock' }
  ];

  this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
    
    

    this.statuses = [
      { label: "DeActive", value: "0" , IfValue: "Deactive" },
      { label: "Active", value: "1" , IfValue: "Active" } 
    ];


    
    this.insertdata=  localStorage.getItem('insertdata');
    
    this.updatedata=  localStorage.getItem('updatedata');
    // console.log("deat");
    // console.log(this.insertdata);
    
    // console.log(this.updatedata);
    // console.log("sgsg");
    
    
    if(this.insertdata=='Success'){
    
      this.organizationRegister();
      console.log("this.insertdata");
      console.log(this.insertdata);
      console.log("this.insertdata");
    
    } 

    if(this.updatedata=='Success'){
    
      this.organizationUpdate();
      console.log("this.updatedata");
      console.log(this.updatedata);
      console.log("this.updatedata");
    
    } 
 
    
   
 
 
      this.representatives = [
          { inventoryStatus: "INSTOCK", image: "INSTOCK" },
          { inventoryStatus: "LOWSTOCK", image: "LOWSTOCK" },
          { inventoryStatus: "OUTOFSTOCK", image: "OUTOFSTOCK" },
          
        ];



        // this.statuses = [

        //   { label: "Pending", value: "Pending" },
        //   { label: "Success", value: "Success" } 

        // ];
  }

  openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
  }

 



  handleChange(evt,orgid) {
    var name = evt.target.name;
    var value = evt.target.value;
    let updateurl= "/organization/organizationregistrationupdatestatus";
    if (name=='radio') {
      
      var activestatus= 1;
    }
    else
    { 
      var activestatus= 0;

    }
   var datarequest={ 
              "dataobject":[{
              "OrgID":orgid, 
              "ActiveStatus":activestatus
               }]}; 
    this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{ 
    if(res.organizationregistrationupdatestatusResult.Message=='Sucess'){
    window.location.reload();
    this.route.navigate(['/Organization-List'])
    localStorage.setItem('updatedata', 'Success');
   }else{
     console.log('Please Try Again Later');
   }
   });
 }
  deleteSelectedProducts() {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete the selected products?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.products = this.products.filter(val => !this.selectedProducts.includes(val));
              this.selectedProducts = null;
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
          }
      });
  }

//   editProduct(product: Billdesk ) {
//       this.Billdesk = {...product};
//       this.productDialog = true;
//   }

  viewProduct(product: Billdesk) {
    // this.product = {...product};
    this.productDialog = true;


    console.log(this.billdeskService.getOrganizationByid(product.OrgID).then(res => this.productsbyid = res));
}


tip(){


  alert('hello tip data');
}

successButton() {
   
    this.successDialog = true;
}





 
GetStockSummary(){
 
  var from=this.paymentform.get('fromdate').value;
  
  var to=this.paymentform.get('todate').value;


  this.billdeskService.getStockListexport(from,to,this.UserInfo).then((res:any) => {


    for(let elm of res){ Reflect.deleteProperty(elm, 'BrandID') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'ActiveStatus') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'BrandName') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'CreatedBy') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'CreatedDate') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'DefaultSalePriceType') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'DeptID') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'DeptName') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'Description') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'DivID') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'DivName') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'MRP') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'ExpiryDate') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'MinWholesaleQuantity') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'OrgID') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'OrgName') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'PackedDate') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'PurchaseBatchNumber') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'PurchaseDetailID') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'PurchaseID') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'PurchasePrice') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'SalePrice') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'StockID') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'StockUnit') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'TaxPercentage') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'UnitName') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'UpdatedDate') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'VendorID') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'VendorName') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'WholesalePrice') } // changing
    for(let elm of res){ Reflect.deleteProperty(elm, 'UpdatedBy') } // changing
   
   
    
   console.log(res);
    
   
   // const arr = res.map(x => Object.assign({}, res, { "S.NO.": "" }))  
   var sn=1
     res.forEach(function(e){
     if (typeof e === "object" ){
       e["S.NO"] = sn
     }
     sn++;
   });
   
   this.products=res;
   
   console.log(this.products); 
   
   
   
   
     // this.products.forEach(this.product=>delete this.product.ActiveStatus)
     // this.products.forEach(this.product=>delete this.product.BrandID)
     // this.products.forEach(this.product=>delete this.product.BrandName)
      
   
   
   
    });

}


 
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Purchase Entry", Detail: " Successfully",Backgroundcolor: "#47c9a2"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }


  openDialogUpdate(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Organization", Detail: "Update Successfully" ,Backgroundcolor: "#137c95"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }

  
  openDialogDelete(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Organization", Detail: "Delete Successfully" ,Backgroundcolor: "#ff4141"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      window.location.reload();
      this.dialogdata = result;
    });
  }
 


organizationRegister() {
  localStorage.removeItem('insertdata'); 
  console.log('remove');// this code for open dialog after 5 second
  setTimeout(() => {
    // this.insertDialog = true;
this.openDialog();

}, 500);
}
organizationUpdate() {
  localStorage.removeItem('updatedata'); 
  console.log('remove');// this code for open dialog after 5 second
  setTimeout(() => {
    // this.insertDialog = true;
this.openDialogUpdate();

}, 500);
}
 



statusupdateButton() {
  this.updateDialog = true;
}



  deleteProduct(product: Billdesk) {

    console.log(product);
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + product.OrgID + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {

  
 console.log(this.billdeskService.DeleteOrganizationByid(product.OrgID).then(res => this.products = res));
      
            // this.successButton();

            this.openDialogDelete();
        
        }
      });
  }

  hideDialog() {
      this.productDialog = false;
      this.submitted = false;
  }
  
  saveProduct() {
      this.submitted = true;

      if (this.product.name.trim()) {
          if (this.product.id) {
              this.products[this.findIndexById(this.product.id)] = this.product;                
            //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
            this.statusupdateButton();
          }
          else {
              this.product.id = this.createId();
              this.product.image = 'product-placeholder.svg';
              this.products.push(this.product);
            //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
          }

        //   this.products = [...this.products];
          this.productDialog = false;
          this.product = {};
      }
  }

  findIndexById(id: string): number {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
              index = i;
              break;
          }
      }

      return index;
  }

  createId(): string {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
  }

 
  exportPdf() {

    console.log(this.products);
    import('jspdf').then((jsPDF) => {
        import('jspdf-autotable').then((x) => {
            const doc = new jsPDF.default('p', 'px', 'a4');
            (doc as any).autoTable(this.exportColumns, this.products);
            doc.save('products.pdf');
        });
    });
}

exportExcel() {
    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(this.products);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'products');
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    fileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}




 
}
interface City {
  name: string,
  code: string
}



// same sa new page

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}