 
<p-toast></p-toast>






<div class="card" style=" overflow-x: scroll;">
 

    <!-- <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="[
    'UserID',
    'RoleTypeName',
    'UserLoginID', 
     'UserCode',
    'UserOrgName',
    'UserDivName',
    'UserDeptName',
    'UserFullName',
    'UserEmailID',
    'UserMobileNo',
    'UserAddress',
    'UserCity',
    'UserState',
    'UserPinCode',
    'CreatedDate',
    'CreatedBy'
    
    
    ]"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"  [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"> -->

        <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="[
        'UserID',
        'RoleTypeName',
        'UserLoginID', 
         'UserCode',
        'UserOrgName',
        'UserDivName',
        'UserDeptName',
        'UserFullName',
        'UserEmailID',
        'UserMobileNo',
        'UserAddress',
        'UserCity',
        'UserState',
        'UserPinCode',
        'CreatedDate',
        'CreatedBy'
            ]" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"    styleClass="p-datatable-customers"    responsiveLayout="scroll"   >    
        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">USER LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                    &nbsp; &nbsp;  <a     [routerLink]="['/User-Registration']" class="btn btn-success">Add <i class="fa fa-plus" aria-hidden="true"></i></a>
                </span>
            </div>

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
  
        </ng-template>
   
     
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="action" style="width: 150px">Action<p-sortIcon field="action"></p-sortIcon></th>
                
                <th pSortableColumn="ActiveStatus" style="width: 150px">Active Status<p-sortIcon field="ActiveStatus"></p-sortIcon></th>
                <th pSortableColumn="UserID" style="width: 150px">User ID<p-sortIcon field="UserID"></p-sortIcon></th>
                <th pSortableColumn="UserCode" style="width: 150px">User  Code<p-sortIcon field="UserCode"></p-sortIcon></th>
                <th pSortableColumn="RoleTypeName" style="width: 150px">Role<p-sortIcon field="RoleTypeName"></p-sortIcon></th>
                <th pSortableColumn="UserLoginID" style="width: 150px">Login ID<p-sortIcon field="UserLoginID"></p-sortIcon></th>
                
                <th pSortableColumn="UserOrgName" style="width: 150px">Organizaion <p-sortIcon field="UserOrgName"></p-sortIcon></th>
                <th pSortableColumn="UserDivName" style="width: 150px">Division<p-sortIcon field="UserDivName"></p-sortIcon></th>
                <th pSortableColumn="UserDeptName" style="width: 150px">Department<p-sortIcon field="UserDeptName"></p-sortIcon></th>
                <th pSortableColumn="UserFullName" style="width: 150px"> Full Name <p-sortIcon field="UserFullName"></p-sortIcon></th>
                <th pSortableColumn="UserEmailID" style="width: 150px">Email <p-sortIcon field="UserEmailID"></p-sortIcon></th>
                <th pSortableColumn="UserMobileNo" style="width: 150px">Mobile <p-sortIcon field="UserMobileNo"></p-sortIcon></th>
                <th pSortableColumn="UserAddress" style="width: 150px">Address<p-sortIcon field="UserAddress"></p-sortIcon></th>
                <th pSortableColumn="UserCity" style="width: 150px">City <p-sortIcon field="UserCity"></p-sortIcon></th>
                <th pSortableColumn="UserState" style="width: 150px">State <p-sortIcon field="UserState"></p-sortIcon></th>
                <th pSortableColumn="UserPinCode" style="width: 150px">Pincode <p-sortIcon field="UserPinCode"></p-sortIcon></th>
                <th pSortableColumn="CreatedDate" style="width: 150px">Created Date<p-sortIcon field="CreatedDate"></p-sortIcon></th>
                <th pSortableColumn="CreatedBy" style="width: 150px">Created Date<p-sortIcon field="CreatedBy"></p-sortIcon></th>
         
                
            </tr>


            <tr>
                <th>

                </th>

                <th>  
                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')" styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown> 
                 </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'UserID' ,'contains')" placeholder="Search UserID" />

                </th>

                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'UserCode' ,'contains')" placeholder="Search User Code" />

                </th>

                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'RoleTypeName' ,'contains')" placeholder="Search Role Type Name" />

                </th>

                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'UserLoginID' ,'contains')" placeholder="Search User LoginID" />

                </th>
 
<th>
<input pInputText type="text" (input)="dt.filter($event.target.value,'UserOrgName' ,'contains')" placeholder="Search Organization" />
</th>

<th>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'UserDivName' ,'contains')" placeholder="Search Division" />
</th>

<th>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'UserDeptName' ,'contains')" placeholder="Search Department" />
</th>


<th>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'UserFullName' ,'contains')" placeholder="Search Fullname" />
</th>

    
<th>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'UserEmailID' ,'contains')" placeholder="Search Email" />
</th>

        
<th>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'UserMobileNo' ,'contains')" placeholder="Search Mobile" />
</th>

            
<th>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'UserAddress' ,'contains')" placeholder="Search Address" />
</th>
      
<th>
<input pInputText type="text" (input)="dt.filter($event.target.value,'UserCity' ,'contains')" placeholder="Search City" />
</th>
<th>
<input pInputText type="text" (input)="dt.filter($event.target.value,'UserState' ,'contains')" placeholder="Search State" />
</th>
<th>
<input pInputText type="text" (input)="dt.filter($event.target.value,'UserPinCode' ,'contains')" placeholder="Search Pincode" />
</th>

<th>
<input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')" placeholder="Search Created Date" />
</th>

<th>
<input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedBy' ,'contains')" placeholder="Search Created By" />
</th>


                

 
 
 



                                                                                    

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr  class="responsivecss">
                <td>
              <span class="p-column-title">Action:</span>
                         <span style="display: flex;align-items: center;justify-content: center;">
                             <a [routerLink]="['/Edit-User',product.UserID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                     style='color: #2459a6;' pTooltip="Edit User Detail"></i></a>
                             <span pTooltip="View User Detail"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                             border: 2px solid;
                             border-radius: 5px;' (click)="viewProduct(product.UserID)"></i> </span>
                         </span>   
       
                    </td>


                    <td> 
           
                        
                    <span class="p-column-title">Status:</span>
                    <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
                    <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>
                       </td>
                <td>      <span class="p-column-title">User ID:</span> {{product.UserID}}</td>
                <td>  <span class="p-column-title">User Code:</span> {{product.UserCode}}</td>
                <td>  <span class="p-column-title">Role:</span> {{product.RoleTypeName}}</td>
                <td>  <span class="p-column-title">Login ID:</span> {{product.UserLoginID}}</td>
                <td>  <span class="p-column-title">Organizaion:</span> {{product.UserOrgName}}</td>
                <td>  <span class="p-column-title">Division:</span> {{product.UserDivName}}</td>
                <td>  <span class="p-column-title">Department:</span> {{product.UserDeptName}}</td>
                <td>  <span class="p-column-title">Full Name:</span> {{product.UserFullName}}</td>
                <td>  <span class="p-column-title">Email:</span> {{product.UserEmailID}}</td>
                <td>  <span class="p-column-title">Mobile:</span> {{product.UserMobileNo}}</td>
                <td>  <span class="p-column-title">Address:</span> {{product.UserAddress}}</td>
                <td>  <span class="p-column-title">City:</span> {{product.UserCity}}</td>
                <td>  <span class="p-column-title">State:</span> {{product.UserState}}</td>
                <td>  <span class="p-column-title">Pincode:</span> {{product.UserPinCode}}</td>
                <td>  <span class="p-column-title">Created Date:</span> {{product.CreatedDate}}</td>
                 <td>  <span class="p-column-title">Created Date:</span> {{product.CreatedBy}}</td>
                
               

            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '600px'}" header="User Details" [modal]="true" styleClass="p-fluid">
  
  
    <ng-template pTemplate="content">
        <br>
        <div class="container">
            <table class="table table-striped">

                <tbody>
                    <tr>
                        <td>
                            <label for="Organization_Name" class="col-form-label">Role :</label>
                        </td>
                        <td>
                            <label for="OrgID" class="col-form-label"> {{productsbyid[0].RoleTypeName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="Organization_Name" class="col-form-label">User Login ID:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UserLoginID}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="PANCard" class="col-form-label">User ID:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UserID}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="GSTN" class="col-form-label">User Organization Name:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UserOrgName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="Contact_Number" class="col-form-label">User Division Name:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UserDivName}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="Emailid" class="col-form-label">User Department Name: </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UserDeptName}}
                            </label></td>

                    </tr>
                    <tr>
                        <td> <label for="Address" class="col-form-label">User Full Name: </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UserFullName}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="City" class="col-form-label">User Email ID: </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UserEmailID}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td><label for="State" class="col-form-label">User Mobile No: </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UserMobileNo}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="PinCode" class="col-form-label">User Address:</label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UserAddress}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="ContactPersonName" class="col-form-label">Created Date: </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedDate}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <label for="accountname" class="col-form-label">Created By: </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedBy}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="BanckAccountNo" class="col-form-label">Upadated Date:</label></td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedDate}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="bankname" class="col-form-label">UpdatedBy: </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedBy}} </label>
                        </td>

                    </tr>
     

                </tbody>
            </table>
        </div>

  
        
        
        


        
         
    </ng-template>
    
    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
        <span style="float: left;padding-top: 2%;">

      <div class="form-check-inline" style="color:#fff">
          <label class="form-check-label">

              Status
          </label>
      </div>
      <div class="form-check-inline" style="color:#fff">
          <label class="form-check-label">
              <!-- {{productsbyid[0].ActiveStatus}} -->
              <input type="radio" class="form-check-input" checked="!checked" name="radio"
                  [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].UserID)"
                  [value]="1">active
          </label>
      </div>
      <div class="form-check-inline" style="color:#fff">
          <label class="form-check-label">
              <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                  [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].UserID)"
                  [value]="0">Deactive
          </label>
      </div>
        </span>
           <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" style="float: right;">
          </button>

  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


