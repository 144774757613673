 
<p-toast></p-toast>






<div class="card" style=" overflow-x: scroll;">
    <!-- <p-toolbar styleClass="p-mb-4"> -->
        <!-- <ng-template pTemplate="left"> -->
            <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button> -->
        <!-- </ng-template> -->

        <!-- <ng-template pTemplate="right"> -->

            <!-- <p-multiSelect style="width:200px;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
            <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
        <!-- </ng-template> -->
    <!-- </p-toolbar> -->

    <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="[
    'RoleTypeName' ,
    'RoleDesc',
    'CreatedDate',
    'CreatedBy'
    ]"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">

        
        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
            <div class="p-d-flex p-ai-center p-jc-between">
                <h5 class="p-m-0">Manage Menu</h5>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                    &nbsp; &nbsp;  <a     [routerLink]="['/Role-Menu-Register']" class="btn btn-success">Add <i class="fa fa-plus" aria-hidden="true"></i></a>
                </span>
            </div>

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
  
        </ng-template>
   
     
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="rating" style="width: 150px">	Action<p-sortIcon field="rating"></p-sortIcon></th>
              
                <th pSortableColumn="RoleTypeName" style="width: 150px">Menu Name<p-sortIcon field="RoleTypeName"></p-sortIcon></th>
                 <th pSortableColumn="RoleDesc" style="width: 150px">Under Of<p-sortIcon field="RoleDesc"></p-sortIcon></th>
                 <th pSortableColumn="CreatedDate" style="width: 150px">Created At<p-sortIcon field="CreatedDate"></p-sortIcon></th>
                          <th pSortableColumn="CreatedBy" style="width: 150px">	Created By<p-sortIcon field="CreatedBy"></p-sortIcon></th>
                
            </tr>


            <tr>
                <td>

                </td>
<td>
    <input pInputText type="text" (input)="dt.filter($event.target.value,'MenuName' ,'contains')" placeholder="Search  Role Name " />

</td>
 
<td>
<input pInputText type="text" (input)="dt.filter($event.target.value,'UnderOfPID' ,'contains')" placeholder="Search Role Desc" />
</td>

<td>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'UpdatedDate' ,'contains')" placeholder="Search Created Date" />
</td>

<td>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedBy' ,'contains')" placeholder="Search Created By" />
</td> </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td>
                    <a     [routerLink]="['/Edit-Organization',product.OrgID]"><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"   style="margin-right: 4px;" ></button>
                    </a><button pButton pRipple icon="pi pi-eye"   class="p-button-rounded p-button-info" (click)="viewProduct(product)"></button> 
                       </td>
                <td>{{product.MenuName}}</td> 
                <td>{{product.UnderOfPID}}</td>
                <td>{{product.UpdatedDate}}</td>
                 <td>{{product.CreatedBy}}</td>   
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '450px'}" header="Product Details" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image">
        <div class="p-field">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" [(ngModel)]="product.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !product.name">Name is required.</small>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea id="description" pInputTextarea [(ngModel)]="product.description" required rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="inventoryStatus">Inventory Status</label>
            <p-dropdown [(ngModel)]="product.inventoryStatus" inputId="inventoryStatus" [options]="statuses" placeholder="Select">
                <ng-template let-option pTemplate="item">
                    <span [class]="'product-badge status-' + option.value">{{option.label}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="p-field">
            <label class="p-mb-3">Category</label>
            <div class="p-formgrid p-grid">
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category1" name="category" value="Accessories" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category1">Accessories</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category2" name="category" value="Clothing" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category2">Clothing</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category3" name="category" value="Electronics" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category3">Electronics</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category4" name="category" value="Fitness" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category4">Fitness</label>
                </div>
            </div>
        </div>

        <div class="p-formgrid p-grid">
            <div class="p-field p-col">
                <label for="price">Price</label>
                <p-inputNumber id="price" [(ngModel)]="product.price" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
            </div>
            <div class="p-field p-col">
                <label for="quantity">Quantity</label>
                <p-inputNumber id="quantity" [(ngModel)]="product.quantity"></p-inputNumber>
            </div>
        </div>
    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


