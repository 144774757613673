<style>
    .p-dialog .p-dialog-header {
        border-bottom: 0 none;
        background: #47c9a2 !important;
        color: #f9f9f9;
        padding: 1.0rem;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    /* .col1{
        position: absolute;
        width: 10em;
        left: 0em;
        top: auto;
    }
    .col2{
        position: absolute;
        width: 5em;
        left: 10em;
        top: auto;
    }
 */

    /* table tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
} */


    .p-datatable .p-datatable-tbody>tr>td:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
    }

    .p-dialog header {

        background: #c947c5 !important;
    }
</style>

<p-toast></p-toast>






<div class="card"  >


    <p-table #dt [value]="products" [rows]="5" [paginator]="true" [globalFilterFields]="[
'InvoiceID',
'VendorInvoiceNo', 
'CreatedDate',
'ValidityExpireOn',
'InvoiceName',
'VendorName',
 'Headname',
'invoiceForName',
'TotalPayableAmount',
'itemcategoryname',
'OrgName',
'DivName',
'DeptName'
    ]" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id" 
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"  styleClass="p-datatable-customers"    responsiveLayout="scroll"  >


<!-- 
 <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="[
 'InvoiceID',
 'VendorInvoiceNo', 
 'CreatedDate',
 'ValidityExpireOn',
 'InvoiceName',
 'VendorName',
 'Headname',
 'invoiceForName',
 'TotalPayableAmount',
 'itemcategoryname',
 'OrgName',
 'DivName',
 'DeptName'
     ]" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
 [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"    styleClass="p-datatable-customers"    responsiveLayout="scroll"   >  -->
        <ng-template pTemplate="caption">

      
        </ng-template>
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 style="text-align:center;margin-top: 10px;margin-bottom: 20px;font-weight: bold;color:#2459a6;">
                    INVOICE HISTORY LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />

                </span>
            </div>
        </ng-template>



        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width: 50px">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th> -->
                <th pSortableColumn="rating" style="width:80px;
                position: sticky;
                left: 0;
                z-index: 2;background:#f8f9fa;"> Action </th>

                <th pSortableColumn="inventoryStatus" style="width: 150px"> Invoice Stage <p-sortIcon
                        field="inventoryStatus"></p-sortIcon></th>
                <th pSortableColumn="InvoiceID " style="width: 150px">Bill Id.<p-sortIcon
                        field="InvoiceID"></p-sortIcon></th>
                <th pSortableColumn="InvoiceID " style="width: 150px">Invoice No.<p-sortIcon
                        field="InvoiceID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Invoice Date<p-sortIcon
                        field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">ValidityExpireOn<p-sortIcon
                        field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskMobileNo" style="width: 150px"> Item Name <p-sortIcon
                        field="DivDeskMobileNo"></p-sortIcon></th>
                <th pSortableColumn="InvoiceID " style="width: 150px">Remark<p-sortIcon field="InvoiceID"></p-sortIcon>
                </th>
                <th pSortableColumn="DivState" style="width: 150px">Vendor Name <p-sortIcon
                        field="DivState"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Net Payable Amount<p-sortIcon
                        field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Payment Status<p-sortIcon
                        field="DivDeskEmailID"></p-sortIcon></th>

                <th pSortableColumn="DivName" style="width: 150px">Invoice Document<p-sortIcon
                        field="DivName"></p-sortIcon></th>
                <!-- <th pSortableColumn="Headname" style="width: 150px">Head Name<p-sortIcon field="Headname"></p-sortIcon></th> -->
                <th pSortableColumn="InvoiceHeadItemName" style="width: 180px">Head Name<p-sortIcon
                        field="InvoiceHeadItemName"></p-sortIcon></th>

                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Invoice For Cycle<p-sortIcon
                        field="DivDeskEmailID"></p-sortIcon></th>

                <th pSortableColumn="itemcategoryname" style="width: 150px">Item Category Name<p-sortIcon
                        field="itemcategoryname"></p-sortIcon></th>

                <th pSortableColumn="DivDeskEmailID" style="width: 150px">Organization Name<p-sortIcon
                        field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivGSTN" style="width: 150px"> Division Name <p-sortIcon
                        field="DivGSTN"></p-sortIcon></th>
                <th pSortableColumn="DivGSTN" style="width: 150px"> Department Name <p-sortIcon
                        field="DivGSTN"></p-sortIcon></th>


            </tr>


            <tr>
                <th style="width:120px;
                position: sticky;
                left: 0;
                z-index: 2;background:#f8f9fa">

                </th>
                <!-- <th  style="width:80px;
                position: sticky;
                left: 0;
                z-index: 2;background:#ffffff;">
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'action' ,'contains')" placeholder="Action Perform" readonly />

                </th> -->



                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'StageName' ,'contains')"
                        placeholder="Search Invoice Status" />
                </th>

                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceID' ,'contains')"
                        placeholder="Search Bill ID" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorInvoiceNo' ,'contains')"
                        placeholder="Search Vendor Invoice No." />
                </th>


                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')"
                        placeholder="Search Invoice Date" />
                </th>
                <th>

                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'ValidityExpireOn' ,'contains')"
                        placeholder="Search Validity Expire On" />
                </th>

                <th>


                    <input pInputText type="text" (input)="dt.filter($event.target.value,'ItemName' ,'contains')"
                        placeholder="Search Item Name" />







                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceName' ,'contains')"
                        placeholder="Search Invoice Name" />
                </th>


                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorName' ,'contains')"
                        placeholder="Search Vendor Name" />
                </th>

                <th>

                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'TotalPayableAmount' ,'contains')"
                        placeholder="Search NetPayable Amount" />
                </th>

                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'paymentstatus' ,'contains')"
                        placeholder="Search Payment Status" />
                </th>

                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'Document' ,'contains')"
                        placeholder="Document" readonly />

                </th>
                <th>

                    <!-- <input pInputText type="text" (input)="dt.filter($event.target.value,'Headname' ,'contains')" placeholder="Search Head Name" /> -->


                    <p-dropdown [options]="itemhead"
                        (onChange)="dt.filter($event.value, 'InvoiceHeadItemName', 'contains')"
                        styleClass="p-column-filter" placeholder="Select a Head Name" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.value&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>
                </th>
                <th>

                    <!-- <input pInputText type="text" (input)="dt.filter($event.target.value,'invoiceForName' ,'contains')" placeholder="Search Invoice For Name" /> -->
                    <p-dropdown [options]="invforcycle"
                        (onChange)="dt.filter($event.value, 'InvoiceForName', 'contains')" styleClass="p-column-filter"
                        placeholder="Select Invoice For" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.value&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>

                </th>





                <th>

                    <!-- <input pInputText type="text" (input)="dt.filter($event.target.value,'itemcategoryname' ,'contains')" placeholder="Search Item Category Name" /> -->

                    <p-dropdown [options]="itemcategory"
                        (onChange)="dt.filter($event.value,'ItemCategoryName', 'contains')" styleClass="p-column-filter"
                        placeholder="Select Item Cat." [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.value&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>


                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'OrgName' ,'contains')"
                        placeholder="Search Org Name" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DivName' ,'contains')"
                        placeholder="Search Div Name" />
                </th>

                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DeptName' ,'contains')"
                        placeholder="Search Dept Name" />
                </th>



            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">
                <td style="width:80px;
                position: sticky;
                left: 0;
                z-index: 2;background:#ffffff;" class="col1">

                    <span class="p-column-title">Action:</span>
                    <a [routerLink]="['/View-Invoice',product.InvoiceID]">
                        <!-- <button pButton pRipple icon="pi pi-eye" class="p-button-info" pTooltip="View Invoice Detail"
                            style="background:#2459a6;border:#2459a6;">

                        </button> -->


                        <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                        border: 2px solid;
                        border-radius: 5px;'></i>
                    </a>







                </td>
                <td class="col2">
                    <span class="p-column-title"> Invoice Stage:</span>
                    {{product.StageName}}

                </td>
                <td>   <span class="p-column-title">Bill Id.:</span> {{product.InvoiceID}}</td>
                <td>   <span class="p-column-title">Invoice No:</span> {{product.VendorInvoiceNo}}</td>
                <td>   <span class="p-column-title">Invoice Date:</span> {{product.InvoiceDate}} </td>

                <td>   <span class="p-column-title">ValidityExpireOn:</span> {{product.ValidityExpireOn}}</td>
                <td>   <span class="p-column-title">Product Name:</span> {{product.ItemName}}</td>


                <td>   <span class="p-column-title">Remark:</span>  {{product.InvoiceName}}</td>

                <td>  <span class="p-column-title">Vendor Name:</span> {{product.VendorName}} </td>


                <td>  <span class="p-column-title">Net Payable Amount:</span> {{product.TotalPayableAmount}} </td>
                <td class="col2">
            <span class="p-column-title">Payment Status:</span> 
                <div *ngIf="product.RespMessage=='success';" style="color:green;display: initial;">{{product.RespMessage}}</div>

                <div *ngIf="product.RespMessage=='failure';" style="color:#ec5729;display: initial;">{{product.RespMessage}}</div> 
                 
                </td>

                <td>  <span class="p-column-title">Invoice Document:</span>  
                    
                    <a href="https://billdeskapi.aisect.org{{product.InvoiceAttechmentURL}}" target="_blank"><i
                            class="fa fa-file-pdf-o" style="font-size:48px;color:red"
                            title="Click For Open Document"></i></a></td>

                <td>   <span class="p-column-title">Head Name:</span>   {{product.InvoiceHeadItemName}}</td>
                <td>   <span class="p-column-title">Invoice For Cycle:</span>  {{product.InvoiceForName}}</td>

                <td>   <span class="p-column-title">Product Category Name:</span>  {{product.ItemCategoryName}} </td>

                <td>   <span class="p-column-title">Organization Name:</span>  {{product.OrgName }} </td>
                <td>  <span class="p-column-title">Division Name:</span>  {{product.DivName}} </td>
                <td>   <span class="p-column-title">Department Name:</span>  {{product.DeptName}} </td>



            </tr>
        </ng-template>
        <ng-template pTemplate="summary" style="display:flex;">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>



<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Organization Deleted Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>


<p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Status Update Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>