import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';


import { Router } from '@angular/router';
 
import { BilldeskService } from '../billdesk.service'; 
@Component({
  selector: 'app-organizationregistrationdetail',
  templateUrl: './organizationregistrationdetail.component.html',
  styleUrls: ['./organizationregistrationdetail.component.css']
})
export class OrganizationregistrationdetailComponent implements OnInit {
  //Create FormGroup 

  countries: any[];
    
  form: FormGroup; 
  stateList:any[];
  visibleloader:boolean;
  cityList:any[];
  selectedCity: string;

  selectedCityName:string='Please Select City';
  
  selectedStateName: string='Please Select State';
  StateID:number;
  DistrictID:number;
  selectedState: string;
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService) {
    console.log(this.billdeskService.getState().then(res => this.stateList = res));
    
    console.log('statelistend');



  }
 
  //Create required field validator for name

  ngOnInit() {


        
  
    this.form = this.fb.group({ 
      
      OrgName: [null, Validators.required], 
      PANNo: [null, Validators.required], 
      GSTN: [null, Validators.required],
      
      DeskMobileNo: [null, Validators.required],
      ActiveStatus: [null, Validators.required],
      PinCode: [null, Validators.required],
      
      AuthPersonName: [null, Validators.required],
      AuthPersonMobileNo: [null, Validators.required],
      BankAcName: [],
      BankAcNo: [],
      
      BankName: [],
      BankBranchName: [],
      IFSCCode: [],
      
      BillingAddress: [null, Validators.required],
      
      City: [null, Validators.required],
      
      State: [null, Validators.required],
 
      DeskEmailID: [null, [Validators.required, Validators.email]],
      
    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
 
  onSubmit() {
   var obj1 = 
    {
         "flag":"MSTOrganization_INSERT",
      "OrgID" :0,
      "CreatedDate"  : "",
      "CreatedBy" :"5",
      "UpdatedDate"  : "",
      "UpdatedBy" :""
    };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
        console.log("merge");
        console.log(form_data);
        console.log("merge"); 
        console.log(this.form);
        console.log(this.form.value.flag);
        
const datarequest={
  "CheckSum": "",
   "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
  
  form_data

]
 }
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
 console.log(this.form);
 
 
 if (this.form.valid) {


  this.visibleloader=true; 
   console.log('form submitted');
 let registerurl= "/organization/organizationregistration";
this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
  console.log("responce data");
  console.log(res);
  console.log("responce data");
   console.log(res.organizationregistrationResult);
  if(res.organizationregistrationResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/Organization-List'])
   localStorage.setItem('insertdata', 'Success');
  }else{
   console.log('Please Try Again Later');
 }
 });
} else {
   this.validateAllFormFields(this.form);
 }
  }

 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }


  changeState(e) { 
console.log(e); 
 this.selectedState=e.value.StateID;


this.selectedStateName=e.value.StateName;
console.log(this.billdeskService.getCity(e.value.StateID).then(res => this.cityList = res));

  }

  changeCity(e) {

console.log(e); 
 
this.selectedCityName=e.value.DistrictName;



console.log(this.selectedCityName);
    this.selectedCity=e.value.DistrictID;
 
  }

 }
 