<div class="card" style=" overflow-x: scroll;">
    <p-table #dt [value]="products" [rows]="10" [paginator]="true"
        [globalFilterFields]="['OrgName','GSTN','StateName','DistrictName','DeskMobileNo','DeskEmailID','AuthPersonName','AuthPersonMobileNo']"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"  styleClass="p-datatable-striped" >
        <!-- <p-table #dt [value]="products" [(selection)]="selectedProducts" dataKey="id" styleClass="p-datatable-customers" [rowHover]="true"
   
              [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
           [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [filterDelay]="0" [globalFilterFields]="['name','country.name','representative.name','status']"> -->

        <!-- <p-table [value]="products" [tableStyle]="{'min-width': '50rem'}"> -->

        <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 class="headingclass">
                    PURCHASE LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />
                    &nbsp; &nbsp; <a [routerLink]="['/purchase_entry']" class="btn btn-success">Add <i
                            class="fa fa-plus" aria-hidden="true"></i></a>
                </span>
            </div>
        </ng-template>


        <ng-template pTemplate="header">



            <tr class="padding-head">
                <!-- <th style="width: 50px">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th> -->
                <th pSortableColumn="rating" class="actionclass"> Action </th>

                <th pSortableColumn="ActiveStatus">Status <p-sortIcon field="ActiveStatus"></p-sortIcon></th>

                <th pSortableColumn="PurchaseID"> Purchase ID <p-sortIcon field="PurchaseID"></p-sortIcon></th>

                <th pSortableColumn="PurchaseBatchNumber"> Purchase Batch No.<p-sortIcon
                        field="PurchaseBatchNumber"></p-sortIcon></th>
                <th pSortableColumn="PurchaseInvoiceNumber">Purchase Inv. No. <p-sortIcon
                        field="PurchaseInvoiceNumber"></p-sortIcon></th>
                <th pSortableColumn="PurchaseInvoiceDate"> Purchase Invoice Date <p-sortIcon
                        field="PurchaseInvoiceDate"></p-sortIcon></th>

                <th pSortableColumn="VendorName"> Vendor Name. <p-sortIcon field="VendorName"></p-sortIcon></th>
                <th pSortableColumn="GrandTotal">Grand Total<p-sortIcon field="GrandTotal"></p-sortIcon></th>
                <th pSortableColumn="CreatedDate">CreatedDate <p-sortIcon field="CreatedDate"></p-sortIcon>
                </th>


            </tr>
            <tr class="padding-head">
                <!-- <td>
  
                  </td> -->
                <!-- <th style="position: sticky;
                  left: 0;
                  z-index: 2;background:#ffffff;"> -->

                <th></th>
                <th>



                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')"
                        styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>


                </th>


                <th style="width: 150px !important;" >

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'PurchaseID' ,'contains')"
                        placeholder="PurchaseID" style="width: 100px !important;"  />
                </th>


                <th>

                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'PurchaseBatchNumber' ,'contains')" placeholder="Search"
                        class="fullwidth" />
                </th>
                <th>

                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'PurchaseInvoiceNumber' ,'contains')"
                        placeholder="Search" class="fullwidth" />
                </th>


                <th>


                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'PurchaseInvoiceDate' ,'contains')" placeholder="Search "
                        class="fullwidth" />







                </th>


                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorName' ,'contains')"
                        placeholder="Search" class="fullwidth" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'GrandTotal' ,'contains')"
                        placeholder="Search" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')"
                        placeholder="Search" />
                </th>




            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">

                <td style="position: sticky;
                  left: 0;
                  z-index: 2;background:#ffffff;">
                    <span class="p-column-title">Action:</span>
                    <!-- <span style="display: flex;align-items: center;justify-content: center;"> -->
                    <!-- <a [routerLink]="['/Edit-Organization',product.PurchaseID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                  style='color: #2459a6;' pTooltip="Edit Organization Detail"></i></a> -->
                    <!-- <span pTooltip="View Purchase Detail"> <i class='fa fa-info-circle '  style="font-size: 25px;
                            color: brown;"
                            (click)="viewProduct(product.PurchaseID)"></i> </span>
                        &nbsp;&nbsp; -->
                    <!-- <span pTooltip="View Product Detail"> <i class='fa fa-list-alt'  style="font-size: 25px;
                            color: #137c95;"
                              (click)="viewProductDetail(product.PurchaseID)"></i> </span> -->
                    <!-- </span> -->




                    <span style="display: flex;align-items: center;justify-content: center;">
                        <!-- <a [routerLink]="['/Edit-Organization',product.OrgID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                style='color: #2459a6;' pTooltip="Edit Organization Detail"></i></a> -->
                        <span pTooltip="View Purchase Detail"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                        border: 2px solid;
                        border-radius: 5px;' (click)="viewProductDetail(product.PurchaseID)"></i> </span>
                    </span>


                </td>
                <td>


                    <span class="p-column-title">Status:</span>
                    <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
                    <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>



                    <!-- <i class="pi-file-edit" style="font-size: 2rem"></i> -->
                </td>
                <td >

                    <span class="p-column-title">Purchase ID:</span>
                    {{product.PurchaseID}}
                </td>

                <td>

                    <span class="p-column-title">Purchase Batch No:</span>
                    {{product.PurchaseBatchNumber}}
                </td>

                <td>
                    <span class="p-column-title">Purchase Invoice No:</span>
                    {{product.PurchaseInvoiceNumber}}
                </td>



                <td>

                    <span class="p-column-title">Purchase Invoice Date:</span>
                    {{product.PurchaseInvoiceDate| date:"dd/MM/YYYY"}}
                </td>
                <td>

                    <span class="p-column-title">Vendor Name:</span>
                    {{product.FromVendorName}}
                </td>
                <td>

                    <span class="p-column-title">Grand Total:</span>
                    {{product.GrandTotal| number:'1.2-2'}}

                </td>
                <td>

                    <span class="p-column-title">Created Date:</span>
                    {{product.CreatedDate}}
                </td>



            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '800px'}" header="Purchase Detail" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <br>

        <!-- {{productsbyid|json}} -->
        <div class="container">
            <table class="table table-striped">

                <tbody>
                    <tr>
                        <td >
                            <label for="Organization_Name" class="col-form-label">PurchaseID :</label>
                        </td>
                        <td>
                            <label for="OrgID" class="col-form-label"> {{productsbyid[0].PurchaseID}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="Organization_Name" class="col-form-label">Purchase Batch Number:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label">
                                {{productsbyid[0].PurchaseBatchNumber}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="PANCard" class="col-form-label">Purchase Invoice Date:</label></td>
                        <td><label for="Organization_Name" class="col-form-label">
                                {{productsbyid[0].PurchaseInvoiceDate}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="GSTN" class="col-form-label">Vendor Name:  </label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].FromVendorName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="Contact_Number" class="col-form-label">Dept Name:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].FromDeptName}}
                            </label></td>

                    </tr>



                    <tr>
                        <td><label for="Emailid" class="col-form-label">Payable Amount </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].PayableAmount}}
                            </label></td>

                    </tr>

                    <tr>
                        <td><label for="City" class="col-form-label">Purchase Quantity </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].PurchaseQuantity}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td><label for="State" class="col-form-label">Tax Amount Grand Total </label></td>
                        <td><label for="Organization_Name" class="col-form-label">
                                {{productsbyid[0].TaxAmountGrandTotal}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="PinCode" class="col-form-label">Taxable Amount Grand Total</label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label">
                                {{productsbyid[0].TaxableAmountGrandTotal}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="ContactPersonName" class="col-form-label">Created Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedDate}}
                            </label>
                        </td>



                </tbody>
            </table>
        </div>

        <!-- <div class="form-check-inline">
              <label class="form-check-label">
  
                  Status
              </label>
          </div>
          <div class="form-check-inline">
              <label class="form-check-label"> 
                  <input type="radio" class="form-check-input" checked="!checked" name="radio"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="1">active
              </label>
          </div>
          <div class="form-check-inline">
              <label class="form-check-label">
                  <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="0">Deactive
              </label>
          </div> -->



    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
        <!-- <span style="float: left;padding-top: 2%;">
  
          <div class="form-check-inline" style="color:#fff">
              <label class="form-check-label">
  
                  Status
              </label>
          </div>
          <div class="form-check-inline" style="color:#fff">
              <label class="form-check-label">
            
                  <input type="radio" class="form-check-input" checked="!checked" name="radio"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="1">active
              </label>
          </div>
          <div class="form-check-inline" style="color:#fff">
              <label class="form-check-label">
                  <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="0">Deactive
              </label>
          </div>
  
          
      </span>
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" style="float: right;"></button>
 -->
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="productDetail" header="Purchase Product Details" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <br>


        <div class="container">


            <div class="row">
                <h1>Purchase Basic Info</h1>

                <table class="table table-striped">

                    <tbody>
                        <tr>
                            <td>
                                <label for="Organization_Name" class="col-form-label">PurchaseID :</label>
                            </td>
                            <td >
                                <label for="OrgID" class="col-form-label"> {{productsbyid[0].PurchaseID}} </label>
                            </td>
                            <td>

                                <label for="Organization_Name" class="col-form-label">Purchase Batch Number:</label>
                            </td>
                            <td><label for="Organization_Name" class="col-form-label">
                                    {{productsbyid[0].PurchaseBatchNumber}} </label>
                            </td>

                        </tr>

                        <tr>
                            <td> <label for="PANCard" class="col-form-label">Purchase Invoice Date:</label></td>
                            <td><label for="Organization_Name" class="col-form-label">
                                    {{productsbyid[0].PurchaseInvoiceDate | date:"dd/MM/YYYY"}} </label>
                            </td>
                            <td>

                                <label for="GSTN" class="col-form-label">Vendor Name:</label>
                            </td>
                            <td><label for="Organization_Name" class="col-form-label"> 
                                
                                <!-- {{productsbyid[0].FromVendorName}} -->
                                
                                <span *ngIf="productsbyid[0].FromVendorName==''"> ---- </span> 
                                <span *ngIf="productsbyid[0].FromVendorName!=''"> {{productsbyid[0].FromVendorName}} </span> 
                              </label>
                            </td>
                        </tr>

                        <tr>
                            <td> <label for="Contact_Number" class="col-form-label">Division Name:</label></td>
                            <td><label for="Organization_Name" class="col-form-label"> 
                                
                                <!-- {{productsbyid[0].FromDivName}} -->



                                <span *ngIf="productsbyid[0].FromDivName==''"> ---- </span> 
                                <span *ngIf="productsbyid[0].FromDivName!=''"> {{productsbyid[0].FromDivName}} </span> 
                                </label></td>
                            <td><label for="Emailid" class="col-form-label">Purchase Type </label></td>
                            <td><label for="Organization_Name" class="col-form-label">

                                <!-- {{productsbyid[0].PurchaseType}} -->
                                <span *ngIf="productsbyid[0].PurchaseType=='1'">
                                    Purchase 
                                </span>
                                <span *ngIf="productsbyid[0].PurchaseType=='2'">
                                    Purchase Order
                                </span>
                                <span *ngIf="productsbyid[0].PurchaseType=='3'">
                                    Opening Stock
                                </span>
                                </label></td>

                        </tr>
                        <tr>
                            <td> <label for="Contact_Number" class="col-form-label">Dept Name:</label></td>

                            <td><label for="Organization_Name" class="col-form-label"> 
                                <span *ngIf="productsbyid[0].FromDeptName==''"> ---- </span> 
                                <span *ngIf="productsbyid[0].FromDeptName!=''"> {{productsbyid[0].FromDeptName}} </span> 
                                </label></td>
                            <td><label for="Emailid" class="col-form-label">Payable Amount </label></td>
                            <td><label for="Organization_Name" class="col-form-label">  

                                {{ productsbyid[0].PayableAmount }}
                                </label></td>

                        </tr>




                            <tr>
                            <td><label for="City" class="col-form-label">Purchase Quantity </label></td>
                            <td><label for="Organization_Name" class="col-form-label">
                                    {{productsbyid[0].PurchaseQuantity}}
                                </label>
                            </td>

                            <td><label for="State" class="col-form-label">Tax Amount Grand Total </label></td>
                            <td><label for="Organization_Name" class="col-form-label">
                            
                                
                                
                                    {{ productsbyid[0].TaxAmountGrandTotal  }}
                                </label>


                            </td>

                        </tr>

                        <tr>
                            <td>

                                <label for="PinCode" class="col-form-label">Taxable Amount Grand Total</label>
                            </td>
                            <td>
                                <label for="Organization_Name" class="col-form-label">
                                    <!-- {{productsbyid[0].TaxableAmountGrandTotal}}  -->
                                
                                    {{ productsbyid[0].TaxableAmountGrandTotal   }}
                                </label>
                            </td>
                            <td>

                                <label for="ContactPersonName" class="col-form-label">Created Date </label>
                            </td>
                            <td>
                                <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedDate| date:"dd/MM/YYYY"}}
                                </label>
                            </td>
                        </tr>
                        <tr>




                    </tbody>
                </table>

            </div>


            <!-- <h1>Purchase Product List  <button type="button" pButton pRipple icon="pi pi-file-pdf" style="float: right;" (click)="exportPdf()" class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button> -->
          <a href="{{imageurl}}{{productsbyid[0].PurchaseInvoiceUpload}}" target="_blank"> <button type="button" pButton pRipple icon="pi pi-file-pdf" style="float: right;"   class="p-button-danger mr-2" pTooltip="Purchase Invoice Download Click Here" tooltipPosition="bottom"></button> </a>
          
            <!-- </h1> -->

        
            <!-- <table class="table table-striped">

                <tbody>


                    <tr>


                        <th>
                            <label for="Organization_Name" class="col-form-label">Product ID :</label>
                        </th>





                        <th>

                            <label for="Organization_Name" class="col-form-label">Product Name:</label>
                        </th>





                        <th> <label for="PANCard" class="col-form-label">Brand Name:</label></th>


                        <th>

                            <label for="GSTN" class="col-form-label">Unit Name:</label>
                        </th>


                        <th> <label for="Contact_Number" class="col-form-label">Packed Date:</label></th>





                        <th><label for="Emailid" class="col-form-label">Expiry Date:</label></th>

                        <th><label for="City" class="col-form-label">Description</label></th>


                        <th><label for="State" class="col-form-label">Price Type Name </label></th>






                        <th>

                            <label for="PinCode" class="col-form-label">Purchase Quantity</label>
                        </th>




                        <th>

                            <label for="ContactPersonName" class="col-form-label">Purchase Price </label>
                        </th>


                        <th>

                            <label for="ContactPersonName" class="col-form-label">Sale Price </label>
                        </th>


                        <th>

                            <label for="ContactPersonName" class="col-form-label">MRP </label>
                        </th>


                        <th>

                            <label for="ContactPersonName" class="col-form-label">Whole sale Price </label>
                        </th>


                        <th>

                            <label for="ContactPersonName" class="col-form-label">Taxable Amount</label>
                        </th>


                        <th>

                            <label for="ContactPersonName" class="col-form-label">Tax Percentage</label>
                        </th>


                        <th>

                            <label for="ContactPersonName" class="col-form-label">TaxAmount</label>
                        </th>



                        <th>

                            <label for="ContactPersonName" class="col-form-label">TotalAmount</label>
                        </th>
                    </tr>
                    <tr *ngFor="let productdetail of productdetails">

                        <td>
                            <label for="OrgID" class="col-form-label"> {{productdetail.ProductID}} </label>
                        </td>

                        <td><label for="Organization_Name" class="col-form-label"> {{productdetail.ProductName}}
                            </label>
                        </td>

                        <td><label for="Organization_Name" class="col-form-label"> {{productdetail.BrandName}}
                            </label>
                        </td>



                        <td><label for="Organization_Name" class="col-form-label"> {{productdetail.UnitName}}
                            </label>
                        </td>


                        <td><label for="Organization_Name" class="col-form-label"> {{productdetail.PackedDate}}
                            </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productdetail.ExpiryDate}}
                            </label></td>

                        <td><label for="Organization_Name" class="col-form-label"> {{productdetail.Description}}
                            </label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productdetail.PriceTypeName}}
                            </label>
                        </td>

                        <td>
                            <label for="Organization_Name" class="col-form-label">
                                {{productdetail.PurchaseQuantity}} </label>
                        </td>


                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productdetail.PurchasePrice}}
                            </label>
                        </td>

                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productdetail.SalePrice}}
                            </label>
                        </td>


                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productdetail.MRP}}
                            </label>
                        </td>


                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productdetail.WholesalePrice}}
                            </label>
                        </td>


                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productdetail.TaxableAmount}}
                            </label>
                        </td>


                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productdetail.TaxPercentage}}
                            </label>
                        </td>


                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productdetail.TaxAmount}}
                            </label>
                        </td>


                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productdetail.TotalAmount}}
                            </label>
                        </td>

                    </tr>

                </tbody>
            </table> -->



            <div class="card">


             
                <p-table [columns]="selectedColumns" [value]="productdetails" [tableStyle]="{'min-width': '100%'}">
                    <ng-template pTemplate="caption">
                        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                            selectedItemsLabel="{0} columns selected" [style]="{'min-width': '200px'}"
                            placeholder="Choose Columns"></p-multiSelect>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <!-- <th>Product ID</th> -->
                            <th *ngFor="let col of columns">
                                {{col.header}}
                                
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product let-columns="columns">
                        <tr>
                            <!-- <td>{{productdetail.ProductID}} </td> -->
                            <td *ngFor="let col of columns">
                               
                                  <span>
                                        {{product[col.field]}}
                                   </span>
 
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">

    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Organization Deleted Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>


<p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Status Update Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>