import { Component, OnInit , Inject} from '@angular/core';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer";  

// import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

import { FormBuilder, FormGroup, FormArray, FormControl, Validators,ReactiveFormsModule  } from '@angular/forms';
import { Router } from '@angular/router';
// import { any } from 'node_modules2/codelyzer/util/function';


import { environment } from 'src/environments/environment';
export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor:string;
}
@Component({
  selector: 'app-rolemenumapping',
  templateUrl: './rolemenumapping.component.html',
  styleUrls: ['./rolemenumapping.component.css']
})
export class RolemenumappingComponent implements OnInit {
  baseUrl=environment.baseUrl;
  productDialog: boolean;

  products: Billdesk[];
  productsbyid: Billdesk[];

  products1: Billdesk[];
  Delete:any;
  data:number=0;

  dialogdata: string;

  RoleTypeID : any;
 

  
  organizationList: Billdesk[];

  product: Billdesk;
  billdesk:any;
  selectedProducts:any;
  rolelist: any;
  insertdata:any;
  updatedata:any;
  submitted: boolean;
  areAllSelected = false;
  statuses: any[];
  cities: City[];
  customers: Customer[];
  successDialog:boolean;
  insertDialog:boolean;
  representatives: Representative[];
  updateDialog:boolean;
  loading: boolean = true;
  inventoryStatus: Representative[];
  selectedCities: City[];

  selectedRoleID:string;
  selectedRoleName:string='Please Select Role';
  empList:any[];
//   OrganizationListResult:any;
form: FormGroup;
menumappinglist:any;
CountryData: Array<any> = [
  { name: 'IND', value: 'India' },
  { name: 'AUS', value: 'Australia' },
  { name: 'USA', value: 'America' },
  { name: 'RUS', value: 'Rusia' },
  { name: 'Eng', value: 'England' }
];
  constructor(private billdeskService: BilldeskService,private http:HttpClient, private messageService: MessageService, private confirmationService: ConfirmationService,public dialog: MatDialog,private route : Router,private fb: FormBuilder) { 
 
      this.cities = [
          {name: 'New York', code: 'NY'},
          {name: 'Rome', code: 'RM'},
          {name: 'London', code: 'LDN'},
          {name: 'Istanbul', code: 'IST'},
          {name: 'Paris', code: 'PRS'}
      ];


      this.form = this.fb.group({
        checkArray: this.fb.array([]),
        RoleTypeID: [null, Validators.required] 
      })
      // this.form = this.fb.group({ 
      
      //   RoleTypeID: [null, Validators.required] 
      // });
  }

  /*
  * event - checkbox event
  * menu-item-list
  * selected menu id
  * menu type : MAIN | SUB
  * actionType : ListView | InsertView | DetailView | StatusUpdate | UpdateView
  */
  onCheckboxChange(ev,menuItem:any,menuId:any,mType:string,actionType:string){    
    let excludedAction = ['InsertView','DetailView','StatusUpdate','UpdateView'];
    menuItem[actionType] = ev.target.checked ? '1' : '0';

    // uncheck all other options if listview == 0
    if(actionType=='ListView' && menuItem[actionType]=='0'){
      for(let action of excludedAction)menuItem[action]='0';
    }
    // if other options are selected then listview = 1;
    if(excludedAction.includes(actionType))menuItem['ListView']='1';

    // find Parent Menue Items of Selected Menue
    let parentMenue = menuItem || {};
    let findMainMenue = menuItem.SubMenuList || [];
    if(findMainMenue.length==0 && parseInt(menuItem.UnderOfPID)>0){
      parentMenue = (this.menumappinglist || []).find((obj)=>{
        return obj.MenuID == menuItem.UnderOfPID;
      });
    }
  
    // Parent Menue : find child menue & make disable when is listView ==0
    if(mType=='MAIN' && menuItem[actionType]=='0'){
      (parentMenue.SubMenuList || []).map((obj)=>{
        obj['ListView']='0';
        for(let action of excludedAction)obj[action]='0';
      });
    }

    // Parent Menue : find child menue & make active when is listView == 1
    if(mType=='MAIN' && menuItem[actionType]=='1'){
      (parentMenue.SubMenuList || []).map((obj)=>{obj['ListView']='1'; });
    }

    // Child Menue : find other option listview active or not
    if(mType=='SUB'){
      let childMenus = [...(parentMenue.SubMenuList || [])];
      let isListViewActive = childMenus.filter((obj)=>{return obj['ListView']=='1'; }).length || 0;
      parentMenue['ListView']= isListViewActive > 0 ? '1' : '0';      
    }


    //console.log(menuId,'menuItem',menuItem) //UnderOfPID
    console.log('menumappinglist:::',this.menumappinglist)

  }//EOF onCheckboxChange

  submitForm(){
    let traverseArray = [];
    let menuItemsList = JSON.parse(JSON.stringify([...this.menumappinglist])); 
    menuItemsList.map((obj)=>{ 
        let childNode = [...(obj.SubMenuList || [])];
        delete obj.SubMenuList;
        traverseArray.push(obj);
        for(let o of childNode){ 
          traverseArray.push(o);
        }
    },traverseArray) ;

    if(traverseArray.length==0) return;


    let postRequestObj={
      CheckSum    : "",
      lead_id     : "",
      ActionFor   : "Insert",           
      dataobject  :  traverseArray
    };    
  
    this.http.post(`${this.baseUrl}/rolemenumapping/rolemenumappingregistration`, postRequestObj).toPromise().then((resp:any)=>{      
      console.log(resp.rolemenumappingregistrationResult);   
      if(resp.rolemenumappingregistrationResult.Message=='Sucess'){
        location.reload(); 
        localStorage.setItem('updatedata', 'Success');
        }else{
          console.log('Please Try Again Later');
        }   
    }).catch((e)=>{
      console.log('catch::',e);
    });

  }//EOF submitForm



  ngOnInit() { 
    this.insertdata=  localStorage.getItem('insertdata');
    this.updatedata=  localStorage.getItem('updatedata');
    console.log("deat");
    console.log(this.insertdata);
    console.log(this.updatedata);
    console.log("sgsg");
    if(this.insertdata=='Success'){
    this.organizationRegister();
    console.log("this.insertdata");
    console.log(this.insertdata);
    console.log("this.insertdata");
    
    } 

    if(this.updatedata=='Success'){
    
      this.organizationUpdate();
      console.log("this.updatedata");
      console.log(this.updatedata);
      console.log("this.updatedata");
    
    } 
 
    
   
  //  var  organizationList  =  this.billdeskService.getOrganization();
//  this.billdeskService.getMenuForRolemaping().then(res => this.products = res);
 
 console.log(this.billdeskService.getUserRoleFOrDDl().then(res => this.rolelist = res));
      this.representatives = [
          { inventoryStatus: "INSTOCK", image: "INSTOCK" },
          { inventoryStatus: "LOWSTOCK", image: "LOWSTOCK" },
          { inventoryStatus: "OUTOFSTOCK", image: "OUTOFSTOCK" },
          
        ];



        this.statuses = [

          { label: "Pending", value: "Pending" },
          { label: "Success", value: "Success" } 

        ];
  }

  openNew() {
      // this.product = {};
      this.submitted = false;
      this.productDialog = true;
  }



  updateaccess(){


    alert('updateacct');

 
    console.log(this.selectedProducts);
  //  const selecteddata= this.selectedProducts.InvoiceID;

  let total = this.selectedProducts.length;
  this.empList=[];
  for (let i = 0; i < total; i++) {
    console.log(this.selectedProducts[i].InvoiceID);

    this.empList.push(this.selectedProducts[i].InvoiceID);
  } 

console.log("emplist");
console.log(this.empList);
console.log('emplist');
var invoiceNos = this.empList.join(","); //"10,9" convert array to string function  

console.log(invoiceNos);
    // this.products1 = this.products.filter(val =>this.selectedProducts.includes(val));

    var request = 
    {
      "flag":"",
      "InvoiceID":invoiceNos   
    };
 
        
const datarequest={
  "CheckSum": "",
  "lead_id": "",
  "ActionFor": "Insert",           
"dataobject":[
  
  request

]
 }
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
 
 
 
 
   console.log('form submitted');
   

let registerurl="/rolemenumapping/rolemenumappingregistration"
   this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
    console.log("depin");
    console.log(res);
    console.log("depin");
      console.log(res.mstmenuregistrationResult);
    if(res.mstmenuregistrationResult.Message=='Sucess'){
     console.log('Insert Success');
     this.route.navigate(['/Menu-List'])
     localStorage.setItem('insertdata', 'Success');
   }else{
     console.log('Please Try Again Later');
   }
   });
 
   
  }

  deleteSelectedProducts() {



    console.log(this.selectedProducts);

    this.products1 = this.products.filter(val =>this.selectedProducts.includes(val));

    console.log(this.products1);
    console.log(this.selectedProducts.includes());
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete the selected products?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.products = this.products.filter(val => !this.selectedProducts.includes(val));
              this.selectedProducts = null;
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
          }
      });
  }

//   editProduct(product: Billdesk ) {
//       this.Billdesk = {...product};
//       this.productDialog = true;
//   }

  viewProduct(product: Billdesk) {
    // this.product = {...product};
    this.productDialog = true;


    console.log(this.billdeskService.getOrganizationByid(product.OrgID).then(res => this.productsbyid = res));
}

successButton() {
   
    this.successDialog = true;
}








 
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Invoice", Detail: "Register Successfully",Backgroundcolor: "#47c9a2"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }


  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
  openDialogUpdate(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Menu Rights ", Detail: "Update Successfully" ,Backgroundcolor: "#0288d1"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }

  
  openDialogDelete(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Organization", Detail: "Delete Successfully" ,Backgroundcolor: "#ff4141"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      window.location.reload();
      this.dialogdata = result;
    });
  }
 


organizationRegister() {
  localStorage.removeItem('insertdata'); 
  console.log('remove');// this code for open dialog after 5 second
  setTimeout(() => {
    // this.insertDialog = true;
this.openDialog();

}, 500);
}
organizationUpdate() {
  localStorage.removeItem('updatedata'); 
  console.log('remove');// this code for open dialog after 5 second
  setTimeout(() => {
    // this.insertDialog = true;
this.openDialogUpdate();

}, 500);
}
 



statusupdateButton() {
  this.updateDialog = true;
}



  deleteProduct(product: Billdesk) {

    console.log(product);
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + product.OrgID + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {

  
 console.log(this.billdeskService.DeleteOrganizationByid(product.OrgID).then(res => this.products = res));
      
            // this.successButton();

            this.openDialogDelete();
        
        }
      });
  }

  hideDialog() {
      this.productDialog = false;
      this.submitted = false;
  }
  
  saveProduct() {
      this.submitted = true;

      if (this.product.name.trim()) {
          if (this.product.id) {
              this.products[this.findIndexById(this.product.id)] = this.product;                
            //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
            this.statusupdateButton();
          }
          else {
              this.product.id = this.createId();
              this.product.image = 'product-placeholder.svg';
              this.products.push(this.product);
            //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
          }

        //   this.products = [...this.products];
          this.productDialog = false;
          // this.product = {};
      }
  }

  findIndexById(id: string): number {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
              index = i;
              break;
          }
      }

      return index;
  }

  createId(): string {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
  }

  
  changeRole(e){
    console.log(e); 
      this.selectedRoleName=e.value.RoleTypeName;
    console.log(this.selectedRoleName);
        this.selectedRoleID=e.value.RoleTypeID;
        } 


    onCheckboxChange____(e,product,col) {    
     product[col] = e.target.checked ? '1' : '0';
    }
        submitForm_________() 
        {
 

          // console.log('hello');
          // console.log(this.form.value.RoleTypeID);
          // console.log(this.form.value.checkArray);

          console.log(this.menumappinglist);

          let menusItems = (this.menumappinglist || []).filter((obj)=>{
              let string = [(obj['InsertView']),(obj['ListView']),(obj['DetailView']),(obj['UpdateView'])].join('');
              console.log(this.RoleTypeID,'string',string)
              if(parseInt(string) > 0){               
                return true;
              }
              return false;              
            }).map((obj)=>{
               obj['RoleTypeID'] = this.RoleTypeID;
               return obj;
             });
            console.log('menusItems',menusItems);


    
       
              
      const datarequest={
        "CheckSum": "",
        "lead_id": "",
        "ActionFor": "Insert",           
      "dataobject":  menusItems
            
       }
      ;
      console.log("datarequest1");
      console.log(datarequest);
      console.log("datarequest1");
       
       
       
       
         console.log('form submitted');let url = this.baseUrl+"/rolemenumapping/rolemenumappingregistration";
         this.http.post(url, datarequest).toPromise().then((resp:any)=>{
           
           
          console.log(resp.rolemenumappingregistrationResult);
        
        if(resp.rolemenumappingregistrationResult.Message=='Sucess'){
 
       
          location.reload();
           localStorage.setItem('insertdata', 'Success');
           localStorage.setItem('updatedata', 'Success');
      
      
        }else{
      
          console.log('Please Try Again Later');
      
        }
        
        });


          
          

                   
        }

 


getMenuMappingListByRoleID(e){
this.RoleTypeID = e.value.RoleTypeID;
this.selectedRoleName=e.value.RoleTypeName;
// console.log(this.selectedRoleName);
this.selectedRoleID=e.value.RoleTypeID;

// console.log(this.billdeskService.getMenuMappingListByRoleID(RoleTypeID).then(res => this.menumappinglist = res));
this.billdeskService.getMenuMappingListByRoleID(this.RoleTypeID).then((res)=>{
  let roleMappingList =  res || [];
  for(let o of roleMappingList){  
    o['RoleTypeID']=this.RoleTypeID;    
    for(let subObj of (o as any).SubMenuList || []){
      subObj['RoleTypeID']=this.RoleTypeID;
    }
  }
  this.menumappinglist = roleMappingList;

  //console.log('this.menumappinglist',this.menumappinglist);
}).catch(()=>{
  this.menumappinglist = [];
})


}
        
checkAll(){


}


}
interface City {
  name: string,
  code: string
}



// same sa new page

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  



}