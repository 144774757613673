
import { Component, OnInit, ElementRef, VERSION, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../../billdesk';
import { BilldeskService } from '../../billdesk.service';

import { Router } from '@angular/router';
import { MessageService, SelectItemGroup } from 'primeng/api';
import { InvoiceItem } from '../../invoiceitem';
import { datatable } from '../../model/datatable.model';
import { environment } from '../../../environments/environment';
// import { ToastrService } from 'ngx-toastr';

import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';   
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
//import * as $ from "jquery";  
declare var $: any; 
 
@Component({
  selector: 'app-purchase-entry',
  templateUrl: './purchase-entry.component.html',
  styleUrls: ['./purchase-entry.component.css']
})
export class PurchaseEntryComponent implements OnInit {

  isDisabled: boolean = false;
  name = 'Angular ' + VERSION.major;
  dynamicArray = [];
  newDynamic;

  
  VendorDetailId: any;

  visibleloader: boolean;
  datatable = new datatable();
  UserTypeID: any;
  selectedOrgId: string;

  BrandDialog:boolean;
  selectBrandDname:any;

  brandform:FormGroup;
purchaseform:FormGroup;


PurchaseFrom:any;
  DataField: Billdesk[];
  ProductCategoryDialog:any;
  purchasetype:any;
  ServiceUrl = environment.baseUrl;
  selectedOrgName: string = 'Please Select Organization'; 

  selectedToOrgName: string = 'Please Select Organization';

  dialogdata:any;
  selectedDivName: string = 'Please Select Division';

  selectedToDivName: string = 'Please Select Division';
  selectBrandIDname:string='Please Select Brand';

  selectedDiv: string;

  selectedDeptID: string;


  selectedToOrgId: string;

selectedToDiv: string;

selectedToDeptID: string;

  selectedDeptName: string = 'Please Select Department';

  selectedToDeptName: string = 'Please Select Department';

  ProductListByGroup: SelectItemGroup[] = [];

  selectedRoleID: string;
  selectedRoleName: string = 'Please Select Role';

  ProductDialog:any;
  VendorShow: boolean;

  sidebarVisible2: boolean;
  organizaionlist: Billdesk[];
  statekey: any;
  stateList: Billdesk[];
  rolelist: Billdesk[];
  departmentlist: Billdesk[];
  divisionlist: Billdesk[];
  cityList: Billdesk[];

  rows: FormArray;
  type: string;

  // stateList:any[];

  // cityList:any[];
  selectedCity: string;
  imageerror:any;
  selectedCityName: string = 'Please Select City';
  itemHeadITemlist: any;
  selectedStateName: string = 'Please Select State';
  StateID: number;
  DistrictID: number;
  selectedState: string;
  departmentdetail: any;

  itemlist: Billdesk[];
  itemcategorylist: Billdesk[];
  selectedItemCategoryID: string;
  selectedItemName = 'Please Select Item';
  selectedItemId: string;
  selectedItemCategoryName = 'Please Select Category';
  venderidsession: any;
  dataArray = [];
  amount_i = 0;
  UserID: any;

  invoiceitem: InvoiceItem[];
  gstdetail: any;

  a: any;
  b: any;
  selectBrandID:any;
  c: any;
  d: any;
  e: any;
  f: any;
  g: any;
  h: any;
  i: any;
  j: any;
  k: any;
  l: any;
  m: any;
  n: any;
  o: any;
  p: any;
  q: any;
  taxamount: any;
  ctaxamount: any;
  staxamount: any;
  totalwithoutgst: any;
  frightcharge: any;
  discount: any;
  Totalwithtax: any;
  receiveamt: any;
  balance: any;
  previousbalance: any;
  currentbalance: any;
  amountInWords: any;
  invdate: any;
  duedate: any;
  discountamt: any;

  invno:any;

// current session variable start
  UserOrgName:string;
  UserDeptName:string;
  UserDivName:string;
  DepartmentID:any;
  InstitutionID:any;
  OrganizationID:any;

// current session variable End

departmentdetailbyselection:any;
  TotalAmountAfterDiscount: any;
  TDSInPercent: any;
  discountedcost: any
  TDSInAmount: any;
  ctaxper: any;
  staxper: any;
  amtAfterTds: any;
  TotalPayableAmount: any;
  itemdetailarray:any;
  vendorlist: Billdesk[];
  form: FormGroup;
  addForm: FormGroup;
  OrgID: any;
  Key: any;
  withouttax: any;
  pricetypelist:any; 
  VendorName: any;
  VendorBillingAddress: any;
  VendorCity: any;
  VendorPinCode: any;
  GSTN: any;
  VendorState: any;
  VendorEmailID: any;
  VendorMobileNo: any;
  brandlist:any;
  unitlist:any;

  vendordetail: any;
  selectedVendorName:string='Please Select Vendor';
  selectedVendorId: any;





  PurchaseDetailID:any; 
  ProductID: any; 
   BrandID: any; 
   PurchaseUnit: any; 
   PackedDate: any; 
   ExpiryDate: any; 
   Description: any; 
   PurchasePriceType: any; 
   PurchaseQuantity: any; 
   PurchasePrice: any; 
   SalePrice: any; 
   MRP: any; 
   productform:FormGroup;

   WholesalePrice: any; 
   TaxableAmount: any; 
   TaxPercentage: any; 
   TaxAmount:  any; 
   TotalAmount:any;  
  public listObj: DropDownListComponent;  
  
 
  public height: string = '220px';  
  public waterMark: string = 'Select a City';  
  public value: string = '3';  
  constructor(private fb: FormBuilder, private http: HttpClient, public dialog: MatDialog, private messageService: MessageService, private route: Router, private billdeskService: BilldeskService, private elementRef: ElementRef) {

    this.imageerror=true;

    this.selectedToOrgId='2';
    this.venderidsession = localStorage.getItem('VendorId');

    console.log("this.venderidsession");
    console.log(this.venderidsession);
 
    this.PurchaseFrom='Vendor';

    // Current Session   Details
this.UserOrgName=localStorage.getItem('UserOrgName');

this.UserDeptName=localStorage.getItem('UserDeptName');

this.UserDivName=localStorage.getItem('UserDivName');


this.DepartmentID=localStorage.getItem('DepartmentID');

this.InstitutionID=localStorage.getItem('InstitutionID');

this.OrganizationID=localStorage.getItem('OrganizationID');


    // Current Session Details

    
    this.purchaseform =this.fb.group({
      UserOrgID: [],
      UserDivID: [],
      UserDeptID: [], 
      ToOrgID: [],
      ToDivID: [],
      ToDeptID: [],


      VendorID: [], 
      InvoiceAttechmentURL: [null, Validators.required],
      invdate: [null, Validators.required],
      purchasetype: [null, Validators.required],
      invno: [null, Validators.required]
    });


    this.productform = this.fb.group({
      ItemName: [null, Validators.required],
      ItemCategoryID: [null, Validators.required],
      ActiveStatus: [null, Validators.required],
    });

    this.brandform = this.fb.group({ 
      BrandName: [null, Validators.required], 
      ActiveStatus: [null, Validators.required],
      });

    this.billdeskService.getOrganizationsLisInvoicetDDL().then(res => this.organizaionlist = res)
    this.billdeskService.getState().then(res => this.stateList = res)
    this.billdeskService.getUserRole().then(res => this.rolelist = res)
    // this.billdeskService.getItemCategoryCombinelist().then(res => this.itemcategorylist = res)

    this.billdeskService.getItemCategoryDDLInvoicelist().then((res:any) => {
      this.itemcategorylist = res
      console.log('this.category',this.itemcategorylist) 
    });
    
    this.billdeskService.getItemCategoryCombinelist().then((res:any) =>{
      // this.itemcategorylist = res
      this.ProductListByGroup = res.map(g => {
        return { label: g.ItemCategoryName,value:g.ItemID, items: g.SubMenuList.map(i => ({ label: i.ItemName, value: i })) };
    });
    console.log('DDDDDXXX:::',this.ProductListByGroup );
})

 




this.billdeskService.getDepartmentByid(this.DepartmentID).then(res => this.departmentdetail = res);

    this.billdeskService.getBrandListDLL().then(res => this.brandlist = res)


    this.billdeskService.getUnitListDLL().then(res => this.unitlist = res) 


    this.billdeskService.getPrice_Type_ListDLL().then(res => this.pricetypelist = res) 
    

    this.gstdetail = ["0", "5", "12", "18", "28"];

    this.receiveamt = 0;
    this.discount = 0;
    this.frightcharge = 0;
    
  }



  onFileChange(event) {

    const filename = event.target.files[0].name;
    var upld = filename.split('.').pop();
    if (upld == 'pdf' || upld == 'png' || upld == 'jpg'|| upld == 'jpeg') {
      // alert("File uploaded is "+upld)
      this.imageerror=true;


    } else {

      this.imageerror=false;
      // alert("Only PDF,JPEG,JPG,PNG are allowed");
      const reset_amount = "";
      this.purchaseform.controls['InvoiceAttechmentURL'].setValue(reset_amount);
    }

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
        this.purchaseform.patchValue({
        InvoiceAttechmentURL: file
      });

    }
  }
  ngOnInit() {
 

    
    // for alread exist one row 
    // this.dynamicArray.push({
    //   PurchaseDetailID: 0,
    //   ProductID: '',
    //   BrandID: '',
    //   PurchaseUnit: '',
    //   PackedDate: '',
    //   ExpiryDate: '',
    //   Description: '',
    //   PurchasePriceType: '',
    //   PurchaseQuantity: '',
    //   PurchasePrice: '',
    //   SalePrice: '',
    //   MRP: '',
    //   WholesalePrice: '',
    //   TaxableAmount: '' ,
    //   TaxPercentage: '',
    //   TaxAmount: '',
    //   TotalAmount: '' 
    // });


 

    setTimeout(()=>{
      console.log('DDDDD',$('.select'))


      $(document).ready(function () {
        $(".select").select2({
            placeholder: "Select a project..."
        })
        
    });


    },7000)
 
    




    this.UserID = localStorage.getItem('UserID');
    if (this.UserTypeID != 5) {
      this.http.get(this.ServiceUrl + '/vendor/vendordetail/' + this.UserID).subscribe(

       
        (response: any) => {
          console.log('thisresponse',response);

          console.log('response.vendordetailResult',response.vendordetailResult);
          this.VendorDetailId = response.vendordetailResult.dataobject[0].VendorID;


          this.VendorName = response.vendordetailResult.dataobject[0].VendorName;
          this.VendorBillingAddress = response.vendordetailResult.dataobject[0].VendorBillingAddress;

          this.VendorCity = response.vendordetailResult.dataobject[0].VendorCity;
          this.VendorPinCode = response.vendordetailResult.dataobject[0].VendorPinCode;
          this.GSTN = response.vendordetailResult.dataobject[0].GSTN;

          console.log(this.GSTN);
          this.VendorState = response.vendordetailResult.dataobject[0].VendorState;
          console.log(this.GSTN);
          this.VendorEmailID = response.vendordetailResult.dataobject[0].VendorEmailID;
          console.log(this.VendorEmailID);

          this.VendorMobileNo = response.vendordetailResult.dataobject[0].VendorMobileNo;
          console.log(this.VendorMobileNo);
          localStorage.setItem('VendorId', this.VendorDetailId);
        });
    }

    // this is for billdesk 

    this.UserTypeID = localStorage.getItem('UserTypeID');
    console.log("this.UserTypeID");
    console.log(this.UserTypeID);
    if (this.UserTypeID == 1) {
      this.VendorShow = false;
      this.billdeskService.getVendorDDLInvoiceList().then((res: any) => {
        this.vendorlist = res

        console.log(this.vendorlist);
      }

      );
    } else {

      this.VendorShow = true;
    }


    // this is for billdesk 

  }


  changeVendor(e) {

console.log(e);
    this.selectedVendorName = e.value.VendorName;

    var Userid = e.value.UserID;
    this.selectedVendorId = e.value.VendorID;

    this.billdeskService.getVendorByid(Userid).then((res: any) => {

      this.vendordetail = res

      console.log('thisdetail',this.vendordetail);


      this.VendorDetailId = this.vendordetail[0].VendorID;

      console.log(this.VendorDetailId);
      this.VendorName =  this.vendordetail[0].VendorName;
      this.VendorBillingAddress =  this.vendordetail[0].VendorBillingAddress;

      this.VendorCity = this.vendordetail[0].VendorCity;
      this.VendorPinCode =  this.vendordetail.VendorPinCode;
      this.GSTN =  this.vendordetail[0].GSTN;
      console.log(this.GSTN);
      this.VendorState =  this.vendordetail[0].VendorState;
      console.log(this.VendorState);
      this.VendorEmailID = this.vendordetail[0].VendorEmailID;
      console.log(this.VendorEmailID);
      this.VendorMobileNo =  this.vendordetail[0].VendorMobileNo;

      console.log(this.VendorMobileNo);

      localStorage.setItem('VendorId', this.VendorDetailId);
    });


  }

  onSubmit() {

console.log('checkvalida',this.purchaseform.valid);


    if (this.purchaseform.valid) {


      this.visibleloader = true; 
      this.isDisabled=true;

   this.totalwithoutgst = this.dynamicArray.reduce((sum, item) => sum + (item.PurchaseQuantity * item.PurchasePrice), 0);


    this.h = this.dynamicArray.reduce((sum, item) => sum + Number(item.TaxPercentage), 0);


    this.taxamount = this.dynamicArray.reduce((sum, item) => sum + Number(item.TaxAmount), 0);




    this.taxamount = this.dynamicArray.reduce((sum, item) => sum + Number(item.TaxAmount), 0);




    this.ctaxper = (this.h) / 2;
    this.staxper = (this.h) / 2;



    this.ctaxamount = (this.taxamount) / 2;
    this.staxamount = (this.taxamount) / 2;


    this.Totalwithtax = Number(this.taxamount) + Number(this.totalwithoutgst) + Number(this.frightcharge) - Number(this.discount);

    this.balance = Number(this.Totalwithtax) - Number(this.receiveamt);

    this.TotalPayableAmount = this.dynamicArray.reduce((sum, item) => sum + Number(item.TotalAmount), 0);

    this.previousbalance = 0;
    this.currentbalance = Number(this.balance) + Number(this.previousbalance);

    this.discountamt = this.totalwithoutgst * (this.discount / 100)



    this.TotalAmountAfterDiscount = this.TotalPayableAmount - this.discountamt;
    // this.TDSInAmount= this.TotalAmountAfterDiscount*(this.TDSInPercent/100);

    this.TDSInAmount = 0;
    this.TDSInPercent = 0;
    this.amtAfterTds = this.discountamt - this.TDSInAmount;



    this.UserID = localStorage.getItem('UserID');

    this.venderidsession = localStorage.getItem('VendorId');
    
    // this.TotalPayableAmount =this.amtAfterTds+this.ctaxamount+this.staxamount;


    console.log(this.dynamicArray);

 
 
      // this.visibleloader = true; 
    const formData = new FormData();

 
    
    
    
    
    formData.append('PurchaseID', '0');


    if(this.PurchaseFrom=='Vendor'){


      console.log('FromVendor')

      formData.append('PurchaseFrom', 'Vendor');//for org Organization
      formData.append('FromVendorID', this.purchaseform.get('VendorID').value);
  
      formData.append('FromOrgID', '0') ;
      formData.append('FromDivID', '0');
      formData.append('FromDeptID', '0');

    }


    if(this.PurchaseFrom=='Organization'){

      console.log('FromOrganization')
      formData.append('PurchaseFrom', 'Organization');//for org Organization
      formData.append('FromVendorID','0');
  
      formData.append('FromOrgID', this.purchaseform.get('UserOrgID').value) ;
      formData.append('FromDivID', this.purchaseform.get('UserDivID').value);
      formData.append('FromDeptID', this.purchaseform.get('UserDeptID').value);

    }



    formData.append('ToOrgID', this.OrganizationID);
    formData.append('ToDivID', this.InstitutionID);
    formData.append('ToDeptID', this.DepartmentID);
 
 

    formData.append('PurchaseInvoiceNumber', this.purchaseform.get('invno').value);
    
    formData.append('PurchaseInvoiceUpload', this.purchaseform.get('InvoiceAttechmentURL').value);
    
    formData.append('PurchaseInvoiceDate', this.purchaseform.get('invdate').value);

    // formData.append('VendorID', this.purchaseform.get('VendorID').value);

    // formData.append('OrgID', this.purchaseform.get('UserOrgID').value);

    // formData.append('DivID', this.purchaseform.get('UserDivID').value);

    // formData.append('DeptID', this.purchaseform.get('UserDeptID').value);



    formData.append('PurchaseType',this.purchaseform.get('purchasetype').value);


console.log('this is sale pr9ice',this.SalePrice);

    this.itemdetailarray=this.dynamicArray.map((d:any)=>{
      d['ProductID'] = parseInt(d.ProductID);
      d['BrandID']=parseInt(d.BrandID);
      d['PurchaseUnit']=parseInt(d.PurchaseUnit);
      d['PurchasePriceType']=parseInt(d.PurchasePriceType);
      d['PurchaseQuantity']=d.PurchaseQuantity.toString();
      d['PurchasePrice']=d.PurchasePrice.toString();

      if(this.SalePrice!=''){
        d['SalePrice']=d.SalePrice.toString();
      }
      if(this.MRP!=''){
        d['MRP']=d.MRP.toString();
      }
      if(this.WholesalePrice!=''){
        d['WholesalePrice']=d.WholesalePrice.toString();
      }
      d['TaxableAmount']=d.TaxableAmount.toString();
      d['TaxPercentage']=d.TaxPercentage.toString();
      d['TaxAmount']=d.TaxAmount.toString();
      d['TotalAmount']=d.TotalAmount.toString();
      return d;
  })

console.log('this.itemdetail array',this.itemdetailarray);
    
  formData.append('PurchaseDetailJson', JSON.stringify(this.itemdetailarray));


 



  formData.append('TaxableAmountGrandTotal', this.totalwithoutgst.toString());

  formData.append('TaxAmountGrandTotal', this.taxamount.toString());

  formData.append('GrandTotal', this.TotalPayableAmount.toString());



  formData.append('DiscountAmount', this.discountamt.toString());



  formData.append('DiscountPercent', this.discount.toString());

  formData.append('TDSAmount', this.TDSInAmount.toString());

  formData.append('TDSInPercent', this.TDSInPercent.toString());


  formData.append('FrightChargeAmount', this.frightcharge.toString());

  formData.append('FrightChargePercentage', this.frightcharge.toString());

  formData.append('RoundOff','0');
  formData.append('PayableAmount', this.TotalPayableAmount.toString());

  formData.append('ReceivedAmount', '0');
  //  formData.append('TotalAmountAfterDiscount',  this.TotalAmountAfterDiscount.toString());
    formData.append('BalanceAmount',  this.TotalPayableAmount.toString());
  

 
    
  
 

 

    // if (this.form.valid) {

    let registerurl = "/purchase/purchaseregistration";
   this.http.post(this.ServiceUrl + registerurl, formData)
    .subscribe((res: any) => {
     if (res.purchaseregistrationResult.Message == "Sucess") {


      this.visibleloader = false;
       this.route.navigate(['/purchase_list'])
       localStorage.setItem('insertdata', 'Success');
      }
     else{
        // alert('Uploaded Successfully.');
     }

    })

  } else {
    this.validateAllFormFields(this.purchaseform);
  }

  }

  addRow() {



    console.log(this.dynamicArray);
    this.dynamicArray.push({
      PurchaseDetailID: 0,
      ProductID: '',
      BrandID: '',
      PurchaseUnit: '',
      PackedDate: '',
      ExpiryDate: '',
      Description: '',
      PurchasePriceType: '',
      PurchaseQuantity: '',
      PurchasePrice: '',
      SalePrice: '',
      MRP: '',
      WholesalePrice: '',
      TaxableAmount: '' ,
      TaxPercentage: '',
      TaxAmount: '',
      TotalAmount: '' 
   
    });


  }


  AddItem(){
 
      this.sidebarVisible2 = true;
 
 }


SubmitItemDetail(){
console.log('this ', this.ProductID);

console.log('brand',this.BrandID);
    this.dynamicArray.push({
      PurchaseDetailID: 0,
      ProductID: this.ProductID.value.ItemID,
      ProductName: this.ProductID.label,
      BrandID: this.BrandID.BrandID,
      BrandName: this.BrandID.BrandName,
      PurchaseUnit: this.PurchaseUnit,
      PackedDate: this.PackedDate,
      ExpiryDate:this.ExpiryDate,
      Description: this.Description,
      PurchasePriceType: this.PurchasePriceType,
      PurchaseQuantity: this.PurchaseQuantity,
      PurchasePrice:this.PurchasePrice,
      SalePrice: this.SalePrice,
      MRP: this.MRP,
      WholesalePrice: this.WholesalePrice,
      TaxableAmount: this.TaxableAmount ,
      TaxPercentage: this.TaxPercentage,
      TaxAmount:this.TaxAmount,
      TotalAmount:this.TotalAmount 
   
    }); 
      this.ProductID='';
      this.BrandID='';
      this.PurchaseUnit='';
      this.PackedDate='';
      this.ExpiryDate='';
      this.Description='';
      this.PurchasePriceType='';
      this.PurchaseQuantity='';
      this.PurchasePrice='';
      this.SalePrice='';
      this.WholesalePrice='';
      this.TaxableAmount='';
      this.TaxPercentage='';
      this.TaxAmount='';
      this.TotalAmount=''; 
      this.MRP=''; 


this.sidebarVisible2 = false;

this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Purchase', life: 3000});
    
}

 
  deleteRow(index) {

    // this.dynamicArray.splice(index, 1);

    if (this.dynamicArray.length == 1) {
      // this.toastr.error("Can't delete the row when there is only one row", 'Warning');
      alert("Cant Not Delete");
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      // this.toastr.warning('Row deleted successfully', 'Delete row');
      alert(" Deleted Row");
      return true;
    }
  }






  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  getDivisionByOrg(e) {
    this.selectedOrgId = e.value.OrgID;
    this.selectedOrgName = e.value.OrgName;
    this.selectedDivName = 'Please Select Division';
    this.selectedDeptName = 'Please Select Department';
    this.billdeskService.getDivisionByOrgListForInvoice(e.value.OrgID).then(res => this.divisionlist = res);
  }




 

  changeDivision(e) {
    this.selectedDivName = e.value.DivName;


    this.selectedDiv = e.value.DivID;
    const orgId = e.value.OrgID;
    const divId = e.value.DivID;
    this.selectedDeptName = 'Please Select Department';
    this.billdeskService.getDepartmentlistforinvoice(orgId, divId).then(res => this.departmentlist = res);
  }









  changeDepartment(e) {

    this.selectedDeptName = e.value.DeptName;
    var DeptID = e.value.DeptID;
    this.selectedDeptName;
    this.selectedDeptID = e.value.DeptID;
    this.billdeskService.getDepartmentByid(DeptID).then(res => this.departmentdetailbyselection = res);
  }

// // this code for dynamic to organization 
//     getDivisionByToOrg(e) {


//     this.selectedToOrgId = e.value.OrgID;
     
//     this.selectedToOrgName = e.value.OrgName;
//     this.selectedToDivName = 'Please Select Division';
//     this.selectedToDeptName = 'Please Select Department';
//     this.billdeskService.getDivisionByOrgListForInvoice(e.value.OrgID).then(res => this.divisionlist = res);
//   }  
//   changeToDivision(e) {
//     this.selectedToDivName = e.value.DivName;


//     this.selectedToDiv = e.value.DivID;
     
//     const orgId = e.value.OrgID;
//     const divId = e.value.DivID;
//     this.selectedToDeptName = 'Please Select Department';
//     this.billdeskService.getDepartmentlistforinvoice(orgId, divId).then(res => this.departmentlist = res);
//   }  


//   changeToDepartment(e) {

//     this.selectedToDeptName = e.value.DeptName;
//     var DeptID = e.value.DeptID;
//     this.selectedDeptName;
//     this.selectedToDeptID = e.value.DeptID
     
//     this.billdeskService.getDepartmentByid(DeptID).then(res => this.departmentdetail = res);
//   }   


  changeBrand(e) {

  this.selectBrandID = e.value.BrandID;
 
  this.selectBrandDname = e.value.BrandName;
   
}

  getValue(rate: any, qty: any, taxper: any, i: any) {
    //do your stuff

    this.withouttax = rate * qty;




    // dynamic.TaxPercentage/100)*dynamic.Quantity*dynamic.Rate 
    this.dynamicArray[i].TaxAmount = (taxper / 100) * qty * rate;
    this.dynamicArray[i].TotalAmount = rate * qty + this.dynamicArray[i].TaxAmount;


    this.dynamicArray[i].TaxableAmount=rate*qty;
  }

  getValueform(rate: any, qty: any, taxper: any, i: any) {
    //do your stuff

    this.withouttax = rate * qty;




    // dynamic.TaxPercentage/100)*dynamic.Quantity*dynamic.Rate 
    this.TaxAmount = (taxper / 100) * qty * rate;
    this.TotalAmount = rate * qty + this.TaxAmount;


    this.TaxableAmount=rate*qty;
  }


  // validation functionality start from here  
  isFieldValid(field: string) {
    return !this.purchaseform.get(field).valid && this.purchaseform.get(field).touched;
  }


  isFieldValidBrand(field: string) {
    return !this.brandform.get(field).valid && this.brandform.get(field).touched;
  }




  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  displayFieldCssBrand(field: string) {
    return {
      'has-error': this.isFieldValidBrand(field),
      'has-feedback': this.isFieldValidBrand(field)
    };
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {

      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  
  

  ProductDialogButton() {

    this.ProductDialog = true;

  }


  BrandDialogButton() {

    this.BrandDialog = true;

  }



  
  ProductRegister() {
    var obj1 =
    {

      "flag": "MSTItemCatagory_INSERT",
      "ItemID": "0",
      "CreatedBy": "2",
      "CreatedByID": "4",
      "UpdatedDate": "10/22/21",
      "UpdatedBy": "",
      "ItemType": "",
      "UnderOfParentID": "",
      "ItemAbstract": "",
      "ItemDescription": "",
      "FAQListID": "",
      "ItemImageUrl": "",
      "ItemImageUrl2": "",
      "ItemImageUrl3": "",
      "ItemImageUrl4": "",
      "ItemVideoSource": "",
      "ItemVideoUrl": "",
      "ItemVisibilityMode": "",
      "ActiveStatus": "",
      "MetaTitle": "",
      "MetaKeyWord": "",
      "MetaDiscription": "",
      "SchemaCodes": "",
      "OGTags": "",
      "UrlOptimization": "",
      "FilterValue1": "",
      "FilterValue2": "",
      "WholeSalePriceMax": "",
      "WholeSalePriceMin": "",
      "ItemUnitPurchasePrice": "",
      "ItemUnitSalesPrice": "",
      "MSP": "",
      "MRP": "",
      "ItemDiscountInPercentage": "",
      "ItemDiscountInAmount": "",
      "Tax1Amount": "",
      "Tax2Amount": "",
      "GST": "",
      "NetPayAmount": "",
      "CoopenCode": "",
      "GSTInPercentage": "",
      "OpeningStock": "",
      "AsofDate": "",
      "IsLowStockWarning": "",
      "LowStockQuantity": "",
      "ItemCode": "",
      "Barcode": "",
      "ItemBrandName": "",
      "ItemMedium": "",
      "ItemSize": "",
      "ItemWorkType": "",
      "ItemBase": "",
      "ItemColor": "",
      "ItemMaterial": "",
      "ItemEntryDate": "",
      "ItemAddedBy": "",
      "ItemToTopTen": "",
      "ItemToHome": "",
      "DisplayOrder": "",
      "Comments": "",
    };
    var obj2 = this.productform.value;
    var form_data = Object.assign(obj1, obj2);
    console.log("form_data");
    console.log(form_data);
    const datarequest = {
      "CheckSum": "",
      "lead_id": "",
      "ActionFor": "Insert",
      "dataobject": [

        form_data

      ]
    }
      ;


    //  if (this.productform.valid) { 
    let registerurl = "/item/itemregistration";
    this.billdeskService.RegisterData(registerurl, datarequest).subscribe((res: any) => {

      if (res.itemregistrationResult.Message == 'Sucess') {
 
        this.route.navigate(['/purchase_entry'])
        this.ProductDialog = false;

        
        this.openDialogProduct();
        this.billdeskService.getItemCategoryCombinelist().then((res:any) =>{
          // this.itemcategorylist = res
          this.ProductListByGroup = res.map(g => {
            return { label: g.ItemCategoryName,value:g.ItemID, items: g.SubMenuList.map(i => ({ label: i.ItemName, value: i })) };
        });
        console.log('DDDDDXXX:::',this.ProductListByGroup );
    })
      
      } else {
        //  console.log('Please Try Again Later');
      }
    });





    //  } else {
    //  this.validateAllFormFields(this.form);
    //  }
  }


  BrandRegister() {
    var obj1 = 
     {
        
       "BrandID" :0,
     
     };
 var obj2 = this.brandform.value;
 var form_data = Object.assign(obj1, obj2);
         console.log("merge");
         console.log(form_data);
         console.log("merge"); 
         console.log(this.brandform);
         console.log(this.brandform.value.flag);
         
 const datarequest={
   "CheckSum": "",
    "lead_id": "",
 "ActionFor": "Insert",            
 "dataobject":[
   
   form_data
 
 ]
  }
 ;
 console.log("datarequest1");
 console.log(datarequest);
 console.log("datarequest1");
  console.log(this.form);
  
  
  if (this.brandform.valid) {
 
 
   this.visibleloader=true; 
    console.log('form submitted');
  let registerurl= "/brand/brandregistration";
 this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
   console.log("responce data");
   console.log(res);
   console.log("responce data");
    console.log(res.brandregistrationResult);
   if(res.brandregistrationResult.Message=='Sucess'){
    console.log('Insert Success');
    this.route.navigate(['/purchase_entry'])
    this.BrandDialog = false;
    this.visibleloader=false;
    this.openBrandProduct();
    localStorage.setItem('insertdata', 'Success');
    this.billdeskService.getBrandListDLL().then(res => this.brandlist = res)
   }else{
    console.log('Please Try Again Later');
  }
  });
 } else {
    this.validateAllFormFields(this.brandform);
  }
   }
 


  openDialogProduct(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Product", Detail: "Registered Successfully", Backgroundcolor: "#47c9a2" }
    });

    dialogRef.afterClosed().subscribe(result => {



      this.dialogdata = result;

      console.log("this.dialogdata");

      console.log(this.dialogdata);
    });
  }


  openBrandProduct(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Brand ", Detail: "Registered Successfully", Backgroundcolor: "#47c9a2" }
    });

    dialogRef.afterClosed().subscribe(result => {



      this.BrandDialog = result;

      console.log("this.dialogdata");

      console.log(this.BrandDialog);
    });
  }


}








// same sa new page

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor: string;
}