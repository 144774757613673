import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';

import { ApiserviceService } from '../api/apiservice.service';
@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {
  //Create FormGroup 


  
  selectedOrgId:string;
  mymodel='';

  mobilenumber:any;
  selectedOrgName:string='Please Select Organization';
  mobilevalidata:any;
  selectedDivName:string='Please Select Division';
  selectedDiv:string;

  selectedDeptID:string;
  selectedDeptName:string='Please Select Department';


  selectedRoleID:string;
  selectedRoleName:string='Please Select Role';

  
  


  organizaionlist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];
  rolelist:Billdesk[];
  departmentlist:Billdesk[];
  divisionlist:Billdesk[];
  cityList:Billdesk[];


  type: string;

  // stateList:any[];
  UserMobileNo:any;
  // cityList:any[];
  selectedCity: string;

  selectedCityName:string='Please Select City';
  
  selectedStateName: string='Please Select State';
  StateID:number;
  DistrictID:number;
  selectedState: string;
  name:any;
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef ,private api:ApiserviceService) {
    this.mobilevalidata='';
    console.log('orgiststart');


    // console.log(this.billdeskService.getOrganization().then(res => this.organizaionlist = res));
    // console.log('stateliststart'); 
    console.log('orgiststart');


    console.log(this.billdeskService.getOrganizationsListDDL().then(res => this.organizaionlist = res));

    console.log(this.billdeskService.getState().then(res => this.stateList = res));
    
    console.log(this.billdeskService.getUserRole().then(res => this.rolelist = res));
 
    
    console.log('statelistend');
    this.name = 'Nancy';
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  
  ngOnInit() {
    this.form = this.fb.group({  
      RoleTypeID: [null, Validators.required],
      UserOrgID: [null, Validators.required],
      UserCode:[null, Validators.required],
      UserDivID: [null, Validators.required],
      UserDeptID: [null, Validators.required],
      UserFullName: [null, Validators.required],
      UserEmailID:[null, Validators.required],
      UserMobileNo: [null,  Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$") ],
      UserAddress: [null, Validators.required],
      UserCity: [null, Validators.required],
      UserState: [null, Validators.required],
      UserPinCode: [null, Validators.required],
      ActiveStatus: [null, Validators.required],
      // VerificationEmailOTP: [null, Validators.required],
      // IsEmailVerified: [null, Validators.required],
      // VerificationMobileOTP: [null, Validators.required],
      // IsMobileVerified: [null, Validators.required],
      UserPassword: [null, Validators.required],
      UserLoginID: [],
      Remark: [null, Validators.required] 


    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) { 
    console.log(e); 
    // this.form.controls['State'].setValue(e.value.StateID); 
    
    
    this.selectedState=e.value.StateID;
    
    
    this.selectedStateName=e.value.StateName;
    console.log(this.billdeskService.getCity(e.value.StateID).then(res => this.cityList = res));
    
      }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
        "flag":"",
        "UserID":"0",
       
"CreatedDate":"10/13/2021",
"CreatedBy":"2",
"UpdatedDate":"",
"UpdatedBy":"",
"VerificationEmailURL":"",
"VerificationEmailOTP":"",
"IsEmailVerified":"",
"VerificationMobileOTP":"",
"IsMobileVerified":"",
     
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);



console.log("datarequest1");
console.log(this.form);


if (this.form.valid) {
  console.log('form submitted');
 

let registerurl= "/userprofile/userprofileregistration";
this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{



  console.log("depin");
  console.log(res);
  console.log("depin");
    console.log(res.userprofileregistrationResult);
  if(res.userprofileregistrationResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/User-List'])
   localStorage.setItem('insertdata', 'Success');
 }else{
   console.log('Please Try Again Later');
 }


if(res.userprofileregistrationResult.Status=='400'){
alert(res.userprofileregistrationResult.Message);
}

 

 });



} else {
  this.validateAllFormFields(this.form);
}
 }


 
 getDivisionByOrg(e){
  this.selectedOrgId=e.value.OrgID;
   this.selectedOrgName=e.value.OrgName; 
console.log(this.billdeskService.getDivisionByOrgbYDLLList(e.value.OrgID).then(res => this.divisionlist = res));
}
changeDivision(e){
 console.log(e); 
  this.selectedDivName=e.value.DivName;


 console.log(this.selectedDivName);
     this.selectedDiv=e.value.DivID;

       const orgId=e.value.OrgID;
  console.log(orgId);
  const divId=e.value.DivID;
//test team
//data
console.log("DIVISION AND DEPARTMENT");
  console.log(divId);
  console.log(orgId);
  console.log(this.billdeskService.getDepartmentListDLLByOrgAndDivId(orgId,divId).then(res => this.departmentlist = res));
  console.log('stateliststart'); 
    }


changeRole(e){
console.log(e); 
  this.selectedRoleName=e.value.RoleTypeName;
console.log(this.selectedRoleName);
    this.selectedRoleID=e.value.RoleTypeID;
    }  


changeDepartment(e){
console.log(e); 
  this.selectedDeptName=e.value.DeptName;
console.log(this.selectedDeptName);
    this.selectedDeptID=e.value.DeptID;
    } 
    
    
     changeCity(e) {

console.log(e); 
 
this.selectedCityName=e.value.DistrictName;



console.log(this.selectedCityName);
    this.selectedCity=e.value.DistrictID;

 
//  this.form.controls['City'].setValue(e.value.DistrictID); 
// console.log(e);
  }

 

validateMobile(mobile){
 this.form.controls['UserLoginID'].setValue(mobile); 
 var uname = new String(mobile) ;
  if(uname.length==10){
    var request="/userprofile/checkuserloginid?UserLoginID="+mobile+"&ChkUserID=0";
    var response="checkuserloginidResult"; 
        this.api.geExistData(request,response).then((res:any) =>{
  if(res.Status==200){
      this.mobilevalidata = false;
    }else{
      this.mobilevalidata = true;
        }}); 
   }
  }
  
 
 }
 