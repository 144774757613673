 

<div class="card"  >

 

    <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="[
        'InvoiceID',
        'VendorInvoiceNo', 
        'CreatedDate',
        'ValidityExpireOn',
        'InvoiceName',
        'VendorName',
        'Headname',
        'invoiceForName',
        'TotalPayableAmount',
        'itemcategoryname',
        'OrgName',
        'DivName',
        'DeptName',
        'InvoiceHeadItemName'
            ]" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"    styleClass="p-datatable-customers"    responsiveLayout="scroll"   > 

 

        <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 style="text-align:center;margin-top: 10px;margin-bottom: 20px;font-weight: bold;color:#2459a6;">
                    INVOICE LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />

                </span>
            </div>
        </ng-template>


        <ng-template pTemplate="header">
            <tr class="padding-head">

                <th pSortableColumn="rating" style="width:120px;
                position: sticky;
                left: 0;
                z-index: 2;background:#f8f9fa;"> Action  </th>

                <th pSortableColumn="inventoryStatus" style="width: 100px"> Invoice Stage <p-sortIcon
                        field="inventoryStatus"></p-sortIcon></th>
                <th pSortableColumn="InvoiceID" style="width: 100px">Bill Id.<p-sortIcon field="InvoiceID"></p-sortIcon>
                </th>
                <th pSortableColumn="VendorInvoiceNo" style="width: 100px">Invoice No.<p-sortIcon
                        field="VendorInvoiceNo"></p-sortIcon></th>
                <th pSortableColumn="PriorityName" style="width: 100px">Priority<p-sortIcon
                        field="PriorityName"></p-sortIcon></th>
                <th pSortableColumn="CreatedDate" style="width: 100px">Invoice Date<p-sortIcon
                        field="CreatedDate"></p-sortIcon></th>
                <!-- <th pSortableColumn="ValidityExpireOn" style="width: 150px">ValidityExpireOn<p-sortIcon
                        field="ValidityExpireOn"></p-sortIcon></th> -->
                <th pSortableColumn="InvoiceHeadItemName" style="width: 100px">Inv Purpose<p-sortIcon
                    field="InvoiceHeadItemName"></p-sortIcon></th>
                <th pSortableColumn="ItemName" style="width: 100px"> Item Name <p-sortIcon
                        field="ItemName"></p-sortIcon></th>
                <th pSortableColumn="InvoiceName" style="width: 100px">Remark<p-sortIcon
                        field="InvoiceName"></p-sortIcon></th>
                <th pSortableColumn="VendorName" style="width: 100px">Vendor Name <p-sortIcon
                        field="VendorName"></p-sortIcon></th>
                <th pSortableColumn="TotalPayableAmount" style="width: 100px">Payable Amount<p-sortIcon
                        field="TotalPayableAmount"></p-sortIcon></th>
                <!-- <th pSortableColumn="paymentstatus" style="width: 100px">Payment Status<p-sortIcon
                        field="paymentstatus"></p-sortIcon></th> -->

                <th pSortableColumn="Document" style="width: 100px">Inv. Doc.<p-sortIcon
                        field="Document"></p-sortIcon></th>
                <!-- <th pSortableColumn="InvoiceHeadItemName" style="width: 180px">Head Name<p-sortIcon
                        field="InvoiceHeadItemName"></p-sortIcon></th> -->

                <th pSortableColumn="InvoiceForName" style="width: 100px">Inv. For Cycle<p-sortIcon
                        field="InvoiceForName"></p-sortIcon></th>

                <th pSortableColumn="ItemCategoryName" style="width: 100px">Item Category Name<p-sortIcon
                        field="ItemCategoryName"></p-sortIcon></th>

                <th pSortableColumn="OrgName" style="width: 100px">Organization Name<p-sortIcon
                        field="OrgName"></p-sortIcon></th>
                <th pSortableColumn="DivName" style="width: 100px"> Division Name <p-sortIcon
                        field="DivName"></p-sortIcon></th>
                <th pSortableColumn="DeptName" style="width: 100px"> Department Name <p-sortIcon
                        field="DeptName"></p-sortIcon></th>


            </tr>


            <tr class="padding-head">
                <th  style="width:120px;
                position: sticky;
                left: 0;
                z-index: 2;background:#f8f9fa;">


                </th>
                <th>

                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'StageName', 'contains')"
                        styleClass="p-column-filter" placeholder="Select a Status" [showClear]="true"  >
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.value&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>




                </th>

                <th >

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceID' ,'contains')"
                        placeholder="  Bill ID"  style="width: 100px;"/>
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorInvoiceNo' ,'contains')"
                        placeholder="Invoice No."  style="width: 150px;" />
                </th>

                <th>
                    <p-dropdown [options]="pririty" (onChange)="dt.filter($event.value, 'PriorityName', 'contains')"
                        styleClass="p-column-filter" placeholder="Select a Priority" [showClear]="true" style="width: 100px;">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.value&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>


                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')"
                        placeholder="Invoice Date"   style="width: 150px;"  />
                </th>
                <!-- <th>
                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'ValidityExpireOn' ,'contains')"
                        placeholder="Validity Expire On"    style="width: 150px;" />
                </th> -->


                <th>
                    <p-dropdown [options]="InvoiceHeadItemNameList" (onChange)="dt.filter($event.value, 'InvoiceHeadItemName', 'contains')"
                        styleClass="p-column-filter" placeholder="Select a Purpose" [showClear]="true" style="width: 100px;">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.value&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>


                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'ItemName' ,'contains')"
                        placeholder="Item Name" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'InvoiceName' ,'contains')"
                        placeholder="Invoice Name" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorName' ,'contains')"
                        placeholder=" Vendor Name" />
                </th>
                <th>
                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'TotalPayableAmount' ,'contains')"
                        placeholder="Payable Amount"   style="width: 150px;" />
                </th>
                <!-- <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'paymentstatus' ,'contains')"
                        placeholder="Payment Status" style="width: 100px" />
                </th> -->
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'Document' ,'contains')"
                        placeholder="Doc." readonly style="width: 100px" />
                </th>
                <!-- <th>
                    <p-dropdown [options]="itemhead"
                        (onChange)="dt.filter($event.value, 'InvoiceHeadItemName', 'contains')"
                        styleClass="p-column-filter" placeholder="Select a Head Name" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.value&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>
                </th> -->
                <th>
                    <p-dropdown [options]="invforcycle" (onChange)="dt.filter($event.value, 'InvoiceForName', 'contains')" styleClass="p-column-filter"
                        placeholder="Invoice For" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.value&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown> 
                </th>
                <th>
                    <p-dropdown [options]="itemcategory"
                        (onChange)="dt.filter($event.value,'ItemCategoryName', 'contains')" styleClass="p-column-filter"
                        placeholder="Item Cat." [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.value&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'OrgName' ,'contains')"
                        placeholder="Org Name" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DivName' ,'contains')"
                        placeholder="Div Name" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DeptName' ,'contains')"
                        placeholder="Dept Name" />
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">
                <td style="width:120px;
                position: sticky;
                left: 0;
                z-index: 2;background:#ffffff;" class="col1">


                    <span class="p-column-title">Action </span>


                    <!-- <span style="display:flex;">
                        <a [routerLink]="['/Edit-Invoice-Registration',product.InvoiceID]"><button pButton pRipple
                                icon="pi pi-pencil" class="p-button-success p-mr-2"
                                style="margin-right: 4px; background:#f09639;border:#f09639"
                                pTooltip="Edit Invoice Detail"></button>
                        </a>
                        <a [routerLink]="['/View-Invoice',product.InvoiceID]">


                            <button pButton pRipple icon="pi pi-eye" class="p-button-info"
                                pTooltip="View Invoice Detail" style="background:#2459a6;border:#2459a6;">

                            </button>
                        </a>
                    </span> -->
                    <span class="buttonsres" >
                        <a [routerLink]="['/Edit-Invoice-Registration',product.InvoiceID]" class="mr-1 fs-2"><i class='fa fa-edit'  style='color: #2459a6;'   pTooltip="Edit Invoice Detail" ></i></a>
                        <a [routerLink]="['/View-Invoice',product.InvoiceID]">   <span  pTooltip="View Invoice Detail"> <i class='fa fa-eye'  style='color: #f09639; zoom: 1.1;
                        border: 2px solid;
                        border-radius: 5px;'   ></i> </span>

                        </a>
                     </span>
                    

                </td>
                <td class="col2">
                     <span class="p-column-title">Invoice Stage </span>
                    {{product.StageName}}
                 </td>
                <td>
                     <span class="p-column-title">Bill Id</span>
                    {{product.InvoiceID}}
                </td>
                <td>
                    <span class="p-column-title">Invoice No.</span>
                    {{product.VendorInvoiceNo}}
                </td>
                <td>
                    <span class="p-column-title">Priority</span>
                    {{product.PriorityName}}
                </td>
                <td>
                     <span class="p-column-title">Invoice Date</span>
                    {{product.InvoiceDate}}
                </td>
                <!-- <td>
                     <span class="p-column-title">ValidityExpireOn</span>
                    {{product.ValidityExpireOn}}
                </td> -->
                <td>
                    <span class="p-column-title">Purpose</span>
                    {{product.InvoiceHeadItemName}}
                </td>

                
                <td>
                     <span class="p-column-title">Item Name</span>
                      {{product.ItemName}}  
                </td>
                <td>
                    <span class="p-column-title">Remark</span>
                    {{product.InvoiceName}}
                </td>
                 <td>
                    <span class="p-column-title">Vendor Name </span>
                    {{product.VendorName}}
                </td>
                <td>
                    <span class="p-column-title">Net Payable Amount</span>
                    {{product.TotalPayableAmount}}
                </td>
                <!-- <td class="col2">
                     <span class="p-column-title">Payment Status</span>
                    <div *ngIf="product.RespMessage=='success'; else elseBlock" style="color:green">Paid</div>
                    <ng-template #elseBlock><span style="color: #ec5729;">Unpaid</span></ng-template>
                </td> -->
                <td>
                    <span class="p-column-title">Invoice Document</span>
                    <a href="https://billdeskapi.aisect.org{{product.InvoiceAttechmentURL}}" target="_blank"><i
                            class="fa fa-file-pdf-o" style="font-size:32px;color:red"
                            title="Click For Open Document"></i>
                    </a>
                </td>
                <!-- <td>
                     <span class="p-column-title">Head Name</span>
                    {{product.InvoiceHeadItemName}}
                </td> -->
                <td>
                    <span class="p-column-title">Invoice For Cycle</span>
                    {{product.InvoiceForName}}
                </td>
                <td>
                    <span class="p-column-title">Item Category Name</span>
                    {{product.ItemCategoryName}}
                </td>
                <td>
                    <span class="p-column-title">Organization Name</span>
                    {{product.OrgName }}
                </td>
                <td>
                     <span class="p-column-title">Division Name</span>{{product.DivName}}
                </td>
                <td>
                     <span class="p-column-title"> Department Name </span>
                    {{product.DeptName}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary" style="display:flex;">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '800px'}" header="Organization Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-field">


            <div class="row">


                <div class="col-md-3">



                    <label for="Organization_Name" class="col-form-label">Organization Id :</label><br>


                    <label for="Organization_Name" class="col-form-label">Organization Name:</label><br>


                    <label for="PANCard" class="col-form-label">PANCard:</label><br>


                    <label for="GSTN" class="col-form-label">GSTN:</label><br>



                    <label for="Contact_Number" class="col-form-label">Contact Number:</label><br>
                    <label for="Emailid" class="col-form-label">Email </label><br>




                    <label for="Address" class="col-form-label">Address </label><br>





                    <label for="City" class="col-form-label">City </label><br>



                    <label for="State" class="col-form-label">State </label><br>



                    <label for="PinCode" class="col-form-label">PinCode</label><br>





                </div>
                <div class="col-md-3">
                    <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].OrgID}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].OrgName}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].PANNo}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].GSTN}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].DeskMobileNo}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].DeskEmailID}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].BillingAddress}}
                    </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].City}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].State}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].PinCode}} </label><br>

                </div>


                <div class="col-md-3">


                    <label for="ContactPersonName" class="col-form-label">Contact Person Name </label><br>




                    <label for="ContactPersonNo" class="col-form-label">Contact Person No </label><br>

                    <label for="accountname" class="col-form-label">Account Name </label><br>

                    <label for="BanckAccountNo" class="col-form-label">Banck Account No </label><br>




                    <label for="bankname" class="col-form-label">Bank Name </label><br>



                    <label for="BanckBranch" class="col-form-label">Banck Branch </label><br>



                    <label for="BankIFSCCode" class="col-form-label">Bank IFSC Code </label><br>


                    <label for="BankIFSCCode" class="col-form-label">Created Date </label><br>


                    <label for="BankIFSCCode" class="col-form-label">Created By </label>
                    <br>
                    <label for="BankIFSCCode" class="col-form-label">Updated Date </label>


                    <label for="BankIFSCCode" class="col-form-label">Updated By </label><br>




                </div>

                <div class="col-md-3"> <label for="Organization_Name"
                        class="col-form-label">{{productsbyid[0].AuthPersonName}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].AuthPersonMobileNo}}
                    </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].BankAcName}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].BankAcNo}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].BankName}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].BankBranchName}}
                    </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].IFSCCode}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].CreatedDate}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].CreatedBy}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].UpdatedDate}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].UpdatedBy}} </label><br>



                </div>
            </div>



        </div>


        <br>

        <div class="p-field">


            <label class="p-mb-3" style="display: flex;">Status: &nbsp;&nbsp;&nbsp; <p-radioButton id="category1"
                    name="category" value="Accessories" [(ngModel)]="product.category"></p-radioButton>
                &nbsp;
                <label for="category1">Active</label>
                &nbsp;
                <p-radioButton id="category2" name="category" value="Clothing"
                    [(ngModel)]="product.category"></p-radioButton>

                &nbsp;
                <label for="category2">Deactive</label></label>

            <br>



        </div>
    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">



        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Organization Deleted Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>


<p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Status Updated Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>
</p-dialog>