import { Component, OnInit , ViewChild, ElementRef,  QueryList,
  VERSION,AfterViewInit,
  ViewChildren} from '@angular/core';

import { HttpClient} from "@angular/common/http"; 

import { Router } from '@angular/router';

import {timer} from 'rxjs';
 import { environment } from '../../environments/environment';
 import { BilldeskService } from '../billdesk.service';
import { event } from 'jquery';
// import { NotificationService } from '../notification.service'; 

 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
}) 


export class LoginComponent implements OnInit {
  // @ViewChild("otp1") myInputField: ElementRef;


  // @ViewChild('otp1') firstNameField: ElementRef<HTMLInputElement>;

  // @ViewChildren('otp1') searchInput: QueryList<ElementRef>;
LoginUrl=environment.baseUrl;
    f = false;
  title="Contact US";
  res:any;
  status:any;
  public message:any;
  localValue:any;
  isShow = true;
  isResend = true;

  isOtp = false;

  isError=true;
  isErrorBlank=true;
  mobile2:any;
  isOtpInvalidError=true;

  isOtpEmptyError=true;
  submitted = false;
  registerForm:any;
   
  DepartmentID:any;
  myTextBox:any;
  timeData = "120"
  config:any;
  showStop:boolean;
  data:number=0;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  buttontype = 'submit';
  buttontype1 = 'button';
  buttontype2 = 'button';
 
  // postdata:any[];

  
  // name = "Angular " + VERSION.major;
  // public datadetail : any = [
  //   { name: "Adam" },
  //   { name: "Eve" },
  //   { name: "Lucifer" },
  //   { name: "Amenadiel" },
  //   { name: "Bob" },
  //   { name: "Sky" }
  // ];
  
  // public selectedValue: any;
  // public searchValue: any;
  // public filteredList: any = [];



  constructor(private Http: HttpClient,private route : Router,private billdesk:BilldeskService ) { 





    // this.myTextBox = document.getElementById('mobile');
    // this.myTextBox.focus();
    // console.log(this.myTextBox);
 

    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.servicetost.showSuccess('sumit','kumar');

    //this line for not reload process at time of navigate another page
  }
 
  // filterDropdown(e) {
  //   console.log("e in filterDropdown -------> ", e);
  //   window.scrollTo(window.scrollX, window.scrollY + 1);
  //   let searchString = e.toLowerCase();
  //   if (!searchString) {
  //     this.filteredList = this.datadetail.slice();
  //     return;
  //   } else {
  //     this.filteredList = this.datadetail.filter(
  //       user => user.name.toLowerCase().indexOf(searchString) > -1
  //     );
  //   }
  //   window.scrollTo(window.scrollX, window.scrollY - 1);
  //   console.log("this.filteredList indropdown -------> ", this.filteredList);
  // }

  // selectValue(name) {
  //   this.selectedValue = name;
  // }
  ngOnInit(): void {

    // this.filteredList = this.datadetail;
   
    this.config = {leftTime: this.timeData, format: 'mm:ss', demand:true};
    this.localValue=  localStorage.getItem('Login_local_session');
 
    if(this.localValue=='Success '){ 
      this.route.navigate(['/dashboard']).then(() => {
        window.location.reload();
      });
    }
    else{
      
    }
  }




 countdownconfig(){
  this.config = {leftTime:this.timeData,format: 'mm:ss', demand:false};
  this.showStop=true;
 }

sendOtp(data: any) {
 
  this.buttontype='button';
  this.buttontype1='submit';
  localStorage.setItem('mobile_resend',data.mobile);
  this.mobile2 =  localStorage.getItem('mobile_resend');
 

  this.countdownconfig(); 

   if(data.mobile==''){
    this.isErrorBlank=false;
    this.isError=true;
    return;
   }
 
    let callSendOTP = (res:any)=>{ 
      this.billdesk.GetDataToken("/userlogin/checkuserandsendotp?UserLoginID="+data.mobile).subscribe((response:any)=>{ 
        let responseObj =  response.checkuserandsendotpResult || {};

        if(responseObj.hasOwnProperty('Message') && responseObj.Message=="Sucess"){
          // alert('autofocus');
          this.isErrorBlank=true; 
          this.isShow = !this.isShow;
          this.isOtp=!this.isOtp;
          this.f = true
          console.log('Focust');
          // this.firstNameField.nativeElement.focus();
          // this.searchInput.first.nativeElement.focus();
          // this.searchInput.changes.subscribe(() => {
          // console.log('came here with changes: ', this.searchInput.first);
          //   if(this.isShow) {

           
          //     this.searchInput.first.nativeElement.focus();



              

          //   }
          // });
          this.resendotpwindow();
        }else{ 
          this.isError=false;
          this.isErrorBlank=true;
        }
      },
      (e:any)=>{

        // console.log('callSendOTP EEROR:::',e);
        
      });
    }

    let tokenstring: string = "c2VjdXJpdHlpc2dvb2Qh";  
    this.billdesk.access_token(tokenstring).subscribe(callSendOTP,callSendOTP); 
 

    }
 
resendotpwindow(){


const obs$=timer(5000,1000);
obs$.subscribe((d)=>{
  
this.data=d;
if(this.data==116){
this.isResend =false;
}
 
});




}


resendotp(data: any) {
  // localStorage.setItem('mobile_resend',data.mobile);
  this.mobile2 =  localStorage.getItem('mobile_resend');
 
  this.countdownconfig(); 

   if(this.mobile2==''){

    this.isErrorBlank=false;
    this.isError=true;
    return;
   }
  this.Http.get(this.LoginUrl+"/UserLogin?UserLoginID="+this.mobile2).subscribe((response:any)=>{
 
  if(response.MstUserListResult.message=="success"){ 
    this.f = true
   this.isError=true;

   this.isErrorBlank=true; 
   this.isShow =false;
    this.isOtp=true;
    this.resendotpwindow();
    }
    else{ 

      this.isError=false;

      this.isErrorBlank=true;
    }
      });

    }
    onClicklogin(data: any) {
      localStorage.removeItem('mobile_resend');  

      var otp=data.otp1;

      if(otp==null){
         this.isOtpEmptyError=false;
        return;
      }
      
      var otp1=data.otp2;
      
      if(otp1==null){
         this.isOtpEmptyError=false;
        return;
      }
      
      var otp2=data.otp3;
      
      if(otp2==null){
        this.isOtpEmptyError=false;
        return;
      }
      
      var otp3=data.otp4;
    
    
      if(otp3==null){
        this.isOtpEmptyError=false;
        return;
      }
      let totalotp = [data.otp1,data.otp2,data.otp3,data.otp4].join('');

      let callBackResponse = (response:any)=>{ 
        let responseObj = response.checkuserloginResult || {};

        if(responseObj.hasOwnProperty('Message') && responseObj.Message=="Sucess"){
          if(response.checkuserloginResult.Message=="Sucess"){ 
            localStorage.setItem('Login_local_session', 'Sucess'); 
           localStorage.setItem('Token', response.checkuserloginResult.Token);
           localStorage.setItem('LoginID', response.checkuserloginResult.dataobject[0].UserLoginID);
           localStorage.setItem('UserFullName', response.checkuserloginResult.dataobject[0].UserFullName);
           localStorage.setItem('UserCode', response.checkuserloginResult.dataobject[0].UserCode);  
           localStorage.setItem('RoleTypeName', response.checkuserloginResult.dataobject[0].RoleTypeName); 
           localStorage.setItem('UserOrgName', response.checkuserloginResult.dataobject[0].UserOrgName); 
           localStorage.setItem('UserDivName', response.checkuserloginResult.dataobject[0].UserDivName); 
           localStorage.setItem('UserDeptName', response.checkuserloginResult.dataobject[0].UserDeptName);  
          localStorage.setItem('UserDeptName', response.checkuserloginResult.dataobject[0].UserDeptName);   
           localStorage.setItem('UserID', response.checkuserloginResult.dataobject[0].UserID);
           localStorage.setItem('UserTypeID', response.checkuserloginResult.dataobject[0].RoleTypeID);
           localStorage.setItem('OrganizationID',response.checkuserloginResult.dataobject[0].UserOrgID);
           localStorage.setItem('InstitutionID',response.checkuserloginResult.dataobject[0].UserDivID);
           localStorage.setItem('UserFullName',response.checkuserloginResult.dataobject[0].UserFullName);
           localStorage.setItem('UserMobile',response.checkuserloginResult.dataobject[0].UserMobileNo);
            localStorage.setItem('DepartmentID', response.checkuserloginResult.dataobject[0].UserDeptID);
            localStorage.setItem('UserEmail', response.checkuserloginResult.dataobject[0].UserEmailID); 
           this.DepartmentID=  localStorage.getItem('DepartmentID'); 
           // });
            localStorage.getItem('UserTypeID'); 
             localStorage.setItem('isLogin', 'true');
           sessionStorage.setItem('Login_Status', 'Success session');
           localStorage.setItem('SeesionUser',"")  
                   this.route.navigate(['/dashboard'])
                     .then(() => {
                       window.location.reload();
                     }); 
                }
               else{ 
           
                 this.isOtpInvalidError=false;
           
                 this.isOtpEmptyError=true;
               }

        }




      }//EOF callBackResponse
      this.billdesk.GetDataToken("/userlogin/checkuserlogin?UserLoginID="+data.mobile+"&Password="+totalotp).subscribe(callBackResponse,callBackResponse);
    } //EOF onClicklogin
 

move(a:any,b:any,c:any,d:any){ 
  var length= c.value.length; 
  var maxlength= c.getAttribute('maxlength'); 
  if(length==maxlength){
  if(d !=""){
  d.focus();
  }
}
if(a.key=='Backspace'){
  b.focus();
}
}
 

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

}
