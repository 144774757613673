 
<div class="card" style=" overflow-x: scroll;">
    <p-table #dt [value]="products" [rows]="10" [paginator]="true"
          [globalFilterFields]="['OrgName','GSTN','StateName','DistrictName','DeskMobileNo','DeskEmailID','AuthPersonName','AuthPersonMobileNo']"
          [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"  styleClass="p-datatable-striped" >
         <ng-template pTemplate="caption">
              <div class="p-d-flex p-ai-center p-jc-between">
                  <h1 class="headingclass">
                      BOX STOCK IN</h1>
                  <span class="p-input-icon-left">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search..." />
                      &nbsp; &nbsp; <a [routerLink]="['/box-stock-out']" class="btn btn-success"> <i
                              class="fa fa-plus" aria-hidden="true"></i> Sale</a>
                  </span>&nbsp;&nbsp;&nbsp;
                  <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2"  pTooltip="Export to Excell" tooltipPosition="bottom"></button>
               </div>

              
          </ng-template> 
           <ng-template pTemplate="header"> 
             <tr class="padding-head"> 
                  <th pSortableColumn="BoxID"> Box ID <p-sortIcon field="BoxID"></p-sortIcon></th>  
                  <th pSortableColumn="BoxName">Box Name <p-sortIcon field="BoxName"></p-sortIcon></th> 
                  <th pSortableColumn="BoxName">Box Rate/Sale Price <p-sortIcon field="BoxName"></p-sortIcon></th> 
                  <th pSortableColumn="BoxName">In Stock Quantity <p-sortIcon field="BoxName"></p-sortIcon></th> 
                  <th pSortableColumn="BoxName">In Stock Amount <p-sortIcon field="BoxName"></p-sortIcon></th> 
                 </tr>
              <tr class="padding-head">  
                 <th style="width: 150px !important;" > 
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'BoxID' ,'contains')" placeholder="SalesID"  style="width: 50% !important;" />
                  </th> 
                  <th>
                     <input pInputText type="text" (input)="dt.filter($event.target.value,'BoxName' ,'contains')" placeholder="Search" />
                  </th> 
                  <th>
                     <input pInputText type="text" (input)="dt.filter($event.target.value,'BoxName' ,'contains')" placeholder="Search" />
                  </th>
                  <th> 
                     <input pInputText type="text" (input)="dt.filter($event.target.value,'BoxMakingProductDetail' ,'contains')"  placeholder="Search " class="fullwidth" />
                  </th>
                  <th> 
                     <input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')"  placeholder="Search" />
                  </th>  
                 </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr class="responsivecss"> 
                <td> 
                    <span class="p-column-title">Box ID:</span>
                      {{product.BoxID}}
                  </td> 
                  <td>
                    <span class="p-column-title">Box Name:</span>
                      {{product.BoxName}}
                  </td> 
                  <td>
                    <span class="p-column-title">Box Rate/Sale Price:</span>
                    {{product.BoxSalePrice}} 
                 </td> 
                  <td>
                    <span class="p-column-title">In Stock Quantity:</span>
                    {{product.BoxAvailableStock}}                  
                  </td> 
                  <td>
                    <span class="p-column-title">In Stock Amount:</span>
                      {{product.BoxAvailableStockAmount}}
                  </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
              <div class="p-d-flex p-ai-center p-jc-between">
                  In total there are {{products ? products.length : 0 }} products.
              </div>
          </ng-template>
      </p-table>
  </div>
  
 
  <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
  <!-- success message -->
  <p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="content">
          <div class="modal-dialog modal-confirm">
              <div class="modal-content">
                  <div class="modal-header justify-content-center">
                      <div class="icon-box">
                          <i class="material-icons">&#xE876;</i>
                      </div>
  
                  </div>
                  <div class="modal-body text-center">
                      <h4>Success!</h4>
                      <p>Organization Deleted Successfully.</p>
                  </div>
              </div>
          </div>
      </ng-template>
  
  
  </p-dialog>
  <!-- EOF success message -->
  
  <!-- update success -->
  <p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="content">
          <div class="modal-dialog modal-confirm">
              <div class="modal-content">
                  <div class="modal-header justify-content-center">
                      <div class="icon-box">
                          <i class="material-icons">&#xE876;</i>
                      </div>
  
                  </div>
                  <div class="modal-body text-center">
                      <h4>Success!</h4>
                      <p>Status Update Successfully.</p>
                  </div>
              </div>
          </div>
      </ng-template>
  
  
  </p-dialog>
  <!--eof update success  -->


