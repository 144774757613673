import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
 
 
import { Billdesk } from '../../billdesk';
import { BilldeskService } from '../../billdesk.service';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';


import { Router } from '@angular/router';
@Component({
  selector: 'app-edit-price-type',
  templateUrl: './edit-price-type.component.html',
  styleUrls: ['./edit-price-type.component.css']
})
export class EditPriceTypeComponent implements OnInit {
  stateList:Billdesk[];

  cityList:Billdesk[];
  public id:any;
  public   OrgName:any;
  public products: Billdesk[];
  constructor(private billdeskService: BilldeskService,private fb: FormBuilder,private _Activatedroute:ActivatedRoute,private http:HttpClient,private route : Router ) {
     
  }
 
  form: FormGroup;
 
  ngOnInit() {
   
 
    this.id=this._Activatedroute.snapshot.paramMap.get("id");
    this._Activatedroute.paramMap.subscribe(params => { 
      this.id = params.get('id');
      console.log(this.id);
      console.log(this.billdeskService.getPriceTypeid(this.id).then(res => this.products = res));

 
 
    });

    
   this.form = this.fb.group({  
    PriceTypeName: [null, Validators.required], 
    ActiveStatus: [null, Validators.required]  ,
 
    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
 
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }




  
  onSubmit() {

  
    this.id=this._Activatedroute.snapshot.paramMap.get("id");
    console.log(this.form.value);
    var obj1 = 
   {
 
     "PriceTypeID" : this.id, 
    
    };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);



if (this.form.valid) {


  
// this.id=this._Activatedroute.snapshot.paramMap.get("id");
// this._Activatedroute.paramMap.subscribe(params => { 
//   this.id = params.get('id');

//   console.log(this.id);
let updateurl= "/pricetype/pricetyperegistrationupdate";
this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{
  console.log("responce data");
  console.log(res);
  console.log("responce data");
   console.log(res.pricetyperegistrationupdateResult);
  if(res.pricetyperegistrationupdateResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/price_type_list'])
   localStorage.setItem('updatedata', 'Success');
 }else{
   console.log('Please Try Again Later');
 }
 });





// }); 
  
} else {
  this.validateAllFormFields(this.form);
}
 }


 changeState(e) {
  console.log('eventfire');
  console.log(e);
   console.log(this.billdeskService.getCity(e).then(res => this.cityList = res));
}
}
