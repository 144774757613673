
<br>
<br>
<br>
        <footer id="rs-footer" class="rs-footer">
      <!-- Footer Top -->
        

            <!-- Footer Bottom -->
            <div class="footer-bottom">
                <div class="container">
                    <div class="copyright">
                        <p class="footercontent">Copyright © 2022   Development BY AISECT Web Center Bhopal . All Rights Reserved. </p>
                    </div>
                </div>
            </div>
        </footer>
        <!-- Footer End -->

        <!-- start scrollUp  -->
        <div id="scrollUp">
            <i class="fa fa-rocket"></i>
        </div>
        
  
        <br><br>
        
        <br><br><br> 
        <!-- Search Modal End --> 