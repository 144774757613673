import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { environment } from './../../environments/environment'; 

import { Billdesk } from './../billdesk';

import { Observable } from 'rxjs';  
@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {
private APIURL  = environment.baseUrl;
constructor(private http:HttpClient) { }
//  getListData
getListData(request:any,response:any) {   
  return this.http.get<any>(this.APIURL+request).toPromise().then(res => <Billdesk[]>res[response].dataobject).then(res => { return res; });
 }



 geExistData(request:any,response:any) {   
  return this.http.get<any>(this.APIURL+request).toPromise().then(res => <Billdesk[]>res[response]).then(res => { return res; });
 }

}
