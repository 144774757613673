import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BilldeskService } from 'src/app/billdesk.service';

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';


import { printDiv } from './print-div';

import { ToWords } from 'to-words';
@Component({
  selector: 'app-saleinvoice',
  templateUrl: './saleinvoice.component.html',
  styleUrls: ['./saleinvoice.component.css']
})
export class SaleinvoiceComponent implements OnInit {
  id:any;
  public myAngularxQrCode: string;

  InvoiceDetailData:any;
  toAddress:any;
  bankdetail:any;
  
  DetailSaleInvoice:any;
  itemdetails:any;
  AmountValue: string = "500000";
  DepartmentID:any;
  InstitutionID:any;
  OrganizationID:any;
  UserInfo:any; 
  inword:any;
  buttonhide=true;

  toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    }
  });
  constructor(private _Activatedroute:ActivatedRoute,private billdeskService:BilldeskService) { 
   
    this.DepartmentID=localStorage.getItem('DepartmentID');

    this.InstitutionID=localStorage.getItem('InstitutionID');
    
    this.OrganizationID=localStorage.getItem('OrganizationID');
    
        this.UserInfo='&OrgID='+this.OrganizationID+'&DivID='+this.InstitutionID+'&DeptID='+ this.DepartmentID;
  }

  ngOnInit(): void {
    this.id=this._Activatedroute.snapshot.paramMap.get("id");

    console.log(this.id);


    const saledetal='?id='+this.id+'&'+this.UserInfo;

    console.log(this.billdeskService.getSale_Detail(saledetal).then((res:any) =>{

      this.DetailSaleInvoice = res


      console.log('thisdetr',res)
console.log('test',this.DetailSaleInvoice[0].ToAddressDetails);
this.toAddress =this.DetailSaleInvoice[0].ToAddressDetails.split("|");
 
      console.log(this.toAddress);
 this.InvoiceDetailData='Sale Invoice No='+this.DetailSaleInvoice[0].SaleInvoiceNumber+'&Sale Invoice Date='+this.DetailSaleInvoice[0].SaleInvoiceDate+
 '&Bill From Division  ='+this.DetailSaleInvoice[0].FromOrgName+'& From Dept Name='+this.DetailSaleInvoice[0].FromDeptName+'&Total Payable Amount='+this.DetailSaleInvoice[0].PayableAmount;

this.itemdetails=this.DetailSaleInvoice[0].SaleDetailCreateJson;



this.bankdetail='Bank Name:'+this.DetailSaleInvoice[0].FromDivBankName+'Account No. :'+this.DetailSaleInvoice[0].FromDivBankAcNo+'IFSC Code :'+this.DetailSaleInvoice[0].FromDivIFSCCode+'Branch :'+this.DetailSaleInvoice[0].FromDivBankBranchName;
 
      // this.InvoiceDetailData=res.saledetailResult.dataobject[0].

      this.inword= this.toWords.convert(this.DetailSaleInvoice[0].BalanceAmount);
        } ));
 }


 print_page(batch_no:any){
  var batchno='Sales Invoice '+batch_no;
  this.buttonhide=false;
  setTimeout(() =>printDiv('content',batchno), 3000);
    }
  


}
