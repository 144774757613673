
<form class="form-horizontal" [formGroup]="form"> 


    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">PRODUCT CATEOGORY REGISTRATION</h1>
<div class="card-body" style="background-color:#fff; ">
    <h1 class="card-title">
       Product Category Registration
    </h1>
    <div class="row">
      



        <div class="position-relative  form-group col-sm-6">
            <label for="ItemCategoryName" class="col-form-label">Category Name  <span>*</span></label>
            <div class="">
                <input type="text" name="ItemCategoryName" id="ItemCategoryName" class="form-control" formControlName="ItemCategoryName">
     <app-field-error-display [displayError]="isFieldValid('ItemCategoryName')" errorMsg="Please Enter Category Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        
        <div class="position-relative  form-group col-sm-6">
            <label for="CategoryDisplayName" class="col-form-label">Display Name  <span>*</span></label>
            <div class="">
                <input type="text" name="CategoryDisplayName" id="CategoryDisplayName" class="form-control" formControlName="CategoryDisplayName">
     <app-field-error-display [displayError]="isFieldValid('CategoryDisplayName')" errorMsg="Please Enter Display Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        
        <div class="position-relative  form-group col-sm-6">
            <label for="Description" class="col-form-label">Description  <span>*</span></label>
            <div class="">
                <input type="text" name="Description" id="Description" class="form-control" formControlName="Description">
     <app-field-error-display [displayError]="isFieldValid('Description')" errorMsg="Please Enter Description Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>

        <div class="position-relative  form-group col-sm-6">
            <label for="ActiveStatus" class="col-form-label">Active Status<span>*</span></label>
            <div class="">
                <!-- <input type="text" name="City" id="City" class="form-control"> -->

                <select  class="form-control" name="ActiveStatus" id="ActiveStatus"  formControlName="ActiveStatus">
                    <option [ngValue]="null" disabled selected >Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </select>
            
            
                <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Select Status"   style="background-color: #f8d7da;
                border-color: #f5c6cb;" class="alert-danger">
                </app-field-error-display>
            
            </div>
        </div>
        
        <!-- <div class="position-relative  form-group col-sm-6">
            <label for="CategoryIcon" class="col-form-label">Category Icon <span>*</span></label>
            <div class="">
                <input type="text" name="CategoryIcon" id="CategoryIcon" class="form-control" formControlName="CategoryIcon">
     <app-field-error-display [displayError]="isFieldValid('CategoryIcon')" errorMsg="Please Enter CategoryIcon"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        
        <div class="position-relative  form-group col-sm-6">
            <label for="ImageUrl" class="col-form-label">ImageUrl <span>*</span></label>
            <div class="">
                <input type="text" name="ImageUrl" id="ImageUrl" class="form-control" formControlName="ImageUrl">
     <app-field-error-display [displayError]="isFieldValid('ImageUrl')" errorMsg="Please Enter ImageUrl"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>

        
        <div class="position-relative  form-group col-sm-6">
            <label for="CategoryUrl" class="col-form-label">Category Url <span>*</span></label>
            <div class="">
                <input type="text" name="CategoryUrl" id="CategoryUrl" class="form-control" formControlName="CategoryUrl">
     <app-field-error-display [displayError]="isFieldValid('CategoryUrl')" errorMsg="Please Enter CategoryUrl "   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>

        
        <div class="position-relative  form-group col-sm-6">
            <label for="CategoryImageUrl" class="col-form-label">Category ImageUrl  <span>*</span></label>
            <div class="">
                <input type="text" name="CategoryImageUrl" id="CategoryImageUrl" class="form-control" formControlName="CategoryImageUrl">
     <app-field-error-display [displayError]="isFieldValid('CategoryImageUrl')" errorMsg="Please Enter Category ImageUrl "   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>

        <div class="position-relative  form-group col-sm-6">
            <label for="Comments" class="col-form-label">Comments <span>*</span></label>
            <div class="">
 
    
    <textarea  name="Comments" id="Comments" class="form-control" formControlName="Comments"></textarea>
    
    <app-field-error-display [displayError]="isFieldValid('Comments')" errorMsg="Please Enter Comments"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        
        <div class="position-relative  form-group col-sm-6">
            <label for="ActiveStatus" class="col-form-label">Active Status<span>*</span></label>
            <div class="">
  
                <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus">
                    <option value="" selected disabled>Please Select</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option> 
                </select>
    
    
    <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter ActiveStatus"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>

        
        <div class="position-relative  form-group col-sm-6">
            <label for="OrderBy" class="col-form-label">OrderBy<span>*</span></label>
            <div class="">
                <input type="text" name="OrderBy" id="ItemCategoryName" class="form-control" formControlName="OrderBy">
     <app-field-error-display [displayError]="isFieldValid('OrderBy')" errorMsg="Please Enter OrderBy "   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>

        
        <div class="position-relative  form-group col-sm-6">
            <label for="EstStartDate" class="col-form-label">Est Start Date <span>*</span></label>
            <div class="">
                <input type="date" name="EstStartDate" id="EstStartDate" class="form-control" formControlName="EstStartDate">
     <app-field-error-display [displayError]="isFieldValid('EstStartDate')" errorMsg="Please Enter Est Start Date "   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        
        <div class="position-relative  form-group col-sm-6">
            <label for="EstEndDate" class="col-form-label">Est End Date<span>*</span></label>
            <div class="">
                <input type="date" name="EstEndDate" id="EstEndDate" class="form-control" formControlName="EstEndDate">
     <app-field-error-display [displayError]="isFieldValid('EstEndDate')" errorMsg="Please Enter Est End Date"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


       

        
        <div class="position-relative  form-group col-sm-6">
            <label for="ActualStartDate" class="col-form-label">Actual Start Date<span>*</span></label>
            <div class="">
                <input type="date" name="ActualStartDate" id="ActualStartDate" class="form-control" formControlName="ActualStartDate">
     <app-field-error-display [displayError]="isFieldValid('ActualStartDate')" errorMsg="Please Enter Actual Start Date"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        
        <div class="position-relative  form-group col-sm-6">
            <label for="ActualEndDate" class="col-form-label">Actual End Date<span>*</span></label>
            <div class="">
                <input type="date" name="ActualEndDate" id="ActualEndDate" class="form-control" formControlName="ActualEndDate">
     <app-field-error-display [displayError]="isFieldValid('ActualEndDate')" errorMsg="Please Enter Actual End Date"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div> -->


        
      

        <div class="position-relative  form-check col-sm-6 offset-sm-6">
            <div class="">
                <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
            </div>
        </div>
   
</div>

