import { Component, OnInit, Inject,ViewChild,ElementRef } from '@angular/core';
import { Product } from '../product';
import { ProductService } from '../productservice';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer"; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { printDiv } from './print-div';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 

import { environment } from '../../environments/environment';
import { ApiserviceService } from '../api/apiservice.service';

@Component({
  selector: 'app-pobatchlistsheetb2',
  templateUrl: './pobatchlistsheetb2.component.html',
  styleUrls: ['./pobatchlistsheetb2.component.css']
})
export class Pobatchlistsheetb2Component implements OnInit {
  products: Product[];
  Totalamount:any;
  Totalinvoice:any;
  empList:any[];
  UserID:any;

  group:any;
  UserTypeID:any;
  DiscountAmount:any;
  TDSInAmount:any;
  TotInvoice:any;
  invoiceCount:any[];
  product: Product;
  id:any;
  i:any=0;
  ServiceUrl=environment.baseUrl;
  TotalTdsinamount:any;
  TotalDiscountAmount:any;
  PoBatchNo:any;
  // ServiceUrl=environment.baseUrl;
  CreatedDate:any;
  productDialog: boolean;
 
  dialogdata: string;
 
 
  selectedProducts:any;

  submitted: boolean;

  statuses: any[];
 
  customers: Customer[];
  insertdata:any;
  updatedata:any;
  representatives: Representative[];

  loading: boolean = true;
 
  yearsdetail:any;
  months:any;
  newArr:any;
  paymentform: FormGroup;
  MonthShow:any;
  YearShow:any;
  TotBIllAmount:any
TotTdsAmount:any
TotDiscount:any
TotNetPayble:any
  showmonth=false;
  array:any;
  groupArr:any;
  currentDate:any;
  sumNumber:any;
  compleatearray:any
  grouptype:any;
  constructor(private http:HttpClient,private fb: FormBuilder,private productService: ProductService, private messageService: MessageService, private confirmationService: ConfirmationService,public dialog: MatDialog,public billdeskservice:BilldeskService,private apiservice:ApiserviceService) { 
 
    this.UserTypeID=localStorage.getItem('UserTypeID'); 
 
   this.UserID=localStorage.getItem('UserID'); 
   

 
  }
 
  ngOnInit(): void {
 
    this.paymentform = this.fb.group({ 
    InvoiceYear: [null, Validators.required],
    InvoiceMonth: [null, Validators.required],
    grouptype:[null, Validators.required],
    }); 
    this.yearsdetail=["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"];
    this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
 

  }


 

  print_page(){
    // this.buttonhide=false;

 
    const title =  "Sheet-A-"+this.YearShow+"_TO_"+this.MonthShow;
//  const title="myfiledownload";
    setTimeout(() =>printDiv('content',title), 3000);
    
       }


  getPODetail(){
    this.TotInvoice=0; 
       
    this.TotBIllAmount=0; 

    
    this.TotTdsAmount=0; 
    
    this.TotDiscount=0; 
    
    this.TotNetPayble=0; 

  

var InvoiceMonth =this.paymentform.get('InvoiceMonth').value+'-31';

var grouptype=this.paymentform.get('grouptype').value;
var InvoiceYear =this.paymentform.get('InvoiceYear').value+'-01';
 
 this.showmonth=true;
this.YearShow=InvoiceYear;
this.MonthShow=InvoiceMonth;


 

var Requesturl='/pobatchregistration/pobatchregistrationlistbydategroupwise?FromDate='+InvoiceYear+'&ToDate='+InvoiceMonth+'&GroupFlag='+grouptype;
const ResponsData='pobatchregistrationlistbydategroupwiseResult';




 this.apiservice.getListData(Requesturl,ResponsData).then(data => {
  
  this.products = data; 
  this.array = this.products;
 

      this.groupArr = this.array.reduce((r,{MonthYear})=>{
          if(!r.some(o=>o.MonthYear==MonthYear)){
            r.push({MonthYear,groupItem:this.array.filter(v=>v.MonthYear==MonthYear)});
          }
      return r;
      },[]);



 
      const total= this.groupArr.length; 
 
      this.compleatearray=[]
      for (let i = 0; i < total; i++) { 
      const totalgroupitem=this.groupArr[i].groupItem.length;
 
      this.TotInvoice=[];   
      for (let j = 0; j < totalgroupitem; j++) { 
          this.TotInvoice.push(this.groupArr[i].groupItem[j].TotalCostWithoutGST);
  
          this.sumNumber = this.TotInvoice.reduce((acc, cur) => acc + Number(cur), 0)
    
         } 
        

      var obj;
      obj = {
        Total: this.sumNumber 
      };
      this.compleatearray.push(obj);
      }  
      this.newArr = this.groupArr.map((item, index) => ({ ...item, TotalwithoutGST: this.compleatearray[index].Total}));
 
    }); 

 this.http.get(this.ServiceUrl+'/pobatchregistration/pobatchregistrationlistbydategroupwise?FromDate='+InvoiceYear+'&ToDate='+InvoiceMonth+'&GroupFlag='+grouptype).subscribe((profileres:any)=>{
 const total =profileres.pobatchregistrationlistbydategroupwiseResult.dataobject.length;
 
         

               this.TotInvoice=[]; 
       
               this.TotBIllAmount=[]; 

               
               this.TotTdsAmount=[]; 
               
               this.TotDiscount=[]; 
               
               this.TotNetPayble=[]; 

        for (let i = 0; i < total; i++) {
    
          this.TotInvoice.push(profileres.pobatchregistrationlistbydategroupwiseResult.dataobject[i].TotInvoice);
          
          this.TotBIllAmount.push(profileres.pobatchregistrationlistbydategroupwiseResult.dataobject[i].TotalCostWithoutGST);
          
          this.TotTdsAmount.push(profileres.pobatchregistrationlistbydategroupwiseResult.dataobject[i].IGSTAMT);
          
          this.TotDiscount.push(profileres.pobatchregistrationlistbydategroupwiseResult.dataobject[i].DiscountAmount);
          
          this.TotNetPayble.push(profileres.pobatchregistrationlistbydategroupwiseResult.dataobject[i].TotalPayableAmount);
    
        }  
      
 
        var sumNumber = this.TotInvoice.reduce((acc, cur) => acc + Number(cur), 0)
 
        this.TotInvoice=sumNumber;

        var sumNumber1 = this.TotBIllAmount.reduce((acc, cur) => acc + Number(cur), 0)
     
        this.TotBIllAmount=sumNumber1;

        var sumNumber2 = this.TotTdsAmount.reduce((acc, cur) => acc + Number(cur), 0)
 
        this.TotTdsAmount=sumNumber2;


        var sumNumber3 = this.TotDiscount.reduce((acc, cur) => acc + Number(cur), 0)
    
        this.TotDiscount=sumNumber3;

        var sumNumber4 = this.TotNetPayble.reduce((acc, cur) => acc + Number(cur), 0)
 
        this.TotNetPayble=sumNumber4;
  
 
});
 
      
  
 



      } 

}
