import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';
@Component({
  selector: 'app-vendor-registration',
  templateUrl: './vendor-registration.component.html',
  styleUrls: ['./vendor-registration.component.css']
})
export class VendorRegistrationComponent implements OnInit {
  //Create FormGroup 

 
  statekey:any;
  stateList:Billdesk[];

  cityList:Billdesk[];


  type: string;
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef) {
    
 this.billdeskService.getState().then(res => this.stateList = res); 
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  selectedStateName:any;

  selectedStateID:any;
  

  selectedCityID:any;
  
  selectedCityName:any;
  ngOnInit() {
    this.form = this.fb.group({ 
      
      VendorName: [null, Validators.required],
      GSTN:[null, Validators.required],
      CIN: [null, Validators.required],
      
      PANNo: [null, Validators.required],
      
      // ItemCategoryID: [null, Validators.required],
      Website:[null, Validators.required],
      SupportUrl: [],
      
      SupportLoginID: [],

      UserPassword: [null, Validators.required],
      UserLoginID: [null, Validators.required],
      SupportPassword: [],
      VendorEmailID: [null, Validators.required],
      VendorMobileNo: [null, Validators.required],
      
      VendorBillingAddress: [null, Validators.required],
      VendorCity: [null, Validators.required],
      VendorState: [null, Validators.required], 
      VendorPinCode: [null, Validators.required],
      // VendorUPIID: [null, Validators.required],
      BankAcNo: [null, Validators.required],
      BankHolderName: [null, Validators.required],
      BankName: [null, Validators.required],
      BankBranchName: [null, Validators.required],
      IFSCCode: [null, Validators.required],
      SwiftCode: [],
      AuthPersonName: [null, Validators.required],
      AuthPersonEmailID: [null, Validators.required],
      AuthPersonMobileNo: [null, Validators.required],
      ActiveStatus: [null, Validators.required],
      IsCustomer:'0'
      // VerificationEmailUR: [null, Validators.required],
      // VerificationEmailOT: [null, Validators.required],
      // IsEmailVerified: [null, Validators.required],
      // VerificationMobileOTP: [null, Validators.required],
      // IsMobileVerified: [null, Validators.required]
       
      
    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {
  this.selectedStateName = e.value.StateName;
  this.selectedStateID=e.value.StateID; 
  this.billdeskService.getCity(e.value.StateID).then(res => this.cityList = res);
  }

  
  changeCity(e){
    this.selectedCityName = e.value.DistrictName;
    this.selectedCityID=e.value.DistrictID;
   }
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => { 
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
        "flag":"",
         "CreatedDate"  : "",
         "CreatedByID":"2",
     "CreatedBy" :"5",
     "RoleTypeID" :"4",
     "UpdatedDate"  : "",
     "UpdatedBy" :"",
     "ItemCategoryID":"",
     "IsMobileVerified":"",
     "VerificationMobileOTP":"",
     "IsEmailVerified":"",
     "VerificationEmailOT":"",
     "VerificationEmailUR":"",
     "VendorUPIID":"",
     
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
 
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
 

if (this.form.valid) { 
let registerurl= "/vendor/vendorregistration";
this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
 


  
//   if(res.vendorregistrationResult.Message=='Sucess'){ 

//    this.route.navigate(['/Vendor-List'])
//    localStorage.setItem('insertdata', 'Success');

//  }else{
//   //  console.log('Please Try Again Later');
//  }



if (res.vendorregistrationResult.Message == "Login ID Already Exist") {
  // alert('Uploaded Successfully.');
  this.route.navigate(['/Vendor-List'])
  localStorage.setItem('exist', 'exist');

}
else {

  if(res.vendorregistrationResult.Message=='Sucess'){ 
  // alert('Uploaded Successfully.');
  this.route.navigate(['/Vendor-List'])
  localStorage.setItem('insertdata', 'Success');

  }


}


 });
} else {
  this.validateAllFormFields(this.form);
}
 }
 

 
 }
 