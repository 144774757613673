 
 

<div class="container" style="background-color: #ffffff; height:80vh;" id="content" #content>
	<p-toast></p-toast>
	 
  
<form class="form-horizontal" [formGroup]="addForm"> 

    <h1 style="text-align:center;margin-top: 20px;padding-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">BOX  MAKING</h1>
    <div class="card-body" style="background-color:#fff; box-shadow: 5px 5px 5px 0px rgb(204 196 196 / 15%); ">
 		<h1 class="card-title">
           Product Making
        </h1> 
		
	<div class="row"> 
		<div class="col-md-12">
					<div class="panel panel-default">
						<div class="panel-body">
							<table class="table">
							<tr>
								<td class="pl-0">
									<div class="col-md-12" [formGroup]="addForm">

										<div class="row">
											<div class="position-relative  form-group col-sm-4">
											<span style="display:inline"><strong> Box Name:</strong> </span>
											 <input type="text"   formControlName="BoxName"    class="form-control"/>
											</div>
											<div class="position-relative  form-group col-sm-4"> 
												<span style="display:inline"><strong> Box Description:</strong> </span>
												<input type="text"  formControlName="BoxDescription"   [formControl]="" class="form-control"/>
											</div>
										</div>
										<div class="form-group">
										<table class="table mb-0" style="overflow-y:auto;">
									      <tbody>
												<tr style="width:100%; background: aliceblue;">
 													<td class="text-center"><strong>Product</strong></td>
													<td class="text-center"><strong>Brand</strong></td>
													<td class="text-center"><strong>Unit</strong></td>
													<td class="text-center"><strong>Quantity</strong></td> 
													<td class="text-center" style="width: 80px;"><strong>Action</strong>
													</td>
												</tr>
											<tr *ngFor="let row of addDynamicRow?.controls; let index = index;">
											<td> 
											<select class="form-control mb"  [formControl]="row.get('ItemID')"  (ngModelChange)="setProductName($event,index)">
												<option selected value="" disabled>Please Select Product</option> 
												<optgroup *ngFor='let grp of itemcategorylist' label="{{grp.ItemCategoryName}}">
														<option *ngFor='let item of grp.ItemJson' [value]="item.ItemID"    data-category="item.ItemCategoryID">
															{{item.ItemName}} 
														</option>
												</optgroup>
											</select>
											</td>
											<td>  
											<select class="form-control mb" [formControl]="row.get('BrandID')"  >
												<option  >Please Select Brand</option> 
												<option *ngFor='let BrandList of  dynamicArray[index].BrandDetail'[value]="BrandList.BrandID">{{BrandList.BrandName}}</option>
											</select>
											</td>
											<td> 
											<select class="form-control mb" [formControl]="row.get('UnitID')" >
												<option selected value="" disabled>Please Select Unit</option>
												<option *ngFor='let item of unitlist' [value]="item.UnitID">{{item.UnitName}}</option>
											</select>
											</td>
											<td>  
												<input type="number"  class="form-control mb"   [formControl]="row.get('Quantity')">
											</td>
											<td> 
												<span class="btn fa fa-trash  btn-danger" style="font-size:20px; height:35px; margin-right:5px; display:flex; align-items:center;" (click)="onRemoveRow(index)"></span>
											 </td>
											</tr>
											</tbody>
											<tfoot>

												<span class=" btn fa fa-plus btn-info"  (click)="onAddRow()" *ngIf="addForm.get('rows')">Add More</span> 
													
											</tfoot>
										</table>
										</div>
									</div>
								</td>  
							</tr> 
							</table> 
					</div>
					
				
				</div>

			</div>
		


		</div>

		<!-- <div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div> -->
 		<div class="row">
			<div class="col-md-12">
				<div class="panel panel-default">
					<div class="panel-body">
						<div class=""> 
							<br>
							<div class="row">
								<div class="col-md-2"></div>
								<div class="col-md-2"></div>
								<div class="col-md-1"></div>
								<div class="col-md-2">
								<button  
									type="submit" class="btn btn-info btn-lg" (click)="onSubmit()"
									 > <i class="fa fa-floppy-o" aria-hidden="true"></i> Save
						 
								</button>
								</div> 
								<div class="col-md-1"></div> 
								<div class="col-md-2"></div>
								<div class="col-md-2"></div>
							</div>

							<br>
						</div>
					</div>
				</div>
			</div>
		</div> 
		</div> 
		
		 
	</form>
</div> 
 