<div class="card" style=" overflow-x: scroll;">
  <p-table #dt [value]="products" [rows]="10" [paginator]="true"
        [globalFilterFields]="['OrgName','GSTN','StateName','DistrictName','DeskMobileNo','DeskEmailID','AuthPersonName','AuthPersonMobileNo']"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]">
        <!-- <p-table #dt [value]="products" [(selection)]="selectedProducts" dataKey="id" styleClass="p-datatable-customers" [rowHover]="true"
 
            [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
         [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [filterDelay]="0" [globalFilterFields]="['name','country.name','representative.name','status']"> -->

        <!-- <p-table [value]="products" [tableStyle]="{'min-width': '50rem'}"> -->

        <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 class="headingclass">
                    ORGANIZATION LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />
                    &nbsp; &nbsp; <a [routerLink]="['/Organization-Registration']" class="btn btn-success">Add <i
                            class="fa fa-plus" aria-hidden="true"></i></a>
                </span>
            </div>
        </ng-template>


        <ng-template pTemplate="header">



            <tr class="padding-head">
                <!-- <th style="width: 50px">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th> -->
                <th pSortableColumn="rating"  class="actionclass"> Action  </th>

                <th pSortableColumn="ActiveStatus">Status <p-sortIcon field="ActiveStatus"></p-sortIcon></th>

                <th pSortableColumn="OrgName"> Organization Name <p-sortIcon field="OrgName"></p-sortIcon></th>
                <th pSortableColumn="AllotedOrgName"> Approver Name <p-sortIcon field="AllotedOrgName"></p-sortIcon></th>

                <th pSortableColumn="GSTN"> GSTN <p-sortIcon field="GSTN"></p-sortIcon></th>
                <th pSortableColumn="StateName">State <p-sortIcon field="StateName"></p-sortIcon></th>
                <th pSortableColumn="DistrictName"> City <p-sortIcon field="DistrictName"></p-sortIcon></th>

                <th pSortableColumn="DeskMobileNo"> Contact No. <p-sortIcon field="DeskMobileNo"></p-sortIcon></th>
                <th pSortableColumn="DeskEmailID"> Email Id<p-sortIcon field="DeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="AuthPersonName"> Contact Person <p-sortIcon field="AuthPersonName"></p-sortIcon>
                </th>
                <th pSortableColumn="AuthPersonMobileNo"> Contact Person No <p-sortIcon
                        field="AuthPersonMobileNo"></p-sortIcon></th>


            </tr>
            <tr class="padding-head">
                <!-- <td>

                </td> -->
                <!-- <th style="position: sticky;
                left: 0;
                z-index: 2;background:#ffffff;"> -->

                <th>
                    <!-- <input pInputText type="text" style="width:100px;" (input)="dt.filter($event.target.value,'action' ,'contains')" placeholder="Action" readonly /> -->

                </th>
                <th>



                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')"
                        styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>


                </th>


                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'OrgName' ,'contains')"
                        placeholder="Search OrgName" />
                </th>


                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'AllotedOrgName' ,'contains')"
                        placeholder="Search Approver" />
                </th>



                

                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'GSTN' ,'contains')"
                        placeholder="Search GSTN" class="fullwidth" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'StateName' ,'contains')"
                        placeholder="Search State" class="fullwidth" />
                </th>


                <th>


                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DistrictName' ,'contains')"
                        placeholder="Search City" class="fullwidth" />







                </th>


                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DeskMobileNo' ,'contains')"
                        placeholder="" class="fullwidth" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DeskEmailID' ,'contains')"
                        placeholder="Search Email Id" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'AuthPersonName' ,'contains')"
                        placeholder="Contact Person Name" />
                </th>
                <th>

                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'AuthPersonMobileNo' ,'contains')"
                        placeholder="Contact Person No." class="fullwidth" />
                </th>




            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">
                <td style="position: sticky;
                left: 0;
                z-index: 2;background:#ffffff;">
                    <!-- <span class="p-column-title" style="margin-top:20px;">Action<br></span>
                    <span style="display:flex;">
                        <a [routerLink]="['/Edit-Organization',product.OrgID]"><button pButton pRipple
                                icon="pi pi-pencil" class="p-button-success p-mr-2"
                                style="margin-right: 4px; background:#f09639;border:#f09639"
                                pTooltip="Edit Organization Detail"></button>
                        </a>
                        <button pButton pRipple icon="pi pi-eye" class="p-button-info" (click)="viewProduct(product)"

                       
                            pTooltip="View Organization Detail" style="background:#2459a6;border:#2459a6;"></button>
                    </span>
                     --> <span class="p-column-title">Action:</span>
                    <span style="display: flex;align-items: center;justify-content: center;">
                        <a [routerLink]="['/Edit-Organization',product.OrgID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                style='color: #2459a6;' pTooltip="Edit Organization Detail"></i></a>
                        <span pTooltip="View Organization Detail"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                        border: 2px solid;
                        border-radius: 5px;' (click)="viewProduct(product)"></i> </span>
                    </span>
                </td>
                <td>


                    <span class="p-column-title">Status:</span>
                    <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
                    <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>



                    <!-- <i class="pi-file-edit" style="font-size: 2rem"></i> -->
                </td>
                <td>

                    <span class="p-column-title">Organization Name:</span>
                    {{product.OrgName}}
                </td>



                <td>
                     <span class="p-column-title">Approver Name:</span>
                    {{product.AllotedOrgName}}
                </td>



                
                <td>

                    <span class="p-column-title">GSTN:</span>
                    {{product.GSTN}}
                </td>


                <td>

                    <span class="p-column-title">State:</span>
                    {{product.StateName}}
                </td>
                <td>


                    <span class="p-column-title">City:</span>
                    {{product.DistrictName}}
                </td>



                <td>

                    <span class="p-column-title">Mobile:</span>
                    {{product.DeskMobileNo}}
                </td>
                <td>

                    <span class="p-column-title">Email:</span>
                    {{product.DeskEmailID}}
                </td>
                <td>

                    <span class="p-column-title">Contact Person:</span>
                    {{product.AuthPersonName}}
                </td>
                <td>

                    <span class="p-column-title">Contact No:</span>
                    {{product.AuthPersonMobileNo}}
                </td>



            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '800px'}" header="Organization Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <br>
        <div class="container">
            <table class="table table-striped">

                <tbody>
                    <tr>
                        <td>
                            <label for="Organization_Name" class="col-form-label">Organization Id :</label>
                        </td>
                        <td>
                            <label for="OrgID" class="col-form-label"> {{productsbyid[0].OrgID}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="Organization_Name" class="col-form-label">Organization Name:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].OrgName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="PANCard" class="col-form-label">PANCard:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].PANNo}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="GSTN" class="col-form-label">GSTN:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].GSTN}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="Contact_Number" class="col-form-label">Contact Number:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DeskMobileNo}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="Emailid" class="col-form-label">Email </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DeskEmailID}}
                            </label></td>

                    </tr>
                    <tr>
                        <td> <label for="Address" class="col-form-label">Address </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BillingAddress}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="City" class="col-form-label">City </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].City}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td><label for="State" class="col-form-label">State </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].State}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="PinCode" class="col-form-label">PinCode</label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].PinCode}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="ContactPersonName" class="col-form-label">Contact Person Name </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].AuthPersonName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <label for="accountname" class="col-form-label">Account Name </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankAcName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="BanckAccountNo" class="col-form-label">Banck Account No </label></td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankAcNo}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="bankname" class="col-form-label">Bank Name </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="BanckBranch" class="col-form-label">Banck Branch </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankBranchName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Bank IFSC Code </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].IFSCCode}} </label>
                        </td>

                    </tr>

                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Created Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedDate}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Created By </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedBy}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Updated Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedDate}}
                            </label>
                        </td>

                    </tr>

                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Updated By </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedBy}}
                            </label>
                        </td>

                    </tr>

                </tbody>
            </table>
        </div>

        <!-- <div class="form-check-inline">
            <label class="form-check-label">

                Status
            </label>
        </div>
        <div class="form-check-inline">
            <label class="form-check-label"> 
                <input type="radio" class="form-check-input" checked="!checked" name="radio"
                    [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                    [value]="1">active
            </label>
        </div>
        <div class="form-check-inline">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                    [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                    [value]="0">Deactive
            </label>
        </div> -->



    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
    <span style="float: left;padding-top: 2%;">

        <div class="form-check-inline" style="color:#fff">
            <label class="form-check-label">

                Status
            </label>
        </div>
        <div class="form-check-inline" style="color:#fff">
            <label class="form-check-label">
                <!-- {{productsbyid[0].ActiveStatus}} -->
                <input type="radio" class="form-check-input" checked="!checked" name="radio"
                    [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                    [value]="1">active
            </label>
        </div>
        <div class="form-check-inline" style="color:#fff">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                    [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                    [value]="0">Deactive
            </label>
        </div>

        
    </span>
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" style="float: right;"></button>
        <!-- <button style="color: white;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            (click)="saveProduct()"></button>  this is unused -->
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Organization Deleted Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>


<p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Status Update Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>