import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Router ,Route} from '@angular/router';
import { Observable } from 'rxjs';
import { MasterService } from '../service/master.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(private service:MasterService,private route:Router){

 

  }
 
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {  
 
    return this.checkUserLogin(next,state);
    }  


 
  // canDeactivate(
  //   component: unknown,
  //   currentRoute: ActivatedRouteSnapshot,
  //   currentState: RouterStateSnapshot,
  //   nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }
    checkUserLogin(Activerouter:any, url: any): boolean {

      if (this.service.isLoggedIn()) {
 
        const MenuName = this.service.getMenuAccess(Activerouter.data.role);


      // console.log('indexof');
      // console.log('userRole');
      // console.log(MenuName); 
      //   console.log(Activerouter.data.role);
  if (Activerouter.data.role == MenuName) { 
    //  console.log('1 matched');         
     return true;
      }
      else{
      // console.log('2 not matched');
      return false;
          }
      }
      else{
        // console.log('3');
        return true;
      }
 
    }
      }
