import { Component,OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { Observable, Subscription, fromEvent } from 'rxjs';
// import { Router } from '@angular/router'

//...

import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
 title="Bill Desk";

 hidenave:boolean;

 isConnected:any;
 noInternetConnection:boolean;
 displayStyle="none";



 onlineEvent: Observable<Event>;
 offlineEvent: Observable<Event>;
 subscriptions: Subscription[] = [];

 connectionStatusMessage: string;
 connectionStatus: string;
  public constructor(private titleService: Title,public router: Router,public activatedRoute:ActivatedRoute,private renderer: Renderer2,private connectionService:ConnectionService) {
   }
   
  ngOnInit(){



    
// for internet connectivity 
// this.isConnected = true;  
// this.connectionService.monitor().subscribe(isConnected => {  
//  if (isConnected.hasNetworkConnection==true) {  
//     this.noInternetConnection=false;
//     this.closePopup();  
// }  
//   else {  
//     this.noInternetConnection=true; 
//     this.openPopup();  
//   }  
// })  
// for internet connectivity 
   this.titleService.setTitle(this.title);



  //  code for title show in meta 
   const appTitle = this.titleService.getTitle();
   this.router
     .events.pipe(
       filter(event => event instanceof NavigationEnd),
       map(() => {
         const child = this.activatedRoute.firstChild;
         if (child.snapshot.data['title']) {
           return child.snapshot.data['title'];
         }
         return appTitle;
       })
     ).subscribe((ttl: string) => {
       this.titleService.setTitle(ttl);
     });


     this.addCssToElement("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.5.0/css/bootstrap-datepicker.css");
     this.addJsToElement("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.5.0/js/bootstrap-datepicker.js");
     this.addCssToElement("https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-alpha1/dist/css/bootstrap.min.css");
     this.addJsToElement("https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-alpha1/dist/js/bootstrap.bundle.min.js");


     this.onlineEvent = fromEvent(window, 'online');
     this.offlineEvent = fromEvent(window, 'offline');
 
     this.subscriptions.push(this.onlineEvent.subscribe(e => {
       this.connectionStatusMessage = 'Back to online';
       this.connectionStatus = 'online';
       console.log('Online...');


        

       setTimeout(() => {

        location.reload();
       console.log('close all');
    }, 5000);
    
     }));
 
     this.subscriptions.push(this.offlineEvent.subscribe(e => {
       this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
       this.connectionStatus = 'offline';
       console.log('Offline...');
     
     }));

  //  code for title show in meta 
  }
  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }
  addCssToElement(src: string): HTMLStyleElement {
    const script = document.createElement('link');
    script.rel = 'stylesheet';
    script.href = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }




  openPopup() {
    
    this.displayStyle = "block";
  }

  closePopup() {
    this.displayStyle = "none";
  }
  
  

  ngOnDestroy(): void {
    /**
    * Unsubscribe all subscriptions to avoid memory leak
    */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}


