
<form class="form-horizontal" [formGroup]="form"> 


<h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;" >SALE TYPE REGISTRATION</h1>
<div class="card-body" style="background-color:#fff; ">
    <div class="row">
      



        <div class="position-relative  form-group col-sm-6">
            <label for="SaleTypeName" class="col-form-label">Sale Type Name  <span>*</span></label>
            <div class="">
                <input type="text" name="SaleTypeName" id="SaleTypeName" class="form-control" formControlName="SaleTypeName">
     <app-field-error-display [displayError]="isFieldValid('SaleTypeName')" errorMsg="Please Enter Sale Type Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        
        <div class="position-relative  form-group col-sm-6">
            <label for="SaleTypeDeduction" class="col-form-label">Sale Type Deduction <span>*</span></label>
            <div class=""> 
         

                <select  class="form-control" name="SaleTypeDeduction" id="SaleTypeDeduction"  formControlName="SaleTypeDeduction">
                    <option [ngValue]="null" disabled selected >Select Deduction</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
        <app-field-error-display [displayError]="isFieldValid('SaleTypeDeduction')" errorMsg="Please Enter Deduction"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        
        <div class="position-relative  form-group col-sm-6">
            <label for="SaleTypeOrder" class="col-form-label">Sale Type Order  <span>*</span></label>
            <div class="">
                <input type="text" name="SaleTypeOrder" id="SaleTypeOrder" class="form-control" formControlName="SaleTypeOrder">
     <app-field-error-display [displayError]="isFieldValid('SaleTypeOrder')" errorMsg="Please Enter Sale Type Order Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>

        <div class="position-relative  form-group col-sm-6">
            <label for="ActiveStatus" class="col-form-label">Active Status<span>*</span></label>
            <div class="">
                <select  class="form-control" name="ActiveStatus" id="ActiveStatus"  formControlName="ActiveStatus">
                    <option [ngValue]="null" disabled selected >Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </select>
            <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Select Status"   style="background-color: #f8d7da;   border-color: #f5c6cb;" class="alert-danger">
            </app-field-error-display>
            </div>
        </div>
        
       

        <div class="position-relative  form-check col-sm-6 offset-sm-6">
            <div class="">
                <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
            </div>
        </div>
   
</div>

