import { Component, OnInit , Inject} from '@angular/core';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer";  

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { printDiv } from './print-div'; 

import { Route, Router } from '@angular/router';
import { ApiserviceService } from '../api/apiservice.service';
export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor:string;
}
@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.css']
})
export class VendorListComponent implements OnInit {
 
    productDialog: boolean;
  
    products: Billdesk[];
    productsbyid: Billdesk[];
  
    data:number=0;
  
    dialogdata: string;
   
  
    
    organizationList: Billdesk[];
  
    product: Billdesk;
    billdesk:any;
    selectedProducts:any;
    insertdata:any;
    updatedata:any;
    submitted: boolean;
  //   OrganizationListResult:any[];
    statuses: any[];
    cities: City[];
    customers: Customer[];
    successDialog:boolean;
    insertDialog:boolean;
    representatives: Representative[];
    updateDialog:boolean;
    loading: boolean = true;
    inventoryStatus: Representative[];
    selectedCities: City[];

    ExistInv:any;
  //   OrganizationListResult:any;
    constructor(private billdeskService: BilldeskService, private messageService: MessageService, private confirmationService: ConfirmationService,public dialog: MatDialog,private route:Router,private apiservice:ApiserviceService) { 
  
        this.cities = [
            {name: 'New York', code: 'NY'},
            {name: 'Rome', code: 'RM'},
            {name: 'London', code: 'LDN'},
            {name: 'Istanbul', code: 'IST'},
            {name: 'Paris', code: 'PRS'}
        ];
  
    }
  
    ngOnInit() {
  
  
    
      this.ExistInv = localStorage.getItem('exist');  
  
      this.insertdata=  localStorage.getItem('insertdata');
      
      this.updatedata=  localStorage.getItem('updatedata');
 
      
      if(this.insertdata=='Success'){
      
        this.organizationRegister();
 
      
      } 
  
      if(this.updatedata=='Success'){
      
        this.organizationUpdate();
 
      
      } 
   
      if (this.ExistInv == 'exist') {

        this.VendorExist();
  
      }
       
 var RequestUrl="/vendor/vendorlist";
    var ResponseData="vendorlistResult";
    this.apiservice.getListData(RequestUrl,ResponseData).then(res =>{

      this.products = res

      console.log(this.products);
      
    } );
  //  this.billdeskService.getVendorList().then(res => this.products = res);
   

 

        this.representatives = [
            { inventoryStatus: "INSTOCK", image: "INSTOCK" },
            { inventoryStatus: "LOWSTOCK", image: "LOWSTOCK" },
            { inventoryStatus: "OUTOFSTOCK", image: "OUTOFSTOCK" },
            
          ];
  
  
          this.statuses = [
            { label: "In Active", value: "0" , IfValue: "In Active" },
            { label: "Active", value: "1" , IfValue: "Active" } 
          ];
  
    }
  
    openNew() {
        // this.product = {};
        this.submitted = false;
        this.productDialog = true;
    }
  
    deleteSelectedProducts() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete the selected products?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.products = this.products.filter(val => !this.selectedProducts.includes(val));
                this.selectedProducts = null;
                this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
            }
        });
    }
  
  //   editProduct(product: Billdesk ) {
  //       this.Billdesk = {...product};
  //       this.productDialog = true;
  //   }
  
  viewProduct(VendorID: Billdesk) {
    // this.product = {...product};
    this.productDialog = true; 
  this.billdeskService.getVendorByid(VendorID).then((res:any) =>{

    let detailObj = res && res[0] ? res[0] : {};
    detailObj['ActiveStatus'] = this.billdeskService.isActive(detailObj.ActiveStatus || '');

    this.productsbyid = {...detailObj};
  } );
}

  
  successButton() {
     
      this.successDialog = true;
  }
  
  
  
  
  
  
  
  
  handleChange(evt,UserID) {
    var name = evt.target.name;
 
   let updateurl= "/vendor/vendorregistrationupdatestatus";
   if (name=='radio') {
    
    var activestatus= 1;
   }
   else
   { 
     var activestatus= 0;

   }
   var datarequest={ 
              "dataobject":[{
              "UserID":UserID, 
              "ActiveStatus":activestatus
               }]}; 
   this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{ 
    if(res.vendorregistrationupdatestatusResult.Message=='Sucess'){
 
     window.location.reload();
     this.route.navigate(['/Vendor-List'])
     localStorage.setItem('updatedata', 'Success');
   }else{
    //  console.log('Please Try Again Later');
   }
   });
 }

   
    openDialog(): void {
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        width: '250px',
        data: {Service: "Vendor", Detail: "Register Successfully",Backgroundcolor: "#47c9a2"}
      });
  
      dialogRef.afterClosed().subscribe(result => {
 
        this.dialogdata = result;
      });
    }
  
  
    openDialogUpdate(): void {
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        width: '250px',
        data: {Service: "Vendor ", Detail: "Update Successfully" ,Backgroundcolor: "#137c95"}
      });
  
      dialogRef.afterClosed().subscribe(result => { 
        this.dialogdata = result;
      });
    }
  


    openDialogVendor(): void {
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        width: '250px',
        data: {Service: "Vendor ", Detail: "Login ID Already Exist  , Please Try another " ,Backgroundcolor: "#FF0000"}
      });
  
      dialogRef.afterClosed().subscribe(result => { 
        this.dialogdata = result;
      });
    }
  
    
    openDialogDelete(): void {
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        width: '250px',
        data: {Service: "Organization", Detail: "Delete Successfully" ,Backgroundcolor: "#ff4141"}
      });
  
      dialogRef.afterClosed().subscribe(result => { 
        window.location.reload();
        this.dialogdata = result;
      });
    }
   
  
  
  organizationRegister() {
    localStorage.removeItem('insertdata'); 
// this code for open dialog after 5 second
    setTimeout(() => {
      // this.insertDialog = true;
  this.openDialog();
  
  }, 500);
  }
  organizationUpdate() {
    localStorage.removeItem('updatedata'); 
// this code for open dialog after 5 second
    setTimeout(() => {
      // this.insertDialog = true;
  this.openDialogUpdate();
  
  }, 500);
  }
   
  
  VendorExist() {
    localStorage.removeItem('exist'); 
// this code for open dialog after 5 second
    setTimeout(() => {
      // this.insertDialog = true;
  this.openDialogVendor();
  
  }, 500);
  }
   
  

  
  statusupdateButton() {
    this.updateDialog = true;
  }
  
  
  
    deleteProduct(product: Billdesk) {
  
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + product.OrgID + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
  
    
this.billdeskService.DeleteOrganizationByid(product.OrgID).then(res => this.products = res);
        
              // this.successButton();
  
              this.openDialogDelete();
          
          }
        });
    }
  
    hideDialog() {
        this.productDialog = false;
        this.submitted = false;
    }
    
    saveProduct() {
        this.submitted = true;
  
        if (this.product.name.trim()) {
            if (this.product.id) {
                this.products[this.findIndexById(this.product.id)] = this.product;                
              //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
              this.statusupdateButton();
            }
            else {
                this.product.id = this.createId();
                this.product.image = 'product-placeholder.svg';
                this.products.push(this.product);
              //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
            }
  
          //   this.products = [...this.products];
            this.productDialog = false;
            // this.product = {};
        }
    }
  
    findIndexById(id: string): number {
        let index = -1;
        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].id === id) {
                index = i;
                break;
            }
        }
  
        return index;
    }
  
    createId(): string {
        let id = '';
        var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for ( var i = 0; i < 5; i++ ) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    print_page(){ 
          setTimeout(() =>printDiv('content'), 3000);
         }


  }
  interface City {
    name: string,
    code: string
  }
  
  
  
  // same sa new page
  
  @Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: '../alertbox/success_alert.html',
  })
  export class DialogOverviewExampleDialog {
  
    constructor(
      public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }