import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bill-invoice-registration',
  templateUrl: './bill-invoice-registration.component.html',
  styleUrls: ['./bill-invoice-registration.component.css']
})
export class BillInvoiceRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
