<form class="form-horizontal" [formGroup]="form">
    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">VENDOR REGISTRATION</h1>
<div class="card-body" style="background-color:#fff;  ">
    <h1 class="card-title">
       Vendor Registration
    </h1>
    <div  class="row">
        <div class="position-relative  form-group col-sm-6">
            <label for="VendorName" class="col-form-label">Vendor Name<span>*</span></label>
            <div   [ngClass]="displayFieldCss('VendorName')">
                <input type="text" name="VendorName" id="VendorName" class="form-control" formControlName="VendorName">


                <app-field-error-display [displayError]="isFieldValid('VendorName')" errorMsg="Please Enter Vendor  Name"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
               </app-field-error-display>
            </div>
        </div>
     
        <div class="position-relative  form-group col-sm-6">
            <label for="VendorMobileNo" class="col-form-label">Mobile No<span>*</span></label>
            <div class="">
                <input type="number" name="VendorMobileNo" id="VendorMobileNo" class="form-control" formControlName="VendorMobileNo">
                <app-field-error-display [displayError]="isFieldValid('VendorMobileNo')" errorMsg="Please Enter Mobile No"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="VendorEmailID" class="col-form-label">Emailid <span>*</span></label>
            <div class="">
                <input type="text" name="VendorEmailID" id="VendorEmailID" class="form-control" formControlName="VendorEmailID">
                <app-field-error-display [displayError]="isFieldValid('VendorEmailID')" errorMsg="Please Enter Email Id"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>       
        <div class="position-relative  form-group col-sm-6">
            <label for="Website" class="col-form-label">Website <span>*</span></label>
            <div class="">
                <input type="text" name="Website" id="Website" class="form-control"  formControlName="Website">

                <app-field-error-display [displayError]="isFieldValid('Website')" errorMsg="Please Enter Website"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="GSTN" class="col-form-label">GISTN <span>*</span></label>
            <div class="">
                <input type="text" name="GSTN" id="GSTN" class="form-control" formControlName="GSTN">
       <app-field-error-display [displayError]="isFieldValid('GSTN')" errorMsg="Please Enter GSTN"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        
        <div class="position-relative  form-group col-sm-6">
            <label for="PANNo" class="col-form-label">PANCard <span>*</span></label>
            <div class="">
                <input type="text" name="PANNo" id="PANNo" class="form-control" formControlName="PANNo">
                <app-field-error-display [displayError]="isFieldValid('PANNo')" errorMsg="Please Enter PANNo"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>   
        <div class="position-relative  form-group col-sm-6">
            <label for="UserLoginID" class="col-form-label">Login ID<span>*</span></label>
            <div class="">
                <input type="text" name="UserLoginID" id="UserLoginID" class="form-control" formControlName="UserLoginID">
                <app-field-error-display [displayError]="isFieldValid('UserLoginID')" errorMsg="Please Enter User  LoginID"   style="background-color: #91696c;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>


        <div class="position-relative  form-group col-sm-6">
            <label for="UserPassword" class="col-form-label">Password<span>*</span></label>
            <div class="">
                <input type="password" name="UserPassword" id="UserPassword" class="form-control" formControlName="UserPassword">
                <app-field-error-display [displayError]="isFieldValid('UserPassword')" errorMsg="Please Enter User Password"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="CIN" class="col-form-label">CIN <span>*</span></label>
            <div class="">
                <input type="text" name="CIN" id="CIN" class="form-control" formControlName="CIN">
                <app-field-error-display [displayError]="isFieldValid('CIN')" errorMsg="Please Enter CIN"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div> 
        <div class="position-relative  form-group col-sm-6">
            <!-- <label for="UserPassword" class="col-form-label">User Type: Vendor</label> -->
            <!-- <div class="">
                <input type="password" name="UserPassword" id="UserPassword" class="form-control" formControlName="UserPassword">
                <app-field-error-display [displayError]="isFieldValid('UserPassword')" errorMsg="Please Enter User Password"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div> -->
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="UserPassword" class="col-form-label">User Type: Vendor</label>
            <!-- <div class="">
                <input type="password" name="UserPassword" id="UserPassword" class="form-control" formControlName="UserPassword">
                <app-field-error-display [displayError]="isFieldValid('UserPassword')" errorMsg="Please Enter User Password"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display>
            </div> -->
        </div>
    </div>
    </div>
    <div class="card-body" style="background-color:#fff;  ">
        <h1 class="card-title">
           Bank Details
        </h1>
        <div  class="row">
        <!-- <div class="position-relative  form-group col-sm-6">
            <label for="ItemCategoryID" class="col-form-label">ItemCategoryID <span>*</span></label>
            <div class="">
                <input type="number" name="ItemCategoryID" id="ItemCategoryID" class="form-control" formControlName="ItemCategoryID">

                <app-field-error-display [displayError]="isFieldValid('ItemCategoryID')" errorMsg="Please Enter ItemCategoryID"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>  -->
        
        
        <div class="position-relative  form-group col-sm-6">
            <label for="BankHolderName" class="col-form-label">BankHolderName <span>*</span></label>
            <div class="">
                <input type="text" name="BankHolderName" id="BankHolderName" class="form-control" formControlName="BankHolderName">

                <app-field-error-display [displayError]="isFieldValid('BankHolderName')" errorMsg="Please Enter BankHolder Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>

            
        <div class="position-relative  form-group col-sm-6">
            <label for="BankName" class="col-form-label">BankName <span>*</span></label>
            <div class="">
                <input type="text" name="BankName" id="BankName" class="form-control" formControlName="BankName">

                <app-field-error-display [displayError]="isFieldValid('BankName')" errorMsg="Please Enter Bank Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>

        <div class="position-relative  form-group col-sm-6">
            <label for="BankBranchName" class="col-form-label">Bank Branch <span>*</span></label>
            <div class="">
                <input type="text" name="BankBranchName" id="BankBranchName" class="form-control" formControlName="BankBranchName">

                <app-field-error-display [displayError]="isFieldValid('BankBranchName')" errorMsg="Please Enter BanckBranch  Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>          
        <div class="position-relative  form-group col-sm-6">
            <label for="BankAcNo" class="col-form-label">BanckAccountNo <span>*</span></label>
            <div class="">
                <input type="number" name="BankAcNo" id="BankAcNo" class="form-control" formControlName="BankAcNo">

                <app-field-error-display [displayError]="isFieldValid('BankAcNo')" errorMsg="Please Enter Bank Account Number"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>   
        <div class="position-relative  form-group col-sm-6">
            <label for="IFSCCode" class="col-form-label">BankIFSCCode <span>*</span></label>
            <div class="">
                <input type="text" name="IFSCCode" id="IFSCCode" class="form-control"  formControlName="IFSCCode">

                
                <app-field-error-display [displayError]="isFieldValid('IFSCCode')" errorMsg="Please Enter IFSC Code"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>   
        <div class="position-relative  form-group col-sm-6">
            <label for="SwiftCode" class="col-form-label">SwiftCode </label>
            <div class="">
                <input type="text" name="SwiftCode" id="SwiftCode" class="form-control" formControlName="SwiftCode">
                <app-field-error-display [displayError]="isFieldValid('SwiftCode')" errorMsg="Please Enter SwiftCode"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        </div></div> <div class="card-body" style="background-color:#fff;  ">
            <h1 class="card-title">
               Contact Details
            </h1>
            <div  class="row">
        <div class="position-relative  form-group col-sm-6">
            <label for="AuthPersonName" class="col-form-label">ContactPersonName</label>
            <div class="">
                <input type="text" name="AuthPersonName" id="AuthPersonName" class="form-control" formControlName="AuthPersonName">
                <app-field-error-display [displayError]="isFieldValid('AuthPersonName')" errorMsg="Please Enter Contact Person Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="AuthPersonMobileNo" class="col-form-label">ContactPersonNo </label>
            <div class="">
                <input type="text" name="AuthPersonMobileNo" id="AuthPersonMobileNo" class="form-control" formControlName="AuthPersonMobileNo">

                <app-field-error-display [displayError]="isFieldValid('AuthPersonMobileNo')" errorMsg="Please Enter Contact Person Name"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="AuthPersonEmailID" class="col-form-label">AuthPersonEmailID </label>
            <div class="">
                <input type="text" name="AuthPersonEmailID" id="AuthPersonEmailID" class="form-control" formControlName="AuthPersonEmailID">

                <app-field-error-display [displayError]="isFieldValid('AuthPersonEmailID')" errorMsg="Please Enter Contact Email"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="SupportUrl" class="col-form-label">SupportUrl</label>
            <div class="">
                <input type="text" name="SupportUrl" id="SupportUrl" class="form-control"  formControlName="SupportUrl">
                <app-field-error-display [displayError]="isFieldValid('SupportUrl')" errorMsg="Please Enter Support Url"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>   
        <div class="position-relative  form-group col-sm-6">
            <label for="SupportLoginID" class="col-form-label">SupportLogin</label>
            <div class="">
                <input type="text" name="SupportLoginID" id="SupportLoginID" class="form-control" formControlName="SupportLoginID">

                <app-field-error-display [displayError]="isFieldValid('SupportLoginID')" errorMsg="Please Enter Support Login"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="SupportPassword" class="col-form-label">SupportPassword</label>
            <div class="">
                <input type="text" name="SupportPassword" id="SupportPassword" class="form-control" formControlName="SupportPassword">

                <app-field-error-display [displayError]="isFieldValid('SupportPassword')" errorMsg="Please Enter Support Password"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="VendorState" class="col-form-label">State <span>*</span></label>
            <div class="">
                 <p-dropdown [options]="stateList" [(ngModel)]="selectedStateID" optionLabel="StateName" [filter]="true"
                    filterBy="StateName" [showClear]="true" [placeholder]="selectedStateName" (onChange)="changeState($event)"
                    name="VendorState" id="VendorState" formControlName="VendorState" [style]="{ width: '100%' }">
                    <ng-template let-country pTemplate="item">
                        <div class="country-item">
                            <div>{{country.StateName}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <app-field-error-display [displayError]="isFieldValid('VendorState')" errorMsg="Please Enter State" style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                </app-field-error-display>
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="VendorCity" class="col-form-label">City <span>*</span></label>
            <div class=""> 
                 <p-dropdown [options]="cityList" [(ngModel)]="selectedCityID" optionLabel="DistrictName" [filter]="true"
                    filterBy="DistrictName" [showClear]="true" [placeholder]="selectedCityName" (onChange)="changeCity($event)"
                    name="VendorCity" id="VendorCity" formControlName="VendorCity" [style]="{ width: '100%' }">
        
                    <ng-template let-country pTemplate="item">
                        <div class="country-item">
                            <div>{{country.DistrictName}}</div>
                        </div>
                    </ng-template>
                </p-dropdown> 
                 <app-field-error-display [displayError]="isFieldValid('VendorCity')" errorMsg="Please Enter VendorCity" style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                </app-field-error-display>
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="VendorPinCode" class="col-form-label">PinCode</label>
            <div class="">
                <input type="text" name="VendorPinCode" id="VendorPinCode" class="form-control"  formControlName="VendorPinCode">

                <app-field-error-display [displayError]="isFieldValid('VendorPinCode')" errorMsg="Please Enter PinCode"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>
        <div class="position-relative  form-group col-sm-6">
            <label for="VendorBillingAddress" class="col-form-label">Address  <span>*</span></label>
            <div class="">
                <textarea name="VendorBillingAddress" id="VendorBillingAddress" class="form-control" formControlName="VendorBillingAddress"></textarea>
               
               
               <app-field-error-display [displayError]="isFieldValid('VendorBillingAddress')" errorMsg="Please Enter Address"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>

        <!-- <div class="position-relative  form-group col-sm-6">
            <label for="VendorUPIID" class="col-form-label">VendorUPIID  <span>*</span></label>
            <div class="">
                 
               
           <input type="number" name="VendorUPIID" id="VendorUPIID" class="form-control" formControlName="VendorUPIID" >     
               <app-field-error-display [displayError]="isFieldValid('VendorUPIID')" errorMsg="Please Enter VendorUPIID"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        <div class="position-relative  form-group col-sm-6">
            <label for="VerificationEmailUR" class="col-form-label">VerificationEmailUR  <span>*</span></label>
            <div class="">
                
                <input type="text" name="VerificationEmailUR" id="VerificationEmailUR" class="form-control" formControlName="VerificationEmailUR">
  
              
              <app-field-error-display [displayError]="isFieldValid('VerificationEmailUR')" errorMsg="Please Enter VerificationEmailUR"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        <div class="position-relative  form-group col-sm-6">
            <label for="VerificationEmailOT" class="col-form-label">VerificationEmailOT  <span>*</span></label>
            <div class="">
                 
                <input type="text" name="VerificationEmailOT" id="VerificationEmailOT" class="form-control" formControlName="VerificationEmailOT">
    
                
                <app-field-error-display [displayError]="isFieldValid('VerificationEmailOT')" errorMsg="Please Enter VerificationEmailOT"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        <div class="position-relative  form-group col-sm-6">
            <label for="IsEmailVerified" class="col-form-label">IsEmailVerified  <span>*</span></label>
            <div class="">
                 <input type="text" name="IsEmailVerified" id="IsEmailVerified" class="form-control" formControlName="IsEmailVerified">
    
              
              
              <app-field-error-display [displayError]="isFieldValid('IsEmailVerified')" errorMsg="Please Enter IsEmailVerified"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>



        <div class="position-relative  form-group col-sm-6">
            <label for="VerificationMobileOTP" class="col-form-label">VerificationMobileOTP  <span>*</span></label>
            <div class="">
                 <input type="text" name="VerificationMobileOTP" id="VerificationMobileOTP" class="form-control" formControlName="VerificationMobileOTP">
     
                <app-field-error-display [displayError]="isFieldValid('VerificationMobileOTP')" errorMsg="Please Enter VerificationMobileOTP"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


        
        <div class="position-relative  form-group col-sm-6">
            <label for="IsMobileVerified" class="col-form-label">IsMobileVerified  <span>*</span></label>
            <div class="">
                <textarea name="IsMobileVerified" id="IsMobileVerified" class="form-control" formControlName="IsMobileVerified"></textarea>
                <app-field-error-display [displayError]="isFieldValid('IsMobileVerified')" errorMsg="Please Enter IsMobileVerified"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div> -->

        <div class="position-relative  form-group col-sm-6">
            <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
            <div class="">

    <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus" > 
        <option value="1">Active</option>
        <option value="0">InActive</option></select>
                 <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                border-color: #f5c6cb;" class="alert-danger">
                </app-field-error-display>
            </div>
        </div>




        <div class="position-relative  form-check col-sm-6 offset-sm-6">
            <div class="">
                <button class="btn btn-primary" (click)="onSubmit()" >Submit</button>
            </div>
        </div>
    <!-- </form> -->
</div>

