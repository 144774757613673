import { Component, OnInit, Inject,ViewChild,ElementRef } from '@angular/core';
import { Product } from '../product';
import { ProductService } from '../productservice';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer"; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { printDiv } from './print-div';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 

import { environment } from '../../environments/environment';

import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-pobathlistyearandmonth',
  templateUrl: './pobathlistyearandmonth.component.html',
  styleUrls: ['./pobathlistyearandmonth.component.css']
})
export class PobathlistyearandmonthComponent implements OnInit {
  products: Product[];
  Totalamount:any;
  Totalinvoice:any;
  empList:any[];
  UserID:any;
  UserTypeID:any;
  DiscountAmount:any;
  TDSInAmount:any;
  TotInvoice:any;
  invoiceCount:any[];
  product: Product;
  id:any;
  i:any=0;
  sumNumber:any;
  sumnoofinvoicearray:any; 
  sumigstamountarray:any;
  sumdiscountdetailarray:any;
  sumtotalnetpayarray:any;

  sumnoofinvoice:any; 
  sumigstamount:any;
  sumdiscountdetail:any;
  sumtotalnetpay:any;

  ServiceUrl=environment.baseUrl;
  TotalTdsinamount:any;
  TotalDiscountAmount:any;
  PoBatchNo:any;
  // ServiceUrl=environment.baseUrl;
  CreatedDate:any;
  productDialog: boolean;
 
  dialogdata: string;
 
  compleatearray:any;
  selectedProducts:any;

  submitted: boolean;

  statuses: any[];
 
  customers: Customer[];
  insertdata:any;
  updatedata:any;
  representatives: Representative[];

  loading: boolean = true;
 
  yearsdetail:any;
  months:any;
  newArr:any;
  paymentform: FormGroup;
  MonthShow:any;
  YearShow:any;
  TotBIllAmount:any
TotTdsAmount:any
TotDiscount:any
TotNetPayble:any
  showmonth=false;
  array:any;
  groupArr:any;
  constructor(private http:HttpClient,private fb: FormBuilder,private productService: ProductService, private messageService: MessageService, private confirmationService: ConfirmationService,public dialog: MatDialog,public billdeskservice:BilldeskService) { 
 
    this.UserTypeID=localStorage.getItem('UserTypeID'); 
 
   this.UserID=localStorage.getItem('UserID'); 
  
  }
 
  ngOnInit(): void {

 
    this.paymentform = this.fb.group({ 
      
      InvoiceYear: [null, Validators.required],
      InvoiceMonth: [null, Validators.required]
 
      }); 
    this.yearsdetail=["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"];
    this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
 

  }


 

  print_page(){
    // this.buttonhide=false;

 
    const title =  "Sheet-B-II-"+this.YearShow+"_TO_"+this.MonthShow;
//  const title="myfiledownload";
    setTimeout(() =>printDiv('content',title), 3000);
    
       }


  getPODetail(){
    this.TotInvoice=0; 
       
    this.TotBIllAmount=0; 

    
    this.TotTdsAmount=0; 
    
    this.TotDiscount=0; 
    
    this.TotNetPayble=0; 
  

var InvoiceMonth =this.paymentform.get('InvoiceMonth').value+'-31';

var InvoiceYear =this.paymentform.get('InvoiceYear').value+'-01';
 
 this.showmonth=true;
this.YearShow=InvoiceYear;
this.MonthShow=InvoiceMonth;
     
 this.billdeskservice.getPObatchReportYearMonthWise(InvoiceYear,InvoiceMonth).then(data => {
          
          this.products = data;
        
          this.array = this.products;
 
              this.groupArr = this.array.reduce((r,{MonthYear})=>{
                  if(!r.some(o=>o.MonthYear==MonthYear)){
                    r.push({MonthYear,groupItem:this.array.filter(v=>v.MonthYear==MonthYear)});
              }
              return r;
              },[]);

              const total= this.groupArr.length; 
     
              this.compleatearray=[]
              for (let i = 0; i < total; i++) { 
              const totalgroupitem=this.groupArr[i].groupItem.length;
       
              this.TotInvoice=[];  
       
              this.sumnoofinvoicearray=[]; 

              
              this.sumigstamountarray=[]; 
              
              this.sumdiscountdetailarray=[]; 
              
              this.sumtotalnetpayarray=[]; 
              
              
              
              for (let j = 0; j < totalgroupitem; j++) { 
                
                  this.TotInvoice.push(this.groupArr[i].groupItem[j].TotalCostWithoutGST);
           
                
                  this.sumNumber = this.TotInvoice.reduce((acc, cur) => acc + Number(cur), 0)
           
 

// sumofinvoice

                this.sumnoofinvoicearray.push(this.groupArr[i].groupItem[j].TotInvoice);
 
                this.sumnoofinvoice = this.sumnoofinvoicearray.reduce((acc, cur) => acc + Number(cur), 0)
      
// sumigstamount


                this.sumigstamountarray.push(this.groupArr[i].groupItem[j].IGSTAMT);
     

                this.sumigstamount = this.sumigstamountarray.reduce((acc, cur) => acc + Number(cur), 0)
          

 // sumdiscountdetail


                this.sumdiscountdetailarray.push(this.groupArr[i].groupItem[j].DiscountAmount);
      
                this.sumdiscountdetail = this.sumdiscountdetailarray.reduce((acc, cur) => acc + Number(cur), 0)
         

 // sumtotalnetpay


              this.sumtotalnetpayarray.push(this.groupArr[i].groupItem[j].TotalPayableAmount);
       
              this.sumtotalnetpay = this.sumtotalnetpayarray.reduce((acc, cur) => acc + Number(cur), 0)
       

                
              } 
           
        
              var obj;
              obj = {
                Total: this.sumNumber ,
                TotInvoice:this.sumnoofinvoice,
                TotTdsAmount :this.sumigstamount,
                TotDiscount :this.sumdiscountdetail,
                TotNetPayble :this.sumtotalnetpay
              };
              this.compleatearray.push(obj);
              }  
              this.newArr = this.groupArr.map((item, index) => ({ ...item, TotalwithoutGST: this.compleatearray[index].Total,TotInvoice: this.compleatearray[index].TotInvoice,TotTdsAmount: this.compleatearray[index].TotTdsAmount,TotDiscount: this.compleatearray[index].TotDiscount,TotNetPayble: this.compleatearray[index].TotNetPayble}));
           

        }); 



    
    
 
 
        this.http.get(this.ServiceUrl+'/pobatchregistration/pobatchregistrationlistbydate?FromDate='+InvoiceYear+'&ToDate='+InvoiceMonth).subscribe((profileres:any)=>{
 
const total =profileres.pobatchregistrationlistbydateResult.dataobject.length;
 
         

               this.TotInvoice=[]; 
       
               this.TotBIllAmount=[]; 

               
               this.TotTdsAmount=[]; 
               
               this.TotDiscount=[]; 
               
               this.TotNetPayble=[]; 

        for (let i = 0; i < total; i++) {
    
          this.TotInvoice.push(profileres.pobatchregistrationlistbydateResult.dataobject[i].TotInvoice);
          
          this.TotBIllAmount.push(profileres.pobatchregistrationlistbydateResult.dataobject[i].TotalCostWithoutGST);
          
          this.TotTdsAmount.push(profileres.pobatchregistrationlistbydateResult.dataobject[i].IGSTAMT);
          
          this.TotDiscount.push(profileres.pobatchregistrationlistbydateResult.dataobject[i].DiscountAmount);
          
          this.TotNetPayble.push(profileres.pobatchregistrationlistbydateResult.dataobject[i].TotalPayableAmount);
    
        }  
      
 
        var sumNumber = this.TotInvoice.reduce((acc, cur) => acc + Number(cur), 0)
 
        this.TotInvoice=sumNumber;

        var sumNumber1 = this.TotBIllAmount.reduce((acc, cur) => acc + Number(cur), 0)
    
        this.TotBIllAmount=sumNumber1;

        var sumNumber2 = this.TotTdsAmount.reduce((acc, cur) => acc + Number(cur), 0)
 
       
        


        var sumNumber3 = this.TotDiscount.reduce((acc, cur) => acc + Number(cur), 0)
 
        this.TotDiscount=sumNumber3;

        var sumNumber4 = this.TotNetPayble.reduce((acc, cur) => acc + Number(cur), 0)
  
        this.TotNetPayble=sumNumber4;
  
 
});
 
      
  
 



      } 

}
