
<form class="form-horizontal" [formGroup]="form"> 
    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">MENU REGISTRATION</h1>
    <div class="card-body" style="background-color:#fff;">
        <h1 class="card-title">
           Menu Detail
        </h1>
        <div class="row">
           

            <div class="position-relative  form-group col-sm-6">
                <label for="MenuType" class="col-form-label">Menu Type  <span>*</span></label>
                <div class="">
                    
                    <select class="form-control"   (change)="getMenuType($event)"><option [ngValue]="null"  selected disabled>Please Select Menu Type</option><option  value="primary">Primary</option><option  value="secondary">Secondary</option></select>
                    <!-- <app-field-error-display [displayError]="isFieldValid('MenuType')" errorMsg="Please Enter Menu Type"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display>  -->
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6" [hidden] = "isShow">
                <label for="UnderOfPID" class="col-form-label">Parent Menu<span>*</span></label>
                <div class="">
 
                    <p-dropdown [options]="menulist"  [(ngModel)]="selectedMenuId" optionLabel="MenuName" [filter]="true" filterBy="MenuName"
                    [showClear]="true" [placeholder]="selectedMenuName"  (onChange)="changeMenu($event)" name="UnderOfPID" id="UnderOfPID"     formControlName="UnderOfPID"  [style]="{ width: '100%' }" >
                 
                    <ng-template let-country pTemplate="item">
                        <div class="country-item">
                            <div>{{country.MenuName}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                
                    <!-- <app-field-error-display [displayError]="isFieldValid('UnderOfPID')" errorMsg="Please Select Menu"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                 -->
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="MenuName" class="col-form-label">Menu Name  <span>*</span></label>
                <div class="">
                    <input type="text" name="MenuName" id="MenuName" class="form-control" formControlName="MenuName">
         <app-field-error-display [displayError]="isFieldValid('MenuName')" errorMsg="Please Enter Menu  Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
          
            <div class="position-relative  form-group col-sm-6">
                <label for="MenuDisplayName" class="col-form-label">Display Menu Name  <span>*</span></label>
                <div class="">
                    <input type="text" name="MenuDisplayName" id="MenuDisplayName" class="form-control" formControlName="MenuDisplayName">
         <app-field-error-display [displayError]="isFieldValid('MenuDisplayName')" errorMsg="Please Enter Display Menu  Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
           

            <div class="position-relative  form-group col-sm-6">
                <label for="URL" class="col-form-label">URL <span>*</span></label>
                <div class="">
                    <input type="text" name="URL" id="URL" class="form-control" formControlName="URL">
         <app-field-error-display [displayError]="isFieldValid('URL')" errorMsg="Please Enter URL"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
           

            <div class="position-relative  form-group col-sm-6">
                <label for="Areas" class="col-form-label">Areas <span>*</span></label>
                <div class="">
                    <input type="text" name="Areas" id="Areas" class="form-control" formControlName="Areas">
         <app-field-error-display [displayError]="isFieldValid('Areas')" errorMsg="Please Enter Areas"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
           


            <div class="position-relative  form-group col-sm-6">
                <label for="ControlarName" class="col-form-label">Controlar Name<span>*</span></label>
                <div class="">
                    <input type="text" name="ControlarName" id="ControlarName" class="form-control" formControlName="ControlarName">
         <app-field-error-display [displayError]="isFieldValid('ControlarName')" errorMsg="Please Enter Controlar Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
           


            <div class="position-relative  form-group col-sm-6">
                <label for="ActionName" class="col-form-label">Action Name  <span>*</span></label>
                <div class="">
                    <input type="text" name="ActionName" id="ActionName" class="form-control" formControlName="ActionName">
         <app-field-error-display [displayError]="isFieldValid('ActionName')" errorMsg="Please Enter Action Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
           


            <div class="position-relative  form-group col-sm-6">
                <label for="MenuDesc" class="col-form-label">Menu Desc <span>*</span></label>
                <div class="">
                    <input type="text" name="MenuDesc" id="MenuDesc" class="form-control" formControlName="MenuDesc">
         <app-field-error-display [displayError]="isFieldValid('MenuDesc')" errorMsg="Please Enter Menu Desc"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
           

<!-- 
            <div class="position-relative  form-group col-sm-6">
                <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
                <div class="">
                    <input type="text" name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus">
         <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter Active Status"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
           
            <div class="position-relative  form-group col-sm-6">
                <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
                <div class="">

        <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus"   >
            <option value="1">Active</option>
            <option value="0">InActive</option></select>
                     <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>

          
            <div class="position-relative  form-group col-sm-6">
                <label for="IndividualStatus" class="col-form-label">Active Individual <span>*</span></label>
                <div class="">
                    <!-- <input type="text" name="IFSCCode" id="IFSCCode" class="form-control" formControlName="IFSCCode"> -->

        <select name="IndividualStatus" id="IndividualStatus" class="form-control" formControlName="IndividualStatus">
     
            <option value="1">Active</option>
            <option value="0">InActive</option>
        </select>
                     <app-field-error-display [displayError]="isFieldValid('IndividualStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>





             <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    