import { Injectable } from '@angular/core';

 

@Injectable({
  providedIn: 'root'
})
export class MasterService {
 
  menuname:any;
  isLogin:any;
  constructor() {}

 

gettoken(){ 
  alert('Master call');
  return !!localStorage.getItem("SeesionUser");  
  } 





  isLoggedIn() {
    const loggedIn = localStorage.getItem('isLogin');
 
    if (loggedIn == 'true')
      this.isLogin = true;
    else
      this.isLogin = false;
    return this.isLogin;
  }


  getMenuAccess(currentMenu){
    this.menuname = currentMenu;
    return this.menuname;
  }

 
  



}
