
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core'; 
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BilldeskService } from './billdesk.service'; 

@Injectable({
  providedIn: 'root'
})
export class InterpreterService {
  token:any;

  constructor(private http:BilldeskService){

  }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return from(this.handleAccess(request, next)).pipe(map(this.mapReponse),catchError(this.handleError));        
    } 

    private async handleAccess(req: HttpRequest<any>, next: HttpHandler):Promise<HttpEvent<any>> { 
      const token = localStorage.getItem('ACCESS_TOKEN'); 

      

      let headerObj = {
        'Content-Type': 'application/json'        
      };  
      
     if(req.body && typeof req.body ==='object'){
        delete headerObj['Content-Type'];
      }
      

      if(token && typeof token!=='undefined'){
        headerObj['Authorization'] = 'Bearer '+ token;
      }

      //
      // console.log('ACCESS_TOKEN:::',headerObj);
      var timestamp = new Date().getTime()

 
   
      if ((req.url.includes("?"))){ 
          // alert(req.url+'This is question mark') 
        const changedReq = req.clone({
          headers: new HttpHeaders(headerObj), url: req.url+'&ts='+timestamp
          });
        return next.handle(changedReq).toPromise();  
         }else{
        // alert(req.url+'No Exist') 
        const changedReq = req.clone({
          headers: new HttpHeaders(headerObj), url: req.url+'?ts='+timestamp
          });
          return next.handle(changedReq).toPromise();  
            }  



      
   
      

    }


    private mapReponse = (event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && event.url && event.url.includes("http")) {
        let responseBody =  event.body || {};       
        
        if ((event.url.includes("useraccess/checkuseraccess")) && responseBody.hasOwnProperty('checkuseraccessResult')){            
            let checkUserAccess = responseBody.checkuseraccessResult || {};
            if(checkUserAccess.hasOwnProperty('AccessKey') && checkUserAccess.hasOwnProperty('Status') && checkUserAccess.Status=='200'){               
              localStorage.setItem('ACCESS_TOKEN',checkUserAccess.AccessKey);
            }
        }

        if ((event.url.includes("userlogin/checkuserlogin")) && responseBody.hasOwnProperty('checkuserloginResult')){            
            let checkUserAccess = responseBody.checkuserloginResult || {};
            if(checkUserAccess.hasOwnProperty('Token') && checkUserAccess.hasOwnProperty('Status') && checkUserAccess.Status=='200'){               
              localStorage.setItem('ACCESS_TOKEN',checkUserAccess.Token);
            }
        }    
      return event;
      }
      return event;
    }
    private handleError = (errorResponse: HttpErrorResponse) => { 
 
      if(errorResponse.hasOwnProperty('status') && errorResponse.status==401){
        //TODO ::: redirect to login page 
        localStorage.removeItem('ACCESS_TOKEN');  
        this.http.logout();
        //alert('Token Expired Plese Login again');  
        location.reload();     
      } 
 
      // console.log(errorResponse);
      // if(errorResponse.status==401){ 
      //          localStorage.removeItem('ACCESS_TOKEN');  
        

       
      //   location.reload();

        
      // } 
      return throwError(errorResponse);
    }

}
