import { Component, OnInit, Inject } from '@angular/core';
import { Product } from '../product';
import { ProductService } from '../productservice';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer"; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor:string;
}
 
@Component({
  selector: 'app-po-list',
  templateUrl: './po-list.component.html',
  styleUrls: ['./po-list.component.css']
})
export class PoListComponent implements OnInit {

  productDialog: boolean;

  products: Product[];
  dialogdata: string;
  product: Product;
 
  selectedProducts:any;

  submitted: boolean;

  statuses: any[];
  cities: City[];
  customers: Customer[];
  insertdata:any;
  updatedata:any;
  representatives: Representative[];

  loading: boolean = true;
  inventoryStatus: Representative[];
  selectedCities: City[];
 
  constructor(private productService: ProductService, private messageService: MessageService, private confirmationService: ConfirmationService,public dialog: MatDialog,public billdeskservice:BilldeskService) { 

      this.cities = [
          {name: 'New York', code: 'NY'},
          {name: 'Rome', code: 'RM'},
          {name: 'London', code: 'LDN'},
          {name: 'Istanbul', code: 'IST'},
          {name: 'Paris', code: 'PRS'}
      ];  

  }

  ngOnInit() {



    this.insertdata=  localStorage.getItem('insertdata');
  
    console.log("deat");
    console.log(this.insertdata);
    
 
    
    if(this.insertdata=='Success'){
    
      this.PoRegister();
      console.log("this.insertdata");
      console.log(this.insertdata);
      console.log("this.insertdata");
    
    } 

  
 
    
      console.log(this.billdeskservice.getPoList().then(data => this.products = data));


 
      // this.representatives = [
      //     {inventoryStatus: 'INSTOCK', value: 'instock'},
      //     {inventoryStatus: 'LOWSTOCK', value: 'lowstock'},
      //     {inventoryStatus: 'OUTOFSTOCK', value: 'outofstock'}
      // ];



      this.representatives = [
          { inventoryStatus: "INSTOCK", image: "INSTOCK" },
          { inventoryStatus: "LOWSTOCK", image: "LOWSTOCK" },
          { inventoryStatus: "OUTOFSTOCK", image: "OUTOFSTOCK" },
          
        ];



        this.statuses = [

          { label: "Pending", value: "Pending" },
          { label: "Success", value: "Success" } 

        ];
  }
 PoRegister() {
    localStorage.removeItem('insertdata'); 
    console.log('remove');// this code for open dialog after 5 second
    setTimeout(() => {
      // this.insertDialog = true;
  this.openDialog();
  
  }, 500);
  }
  
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "PO", Detail: "Register Successfully",Backgroundcolor: "#47c9a2"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }

   
  openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
  }

  deleteSelectedProducts() {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete the selected products?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.products = this.products.filter(val => !this.selectedProducts.includes(val));
              this.selectedProducts = null;
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
          }
      });
  }

  editProduct(product: Product) {
      this.product = {...product};
      this.productDialog = true;
  }

  deleteProduct(product: Product) {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + product.name + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.products = this.products.filter(val => val.id !== product.id);
              this.product = {};
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
          }
      });
  }

  hideDialog() {
      this.productDialog = false;
      this.submitted = false;
  }
  
  saveProduct() {
      this.submitted = true;

      if (this.product.name.trim()) {
          if (this.product.id) {
              this.products[this.findIndexById(this.product.id)] = this.product;                
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
          }
          else {
              this.product.id = this.createId();
              this.product.image = 'product-placeholder.svg';
              this.products.push(this.product);
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
          }

        //   this.products = [...this.products];
          this.productDialog = false;
          this.product = {};
      }
  }

  findIndexById(id: string): number {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
              index = i;
              break;
          }
      }

      return index;
  }

  createId(): string {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
  }
}


interface City {
  name: string,
  code: string
}

 
// same sa new page

@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: '../alertbox/success_alert.html',
  })
  export class DialogOverviewExampleDialog {
  
    constructor(
      public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }