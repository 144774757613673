import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appHinglishTyping]'
})
export class HinglishTypingDirective{

  constructor(private _el: ElementRef) { }  

  @HostListener('keyup', ['$event']) onKeyUp(event:any) {     
    this.transferText();
  }

  transferText(){
    let inputElement = this._el.nativeElement;
    let initalValue = inputElement.value; 


    initalValue = initalValue.replace(/&/g, "्");
    initalValue = initalValue.replace(/््/g, "");
    
    initalValue = initalValue.replace(/a/g, "अ");
    initalValue = initalValue.replace(/[Aā]/g, "आ");
    initalValue = initalValue.replace(/i/g, "इ");
    initalValue = initalValue.replace(/[Iī]/g, "ई");
    initalValue = initalValue.replace(/u/g, "उ");
    initalValue = initalValue.replace(/[Uū]/g, "ऊ");
    initalValue = initalValue.replace(/अअ/g, "आ");
    initalValue = initalValue.replace(/इइ/g, "ई");
    initalValue = initalValue.replace(/उउ/g, "ऊ");
    initalValue = initalValue.replace(/e/g, "ए");
    initalValue = initalValue.replace(/o/g, "ओ");
    initalValue = initalValue.replace(/अइ/g, "ऐ");
    initalValue = initalValue.replace(/अउ/g, "औ");
    
    
    // candra 
    initalValue = initalValue.replace(/एए/g, "ऐ");
    initalValue = initalValue.replace(/ऐए/g, "ऍ");
    initalValue = initalValue.replace(/ऍए/g, "ऎ");
    initalValue = initalValue.replace(/ऎए/g, "ए");
    
    initalValue = initalValue.replace(/ेए/g, "ै");
    initalValue = initalValue.replace(/ैए/g, "ॅ");
    initalValue = initalValue.replace(/ॅए/g, "ॆ");
    initalValue = initalValue.replace(/ॆए/g, "े");
    // a
    initalValue = initalValue.replace(/आअ/g, "ऑ");
    initalValue = initalValue.replace(/ऑअ/g, "ऒ");
    initalValue = initalValue.replace(/ऒअ/g, "ओ"); 
    initalValue = initalValue.replace(/ओअ/g, "औ");   
    initalValue = initalValue.replace(/औअ/g, "ॲ");
    initalValue = initalValue.replace(/ॲअ/g, "अ");
    
    initalValue = initalValue.replace(/ाअ/g, "ॉ");
    initalValue = initalValue.replace(/ॉअ/g, "ॊ");
    initalValue = initalValue.replace(/ॊअ/g, "ो");
    initalValue = initalValue.replace(/ोअ/g, "ौ");
    initalValue = initalValue.replace(/ौअ/g, "ा");
    //o
    initalValue = initalValue.replace(/ओओ/g, "औ");   
    initalValue = initalValue.replace(/औओ/g, "ऑ");
    initalValue = initalValue.replace(/ऑओ/g, "ऒ");
    initalValue = initalValue.replace(/ऒओ/g, "ओ");
    
    initalValue = initalValue.replace(/ोओ/g, "ौ");
    initalValue = initalValue.replace(/ौओ/g, "ॉ");
    initalValue = initalValue.replace(/ॉओ/g, "ॊ");
    initalValue = initalValue.replace(/ॊओ/g, "ो");
    
    
    // suppression du virama 
    initalValue = initalValue.replace(/िइ/g, "ी");
    initalValue = initalValue.replace(/ुउ/g, "ू");
    initalValue = initalValue.replace(/्अ/g, "\u200b");
    initalValue = initalValue.replace(/\u200bअ/g, "ा");
    initalValue = initalValue.replace(/\u200bइ/g, "ै");
    initalValue = initalValue.replace(/\u200bउ/g, "ौ");
    initalValue = initalValue.replace(/्आ/g, "ा");
    initalValue = initalValue.replace(/्इ/g, "ि");
    initalValue = initalValue.replace(/्ई/g, "ी");
    initalValue = initalValue.replace(/्उ/g, "ु");
    initalValue = initalValue.replace(/्ऊ/g, "ू");
    initalValue = initalValue.replace(/्ऋ/g, "ृ");
    initalValue = initalValue.replace(/्ॠ/g, "ॄ");
    initalValue = initalValue.replace(/्ऌ/g, "ॢ");
    initalValue = initalValue.replace(/्ॡ/g, "ॣ");
    initalValue = initalValue.replace(/्ए/g, "े");
    initalValue = initalValue.replace(/्ओ/g, "ो");
    
    initalValue = initalValue.replace(/् /g, " ");
    // diacritiques
    initalValue = initalValue.replace(/\u200b॑/g, "॑");
    initalValue = initalValue.replace(/\u200b\ं/g, "ं");
    initalValue = initalValue.replace(/\u200b\ः/g, "ः");
    initalValue = initalValue.replace(/\u200b\॓/g, "॓");
    initalValue = initalValue.replace(/\u200b\॔/g, "॔");
    //initalValue = initalValue.replace(/\u200b\़/g, "़");  supr double emploi
    //initalValue = initalValue.replace(/\u200b\़/g, "ँ");
    
    //cons
    initalValue = initalValue.replace(/n/g, "न्");
    initalValue = initalValue.replace(/k/g, "क्");
    initalValue = initalValue.replace(/g/g, "ग्");
    initalValue = initalValue.replace(/c/g, "च्");
    initalValue = initalValue.replace(/j/g, "ज्");
    initalValue = initalValue.replace(/[TṭṬ]/g, "ट्");
    initalValue = initalValue.replace(/[DḍḌ]/g, "ड्");
    initalValue = initalValue.replace(/[NṇṆ]/g, "ण्");
    initalValue = initalValue.replace(/t/g, "त्");
    initalValue = initalValue.replace(/d/g, "द्");
    initalValue = initalValue.replace(/p/g, "प्");
    initalValue = initalValue.replace(/b/g, "ब्");
    initalValue = initalValue.replace(/m/g, "म्");
    initalValue = initalValue.replace(/q/g, "क़्");
    initalValue = initalValue.replace(/Q/g, "क़्");
    initalValue = initalValue.replace(/क़्/g, "क़्");
    initalValue = initalValue.replace(/X/g, "ख़्");
    initalValue = initalValue.replace(/ख़्/g, "ख़्");
    initalValue = initalValue.replace(/Y/g, "ग़्");
    initalValue = initalValue.replace(/ग़्/g, "ग़्");
    initalValue = initalValue.replace(/[zZ]/g, "ज़्");
    initalValue = initalValue.replace(/ज़्/g, "ज़्");
    initalValue = initalValue.replace(/[fF]/g, "फ़्");
    initalValue = initalValue.replace(/फ़्/g, "फ़्");
    initalValue = initalValue.replace(/[RṚṛ]/g, "ड़्");
    initalValue = initalValue.replace(/ड़्/g, "ड़्");
    initalValue = initalValue.replace(/y/g, "य्");
    initalValue = initalValue.replace(/r/g, "र्");
    initalValue = initalValue.replace(/ऱ्/g, "ऱ्");
    initalValue = initalValue.replace(/l/g, "ल्");
    initalValue = initalValue.replace(/[LḶḷ]/g, "ळ्");
    initalValue = initalValue.replace(/ऴ्/g, "ऴ्");
    initalValue = initalValue.replace(/v/g, "व्");
    initalValue = initalValue.replace(/w/g, "व्");
    initalValue = initalValue.replace(/h/g, "ह्");
    initalValue = initalValue.replace(/[SṣṢ]/g, "ष्");
    initalValue = initalValue.replace(/s/g, "स्");
    // cas particuliers
    initalValue = initalValue.replace(/[ṅG]/g, "ङ्");
    initalValue = initalValue.replace(/[ñJ]/g, "ञ्");
    initalValue = initalValue.replace(/न्ग्/g, "ङ्");
    initalValue = initalValue.replace(/न्ज्/g, "ञ्");
    
    // aspirées
    initalValue = initalValue.replace(/क्ह्/g, "ख्");
    initalValue = initalValue.replace(/ग्ह्/g, "घ्");
    initalValue = initalValue.replace(/च्ह्/g, "छ्");
    initalValue = initalValue.replace(/ज्ह्/g, "झ्");
    initalValue = initalValue.replace(/ट्ह्/g, "ठ्");
    initalValue = initalValue.replace(/ड्ह्/g, "ढ्");
    initalValue = initalValue.replace(/त्ह्/g, "थ्");
    initalValue = initalValue.replace(/द्ह्/g, "ध्");
    initalValue = initalValue.replace(/त्ह्/g, "थ्");
    initalValue = initalValue.replace(/द्ह्/g, "ध्");
    initalValue = initalValue.replace(/प्ह्/g, "फ्");
    initalValue = initalValue.replace(/ब्ह्/g, "भ्");
    initalValue = initalValue.replace(/ड़्ह्/g, "ढ़्");
    
    initalValue = initalValue.replace(/ढ़्/g, "ढ़्");
    
    // cas du s barre
    initalValue = initalValue.replace(/स्ह्/g, "श्");
    initalValue = initalValue.replace(/[çzśŚ]/g, "श्");
    
    // cas du ri li 
    initalValue = initalValue.replace(/्-र्/g, "ृ");
    initalValue = initalValue.replace(/-र्/g, "ऋ");
    initalValue = initalValue.replace(/ऋइ/g, "ॠ");
    initalValue = initalValue.replace(/ृइ/g, "ॄ");
    
    initalValue = initalValue.replace(/्-ल्/g, "ॢ");
    initalValue = initalValue.replace(/-ल्/g, "ऌ");
    initalValue = initalValue.replace(/ऌइ/g, "ॡ");
    initalValue = initalValue.replace(/ॢइ/g, "ॣ");
    
    //suppression du zero
    initalValue = initalValue.replace(/\u200bक/g, "क");
    initalValue = initalValue.replace(/\u200bख/g, "ख");
    initalValue = initalValue.replace(/\u200bग/g, "ग");
    initalValue = initalValue.replace(/\u200bघ/g, "घ");
    initalValue = initalValue.replace(/\u200bङ/g, "ङ");
    initalValue = initalValue.replace(/\u200bच/g, "च");
    initalValue = initalValue.replace(/\u200bछ/g, "छ");
    initalValue = initalValue.replace(/\u200bज/g, "ज");
    initalValue = initalValue.replace(/\u200bझ/g, "झ");
    initalValue = initalValue.replace(/\u200bञ/g, "ञ");
    initalValue = initalValue.replace(/\u200bट/g, "ट");
    initalValue = initalValue.replace(/\u200bठ/g, "ठ");
    initalValue = initalValue.replace(/\u200bड/g, "ड");
    initalValue = initalValue.replace(/\u200bढ/g, "ढ");
    initalValue = initalValue.replace(/\u200bण/g, "ण");
    initalValue = initalValue.replace(/\u200bत/g, "त");
    initalValue = initalValue.replace(/\u200bथ/g, "थ");
    initalValue = initalValue.replace(/\u200bद/g, "द");
    initalValue = initalValue.replace(/\u200bध/g, "ध");
    initalValue = initalValue.replace(/\u200bन/g, "न");
    initalValue = initalValue.replace(/\u200bप/g, "प");
    initalValue = initalValue.replace(/\u200bफ/g, "फ");
    initalValue = initalValue.replace(/\u200bब/g, "ब");
    initalValue = initalValue.replace(/\u200bभ/g, "भ");
    initalValue = initalValue.replace(/\u200bम/g, "म");
    initalValue = initalValue.replace(/\u200bक़/g, "क़");
    initalValue = initalValue.replace(/\u200bख़/g, "ख़");
    initalValue = initalValue.replace(/\u200bग़/g, "ग़");
    initalValue = initalValue.replace(/\u200bज़/g, "ज़");
    initalValue = initalValue.replace(/\u200bड़/g, "ड़");
    initalValue = initalValue.replace(/\u200bढ़/g, "ढ़");
    initalValue = initalValue.replace(/\u200bफ़/g, "फ़");
    initalValue = initalValue.replace(/\u200bय/g, "य");
    initalValue = initalValue.replace(/\u200bर/g, "र");
    initalValue = initalValue.replace(/\u200bल/g, "ल");
    initalValue = initalValue.replace(/\u200bळ/g, "ळ");
    initalValue = initalValue.replace(/\u200bव/g, "व");
    initalValue = initalValue.replace(/\u200bह/g, "ह");
    initalValue = initalValue.replace(/\u200bश/g, "श");
    initalValue = initalValue.replace(/\u200bष/g, "ष");
    initalValue = initalValue.replace(/\u200bस/g, "स");
    initalValue = initalValue.replace(/\u200b /g, " ");
    
    // accents 
    initalValue = initalValue.replace(/\u200b\॓/g, "॓");
    initalValue = initalValue.replace(/\u200b\॔/g, "॔");
    
    // anusvara
    initalValue = initalValue.replace(/[MṃṂṁ]/g, "ं");
    initalValue = initalValue.replace(/\u200bं/g, "ं");
    initalValue = initalValue.replace(/्ं/g, "ं");
    // + candrabindu 
    initalValue = initalValue.replace(/ंं/g, "ँ");
    initalValue = initalValue.replace(/ँं/g, "");
    initalValue = initalValue.replace(/\u200bँ/g, "ँ");
    //OM
    initalValue = initalValue.replace(/O/g, "ॐ");
    initalValue = initalValue.replace(/ॐं/g, "ॐ");
    // visarga
    initalValue = initalValue.replace(/[HḥḤ]/g, "ः");
    initalValue = initalValue.replace(/्ः/g, "ः");
    initalValue = initalValue.replace(/\u200b\ः/g, "ः");
    // + nukta
    initalValue = initalValue.replace(/्=/g, "़्");
    initalValue = initalValue.replace(/=/g, "़");
    initalValue = initalValue.replace(/\u200b़/g, "़");
    // anudatta + udatta
    initalValue = initalValue.replace(/_/g, "॒");
    initalValue = initalValue.replace(/\u200b॒/g, "॒");
    initalValue = initalValue.replace(/॒॒/g, "॑");
    initalValue = initalValue.replace(/॒॑/g, "");
    initalValue = initalValue.replace(/\u200b॑/g, "॑");
    
    
    // alternative : initalValue = initalValue.replace(/:/g, "ः");
    // avagraha
    initalValue = initalValue.replace(/\'/g, "ऽ");
    initalValue = initalValue.replace(/’/g, "ऽ");
    
    //half conso pour sanskrit
    initalValue = initalValue.replace(/x/g, "\u200d");
    initalValue = initalValue.replace(/\u200d\u200d/g, "\u200c");
    
    
    // ponctuation
    initalValue = initalValue.replace(/\|/g, "।");
    initalValue = initalValue.replace(/\//g, "।");
    initalValue = initalValue.replace(/।।/g, "॥");
    
    initalValue = initalValue.replace(/0\+/g, "०");
    initalValue = initalValue.replace(/1\+/g, "१");
    initalValue = initalValue.replace(/2\+/g, "२");
    initalValue = initalValue.replace(/3\+/g, "३");
    initalValue = initalValue.replace(/4\+/g, "४");
    initalValue = initalValue.replace(/5\+/g, "५");
    initalValue = initalValue.replace(/6\+/g, "६");
    initalValue = initalValue.replace(/7\+/g, "७");
    initalValue = initalValue.replace(/8\+/g, "८");
    initalValue = initalValue.replace(/9\+/g, "९");
    



    let startPos = inputElement.selectionStart;
    let endPos = inputElement.selectionEnd;

    let beforeLen = inputElement.value.length;
    let afterLen = initalValue.length;
    let adjustment = afterLen - beforeLen;

    inputElement.value = initalValue;

    inputElement.selectionStart = startPos + adjustment;
    inputElement.selectionEnd = endPos + adjustment;

    inputElement.focus();
    inputElement.scrollTop = inputElement.scrollHeight;


  }//EOF transferText

}
