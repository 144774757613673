<style>
  td {

    color: rebeccapurple;
  }
</style>


<!-- <app-single-selection-examplee></app-single-selection-examplee> -->
<div class="login-main-bg">
  <div class="text-center">

  </div>

  <div class="container">


    <!-- <button type="button" pButton pRipple (click)="showError()" label="Error" class="p-button-danger"></button> -->
    <div class="row" *ngIf="isLoaded">
      <div class="col-sm-12">

        <p><mat-card class="example-card dashbord-card">
            <mat-card-header>



              <div class="container" style="color: rebeccapurple;">
                <h1><mat-card-title style="text-align: center;font-size: larger;font-family: fantasy;"><span
                      style="color:rebeccapurple;">Welcome-</span> {{UserFullName}}<span
                      style="font-size: 16px;">({{RoleTypeName}})</span></mat-card-title>  <button class="btn btn-info"  style="float: right;"  (click)="statusUpdateDialog()" >Set Pin</button></h1>
                <div class="row">
                  <div class="col-sm-4">
                    <p>Mobile: {{UserMobile}}</p>
                  </div>


                  <div class="col-sm-4">
                    <p>Email:{{UserEmail}}</p>
                  </div>

                  <div class="col-sm-4">

                   
                    <p>Role:{{RoleTypeName}}</p>
                  </div>

                </div>
                <div class="row" *ngIf="isvendor">
                  <div class="col-sm-4">
                    <p>Organization:{{UserOrgName}}</p>
                  </div>
                  <div class="col-sm-4">
                    <p>Division:{{UserDivName}}</p>
                  </div>

                  <div class="col-sm-4">
                    <p>Department:{{UserDeptName}}</p>
                  </div>




                </div>
              </div>

            </mat-card-header>
            <mat-card-content>

            </mat-card-content>
            <mat-card-actions>

            </mat-card-actions>
          </mat-card>
        </p>
      </div>
      <div class="row" style=" max-width: 102%;" *ngIf="isApprover">


        <div class="col-sm-4">

          <p><mat-card class="example-card dashbord-card " style="height: 153px;">
              <mat-card-header *ngIf="approve_inv">

                <mat-card-title>Approved Invoice</mat-card-title>
                <mat-card-subtitle style="color: #009419;">Total
                  Invoice:{{approve_inv.Tot_Registration}}</mat-card-subtitle>

                <mat-card-subtitle style="color: #002bff;">Total
                  Amount:{{approve_inv.Tot_Reg_Amount}}</mat-card-subtitle>


              </mat-card-header>

            </mat-card>
          </p>
        </div>

        <div class="col-sm-4">

          <p>
            <mat-card class="example-card dashbord-card" style="height: 153px;">
              <mat-card-header *ngIf="paid_inv">

                <mat-card-title>Paid Invoice </mat-card-title>
                <mat-card-subtitle style="color: #009419;">Total
                  Invoice:{{paid_inv[0].Tot_Registration}}</mat-card-subtitle>

                <mat-card-subtitle style="color: #002bff;">Total
                  Amount:{{paid_inv[0].Tot_Reg_Amount}}</mat-card-subtitle>

                <!-- <mat-card-subtitle>Pending:{{responsedata.GetDashboardResult.data[0].PendingLevel1Approval}}</mat-card-subtitle> -->
              </mat-card-header>


            </mat-card>
          </p>
        </div>

        <div class="col-sm-4">

          <p><mat-card class="example-card dashbord-card" style="height: 153px;">
              <mat-card-header *ngIf="pending_inv">

                <mat-card-title>Pending for Approval</mat-card-title>
                <mat-card-subtitle style="color: #009419;">Total
                  Invoice:{{pending_inv[0].Tot_Registration}}</mat-card-subtitle>

                <mat-card-subtitle style="color: #002bff;">Total
                  Amount:{{pending_inv[0].Tot_Reg_Amount}}</mat-card-subtitle>

                <!-- <mat-card-subtitle>Pending:{{responsedata.GetDashboardResult.data[0].PendingHodApproval}}</mat-card-subtitle> -->
              </mat-card-header>


            </mat-card>
          </p>
        </div>




      </div>


      <div class="row" style=" max-width: 102%;" *ngIf="!isApprover">
        <div class="col-sm-3">

          <p>

            <mat-card class="example-card dashbord-card" style="height: 153px;">
              <mat-card-header *ngIf="registerd_inv">

                <mat-card-title>Registered Invoice </mat-card-title>

                <mat-card-subtitle style="color: #009419;">Total
                  Invoice:{{registerd_inv.Tot_Registration}}</mat-card-subtitle>

                <mat-card-subtitle style="color: #002bff;">Total
                  Amount:{{registerd_inv.Tot_Reg_Amount}}</mat-card-subtitle>


              </mat-card-header>

            </mat-card>
          </p>
        </div>


        <div class="col-sm-3">

          <p><mat-card class="example-card dashbord-card " style="height: 153px;">
              <mat-card-header *ngIf="approve_inv">

                <mat-card-title>Approved Invoice</mat-card-title>
                <mat-card-subtitle style="color: #009419;">Total
                  Invoice:{{approve_inv.Tot_Registration}}</mat-card-subtitle>

                <mat-card-subtitle style="color: #002bff;">Total
                  Amount:{{approve_inv.Tot_Reg_Amount}}</mat-card-subtitle>


              </mat-card-header>

            </mat-card>
          </p>
        </div>

        <div class="col-sm-3">

          <p>
            <mat-card class="example-card dashbord-card" style="height: 153px;">
              <mat-card-header *ngIf="paid_inv">

                <mat-card-title>Paid Invoice </mat-card-title>
                <mat-card-subtitle style="color: #009419;">Total
                  Invoice:{{paid_inv[0].Tot_Registration}}</mat-card-subtitle>

                <mat-card-subtitle style="color: #002bff;">Total
                  Amount:{{paid_inv[0].Tot_Reg_Amount}}</mat-card-subtitle>

                <!-- <mat-card-subtitle>Pending:{{responsedata.GetDashboardResult.data[0].PendingLevel1Approval}}</mat-card-subtitle> -->
              </mat-card-header>


            </mat-card>
          </p>
        </div>

        <div class="col-sm-3">

          <p><mat-card class="example-card dashbord-card" style="height: 153px;">
              <mat-card-header *ngIf="pending_inv">

                <mat-card-title>Pending Invoice</mat-card-title>
                <mat-card-subtitle style="color: #009419;">Total
                  Invoice:{{pending_inv[0].Tot_Registration}}</mat-card-subtitle>

                <mat-card-subtitle style="color: #002bff;">Total
                  Amount:{{pending_inv[0].Tot_Reg_Amount}}</mat-card-subtitle>

                <!-- <mat-card-subtitle>Pending:{{responsedata.GetDashboardResult.data[0].PendingHodApproval}}</mat-card-subtitle> -->
              </mat-card-header>


            </mat-card>
          </p>
        </div>




      </div>

      <p-table [value]="dashboardcountyearmonthwiseinvoicesummarylistResult"
        *ngIf="dashboardcountyearmonthwiseinvoicesummarylistResult" styleClass="p-datatable-gridlines">
        <ng-template pTemplate="caption">
          <h2 class="heading">1. Year & Month Wise Invoice Summary
            :{{dashboardcountyearmonthwiseinvoicesummarylistResult[0].InvoiceFinancialYear}} </h2>
        </ng-template>


        <ng-template pTemplate="header">
          <tr style="font-family: serif;">
            <th rowspan="2">S.No.</th>

            <th rowspan="2">Month</th>
            <th colspan="2" style="text-align: center;">Registered</th>
            <th colspan="2" style="text-align: center;">Approved</th>
            <th colspan="2" style="text-align: center;">Paid</th>
            <th colspan="2" style="text-align: center;">Pending</th>


          </tr>

          <tr style="font-family: serif;">
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-i="rowIndex">
          <tr style="font-family: ui-serif;">
            <td><span class="p-column-title">Serial Number:</span>{{i+1}}</td>
            <td><span class="p-column-title">Month :</span> {{ product.InvoiceMonth }}</td>
            <td><span class="p-column-title"> Total Registerd Invoice :</span> {{ product.Registered_TotInv }} </td>
            <td><span class="p-column-title"> Total Registerd Amount :</span> {{ product.Registered_TotAmt }} </td>
            <td><span class="p-column-title"> Total Approved Invoice :</span> {{ product.Approved_TotInv }}</td>
            <td><span class="p-column-title"> Total Approved Amount :</span> {{ product.Approved_TotAmt }}</td>
            <td><span class="p-column-title"> Total Paid Invoice :</span> {{ product.Paid_TotInv }} </td>
            <td><span class="p-column-title"> Total Paid Amount :</span> {{ product.Paid_TotAmt }}</td>
            <td><span class="p-column-title"> Total Pending Invoice :</span> {{ product.Pending_TotInv }}</td>
            <td><span class="p-column-title"> Total Pending Amount :</span> {{ product.Pending_TotAmt }}</td>

          </tr>
        </ng-template>

      </p-table>

      <br>
      <p-table [value]="dashboardcountyearorganizationwiseinvoicesummarylistResult"
        *ngIf="dashboardcountyearorganizationwiseinvoicesummarylistResult" styleClass="p-datatable-gridlines"
        [ngStyle]="{'margin-top': '2%'}">
        <ng-template pTemplate="caption">
          <h2 class="heading">2. Year & Organization Wise Invoice Summary
            :{{dashboardcountyearorganizationwiseinvoicesummarylistResult[0].InvoiceFinancialYear}} </h2>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="font-family: serif;">
            <th rowspan="2">S.No.</th>

            <th rowspan="2">Organization Name</th>
            <th colspan="2" style="text-align: center;">Registered</th>
            <th colspan="2" style="text-align: center;">Approved</th>
            <th colspan="2" style="text-align: center;">Paid</th>
            <th colspan="2" style="text-align: center;">Pending</th>


          </tr>

          <tr style="font-family: serif;">



            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-i="rowIndex">
   
          <tr style="font-family: ui-serif;">
            <td><span class="p-column-title">Serial Number:</span>{{i+1}}</td>
            <td><span class="p-column-title">Organization Name</span> {{ product.OrgName }}</td>

            <td><span class="p-column-title"> Total Registerd Invoice :</span> {{ product.Registered_TotInv }} </td>
            <td><span class="p-column-title"> Total Registerd Amount :</span> {{ product.Registered_TotAmt }} </td>
            <td><span class="p-column-title"> Total Approved Invoice :</span> {{ product.Approved_TotInv }}</td>
            <td><span class="p-column-title"> Total Approved Amount :</span> {{ product.Approved_TotAmt }}</td>
            <td><span class="p-column-title"> Total Paid Invoice :</span> {{ product.Paid_TotInv }} </td>
            <td><span class="p-column-title"> Total Paid Amount :</span> {{ product.Paid_TotAmt }}</td>
            <td><span class="p-column-title"> Total Pending Invoice :</span> {{ product.Pending_TotInv }}</td>
            <td><span class="p-column-title"> Total Pending Amount :</span> {{ product.Pending_TotAmt }}</td>

          </tr>
        </ng-template>

      </p-table>

      <!-- <h1 style="text-align: center;"> Year & Vendor Wise Invoice Summary</h1>   -->
      <p-table [value]="dashboardcountyearvendornamewiseinvoicesummarylistResult"
        *ngIf="dashboardcountyearvendornamewiseinvoicesummarylistResult" styleClass="p-datatable-gridlines"
        [ngStyle]="{'margin-top': '2%'}">
        <ng-template pTemplate="caption">
          <h2 class="heading">3. Year & Vendor Wise Invoice Summary
            :{{dashboardcountyearvendornamewiseinvoicesummarylistResult[0].InvoiceFinancialYear}} </h2>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="font-family: serif;">
            <th rowspan="2">S.No.</th>

            <th rowspan="2">Vendor Name</th>
            <th colspan="2" style="text-align: center;">Registered</th>
            <th colspan="2" style="text-align: center;">Approved</th>
            <th colspan="2" style="text-align: center;">Paid</th>
            <th colspan="2" style="text-align: center;">Pending</th>


          </tr>

          <tr style="font-family: serif;">



            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-i="rowIndex">
          <!-- <tr style="font-family: ui-serif;">
      <td> {{i+1}}</td>
      <td>{{ product.VendorName }}</td>
      <td>{{ product.Registered_TotInv }} </td> 
      <td>{{ product.Registered_TotAmt }} </td>         
       <td> {{ product.Approved_TotInv }}</td>
      <td> {{ product.Approved_TotAmt }}</td>
      <td>{{ product.Paid_TotInv }} </td>
      <td> {{ product.Paid_TotAmt }}</td>         
       <td> {{ product.Pending_TotInv }}</td>
      <td> {{ product.Pending_TotAmt }}</td>
  
    </tr> -->


          <tr style="font-family: ui-serif;">
            <td><span class="p-column-title">Serial Number:</span>{{i+1}}</td>
            <td><span class="p-column-title">Vendor :</span> {{ product.VendorName }}</td>

            <td><span class="p-column-title"> Total Registerd Invoice :</span> {{ product.Registered_TotInv }} </td>
            <td><span class="p-column-title"> Total Registerd Amount :</span> {{ product.Registered_TotAmt }} </td>
            <td><span class="p-column-title"> Total Approved Invoice :</span> {{ product.Approved_TotInv }}</td>
            <td><span class="p-column-title"> Total Approved Amount :</span> {{ product.Approved_TotAmt }}</td>
            <td><span class="p-column-title"> Total Paid Invoice :</span> {{ product.Paid_TotInv }} </td>
            <td><span class="p-column-title"> Total Paid Amount :</span> {{ product.Paid_TotAmt }}</td>
            <td><span class="p-column-title"> Total Pending Invoice :</span> {{ product.Pending_TotInv }}</td>
            <td><span class="p-column-title"> Total Pending Amount :</span> {{ product.Pending_TotAmt }}</td>

          </tr>
        </ng-template>

      </p-table>



      <br>


      <!--<h1 style="text-align: center;"> Year & Vendor Wise Invoice Summary</h1>   -->
      <p-table [value]="dashboardcountyearmonthvendornamewiseinvoicesummarylistResult"
        *ngIf="dashboardcountyearmonthvendornamewiseinvoicesummarylistResult" styleClass="p-datatable-gridlines"
        [ngStyle]="{'margin-top': '2%'}">
        <ng-template pTemplate="caption">
          <h2 class="heading">4. Year- Month & Vendor Wise Invoice Summary
            :{{dashboardcountyearmonthvendornamewiseinvoicesummarylistResult[0].InvoiceFinancialYear}} </h2>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="font-family: serif;">
            <th rowspan="2">S.No.</th>
            <th rowspan="2">Month Name</th>
            <th rowspan="2">Vendor Name</th>
            <th colspan="2" style="text-align: center;">Registered</th>
            <th colspan="2" style="text-align: center;">Approved</th>
            <th colspan="2" style="text-align: center;">Paid</th>
            <th colspan="2" style="text-align: center;">Pending</th>


          </tr>

          <tr style="font-family: serif;">



            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-i="rowIndex">
 
          <tr style="font-family: ui-serif;">
            <td><span class="p-column-title">Serial Number:</span>{{i+1}}</td>
            <td><span class="p-column-title">Month :</span> {{ product.InvoiceMonth }}</td>
            <td><span class="p-column-title">Vendor :</span> {{ product.VendorName }}</td>

            <td><span class="p-column-title"> Total Registerd Invoice :</span> {{ product.Registered_TotInv }} </td>
            <td><span class="p-column-title"> Total Registerd Amount :</span> {{ product.Registered_TotAmt }} </td>
            <td><span class="p-column-title"> Total Approved Invoice :</span> {{ product.Approved_TotInv }}</td>
            <td><span class="p-column-title"> Total Approved Amount :</span> {{ product.Approved_TotAmt }}</td>
            <td><span class="p-column-title"> Total Paid Invoice :</span> {{ product.Paid_TotInv }} </td>
            <td><span class="p-column-title"> Total Paid Amount :</span> {{ product.Paid_TotAmt }}</td>
            <td><span class="p-column-title"> Total Pending Invoice :</span> {{ product.Pending_TotInv }}</td>
            <td><span class="p-column-title"> Total Pending Amount :</span> {{ product.Pending_TotAmt }}</td>

          </tr>
        </ng-template>

      </p-table>



      <br>
        <p-table [value]="dashboardcountyearservicewiseinvoicesummarylistResult"
        *ngIf="dashboardcountyearservicewiseinvoicesummarylistResult" styleClass="p-datatable-gridlines"
        [ngStyle]="{'margin-top': '2%'}">
        <ng-template pTemplate="caption">
          <h2 class="heading">5. Year & Service Wise Invoice Summary
            :{{dashboardcountyearservicewiseinvoicesummarylistResult[0].InvoiceFinancialYear}} </h2>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="font-family: serif;">
            <th rowspan="2">S.No.</th>

            <th rowspan="2">Services</th>
            <th colspan="2" style="text-align: center;">Registered</th>
            <th colspan="2" style="text-align: center;">Approved</th>
            <th colspan="2" style="text-align: center;">Paid</th>
            <th colspan="2" style="text-align: center;">Pending</th>


          </tr>

          <tr style="font-family: serif;">



            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>
            <th>Total Invoice </th>

            <th>Total Amount</th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-i="rowIndex">
           <tr style="font-family: ui-serif;">
            <td><span class="p-column-title">Serial Number:</span>{{i+1}}</td>
            <td><span class="p-column-title">Item Category :</span> {{ product.ItemCategoryName }}</td>

            <td><span class="p-column-title"> Total Registerd Invoice :</span> {{ product.Registered_TotInv }} </td>
            <td><span class="p-column-title"> Total Registerd Amount :</span> {{ product.Registered_TotAmt }} </td>
            <td><span class="p-column-title"> Total Approved Invoice :</span> {{ product.Approved_TotInv }}</td>
            <td><span class="p-column-title"> Total Approved Amount :</span> {{ product.Approved_TotAmt }}</td>
            <td><span class="p-column-title"> Total Paid Invoice :</span> {{ product.Paid_TotInv }} </td>
            <td><span class="p-column-title"> Total Paid Amount :</span> {{ product.Paid_TotAmt }}</td>
            <td><span class="p-column-title"> Total Pending Invoice :</span> {{ product.Pending_TotInv }}</td>
            <td><span class="p-column-title"> Total Pending Amount :</span> {{ product.Pending_TotAmt }}</td>

          </tr>
        </ng-template>

      </p-table>





    </div>



  </div>

</div>


<p-dialog [(visible)]="statusupdateDialog" [style]="{width: '450px',color:'#fff'}"
header="Pin Generate" [modal]="true" styleClass="p-fluid">
<ng-template pTemplate="content">

  <form [formGroup]="PinForm"  (ngSubmit)="PinUpdate()">

    <h1 [hidden]="isOtpInvalidError" class="text-danger text-center alert alert-danger"
    style="width:94%;margin-top:1% !important">Please Enter Valid Code</h1>
  <h1 [hidden]="isOtpEmptyError" class="text-danger text-center alert alert-danger"
    style="width:94%;margin-top:1% !important">All field Is Require</h1>
    <div class="container height-100 d-flex justify-content-center align-items-center">
        <div class="position-relative">
            <div class="card p-2 text-center"> 
                <div> <span>Please Set Your Secret PIN <br> For Secure your account</span> <small>*******</small> </div>
                <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2"> <input
                        class="m-2 text-center form-control rounded" type="text" #first  formControlName="first" maxlength="1"  (keyup)="move($event,'',first,second)" (keypress)="numberOnly($event)"   /> <input
                        class="m-2 text-center form-control rounded" type="text"  #second    formControlName="second"  maxlength="1" (keyup)="move($event,first,second,third)" (keypress)="numberOnly($event)"  /> <input
                        class="m-2 text-center form-control rounded" type="text"   #third  formControlName="third" maxlength="1" (keyup)="move($event,second,third,fourth)" (keypress)="numberOnly($event)" /> <input
                        class="m-2 text-center form-control rounded" type="text"  #fourth    formControlName="fourth"  maxlength="1" (keyup)="move($event,third,fourth,'')" (keypress)="numberOnly($event)"    /> </div>
                <div class="mt-4"> <button  class="btn btn-danger px-4 validate" type="submit" >Validate</button> </div>
            </div>


        </div>
    </div>


</form>

</ng-template>

<ng-template pTemplate="footer" style="color:#fff;background: #ec5729;">
      
  
  <!-- <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
        (click)="hideDialog()" [style]="{color:'#fff'}"></button
        
        > -->
      </ng-template>

</p-dialog>  