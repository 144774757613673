 

<div class="card"  >

 

    <p-table   #dt [columns]="selectedColumns" [value]="products" [tableStyle]="{'min-width': '100%'}"  >
        <ng-template pTemplate="caption"  >
            <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{'min-width': '200px'}"
                placeholder="Choose Columns"></p-multiSelect>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <!-- <th>Product ID</th> -->


                <th pSortableColumn="rating" style="width:120px;
                position: sticky;
                left: 0;
                z-index: 2;background:#f8f9fa;"> Action  </th>
                <th       *ngFor="let col of columns"><p-sortIcon  [pSortableColumn]="col.field" 
                    [field]="col.field"></p-sortIcon> 
                    {{col.header}}
                        <br> <br> 

                        <span style="margin-top:10px;">
                            
                            <input pInputText type="text" (input)="dt.filter($event.target.value,col.field ,'contains')"
                            [placeholder]="col.header" />
                        </span>
                    
                </th>

                    </tr>
           
        </ng-template>

        <ng-template pTemplate="body" let-product let-columns="columns">
            <tr>
                <!-- <td>{{productdetail.ProductID}} </td> -->

<th>
    <span class="buttonsres" >
        <a [routerLink]="['/Edit-Invoice-Registration',product.InvoiceID]" class="mr-1 fs-2"><i class='fa fa-edit'  style='color: #2459a6;'   pTooltip="Edit Invoice Detail" ></i></a>
        <a [routerLink]="['/View-Invoice',product.InvoiceID]">   <span  pTooltip="View Invoice Detail"> <i class='fa fa-eye'  style='color: #f09639; zoom: 1.1;
        border: 2px solid;
        border-radius: 5px;'   ></i> </span>

        </a>
     </span>
    </th>
                <td *ngFor="let col of columns">
<!-- {{col|json}} -->
<!-- {{product|json}} -->
<span class="p-column-title"> {{product[col.header]}}  </span>


 
<span *ngIf="product[col.header]=='Document'">      
    
<a href="https://billdeskapi.aisect.org{{product.InvoiceAttechmentURL}}" target="_blank"><i
    class="fa fa-file-pdf-o" style="font-size:32px;color:red"
    title="Click For Open Document"></i>
</a> 


</span>




{{product[col.field]}} 
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary" style="display:flex;">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>

    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '800px'}" header="Organization Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-field">


            <div class="row">


                <div class="col-md-3">



                    <label for="Organization_Name" class="col-form-label">Organization Id :</label><br>


                    <label for="Organization_Name" class="col-form-label">Organization Name:</label><br>


                    <label for="PANCard" class="col-form-label">PANCard:</label><br>


                    <label for="GSTN" class="col-form-label">GSTN:</label><br>



                    <label for="Contact_Number" class="col-form-label">Contact Number:</label><br>
                    <label for="Emailid" class="col-form-label">Email </label><br>




                    <label for="Address" class="col-form-label">Address </label><br>





                    <label for="City" class="col-form-label">City </label><br>



                    <label for="State" class="col-form-label">State </label><br>



                    <label for="PinCode" class="col-form-label">PinCode</label><br>





                </div>
                <div class="col-md-3">
                    <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].OrgID}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].OrgName}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].PANNo}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].GSTN}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].DeskMobileNo}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].DeskEmailID}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].BillingAddress}}
                    </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].City}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].State}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].PinCode}} </label><br>

                </div>


                <div class="col-md-3">


                    <label for="ContactPersonName" class="col-form-label">Contact Person Name </label><br>




                    <label for="ContactPersonNo" class="col-form-label">Contact Person No </label><br>

                    <label for="accountname" class="col-form-label">Account Name </label><br>

                    <label for="BanckAccountNo" class="col-form-label">Banck Account No </label><br>




                    <label for="bankname" class="col-form-label">Bank Name </label><br>



                    <label for="BanckBranch" class="col-form-label">Banck Branch </label><br>



                    <label for="BankIFSCCode" class="col-form-label">Bank IFSC Code </label><br>


                    <label for="BankIFSCCode" class="col-form-label">Created Date </label><br>


                    <label for="BankIFSCCode" class="col-form-label">Created By </label>
                    <br>
                    <label for="BankIFSCCode" class="col-form-label">Updated Date </label>


                    <label for="BankIFSCCode" class="col-form-label">Updated By </label><br>




                </div>

                <div class="col-md-3"> <label for="Organization_Name"
                        class="col-form-label">{{productsbyid[0].AuthPersonName}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].AuthPersonMobileNo}}
                    </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].BankAcName}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].BankAcNo}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].BankName}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].BankBranchName}}
                    </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].IFSCCode}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].CreatedDate}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].CreatedBy}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].UpdatedDate}} </label><br>
                    <label for="Organization_Name" class="col-form-label">{{productsbyid[0].UpdatedBy}} </label><br>



                </div>
            </div>



        </div>


        <br>

        <div class="p-field">


            <label class="p-mb-3" style="display: flex;">Status: &nbsp;&nbsp;&nbsp; <p-radioButton id="category1"
                    name="category" value="Accessories" [(ngModel)]="product.category"></p-radioButton>
                &nbsp;
                <label for="category1">Active</label>
                &nbsp;
                <p-radioButton id="category2" name="category" value="Clothing"
                    [(ngModel)]="product.category"></p-radioButton>

                &nbsp;
                <label for="category2">Deactive</label></label>

            <br>



        </div>
    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">



        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Organization Deleted Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>


<p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Status Updated Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>
</p-dialog>