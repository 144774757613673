 
<div class="card" style=" overflow-x: scroll;">
    <p-table #dt [value]="products" [rows]="10" [paginator]="true"
          [globalFilterFields]="['OrgName','GSTN','StateName','DistrictName','DeskMobileNo','DeskEmailID','AuthPersonName','AuthPersonMobileNo']"
          [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"  styleClass="p-datatable-striped" >
          <!-- <p-table #dt [value]="products" [(selection)]="selectedProducts" dataKey="id" styleClass="p-datatable-customers" [rowHover]="true"
   
              [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
           [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [filterDelay]="0" [globalFilterFields]="['name','country.name','representative.name','status']"> -->
  
          <!-- <p-table [value]="products" [tableStyle]="{'min-width': '50rem'}"> -->
  
          <ng-template pTemplate="caption">
              <div class="p-d-flex p-ai-center p-jc-between">
                  <h1 class="headingclass">
                      UNIT LIST</h1>
                  <span class="p-input-icon-left">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search..." />
                      &nbsp; &nbsp; <a [routerLink]="['/unit_entry']" class="btn btn-success">Add <i
                              class="fa fa-plus" aria-hidden="true"></i></a>
                  </span>
              </div>
          </ng-template>
  
  
          <ng-template pTemplate="header">
  
  
  
              <tr class="padding-head">
                  <!-- <th style="width: 50px">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th> -->
                  <th pSortableColumn="rating"  class="actionclass"> Action  </th>
  
                  <th pSortableColumn="ActiveStatus">Status <p-sortIcon field="ActiveStatus"></p-sortIcon></th>
  
                  <th pSortableColumn="UnitID"> Unit ID <p-sortIcon field="UnitID"></p-sortIcon></th>
  
                  <th pSortableColumn="UnitName"> UnitName<p-sortIcon field="UnitName"></p-sortIcon></th> 
                  <th pSortableColumn="CreatedDate">CreatedDate <p-sortIcon field="CreatedDate"></p-sortIcon>
                  </th>
           
  
              </tr>
              <tr class="padding-head">
                  <!-- <td>
  
                  </td> -->
                  <!-- <th style="position: sticky;
                  left: 0;
                  z-index: 2;background:#ffffff;"> -->
  
                  <th>
                       
                  </th>
                  <th>
  
  
  
                      <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')"
                          styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                          <ng-template let-option pTemplate="item">
                              <span
                                  [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                          </ng-template>
                      </p-dropdown>
  
  
                  </th>
  
  
                  <th>
  
                      <input pInputText type="text" (input)="dt.filter($event.target.value,'UnitID' ,'contains')"
                          placeholder="Search UnitID" />
                  </th>
  
  
                  <th>
  
                      <input pInputText type="text" (input)="dt.filter($event.target.value,'UnitName' ,'contains')"
                          placeholder="Search" class="fullwidth" />
                  </th>
                  <th>
                      <input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')" placeholder="Search" />
                  </th>
                 
  
  
  
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
              <tr class="responsivecss">
                  <td style="position: sticky;
                  left: 0;
                  z-index: 2;background:#ffffff;">
        
                       
                       
                       <span class="p-column-title">Action:</span>
                      <span style="display: flex;align-items: center;justify-content: center;">
                          <a [routerLink]="['/Edit_Unit',product.UnitID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                  style='color: #2459a6;' pTooltip="Edit Unit Detail"></i></a>
                          <span pTooltip="View Unit Detail"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                          border: 2px solid;
                          border-radius: 5px;' (click)="viewProduct(product.UnitID)"></i> </span>
                      </span>
                  </td>
                  <td>
  
  
                      <span class="p-column-title">Status:</span>
                      <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
                      <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>
  
  
  
                      <!-- <i class="pi-file-edit" style="font-size: 2rem"></i> -->
                  </td>
                  <td>
  
                      <span class="p-column-title">Unit ID:</span>
                      {{product.UnitID}}
                  </td>
  
                  <td>
  
                      <span class="p-column-title">Unit Name:</span>
                      {{product.UnitName}}
                  </td>
    
                  <td>
  
                      <span class="p-column-title">Created Date:</span>
                      {{product.CreatedDate }}
                  </td>
  
  
  
              </tr>
          </ng-template>
          <ng-template pTemplate="summary">
              <div class="p-d-flex p-ai-center p-jc-between">
                  In total there are {{products ? products.length : 0 }} products.
              </div>
          </ng-template>
      </p-table>
  </div>
  
  <p-dialog [(visible)]="productDialog" [style]="{width: '800px'}" header="Unit Details" [modal]="true"
      styleClass="p-fluid">
      <ng-template pTemplate="content">
          <br>
          <div class="container">
              <table class="table table-striped">
  
                  <tbody>
                      <tr>
                          <td>
                              <label for="Organization_Name" class="col-form-label">Unit Id :</label>
                          </td>
                          <td>
                              <label for="OrgID" class="col-form-label"> {{productsbyid[0].UnitID}} </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td>
  
                              <label for="Organization_Name" class="col-form-label">Unit Name:</label>
                          </td>
                          <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UnitName}} </label>
                          </td>
  
                      </tr>
                 
  
                      <tr>
                          <td> 
                              <label for="BankIFSCCode" class="col-form-label">Created Date </label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedDate}}
                              </label>
                          </td>
  
                      </tr>
  
  
                     
  
  
                 
   
  
                  </tbody>
              </table>
          </div>
  
          <!-- <div class="form-check-inline">
              <label class="form-check-label">
  
                  Status
              </label>
          </div>
          <div class="form-check-inline">
              <label class="form-check-label"> 
                  <input type="radio" class="form-check-input" checked="!checked" name="radio"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="1">active
              </label>
          </div>
          <div class="form-check-inline">
              <label class="form-check-label">
                  <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="0">Deactive
              </label>
          </div> -->
  
  
  
      </ng-template>
  
      <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
      <span style="float: left;padding-top: 2%;">
  
          <div class="form-check-inline" style="color:#fff">
              <label class="form-check-label">
  
                  Status
              </label>
          </div>
          <div class="form-check-inline" style="color:#fff">
              <label class="form-check-label">
                  <!-- {{productsbyid[0].ActiveStatus}} -->
                  <input type="radio" class="form-check-input" checked="!checked" name="radio"
                      [(ngModel)]="productsbyid[0].ActiveStatus"  
                      [value]="1">active
              </label>
          </div>
          <div class="form-check-inline" style="color:#fff">
              <label class="form-check-label">
                  <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                      [(ngModel)]="productsbyid[0].ActiveStatus"  
                      [value]="0">Deactive
              </label>
          </div>
  
          
      </span>
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" style="float: right;"></button>
          <!-- <button style="color: white;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
              (click)="saveProduct()"></button>  this is unused -->
      </ng-template>
  </p-dialog>
  
  <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
  
  <p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="content">
          <div class="modal-dialog modal-confirm">
              <div class="modal-content">
                  <div class="modal-header justify-content-center">
                      <div class="icon-box">
                          <i class="material-icons">&#xE876;</i>
                      </div>
  
                  </div>
                  <div class="modal-body text-center">
                      <h4>Success!</h4>
                      <p>Organization Deleted Successfully.</p>
                  </div>
              </div>
          </div>
      </ng-template>
  
  
  </p-dialog>
  
  
  <p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="content">
          <div class="modal-dialog modal-confirm">
              <div class="modal-content">
                  <div class="modal-header justify-content-center">
                      <div class="icon-box">
                          <i class="material-icons">&#xE876;</i>
                      </div>
  
                  </div>
                  <div class="modal-body text-center">
                      <h4>Success!</h4>
                      <p>Status Update Successfully.</p>
                  </div>
              </div>
          </div>
      </ng-template>
  
  
  </p-dialog>