import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';
@Component({
  selector: 'app-mstmenuregistration',
  templateUrl: './mstmenuregistration.component.html',
  styleUrls: ['./mstmenuregistration.component.css']
})
export class MstmenuregistrationComponent implements OnInit {
 
  //Create FormGroup 


  menulist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];
  isShow = true;
  cityList:Billdesk[];
  headlist:Billdesk[];

  type: string;
  selectedMenuId:string;
  selectedMenuName:string='Please Select Menu';


  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef) {
   
    
    console.log(this.billdeskService.getMenu().then(res => this.menulist = res));

  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  
  ngOnInit() {
    this.form = this.fb.group({ 
      //  MenuType: [null, Validators
      
      
      UnderOfPID: new FormControl(),
//##this technic for without validate field in form and get data from form 

      MenuName: [null, Validators.required] ,
      // UnderOfPID: [null, Validators.required] ,
      MenuDisplayName: [null, Validators.required] ,
      URL: [null, Validators.required],
      Areas: [null, Validators.required],
     ControlarName: [null, Validators.required],
 ActionName: [null, Validators.required],
 MenuDesc: [null, Validators.required],
 ActiveStatus: [null, Validators.required],
 IndividualStatus:[null, Validators.required]

    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {
    // this.Key.setValue(e.target.value, {
    //   onlySelf: true
    // })


    // let messageId = e.getAttribute('data-key');


    // this.type = this.elementRef.nativeElement.getAttribute('data-key');
    //let messageId = el.dataset.messageId;
//     console.log("Message Id: ", messageId);
// console.log(e); 
// console.log(e.target.dataset.Key);
 
// console.log(e.target.value);
console.log(this.billdeskService.getCity(20).then(res => this.cityList = res));
  }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
    "flag":"",  
    "MenuID":"0",

    "ImageURL1":"",
    "ImageURL2":"1",
    "OrderBY":"1",
    "CreatedBy":"1",
    "CreatedByID":"1",
    "UpdatedDate":"",
    "UpdatedBy":""
   };

   
   var obj2 = this.form.value;
console.log('netformdata');
console.log(obj2);
console.log('netformdata');
  //  alert(this.isShow);
  if(this.isShow===true){ 

    // alert('true');
   var obj3 = 
   {
    "UnderOfPID":"0" 
   };
   var form_data = Object.assign(obj1, obj2,obj3);
  }else{ 

    // alert('false');
    var form_data = Object.assign(obj1, obj2);

  }

       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);


if (this.form.valid) {
  console.log('form submitted');
 
let registerurl= "/mstmenu/mstmenuregistration";
this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
  console.log("depin");
  console.log(res);
  console.log("depin");
    console.log(res.mstmenuregistrationResult);
  if(res.mstmenuregistrationResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/Menu-List'])
   localStorage.setItem('insertdata', 'Success');
 }else{
   console.log('Please Try Again Later');
 }
 });


} else {
  this.validateAllFormFields(this.form);
}
 }


 changeMenu(e){
  console.log(e); 
    this.selectedMenuName=e.value.MenuName;
  console.log(this.selectedMenuName);
      this.selectedMenuId=e.value.MenuID;
      }  



getMenuType(e){
  console.log(e); 
  
  console.log(e.target.value); 

  if(e.target.value=='secondary'){

    this.isShow=false;
  }else{

    this.isShow=true;
  }
    
      }  
      
 }
 