  
 
<style>


  .cdk-overlay-connected-position-bounding-box{

    width: 100% !important;
  }
</style>
 
 
 <div *ngIf="!session">

  
<mat-toolbar  style="height: 80px;"  class="noprint">
    <div fxShow="true" fxHide.gt-sm="true">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>


    <div fxShow="true" fxHide.lt-md="true">

    <a mat-button class="companyName" routerLink="/dashboard" style="margin-left: 70px;">
      <span><img style="height: 67px;" src="assets/images/3.png" alt="logo"></span>
    </a>

    </div>
    <div fxShow="false" fxHide.lt-md="false" style="margin-left: 60%;" >

      <a mat-button class="companyName" routerLink="/dashboard"> 
        <span><img style="height: 49px; " src="assets/images/3.png" alt="logo"  ></span>
      </a>
  
      </div>
<div > 
</div>

<span class="example-spacer"></span>


<a    mat-button fxShow="true"  fxHide.lt-md="true"  routerLink="/dashboard">Home</a>
<div fxShow="true" fxHide.lt-md="true" *ngFor="let item of data" style="font-size: 10pt; padding: 10px;" > 
        <button mat-button  class="buttonclass" [matMenuTriggerFor]="submenu">{{item.menuName}}  <i class="arrow down" *ngIf="item.subMenuList?.length != 0" ></i></button>
         <mat-menu #submenu="matMenu" xPosition="after">
          
          <a *ngFor="let submenulist of item.subMenuList"  routerLink="/{{submenulist.url}}">
             <button mat-menu-item >{{submenulist.menuName}} </button>
          </a>
       </mat-menu> 
</div>
<a mat-button  fxHide.lt-md="true"    routerLink="" (click)="onLogout()"> <i class="fa fa-sign-out mr-1"  style="color:#fd8d3d;font-size: 20px;"></i>Sign Out</a>
</mat-toolbar>
</div>

<!-- End mobile menu -->

<!-- start mobile menu  -->

<mat-sidenav-container fxFlexFill class="example-container" class="nav-menu "  >
<mat-sidenav color="primary" #sidenav fxLayout="column" mode="push"  style="width: 70%;"  opened="false"  fxHide.gt-sm="true">

  <a   mat-button routerLink="/dashboard">Home</a>
<div class="notdrop" style="border-bottom: 2px solid #d5d5d547; color: black;" *ngFor="let item of data">
   <mat-accordion  >
<mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <button    mat-button     (click)="toggleoff()" style="text-align: center;">{{item.menuName}} </button>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul *ngFor="let submenulist of item.subMenuList" >
               <li>  <a   (click)="sidenav.close()"  mat-button  routerLink="/{{submenulist.url}}" ><button mat-menu-item > <i class="fa fa-plus mr-1"  style="color:#fd8d3d;font-size: 20px;"></i> {{submenulist.menuName}}</button></a></li> 
            </ul>
</mat-expansion-panel>
</mat-accordion>
</div>
<a mat-button  routerLink="" (click)="onLogout()"><i class="fa fa-sign-out mr-1"  style="color:#fd8d3d;font-size: 20px;"></i>Sign Out</a>
</mat-sidenav>
<mat-sidenav-content fxFlexFill>

<router-outlet></router-outlet>
<app-footer *ngIf="!session"></app-footer>
</mat-sidenav-content>
</mat-sidenav-container>
 
        
       