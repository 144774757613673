 
<p-toast></p-toast>






<div class="card" style=" overflow-x: scroll;">
    <!-- <p-toolbar styleClass="p-mb-4"> -->
        <!-- <ng-template pTemplate="left"> -->
            <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button> -->
        <!-- </ng-template> -->

        <!-- <ng-template pTemplate="right"> -->

            <!-- <p-multiSelect style="width:200px;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
            <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
        <!-- </ng-template> -->
    <!-- </p-toolbar> -->

    <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="[
    'StageID',
    'StageName' ,
     'RoleID',
  ]"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]">

        
        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">INVOICE STAGE LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                    &nbsp; &nbsp;  <a     [routerLink]="['/Stage-Registration']" class="btn btn-success">Add <i class="fa fa-plus" aria-hidden="true"></i></a>
                </span>
            </div>

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
  
        </ng-template>
   
     
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="rating" class="actionclass">	Action<p-sortIcon field="rating"></p-sortIcon></th>
                
                <th pSortableColumn="ActiveStatus" style="width: 150px">	Active Status<p-sortIcon field="ActiveStatus"></p-sortIcon></th>
               
                <th pSortableColumn="StageID" style="width: 150px">Stage ID<p-sortIcon field="StageID"></p-sortIcon></th>
                <th pSortableColumn="StageName" style="width: 150px">Stage Name<p-sortIcon field="StageName"></p-sortIcon></th>
     
                <th pSortableColumn="RoleID" style="width: 150px">Role<p-sortIcon field="RoleID"></p-sortIcon></th>
               
                
            </tr>


            <tr>
                <th>

                </th>
                <th>    
                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')" styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                      <ng-template let-option pTemplate="item">
                          <span [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                      </ng-template>
                    </p-dropdown> 
                  </th>
      
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'StageID' ,'contains')" placeholder="Search StageID" />
                
                </th>
<th>
    <input pInputText type="text" (input)="dt.filter($event.target.value,'StageName' ,'contains')" placeholder="Search Stage Name " />

</th>
 
 

<th>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'RoleID' ,'contains')" placeholder="Search RoleID" />
</th>

 
 
   
                                                                                    

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">
                <td>
                    <!-- <a     [routerLink]="['/Edit-Stage',product.StageID]"><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"   style="margin-right: 4px;" ></button>
                    </a><button pButton pRipple icon="pi pi-eye"   class="p-button-rounded p-button-info" (click)="viewProduct(product.StageID)"></button> 
 -->

                    <span class="p-column-title">Action:</span>
                    <span style="display: flex;align-items: center;justify-content: center;">
                        <a [routerLink]="['/Edit-Stage',product.StageID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                style='color: #2459a6;' pTooltip="Edit Stage Detail"></i></a>
                        <span pTooltip="View Stage Detail"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                        border: 2px solid;
                        border-radius: 5px;' (click)="viewProduct(product.StageID)"></i> </span>
                    </span>
   </td>
   <td>
    <span class="p-column-title">Status:</span>
    <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
    <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>
     </td>

   <td><span class="p-column-title">Stage ID:</span>{{product.StageID}}</td> 
                <td><span class="p-column-title">Stage Name:</span>{{product.StageName}}</td> 
         
                <td><span class="p-column-title">Role:</span> {{product.RoleID}}</td>
          
                

            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>


<p-dialog [(visible)]="productDialog" [style]="{width: '600px'}" header="Stage Details" [modal]="true" styleClass="p-fluid">
  
    <ng-template pTemplate="content">


        <br>
        <div class="container">
            <table class="table table-striped">

                <tbody>
                    <tr>
                        <td>
                            <label for="Organization_Name" class="col-form-label">Stage ID :</label>
                        </td>
                        <td>
                            <label for="OrgID" class="col-form-label"> {{productsbyid[0].StageID}} </label>
                        </td>

                    </tr>
           
                    <tr>
                        <td> <label for="PANCard" class="col-form-label">Stage Name:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].StageName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="GSTN" class="col-form-label">Owner Of:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].OwnerOf}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="Contact_Number" class="col-form-label">RoleID:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].RoleID}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="Emailid" class="col-form-label">Descp: </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].Descp}}
                            </label></td>

                    </tr>
                    <tr>
                        <td> <label for="Address" class="col-form-label">UpdatedBy:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedBy}}
                            </label></td>

                    </tr>
                    
                   

                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Created By: </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedBy}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">CreatedOn: </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedOn}}
                            </label>
                        </td>

                    </tr>
 

                </tbody>
            </table>
        </div>





    </ng-template>
 
  


    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
        <span style="float: left;padding-top: 2%;">

      <div class="form-check-inline" style="color:#fff">
          <label class="form-check-label">

              Status
          </label>
      </div>
      <div class="form-check-inline" style="color:#fff">
          <label class="form-check-label">
              <!-- {{productsbyid[0].ActiveStatus}} -->
              <input type="radio" class="form-check-input" checked="!checked" name="radio"
                  [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].StageID)"
                  [value]="1">active
          </label>
      </div>
      <div class="form-check-inline" style="color:#fff">
          <label class="form-check-label">
              <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                  [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].StageID)"
                  [value]="0">Deactive
          </label>
      </div>
        </span>
           <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" style="float: right;">
          </button>

  </ng-template>
</p-dialog>


<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


