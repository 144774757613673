import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
 
 
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';


import { Router } from '@angular/router';
@Component({
  selector: 'app-editorganization',
  templateUrl: './editorganization.component.html',
  styleUrls: ['./editorganization.component.css']
})
export class EditorganizationComponent implements OnInit {
  //Create FormGroup 
  stateList:Billdesk[];

  cityList:Billdesk[];
  public id:any;
  public   OrgName:any;
  public products: Billdesk[];

  approverlist:any;
  constructor(private billdeskService: BilldeskService,private fb: FormBuilder,private _Activatedroute:ActivatedRoute,private http:HttpClient,private route : Router ) {
     
  }
 
  form: FormGroup;
 
  ngOnInit() {
   
    console.log(this.billdeskService.getState().then(res => this.stateList = res));
    console.log('statelistend');

    console.log(this.billdeskService.getApprover_List().then(res => this.approverlist = res));
    this.id=this._Activatedroute.snapshot.paramMap.get("id");
    this._Activatedroute.paramMap.subscribe(params => { 
      this.id = params.get('id');
      console.log(this.id);
      console.log(this.billdeskService.getOrganizationByid(this.id).then(res => this.products = res));

 
 
    });

    
   this.form = this.fb.group({  
      OrgName: [null, Validators.required], 
      PANNo: [null, Validators.required], 
      GSTN: [null, Validators.required],
      
      ActiveStatus: [null, Validators.required]  ,
      DeskMobileNo:['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]] , 
      PinCode: [null, Validators.required],
      
      AuthPersonName: [null, Validators.required],
      AuthPersonMobileNo: [null, Validators.required],
   
      AllotedOrgID:[null, Validators.required],

      // BankAcName: [null, Validators.required],
      // BankAcNo: [null, Validators.required],
      // BankName: [null, Validators.required],
      // BankBranchName: [null, Validators.required],

      BankAcName: [],
      BankAcNo: [],
      
      BankName: [],
      BankBranchName: [],

      IFSCCode: [],
      
      BillingAddress: [null, Validators.required],
      
      City: [null, Validators.required],
      
      State: [null, Validators.required],
 
      DeskEmailID: [null, [Validators.required, Validators.email]],
      
    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
 
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }




  
  onSubmit() {

  
    this.id=this._Activatedroute.snapshot.paramMap.get("id");
    console.log(this.form.value);
    var obj1 = 
   {
        "flag":"MSTOrganization_INSERT",
     "OrgID" : this.id,
     "CreatedDate"  : "",
     "CreatedBy" :"5",
     "UpdatedDate"  : "",
     "UpdatedBy" :""
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.form);
       console.log(this.form.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.form);



if (this.form.valid) {


  
// this.id=this._Activatedroute.snapshot.paramMap.get("id");
// this._Activatedroute.paramMap.subscribe(params => { 
//   this.id = params.get('id');

//   console.log(this.id);
let updateurl= "/organization/organizationregistrationupdate";
this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{
  console.log("responce data");
  console.log(res);
  console.log("responce data");
   console.log(res.organizationregistrationupdateResult);
  if(res.organizationregistrationupdateResult.Message=='Sucess'){
   console.log('Insert Success');
   this.route.navigate(['/Organization-List'])
   localStorage.setItem('updatedata', 'Success');
 }else{
   console.log('Please Try Again Later');
 }
 });





// }); 
  
} else {
  this.validateAllFormFields(this.form);
}
 }


 changeState(e) {
  console.log('eventfire');
 
  console.log(e);
   console.log(this.billdeskService.getCity(e).then(res => this.cityList = res));
}
}
