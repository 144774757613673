<!-- <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" style="display:flex;"> <i class="fa fa-exclamation-circle"
            style="color:rgb(241, 122, 75);font-size: 25px;" aria-hidden="true"></i> &nbsp;&nbsp; Internet Connection
          Error.</h1>
      </div>
      <div class="modal-body">
        <h3>Your Device is currently not connected to intenet , So Please check Your Connectivity and Try again </h3>
      </div>
     <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div> -->

<!-- this is alert for internet connectivity -->


<app-online-status
  [onlineStatusMessage]="connectionStatusMessage"
  [onlineStatus]="connectionStatus">
</app-online-status>
<app-header [hidden]="hidenave"></app-header>