import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';


import { Billdesk } from './billdesk';

import { environment } from '../environments/environment';


import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BilldeskService {
    private APIURL = environment.baseUrl;
    UserID: any;
    UserTypeID: any;
    status: string[] = ['OUTOFSTOCK', 'INSTOCK', 'LOWSTOCK'];
    APIURLUAT = 'https://uatapibilldesk.rntu.in/api/billdesk/';

    authtoken: any;
    url: any;
    constructor(private http: HttpClient) {
        this.UserTypeID = localStorage.getItem('UserTypeID');
        this.UserID = localStorage.getItem('UserID');
    }



    isActive(status: any) {
        return (status == '1' || status == 1 || status == true || status == 'true') ? 1 : 0;
    }

    getOrganization() {

        return this.http.get<any>(this.APIURL + 'Organization?UserID=' + this.UserID + '&RoleTypeID=' + this.UserTypeID)
            .toPromise()
            .then(res => <Billdesk[]>res.OrganizationListResult.data)
            .then(res => { return res; });

    }
    //   //mode to token  replace by  getOrganizationsList

    getOrganizationsList() {

        return this.http.get<any>(this.APIURL + '/organization/organizationlist')
            .toPromise()
            .then(res => <Billdesk[]>res.organizationlistResult.dataobject)
            .then(res => { return res; });

    }



    getOrganizationsListDDL() {
        return this.http.get<any>(this.APIURL + '/organization/organizationlistforddl')
            .toPromise()
            .then(res => <Billdesk[]>res.organizationlistforddlResult.dataobject)
            .then(res => { return res; });

    }



    getOrganizationsLisInvoicetDDL() {
        return this.http.get<any>(this.APIURL + '/organization/organizationlistforInvoiceddl')
            .toPromise()
            .then(res => <Billdesk[]>res.organizationlistforInvoiceddlResult.dataobject)
            .then(res => { return res; });
    }

    // getOrganizationLIst() {

    //     return this.http.get<any>(this.APIURL+'Organization?UserID='+this.UserID+'&RoleTypeID='+this.UserTypeID)
    //     .toPromise()
    //     .then(res => <Billdesk[]>res.OrganizationListResult.data)
    //     .then(res => { return res; });

    // }

    // getOrganizationList() {

    //     return this.http.get<any>(this.APIURL+'Organization?UserID='+this.UserID+'&RoleTypeID='+this.UserTypeID+'&FlagName')
    //     .toPromise()
    //     .then(res => <Billdesk[]>res.OrganizationListResult.data)
    //     .then(res => { return res; });
    // }






    // for dashboard

    getMonthDetail() {
        return this.http.get<any>('assets/month.json')
            .toPromise()
            .then(res => <Billdesk[]>res.data)
            .then(data => { return data; });
    }





    getVendorDetail() {

        return this.http.get<any>(this.APIURL + 'vendor?UserID=' + this.UserID + '&UserTypeID=' + this.UserTypeID)
            .toPromise()
            .then(res => <Billdesk[]>res.GetVendorListResult.data)
            .then(data => { return data; });
    }




    getVendorList() {

        return this.http.get<any>(this.APIURL + '/vendor/vendorlist')
            .toPromise()
            .then(res => <Billdesk[]>res.vendorlistResult.dataobject)
            .then(data => { return data; });
    }


    getVendorDDLInvoiceList() {

        return this.http.get<any>(this.APIURL + '/vendor/vendorlistddl')
            .toPromise()
            .then(res => <Billdesk[]>res.vendorlistddlResult.dataobject)
            .then(data => { return data; });
    }


    getInstituteList() {
        return this.http.get<any>(this.APIURL + '/institution/institutionlist?OrgID=0')
            .toPromise()
            .then(res => <Billdesk[]>res.institutionlistResult.dataobject)
            .then(data => { return data; });
    }




    getInstituteDetail() {
        return this.http.get<any>(this.APIURL + 'Institution?UserID=' + this.UserID + '&RoleTypeID=' + this.UserTypeID + '&OrgId=0')
            .toPromise()
            .then(res => <Billdesk[]>res.GetInstituteListResult.data)
            .then(data => { return data; });
    }




    getServicesDetail() {



        return this.http.get<any>(this.APIURL + 'ItemCategory?UserID=' + this.UserID + '&UserTypeID=' + this.UserTypeID)
            .toPromise()
            .then(res => <Billdesk[]>res.ItemCategoryListResult.data)
            .then(data => { return data; });
    }



    getServicesList() {
        return this.http.get<any>(this.APIURL + '/itemcategory/itemcategorylist')
            .toPromise()
            .then(res => <Billdesk[]>res.itemcategorylistResult.dataobject)
            .then(data => { return data; });
    }
    // for Reports
    getReportMonthWise(month: any) {



        return this.http.get<any>(this.APIURL + 'Report?MonthName=' + month + '&monthid=0')
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceListResult.data)
            .then(res => { return res; });

    }

    // for Reports
    getReportDepartmentWise(departmentID: any) {



        return this.http.get<any>(this.APIURL + 'Report?DepartmentID=' + departmentID)
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceListResult.data)
            .then(res => { return res; });

    }


    getReportYearWise(year: any) {



        return this.http.get<any>(this.APIURL + 'Report?YearName=' + year + '&type=Year')
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceListResult.data)
            .then(res => { return res; });

    }
    getReportVendorWise(Vendor: any) {



        return this.http.get<any>(this.APIURL + 'Report?VendorID=' + Vendor)
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceListResult.data)
            .then(res => { return res; });

    }

    getReportInstituteWise(divid: any) {



        return this.http.get<any>(this.APIURL + 'Report?DivID=' + divid)
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceListResult.data)
            .then(res => { return res; });

    }



    getReportVendorandDivisionWise(vendorid: any, divid: any) {



        return this.http.get<any>(this.APIURL + 'Report/VenderDivWiseInvoiceList?VendorID=' + vendorid + '&DivID=' + divid)
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceListResult.data)
            .then(res => { return res; });

    }


    getReportYearMonthWise(InvoiceYear: any, InvoiceMonth: any) {
        return this.http.get<any>(this.APIURL + 'Report?MonthName=' + InvoiceMonth + '&monthid=0&year=' + InvoiceYear)
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceListResult.data)
            .then(res => { return res; });

    }

    getReportVendorandMonthWise(vendorid: any, month: any) {



        return this.http.get<any>('https://services.aisect.org//api/Report?MonthName=' + month + '&VendorID=' + vendorid + '&Type=0')
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceListResult.data)
            .then(res => { return res; });

    }



    getReportVendorandServiceWise(vendorid: any, ItemCategoryID: any) {
        return this.http.get<any>('https://services.aisect.org//api/Report?VendorID=' + vendorid + '&ServiceID=' + ItemCategoryID + '&VendorService=0')
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceListResult.data)
            .then(res => { return res; });
    }



    getDivision() {
        return this.http.get<any>(this.APIURL + 'Institution?UserID=' + this.UserID + '&RoleTypeID=' + this.UserTypeID + '&OrgId=0')
            .toPromise()
            .then(res => <Billdesk[]>res.GetInstituteListResult.data)
            .then(res => { return res; });
    }

    getDivisionList() {
        return this.http.get<any>(this.APIURL + '/institution/institutionlist')
            .toPromise()
            .then(res => <Billdesk[]>res.institutionlistResult.dataobject)
            .then(res => { return res; });
    }



    getDivisionByOrg(id: any) {
        return this.http.get<any>(this.APIURL + 'Institution?UserID==' + this.UserID + '&RoleTypeID=' + this.UserTypeID + '&OrgId=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.GetInstituteListResult.data)
            .then(res => { return res; });
    } //.change for token replace by  getDivisionByOrgList

    getDivisionByOrgList(id: any) {
        return this.http.get<any>(this.APIURL + '/institution/institutionlist?OrgId=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.institutionlistResult.dataobject)
            .then(res => { return res; });
    }



    getDivisionByOrgbYDLLList(id: any) {
        return this.http.get<any>(this.APIURL + '/institution/institutionlistforddlbyorgid?OrgID=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.institutionlistforddlbyorgidResult.dataobject)
            .then(res => { return res; });
    }


    getDivisionByOrgListForInvoice(id: any) {
        return this.http.get<any>(this.APIURL + '/institution/institutionlistforinvoice?OrgID=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.institutionlistforinvoiceResult.dataobject)
            .then(res => { return res; });
    }





    getDivisionListByOrg(id: any) {
        return this.http.get<any>(this.APIURL + 'Institution?UserID==' + this.UserID + '&RoleTypeID=' + this.UserTypeID + '&OrgId=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.GetInstituteListResult.data)
            .then(res => { return res; });
    }



    getItemByCategory(id: any) {
        return this.http.get<any>(this.APIURL + '/item/itemlistbyitemcategoryid?itemCategoryID=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.itemlistbyitemcategoryidResult.dataobject)
            .then(res => { return res; });
    }

    getItemByCategoryDDLInvoice(id: any) {
        return this.http.get<any>(this.APIURL + '/item/itemlistbyitemcategoryidforinvoice?itemCategoryID=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.itemlistbyitemcategoryidforinvoiceResult.dataobject)
            .then(res => { return res; });
    }

    getVendor() {


        return this.http.get<any>(this.APIURL + 'vendor?UserID=' + this.UserID + '&UserTypeID=' + this.UserTypeID)
            .toPromise()
            .then(res => <Billdesk[]>res.GetVendorListResult.data)
            .then(res => { return res; });

    }


    getItemCategory() {

        return this.http.get<any>(this.APIURL + 'ItemCategory?UserID=' + this.UserID + '&UserTypeID=' + this.UserTypeID)
            .toPromise()
            .then(res => <Billdesk[]>res.ItemCategoryListResult.data)
            .then(res => { return res; });

    } //without token system 

    getItemCategorylist() {

        return this.http.get<any>(this.APIURL + '/itemcategory/itemcategorylist')
            .toPromise()
            .then(res => <Billdesk[]>res.itemcategorylistResult.dataobject)
            .then(res => { return res; });

    }


    getSaleTypelist() {

        return this.http.get<any>(this.APIURL + '/saletype/saletypelist')
            .toPromise()
            .then(res => <Billdesk[]>res.saletypelistResult.dataobject)
            .then(res => { return res; });

    }


    getItemCategoryDDLInvoicelist() {
        return this.http.get<any>(this.APIURL + '/itemcategory/itemcategorylistforddl')
            .toPromise()
            .then(res => <Billdesk[]>res.itemcategorylistforddlResult.dataobject)
            .then(res => { return res; });

    }


    getItemCategoryCombinelist() {
        return this.http.get<any>(this.APIURL + '/item/itemlistforcombineddl')
            .toPromise()
            .then(res => <Billdesk[]>res.itemlistforcombineddlResult.dataobject)
            .then(res => { return res; });
    }




 

 
    getHeadItemCombinelist() {
        return this.http.get<any>(this.APIURL + '/invoiceheaditem/invoiceheadlistforcombineddl')
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceheadlistforcombineddlResult.dataobject)
            .then(res => { return res; });
    }




    getDashboardCountDetail() {


        this.UserID = localStorage.getItem('UserID');


        this.UserTypeID = localStorage.getItem('UserTypeID');




        // return this.http.get<any>(this.APIURL+'DashboardCount?UserID='+this.UserID+'&UserTypeID='+this.UserTypeID)



        return this.http.get<any>(this.APIURL + '/dashboardcount/dashboardcountlist')
            .toPromise()
            .then(res => <Billdesk[]>res.dashboardcountlistResult.dataobject)
            .then(res => { return res; });
    }





    dashboardcountregisteredinvoicelistResult() {
        return this.http.get<any>(this.APIURL + '/dashboardcount/dashboardcountregisteredinvoicelist')
            .toPromise()
            .then(res => <Billdesk[]>res.dashboardcountregisteredinvoicelistResult.dataobject)
            .then(res => { return res; });
    }

    dashboardcountapprovedinvoicelistResult() {
        return this.http.get<any>(this.APIURL + '/dashboardcount/dashboardcountapprovedinvoicelist')
            .toPromise()
            .then(res => <Billdesk[]>res.dashboardcountapprovedinvoicelistResult.dataobject)
            .then(res => { return res; });
    }


    dashboardcountpaidinvoicelistResult() {
        return this.http.get<any>(this.APIURL + '/dashboardcount/dashboardcountpaidinvoicelist')
            .toPromise()
            .then(res => <Billdesk[]>res.dashboardcountpaidinvoicelistResult.dataobject)
            .then(res => { return res; });
    }


    dashboardcountpendinginvoicelistResult() {
        return this.http.get<any>(this.APIURL + '/dashboardcount/dashboardcountpendinginvoicelist')
            .toPromise()
            .then(res => <Billdesk[]>res.dashboardcountpendinginvoicelistResult.dataobject)
            .then(res => { return res; });
    }

    InvSumYear_Month() {
        return this.http.get<any>(this.APIURL + '/dashboardcount/dashboardcountyearmonthwiseinvoicesummarylist')
            .toPromise()
            .then(res => <Billdesk[]>res.dashboardcountyearmonthwiseinvoicesummarylistResult.dataobject)
            .then(res => { return res; });
    }




    InvSumYear_Org() {
        return this.http.get<any>(this.APIURL + '/dashboardcount/dashboardcountyearorganizationwiseinvoicesummarylist')
            .toPromise()
            .then(res => <Billdesk[]>res.dashboardcountyearorganizationwiseinvoicesummarylistResult.dataobject)
            .then(res => { return res; });
    }

    InvSumYear_Vendor() {
        return this.http.get<any>(this.APIURL + '/dashboardcount/dashboardcountyearvendornamewiseinvoicesummarylist')
            .toPromise()
            .then(res => <Billdesk[]>res.dashboardcountyearvendornamewiseinvoicesummarylistResult.dataobject)
            .then(res => { return res; });
    }



    InvSumYear_Month_Vendor() {
        return this.http.get<any>(this.APIURL + '/dashboardcount/dashboardcountyearmonthvendornamewiseinvoicesummarylist')
            .toPromise()
            .then(res => <Billdesk[]>res.dashboardcountyearmonthvendornamewiseinvoicesummarylistResult.dataobject)
            .then(res => { return res; });
    }

    InvSumYear_Service() {
        return this.http.get<any>(this.APIURL + '/dashboardcount/dashboardcountyearservicewiseinvoicesummarylist')
            .toPromise()
            .then(res => <Billdesk[]>res.dashboardcountyearservicewiseinvoicesummarylistResult.dataobject)
            .then(res => { return res; });
    }



    // dashboardcountyearmonthwiseinvoicesummarylistResult(){     
    //     return this.http.get<any>(this.APIURL+'/dashboardcount/dashboardcountyearmonthwiseinvoicesummarylist')
    //         .toPromise()
    //         .then(res => <Billdesk[]>res.dashboardcountyearmonthwiseinvoicesummarylistResult.dataobject)
    //         .then(res => { return res; });
    //     }







    getItemList() {


        // http://services.aisect.org/api/Item?UserID=1&UserTypeID=1&ItemCategoryID=0

        return this.http.get<any>(this.APIURL + '/item/itemlist')
            .toPromise()
            .then(res => <Billdesk[]>res.itemlistResult.dataobject)
            .then(res => { return res; });

    }


    getDepartment() {


        return this.http.get<any>(this.APIURL + '/department/departmentlist')
            .toPromise()
            .then(res => <Billdesk[]>res.departmentlistResult.dataobject)
            .then(res => { return res; });

    }


    getDepartmentList() {


        return this.http.get<any>(this.APIURL + '/department/departmentlist?OrgId=0&DivId=0')
            .toPromise()
            .then(res => <Billdesk[]>res.departmentlistResult.dataobject)
            .then(res => { return res; });

    }


    getStage() {
        return this.http.get<any>(this.APIURL + 'MSTStage?UserID=1&UserTypeID=0')
            .toPromise()
            .then(res => <Billdesk[]>res.StageListResult.data)
            .then(res => { return res; });

    }


    getStageByRole(UserTypeID: any) {
        return this.http.get<any>(this.APIURL + 'MSTStage?UserID=1&UserTypeID=' + UserTypeID)
            .toPromise()
            .then(res => <Billdesk[]>res.StageListResult.data)
            .then(res => { return res; });

    }



    getStageByRoleType(UserTypeID: any, StageID: any) {
        return this.http.get<any>(this.APIURL + '/mststage/mststagelistbyroleidopen?RoleTypeID=' + UserTypeID + '&StageID=' + StageID)
            .toPromise()
            .then(res => <Billdesk[]>res.mststagelistbyroleidopenResult.dataobject)
            .then(res => { return res; });

    }
    getStageListByRoleType(StageID: any) {
        return this.http.get<any>(this.APIURL + '/mststage/mststagelistbyroleid?StageID=' + StageID)
            .toPromise()
            .then(res => <Billdesk[]>res.mststagelistbyroleidResult.dataobject)
            .then(res => { return res; });

    }
    getStageList() {
        return this.http.get<any>(this.APIURL + '/mststage/mststagelist')
            .toPromise()
            .then(res => <Billdesk[]>res.mststagelistResult.dataobject)
            .then(res => { return res; });

    }



    getHead() {
        return this.http.get<any>(this.APIURL + '/invoiceheadcategory/invoiceheadcategorylist')
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceheadcategorylistResult.dataobject)
            .then(res => { return res; });

    }



    getUserlist() {
        return this.http.get<any>(this.APIURL + '/userprofile/userprofilelist')
            .toPromise()
            .then(res => <Billdesk[]>res.userprofilelistResult.dataobject)
            .then(res => { return res; });
    }


    getMenu() {
        return this.http.get<any>(this.APIURL + '/mstmenu/mstmenulist')
            .toPromise()
            .then(res => <Billdesk[]>res.mstmenulistResult.dataobject)
            .then(res => { return res; });
    }


    getMenuForRolemaping() {
        return this.http.get<any>(this.APIURL + '/rolemenumapping/rolemenumappinglistbyrole?RoleType=1')
            .toPromise()
            .then(res => <Billdesk[]>res.rolemenumappinglistbyroleResult.dataobject)
            .then(res => { return res; });
    }


    getTrackingStatus(id) {



        return this.http.get<any>(this.APIURL + '/invoicetracking/invoicetrackinglist?InvoiceID=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.invoicetrackinglistResult.dataobject)
            .then(res => { return res; });

    }

    getTrackingStatusbyEmail(id: any, RoleTypeID: any) {
        return this.http.get<any>(this.APIURL + '/invoicetracking/invoicetrackinglistopen?RoleTypeID=' + RoleTypeID + '&InvoiceID=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.invoicetrackinglistopenResult.dataobject)
            .then(res => { return res; });

    }

    getUserRole() {
        return this.http.get<any>(this.APIURL + '/mstroletype/mstroletypelist')
            .toPromise()
            .then(res => <Billdesk[]>res.mstroletypelistResult.dataobject)
            .then(res => { return res; });

    }


    getUserRoleFOrDDl() {
        return this.http.get<any>(this.APIURL + '/userroletype/userroletypelistforddl')
            .toPromise()
            .then(res => <Billdesk[]>res.userroletypelistforddlResult.dataobject)
            .then(res => { return res; });

    }

    getUserRoleList() {
        return this.http.get<any>(this.APIURL + '/userroletype/userroletypelist')
            .toPromise()
            .then(res => <Billdesk[]>res.userroletypelistResult.dataobject)
            .then(res => { return res; });

    }

    // getMenuMappingListByRoleID(Role_Id){  
    //     return this.http.get<any>(this.APIURL+'/rolemenumapping/rolemenumappinglistbyrole?RoleType='+Role_Id)
    //     .toPromise()
    //     .then(res => <Billdesk[]>res.rolemenumappinglistbyroleResult.dataobject)
    //     .then(res => { return res; });

    // }
    getMenuMappingListByRoleID(Role_Id) {
        return this.http.get<any>(this.APIURL + '/rolemenumapping/rolemenumappinglistbyrolenew?RoleType=' + Role_Id)
            .toPromise()
            .then(res => <Billdesk[]>res.rolemenumappinglistbyrolenewResult.dataobject)
            .then(res => { return res; });
    }





    getUserRoleRight() {
        return this.http.get<any>(this.APIURL + 'UserRoleRightsMapping?UserID=1&UserTypeID=1')
            .toPromise()
            .then(res => <Billdesk[]>res.UserRoleRightsMappingListResult.data)
            .then(res => { return res; });

    }


    getHeaditem() {

        return this.http.get<any>(this.APIURL + '/InvoiceHeadItem?UserID=' + this.UserID + '&RoleTypeID=' + this.UserTypeID)
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceHeadItemListResult.data)
            .then(res => { return res; });

    }

    getHeaditemlist() {

        return this.http.get<any>(this.APIURL + '/invoiceheaditem/invoiceheaditemlist')
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceheaditemlistResult.dataobject)
            .then(res => { return res; });

    }





    getPriority() {
        return this.http.get<any>(this.APIURL + '/priority/prioritylist')
            .toPromise()
            .then(res => <Billdesk[]>res.prioritylistResult.dataobject)
            .then(res => { return res; });

    }



    getInvoiceFor() {

        return this.http.get<any>(this.APIURL + '/invoicefor/invoiceforlist')
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceforlistResult.dataobject)
            .then(res => { return res; });

    }



    // getInvoiceDetail(){  
    //     return this.http.get<any>(this.APIURL+'InvoiceRegistration?UserID=1&UserTypeID=1')
    //     .toPromise()
    //     .then(res => <Billdesk[]>res.InvoiceListResult.data)
    //     .then(res => { return res; });

    // } //old 2812
    getInvoiceDetail() {


        this.UserID = localStorage.getItem('UserID');

        this.UserTypeID = localStorage.getItem('UserTypeID');


        return this.http.get<any>(this.APIURL + 'InvoiceRegistration?UserID=' + this.UserID + '&UserTypeID=' + this.UserTypeID + '&ListType=Pending')
            .toPromise()
            .then(res => <Billdesk[]>res.InvoiceListResult.data)
            .then(res => { return res; });

    }
    getInvoicelist() {


        this.UserID = localStorage.getItem('UserID');



        this.UserTypeID = localStorage.getItem('UserTypeID');


        return this.http.get<any>(this.APIURL + '/invoiceregistration/invoiceregistrationlist?ListType=Pending')
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceregistrationlistResult.dataobject)
            .then(res => { return res; });

    }







    getInvoiceDetailForPoCreation() {
        return this.http.get<any>(this.APIURL + '/invoiceregistration/invoiceregistrationlist?ListType=POCreation')
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceregistrationlistResult.dataobject)
            .then(res => { return res; });
    }








    getInvoiceHistoryDetail() {



        this.UserID = localStorage.getItem('UserID');


        this.UserTypeID = localStorage.getItem('UserTypeID');

        return this.http.get<any>(this.APIURL + '/invoiceregistration/invoiceregistrationlist?ListType=Paid')
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceregistrationlistResult.dataobject)
            .then(res => { return res; });

    }



    getPoList() {
        return this.http.get<any>(this.APIURL + '/pobatchregistration/pobatchregistrationlist')
            .toPromise()
            .then(res => <Billdesk[]>res.pobatchregistrationlistResult.dataobject)
            .then(res => { return res; });

    }


    getInvoiceByid(id: any) {

        return this.http.get<any>(this.APIURL + '/invoiceregistration/invoiceregistrationdetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceregistrationdetailResult.dataobject)
            .then(res => { return res; });

    }


    getInvoiceByidopen(id: any) {
        return this.http.get<any>(this.APIURL + '/invoiceregistration/invoiceregistrationdetailopen/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceregistrationdetailopenResult.dataobject)
            .then(res => { return res; });

    }



    getPoDetail(id: any) {
        return this.http.get<any>(this.APIURL + '/poBatchRegistration/poBatchRegistrationDetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.pobatchregistrationdetailResult.dataobject)
            .then(res => { return res; });

    }

    getPoDetailForCount(id: any) {
        return this.http.get<any>(this.APIURL + '/poBatchRegistration/poBatchRegistrationDetail/' + id);
    }



    getDepartmentByOrgAndDivId(orgid: any, divid: any) {
        return this.http.get<any>(this.APIURL + 'Department?UserID=' + this.UserID + '&RoleTypeID=' + this.UserTypeID + '&OrgID=' + orgid + '&DivID=' + divid)
            .toPromise()
            .then(res => <Billdesk[]>res.DepartmentListResult.datalist)
            .then(res => { return res; });

    } // token change rplace getDepartmentListByOrgAndDivId



    getDepartmentListByOrgAndDivId(orgid: any, divid: any) {




        return this.http.get<any>(this.APIURL + '/department/departmentlistforddlbyorgdivid?OrgID=' + orgid + '&DivID=' + divid)
            .toPromise()
            .then(res => <Billdesk[]>res.departmentlistforddlbyorgdividResult.dataobject)
            .then(res => { return res; });

            
    }



    getDepartmentListDLLByOrgAndDivId(orgid: any, divid: any) {
        return this.http.get<any>(this.APIURL + '/department/departmentlistforddlbyorgdivid?OrgID=' + orgid + '&DivID=' + divid)
            .toPromise()
            .then(res => <Billdesk[]>res.departmentlistforddlbyorgdividResult.dataobject)
            .then(res => { return res; });

    }



        
    getDepartmentlistforinvoice(orgid: any, divid: any) {
        return this.http.get<any>(this.APIURL + '/department/departmentlistforinvoice?OrgID=' + orgid + '&DivID=' + divid)
            .toPromise()
            .then(res => <Billdesk[]>res.departmentlistforinvoiceResult.dataobject)
            .then(res => { return res; });

    }






    getDepartmentByOrgAndDivIdList(orgid: any, divid: any) {
        return this.http.get<any>(this.APIURL + 'Department?UserID=' + this.UserID + '&RoleTypeID=' + this.UserTypeID + '&OrgID=' + orgid + '&DivID=' + divid + '&FlagName')
            .toPromise()
            .then(res => <Billdesk[]>res.DepartmentListResult.datalist)
            .then(res => { return res; });
    }
    getPoInstutueWise(id: any) {
        return this.http.get<any>(this.APIURL + 'InstituteWiseSummary?UserID=' + this.UserID + '&UserTypeID=' + this.UserTypeID + '&POOrderID=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.GetInstituteWiseSummaryResult.data)
            .then(res => { return res; });

    }

    getOrganizationByid(id: any) {
        return this.http.get<any>(this.APIURL + '/organization/organizationdetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.organizationdetailResult.dataobject)
            .then(res => { return res; });

    }
    getDivisionByid(id: any) {
        return this.http.get<any>(this.APIURL + '/institution/institutiondetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.institutiondetailResult.dataobject)
            .then(res => { return res; });

    }
    getDepartmentByid(id: any) {
        return this.http.get<any>(this.APIURL + '/department/departmentdetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.departmentdetailResult.dataobject)
            .then(res => { return res; });

    }







    getItemCategoryByid(id: any) {
        return this.http.get<any>(this.APIURL + '/itemcategory/itemcategorydetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.itemcategorydetailResult.dataobject)
            .then(res => { return res; });

    }

    getItemByid(id: any) {
        return this.http.get<any>(this.APIURL + '/item/itemdetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.itemdetailResult.dataobject)
            .then(res => { return res; });

    }

    getInvoiceForByid(id: any) {
        return this.http.get<any>(this.APIURL + '/invoicefor/invoicefordetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.invoicefordetailResult.dataobject)
            .then(res => { return res; });

    }



    getInvoiceHeadCategoryByid(id: any) {
        return this.http.get<any>(this.APIURL + '/invoiceheadcategory/invoiceheadcategorydetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceheadcategorydetailResult.dataobject)
            .then(res => { return res; });

    }



    getInvoiceHeadItemByid(id: any) {
        return this.http.get<any>(this.APIURL + '/invoiceheaditem/invoiceheaditemdetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.invoiceheaditemdetailResult.dataobject)
            .then(res => { return res; });

    }






    getVendorByid(id: any) {
        return this.http.get<any>(this.APIURL + '/vendor/vendordetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.vendordetailResult.dataobject)
            .then(res => { return res; });
    }


    getStageByid(id: any) {
        return this.http.get<any>(this.APIURL + '/mststage/mststagedetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.mststagedetailResult.dataobject)
            .then(res => { return res; });

    }


    getPriorityByid(id: any) {
        return this.http.get<any>(this.APIURL + '/priority/prioritydetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.prioritydetailResult.dataobject)
            .then(res => { return res; });

    }




    getHeaderMenuAuth() {
        return this.http.get<any>(this.APIURL + '/userlogin/rolewisemenulist')
            .toPromise()
            .then(res => <Billdesk[]>res.dataobject)
            .then(res => { return res; });
    }





    IsMenuShow() {
        let updatedataurl = this.APIURL + '/userlogin/rolewisemenuarrlist';
        return this.http.get(updatedataurl);
      }



    getUserByid(id: any) {
        return this.http.get<any>(this.APIURL + '/userprofile/userprofiledetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.userprofiledetailResult.dataobject)
            .then(res => { return res; });

    }


    getRoleByid(id: any) {
        return this.http.get<any>(this.APIURL + '/userroletype/userroletypedetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.userroletypedetailResult.dataobject)
            .then(res => { return res; });

    }

    getMenuByid(id: any) {
        return this.http.get<any>(this.APIURL + '/mstmenu/mstmenudetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.mstmenudetailResult.dataobject)
            .then(res => { return res; });

    }







    DeleteOrganizationByid(id: any) {


        return this.http.delete<any>(this.APIURL + 'Organization/delete?id=' + id)
            .toPromise()
            .then(res => <Billdesk[]>res)
            .then(res => { return res; });

    }



    // getState(){
    //     const datarequest={"Type":"State"};

    //   let url ="http://Test5.aisectonline.com/api/dropdown";
    //  return  this.http.post(url, datarequest).toPromise().then((data:any)=>{
    // return data.data;
    //   }); 
    // }



    getState() {

        return this.http.get<any>(this.APIURL + '/state/statelist')
            .toPromise()
            .then(res => <Billdesk[]>res.statelistResult.dataobject)
            .then(res => { return res; });

    }

    getCity(statekey) {

        return this.http.get<any>(this.APIURL + '/district/districtlist?StateID=' + statekey)
            .toPromise()
            .then(res => <Billdesk[]>res.districtlistResult.dataobject)
            .then(res => { return res; });

    }
    // getCity(statekey){
    //     const datarequest={"Type":"District","ID1":statekey};

    //   let url ="http://Test5.aisectonline.com/api/dropdown";
    //  return  this.http.post(url, datarequest).toPromise().then((data:any)=>{
    // return data.data;
    //   }); 
    // }
    geSelectedInvoice() {
        return this.http.get<any>('assets/selectinvoice.json')
            .toPromise()
            .then(res => <Billdesk[]>res.data)
            .then(data => { return data; });
    }

    getListPo() {
        return this.http.get<any>('assets/polist.json')
            .toPromise()
            .then(res => <Billdesk[]>res.data)
            .then(data => { return data; });
    }



    getProductsWithOrdersSmall() {
        return this.http.get<any>('assets/products-orders-small.json')
            .toPromise()
            .then(res => <Billdesk[]>res.data)
            .then(data => { return data; });
    }

    // generatePrduct(): Billdesk {
    //     const product: Billdesk =  {
    //         id: this.generateId(),
    //         name: this.generateName(),
    //         description: "Product Description",
    //         price: this.generatePrice(),
    //         quantity: this.generateQuantity(),
    //         category: "Product Category",
    //         inventoryStatus: this.generateStatus(),
    //         rating: this.generateRating()
    //     };

    //     product.image = product.name.toLocaleLowerCase().split(/[ ,]+/).join('-')+".jpg";;
    //     return product;
    // }


    getPOReportYearMonthWise(InvoiceYear: any, InvoiceMonth: any) {
        return this.http.get<any>(this.APIURL + '/pobatchregistration/pobatchregistrationlistbyyearmonth?Year=' + InvoiceYear + '&Month=' + InvoiceMonth)
            .toPromise()
            .then(res => <Billdesk[]>res.pobatchregistrationlistbyyearmonthResult.dataobject)
            .then(res => { return res; });
    }



    getPObatchReportYearMonthWise(InvoiceYear: any, InvoiceMonth: any) {
        // https://uatapibilldesk.rntu.in/api/billdesk/pobatchregistration/pobatchregistrationlistbydate?FromDate=2022-02-01&ToDate=2023-01-31&ts=1674471264296
        return this.http.get<any>(this.APIURL + '/pobatchregistration/pobatchregistrationlistbydate?FromDate=' + InvoiceYear + '&ToDate=' + InvoiceMonth)
            .toPromise()
            .then(res => <Billdesk[]>res.pobatchregistrationlistbydateResult.dataobject)
            .then(res => { return res; });


    }
    getPObatchSummary(InvoiceYear: any, InvoiceMonth: any) {
        https://uatapibilldesk.rntu.in/api/billdesk/pobatchregistration/pobatchregistrationlistsummary?FromDate=2022-02-01&ToDate=2023-01-31&ts=1674471264296
        return this.http.get<any>(this.APIURL + '/pobatchregistration/pobatchregistrationlistsummary?FromDate=' + InvoiceYear + '&ToDate=' + InvoiceMonth)
            .toPromise()
            .then(res => <Billdesk[]>res.pobatchregistrationlistsummaryResult.dataobject)
            .then(res => { return res; });


    }



    generateId() {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    // generateName() {
    //     return this.productNames[Math.floor(Math.random() * Math.floor(30))];
    // }

    generatePrice() {
        return Math.floor(Math.random() * Math.floor(299) + 1);
    }

    generateQuantity() {
        return Math.floor(Math.random() * Math.floor(75) + 1);
    }

    generateStatus() {
        return this.status[Math.floor(Math.random() * Math.floor(3))];
    }

    generateRating() {
        return Math.floor(Math.random() * Math.floor(5) + 1);
    }






    UpdateData(updateurl: any, datarequest: any) {
        let updatedataurl = this.APIURL + updateurl;
        return this.http.post(updatedataurl, datarequest);
    }

    RegisterData(registerurl: any, datarequest: any) {
        let updatedataurl = this.APIURL + registerurl;
        return this.http.post(updatedataurl, datarequest);
    }








    access_token(tokenstring: any) {
        localStorage.removeItem('ACCESS_TOKEN');
        let encoded: string = atob(tokenstring);
        if (encoded == 'securityisgood!') {
            let token_url = this.APIURL + '/useraccess/checkuseraccess?MerchantID=101&MerchantKey=111';
            return this.http.get(token_url);
        }
        else {

            const SubscribeObservable = new Observable((observer) => {
                observer.next('ACCESS TOKEN FAILED ! PLEASE CONTACT SERVICE PROVIDER');
            });
            return SubscribeObservable;
        }
    }

    token(tokenstring: any) {
        let encoded: string = atob(tokenstring);


        if (encoded == 'securityisgood!') {
            const datarequest = {
                "registrationID": 0,
                "userID": 0,
                "roleTypeID": 0,
                "roleTypeName": "string",
                "userLoginID": "string",
                "userPassword": "string",
                "transactionPassword": "string",
                "activeStatus": "string",
                "currentLoginDate": "string",
                "lastLoginDate": "string",
                "attemptNo": 0,
                "attemptDate": "string",
                "createdDate": "string",
                "createdBy": "string",
                "updatedDate": "string",
                "updatedBy": "string",
                "remark": "string",
                "userCode": "string",
                "userOrgID": "string",
                "userDivID": "string",
                "userDeptID": "string",
                "userOrgName": "string",
                "userDivName": "string",
                "userDeptName": "string",
                "userFullName": "string",
                "userEmailID": "string",
                "userMobileNo": "string",
                "userEmail": "string",
                "userMobile": "string",
                "isEmailVerified": "string",
                "isMobileVerified": "string"
            };
            let token_url = 'https://uatapibilldesk.rntu.in/api/Authenticate/login';
            return this.http.post(token_url, datarequest);
        }
        else {


            localStorage.removeItem('AUTHENTICATION_TOKEN');
            const SubscribeObservable = new Observable((observer) => {
                observer.next('AUTHENTICATION FAILED ! PLEASE CONTACT SERVICE PROVIDER');
            });
            return SubscribeObservable;
        }
    }

    GetDataToken(url: any) {
        let updatedataurl = this.APIURL;
        return this.http.get(updatedataurl + url);
    }




    getAuthToken(): string {
        return localStorage.getItem('Token');
    }


    logout() {
        localStorage.clear();
    }



    //  getListData
    //  getListData(request:any,response:any) {   
    //      return this.http.get<any>(this.APIURL+request).toPromise().then(res => <Billdesk[]>res[response].dataobject).then(res => { return res; });
    //     }



    getCustomersLarge() {
        return this.http.get<any>('assets/customers-large.json')
            .toPromise()
            .then(res => res.data)
            .then(data => { return data; });
    }

/* INVENTORY FUNCTION*/ 
    
    getPurchaseList(UserInfo) {

        return this.http.get<any>(this.APIURL + '/purchase/purchaselist?'+UserInfo)
            .toPromise()
            .then(res => <Billdesk[]>res.purchaselistResult.dataobject)
            .then(res => { return res; });

    }


    getPurchaseDetailByid(id: any) {
        return this.http.get<any>(this.APIURL + '/purchase/purchasedetail' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.purchasedetailResult.dataobject)
            .then(res => { return res; });

    }
    
    getPurchaseDetailofProductByid(id: any) {
        return this.http.get<any>(this.APIURL + '/purchase/purchasedetails' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.purchasedetailsResult.dataobject)
            .then(res => { return res; });

    }
    

    
    getStockList() {

        return this.http.get<any>(this.APIURL + '/stock/stocklist')
            .toPromise()
            .then(res => <Billdesk[]>res.stocklistResult.dataobject)
            .then(res => { return res; });

    }


    getStockListexport(fromdate,todate,userinfo) {

        return this.http.get<any>(this.APIURL + '/stock/stocklistdatewise?FromDate='+fromdate+'&ToDate='+todate+userinfo)
            .toPromise()
            .then(res => <Billdesk[]>res.stocklistdatewiseResult.dataobject)
            .then(res => { return res; });

    }



    

    getInStockSummary(fromdate,todate,userinfo) {
         return this.http.get<any>(this.APIURL + '/stock/stocklistproductwise?FromDate='+fromdate+'&ToDate='+todate+'&'+userinfo)
        .toPromise()
        .then(res => <Billdesk[]>res.stocklistproductwiseResult.dataobject)
        .then(res => { return res; });

    }
    
 
    getInStockSummaryProductWiseAll(userinfo){
        return this.http.get<any>(this.APIURL + '/stock/stocklistproductwiseall?'+userinfo)
       .toPromise()
       .then(res => <Billdesk[]>res.stocklistproductwiseallResult.dataobject)
       .then(res => { return res; });

   }



    getSalesList(userinfo) {

        return this.http.get<any>(this.APIURL + '/sale/salelist?'+userinfo)
            .toPromise()
            .then(res => <Billdesk[]>res.salelistResult.dataobject)
            .then(res => { return res; });

    }
    getBrandList() {

        return this.http.get<any>(this.APIURL + '/brand/brandlist')
            .toPromise()
            .then(res => <Billdesk[]>res.brandlistResult.dataobject)
            .then(res => { return res; });

    }


    getBrandListDLL() {

        return this.http.get<any>(this.APIURL + '/brand/brandlistddl')
            .toPromise()
            .then(res => <Billdesk[]>res.brandlistddlResult.dataobject)
            .then(res => { return res; });

    }


    getBrandByid(id: any) {
        return this.http.get<any>(this.APIURL + '/brand/branddetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.branddetailResult.dataobject)
            .then(res => { return res; });

    }
 
    
    getPriceTypeid(id: any) {
        return this.http.get<any>(this.APIURL + '/pricetype/pricetypedetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.pricetypedetailResult.dataobject)
            .then(res => { return res; });

    }
 



    getUnitTypeid(id: any) {
        return this.http.get<any>(this.APIURL + '/unit/unitdetail/' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.unitdetailResult.dataobject)
            .then(res => { return res; });

    }
 


    
    getUnitList() {

        return this.http.get<any>(this.APIURL + '/unit/unitlist')
            .toPromise()
            .then(res => <Billdesk[]>res.unitlistResult.dataobject)
            .then(res => { return res; });

    }
    getUnitListDLL() {

        return this.http.get<any>(this.APIURL + '/unit/unitlistddl')
            .toPromise()
            .then(res => <Billdesk[]>res.unitlistddlResult.dataobject)
            .then(res => { return res; });

    }

    getPrice_Type_ListDLL() {

        return this.http.get<any>(this.APIURL + '/pricetype/pricetypelistddl')
            .toPromise()
            .then(res => <Billdesk[]>res.pricetypelistddlResult.dataobject)
            .then(res => { return res; });

    }



    getStockBatchno_ListDLL(productid) { 
        return this.http.get<any>(this.APIURL + '/stock/batchlistddl?ProductID='+productid)
            .toPromise()
            .then(res => <Billdesk[]>res.batchlistddlResult.dataobject)
            .then(res => { return res; });

    }


    
    getStockDetailByBatchNDLL(stockid) {

        return this.http.get<any>(this.APIURL + '/stock/stocksearchdetail?StockID='+stockid)
            .toPromise()
            .then(res => <Billdesk[]>res.stocksearchdetailResult.dataobject)
            .then(res => { return res; });

    }

       
    getPrice_Type_List() {

        return this.http.get<any>(this.APIURL + '/pricetype/pricetypelist')
            .toPromise()
            .then(res => <Billdesk[]>res.pricetypelistResult.dataobject)
            .then(res => { return res; });

    }



 

    getItemAvailableQuantityItemInventoryCombinelist(userdetail) {
        return this.http.get<any>(this.APIURL + '/stock/stockproductlist'+userdetail)
            .toPromise()
            .then(res => <Billdesk[]>res.stockproductlistResult.dataobject)
            .then(res => { return res; });
    } 



    getBrandListItemId(ItemID) {
        return this.http.get<any>(this.APIURL + '/brand/brandlistddlitemwise?ItemID='+ItemID)
            .toPromise()
            .then(res => <Billdesk[]>res.brandlistddlResult.dataobject)
            .then(res => { return res; });
    } 

 
    getSale_Detail(id: any) {
        // return this.http.get<any>(this.APIURL + '/sale/saledetail/' + id)
        //     .toPromise()
        //     .then(res => <Billdesk[]>res.saledetailResult.dataobject)
        //     .then(res => { return res; });


            return this.http.get<any>(this.APIURL + '/sale/saledetailforinvoice' + id)
            .toPromise()
            .then(res => <Billdesk[]>res.saledetailforinvoiceResult.dataobject)
            .then(res => { return res; });

    }
    // this is for getting approver list for using eg. organization edit 
    getApprover_List() {
    return this.http.get<any>(this.APIURL + '/approver/approverlistddl')
            .toPromise()
            .then(res => <Billdesk[]>res.approverlistddlResult.dataobject)
            .then(res => { return res; });
}





    getProductsData() {
        return [
            {
                id: '1000',
                code: 'f230fh0g3',
                name: 'Bamboo Watch',
                description: 'Product Description',
                image: 'bamboo-watch.jpg',
                price: 65,
                category: 'Accessories',
                quantity: 24,
                inventoryStatus: 'INSTOCK',
                rating: 5
            },
            {
                id: '1001',
                code: 'nvklal433',
                name: 'Black Watch',
                description: 'Product Description',
                image: 'black-watch.jpg',
                price: 72,
                category: 'Accessories',
                quantity: 61,
                inventoryStatus: 'OUTOFSTOCK',
                rating: 4
            },
            {
                id: '1002',
                code: 'zz21cz3c1',
                name: 'Blue Band',
                description: 'Product Description',
                image: 'blue-band.jpg',
                price: 79,
                category: 'Fitness',
                quantity: 2,
                inventoryStatus: 'LOWSTOCK',
                rating: 3
            },
            {
                id: '1003',
                code: '244wgerg2',
                name: 'Blue T-Shirt',
                description: 'Product Description',
                image: 'blue-t-shirt.jpg',
                price: 29,
                category: 'Clothing',
                quantity: 25,
                inventoryStatus: 'INSTOCK',
                rating: 5
            },
            {
                id: '1004',
                code: 'h456wer53',
                name: 'Bracelet',
                description: 'Product Description',
                image: 'bracelet.jpg',
                price: 15,
                category: 'Accessories',
                quantity: 73,
                inventoryStatus: 'INSTOCK',
                rating: 4
            },
            {
                id: '1005',
                code: 'av2231fwg',
                name: 'Brown Purse',
                description: 'Product Description',
                image: 'brown-purse.jpg',
                price: 120,
                category: 'Accessories',
                quantity: 0,
                inventoryStatus: 'OUTOFSTOCK',
                rating: 4
            },
            {
                id: '1006',
                code: 'bib36pfvm',
                name: 'Chakra Bracelet',
                description: 'Product Description',
                image: 'chakra-bracelet.jpg',
                price: 32,
                category: 'Accessories',
                quantity: 5,
                inventoryStatus: 'LOWSTOCK',
                rating: 3
            },
            {
                id: '1007',
                code: 'mbvjkgip5',
                name: 'Galaxy Earrings',
                description: 'Product Description',
                image: 'galaxy-earrings.jpg',
                price: 34,
                category: 'Accessories',
                quantity: 23,
                inventoryStatus: 'INSTOCK',
                rating: 5
            },
            {
                id: '1008',
                code: 'vbb124btr',
                name: 'Game Controller',
                description: 'Product Description',
                image: 'game-controller.jpg',
                price: 99,
                category: 'Electronics',
                quantity: 2,
                inventoryStatus: 'LOWSTOCK',
                rating: 4
            },
            {
                id: '1009',
                code: 'cm230f032',
                name: 'Gaming Set',
                description: 'Product Description',
                image: 'gaming-set.jpg',
                price: 299,
                category: 'Electronics',
                quantity: 63,
                inventoryStatus: 'INSTOCK',
                rating: 3
            },
            {
                id: '1010',
                code: 'plb34234v',
                name: 'Gold Phone Case',
                description: 'Product Description',
                image: 'gold-phone-case.jpg',
                price: 24,
                category: 'Accessories',
                quantity: 0,
                inventoryStatus: 'OUTOFSTOCK',
                rating: 4
            },
            {
                id: '1011',
                code: '4920nnc2d',
                name: 'Green Earbuds',
                description: 'Product Description',
                image: 'green-earbuds.jpg',
                price: 89,
                category: 'Electronics',
                quantity: 23,
                inventoryStatus: 'INSTOCK',
                rating: 4
            },
            {
                id: '1012',
                code: '250vm23cc',
                name: 'Green T-Shirt',
                description: 'Product Description',
                image: 'green-t-shirt.jpg',
                price: 49,
                category: 'Clothing',
                quantity: 74,
                inventoryStatus: 'INSTOCK',
                rating: 5
            },
            {
                id: '1013',
                code: 'fldsmn31b',
                name: 'Grey T-Shirt',
                description: 'Product Description',
                image: 'grey-t-shirt.jpg',
                price: 48,
                category: 'Clothing',
                quantity: 0,
                inventoryStatus: 'OUTOFSTOCK',
                rating: 3
            },
            {
                id: '1014',
                code: 'waas1x2as',
                name: 'Headphones',
                description: 'Product Description',
                image: 'headphones.jpg',
                price: 175,
                category: 'Electronics',
                quantity: 8,
                inventoryStatus: 'LOWSTOCK',
                rating: 5
            },
            {
                id: '1015',
                code: 'vb34btbg5',
                name: 'Light Green T-Shirt',
                description: 'Product Description',
                image: 'light-green-t-shirt.jpg',
                price: 49,
                category: 'Clothing',
                quantity: 34,
                inventoryStatus: 'INSTOCK',
                rating: 4
            },
            {
                id: '1016',
                code: 'k8l6j58jl',
                name: 'Lime Band',
                description: 'Product Description',
                image: 'lime-band.jpg',
                price: 79,
                category: 'Fitness',
                quantity: 12,
                inventoryStatus: 'INSTOCK',
                rating: 3
            },
            {
                id: '1017',
                code: 'v435nn85n',
                name: 'Mini Speakers',
                description: 'Product Description',
                image: 'mini-speakers.jpg',
                price: 85,
                category: 'Clothing',
                quantity: 42,
                inventoryStatus: 'INSTOCK',
                rating: 4
            },
            {
                id: '1018',
                code: '09zx9c0zc',
                name: 'Painted Phone Case',
                description: 'Product Description',
                image: 'painted-phone-case.jpg',
                price: 56,
                category: 'Accessories',
                quantity: 41,
                inventoryStatus: 'INSTOCK',
                rating: 5
            },
            {
                id: '1019',
                code: 'mnb5mb2m5',
                name: 'Pink Band',
                description: 'Product Description',
                image: 'pink-band.jpg',
                price: 79,
                category: 'Fitness',
                quantity: 63,
                inventoryStatus: 'INSTOCK',
                rating: 4
            },
            {
                id: '1020',
                code: 'r23fwf2w3',
                name: 'Pink Purse',
                description: 'Product Description',
                image: 'pink-purse.jpg',
                price: 110,
                category: 'Accessories',
                quantity: 0,
                inventoryStatus: 'OUTOFSTOCK',
                rating: 4
            },
            {
                id: '1021',
                code: 'pxpzczo23',
                name: 'Purple Band',
                description: 'Product Description',
                image: 'purple-band.jpg',
                price: 79,
                category: 'Fitness',
                quantity: 6,
                inventoryStatus: 'LOWSTOCK',
                rating: 3
            },
            {
                id: '1022',
                code: '2c42cb5cb',
                name: 'Purple Gemstone Necklace',
                description: 'Product Description',
                image: 'purple-gemstone-necklace.jpg',
                price: 45,
                category: 'Accessories',
                quantity: 62,
                inventoryStatus: 'INSTOCK',
                rating: 4
            },
            {
                id: '1023',
                code: '5k43kkk23',
                name: 'Purple T-Shirt',
                description: 'Product Description',
                image: 'purple-t-shirt.jpg',
                price: 49,
                category: 'Clothing',
                quantity: 2,
                inventoryStatus: 'LOWSTOCK',
                rating: 5
            },
            {
                id: '1024',
                code: 'lm2tny2k4',
                name: 'Shoes',
                description: 'Product Description',
                image: 'shoes.jpg',
                price: 64,
                category: 'Clothing',
                quantity: 0,
                inventoryStatus: 'INSTOCK',
                rating: 4
            },
            {
                id: '1025',
                code: 'nbm5mv45n',
                name: 'Sneakers',
                description: 'Product Description',
                image: 'sneakers.jpg',
                price: 78,
                category: 'Clothing',
                quantity: 52,
                inventoryStatus: 'INSTOCK',
                rating: 4
            },
            {
                id: '1026',
                code: 'zx23zc42c',
                name: 'Teal T-Shirt',
                description: 'Product Description',
                image: 'teal-t-shirt.jpg',
                price: 49,
                category: 'Clothing',
                quantity: 3,
                inventoryStatus: 'LOWSTOCK',
                rating: 3
            },
            {
                id: '1027',
                code: 'acvx872gc',
                name: 'Yellow Earbuds',
                description: 'Product Description',
                image: 'yellow-earbuds.jpg',
                price: 89,
                category: 'Electronics',
                quantity: 35,
                inventoryStatus: 'INSTOCK',
                rating: 3
            },
            {
                id: '1028',
                code: 'tx125ck42',
                name: 'Yoga Mat',
                description: 'Product Description',
                image: 'yoga-mat.jpg',
                price: 20,
                category: 'Fitness',
                quantity: 15,
                inventoryStatus: 'INSTOCK',
                rating: 5
            },
            {
                id: '1029',
                code: 'gwuby345v',
                name: 'Yoga Set',
                description: 'Product Description',
                image: 'yoga-set.jpg',
                price: 20,
                category: 'Fitness',
                quantity: 25,
                inventoryStatus: 'INSTOCK',
                rating: 8
            }
        ];
    }


    
    getProducts() {
        return Promise.resolve(this.getProductsData());
    }


// api for inv summary 

summary_year(year) {
    return this.http.get<any>(this.APIURL + '/summaryreport/yearmonthwiseinvoicelistsummaryreport?QueryCondition='+year)
        .toPromise()
        .then(res => <Billdesk[]>res.yearmonthwiseinvoicelistsummaryreportResult.dataobject)
        .then(res => { return res; });
}

 summary_year_organization(year) {
    return this.http.get<any>(this.APIURL + '/summaryreport/yearorganizationwiseinvoicelistsummaryreport?QueryCondition='+year)
        .toPromise()
        .then(res => <Billdesk[]>res.yearorganizationwiseinvoicelistsummaryreportResult.dataobject)
        .then(res => { return res; });
}
 summary_year_month_vendor(year) {
    return this.http.get<any>(this.APIURL + '/summaryreport/yearmonthvendornamewiseinvoicelistsummaryreport?QueryCondition='+year)
        .toPromise()
        .then(res => <Billdesk[]>res.yearmonthvendornamewiseinvoicelistsummaryreportResult.dataobject)
        .then(res => { return res; });
}

 summary_year_vendor(year) {
    return this.http.get<any>(this.APIURL + '/summaryreport/yearvendornamewiseinvoicelistsummaryreport?QueryCondition='+year)
        .toPromise()
        .then(res => <Billdesk[]>res.yearvendornamewiseinvoicelistsummaryreportResult.dataobject)
        .then(res => { return res; });
}



summary_year_service(year) {
    return this.http.get<any>(this.APIURL + '/summaryreport/yearservicewiseinvoicelistsummaryreport?QueryCondition='+year)
        .toPromise()
        .then(res => <Billdesk[]>res.yearservicewiseinvoicelistsummaryreportResult.dataobject)
        .then(res => { return res; });
}


getDetail(id: number,url:string) {
    return this.http.get<any>(this.APIURL + url + id)
        .toPromise() 
        .then(res => { return res; });
}

getList(url:string) {
    return this.http.get<any>(this.APIURL + url)
        .toPromise() 
        .then(res => { return res; });
}



getBoxList() { 
    return this.http.get<any>(this.APIURL + '/boxmaking/list')
        .toPromise()
        .then(res => <Billdesk[]>res.boxmakinglistResult.dataobject)
        .then(res => { return res; });

}


getInStockBoxList() { 
    return this.http.get<any>(this.APIURL + '/boxmaking/instocklist')
        .toPromise()
        .then(res => <Billdesk[]>res.instocklistResult.dataobject)
        .then(res => { return res; });

} 



getSaleBoxList() { 
    return this.http.get<any>(this.APIURL + '/boxsale/list')
        .toPromise()
        .then(res => <Billdesk[]>res.boxsalelistResult.dataobject)
        .then(res => { return res; });

} 


getBoxDLL() {
    return this.http.get<any>(this.APIURL + '/boxmaking/ddl')
        .toPromise()
        .then(res => <Billdesk[]>res.boxmakinglistddlResult.dataobject)
        .then(res => { return res; });
} 

}