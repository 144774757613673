
<p-progressSpinner strokeWidth="5" fill="#fff" 
animationDuration="1s" style="    z-index: 58;
margin-top: unset;
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
margin-left: 43%;
margin-top: 20%;"  *ngIf="visibleloader" >  </p-progressSpinner>

<form class="form-horizontal" [formGroup]="form"> 
    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">PRIORITY  REGISTRATION</h1>

    <div class="card-body" style="background-color:#fff;">

        

        <h1 class="card-title">
           Priority Registration
        </h1>
        <div class="row">
          
    
    
    
            <div class="position-relative  form-group col-sm-12">
                <label for="PriorityName" class="col-form-label">Priority Name  <span>*</span></label>
                <div class="">
                    <input type="text" name="PriorityName" id="PriorityName" class="form-control" formControlName="PriorityName">
         <app-field-error-display [displayError]="isFieldValid('PriorityName')" errorMsg="Please Enter Priority Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
           
    
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="IsActive" class="col-form-label">Is Active<span>*</span></label>
                <div class="">  
                <select  name="IsActive" id="IsActive" class="form-control" formControlName="IsActive">
                 <option value="1">Active</option>
                <option value="0">In Active</option> 
                </select>
         <app-field-error-display [displayError]="isFieldValid('IsActive')" errorMsg="Please Enter Is Active "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    
    
            
          
            
    
            
            
    
            
          
    
            <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    
    