


<br>
&nbsp;&nbsp;&nbsp;&nbsp; <a     [routerLink]="['/PO-List']"  class="noprint"><button class="btn btn-info btn-lg">Back</button>
 </a>  
<div class="card-body" style="background-color:#fff;">
    <h1 class="card-title"  style="font-weight:bold;">
      INVOICE PO LIST SHEET - B
     
       <br>
    </h1>
    <form class="row" >
 
        <div class="position-relative form-group col-sm-4">
            <label for="Contact_Number" class="col-form-label" style="font-weight:bold;font-size:17px;">Invoice Po number </label> <br> <span>
              {{products[0].DisplayBatchNo}}
            </span>
            
        </div> 
        <div class="position-relative  form-group col-sm-4">
            <label for="Organization_Name" class="col-form-label"  style="font-weight:bold;font-size:17px;">For The Month</label> :   {{ products[0].CreatedDate | date:'MMMM-yyyy' }} <br>  
            <label for="Organization_Name" class="col-form-label"  style="font-weight:bold;font-size:17px;">Date of issue</label> <br>  
            
            <!-- {{products[0].CreatedDate}} -->


            {{ products[0].CreatedDate | date:'dd-MMMM-yyyy, h:mm:ss' }}
             
        </div>
        <div class="position-relative form-group col-sm-4">
            <!-- <label for="Contact_Number" class="col-form-label" style="font-weight:bold;font-size:17px;">To <br>The Director General <br>AISECT GROUP Bhopal MP</label>  -->
            <img src="https://www.aisect.org/assets/wp-content/themes/twentysixteen/images/logo.png">
        </div>
        <div class="position-relative form-group col-sm-4">
            <label for="Contact_Number" class="col-form-label" style="font-weight:bold;font-size:17px;">To <br>The Director General <br>AISECT GROUP Bhopal MP</label> 
            
        </div> 

        
        <div class="position-relative  form-group col-sm-4">
            <label for="Organization_Name" class="col-form-label"  style="font-weight:bold;font-size:17px;">AISECT </label> <br>SCOPE Campus <br>462047<br>account@aisect.org
             
        </div>
        <div class="position-relative  form-group col-sm-4">
            <!-- <label for="Organization_Name" class="col-form-label"  ><span style="font-weight: bold;">Sub</span>: Submission of monthly regular bills for Payment Approval.
              </label>  
              -->
        </div>
        <div class="position-relative  form-group col-sm-4">
            <label for="Organization_Name" class="col-form-label"  ><span style="font-weight: bold;">Sub</span>: Submission of monthly regular bills for Payment Approval.
              </label>  
             
        </div>
        
      
    </form> 
    <h1 class="card-title"  style="font-weight:bold;">
        Description
    </h1>
 
<p-toast></p-toast>






<div class="card" >
 

    <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="['name','country.name','inventoryStatus']"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" class="table">

        
        <ng-template pTemplate="caption">
            
            <!-- <span style="display: inline;"></span> -->
            <!-- <div class="p-d-flex p-ai-center p-jc-between noprint">
                <h5 class="p-m-0">Manage Po Batch</h5>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                </span>
            </div> -->

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
  
        </ng-template>
   
     
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="tabletd"  style="width: 100px">S.No.</th>
                <!-- <th pSortableColumn="InvoiceID" style="width: 50px">BIll Id <p-sortIcon field="InvoiceID"></p-sortIcon></th> -->
                <th pSortableColumn="DivName" style="width: 100px"> Division Name <p-sortIcon field="DivName"></p-sortIcon></th>
                <th pSortableColumn="VendorName" style="width: 100px">Vendor Name <p-sortIcon field="VendorName"></p-sortIcon></th>
                <th pSortableColumn="ItemName" style="width: 100px">Particular <p-sortIcon field="ItemName"></p-sortIcon></th>
                <th pSortableColumn="VendorInvoiceNo" style="width: 100px">Invoice No <p-sortIcon field="VendorInvoiceNo"></p-sortIcon></th>
                <!-- <th pSortableColumn="InvoiceDate" style="width: 100px">Invoice Date <p-sortIcon field="InvoiceDate"></p-sortIcon></th>
                  <th pSortableColumn="IsPOPaymentComplete" style="width: 100px">Invoice Status <p-sortIcon field="IsPOPaymentComplete"></p-sortIcon></th> -->
                <th pSortableColumn="TotalCostWithoutGST" style="width: 100px">Total Cost Without GST <p-sortIcon field="TotalCostWithoutGST"></p-sortIcon></th>
                 <th pSortableColumn="IsPOPaymentComplete" style="width: 100px">TDS In Percent <p-sortIcon field="IsPOPaymentComplete"></p-sortIcon></th>
                 <th pSortableColumn="IsPOPaymentComplete" style="width: 100px">TDS In Amount <p-sortIcon field="IsPOPaymentComplete"></p-sortIcon></th>

                <th pSortableColumn="TotalPayableAmount" style="width: 80px">Payable Amount<p-sortIcon field="TotalPayableAmount"></p-sortIcon></th>
               
            </tr>


            <tr class="noprint">
                <td>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'{i ' ,'contains')" placeholder="Search" />

                </td>
                <td>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DivName' ,'contains')" placeholder="Search DivName" />

                </td>
<td>

    <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorName' ,'contains')" placeholder="Vendor Search" />
</td>
<td>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'ItemName' ,'contains')" placeholder="Search Item" />
</td>
<td>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'VendorInvoiceNo' ,'contains')" placeholder="Search Inv. No." />
</td>
<td>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'TotalCostWithoutGST' ,'contains')" placeholder="Search  Amount" />
</td>
<td>
    
    <input pInputText type="text" (input)="dt.filter($event.target.value,'TDSInPercent' ,'contains')" placeholder="Search TDS In Percent" />
    </td>
    <td>
    
        <input pInputText type="text" (input)="dt.filter($event.target.value,'TDSInAmount' ,'contains')" placeholder="Search TDS In Amount" />
        </td>
        

    <td>
    
        <input pInputText type="text" (input)="dt.filter($event.target.value,'TotalPayableAmount' ,'contains')" placeholder="Search Amount" />
        </td>
        
 
 
 
      


            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product  let-i="rowIndex" >
            <tr>
             <td> <a     [routerLink]="['/View-Invoice',product.InvoiceID]"> {{i + 1}}     </a>  </td>
            <!-- <tr>
                <td> 
                 <a     [routerLink]="['/View-Invoice',product.InvoiceID]"> {{product.InvoiceID}}
                </a>  
             </td>  -->
                 <td>{{product.DivName }}</td>
                <td>{{product.VendorName}}</td>
                <td>{{product.ItemName}}</td>
                <td>{{product.VendorInvoiceNo}}</td>
                <td>{{product.TotalCostWithoutGST}}</td>
                <td>{{product.TDSInPercent}}</td>
                <td>{{product.TDSInAmount}}</td>
                <!-- <td>{{product.TotalPayableAmount}}</td> -->
                <td>{{product.TotalPayableAmount| number : '1.2-2'}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} Invoice.
            </div>
        </ng-template>
    </p-table>
</div>

<!-- <p-dialog [(visible)]="productDialog" [style]="{width: '450px'}" header="Product Details" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image">
        <div class="p-field">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" [(ngModel)]="product.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !product.name">Name is required.</small>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea id="description" pInputTextarea [(ngModel)]="product.description" required rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="inventoryStatus">Inventory Status</label>
            <p-dropdown [(ngModel)]="product.inventoryStatus" inputId="inventoryStatus" [options]="statuses" placeholder="Select">
                <ng-template let-option pTemplate="item">
                    <span [class]="'product-badge status-' + option.value">{{option.label}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="p-field">
            <label class="p-mb-3">Category</label>
            <div class="p-formgrid p-grid">
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category1" name="category" value="Accessories" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category1">Accessories</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category2" name="category" value="Clothing" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category2">Clothing</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category3" name="category" value="Electronics" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category3">Electronics</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category4" name="category" value="Fitness" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category4">Fitness</label>
                </div>
            </div>
        </div>

        <div class="p-formgrid p-grid">
            <div class="p-field p-col">
                <label for="price">Price</label>
                <p-inputNumber id="price" [(ngModel)]="product.price" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
            </div>
            <div class="p-field p-col">
                <label for="quantity">Quantity</label>
                <p-inputNumber id="quantity" [(ngModel)]="product.quantity"></p-inputNumber>
            </div>
        </div>
    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
    </ng-template>
</p-dialog> -->

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<form class="row">
    <div class="position-relative form-group col-sm-6">


        <br>
        
        <br>
        <br>
        <br>
        <br>
        <label for="Contact_Number" class="col-form-label" style="font-weight:bold;font-size:17px;">Approved</label> <br>
        
        <label for="Organization_Name" class="col-form-label"  >Please Pay invoice by {{currentdate}}</label>             
    </div> 
    <div class="position-relative  form-group col-sm-6" style="padding-left: 24%;">
        <label for="Organization_Name" class="col-form-label"  style="font-weight:bold;font-size:17px;">Subtotal: Rs {{Totalamount| number : '1.2-2'}}</label><br>
        
        <label for="Organization_Name" class="col-form-label"  style="font-weight:bold;font-size:12px;">Discount: Rs 0</label><br>
        <label for="Organization_Name" class="col-form-label"  style="font-weight:bold;font-size:12px;">(Tax rate): 0%</label><br>
        
        <label for="Organization_Name" class="col-form-label"  style="font-weight:bold;font-size:12px;">Tax: Rs 0</label><br>
        <label for="" class="col-form-label totalamount"  >Invoice total: Rs {{Totalamount| number : '1.2-2'}}</label>
       
    </div>
 


    <div style="text-align: center;"> <button class="btn btn-info noprint"   (click)="print_page()">Print&nbsp;&nbsp;<i class="fa fa-print" style="font-size:18px"></i></button></div>
  
  
</form> 

</div>




 

