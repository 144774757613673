
<form class="form-horizontal" [formGroup]="form"> 

    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">PAYMENT FREQUENCY REGISTRATION</h1>

    <div class="card-body" style="background-color:#fff;">
        <h1 class="card-title">
        Payment Frequency Registration
        </h1>
        <div class="row">
          
           <div class="position-relative  form-group col-sm-12">
                <label for="InvoiceForName" class="col-form-label">Invoice For Name<span>*</span></label>
                <div class="">
                    <input type="text" name="InvoiceForName" id="InvoiceForName" class="form-control" formControlName="InvoiceForName">
         <app-field-error-display [displayError]="isFieldValid('InvoiceForName')" errorMsg="Please Enter InvoiceForName"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
    
            
         
     
    
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ActiveStatus" class="col-form-label"> Active Status<span>*</span></label>
                <div class=""> 
                    <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus"><option value="1">Active</option><option value="0">Inactive</option></select>
                    <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter ActiveStatus"   style="background-color: #791820;
                                border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display> 
                </div>
            </div> 
     -->
    <!-- this is for active status update don't delte it  -->
            
            
    
            
          
    
            <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    
    