<style>
    td {
  
      color: rebeccapurple;
    }
  </style>
  
  
  <!-- <app-single-selection-examplee></app-single-selection-examplee> -->
  <div class="login-main-bg">
    <div class="text-center">
  
    </div>
  
    <div class="container">
  
  <br>
      <!-- <button type="button" pButton pRipple (click)="showError()" label="Error" class="p-button-danger"></button> -->
      <div class="row" *ngIf="isLoaded">
    
            <div class="col-sm-12">
  
                <p><mat-card class="example-card dashbord-card">
                    <mat-card-header>
        
        
        
                      <div class="container" style="color: rebeccapurple;">
                        <h1><mat-card-title style="text-align: center;font-size: larger;font-family: fantasy;" > 
 <div class="row">
                            <div class="position-relative  form-group col-sm-4">
                                <label for="InvoiceYear" class="col-form-label">Year<span>*</span></label>
                                <div class=""> <select  name="InvoiceYear"    id="InvoiceYear" class="form-control"    (change)="getDetail($event.target.value)" >
                                      <option [ngValue]="null" disabled selected>Select Year</option>
                                        <option *ngFor="let month of yearsdetail" [ngValue]="month">{{month}}</option>
                                     </select>
                                </div>
                            </div>
                            <div class="position-relative  form-group col-sm-4">
                                <label for="InvoiceYear" class="col-form-label">OR<span>*</span></label>
                            
                            </div>

                            <div class="position-relative  form-group col-sm-4">
                                <label for="InvoiceYear" class="col-form-label">Show All<span>*</span></label>
                                <div class=""> 


                                    <button (click)="showall()" class="btn btn-info">Click Here</button>
                                </div>
                            </div> 
                        </div>
                        
                        </mat-card-title>  </h1>
              
                      </div>
        
                    </mat-card-header>
                    <mat-card-content>
        
                    </mat-card-content>
                    <mat-card-actions>
        
                    </mat-card-actions>
                  </mat-card>
                </p>
              </div>
       


        <div class="col-sm-12">
  
            <p><mat-card class="example-card dashbord-card">
                <mat-card-header>
    
    
    
                  <div class="container" style="color: rebeccapurple;">
                    <h1><mat-card-title style="text-align: center;font-size: larger;font-family: fantasy;" > Year Wise Summary</mat-card-title>  </h1>
          
                  </div>
    
                </mat-card-header>
                <mat-card-content>
    
                </mat-card-content>
                <mat-card-actions>
    
                </mat-card-actions>
              </mat-card>
            </p>
          </div>
   
  
        <p-table [value]="dashboardcountyearmonthwiseinvoicesummarylistResult"
          *ngIf="dashboardcountyearmonthwiseinvoicesummarylistResult" styleClass="p-datatable-gridlines">
          <ng-template pTemplate="caption">
            <h2 class="heading">1. Year & Month Wise Invoice Summary
               </h2>
          </ng-template>
  
  
          <ng-template pTemplate="header">
            <tr style="font-family: serif;">
              <th rowspan="2">S.No.</th>
  
              <th rowspan="2">Month</th>
              <th rowspan="2">Year</th>
              <th colspan="2" style="text-align: center;">Registered</th>
              <th colspan="2" style="text-align: center;">Approved</th>
              <th colspan="2" style="text-align: center;">Paid</th>
              <th colspan="2" style="text-align: center;">Pending</th>
  
  
            </tr>
  
            <tr style="font-family: serif;">
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
  
  
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-i="rowIndex">
            <tr style="font-family: ui-serif;">
              <td><span class="p-column-title">Serial Number:</span>{{i+1}}</td>
              <td><span class="p-column-title">Month :</span> {{ product.InvoiceMonth }}</td>
              <td><span class="p-column-title">Year :</span> {{ product.InvoiceFinancialYear }}</td>
              <td><span class="p-column-title"> Total Registerd Invoice :</span> {{ product.Registered_TotInv }} </td>
              <td><span class="p-column-title"> Total Registerd Amount :</span> {{ product.Registered_TotAmt }} </td>
              <td><span class="p-column-title"> Total Approved Invoice :</span> {{ product.Approved_TotInv }}</td>
              <td><span class="p-column-title"> Total Approved Amount :</span> {{ product.Approved_TotAmt }}</td>
              <td><span class="p-column-title"> Total Paid Invoice :</span> {{ product.Paid_TotInv }} </td>
              <td><span class="p-column-title"> Total Paid Amount :</span> {{ product.Paid_TotAmt }}</td>
              <td><span class="p-column-title"> Total Pending Invoice :</span> {{ product.Pending_TotInv }}</td>
              <td><span class="p-column-title"> Total Pending Amount :</span> {{ product.Pending_TotAmt }}</td>
  
            </tr>
          </ng-template>
  
        </p-table>
  
        <br>
        <p-table [value]="dashboardcountyearorganizationwiseinvoicesummarylistResult"
          *ngIf="dashboardcountyearorganizationwiseinvoicesummarylistResult" styleClass="p-datatable-gridlines"
          [ngStyle]="{'margin-top': '2%'}">
          <ng-template pTemplate="caption">
            <h2 class="heading">2. Year & Organization Wise Invoice Summary
             </h2>
          </ng-template>
          <ng-template pTemplate="header">
            <tr style="font-family: serif;">
              <th rowspan="2">S.No.</th>
  
              <th rowspan="2">Organization Name</th>
              <th rowspan="2">Year</th>
              <th colspan="2" style="text-align: center;">Registered</th>
              <th colspan="2" style="text-align: center;">Approved</th>
              <th colspan="2" style="text-align: center;">Paid</th>
              <th colspan="2" style="text-align: center;">Pending</th>
  
  
            </tr>
  
            <tr style="font-family: serif;">
  
  
  
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
  
  
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-i="rowIndex">
     
            <tr style="font-family: ui-serif;">
              <td><span class="p-column-title">Serial Number:</span>{{i+1}}</td>
              <td><span class="p-column-title">Organization Name</span> {{ product.OrgName }}</td>
              
              <td><span class="p-column-title">Year</span> {{ product.InvoiceFinancialYear }}</td>
              
  
              <td><span class="p-column-title"> Total Registerd Invoice :</span> {{ product.Registered_TotInv }} </td>
              <td><span class="p-column-title"> Total Registerd Amount :</span> {{ product.Registered_TotAmt }} </td>
              <td><span class="p-column-title"> Total Approved Invoice :</span> {{ product.Approved_TotInv }}</td>
              <td><span class="p-column-title"> Total Approved Amount :</span> {{ product.Approved_TotAmt }}</td>
              <td><span class="p-column-title"> Total Paid Invoice :</span> {{ product.Paid_TotInv }} </td>
              <td><span class="p-column-title"> Total Paid Amount :</span> {{ product.Paid_TotAmt }}</td>
              <td><span class="p-column-title"> Total Pending Invoice :</span> {{ product.Pending_TotInv }}</td>
              <td><span class="p-column-title"> Total Pending Amount :</span> {{ product.Pending_TotAmt }}</td>
  
            </tr>
          </ng-template>
  
        </p-table>
  
        <!-- <h1 style="text-align: center;"> Year & Vendor Wise Invoice Summary</h1>   -->
        <p-table [value]="dashboardcountyearvendornamewiseinvoicesummarylistResult"
          *ngIf="dashboardcountyearvendornamewiseinvoicesummarylistResult" styleClass="p-datatable-gridlines"
          [ngStyle]="{'margin-top': '2%'}">
          <ng-template pTemplate="caption">
            <h2 class="heading">3. Year & Vendor Wise Invoice Summary </h2>
          </ng-template>
          <ng-template pTemplate="header">
            <tr style="font-family: serif;">
              <th rowspan="2">S.No.</th>
  
              <th rowspan="2">Vendor Name</th>

              
              <th rowspan="2">Year</th>
              <th colspan="2" style="text-align: center;">Registered</th>
              <th colspan="2" style="text-align: center;">Approved</th>
              <th colspan="2" style="text-align: center;">Paid</th>
              <th colspan="2" style="text-align: center;">Pending</th>
  
  
            </tr>
  
            <tr style="font-family: serif;">
  
  
  
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
  
  
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-i="rowIndex">
            <!-- <tr style="font-family: ui-serif;">
        <td> {{i+1}}</td>
        <td>{{ product.VendorName }}</td>
        <td>{{ product.Registered_TotInv }} </td> 
        <td>{{ product.Registered_TotAmt }} </td>         
         <td> {{ product.Approved_TotInv }}</td>
        <td> {{ product.Approved_TotAmt }}</td>
        <td>{{ product.Paid_TotInv }} </td>
        <td> {{ product.Paid_TotAmt }}</td>         
         <td> {{ product.Pending_TotInv }}</td>
        <td> {{ product.Pending_TotAmt }}</td>
    
      </tr> -->
  
  
            <tr style="font-family: ui-serif;">
              <td><span class="p-column-title">Serial Number:</span>{{i+1}}</td>
              <td><span class="p-column-title">Vendor :</span> {{ product.VendorName }}</td>
      
              <td><span class="p-column-title">Year</span> {{ product.InvoiceFinancialYear }}</td>
              <td><span class="p-column-title"> Total Registerd Invoice :</span> {{ product.Registered_TotInv }} </td>
              <td><span class="p-column-title"> Total Registerd Amount :</span> {{ product.Registered_TotAmt }} </td>
              <td><span class="p-column-title"> Total Approved Invoice :</span> {{ product.Approved_TotInv }}</td>
              <td><span class="p-column-title"> Total Approved Amount :</span> {{ product.Approved_TotAmt }}</td>
              <td><span class="p-column-title"> Total Paid Invoice :</span> {{ product.Paid_TotInv }} </td>
              <td><span class="p-column-title"> Total Paid Amount :</span> {{ product.Paid_TotAmt }}</td>
              <td><span class="p-column-title"> Total Pending Invoice :</span> {{ product.Pending_TotInv }}</td>
              <td><span class="p-column-title"> Total Pending Amount :</span> {{ product.Pending_TotAmt }}</td>
  
            </tr>
          </ng-template>
  
        </p-table>
  
  
  
        <br>
  
  
        <!--<h1 style="text-align: center;"> Year & Vendor Wise Invoice Summary</h1>   -->
        <p-table [value]="dashboardcountyearmonthvendornamewiseinvoicesummarylistResult"
          *ngIf="dashboardcountyearmonthvendornamewiseinvoicesummarylistResult" styleClass="p-datatable-gridlines"
          [ngStyle]="{'margin-top': '2%'}">
          <ng-template pTemplate="caption">
            <h2 class="heading">4. Year- Month & Vendor Wise Invoice Summary </h2>
          </ng-template>
          <ng-template pTemplate="header">
            <tr style="font-family: serif;">
              <th rowspan="2">S.No.</th>
              <th rowspan="2">Month Name</th>
              <th rowspan="2">Year</th>
              <th rowspan="2">Vendor Name</th>
              <th colspan="2" style="text-align: center;">Registered</th>
              <th colspan="2" style="text-align: center;">Approved</th>
              <th colspan="2" style="text-align: center;">Paid</th>
              <th colspan="2" style="text-align: center;">Pending</th>
  
  
            </tr>
  
            <tr style="font-family: serif;">
  
  
  
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
  
  
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-i="rowIndex">
   
            <tr style="font-family: ui-serif;">
              <td><span class="p-column-title">Serial Number:</span>{{i+1}}</td>
              <td><span class="p-column-title">Month :</span> {{ product.InvoiceMonth }}</td>
              <td><span class="p-column-title">Year</span> {{ product.InvoiceFinancialYear }}</td>
              <td><span class="p-column-title">Vendor :</span> {{ product.VendorName }}</td>
  
              <td><span class="p-column-title"> Total Registerd Invoice :</span> {{ product.Registered_TotInv }} </td>
              <td><span class="p-column-title"> Total Registerd Amount :</span> {{ product.Registered_TotAmt }} </td>
              <td><span class="p-column-title"> Total Approved Invoice :</span> {{ product.Approved_TotInv }}</td>
              <td><span class="p-column-title"> Total Approved Amount :</span> {{ product.Approved_TotAmt }}</td>
              <td><span class="p-column-title"> Total Paid Invoice :</span> {{ product.Paid_TotInv }} </td>
              <td><span class="p-column-title"> Total Paid Amount :</span> {{ product.Paid_TotAmt }}</td>
              <td><span class="p-column-title"> Total Pending Invoice :</span> {{ product.Pending_TotInv }}</td>
              <td><span class="p-column-title"> Total Pending Amount :</span> {{ product.Pending_TotAmt }}</td>
  
            </tr>
          </ng-template>
  
        </p-table>
  
  
  
        <br>
          <p-table [value]="dashboardcountyearservicewiseinvoicesummarylistResult"
          *ngIf="dashboardcountyearservicewiseinvoicesummarylistResult" styleClass="p-datatable-gridlines"
          [ngStyle]="{'margin-top': '2%'}">
          <ng-template pTemplate="caption">
            <h2 class="heading">5. Year & Service Wise Invoice Summary  </h2>
          </ng-template>
          <ng-template pTemplate="header">
            <tr style="font-family: serif;">
              <th rowspan="2">S.No.</th>
  
              <th rowspan="2">Services</th>
              <th rowspan="2">Year</th>

                   
              <th colspan="2" style="text-align: center;">Registered</th>
              <th colspan="2" style="text-align: center;">Approved</th>
              <th colspan="2" style="text-align: center;">Paid</th>
              <th colspan="2" style="text-align: center;">Pending</th>
  
  
            </tr>
  
            <tr style="font-family: serif;">
  
  
  
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
              <th>Total Invoice </th>
  
              <th>Total Amount</th>
  
  
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-i="rowIndex">
             <tr style="font-family: ui-serif;">
              <td><span class="p-column-title">Serial Number:</span>{{i+1}}</td>
              <td><span class="p-column-title">Item Category :</span> {{ product.ItemCategoryName }}</td>
  
              <td><span class="p-column-title">Year</span> {{ product.InvoiceFinancialYear }}</td>
              <td><span class="p-column-title"> Total Registerd Invoice :</span> {{ product.Registered_TotInv }} </td>
              <td><span class="p-column-title"> Total Registerd Amount :</span> {{ product.Registered_TotAmt }} </td>
              <td><span class="p-column-title"> Total Approved Invoice :</span> {{ product.Approved_TotInv }}</td>
              <td><span class="p-column-title"> Total Approved Amount :</span> {{ product.Approved_TotAmt }}</td>
              <td><span class="p-column-title"> Total Paid Invoice :</span> {{ product.Paid_TotInv }} </td>
              <td><span class="p-column-title"> Total Paid Amount :</span> {{ product.Paid_TotAmt }}</td>
              <td><span class="p-column-title"> Total Pending Invoice :</span> {{ product.Pending_TotInv }}</td>
              <td><span class="p-column-title"> Total Pending Amount :</span> {{ product.Pending_TotAmt }}</td>
  
            </tr>
          </ng-template>
  
        </p-table>
  
  
  
  
  
      </div>
  
  
  
    </div>
  
  </div>
 