<p-toast></p-toast>






<div class="card" style=" overflow-x: scroll;">

    <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="[
    'SaleTypeName' ,
    'SaleTypeDeduction',
    'SaleTypeOrder',
    'CreatedDate'
    ]" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]">


        <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 class="headingclass">SALE TYPE LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />
                    &nbsp; &nbsp; <a [routerLink]="['/Sale-Type-Registration']" class="btn btn-success">Add <i
                            class="fa fa-plus" aria-hidden="true"></i></a>
                </span>
            </div>
        </ng-template>


        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="rating" class="actionclass"> Action </th>

                <th pSortableColumn="price" style="width: 150px">Active Status<p-sortIcon field="price"></p-sortIcon>
                </th>
                <th pSortableColumn="SaleTypeName" style="width: 150px">Sale Type Name<p-sortIcon
                        field="SaleTypeName"></p-sortIcon></th>
                <th pSortableColumn="SaleTypeDeduction" style="width: 150px">Sale Type Deduction <p-sortIcon
                        field="SaleTypeDeduction"></p-sortIcon></th>
                <th pSortableColumn="SaleTypeOrder" style="width: 150px">Sale Type Order<p-sortIcon
                        field="SaleTypeOrder"></p-sortIcon></th>
                <!-- <th pSortableColumn="ActiveStatus" style="width: 150px">Active Status<p-sortIcon field="ActiveStatus"></p-sortIcon></th> -->
                <th pSortableColumn="CreatedDate" style="width: 150px"> Created Date<p-sortIcon
                        field="CreatedDate"></p-sortIcon></th>

            </tr>


            <tr class="padding-head">
                <th>

                </th>


                <th>
                    <!-- <input pInputText type="text" (input)="dt.filter($event.target.value,'ActiveStatus' ,'contains')" placeholder="Active Status" /> -->
                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')"
                        styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'SaleTypeName' ,'contains')"
                        placeholder="Search ItemCategory Name " />

                </th>

                <th>
                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'SaleTypeDeduction' ,'contains')" placeholder="Search" />
                </th>

                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'SaleTypeOrder' ,'contains')"
                        placeholder="Search" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')"
                        placeholder="Search" />
                </th>




            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">


                <td>
                    <span class="p-column-title">Action:</span>
                    <span style="display: flex;align-items: center;justify-content: center;">
                        <a [routerLink]="['/Sale-Type-Edit',product.SaleTypeID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                style='color: #2459a6;' pTooltip="Edit Sale-Type-List"></i></a>
                        <span pTooltip="View Product Sale Type"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                            border: 2px solid;
                            border-radius: 5px;' (click)="viewProduct(product.SaleTypeID)"></i>
                        </span>
                    </span>
                </td>
                <td>


                    <span class="p-column-title">Status:</span>
                    <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
                    <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>

                </td>
                <td>


                    <span class="p-column-title">Sale Type Name:</span>
                    {{product.SaleTypeName}}
                </td>
                <td>

                    <span class="p-column-title">SaleType Name Name:</span>
                    {{product.SaleTypeDeduction}}
                </td>
                <td>

                    <span class="p-column-title">Sale Type Order:</span>
                    {{product.SaleTypeOrder}}
                </td>
                <!-- <td>{{product.ActiveStatus}}</td>  -->
                <td>

                    <span class="p-column-title">Created Date:</span>
                    {{product.CreatedDate}}
                </td>


            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>


<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


<p-dialog [(visible)]="productDialog" [style]="{width: '600px'}" header="Product Category Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <br>
        <div class="container">
            <table class="table table-striped">

                <tbody>
                    <tr> 
                        <td>
                            <label for="Organization_Name" class="col-form-label">Sale Type ID </label>
                        </td>
                        <td>
                            <label for="OrgID" class="col-form-label"> {{productsbyid?.SaleTypeID}} </label>
                        </td>
                     </tr>
                    <tr>
                        <td>

                            <label for="Organization_Name" class="col-form-label">SaleType Name:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.SaleTypeName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="PANCard" class="col-form-label">Sale Type Deduction:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.SaleTypeDeduction}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="GSTN" class="col-form-label">Sale Type Order:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.SaleTypeOrder}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td><label for="Emailid" class="col-form-label">UpdatedOn: </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.UpdatedDate}}
                            </label></td>

                    </tr>
                    <tr>
                        <td> <label for="Address" class="col-form-label">CreatedBy: </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.CreatedBy}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="City" class="col-form-label">CreatedOn: </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid?.CreatedOn}} </label>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>



    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">


        <span style="float: left;padding-top: 2%;" *ngIf="productsbyid">

            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">

                    Status
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <!-- {{productsbyid?.ActiveStatus}} -->
                    <input type="radio" class="form-check-input" checked="!checked" name="radio"
                        [(ngModel)]="productsbyid.ActiveStatus" (change)="handleChange($event,productsbyid?.SaleTypeID)"
                        [value]="1">active
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                        [(ngModel)]="productsbyid.ActiveStatus" (change)="handleChange($event,productsbyid?.SaleTypeID)"
                        [value]="0">Deactive
                </label>
            </div>

        </span>
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"
            style="float: right;"></button>


        <!-- <button style="color: white;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                (click)="saveProduct()"></button>  this is unused -->

        <!-- <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button> -->
        <!-- <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button> -->
    </ng-template>
</p-dialog>