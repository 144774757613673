import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-saletyperegistration',
  templateUrl: './saletyperegistration.component.html',
  styleUrls: ['./saletyperegistration.component.css']
})
export class SaletyperegistrationComponent implements OnInit {
 
  statekey:any;
  stateList:Billdesk[];
  cityList:Billdesk[];
  type: string;
  saleTypeId:any;
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef,private _Activatedroute:ActivatedRoute) {
 
//  this.billdeskService.getOrganization().then(res => this.organizaionlist = res);
    this.billdeskService.getState().then(res => this.stateList = res);
 
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  registrationDetail:any;
  
  ngOnInit() {
    this.saleTypeId=this._Activatedroute.snapshot.paramMap.get("id");
    if(this.saleTypeId){ 
      const url="/saletype/saletypedetail/";
      this.billdeskService.getDetail(this.saleTypeId,url).then((res:any) => 
      { 
         if(res.saletypedetailResult.Message=='Sucess'){ 
          this.registrationDetail = res.saletypedetailResult.dataobject[0];
          console.log('sgsg',this.registrationDetail) 
          this.form.setValue({SaleTypeName: this.registrationDetail.SaleTypeName,SaleTypeDeduction: this.registrationDetail.SaleTypeDeduction, SaleTypeOrder: this.registrationDetail.SaleTypeOrder, ActiveStatus: this.registrationDetail.ActiveStatus})
          // let detailObj = res && res[0] ? res[0] : {};
  
          // console.log('sgsgsgsggsgsgsg',detailObj)
          // detailObj['ActiveStatus'] = this.billdeskService.isActive(detailObj.ActiveStatus || '');
          // this.registrationDetail = {...detailObj}; 
        }else{
          //  console.log('Please Try Again Later');
        }
      }
     );
    }else{
      // alert('not get')
    }
    this.form = this.fb.group({ 
      
      SaleTypeName: [null, Validators.required],
      SaleTypeDeduction:[null, Validators.required],
      SaleTypeOrder: [null, Validators.required],
      ActiveStatus: [null, Validators.required] 
    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {
  this.billdeskService.getCity(20).then(res => this.cityList = res);
  }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => { 
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
    "flag":"MSTItemCatagory_INSERT",
    "SaleTypeID":"0", 
    "CreatedBy":"1",  
  };
  var obj2 = this.form.value;
  var form_data = Object.assign(obj1, obj2);
  
  const datarequest={
  "CheckSum": "",
    "lead_id": "",
    "ActionFor": "Insert",            
    "dataobject":[form_data]
  };
 

    if (this.form.valid) { 
      let registerurl= "/saletype/saletyperegistration";
    this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
      
      console.log('res',res)
      if(res.saletyperegistrationResult.Message=='Sucess'){
      
      this.route.navigate(['/Sale-Type-List'])
      localStorage.setItem('insertdata', 'Success');
    }else{
      //  console.log('Please Try Again Later');
    }
 });
  } else {
    this.validateAllFormFields(this.form);
  }
 }

 onUpdate(){ 
            if(this.saleTypeId){
          var obj1 = 
          {
          "flag":"MSTItemCatagory_INSERT",
          "SaleTypeID":this.saleTypeId, 
          "CreatedBy":"1",  
        };
        var obj2 = this.form.value;
        var form_data = Object.assign(obj1, obj2);
        
        const datarequest={
        "CheckSum": "",
          "lead_id": "",
          "ActionFor": "Insert",            
          "dataobject":[form_data]
        };

            let updateurl= "/saletype/saletyperegistrationupdate";
          
            if (this.form.valid) {  
            this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{
              console.log('res',res)
              if(res.saletyperegistrationResult.Message=='Sucess'){
              
              this.route.navigate(['/Sale-Type-List'])
              localStorage.setItem('insertdata', 'Success');
            }else{
              //  console.log('Please Try Again Later');
            }
            });
            } else {
              this.validateAllFormFields(this.form);
            }


            }else{ return ;}
        }

 }
 