<h1  style=" 
color: white !important;
padding: 10px;"  [style.background-color]="data.Backgroundcolor"
 >Message<button style="   

 float: right;
    width: 2rem;
    height: 2rem;
    color: #fff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;" (click)="onNoClick();">X</button></h1>
<div mat-dialog-content style="overflow-x: hidden;">
 
 

  <div class="modal-dialog modal-confirm" style=" 
    border-bottom: none;
    position: relative;
    text-align: center;
    margin: -20px -20px 0;
    border-radius: 5px 5px 0 0;
    padding: 35px;
">
      <div class="modal-content">
 
            <div class="modal-header justify-content-center"  [style.background-color]="data.Backgroundcolor" >
         
           
            
            <div class="icon-box">
                  <i class="material-icons" style=" 
                  width: 95px;
                  height: 86px;
                  display: inline-block;
                  border-radius: 50%;
                  z-index: 9;
                  border: 5px solid #fff;
                  padding: 9px;
                  text-align: center;
                  background: '{{data.Backgroundcolor}}';
                  font-size: 48px; color: #ffffff !important;">&#xE876;</i>
              </div>
              
          </div>
          <div class="modal-body text-center">
              <h4>Success!</h4>	
              <p>{{data.Service}} {{data.Detail}}.</p>

              
 
                   </div>
      </div>
  </div>
      
 
</div>