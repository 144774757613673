import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  localValue:any;
  isSidebarOpen=false;
  panelOpenState = false;
  UserType:any;
    session=false;
    RoleTypeName:any;
    role_account:any;
    UserTypeID:any;
    role_hod:any;
    regmenu:any;
    showmenu=false;
    showmenuhod=true;
    data:any;
    getsupercat:any;

  constructor(public route:Router,private billdeskService:BilldeskService) { 




    
    this.UserType=localStorage.getItem('UserTypeID');  

   }

  ngOnInit(): void {  
    if(this.UserType > 0){
    this.getsupercat = this.billdeskService.getHeaderMenuAuth().then(res=>this.data = res);
  }

    
    this.UserTypeID=localStorage.getItem('UserTypeID'); 
 
 
if(this.UserTypeID=='1'){
  this.showmenuhod=false;
  }
if(this.UserTypeID!='5'){
  this.showmenu=true;
  }
if(this.UserTypeID=='5'|| this.UserTypeID=='3'|| this.UserTypeID=='2' ){
  this.regmenu=true;
  }
 
    this.localValue=  localStorage.getItem('Login_local_session');
   if(this.localValue==null){
    const urldata=window.location.pathname; 
    var array = urldata.split('/');
 ;
    if(array[1]=='InvoiceDetail'){
       this.session=true; 
    }else{
  if(localStorage.getItem("UserEmail") === null) {
     this.session=true;
     this.route.navigate(['/']);
   }
  if(localStorage.getItem("UserMobile") === null) {
     this.session=true;
     this.route.navigate(['/']);
  }
  if (localStorage.getItem("LoginID") === null) {
  this.session=true;
  this.route.navigate(['/']);
  }
 if(localStorage.getItem("LoginID") === null) {
    this.session=true;
    this.route.navigate(['/']);
 }
 }} 
 }
  

  openSidebar(){
    this.isSidebarOpen=true;
  }

  closeSidebar(){
    this.isSidebarOpen=false;
  }
 
  toggleoff(){
    this.openSidebar();
  }



  onLogout() {  
    localStorage.removeItem('Login_local_session');  
    localStorage.removeItem('UserEmail');  
    localStorage.removeItem('UserMobile');  
    localStorage.removeItem('LoginID');  
    localStorage.removeItem('VendorId');  
    localStorage.clear();
    localStorage.removeItem('DepartmentID');  
    window.location.reload();
    this.route.navigate(['/']);
  

}
 

}
