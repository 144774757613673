// import { Directive } from '@angular/core';

// @Directive({
//   selector: '[appAutofocus]'
// })
// export class AutofocusDirective {

//   constructor() { }

// }





import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[datoAutoFocus]'
})
export class AutofocusDirective {

  @Input()
  public set datoAutoFocus(value) {
    if (!!value) {
      this.host.nativeElement.focus();
    }
  }

  public constructor(
    private host: ElementRef,
  ) {
  }


}