<br>&nbsp;&nbsp; <a     [routerLink]="['/Sheet-A-Month-Wise-PO-Summary']"  class="noprint"><button class="btn btn-info btn-lg">Back</button>
</a>    

<div class="card"   style="background-color:#fff; margin:50px;" >
 
    <form    [formGroup]="paymentform" (ngSubmit)="getSaleReport()" class="noprint" style="text-align:center;padding:10px;"   >

     <div class="row"   style="padding:10px;">
        <div class="position-relative  form-group col-sm-3 dateclass">
          <label>From</label>
          <input type="date"     class="form-control"   formControlName="InvoiceYear"> 
        </div> 
    <div class="position-relative  form-group col-sm-3 dateclass"> 
            <label>To</label>
            <input type="date"     class="form-control"   formControlName="InvoiceMonth"> 
    </div>




    <div class="position-relative  form-group col-sm-2">     
       <!-- <label for="InvoiceYear" class="col-form-label">&nbsp;</label> -->

        <div class="">
          
    <button type="submit" class="btn btn-info btn-sm"  pButton pRipple     [style]="{width:'35%',padding:'2%',marginTop:'30px',background:'#f1592a',border:'1px solid #f1592a',borderRadius:'10px'}" >Search</button> 
        </div>
          
    </div>

    <div class="position-relative  form-group col-sm-4">
    </div>
</div>
</form>


  </div>  
  
  <div  id="content" #content> 

    
<div class="card-body" style="background-color:#fff; margin:50px;">

  <img src="../../assets/images/3.png"  class="logocss" />
  <h1 class="card-title"  style="font-weight:bold;text-align: center;">
    Sales Report 
  </h1>
<div>
    
  
<h3 class="card-title dateclass"   style="font-weight:bold;text-align: center;"  *ngIf="showmonth" >{{YearShow| date: 'dd/MM/yyyy'}} To  {{MonthShow| date: 'dd/MM/yyyy'}} </h3>   
<div class="card"  *ngIf="products">  
  <p-table  #dt
  [columns]="cols"
  [value]="products"
  selectionMode="multiple"
  [(selection)]="selectedProducts">

  <ng-template pTemplate="caption">
    <div class="flex">
        <button type="button" pButton pRipple icon="pi pi-file" (click)="dt.exportCSV()" class="mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
        <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button>
    </div>
</ng-template>
<!-- <ng-template pTemplate="header">
              <tr>
                <th class="tabletd" style="width:50px">SN</th>
                <th class="tabletd" style="width:50px">Stock ID </th>
                <th class="tabletd" style="width: 150px">Product Name </th>
                <th class="tabletd" style="width: 150px">Category</th>
                <th class="tabletd" style="width: 150px">Brand</th>
                <th class="tabletd" style="width: 150px">Sale Price</th>
                <th class="tabletd" style="width: 200px">Sale Online</th>
                <th class="tabletd" style="width: 200px">Sale Offline</th>
                <th class="tabletd" style="width: 100px">Total Sale</th>
                <th class="tabletd" style="width: 200px">Total Amount</th>
              </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product  let-i="rowIndex" >
        <tr>
          <td>{{i + 1}}</td>
          <td>{{product.StockID}}</td>
          <td>{{product.ProductName}}</td>
          <td> {{product.ItemCategoryName}}</td>
          <td>{{product.BrandName }}</td>
          <td>{{product.SalePrice}}</td>
          <td>{{product.SaleOnline}}</td>
          <td>{{product.SaleOffline}}</td>
          <td>{{product.TotalSale}}</td>
          <td>{{product.TotalAmount}}</td> 
        </tr>
       
        
    </ng-template>
  <ng-template pTemplate="footer" >
    <tr>
      <td colspan="5">Total</td> 
      <td></td> 
      <td></td> 
      <td></td> 
      <td></td> 
      <td>{{sumtotalnetpay}}</td> 
    </tr>
</ng-template> -->

<ng-template pTemplate="caption" style.visibility]="isHidden? 'hidden': 'visible'">
  <div class="flex">
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-file"
      (click)="dt.exportCSV()"
      class="mr-2"
      pTooltip="CSV"
      tooltipPosition="bottom"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-file-excel"
      (click)="exportExcel()"
      class="p-button-success mr-2"
      pTooltip="XLS"
      tooltipPosition="bottom"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-filter"
      (click)="dt.exportCSV({ selectionOnly: true })"
      class="p-button-info ml-auto"
      pTooltip="Selection Only"
      tooltipPosition="bottom"
    ></button>
  </div>  
</ng-template>
<ng-template pTemplate="header" let-columns>
  <tr>
    <th *ngFor="let col of columns">
      {{ col.header }}
    </th>
  </tr>
</ng-template>
<ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
  <tr [pSelectableRow]="rowData"> 
    <td *ngFor="let col of columns">
      {{ rowData[col.field] }}
    </td>
  </tr>
</ng-template>
<ng-template pTemplate="footer" >
  <tr>
    <td colspan="5">Total</td> 
    <td></td> 
    <td></td> 
    <td></td> 
    <td>{{sumtotalnetpay}}</td> 
  </tr>
</ng-template> 
  </p-table>
</div>


 
</div> 



  
</div>

<!-- <div *ngFor="let item of groupArr">
  <ng-container>
<tr >
<td colspan="2"><b>{{item.MonthYear}}</b></td>
</tr>
<tr *ngFor="let value of item.groupItem">
<td>{{value.InvoiceID}}</td>
<td>{{value.name}}</td>
</tr>
</ng-container></div> -->
<div style="text-align: center;"> <button class="btn btn-info noprint" id="printbtn"  (click)="print_page()">Print&nbsp;&nbsp;<i class="fa fa-print" style="font-size:32px"></i></button></div>
  