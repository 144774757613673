import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


import { ConnectionService, ConnectionServiceModule } from 'ng-connection-service';
@Component({
  selector: 'app-invoice-registration',
  templateUrl: './invoice-registration.component.html',
  styleUrls: ['./invoice-registration.component.css'],
  providers: [DatePipe]
})
export class InvoiceRegistrationComponent implements OnInit {  //Create FormGroup 

  UserID: any;
  InvoiceDate: any;
  expDate: any;

  categorylist: any;
  dialogdata: string;
  ProductCategoryDialog: boolean;
  ProductDialog: boolean;
  InvoicePurposeDialog: boolean;
  organizaionlist: Billdesk[];
  visibleloader: boolean;
  divisionlist: Billdesk[];
  departmentlist: Billdesk[];
  vendorlist: Billdesk[];
  invforlist: Billdesk[];
  headlist: Billdesk[];
  itemcategorylist: Billdesk[];
  itemcategorylistforProduct: Billdesk[];
  gstdetail: any;
  prioritylist: Billdesk[];
  itemlist: Billdesk[];
  statekey: any;
  stateList: Billdesk[];
  A: any;
  B: any;
  C: any;
  // productDialog: boolean;
  cityList: Billdesk[];
  months: any;
  years: any;
  yearsdetail: any;
  type: string;
  value_c: any;

  selectedOrgId: string;

  UserTypeID: any;
  VendorDetailId: any;
  selectedOrgName: string = 'Please Select Organization';

  selectedDivName: string = 'Please Select Division';
  selectedDiv: string;
  selectedItemCategoryID: string;
  selectedItemName = 'Please Select Item';
  selectedItemId: string;
  selectedItemCategoryName = 'Please Select Category';
  imageUrl: string = "/assets/images/noimage.png";
  fileToUpload: File = null;
  imageUrl1: string = "/assets/images/noimage.png";

  VendorDetailName: any
  RecentVendor: any;
  selectedPriorityId: string;
  selectedPriorityName: string = 'Please Select Priority';

  selectedDeptID: string;
  selectedDeptName: string = 'Please Select Department';
  selectedVendorId: string;
  selectedVendorName: string = 'Please Select Vendor';
  selectedInvoiceForID: string;
  selectedInvoiceForName: string = 'Please Select Inv.For';
  selectedInvoiceHeadID: string;
  selectedInvoiceHeadName: string = 'Please Select Head';
  vendordetail: any;
  RoleTypeName: any;
  UserEmail: any;
  VendorShow: boolean;
  invalidDates: Array<Date>
  VendorId: any;
  value: Date;
  date5: Date;

  date3: Date;
  ServiceUrl = environment.baseUrl;

  // TotalGSTAmount:any;
  constructor(private fb: FormBuilder, private http: HttpClient, private route: Router, public dialog: MatDialog, private billdeskService: BilldeskService, private elementRef: ElementRef, private datePipe: DatePipe) {
    // this.visibleloader=true;


    this.UserID = localStorage.getItem('UserID');


    this.billdeskService.getOrganizationsLisInvoicetDDL().then(res => this.organizaionlist = res);


    this.billdeskService.getVendorDDLInvoiceList().then(res => this.vendorlist = res);
    this.billdeskService.getInvoiceFor().then(res => this.invforlist = res);
    this.billdeskService.getItemCategoryDDLInvoicelist().then(res => this.itemcategorylist = res);
    this.billdeskService.getHeaditemlist().then(res => this.headlist = res);
    this.billdeskService.getPriority().then(res => this.prioritylist = res);
    this.billdeskService.getState().then(res => this.stateList = res);


    this.RoleTypeName = localStorage.getItem('RoleTypeName');

    if (this.RoleTypeName == 'vendor') {

      // under consttuction 

    }
  }

  form: FormGroup;
  insertdata: any;
  productform: FormGroup;
  categoryform: FormGroup;
  InvoicePurposeform: FormGroup;
  ItemCategoryID: any;
  Key: any;

  ngOnInit() {



    this.categoryform = this.fb.group({
      ItemCategoryName: [null, Validators.required],
      CategoryDisplayName: [null, Validators.required],
      Description: [null, Validators.required],
      ActiveStatus: [null, Validators.required]
    });




    this.productform = this.fb.group({
      ItemName: [null, Validators.required],
      ItemCategoryID: [null, Validators.required],
      ActiveStatus: [null, Validators.required],
    });
    // this.VendorShow=true;


    this.InvoicePurposeform = this.fb.group({

      InvoiceHeadName: [null, Validators.required],

      InvoiceHeadCategoryID: [null, Validators.required]
      // ,

      // IsActive: [null, Validators.required] 
    });

    // run after vendor login


    this.UserTypeID = localStorage.getItem('UserTypeID');
    console.log("this.UserTypeID");
    console.log(this.UserTypeID);
    if (this.UserTypeID == 4) {

      console.log("Vendor");
      this.http.get(this.ServiceUrl + '/vendor/vendordetail/' + this.UserID).subscribe(
        (response: any) => {
          this.VendorDetailId = response.vendordetailResult.dataobject[0].VendorID;

          if (this.VendorDetailId > 0) {
            this.VendorShow = true;


            console.log("this.VendorDetailId");
            console.log(this.VendorDetailId);
            localStorage.setItem('VendorId', this.VendorDetailId);
            localStorage.setItem('VendorName', this.VendorDetailName);

            this.RecentVendor = localStorage.getItem('VendorId');

            console.log("this.RecentVendorinvendor");
            console.log(this.RecentVendor);
          }
          else {
            this.VendorShow = false;
          }
          this.VendorDetailName = response.vendordetailResult.dataobject[0].VendorName;

        });
    }


    // run after vendor login .


    this.RecentVendor = localStorage.getItem('VendorId');

    console.log("his.RecentVendor");
    console.log(this.RecentVendor);
    this.UserID = localStorage.getItem('UserID');

    this.gstdetail = ["0", "5", "12", "18", "28"];

    this.form = this.fb.group({

      VendorInvoiceNo: [null, Validators.required],
      DiscountAmount: [null, Validators.required],
      InvoiceDate: [null, Validators.required],
      DivID: [null, Validators.required],
      OrgID: [null, Validators.required],
      DeptID: [null, Validators.required],
      // UserID:[null, Validators.required],
      VendorID: [null, Validators.required],
      InvoiceName: [],
      InvoiceForID: [null, Validators.required],
      // PaymentPriorityID: [null, Validators.required],
      ItemCategoryID: [null, Validators.required],
      ItemID: [null, Validators.required],
      HeadID: [null, Validators.required],



      // IGSTPer: [null, Validators.required],0 not calculate
      IGSTPer: [],
      // InvoiceDesc: [null, Validators.required],
      // InvoiceYear: [null, Validators.required],
      // InvoiceMonth: [null, Validators.required],

      CGSTPer: [null, Validators.required],
      SGSTPer: [null, Validators.required],
      SGSTAmount: [null, Validators.required],
      CGSTAmount: [null, Validators.required],





      ValidityExpireOn: [],
      // DayRemaining: [null, Validators.required],
      WarrantyImp: [],
      Priority: [null, Validators.required],


      TotalCostWithoutGST: [null, Validators.required],
      IGSTAmount: [null, Validators.required],
      TDSInAmountToGovt: [null, Validators.required],
      TDSInPercent: [null, Validators.required],
      TDSInAmount: [null, Validators.required],
      TotalAmountAfterTDS: [null, Validators.required],
      DiscountPercent: [null, Validators.required],



      TotalAmountAfterDiscount: [null, Validators.required],
      TotalInvoiceValue: [null, Validators.required],
      // LateFeeIfApplicable: [null, Validators.required],
      TotalPayableAmount: [null, Validators.required],
      // PreferdablePaymentMode: [null, Validators.required],
      // InvoiceAttechmentURL: [null, Validators.required],
      // ApprovalLetterAttachmentURL: [null, Validators.required],


      InvoiceAttechmentURL: [],
      ApprovalLetterAttachmentURL: [],
      // TDSCertificateAttachmentURL: [null, Validators.required],
      DepttContactPerson: [null, Validators.required],
      // AccountNo: [null, Validators.required],
      // Department: [null, Validators.required],
      // IRNNo: [null, Validators.required],
      // Scheme: [null, Validators.required]



    });



    // window. onafterprint = window. close;

    window.onafterprint = function () {

      location.reload();
      window.close();
    }

  }





  openDialogInvoicePurpose(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Invoice Purpose", Detail: "Registered Successfully", Backgroundcolor: "#47c9a2" }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogdata = result; 
      console.log(this.dialogdata);
    });
  }

  openDialogCategory(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Category", Detail: "Registered Successfully", Backgroundcolor: "#47c9a2" }
    });

    dialogRef.afterClosed().subscribe(result => {



      this.dialogdata = result;

      console.log("this.dialogdata");

      console.log(this.dialogdata);
    });
  }
  openDialogProduct(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Product", Detail: "Registered Successfully", Backgroundcolor: "#47c9a2" }
    });

    dialogRef.afterClosed().subscribe(result => {



      this.dialogdata = result;

      console.log("this.dialogdata");

      console.log(this.dialogdata);
    });
  }


  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }


  isFieldValidCategory(field: string) {
    return !this.categoryform.get(field).valid && this.categoryform.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }



  changeOrganization(e) {
    this.ItemCategoryID.setValue(e.target.value, {
      onlySelf: true
    })
  }



  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);

    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  handleFileInput1(file: FileList) {
    this.fileToUpload = file.item(0);

    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl1 = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }





  changeState(e) {
    this.billdeskService.getCity(20).then(res => this.cityList = res);
  }




  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {

      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  reset() {
    this.form.reset();
  }

  getYear() {


    //   this.years = new Date().getFullYear();
    // var range = [];
    // range.push(this.years);
    // for (var i = -6; i < 7; i++) {
    //     range.push(this.years + i);
    // }
    // this.yearsdetail = range;

    this.yearsdetail = ["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"];

  }

  getMonth() {
    this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  }


  valueChangedD(G, L, M) {
    const value_a = parseFloat(G);

    const value_b = parseFloat(L);

    const value_d = parseFloat(M);

    const value_c = value_a + value_b + value_d;

    this.form.controls['TotalPayableAmount'].setValue(value_c);
  }


  valueChangedF(A, E) {
    const value_a = parseFloat(A);
    const value_b = parseFloat(E);
    const value_c = value_a * value_b;
    const tds_amount = value_c / 100;
    this.form.controls['TDSInAmount'].setValue(tds_amount);
    this.form.controls['TDSInAmountToGovt'].setValue(tds_amount);


  }



  valueChangeddiscountreceive(N, O, P) {


    const value_a = parseFloat(N);


    const value_b = parseFloat(O);


    const value_d = parseFloat(P);

    const value_c = value_a + value_b + value_d;

    this.form.controls['TotalInvoiceValue'].setValue(value_c);
    // this.form.controls['TotalPayableAmount'].setValue(value_c); 


  }

  valueChangedI(A, H) {
    const value_a = parseFloat(A);

    const value_b = parseFloat(H);
    const value_c = value_a * value_b;

    const tds_amount = value_c / 100;
    this.form.controls['DiscountAmount'].setValue(tds_amount);


    this.resetfield1();
    // this.form.controls['DiscountAmount'].setValue(tds_amount); 

  }


  resetfield() {
    const reset_amount = '';
    this.form.controls['DiscountPercent'].setValue(reset_amount);
    this.form.controls['DiscountAmount'].setValue(reset_amount);

    this.form.controls['TotalAmountAfterDiscount'].setValue(reset_amount);

    this.form.controls['TDSInPercent'].setValue(reset_amount);

    this.form.controls['TDSInAmount'].setValue(reset_amount);

    this.form.controls['TotalAmountAfterTDS'].setValue(reset_amount);

    this.form.controls['IGSTPer'].setValue(reset_amount);

    this.form.controls['CGSTPer'].setValue(reset_amount);

    this.form.controls['SGSTPer'].setValue(reset_amount);
    this.form.controls['IGSTAmount'].setValue(reset_amount);
    this.form.controls['CGSTAmount'].setValue(reset_amount);
    this.form.controls['SGSTAmount'].setValue(reset_amount);
    this.form.controls['TotalPayableAmount'].setValue(reset_amount);
    this.form.controls['TDSInAmountToGovt'].setValue(reset_amount);
    this.form.controls['TotalInvoiceValue'].setValue(reset_amount);
    // this.form.controls['DiscountAmount'].setValue(tds_amount); 

  }


  resetfield1() {
    const reset_amount = '';
    //  this.form.controls['DiscountPercent'].setValue(reset_amount); 
    //  this.form.controls['DiscountAmount'].setValue(reset_amount); 

    //  this.form.controls['TotalAmountAfterDiscount'].setValue(reset_amount); 

    this.form.controls['TDSInPercent'].setValue(reset_amount);

    this.form.controls['TDSInAmount'].setValue(reset_amount);

    this.form.controls['TotalAmountAfterTDS'].setValue(reset_amount);

    this.form.controls['IGSTPer'].setValue(reset_amount);

    this.form.controls['CGSTPer'].setValue(reset_amount);

    this.form.controls['SGSTPer'].setValue(reset_amount);
    this.form.controls['IGSTAmount'].setValue(reset_amount);
    this.form.controls['CGSTAmount'].setValue(reset_amount);
    this.form.controls['SGSTAmount'].setValue(reset_amount);
    this.form.controls['TotalPayableAmount'].setValue(reset_amount);
    this.form.controls['TDSInAmountToGovt'].setValue(reset_amount);
    this.form.controls['TotalInvoiceValue'].setValue(reset_amount);
  }


  resetfield2() {
    const reset_amount = '';
    this.form.controls['DiscountPercent'].setValue(reset_amount);
    this.form.controls['DiscountAmount'].setValue(reset_amount);
    this.form.controls['TotalAmountAfterDiscount'].setValue(reset_amount);
    this.form.controls['TDSInPercent'].setValue(reset_amount);
    this.form.controls['TDSInAmount'].setValue(reset_amount);
    this.form.controls['TotalAmountAfterTDS'].setValue(reset_amount);
    this.form.controls['IGSTPer'].setValue(reset_amount);
    this.form.controls['CGSTPer'].setValue(reset_amount);
    this.form.controls['SGSTPer'].setValue(reset_amount);
    this.form.controls['IGSTAmount'].setValue(reset_amount);
    this.form.controls['CGSTAmount'].setValue(reset_amount);
    this.form.controls['SGSTAmount'].setValue(reset_amount);
    this.form.controls['TotalPayableAmount'].setValue(reset_amount);
    this.form.controls['TDSInAmountToGovt'].setValue(reset_amount);
    this.form.controls['TotalInvoiceValue'].setValue(reset_amount);
  }





  valueChangedJ(G, I) {
    const value_a = parseFloat(G);
    const value_b = parseFloat(I);
    const value_c = value_a - value_b;
    this.form.controls['TotalAmountAfterDiscount'].setValue(value_c);
  }


  // valueChangedM(J,K,L){
  //   const value_a=parseFloat(J);

  //   const value_b=parseFloat(K);
  //   const value_c=parseFloat(L);
  //   const value_d=value_a+value_b+value_c;

  // this.form.controls['TotalPayableAmount'].setValue(value_d); 
  // }

  valueChangedG(A, F) {
    const value_a = parseFloat(A);

    const value_b = parseFloat(F);
    const value_c = value_a - value_b;

    this.form.controls['TotalAmountAfterTDS'].setValue(value_c);
  }

  valueChangedA(A, B) {

    const value_a = parseFloat(A);

    const value_b = parseFloat(B);
    const value_c = value_a * value_b;

    const gst_amount = value_c / 100;


    const cgst_amount = value_b / 2;


    const sgst_amount = value_b / 2;
    this.form.controls['IGSTAmount'].setValue(gst_amount);
    this.form.controls['CGSTPer'].setValue(cgst_amount);
    this.form.controls['SGSTPer'].setValue(sgst_amount);




    // this.form.controls['TotalPayableAmount'].setValue(B); 
  }


  valueChangedgsti(A, B) {

    const value_a = parseFloat(A);

    const value_b = parseFloat(B);
    const value_c = value_a * value_b;

    const gst_amount = value_c / 100;
    this.form.controls['CGSTAmount'].setValue(gst_amount);

    // this.form.controls['TotalPayableAmount'].setValue(B); 
  }



  valueChangedgstj(A, B) {

    const value_a = parseFloat(A);

    const value_b = parseFloat(B);
    const value_c = value_a * value_b;

    const gst_amount = value_c / 100;
    this.form.controls['SGSTAmount'].setValue(gst_amount);

    // this.form.controls['TotalPayableAmount'].setValue(B); 
  }


  valueChangedH1(A, B) {
    const value_a = parseFloat(A);
    const value_b = parseFloat(B);
    const value_c = value_a + value_b;


  }






  submit() {

    if (this.form.valid) {
      this.visibleloader = true;



      const formData = new FormData();




      this.InvoiceDate = this.datePipe.transform(this.form.get('InvoiceDate').value, 'MM/dd/yyyy');
      console.log(this.InvoiceDate);


      formData.append('InvoiceDate', this.InvoiceDate);



      console.log("this.RecentVendor");
      console.log(this.RecentVendor);

      if (this.RecentVendor > 0) {
        formData.append('VendorID', this.RecentVendor);
      }
      else {

        formData.append('VendorID', this.form.get('VendorID').value);
      }


      formData.append('InvoiceName', this.form.get('InvoiceName').value);

      formData.append('ItemID', this.form.get('ItemID').value);


      formData.append('InvoiceHeadItemID', this.form.get('HeadID').value);

      formData.append('InvoiceDesc', 'Description');


      formData.append('InvoiceMonth', 'FEB');



      if (this.form.get('ValidityExpireOn').value != null) {


        this.expDate = this.datePipe.transform(this.form.get('ValidityExpireOn').value, 'MM/dd/yyyy');
        formData.append('ValidityExpireOn', this.expDate);
      }
      else {
        formData.append('ValidityExpireOn', '');
      }

      formData.append('WarrantyImp', this.form.get('WarrantyImp').value);


      formData.append('Priority', this.form.get('Priority').value);

      formData.append('TotalCostWithoutGST', this.form.get('TotalCostWithoutGST').value);


      formData.append('IGSTAmount', this.form.get('IGSTAmount').value);


      formData.append('TDSInAmountToGovt', this.form.get('TDSInAmountToGovt').value);



      formData.append('TDSInPercent', this.form.get('TDSInPercent').value);


      formData.append('TDSInAmount', this.form.get('TDSInAmount').value);

      formData.append('TotalAmountAfterTDS', this.form.get('TotalAmountAfterTDS').value);

      formData.append('DiscountPercent', this.form.get('DiscountPercent').value);

      formData.append('DiscountAmount', this.form.get('DiscountAmount').value);


      formData.append('TotalAmountAfterDiscount', this.form.get('TotalAmountAfterDiscount').value);

      formData.append('TotalInvoiceValue', this.form.get('TotalInvoiceValue').value);



      formData.append('TotalPayableAmount', this.form.get('TotalPayableAmount').value);

      // 17/02/2023
      // formData.append('InvoiceAttechmentURL',this.form.get('InvoiceAttechmentURL').value);


      // formData.append('ApprovalLetterAttachmentURL',this.form.get('ApprovalLetterAttachmentURL').value);
      // 17/02/2023

      formData.append('IGSTPer', this.form.get('IGSTPer').value);



      formData.append('CGSTPer', this.form.get('CGSTPer').value);


      formData.append('SGSTPer', this.form.get('SGSTPer').value);





      formData.append('CGSTAmount', this.form.get('CGSTAmount').value);



      formData.append('FreightCharges', '');

      formData.append('SGSTAmount', this.form.get('SGSTAmount').value);











      formData.append('DepttContactPerson', this.form.get('DepttContactPerson').value);

      formData.append('VendorInvoiceNo', this.form.get('VendorInvoiceNo').value);


      formData.append('InvoiceID', '0');

      formData.append('OrgID', this.form.get('OrgID').value);

      formData.append('DivID', this.form.get('DivID').value);

      formData.append('DeptID', this.form.get('DeptID').value);




      this.UserID = localStorage.getItem('UserID');



      this.UserTypeID = localStorage.getItem('UserTypeID');

      formData.append('UserID', this.UserID);


      formData.append('InvoiceForID', this.form.get('InvoiceForID').value);

      formData.append('PaymentPriorityID', '1');  //hardcord

      formData.append('ItemCategoryID', this.form.get('ItemCategoryID').value);
      formData.append('InvoiceHeadId', '1');


      formData.append('InvoiceYear', '2022');

      formData.append('DayRemaining', '1');


      //hardcord




      formData.append('ItemName', 'actualformtestdata');
      formData.append('ApprovalLetterAttachmentURL', this.form.get('ApprovalLetterAttachmentURL').value);


      formData.append('InvoiceAttechmentURL', this.form.get('InvoiceAttechmentURL').value);



      this.UserEmail = localStorage.getItem('UserEmail');



      formData.append('UserEmailID', this.UserEmail);


      this.http.post(this.ServiceUrl + '/invoiceregistration/invoiceregistration', formData)
        .subscribe((res: any) => {




          this.visibleloader = false;
          if (res.invoiceregistrationResult.message == "Invoice Already Exist") {
            // alert('Uploaded Successfully.');
            this.route.navigate(['/InvoiceList'])
            localStorage.setItem('exist', 'exist');

          }
          else {
            // alert('Uploaded Successfully.');
            this.route.navigate(['/InvoiceList'])
            localStorage.setItem('insertdata', 'Success');


          }

        })


    } else {
      this.validateAllFormFields(this.form);
    }
  }





  onFileChange1(event) {


    const filename = event.target.files[0].name;
    var upld = filename.split('.').pop();
    if (upld == 'pdf' || upld == 'png' || upld == 'jpg') {
      // alert("File uploaded is "+upld)
    } else {
      alert("Only PDF,JPEG,PNG are allowed")
      const reset_amount = "";
      this.form.controls['ApprovalLetterAttachmentURL'].setValue(reset_amount);
    }



    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.patchValue({
        ApprovalLetterAttachmentURL: file
      });
    }
  }

  onFileChange(event) {

    const filename = event.target.files[0].name;
    var upld = filename.split('.').pop();
    if (upld == 'pdf' || upld == 'png' || upld == 'jpg') {
      // alert("File uploaded is "+upld)
    } else {
      alert("Only PDF,JPEG,PNG are allowed");
      const reset_amount = "";
      this.form.controls['InvoiceAttechmentURL'].setValue(reset_amount);
    }

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.patchValue({
        InvoiceAttechmentURL: file
      });

    }
  }


  onSubmit(fileToUpload: File, fileToUpload1: File) {


    //     console.log(fileToUpload);

    //     console.log(fileToUpload1);
    // console.log("hellosir");

    //     const endpoint = 'http://services.aisect.org/api/InvoiceRegistration?UserTypeid=1&userid=1&invoiceType=1';
    //     const formData: FormData = new FormData();


    //     formData.append('VendorInvoiceNo',"4246");

    //     formData.append('InvoiceID', '1'); 

    //     formData.append('OrgID', '1'); 

    //     formData.append('DivID','1'); 
    //     formData.append('DeptID', '1'); 

    //     formData.append('UserID','1'); 


    //     formData.append('InvoiceForID','1'); 

    //     formData.append('PaymentPriorityID','1'); 

    //     formData.append('ItemCategoryID','1'); 
    //     formData.append('InvoiceHeadId','1'); 

    //     formData.append('InvoiceYear','2095'); 

    //     formData.append('DayRemaining','1'); 

    //     formData.append('ItemName','testdata'); 





    //     formData.append('InvoiceAttechmentURL', fileToUpload, fileToUpload.name);

    //     formData.append('ApprovalLetterAttachmentURL', fileToUpload1, fileToUpload1.name);

    //        console.log(this.http.post(endpoint, formData));

    //        console.log("Image Upload Successfully");
    //        return;


    //     var obj1 = 
    //    {
    //     "flag":"",
    //     "InvoiceID":"0",
    //     "CreatedDate":"10/22/2021",
    //     "CreatedBy":"1",
    //     "UpdatedDate":"",
    //     "UpdatedBy":"",
    //     "UserID":"1",//requiere
    //     "TDSCertificateAttachmentURL":"1",//requiere
    //     "AccountNo":"",
    //     "Department":"",
    //     "IRNNo":"",
    //     "Scheme":"",
    //     "PaymentPriorityID":"1",//requiere
    //     "InvoiceHeadId":"1",//requiere
    //     "PreferdablePaymentMode":"",

    //    };
    // var obj2 = this.form.value;
    // var form_data = Object.assign(obj1, obj2);
    //        console.log("merge");
    //        console.log(form_data);
    //        console.log("merge"); 
    //        console.log(this.form);
    //       //  console.log(this.form.value.flag);

    // const datarequest={
    //  "CheckSum": "",
    //   "lead_id": "",
    // "ActionFor": "Insert",            
    // "user_column_data":[

    //  form_data

    // ]
    // }
    // ;
    // console.log("datarequest1");
    // console.log(datarequest);
    // console.log("datarequest1");
    // console.log(this.form);


    // if (this.form.valid) {
    //   console.log('form submitted');

    //   let url ="http://services.aisect.org/api/InvoiceRegistration";
    //   this.http.post(url, datarequest).toPromise().then((data:any)=>{


    //    console.log(data.ItemCategoryRegDetails);

    //  if(data.ItemCategoryRegDetails.message=='success'){

    //    console.log('Insert Success');

    //    this.route.navigate(['/InvoiceList'])
    // localStorage.setItem('insertdata', 'Success');


    //  }else{

    //    console.log('Please Try Again Later');

    //  }

    //  });
    // } else {
    //   this.validateAllFormFields(this.form);
    // }
  }




  getDivisionByOrg(e) {
    this.selectedOrgId = e.value.OrgID;
    this.selectedOrgName = e.value.OrgName;
    this.selectedDivName = 'Please Select Division';
    this.selectedDeptName = 'Please Select Department';
    this.billdeskService.getDivisionByOrgListForInvoice(e.value.OrgID).then(res => this.divisionlist = res);
  }
  changeDivision(e) {
    this.selectedDivName = e.value.DivName;


    this.selectedDiv = e.value.DivID;

    const orgId = e.value.OrgID;
    const divId = e.value.DivID;


    this.selectedDeptName = 'Please Select Department';
    this.billdeskService.getDepartmentlistforinvoice(orgId, divId).then(res => this.departmentlist = res);

  }


  getItemByCategory(e) {
    this.selectedItemCategoryID = e.value.ItemCategoryID;
    this.selectedItemCategoryName = e.value.ItemCategoryName;

    this.selectedItemName = "Please Select Item";
    this.billdeskService.getItemByCategoryDDLInvoice(e.value.ItemCategoryID).then(res => this.itemlist = res);

  }
  changeItemStage(e) {

    this.selectedItemName = e.value.ItemName;

    this.selectedItemId = e.value.ItemID;

  }


  changePriority(e) {

    this.selectedPriorityName = e.value.PriorityName;

    this.selectedPriorityId = e.value.PriorityID;
  }



  changeDepartment(e) {

    this.selectedDeptName = e.value.DeptName;

    this.selectedDeptID = e.value.DeptID;
  }


  changeVendor(e) {


    this.selectedVendorName = e.value.VendorName;

    var Userid = e.value.UserID;
    this.selectedVendorId = e.value.VendorID;

    console.log(this.billdeskService.getVendorByid(Userid).then(res => this.vendordetail = res));


  }


  changeVendorstatic(e) {
    this.billdeskService.getVendorByid(this.UserID).then(res => this.vendordetail = res);
  }

  changeInvoiceFor(e) {

    this.selectedInvoiceForName = e.value.InvoiceForName;

    this.selectedInvoiceForID = e.value.InvoiceForID;
  }

  changeHeadName(e) {

    this.selectedInvoiceHeadName = e.value.InvoiceHeadItemName;

    this.selectedInvoiceHeadID = e.value.InvoiceHeadItemID;
  }




  resetform() {
    location.reload();

    // this.form.reset();

    // this.route.navigate(['/InvoiceRegistration']);
  }



  ProductCategoryDialogButton() {

    this.ProductCategoryDialog = true;
  }



  ProductCategoryRegister() {


    var obj1 =
    {

      "flag": "MSTItemCatagory_INSERT",
      "ItemCategoryID": "0",
      "UpdatedBy": "1",
      "UpdatedOn": "10/22/2021",
      "CreatedBy": "1",
      "CreatedOn": "",
      "CategoryIcon": "",

      "ImageUrl": "",
      "CategoryUrl": "",
      "CategoryImageUrl": "",
      "Comments": "",


      "ActiveStatus": "",
      "OrderBy": "",
      "EstStartDate": "",

      "EstEndDate": "",
      "ActualStartDate": "",
      "ActualEndDate": ""
    };
    var obj2 = this.categoryform.value;


    // console.log(this.categoryform.valid);
    console.log('obj2');
    console.log(obj2);
    var form_data = Object.assign(obj1, obj2);

    const datarequest = {
      "CheckSum": "",
      "lead_id": "",
      "ActionFor": "Insert",
      "dataobject": [

        form_data

      ]
    }
      ;


    // if (this.categoryform.valid) { 
    let registerurl = "/itemcategory/itemcategoryregistration";
    this.billdeskService.RegisterData(registerurl, datarequest).subscribe((res: any) => {

      if (res.itemcategoryregistrationResult.Message == 'Sucess') {

        this.route.navigate(['/InvoiceRegistration'])
        this.ProductCategoryDialog = false;
        this.openDialogCategory();
        // Live Reload Data
        this.billdeskService.getItemCategoryDDLInvoicelist().then(res => this.itemcategorylist = res);
        // Live Reload Data End 
      } else {
        //  console.log('Please Try Again Later');
      }
    });


    // } else {


    //   this.validateAllFormFields(this.categoryform);
    // }
  }



  ProductDialogButton() {

    this.ProductDialog = true;

  }




  InvoicePurposeDialogButton() {

    this.InvoicePurposeDialog = true;
    this.billdeskService.getHead().then(res => this.headlist = res);

  }

  ProductRegister() {
    var obj1 =
    {

      "flag": "MSTItemCatagory_INSERT",
      "ItemID": "0",
      "CreatedBy": "2",
      "CreatedByID": "4",
      "UpdatedDate": "10/22/21",
      "UpdatedBy": "",
      "ItemType": "",
      "UnderOfParentID": "",
      "ItemAbstract": "",
      "ItemDescription": "",
      "FAQListID": "",
      "ItemImageUrl": "",
      "ItemImageUrl2": "",
      "ItemImageUrl3": "",
      "ItemImageUrl4": "",
      "ItemVideoSource": "",
      "ItemVideoUrl": "",
      "ItemVisibilityMode": "",
      "ActiveStatus": "",
      "MetaTitle": "",
      "MetaKeyWord": "",
      "MetaDiscription": "",
      "SchemaCodes": "",
      "OGTags": "",
      "UrlOptimization": "",
      "FilterValue1": "",
      "FilterValue2": "",
      "WholeSalePriceMax": "",
      "WholeSalePriceMin": "",
      "ItemUnitPurchasePrice": "",
      "ItemUnitSalesPrice": "",
      "MSP": "",
      "MRP": "",
      "ItemDiscountInPercentage": "",
      "ItemDiscountInAmount": "",
      "Tax1Amount": "",
      "Tax2Amount": "",
      "GST": "",
      "NetPayAmount": "",
      "CoopenCode": "",
      "GSTInPercentage": "",
      "OpeningStock": "",
      "AsofDate": "",
      "IsLowStockWarning": "",
      "LowStockQuantity": "",
      "ItemCode": "",
      "Barcode": "",
      "ItemBrandName": "",
      "ItemMedium": "",
      "ItemSize": "",
      "ItemWorkType": "",
      "ItemBase": "",
      "ItemColor": "",
      "ItemMaterial": "",
      "ItemEntryDate": "",
      "ItemAddedBy": "",
      "ItemToTopTen": "",
      "ItemToHome": "",
      "DisplayOrder": "",
      "Comments": "",
    };
    var obj2 = this.productform.value;
    var form_data = Object.assign(obj1, obj2);
    console.log("form_data");
    console.log(form_data);
    const datarequest = {
      "CheckSum": "",
      "lead_id": "",
      "ActionFor": "Insert",
      "dataobject": [

        form_data

      ]
    }
      ;


    //  if (this.productform.valid) { 
    let registerurl = "/item/itemregistration";
    this.billdeskService.RegisterData(registerurl, datarequest).subscribe((res: any) => {

      if (res.itemregistrationResult.Message == 'Sucess') {
        this.route.navigate(['/InvoiceRegistration'])
        this.ProductDialog = false;
        this.openDialogProduct();

        this.billdeskService.getItemByCategoryDDLInvoice(this.selectedItemCategoryID).then(res => this.itemlist = res);
      } else {
        //  console.log('Please Try Again Later');
      }
    });





    //  } else {
    //  this.validateAllFormFields(this.form);
    //  }
  }
  InvoicePurposeRegister() {
    var obj1 = 
   {
    "flag":"",  
    "CreatedBy":"1",
     "IsActive":1
   };
var obj2 = this.InvoicePurposeform.value;
var form_data = Object.assign(obj1, obj2);
       console.log("merge");
       console.log(form_data);
       console.log("merge"); 
       console.log(this.InvoicePurposeform);
       console.log(this.InvoicePurposeform.value.flag);
       
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
console.log("datarequest1");
console.log(datarequest);
console.log("datarequest1");
console.log(this.InvoicePurposeform);


// if (this.form.valid) {
  console.log('form submitted');
  
 
let registerurl= "/invoiceheaditem/invoiceheaditemregistration";
this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
  console.log("depin");
  console.log(res);
  console.log("depin");
    console.log(res.invoiceheaditemregistrationResult);
  if(res.invoiceheaditemregistrationResult.Message=='Sucess'){

    this.InvoicePurposeDialog = false;
    this.route.navigate(['/InvoiceRegistration']) 
    this.openDialogInvoicePurpose();

     this.billdeskService.getHeaditemlist().then(res => this.headlist = res);
 }else{
   console.log('Please Try Again Later');
 }
 });




// } else {
//   this.validateAllFormFields(this.form);
// }
 }


}








// same sa new page

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor: string;
}