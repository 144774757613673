 
 
   
   <form class="form-horizontal" [formGroup]="form" *ngFor="let data of products">


 
    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">EDIT PRICE TYPE</h1>
        <div class="card-body" style="background-color:#fff; ">
        
        
        
              
            <h1 class="card-title">
                Price Type  Detail
            </h1>
         
        <div class="row">


          
                <div class="position-relative  form-group col-sm-6">
                    <label for="PriceTypeName" class="col-form-label">Price Type Name  <span>*</span></label>
      
                    <div   [ngClass]="displayFieldCss('PriceTypeName')">
                 
                     
                        <input    type="text"  name="PriceTypeName" class="form-control"  formControlName="PriceTypeName" [(ngModel)]="data.PriceTypeName"  >
                     
                        
                         
                        <app-field-error-display [displayError]="isFieldValid('PriceTypeName')" errorMsg="Please Price Type  Name"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            
        
          
                    
                    <div class="position-relative  form-group col-sm-6">
                        <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
                        <div class="">
 
                <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus"  [(ngModel)]="data.ActiveStatus">
                    <option value="1">Active</option>
                    <option value="0">InActive</option></select>
                             <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                     
            
                    <div class="position-relative  form-check col-sm-6 offset-sm-6 ">
                        <div class="">
        
                            <br>
                            <br>
                            <br>
                            <button class="btn btn-primary btn-lg" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>  
            
                    </div>
           
          
                