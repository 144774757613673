import { Component, OnInit , Inject, Input} from '@angular/core';
import { Billdesk } from '../../billdesk';
import { BilldeskService } from '../../billdesk.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../../customer";  

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

 
export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor:string;
} 
@Component({
  selector: 'app-purchase-list',
  templateUrl: './purchase-list.component.html',
  styleUrls: ['./purchase-list.component.css']
})
export class PurchaseListComponent implements OnInit {

  productDialog: boolean;
  productDetail:boolean;

  imageurl=environment.imgUrl;
  products: Billdesk[];
  productsbyid: Billdesk[];

  data:number=0;

  dialogdata: string;
 
  radio=false;
  radio1=false;
  
  organizationList: Billdesk[];
productdetails:any;
  product: Billdesk;
  billdesk:any;
  selectedProducts:any;
  insertdata:any;
  updatedata:any;
  submitted: boolean;
//   OrganizationListResult:any[];
  statuses: any[];
  cities: City[];
  customers: Customer[];
  successDialog:boolean;
  insertDialog:boolean;
  representatives: Representative[];
  updateDialog:boolean;
  loading: boolean = true;
  inventoryStatus: Representative[];
  selectedCities: City[];
  
  cols: any[];
  exportColumns:any;
  _selectedColumns: any[];
  UserInfo:any;
  DepartmentID:any; 
  InstitutionID:any;
  OrganizationID:any;
//   OrganizationListResult:any;
  constructor(private billdeskService: BilldeskService, private messageService: MessageService, private confirmationService: ConfirmationService,public dialog: MatDialog,private route:Router) { 
 this.cities = [
          {name: 'New York', code: 'NY'},
          {name: 'Rome', code: 'RM'},
          {name: 'London', code: 'LDN'},
          {name: 'Istanbul', code: 'IST'},
          {name: 'Paris', code: 'PRS'}
      ];
      this.cols = [
        {field:'ProductID',header:'Product ID'},
        { field: 'ProductName', header: 'Product Name' },
        { field: 'BrandName', header: 'Brand Name' },
        { field: 'UnitName', header: 'Unit Name' },
        { field: 'PackedDate', header: 'Packed Date' },
        { field: 'ExpiryDate', header: 'Expiry Date' },
        { field: 'Description', header: 'Description' },
        { field: 'PriceTypeName', header: 'Price Type Name ' },
        { field: 'PurchaseQuantity', header: 'Purchase Quantity' },
        { field: 'PurchasePrice', header: 'Purchase Price' },
        { field: 'SalePrice', header: 'Sale Price' },
        { field: 'MRP', header: 'MRP' },
        { field: 'WholesalePrice', header: 'Whole sale Price ' },
        { field: 'TaxableAmount', header: 'Taxable Amount' },
        { field: 'TaxPercentage', header: 'Tax Percentage' },
        { field: 'TaxAmount', header: 'TaxAmount' },
        { field: 'TotalAmount', header: 'TotalAmount'} 
    ];
  

    this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
    this._selectedColumns = this.cols;


    this.DepartmentID=localStorage.getItem('DepartmentID');

this.InstitutionID=localStorage.getItem('InstitutionID');

this.OrganizationID=localStorage.getItem('OrganizationID');

    this.UserInfo='&OrgID='+this.OrganizationID+'&DivID='+this.InstitutionID+'&DeptID='+ this.DepartmentID;
    
  }



  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
}

set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
} 

exportPdf() {
  import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
          const doc = new jsPDF.default('l', 'mm', [297, 210]);
          (doc as any).autoTable(this.exportColumns, this.productdetails);
          doc.save('products.pdf');
      });
  });
}

  ngOnInit() {


    




    this.statuses = [
      { label: "DeActive", value: "0" , IfValue: "Deactive" },
      { label: "Active", value: "1" , IfValue: "Active" } 
    ];


    
    this.insertdata=  localStorage.getItem('insertdata');
    
    this.updatedata=  localStorage.getItem('updatedata');
    // console.log("deat");
    // console.log(this.insertdata);
    
    // console.log(this.updatedata);
    // console.log("sgsg");
    
    
    if(this.insertdata=='Success'){
    
      this.organizationRegister();
      console.log("this.insertdata");
      console.log(this.insertdata);
      console.log("this.insertdata");
    
    } 

    if(this.updatedata=='Success'){
    
      this.organizationUpdate();
      console.log("this.updatedata");
      console.log(this.updatedata);
      console.log("this.updatedata");
    
    } 
 

   
  //  var  organizationList  =  this.billdeskService.getOrganization();
 this.billdeskService.getPurchaseList(this.UserInfo).then(res => this.products = res);
 
      this.representatives = [
          { inventoryStatus: "INSTOCK", image: "INSTOCK" },
          { inventoryStatus: "LOWSTOCK", image: "LOWSTOCK" },
          { inventoryStatus: "OUTOFSTOCK", image: "OUTOFSTOCK" },
          
        ];



        // this.statuses = [

        //   { label: "Pending", value: "Pending" },
        //   { label: "Success", value: "Success" } 

        // ];
  }

  openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
  }

 



  handleChange(evt,orgid) {
    var name = evt.target.name;
    var value = evt.target.value;
    let updateurl= "/organization/organizationregistrationupdatestatus";
    if (name=='radio') {
      
      var activestatus= 1;
    }
    else
    { 
      var activestatus= 0;

    }
   var datarequest={ 
              "dataobject":[{
              "OrgID":orgid, 
              "ActiveStatus":activestatus
               }]}; 
    this.billdeskService.UpdateData(updateurl,datarequest).subscribe((res:any)=>{ 
    if(res.organizationregistrationupdatestatusResult.Message=='Sucess'){
    window.location.reload();
    this.route.navigate(['/Organization-List'])
    localStorage.setItem('updatedata', 'Success');
   }else{
     console.log('Please Try Again Later');
   }
   });
 }
  deleteSelectedProducts() {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete the selected products?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.products = this.products.filter(val => !this.selectedProducts.includes(val));
              this.selectedProducts = null;
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
          }
      });
  }

//   editProduct(product: Billdesk ) {
//       this.Billdesk = {...product};
//       this.productDialog = true;
//   }

  viewProduct(product: Billdesk) {
    // this.product = {...product};
    this.productDialog = true;

const useerwithpurchse='id='+product+'&'+this.UserInfo;
    console.log(this.billdeskService.getPurchaseDetailByid(useerwithpurchse).then(res => this.productsbyid = res));
}


viewProductDetail(product:Billdesk){ 
 this.productDetail = true; 
const useerwithpurchse='?id='+product+'&'+this.UserInfo;
 this.billdeskService.getPurchaseDetailByid(useerwithpurchse).then(res => this.productsbyid = res);
 this.billdeskService.getPurchaseDetailofProductByid(useerwithpurchse).then((res:any)  =>{this.productdetails = res });

}

tip(){


  alert('hello tip data');
}

successButton() {
   
    this.successDialog = true;
}








 
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Purchase Entry", Detail: " Successfully",Backgroundcolor: "#47c9a2"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }


  openDialogUpdate(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Organization", Detail: "Update Successfully" ,Backgroundcolor: "#137c95"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }

  
  openDialogDelete(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {Service: "Organization", Detail: "Delete Successfully" ,Backgroundcolor: "#ff4141"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      window.location.reload();
      this.dialogdata = result;
    });
  }
 


organizationRegister() {
  localStorage.removeItem('insertdata'); 
  console.log('remove');// this code for open dialog after 5 second
  setTimeout(() => {
    // this.insertDialog = true;
this.openDialog();

}, 500);
}
organizationUpdate() {
  localStorage.removeItem('updatedata'); 
  console.log('remove');// this code for open dialog after 5 second
  setTimeout(() => {
    // this.insertDialog = true;
this.openDialogUpdate();

}, 500);
}
 



statusupdateButton() {
  this.updateDialog = true;
}



  deleteProduct(product: Billdesk) {

    console.log(product);
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + product.OrgID + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {

  
 console.log(this.billdeskService.DeleteOrganizationByid(product.OrgID).then(res => this.products = res));
      
            // this.successButton();

            this.openDialogDelete();
        
        }
      });
  }

  hideDialog() {
      this.productDialog = false;
      this.submitted = false;
  }
  
  saveProduct() {
      this.submitted = true;

      if (this.product.name.trim()) {
          if (this.product.id) {
              this.products[this.findIndexById(this.product.id)] = this.product;                
            //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
            this.statusupdateButton();
          }
          else {
              this.product.id = this.createId();
              this.product.image = 'product-placeholder.svg';
              this.products.push(this.product);
            //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
          }

        //   this.products = [...this.products];
          this.productDialog = false;
          this.product = {};
      }
  }

  findIndexById(id: string): number {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
              index = i;
              break;
          }
      }

      return index;
  }

  createId(): string {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
  }
}
interface City {
  name: string,
  code: string
}



// same sa new page

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}