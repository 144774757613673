
import { Component, OnInit, ElementRef, VERSION } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../../billdesk';
import { BilldeskService } from '../../billdesk.service';

import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InvoiceItem } from '../../invoiceitem';
import { datatable } from '../../model/datatable.model';
import { environment } from '../../../environments/environment';
// import { ToastrService } from 'ngx-toastr';

import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';   
//import * as $ from "jquery";  
declare var $: any;

 
@Component({
  selector: 'app-productcreatebyitem',
  templateUrl: './productcreatebyitem.component.html',
  styleUrls: ['./productcreatebyitem.component.css']
})
export class ProductcreatebyitemComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  dynamicArray = [];
  newDynamic;
  VendorDetailId: any;
  datatable = new datatable();
  UserTypeID: any;
  selectedOrgId: string;
  groupname:any;
  DataField: Billdesk[];
  purchasetype:any;

  selectedOrgName: string = 'Please Select Organization';

  selectedDivName: string = 'Please Select Division';
  selectedDiv: string;

  selectedDeptID: string;
  selectedDeptName: string = 'Please Select Department';


  ServiceUrl = environment.baseUrl;

  selectedRoleID: string;
  selectedRoleName: string = 'Please Select Role';


  VendorShow: boolean;

  sidebarVisible2: boolean;
  organizaionlist: Billdesk[];
  statekey: any;
  stateList: Billdesk[];
  rolelist: Billdesk[];
  departmentlist: Billdesk[];
  divisionlist: Billdesk[];
  cityList: Billdesk[];

  rows: FormArray;
  type: string;

  // stateList:any[];

  // cityList:any[];
  selectedCity: string;

  selectedCityName: string = 'Please Select City';
  itemHeadITemlist: any;
  selectedStateName: string = 'Please Select State';
  StateID: number;
  DistrictID: number;
  selectedState: string;
  departmentdetail: any;

  itemlist: Billdesk[];
  itemcategorylist: Billdesk[];
  selectedItemCategoryID: string;
  selectedItemName = 'Please Select Item';
  selectedItemId: string;
  selectedItemCategoryName = 'Please Select Category';
  venderidsession: any;
  dataArray = [];
  amount_i = 0;
  UserID: any;

  invoiceitem: InvoiceItem[];
  gstdetail: any;

  a: any;
  b: any;
  c: any;
  d: any;
  e: any;
  f: any;
  g: any;
  h: any;
  i: any;
  j: any;
  k: any;
  l: any;
  m: any;
  n: any;
  o: any;
  p: any;
  q: any;
  taxamount: any;
  ctaxamount: any;
  staxamount: any;
  totalwithoutgst: any;
  frightcharge: any;
  discount: any;
  Totalwithtax: any;
  receiveamt: any;
  balance: any;
  previousbalance: any;
  currentbalance: any;
  amountInWords: any;
  invdate: any;
  duedate: any;
  discountamt: any;

  invno:any;
  purchasebatchDetail:any;
  BatchNo:any;


  TotalAmountAfterDiscount: any;
  TDSInPercent: any;
  discountedcost: any
  TDSInAmount: any;
  ctaxper: any;
  staxper: any;
  amtAfterTds: any;
  TotalPayableAmount: any;

  vendorlist: Billdesk[];
  form: FormGroup;
  addForm: FormGroup;
  OrgID: any;
  Key: any;
  withouttax: any;
  pricetypelist:any; 
  VendorName: any;
  VendorBillingAddress: any;
  VendorCity: any;
  VendorPinCode: any;
  GSTN: any;
  VendorState: any;
  VendorEmailID: any;
  VendorMobileNo: any;
  brandlist:any;
  unitlist:any;
  MinWholesaleQuantity:any;
  vendordetail: any;
  selectedVendorName:string='Please Select Vendor';
  selectedVendorId: any;





  SaleDetailID:any; 
  ProductID: any; 
   BrandID: any; 
   SaleUnit: any; 
   PackedDate: any; 
   ExpiryDate: any; 
   Description: any; 
   SalePriceType: any; 
   PurchaseQuantity: any; 
   SalePrice: any;  
   MRP: any; 

   SaleQuantity:any;
   WholesalePrice: any; 
   TaxableAmount: any; 
   TaxPercentage: any; 
   TaxAmount:  any; 
   batchlistbyproduct:any;
   StockQuantity:any;
   TotalAmount:any;  

   BrandName:any;

   StockID:any;
  public listObj: DropDownListComponent;  
  
  public CityData: Object[] = [  
      { Id: '1', City: 'Jaipur' },  
      { Id: '2', City: 'Delhi' },  
      { Id: '3', City: 'Noida' },  
      { Id: '4', City: 'Gurgoan' },  
      { Id: '5', City: 'Pune' },  
      { Id: '6', City: 'Mumbai' },  
      { Id: '7', City: 'Ajmer' },  
      { Id: '8', City: 'Bangalore' },  
      { Id: '9', City: 'Hyderabad' },  
      { Id: '10', City: 'Vizag' }  
  ];  

  public fields: Object = { text: 'City', value: 'Id' };  
  public height: string = '220px';  
  public waterMark: string = 'Select a City';  
  public value: string = '3';  
  constructor(private fb: FormBuilder, private http: HttpClient, private messageService: MessageService, private route: Router, private billdeskService: BilldeskService, private elementRef: ElementRef) {

    this.venderidsession = localStorage.getItem('VendorId');

    console.log("this.venderidsession");
    console.log(this.venderidsession);
    this.addForm = this.fb.group({
      items: [null, Validators.required],
      items_value: ['no', Validators.required],
      VendorID: [null, Validators.required]

    });
    this.billdeskService.getOrganizationsLisInvoicetDDL().then(res => this.organizaionlist = res)
    this.billdeskService.getState().then(res => this.stateList = res)
    this.billdeskService.getUserRole().then(res => this.rolelist = res)
    this.billdeskService.getItemCategoryCombinelist().then(res => this.itemcategorylist = res)


    this.billdeskService.getBrandListDLL().then(res => this.brandlist = res)


    this.billdeskService.getUnitListDLL().then(res => this.unitlist = res) 


    this.billdeskService.getPrice_Type_ListDLL().then(res => this.pricetypelist = res) 



   
    

    this.gstdetail = ["0", "5", "12", "18", "28"];

    this.receiveamt = 0;
    this.discount = 0;
    this.frightcharge = 0;
    
  }
  ngOnInit() {
 

    // for alread exist one row 
    // this.dynamicArray.push({
    //   SaleDetailID: 0,
    //   ProductID: '',
    //   BrandID: '',
    //   SaleUnit: '',
    //   PackedDate: '',
    //   ExpiryDate: '',
    //   Description: '',
    //   SalePriceType: '',
    //   PurchaseQuantity: '',
    //   SalePrice: '',
    //   SalePrice: '',
    //   MRP: '',
    //   WholesalePrice: '',
    //   TaxableAmount: '' ,
    //   TaxPercentage: '',
    //   TaxAmount: '',
    //   TotalAmount: '' 
    // });


 

    setTimeout(()=>{
      console.log('DDDDD',$('.select'))


      $(document).ready(function () {
        $(".select").select2({
            placeholder: "Select a project..."
        })
        
    });


    },7000)
 
    




    this.UserID = localStorage.getItem('UserID');
    if (this.UserTypeID != 5) {
      this.http.get(this.ServiceUrl + '/vendor/vendordetail/' + this.UserID).subscribe(
        (response: any) => {
          this.VendorDetailId = response.vendordetailResult.dataobject[0].VendorID;


          this.VendorName = response.vendordetailResult.dataobject[0].VendorName;
          this.VendorBillingAddress = response.vendordetailResult.dataobject[0].VendorBillingAddress;

          this.VendorCity = response.vendordetailResult.dataobject[0].VendorCity;
          this.VendorPinCode = response.vendordetailResult.dataobject[0].VendorPinCode;
          this.GSTN = response.vendordetailResult.dataobject[0].GSTN;
          this.VendorState = response.vendordetailResult.dataobject[0].VendorState;
          this.VendorEmailID = response.vendordetailResult.dataobject[0].VendorEmailID;
          this.VendorMobileNo = response.vendordetailResult.dataobject[0].VendorMobileNo;
          localStorage.setItem('VendorId', this.VendorDetailId);
        });
    }

    // this is for billdesk 

    this.UserTypeID = localStorage.getItem('UserTypeID');
    console.log("this.UserTypeID");
    console.log(this.UserTypeID);
    if (this.UserTypeID == 1) {
      this.VendorShow = false;
      this.billdeskService.getVendorDDLInvoiceList().then((res: any) => {
        this.vendorlist = res

        console.log(this.vendorlist);
      }

      );
    } else {

      this.VendorShow = true;
    }


    // this is for billdesk 

  }




getBatchNoByProductId(e) {
 this.billdeskService.getStockBatchno_ListDLL(e).then(res => this.batchlistbyproduct = res) 
 }



 getStockDetailByBatchNo(e) {

  console.log(e);
  this.billdeskService.getStockDetailByBatchNDLL(e).then((res:any) => {


    this.purchasebatchDetail = res  
console.log(this.purchasebatchDetail[0]);  
    this.StockID=this.purchasebatchDetail[0].StockID;
    this.StockQuantity=this.purchasebatchDetail[0].AvailableQuantity;
    this.MRP=this.purchasebatchDetail[0].MRP;
    this.BrandName=this.purchasebatchDetail[0].BrandName;
    this.BrandID=this.purchasebatchDetail[0].BrandID;
this.SalePriceType=this.purchasebatchDetail[0].DefaultSalePriceType;

this.PackedDate=this.purchasebatchDetail[0].PackedDate;
this.ExpiryDate=this.purchasebatchDetail[0].ExpiryDate;
this.Description=this.purchasebatchDetail[0].description;
this.SalePrice=this.purchasebatchDetail[0].SalePrice;
this.WholesalePrice=this.purchasebatchDetail[0].WholesalePrice;
this.MinWholesaleQuantity=this.purchasebatchDetail[0].MinWholesaleQuantity;

this.TaxPercentage=this.purchasebatchDetail[0].TaxPercentage;
    console.log(this.StockID);
    
    console.log(this.BrandName);
  } )


}
 


  changeVendor(e) {

console.log(e);
    this.selectedVendorName = e.value.VendorName;

    var Userid = e.value.UserID;
    this.selectedVendorId = e.value.VendorID;

    this.billdeskService.getVendorByid(Userid).then((res: any) => {

      this.vendordetail = res

      console.log(this.vendordetail);


      this.VendorDetailId = this.vendordetail[0].VendorID;

      console.log(this.VendorDetailId);
      this.VendorName =  this.vendordetail[0].VendorName;
      this.VendorBillingAddress =  this.vendordetail[0].VendorBillingAddress;

      this.VendorCity = this.vendordetail[0].VendorCity;
      this.VendorPinCode =  this.vendordetail.dataobject[0].VendorPinCode;
      this.GSTN =  this.vendordetail[0].GSTN;
      this.VendorState =  this.vendordetail[0].VendorState;
      this.VendorEmailID = this.vendordetail[0].VendorEmailID;
      this.VendorMobileNo =  this.vendordetail[0].VendorMobileNo;

      localStorage.setItem('VendorId', this.VendorDetailId);
    });


  }

  onSubmit() {

 

// this.StockQuantity=100;
this.discountamt=0;
    this.totalwithoutgst = this.dynamicArray.reduce((sum, item) => sum + (item.SaleQuantity * item.SalePrice), 0);


    this.h = this.dynamicArray.reduce((sum, item) => sum + Number(item.TaxPercentage), 0);


    this.taxamount = this.dynamicArray.reduce((sum, item) => sum + Number(item.TaxAmount), 0);




    this.taxamount = this.dynamicArray.reduce((sum, item) => sum + Number(item.TaxAmount), 0);




    this.ctaxper = (this.h) / 2;
    this.staxper = (this.h) / 2;



    this.ctaxamount = (this.taxamount) / 2;
    this.staxamount = (this.taxamount) / 2;


    this.Totalwithtax = Number(this.taxamount) + Number(this.totalwithoutgst) + Number(this.frightcharge) - Number(this.discount);

    this.balance = Number(this.Totalwithtax) - Number(this.receiveamt);

    this.TotalPayableAmount = this.dynamicArray.reduce((sum, item) => sum + Number(item.TotalAmount), 0);

    this.previousbalance = 0;
    this.currentbalance = Number(this.balance) + Number(this.previousbalance);

    this.discountamt = this.totalwithoutgst * (this.discount / 100)



    this.TotalAmountAfterDiscount = this.TotalPayableAmount - this.discountamt;
    // this.TDSInAmount= this.TotalAmountAfterDiscount*(this.TDSInPercent/100);

    this.TDSInAmount = 0;
    this.TDSInPercent = 0;
    this.amtAfterTds = this.discountamt - this.TDSInAmount;



    this.UserID = localStorage.getItem('UserID');

    this.venderidsession = localStorage.getItem('VendorId');
    
    // this.TotalPayableAmount =this.amtAfterTds+this.ctaxamount+this.staxamount;


    console.log(this.dynamicArray);

 
    var obj1 =
    {
      "SaleID": 0,
      // "SaleInvoiceDate" : this.invno.toString(),
      "SaleInvoiceDate": this.invdate.toString(),  
      "OrgID": this.selectedOrgId,
      "DivID": this.selectedDiv,
      "DeptID": this.selectedDeptID, 
      "VendorID": this.VendorDetailId,
      "TaxableAmountGrandTotal": this.totalwithoutgst.toString(),
      "TaxAmountGrandTotal" : this.taxamount.toString(),
      "GrandTotal" :  this.TotalPayableAmount.toString(),
      "DiscountAmount": this.discountamt.toString(),
      "DiscountPercent": this.discount.toString(),
      "TDSAmount" : this.TDSInAmount.toString(),
      "TDSInPercent": this.TDSInPercent.toString(),  
      "FrightChargeAmount" : this.frightcharge.toString(),
      "FrightChargePercentage" :this.frightcharge.toString(),
      "RoundOff" : "0",
      "PayableAmount" : this.TotalPayableAmount.toString(),
      "ReceivedAmount" : "0",
      // "TotalAmountAfterDiscount": this.TotalAmountAfterDiscount.toString(),
      "BalanceAmount" : this.TotalPayableAmount.toString(),
      "SaleType" :Number(this.purchasetype),
      "SaleDetailJson": this.dynamicArray.map((d:any)=>{
          d['ProductID'] = parseInt(d.ProductID);
          d['BrandID']=parseInt(d.BrandID);
          d['StockID']=parseInt(d.StockID);
          d['SaleUnit']=parseInt(d.SaleUnit);
          d['SalePriceType']=parseInt(d.SalePriceType);
          d['StockQuantity']=d.StockQuantity.toString(); 
          d['SaleQuantity']=d.SalePrice.toString();
          d['MRP']=d.MRP.toString();
          d['WholesalePrice']=d.WholesalePrice.toString();
          d['TaxableAmount']=d.TaxableAmount.toString();
          d['TaxPercentage']=d.TaxPercentage.toString();
          d['TaxAmount']=d.TaxAmount.toString();
          d['TotalAmount']=d.TotalAmount.toString();
          return d;
      })
    };


    const datarequest = {
      "CheckSum": "",
      "lead_id": "",
      "ActionFor": "Insert",
      "dataobject": [
        obj1
      ]
    };



console.log(datarequest);


    // if (this.form.valid) {

    let registerurl = "/sale/saleregistration";
    this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res: any) => {
      console.log('res');
      console.log(res);
      console.log("res");
      if (res.saleregistrationResult.Message == 'Sucess') {
   
        this.route.navigate(['/sales_list'])
        localStorage.setItem('insertdata', 'Success');
      } else {
        // console.log('Please Try Again Later');
      }

    });



  }

  addRow() {



    console.log(this.dynamicArray);
    this.dynamicArray.push({
      SaleDetailID: 0,
      StockID:'',
      ProductID: '',
      BrandID: '',
      SaleUnit: '',
      PackedDate: '',
      ExpiryDate: '',
      Description: '',
      SalePriceType: '',
      StockQuantity: '',
      SaleQuantity:'',
      SalePrice: '', 
      MRP: '',
      WholesalePrice: '',
      TaxableAmount: '' ,
      TaxPercentage: '',
      TaxAmount: '',
      TotalAmount: '' 
   
    });


  }


  AddItem(){
 
      this.sidebarVisible2 = true;
 
 }


SubmitItemDetail(){



  this.StockQuantity=10;
    this.dynamicArray.push({



      SaleDetailID: 0,
      StockID:this.StockID,
      ProductID: this.ProductID,
  
      BrandID: this.BrandID,
      SaleUnit: this.SaleUnit,

      PackedDate: this.PackedDate,
      ExpiryDate:this.PackedDate,
      Description: this.Description,
      SalePriceType: this.SalePriceType,  
      SaleQuantity:this.SaleQuantity,

      StockQuantity:this.StockQuantity,
      SalePrice:this.SalePrice, 
      MRP: this.MRP,
      WholesalePrice: this.WholesalePrice,
      TaxableAmount: this.TaxableAmount ,
      TaxPercentage: this.TaxPercentage,
      TaxAmount:this.TaxAmount,
      TotalAmount:this.TotalAmount 
  });


    
this.ProductID='';
this.BrandID='';
this.StockID='';
this.SaleUnit='';
this.PackedDate='';
this.ExpiryDate='';
this.Description='';
this.SalePriceType='';
this.PurchaseQuantity='';
this.SalePrice='';
this.StockQuantity='';
this.SaleQuantity='';
this.WholesalePrice='';
this.TaxableAmount='';
this.TaxPercentage='';
this.TaxAmount='';
this.TotalAmount=''; 
this.MRP=''; 


this.sidebarVisible2 = false;

this.messageService.add({severity:'success', summary: 'Successful', detail: 'Products Sales', life: 3000});
    
}
  deleteRow(index) {

    // this.dynamicArray.splice(index, 1);

    if (this.dynamicArray.length == 1) {
      // this.toastr.error("Can't delete the row when there is only one row", 'Warning');
      alert("Cant Not Delete");
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      // this.toastr.warning('Row deleted successfully', 'Delete row');
      alert(" Deleted Row");
      return true;
    }
  }






  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  getDivisionByOrg(e) {
    this.selectedOrgId = e.value.OrgID;
    this.selectedOrgName = e.value.OrgName;
    this.selectedDivName = 'Please Select Division';
    this.selectedDeptName = 'Please Select Department';
    this.billdeskService.getDivisionByOrgListForInvoice(e.value.OrgID).then(res => this.divisionlist = res);
  }

  changeDivision(e) {
    this.selectedDivName = e.value.DivName;


    this.selectedDiv = e.value.DivID;
    const orgId = e.value.OrgID;
    const divId = e.value.DivID;
    this.selectedDeptName = 'Please Select Department';
    this.billdeskService.getDepartmentlistforinvoice(orgId, divId).then(res => this.departmentlist = res);
  }




  changeDepartment(e) {

    this.selectedDeptName = e.value.DeptName;
    var DeptID = e.value.DeptID;
    this.selectedDeptName;
    this.selectedDeptID = e.value.DeptID;
    this.billdeskService.getDepartmentByid(DeptID).then(res => this.departmentdetail = res);
  }



  getValue(rate: any, qty: any, taxper: any, i: any) {
    //do your stuff

    this.withouttax = rate * qty;




    // dynamic.TaxPercentage/100)*dynamic.Quantity*dynamic.Rate 
    this.dynamicArray[i].TaxAmount = (taxper / 100) * qty * rate;
    this.dynamicArray[i].TotalAmount = rate * qty + this.dynamicArray[i].TaxAmount;


    this.dynamicArray[i].TaxableAmount=rate*qty;
  }

  getValueform(rate: any, qty: any, taxper: any, i: any) {
    //do your stuff

    this.withouttax = rate * qty;




    // dynamic.TaxPercentage/100)*dynamic.Quantity*dynamic.Rate 
    this.TaxAmount = (taxper / 100) * qty * rate;
    this.TotalAmount = rate * qty + this.TaxAmount; 

    this.TaxableAmount=rate*qty;
  }
}





