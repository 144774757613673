 

<form class="form-horizontal" [formGroup]="form"> 
   
<div class="row" >
            <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">ROLE MENU MAPPING</h1>
<div class="p-d-flex p-ai-center p-jc-between">
    <div class="position-relative  form-group col-sm-3">
        <label for="RoleTypeID" class="col-form-label">Role</label>
        <div class="">
        

<p-dropdown [options]="rolelist"  [(ngModel)]="selectedRoleID" optionLabel="DeptName" [filter]="true" filterBy="DeptName" [showClear]="true" [placeholder]="selectedRoleName"  (onChange)="getMenuMappingListByRoleID($event)" name="RoleTypeID" id="RoleTypeID"     formControlName="RoleTypeID"  [style]="{ width: '100%' }" >
         
            <ng-template let-country pTemplate="item">
                <div class="country-item">
                    <div>{{country.RoleTypeName}}</div>
                </div>
            </ng-template>
        </p-dropdown>
            <app-field-error-display [displayError]="isFieldValid('RoleTypeID')" errorMsg="Please Select Role"   style="background-color: #791820;
            border-color: #f5c6cb;" class="alert-danger">
   </app-field-error-display> 
        </div>
    </div>
</div>

</div>
<div class="row">
    <div class="col m-3">
        <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 g-4">     
            <div class="col" *ngFor="let menulist of menumappinglist">
                  <div class="card border-secondary h-100">
                      <div class="card-header">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox-{{menulist.MenuID}}" (change)="onCheckboxChange($event,menulist,menulist.MenuID,'MAIN','ListView')" [checked]="menulist.ListView=='1'">
                            <label class="form-check-label" for="inlineCheckbox-{{menulist.MenuID}}">{{menulist.MenuName}}</label>
                        </div>
                      </div>
                      <div class="card-body text-secondary">        
                         <div class="d-flex justify-content-between" *ngFor="let submenulist of menulist.SubMenuList">
                              <div class="flex-grow-1">
                                  <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="checkbox" id="inlineCheckbox-{{submenulist.MenuID}}" (change)="onCheckboxChange($event,submenulist,submenulist.MenuID,'SUB','ListView')"  [checked]="submenulist.ListView=='1'">
                                      <label class="form-check-label" for="inlineCheckbox-{{submenulist.MenuID}}">{{submenulist.MenuName}}</label>
                                  </div>
                              </div>
                              <div>                                 
                                  <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="checkbox" id="inlineCheckbox-detail-{{submenulist.MenuID}}" (change)="onCheckboxChange($event,submenulist,submenulist.MenuID,'SUB','DetailView')" [checked]="submenulist.DetailView=='1'">
                                      <label class="form-check-label" for="inlineCheckbox-detail-{{submenulist.MenuID}}">Detail</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox-add-{{submenulist.MenuID}}" (change)="onCheckboxChange($event,submenulist,submenulist.MenuID,'SUB','InsertView')" [checked]="submenulist.InsertView=='1'">
                                    <label class="form-check-label" for="inlineCheckbox-add-{{submenulist.MenuID}}">Add</label>
                                </div>
                                  <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="checkbox" id="inlineCheckbox-update-{{submenulist.MenuID}}" (change)="onCheckboxChange($event,submenulist,submenulist.MenuID,'SUB','UpdateView')"  [checked]="submenulist.UpdateView=='1'">
                                      <label class="form-check-label" for="inlineCheckbox-update-{{submenulist.MenuID}}">Update</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox-status-{{submenulist.MenuID}}" (change)="onCheckboxChange($event,submenulist,submenulist.MenuID,'SUB','StatusUpdate')" [checked]="submenulist.StatusUpdate=='1'">
                                    <label class="form-check-label" for="inlineCheckbox-status-{{submenulist.MenuID}}">Status</label>
                                </div>
                              </div>
                         </div><!--eof -d-flex-->
                      </div><!--eof acrd body-->
                  </div> <!-- eof card-->        
            </div><!--eof col-->
        </div><!--eof row-->
    </div>
</div>




 





 

<div class="card" style="overflow-x: scroll;">

<a   style="margin-left:35%;margin-right:35%;"   class="btn btn-info" (click)="submitForm()"> Update </a>
</div>



 
</form>
 
<br>
<br>
<br>
<br>
 