import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoicegenerationprocessed',
  templateUrl: './invoicegenerationprocessed.component.html',
  styleUrls: ['./invoicegenerationprocessed.component.css']
})
export class InvoicegenerationprocessedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
