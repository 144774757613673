<!-- <p-progressSpinner strokeWidth="5" fill="#fff" 
animationDuration="1s" style="position: fixed;
left: 0px;
top: 0px;
width: 100%;
height: 100%;

margin-left: 43%;
margin-top: 20%;  
z-index: 9999;"  *ngIf="visibleloader" >  </p-progressSpinner> -->

<div *ngIf="visibleloader"
    style="position: fixed;left: 0px;top: 0px;width: 100%;height: 100%;margin-left: 37%;margin-top: 10%; z-index: 9999;">
    <img src="../../assets/images/loading.gif"></div>
<form class="form-horizontal" [formGroup]="form" (ngSubmit)="submit()" *ngFor="let data of products">


    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;">EDIT INVOICE</h1>
    <div class="card-body" style="background-color:#fff;margin-top: 0px; ">




        <h1 class="card-title">
            Vendor Details
        </h1>

        <div class="row">

            <div class="position-relative  form-group col-sm-6">
                <label for="VendorID" class="col-form-label">Vendor Name<span>*</span></label>


                <div class="">





                    <p-dropdown [options]="vendorlist" [(ngModel)]="data.VendorID" [autoDisplayFirst]="false"
                        optionLabel="VendorName" formControlName="VendorID" [placeholder]="data.VendorName"
                        optionValue="VendorID" [filter]="true" filterBy="VendorName">
                    </p-dropdown>

                    <!-- <span class="ui-inputgroup-addon"><i [ngClass]= "(selectedVendorId=='M'? 'fa fa-male':'fa fa-female')"></i></span> -->

                    <app-field-error-display [displayError]="isFieldValid('VendorID')"
                        errorMsg="Please Select Vendor Name" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>

                </div>
            </div>

            <div class="position-relative  form-group col-sm-6">
                <label for="City" class="col-form-label">Organization<span>*</span></label>
                <div class="">





                    <p-dropdown [options]="organizaionlist" [(ngModel)]="data.OrgID" [autoDisplayFirst]="false"
                        optionLabel="OrgName" formControlName="OrgID" [placeholder]="data.OrgName" optionValue="OrgID"
                        [filter]="true" (ngModelChange)="getDivisionByOrg($event)" filterBy="OrgName">
                    </p-dropdown>

                    <app-field-error-display [displayError]="isFieldValid('OrgID')"
                        errorMsg="Please Select Organization Name" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>

                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">



                <label for="DivID" class="col-form-label">Division Name<span>*</span></label>
                <div class="">



                    <!-- <select  class="form-control" name="DivID" id="DivID"     formControlName="DivID" (ngModelChange)="changeDivision($event)"  [ngModel]="data.DivID"> 
                            
                        <option [ngValue]="null" disabled selected >Please Select Division</option>
                    
                        <option *ngFor="let ddetail of divisionlist"      [value]="ddetail.DivID" >{{ddetail.DivName}}</option>
                    
    
                    </select>
     -->
                    <p-dropdown [options]="divisionlist" [(ngModel)]="data.DivID" [autoDisplayFirst]="false"
                        optionLabel="DivName" formControlName="DivID" [placeholder]="data.DivName" optionValue="DivID"
                        [filter]="true" (ngModelChange)="changeDivision($event)" filterBy="DivName">
                    </p-dropdown>



                    <app-field-error-display [displayError]="isFieldValid('DivID')"
                        errorMsg="Please Select Division Name" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>

                </div>
            </div>



            <div class="position-relative  form-group col-sm-6">
                <label for="DeptID" class="col-form-label">Department Name<span>*</span></label>


                <div class="">
 
                    <p-dropdown [options]="departmentlist" [(ngModel)]="data.DeptID" [autoDisplayFirst]="false"
                        optionLabel="DeptName" formControlName="DeptID" [placeholder]="data.DeptName"
                        optionValue="DeptID" [filter]="true" filterBy="DeptName">
                    </p-dropdown>


                    <app-field-error-display [displayError]="isFieldValid('DeptID')"
                        errorMsg="Please Select Deparment Name" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>

                </div>
            </div>






        </div>



    </div>

    <div class="card-body" style="background-color:#fff; ">
        <h1 class="card-title">
            Invoice Details
        </h1>
        <div class="row">


            <div class="position-relative  form-group col-sm-6">
                <label for="ItemCategoryID" class="col-form-label">Product Category<span>*</span></label>
                <div class="">
          

         
                    <p-dropdown [options]="itemcategorylist" [(ngModel)]="data.ItemCategoryID"
                    [autoDisplayFirst]="false" optionLabel="ItemCategoryName" formControlName="ItemCategoryID"
                    [placeholder]="data.ItemCategoryName" optionValue="ItemCategoryID" [filter]="true" (ngModelChange)="getItemByCategory($event)" 
                    filterBy="ItemCategoryName">
                </p-dropdown>  

                    <app-field-error-display [displayError]="isFieldValid('ItemCategoryID')"
                        errorMsg="Please Select Item Category ID" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>

                </div>
            </div>

            <div class="position-relative  form-group col-sm-6">
                <label for="ItemID" class="col-form-label">Product<span>*</span></label>
                <div class="">
                  
 
                    <p-dropdown [options]="itemlist" [(ngModel)]="data.ItemID" [autoDisplayFirst]="false"
                    optionLabel="ItemName" formControlName="ItemID" [placeholder]="data.ItemName"
                    optionValue="ItemID" [filter]="true" filterBy="ItemName">
                </p-dropdown>


                    <app-field-error-display [displayError]="isFieldValid('ItemID')" errorMsg="Please Select Item"
                        style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>

                </div>
            </div>

            <div class="position-relative  form-group col-sm-6">
                <label for="InvoiceForID" class="col-form-label">Payment Frequency<span>*</span></label>
                <div class="">
                   

                    <!-- <select class="form-control" name="InvoiceForID" id="InvoiceForID" formControlName="InvoiceForID"
                        [ngModel]="data.InvoiceForID">
                        <option [ngValue]="null" disabled selected>Please Select Invoice For</option>
                        <option *ngFor="let invfordetail of invforlist" [value]="invfordetail.InvoiceForID">
                            {{invfordetail.InvoiceForName}}</option>

                    </select> -->


                    <p-dropdown [options]="invforlist" [(ngModel)]="data.InvoiceForID" [autoDisplayFirst]="false"
                    optionLabel="InvoiceForName" formControlName="InvoiceForID" [placeholder]="data.InvoiceForName"
                    optionValue="InvoiceForID" [filter]="true" filterBy="InvoiceForName">
                </p-dropdown>

                    <app-field-error-display [displayError]="isFieldValid('InvoiceForID')"
                        errorMsg="Please Select Invoice For ID" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>

                </div>
            </div>

            <div class="position-relative form-group col-sm-6">
                <label for="InvoiceName" class="col-form-label">Remark<span>*</span></label>
                <div class="">
                    <input name="InvoiceName" id="InvoiceName" placeholder="Please Enter Invoice Name" type="text"
                        class="form-control" formControlName="InvoiceName" [(ngModel)]="data.InvoiceName">
                    <app-field-error-display [displayError]="isFieldValid('InvoiceName')"
                        errorMsg="Please Enter Invoice Name" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>
       

            <div class="position-relative  form-group col-sm-6">
                <label for="HeadID" class="col-form-label">Invoice Purpose<span>*</span></label>
                <div class="">
                  

          

                    <p-dropdown [options]="headlist" [(ngModel)]="data.InvoiceHeadItemID" [autoDisplayFirst]="false"
                    optionLabel="InvoiceHeadItemName" formControlName="HeadID" [placeholder]="data.InvoiceHeadItemName"
                    optionValue="InvoiceHeadItemID" [filter]="true" filterBy="InvoiceHeadItemName">
                </p-dropdown>

                    <app-field-error-display [displayError]="isFieldValid('HeadID')"
                        errorMsg="Please Select Head Category" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>

                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="VendorInvoiceNo" class="col-form-label">Vendor Invoice No <span>*</span></label>
                <div [ngClass]="displayFieldCss('VendorInvoiceNo')">


                    <input name="VendorInvoiceNo" id="VendorInvoiceNo" placeholder="Please Enter VendorInvoiceNo"
                        type="text" class="form-control" formControlName="VendorInvoiceNo"
                        [(ngModel)]="data.VendorInvoiceNo">

                    <app-field-error-display [displayError]="isFieldValid('VendorInvoiceNo')"
                        errorMsg="Please Enter VendorInvoiceNo" style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="InvoiceAttechmentURL" class="col-form-label">Attachment Invoice<span>*</span></label>
                <div class="">
                    <!-- <input type="file" name="InvoiceAttechmentURL" id="InvoiceAttechmentURL"   placeholder="Please Enter InvoiceAttechmentURL" class="form-control" formControlName="InvoiceAttechmentURL"> -->

                    <!-- <img [src]="imageUrl"  style="width:250px;height:200px"> -->


                    <a href="{{ImageUrl}}{{data.InvoiceAttechmentURL}}" title="Click Here to View" target="_blank"> <i
                            class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i></a>
                    &nbsp;&nbsp;
                    <span *ngIf="data.InvoiceAttechmentURL || data.InvoiceAttechmentURL==''" class="btn btn-sm btn-info"
                        style="color:white;background: #f1592a;border: 1px solid #f1592a;"
                        (click)="showinvattachmentfunction()" [hidden]="hideattachmentupdatebutton"> <i
                            class="fa fa-upload mr-1"></i> Update</span>
                    <input type="file" #Image accept="*" (change)="onFileChange($event)" name="InvoiceAttechmentURL"
                        id="InvoiceAttechmentURL" placeholder="Please Enter InvoiceAttechmentURL" class="form-control"
                        formControlName="InvoiceAttechmentURL" *ngIf="showinvoiceattachment">
                    <br>
                    <br>
                    <span *ngIf="closeinvattachment" class="btn btn-sm btn-primary"
                        style="color:white;background: #f1592a;border: 1px solid #f1592a;"
                        (click)="closeinvattachmentfunction()"> <i class="fa fa-close mr-1"></i>Close</span>

                    <app-field-error-display [displayError]="isFieldValid('InvoiceAttechmentURL')"
                        errorMsg="Please Enter  InvoiceAttechmentURL"  style="background-color: #f8d7da; border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>


            <div class="position-relative form-group col-sm-6">
                <label for="InvoiceDate" class="col-form-label">Date Of Invoice<span>*</span></label>
                <div class="">

                    <!-- {{data.InvoiceDate}} -->
                    <input name="InvoiceDate" id="InvoiceDate" placeholder="Please Enter InvoiceDate" type="text"
                        class="form-control" formControlName="InvoiceDate" [(ngModel)]="data.InvoiceDate"
                        min="1950-01-01" max="2030-12-31" onfocus="(this.type='date')" onfocusout="(this.type='text')">
                    <app-field-error-display [displayError]="isFieldValid('InvoiceDate')"
                        errorMsg="Please Enter InvoiceDate" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>



            <div class="position-relative  form-group col-sm-6">
                <label for="ValidityExpireOn" class="col-form-label">Validity ExpireOn<span>*</span></label>
                <div class="">
                    <input type="text" name="ValidityExpireOn" id="ValidityExpireOn"
                        placeholder="Please Enter Validity ExpireOn" class="form-control"
                        formControlName="ValidityExpireOn" [(ngModel)]="data.ValidityExpireOn" min="1950-01-01"
                        max="2030-12-31" onfocus="(this.type='date')" onfocusout="(this.type='text')">
                    <app-field-error-display [displayError]="isFieldValid('ValidityExpireOn')"
                        errorMsg="Please Enter  Validity ExpireOn" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>

 



            <div class="position-relative  form-group col-sm-6">
                <label for="WarrantyImp" class="col-form-label">Warranty/AMC/Any other imp. caption
                    <span>*</span></label>
                <div class="">
                    <input type="text" name="WarrantyImp" id="WarrantyImp" class="form-control"
                        placeholder="Please Enter Warranty Imp" formControlName="WarrantyImp"
                        [(ngModel)]="data.WarrantyImp">


                    <app-field-error-display [displayError]="isFieldValid('WarrantyImp')"
                        errorMsg="Please Enter  Warranty Imp" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>



            <div class="position-relative  form-group col-sm-6">
                <label for="DepttContactPerson" class="col-form-label">Dept. Contact Person<span>*</span></label>
                <div class="">
                    <input type="text" name="DepttContactPerson" id="DepttContactPerson"
                        placeholder="Please Enter DepttContactPerson" class="form-control"
                        formControlName="DepttContactPerson" [(ngModel)]="data.DepttContactPerson">


                    <app-field-error-display [displayError]="isFieldValid('DepttContactPerson')"
                        errorMsg="Please Enter  DepttContactPerson" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>

            <div class="position-relative  form-group col-sm-6">
                <label for="Priority" class="col-form-label">Priority<span>*</span></label>
                <div class="">

                   


    

                    <p-dropdown [options]="prioritylist" [(ngModel)]="data.Priority" [autoDisplayFirst]="false"
                    optionLabel="PriorityName" formControlName="Priority" [placeholder]="data.PriorityName"
                    optionValue="PriorityID" [filter]="true" filterBy="PriorityName">
                </p-dropdown>
                {{data.PriorityID}} 
                    <app-field-error-display [displayError]="isFieldValid('Priority')" errorMsg="Please Enter  Priority"
                        style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">

                        
                    </app-field-error-display>

                </div>
            </div>

            <div class="position-relative  form-group col-sm-6">
                <label for="ApprovalLetterAttachmentURL" class="col-form-label">Prior Approval
                    Letter<span>*</span></label>
                <div class="">

                    <!-- <i class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i> -->
                    <!-- <input type="file" #Image1 accept="*" (change)="onFileChange1($event)"    name="ApprovalLetterAttachmentURL" id="ApprovalLetterAttachmentURL"    class="form-control" formControlName="ApprovalLetterAttachmentURL"> -->

                    <a href="{{ImageUrl}}{{data.ApprovalLetterAttachmentURL}}" title="Click Here to View"
                        target="_blank"> <i class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i></a>
                    &nbsp;&nbsp;
                    <span *ngIf="data.InvoiceAttechmentURL || data.ApprovalLetterAttachmentURL==''"
                        class="btn btn-sm btn-info" style="color:white;background: #f1592a;border: 1px solid #f1592a;"
                        (click)="showapproveattachmentfunction()" [hidden]="hideapproveupdatebutton"><i
                            class="fa fa-upload mr-1"></i> Update</span>
                    <!-- <input type="file" #Image accept="*"    (change)="onFileChange($event)"    name="InvoiceAttechmentURL" id="InvoiceAttechmentURL"   placeholder="Please Enter InvoiceAttechmentURL" class="form-control" formControlName="InvoiceAttechmentURL" *ngIf="showinvoiceattachment"> -->
                    <input type="file" #Image1 accept="*" (change)="onFileChange1($event)"
                        name="ApprovalLetterAttachmentURL" id="ApprovalLetterAttachmentURL" class="form-control"
                        formControlName="ApprovalLetterAttachmentURL" *ngIf="showapproveattachment">

                    <br>
                    <br>
                    <span *ngIf="closeapprove" class="btn btn-sm btn-primary"
                        style="color:white;background: #f1592a;border: 1px solid #f1592a;"
                        (click)="closeapproveattachmentfunction()"><i class="fa fa-close  mr-1"></i>Close</span>







                    <app-field-error-display [displayError]="isFieldValid('ApprovalLetterAttachmentURL')"
                        errorMsg="Please Enter  ApprovalLetterAttachmentURL" style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>
        </div>
    </div>



    <div class="card-body" style="background-color:#fff; ">
        <h1 class="card-title">
            Payment Details
        </h1>
        <div class="row">


            <div class="position-relative  form-group col-sm-6">
                <label for="TotalCostWithoutGST" class="col-form-label">Total Cost Without GST(A)<span>*</span></label>
                <div class="">
                    <input type="number" name="TotalCostWithoutGST" id="TotalCostWithoutGST" #num1
                        placeholder="Please Enter TotalCostWithoutGST" class="form-control"
                        formControlName="TotalCostWithoutGST" [ngModel]="data.TotalCostWithoutGST"
                        (input)="resetfield()">


                    <app-field-error-display [displayError]="isFieldValid('TotalCostWithoutGST')"
                        errorMsg="Please Enter  TotalCostWithoutGSTe" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
            </div>

            <div class="position-relative  form-group col-sm-6">
                <label for="DiscountPercent" class="col-form-label">Discount Percent(B)<span>*</span></label>
                <div class="">
                    <input type="number" name="DiscountPercent" id="DiscountPercent" #num6
                        placeholder="Please Enter DiscountPercent" class="form-control"
                        formControlName="DiscountPercent" (input)="valueChangedI(num1.value,num6.value)"
                        [ngModel]="data.DiscountPercent">


                    <app-field-error-display [displayError]="isFieldValid('DiscountPercent')"
                        errorMsg="Please Enter  DiscountPercent" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
            </div>

            <div class="position-relative  form-group col-sm-6">
                <label for="DiscountAmount" class="col-form-label">Discount Amount(C=A*B/100)<span>*</span></label>
                <div class="">
                    <input type="text" readonly name="DiscountAmount" id="DiscountAmount" #num8
                        placeholder="Please Enter DiscountAmount" class="form-control" formControlName="DiscountAmount"
                        (ngModelChange)="valueChangedJ(num1.value,num8.value)" [ngModel]="data.DiscountAmount">


                    <app-field-error-display [displayError]="isFieldValid('DiscountAmount')"
                        errorMsg="Please Enter  DiscountAmount" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>

            <div class="position-relative  form-group col-sm-6">
                <label for="TotalAmountAfterDiscount" class="col-form-label">Discounted Cost
                    (D=A-C)<span>*</span></label>
                <div class="">
                    <input type="text" readonly name="TotalAmountAfterDiscount" id="TotalAmountAfterDiscount" #num9
                        placeholder="Please Enter TotalAmountAfterDiscount" class="form-control"
                        formControlName="TotalAmountAfterDiscount" [ngModel]="data.TotalAmountAfterDiscount">


                    <app-field-error-display [displayError]="isFieldValid('TotalAmountAfterDiscount')"
                        errorMsg="Please Enter  TotalAmountAfterDiscount" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>




            <div class="position-relative  form-group col-sm-6">
                <label for="TDSInPercent" class="col-form-label">TDS In Percent(E)<span>*</span></label>
                <div class="">
                    <input type="number" name="TDSInPercent" id="TDSInPercent" #num4
                        placeholder="Please Enter TDSInPercent" class="form-control" formControlName="TDSInPercent"
                        (input)="valueChangedF(num9.value,num4.value)" [ngModel]="data.TDSInPercent">


                    <app-field-error-display [displayError]="isFieldValid('TDSInPercent')"
                        errorMsg="Please Enter  TDSInPercent" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>

            <div class="position-relative  form-group col-sm-6">
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="TDSInAmount" class="col-form-label">TDS In Amount(F=D*E/100)<span>*</span></label>
                <div class="">
                    <input type="text" readonly name="TDSInAmount" id="TDSInAmount" #num5
                        placeholder="Please Enter TDSInAmount" class="form-control" formControlName="TDSInAmount"
                        (ngModelChange)="valueChangedG(num9.value,num5.value)" [ngModel]="data.TDSInAmount">


                    <app-field-error-display [displayError]="isFieldValid('TDSInAmount')"
                        errorMsg="Please Enter  TDSInAmount" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>

            <div class="position-relative  form-group col-sm-6">
                <label for="TotalAmountAfterTDS" class="col-form-label">Amount After TDS(G=D-F) <span>*</span></label>
                <div class="">
                    <input type="text" readonly name="TotalAmountAfterTDS" id="TotalAmountAfterTDS" #num7
                        placeholder="Please Enter Total Amount AfterTDS" class="form-control"
                        formControlName="TotalAmountAfterTDS" [ngModel]="data.TotalAmountAfterTDS">


                    <app-field-error-display [displayError]="isFieldValid('TotalAmountAfterTDS')"
                        errorMsg="Please Enter  TotalAmountAfterTDS" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>

            <div class="position-relative  form-group col-sm-6">
                <label for="IGSTPer" class="col-form-label">IGST in Percent(H)<span>*</span></label>
                <div class="">
                    <!-- <input type="number" name="IGSTPer"   #num2   placeholder="Please Enter GST Per" class="form-control" (input)="valueChangedA(num9.value,num2.value)"  formControlName="IGSTPer" >  -->


                    <select name="IGSTPer" id="IGSTPer" #num2 class="form-control" formControlName="IGSTPer"
                        (ngModelChange)="valueChangedA(num9.value,num2.value)" [ngModel]="data.IGSTPer">
                        <!-- <option [ngValue]="null" disabled selected >Please Select Gst</option> -->
                        <option *ngFor="let gstdetails of gstdetail" [value]="gstdetails">{{gstdetails}}</option>

                    </select>


                    <!-- 
                             <app-field-error-display [displayError]="isFieldValid('IGSTPer')" errorMsg="Please Enter  IGST Percent"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display> -->
                </div>
            </div>
            <div class="position-relative  form-group col-sm-6">
            </div>
            <div class="position-relative  form-group col-sm-6">
                <label for="CGSTPer" class="col-form-label">CGST in Percent(I)<span>*</span></label>
                <div class="">
                    <input type="number" name="CGSTPer" readonly #num21 placeholder="Please Enter GST Per"
                        class="form-control" (ngModelChange)="valueChangedgsti(num9.value,num21.value)"
                        formControlName="CGSTPer" [ngModel]="data.CGSTPer">


                    <app-field-error-display [displayError]="isFieldValid('CGSTPer')"
                        errorMsg="Please Enter  SGST Percent" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>



            <div class="position-relative  form-group col-sm-6">
                <label for="SGSTPer" class="col-form-label">SGST in Percent(J)<span>*</span></label>
                <div class="">
                    <input type="number" name="SGSTPer" readonly #num22 placeholder="Please Enter GST Per"
                        class="form-control" (ngModelChange)="valueChangedgstj(num9.value,num22.value)"
                        formControlName="SGSTPer" [ngModel]="data.SGSTPer">


                    <app-field-error-display [displayError]="isFieldValid('SGSTPer')"
                        errorMsg="Please Enter  SGST Percent" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>






            <div class="position-relative  form-group col-sm-6">
                <label for="IGSTAmount" class="col-form-label">Total IGST Amount(K=D*H/100)<span>*</span></label>
                <div class="">
                    <input type="text" readonly name="IGSTAmount" id="IGSTAmount" #num3
                        placeholder="Please Enter IGSTAmount" class="form-control" formControlName="IGSTAmount"
                        [ngModel]="data.IGSTAmount">


                    <app-field-error-display [displayError]="isFieldValid('IGSTAmount')"
                        errorMsg="Please Enter  IGSTAmount" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>


            <div class="position-relative  form-group col-sm-6">
                <label for="CGSTAmount" class="col-form-label">Total CGST Amount(L=D*I/100)<span>*</span></label>
                <div class="">
                    <input type="text" readonly name="CGSTAmount" id="CGSTAmount" #num31
                        placeholder="Please Enter Total CGST Amount" class="form-control" formControlName="CGSTAmount"
                        [ngModel]="data.CGSTAmount">


                    <app-field-error-display [displayError]="isFieldValid('CGSTAmount')"
                        errorMsg="Please Enter  Total CGST Amount" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>



            <div class="position-relative  form-group col-sm-6">
                <label for="SGSTAmount" class="col-form-label">Total SGST Amount(M=D*J/100)<span>*</span></label>
                <div class="">
                    <input type="text" readonly name="SGSTAmount" id="SGSTAmount" #num32
                        placeholder="Please Enter Total SGST Amount" class="form-control" formControlName="SGSTAmount"
                        (ngModelChange)="valueChangedD(num7.value,num31.value, num32.value)"
                        [ngModel]="data.SGSTAmount">


                    <app-field-error-display [displayError]="isFieldValid('SGSTAmount')"
                        errorMsg="Please Enter  Total SGST Amount" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>





            <div class="position-relative  form-group col-sm-6">
                <label for="TotalPayableAmount" class="col-form-label">Total Amt Payable to
                    Vendor(N=G+L+M)<span>*</span></label>
                <div class="">
                    <input type="text" readonly name="TotalPayableAmount" #numn123 id="TotalPayableAmount"
                        placeholder="Please Enter TotalPayableAmount" class="form-control"
                        formControlName="TotalPayableAmount"
                        (ngModelChange)="valueChangeddiscountreceive(numn123.value,num123.value, num8.value)"
                        [ngModel]="data.TotalPayableAmount">


                    <app-field-error-display [displayError]="isFieldValid('TotalPayableAmount')"
                        errorMsg="Please Enter  TotalPayableAmount" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>



            <div class="position-relative  form-group col-sm-6">
                <label for="TDSInAmountToGovt" class="col-form-label">TDS Payable of Behalf of Vendor to
                    govt(O=F)<span>*</span></label>
                <div class="">
                    <input type="text" readonly name="TDSInAmountToGovt" #num123 id="TDSInAmountToGovt"
                        placeholder="Please Enter Total Payable Amount" class="form-control"
                        formControlName="TDSInAmountToGovt" [ngModel]="data.TDSInAmountToGovt">


                    <app-field-error-display [displayError]="isFieldValid('TDSInAmountToGovt')"
                        errorMsg="Please Enter  Amount To Govt " style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>










            <!-- <div class="position-relative  form-group col-sm-6">
                        <label for="DiscountPercent" class="col-form-label">Discount Received (P=C)<span>*</span></label>
                        <div class=""> 
                            <input type="number"  readonly name="DiscountAmount" id="DiscountAmount"  #discountrec    placeholder="Please Enter DiscountAmount" class="form-control" formControlName="DiscountAmount"    >
        
        
                             <app-field-error-display [displayError]="isFieldValid('DiscountAmount')" errorMsg="Please Enter  DiscountAmount"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div> -->









            <div class="position-relative  form-group col-sm-6">
                <label for="TotalInvoiceValue" class="col-form-label">Total Invoice Value
                    (Q=N+O+P)<span>*</span></label>
                <div class="">
                    <input type="number" readonly name="TotalInvoiceValue" id="TotalInvoiceValue" #num10
                        placeholder="Please Enter Total Invoice Value" class="form-control"
                        formControlName="TotalInvoiceValue" [ngModel]="data.TotalInvoiceValue">


                    <app-field-error-display [displayError]="isFieldValid('TotalInvoiceValue')"
                        errorMsg="Please Enter  TotalInvoiceValue" style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>
            <!-- <div class="position-relative  form-group col-sm-6">
                        <label for="PreferdablePaymentMode" class="col-form-label">PreferdablePaymentMode<span>*</span></label>
                        <div class="">
                            <input type="text" name="PreferdablePaymentMode" id="PreferdablePaymentMode"   placeholder="Please Enter PreferdablePaymentMode" class="form-control" formControlName="PreferdablePaymentMode">
        
        
                             <app-field-error-display [displayError]="isFieldValid('PreferdablePaymentMode')" errorMsg="Please Enter  PreferdablePaymentMode"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div> -->

            <!-- <div class="position-relative  form-group col-sm-6">
                        <label for="TDSCertificateAttachmentURL" class="col-form-label">TDSCertificateAttachmentURL<span>*</span></label>
                        <div class="">
                            <input type="file" name="TDSCertificateAttachmentURL" id="TDSCertificateAttachmentURL"   placeholder="Please Enter TDSCertificateAttachmentURL" class="form-control" formControlName="TDSCertificateAttachmentURL">
        
        
                             <app-field-error-display [displayError]="isFieldValid('TDSCertificateAttachmentURL')" errorMsg="Please Enter  TDSCertificateAttachmentURL"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div> 


                    <div class="position-relative  form-group col-sm-6">
                        <label for="AccountNo" class="col-form-label">AccountNo<span>*</span></label>
                        <div class="">
                            <input type="text" name="AccountNo" id="AccountNo"   placeholder="Please Enter AccountNo" class="form-control" formControlName="AccountNo">
        





                            
        
                             <app-field-error-display [displayError]="isFieldValid('AccountNo')" errorMsg="Please Enter  AccountNo"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                   



                    <div class="position-relative  form-group col-sm-6">
                        <label for="Department" class="col-form-label">Department<span>*</span></label>
                        <div class="">
                            <input type="text" name="Department" id="Department"   placeholder="Please Enter Department" class="form-control" formControlName="Department">
        
        
                             <app-field-error-display [displayError]="isFieldValid('Department')" errorMsg="Please Enter  Department"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                   


                    <div class="position-relative  form-group col-sm-6">
                        <label for="IRNNo" class="col-form-label">IRNNo<span>*</span></label>
                        <div class="">
                            <input type="text" name="IRNNo" id="IRNNo"   placeholder="Please Enter IRNNo" class="form-control" formControlName="IRNNo">
        
        
                             <app-field-error-display [displayError]="isFieldValid('IRNNo')" errorMsg="Please Enter  IRNNo"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                   


                    <div class="position-relative  form-group col-sm-6">
                        <label for="Scheme" class="col-form-label">Scheme<span>*</span></label>
                        <div class="">
                            <input type="text" name="Scheme" id="Scheme"   placeholder="Please Enter Scheme" class="form-control" formControlName="Scheme">
        
        
                             <app-field-error-display [displayError]="isFieldValid('Scheme')" errorMsg="Please Enter  Scheme"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="DayRemaining" class="col-form-label">Day Remaining <span>*</span></label>
                        <div class="">
                            <input type="number" name="DayRemaining" id="DayRemaining" class="form-control"   placeholder="Please Enter DayRemaining." formControlName="DayRemaining">
                            <app-field-error-display [displayError]="isFieldValid('DayRemaining')" errorMsg="Please Enter  Day Remaining"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div> -->

            <!-- <div class="position-relative  form-group col-sm-6">
                    <label for="UserID" class="col-form-label">User<span>*</span></label>
                    <div class="">
                       
                        <select  class="form-control" name="UserID" id="UserID"   (change)="changeOrganization($event)"  formControlName="UserID"> 
                            
                             
                            <option [ngValue]="null" disabled selected >Choose User</option>
                             
                            <option value="1">Test</option>
                        
                        </select>
                    
                    
                        <app-field-error-display [displayError]="isFieldValid('UserID')" errorMsg="Please Select User"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div> -->









            <!-- <div class="position-relative  form-group col-sm-6">
                    <label for="PaymentPriorityID" class="col-form-label">Payment Priority ID<span>*</span></label>
                    <div class="">
                        <input type="text" name="PaymentPriorityID" id="PaymentPriorityID" placeholder="Please Enter Valid  PaymentPriorityID"  class="form-control" formControlName="PaymentPriorityID">
         
                        <app-field-error-display [displayError]="isFieldValid('PaymentPriorityID')" errorMsg="Please Enter PaymentPriorityID"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div> -->
















            <!-- <div class="position-relative  form-group col-sm-6">
                    <label for="InvoiceHeadId" class="col-form-label">Invoice Head Id</label>
                    <div class="">
                        <input type="text" name="InvoiceHeadId"   placeholder="Please Enter  PinCode"  id="InvoiceHeadId" class="form-control" formControlName="InvoiceHeadId">
        
                        <app-field-error-display [displayError]="isFieldValid('InvoiceHeadId')" errorMsg="Please Enter Invoice Head Id"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div> -->










            <div class="position-relative  form-check col-sm-6 offset-sm-6 ">
                <div class="">

                    <br>
                    <br>
                    <br>
                    <button class="btn btn-primary btn-lg" type="submit">Submit</button>
                </div>
            </div>

        </div>