 
    <form class="form-horizontal" [formGroup]="form">

        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">DEPARTMENT REGISTRATION</h1>
        <div class="card-body" style="background-color:#fff; ">
        
        
        
              
            <h1 class="card-title">
                Department Detail
            </h1>
         
        <div class="row">
                <div class="position-relative  form-group col-sm-6">
                    <label for="DeptName" class="col-form-label">Department Name <span>*</span></label>
                    <div   [ngClass]="displayFieldCss('DeptName')">
                     
                     
                        <input name="DeptName" id="DeptName" placeholder="" type="text" class="form-control" formControlName="DeptName">
                    
                        <app-field-error-display [displayError]="isFieldValid('DeptName')" errorMsg="Please Enter Department"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="OrgID" class="col-form-label">Organization Name<span>*</span></label>
                    <div class="">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
          
                        <!-- <select  class="form-control" name="OrgID" id="OrgID"   (change)="changeOrganization($event.target.value)"  formControlName="OrgID"> 
                            
                             
                            <option value="" disabled selected >Choose Your Organizaion</option>
                           
                            <option *ngFor="let list of organizaionlist"   [value]="list.OrgID" >{{list.OrgName}}</option>
                        
                        </select> -->
                        <p-dropdown [options]="organizaionlist" [(ngModel)]="selectedOrgId" optionLabel="OrgName" [filter]="true" filterBy="OrgName"
                        [showClear]="true" [placeholder]="selectedOrgName"  (onChange)="getDivisionByOrg($event)" name="OrgID" id="OrgID"      formControlName="OrgID"  [style]="{ width: '100%' }" >
                     
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.OrgName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    
                        <app-field-error-display [displayError]="isFieldValid('OrgID')" errorMsg="Please Select Organization Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
        


                <div class="position-relative  form-group col-sm-6">
                    <label for="DivID" class="col-form-label">Division Name<span>*</span></label>
                    <div class="">
                      
        
                        <!-- <select  class="form-control" name="DivID" id="DivID"  formControlName="DivID">
                            <option value="" disabled selected >Choose Your Division</option>
                            
                      
                            <option *ngFor="let list of divisionlist" [ngValue]="list.DivID">{{list.DivName}}</option>
                        
                        
                        </select> -->
                    
                <p-dropdown [options]="divisionlist"  [(ngModel)]="selectedDiv" optionLabel="DivName" [filter]="true" filterBy="DivName"
                        [showClear]="true" [placeholder]="selectedDivName"  (onChange)="changeDivision($event)" name="DivID" id="DivID"     formControlName="DivID"  [style]="{ width: '100%' }" >
                     
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <div>{{country.DivName}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>  
                        <app-field-error-display [displayError]="isFieldValid('DivID')" errorMsg="Please Select Division Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
                 
        
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="HODName" class="col-form-label">HOD Name<span>*</span></label>
                    <div class="">
                        <input type="text" name="HODName" id="HODName" class="form-control" formControlName="HODName">
        
                        <app-field-error-display [displayError]="isFieldValid('HODName')" errorMsg="Please Enter Name Of HOD"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            </div>
        
           
        
        </div>
        
            <div class="card-body" style="background-color:#fff; ">
                <h1 class="card-title">
                    Contact Detail
                </h1>
            <div class="row">
                <div class="position-relative form-group col-sm-6">
                    <label for="HODMobileNo" class="col-form-label">HOD Contact Number<span>*</span></label>
                    <div class="">
                        <input name="HODMobileNo" id="HODMobileNo" placeholder="" type="number" class="form-control" formControlName="HODMobileNo">
                        <app-field-error-display [displayError]="isFieldValid('HODMobileNo')" errorMsg="Please Enter Contact Number"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="HODEmailID" class="col-form-label">HOD Email<span>*</span></label>
                    <div class="">
                        <input type="email" name="HODEmailID" id="HODEmailID" class="form-control" formControlName="HODEmailID">
         
                        <app-field-error-display [displayError]="isFieldValid('HODEmailID')" errorMsg="Please Enter Email Id"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                
        
                
                 
                <div class="position-relative  form-group col-sm-6">
                    <label for="ActiveStatus" class="col-form-label">Active Status<span>*</span></label>
                    <div class="">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <select  class="form-control" name="ActiveStatus" id="ActiveStatus"  formControlName="ActiveStatus">
                            <option [ngValue]="null" disabled selected >Select Status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    
                    
                        <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Select Status"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
                
                <div class="position-relative  form-check col-sm-6 offset-sm-6 ">
                    <div class="">
    
                        <br>
                        <br>
                        <br>
                        <button class="btn btn-primary btn-lg" (click)="onSubmit()">Submit</button>
                    </div>
                </div>  
                <div class="position-relative  form-check col-sm-12 ">
                    <br>
                    <br>
                    <br>
     <h2 style="text-align: center;color: #791820;"> Note: User Registration is complusary For Each Department </h2>
                    
                </div>  
                
                </div>
                </div>
        
                   
         