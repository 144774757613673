
<div  class="card-body" style="background-color:#fff;"   id="content"  #content>

<div class='container'>
    <div class='row'>
        <div class='col-sm-12'>
            <h3 style='text-align:right'> Pro Forma Invoice </h3>
        </div>
    </div>
</div>
<!-- <h3 style='color:#f33f06'><b>{{DetailSaleInvoice.VendorName}}</b></h3> -->
<table style='width:100%'>
    <tr>
        <td>

            <h1>{{DetailSaleInvoice[0].FromDivName}} </h1>
            {{DetailSaleInvoice[0].FromDeptName}} <br>
            {{DetailSaleInvoice[0].FromDivBillingAddress}},<br>{{DetailSaleInvoice[0].FromCity}},
            {{DetailSaleInvoice[0].FromDivPinCode}},
            {{DetailSaleInvoice[0].FromStateName}} <br>
            {{DetailSaleInvoice[0].FromDivDeskEmailID}},{{DetailSaleInvoice[0].FromDivDeskMobileNo}} <br>
            GSTIN- {{DetailSaleInvoice[0].FromDivGSTN}} <br>
            PAN NO - {{DetailSaleInvoice[0].FromDivGSTN}}



        </td>
        <td style='text-align:right'><img src='{{DetailSaleInvoice[0].FromOrgLogoURL}} ' title='LOGO' alt='LOGO' /></td>
    </tr>
</table>
<div style='border-bottom:7px inset;width:100%;border-color:#a5a0a0'></div><br>
<table style='width:100%'>
    <tr>

        <th style='text-align:left' width='50%'><strong>Invoice No : </strong>{{DetailSaleInvoice[0].SaleInvoiceNumber}}
        </th>
        <th style='text-align:left' width='25%'><strong>Invoice Date : </strong>{{DetailSaleInvoice[0].SaleInvoiceDate| date :'d/M/Y'}}
        </th>
        <th style='text-align:right' width='25%'><strong>Due Date  : </strong>{{DetailSaleInvoice[0].SaleInvoiceDate| date :'d/M/Y'}}</th>
    </tr>
    <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr  >
        <!-- {{DetailSaleInvoice[0].SaleTo}} -->
        <td  *ngIf="DetailSaleInvoice[0].SaleTo=='Organization'" ><strong>BILL TO : </strong><br>{{DetailSaleInvoice[0].ToDivName}}<br>{{DetailSaleInvoice[0].ToDeptName}}<br>{{toAddress[0]}} <br>City: {{toAddress[1]}}<br>State: {{toAddress[2]}}<br>
        </td>
        <td *ngIf="DetailSaleInvoice[0].SaleTo=='Organization'" >GSTIN/UIN : {{toAddress[7]}}<br>State Name : {{toAddress[2]}},<br>Code : {{toAddress[3]}}</td>
        <td  *ngIf="DetailSaleInvoice[0].SaleTo=='Vendor'" ><strong>BILL TO/SHIP TO : </strong><br>{{DetailSaleInvoice[0].ToVendorName}}<br>{{toAddress[0]}} <br>City: {{toAddress[1]}}<br>State: {{toAddress[2]}}<br>
        </td>
        <td *ngIf="DetailSaleInvoice[0].SaleTo=='Vendor'" >GSTIN/UIN : {{toAddress[7]}}<br>State Name : {{toAddress[2]}},<br>Code : {{toAddress[3]}}</td>
     
       
        <td style='text-align:right'>
            <!-- <img src='13' title='Bill QR' alt='Bill QR' width='150px;' height='150px;' /> -->
            <qrcode [qrdata]="InvoiceDetailData" [allowEmptyString]="true"
                [ariaLabel]="'QR Code image with the following content...'" [cssClass]="'center'"
                [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'"  [elementType]="'img'"
                [errorCorrectionLevel]="'M'" [imageSrc]="'./assets/angular-logo.png'" [imageHeight]="75"
                [imageWidth]="75" [margin]="1" [scale]="1" [title]="'A custom title attribute'" [width]="100"></qrcode>

        </td>
    </tr>
</table><br>
<div style='border-bottom:7px inset;width:100%;border-color:#080808'></div><br>
<table cellspacing='0' style='width:100%'>
    <tr style='background-color:#BEBDBD;'>
        <th><strong>S.NO.</strong></th>
        <th style='text-align:left'><strong>ITEMS/ DESCRIPTION</strong></th>
        <th style='text-align:left'><strong>HSN/ SAC</strong></th>
        <th style='text-align:left'><strong>QUANTITY</strong></th>
        <th style='text-align:left'><strong>MRP</strong></th>
        <th style='text-align:left'><strong>RATE</strong></th>
        <th style='text-align:left'><strong>TAX</strong></th>
        <th style='text-align:left'><strong>AMOUNT</strong></th>
    </tr>
    <tr *ngFor="let itemdetail  of itemdetails let i=index;"  style="line-height: 24px;">

        <td>{{i+1}}</td>
        <td>{{itemdetail.ProductName}}</td>
        <td>{{itemdetail.Description}}</td>
        <td>{{itemdetail.SaleQuantity}}</td>
        <td >{{itemdetail.MRP}}</td>
        <td>{{itemdetail.SalePrice}}</td>

        <td>{{itemdetail.TaxAmount}}</td>
        <td >{{itemdetail.TotalAmount}}</td>
         



    </tr>


    <tr style='border-bottom:9px inset;border-top: 2px inset #a5a0a0;'>
        <td class='text-center'>Subtotal</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td><strong>{{DetailSaleInvoice[0].TaxAmountGrandTotal}}</strong></td>
        <td style='text-align:left'>{{DetailSaleInvoice[0].PayableAmount}}</td>
    </tr>
</table>
<div style='border-bottom:7px inset;width:100%;border-color:#080808'></div><br>
<table style='width:100%'>
    <tr>
        <td style='text-align:left;vertical-align:top' width='60%'>
            <table>
                <tr>
                    <td><strong>Bank Account Details : </strong></td>
                </tr>
                <tr>
                    <td>Bank Name : {{DetailSaleInvoice[0].FromDivBankName}}</td>
                </tr>
                <tr>
                    <td>Account No. : {{DetailSaleInvoice[0].FromDivBankAcNo}}</td>
                </tr>
                <tr>
                    <td>IFSC Code : {{DetailSaleInvoice[0].FromDivIFSCCode}}</td>
                </tr>
                <tr>
                    <td>Branch : {{DetailSaleInvoice[0].FromDivBankBranchName}}</td>
                </tr>
                <tr>
                    <td>


                        <!-- <img src='36' title='Bank Account QR' alt='Bank Account QR' width='120px;' height='120px;' /> -->

                        <qrcode [qrdata]="bankdetail" [allowEmptyString]="true"
                            [ariaLabel]="'QR Code image with the following content...'" [cssClass]="'center'"
                            [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'"    [elementType]="'img'"
                            [errorCorrectionLevel]="'M'" [imageSrc]="'./assets/angular-logo.png'" [imageHeight]="75"
                            [imageWidth]="75" [margin]="1" [scale]="1" [title]="'A custom title attribute'"
                            [width]="100"></qrcode>  

                    </td>
                </tr>
                <tr>
                    <td></td>
                </tr>
            </table>
        </td>
        <td>
            <table width='100%'>
                <tr>
                    <td  width='60%'><b>TAXABLE AMOUNT</b></td>
                    <td style='text-align:right'><b>Rs. {{DetailSaleInvoice[0].TaxableAmountGrandTotal}}</b></td>
                </tr>
                <tr>
                    <td colspan='2' style='border-bottom:2px solid #a59e9e'></td>
                </tr>
                <tr>
                    <td >CGST@{{DetailSaleInvoice[0].TaxAmountGrandTotal/2}}%</td>
                    <td style='text-align:right'>Rs. {{DetailSaleInvoice[0].TaxAmountGrandTotal/2 }}</td>
                </tr>
                <tr>
                    <td colspan='2' style='border-bottom:2px solid #a59e9e'></td>
                </tr>
                <tr>
                    <td >SGST@{{DetailSaleInvoice[0].TaxAmountGrandTotal/2 }}%</td>
                    <td style='text-align:right'>Rs. {{DetailSaleInvoice[0].TaxAmountGrandTotal/2  }}</td>
                </tr>
                <tr>
                    <td colspan='2' style='border-bottom:2px solid #a59e9e'></td>
                </tr>
                <tr>
                    <td >FC</td>
                    <td style='text-align:right'>Rs. {{DetailSaleInvoice[0].FrightChargeAmount}}</td>
                </tr>
                <tr>
                    <td colspan='2' style='border-bottom:2px solid #a59e9e'></td>
                </tr>
                <tr>
                    <td >Discount</td>
                    <td style='text-align:right'>Rs. - {{DetailSaleInvoice[0].DiscountAmount}}</td>
                </tr>
                <tr>
                    <td colspan='2' style='border-bottom:2px solid #a59e9e'></td>
                </tr>
                <tr>
                    <td ><strong><b>TOTAL</b></strong></td>
                    <td style='text-align:right'>Rs. {{DetailSaleInvoice[0].GrandTotal}}</td>
                </tr>
                <tr>
                    <td colspan='2' style='border-bottom:2px solid #a59e9e'></td>
                </tr>
                <tr>
                    <td >Received Amount</td>
                    <td style='text-align:right'>Rs. {{DetailSaleInvoice[0].ReceivedAmount}}</td>
                </tr>
                <tr>
                    <td colspan='2' style='border-bottom:2px solid #a59e9e'></td>
                </tr>
                <tr>
                    <td >BALANCE</td>
                    <td style='text-align:right'>Rs. {{DetailSaleInvoice[0].BalanceAmount}}</td>
                </tr>
                <tr>
                    <td colspan='2' style='border-bottom:2px solid #a59e9e'></td>
                </tr>
                <tr>
                    <td >Previous Balance</td>
                    <td style='text-align:right'>Rs. 0</td>
                </tr>
                <tr>
                    <td colspan='2' style='border-bottom:2px solid #a59e9e'></td>
                </tr>
                <tr>
                    <td >Current Balance</td>
                    <td style='text-align:right'>Rs. {{DetailSaleInvoice[0].BalanceAmount}}</td>
                </tr>
                <tr>
                    <td colspan='2' style='border-bottom:2px solid #a59e9e'></td>
                </tr>
            </table>
        </td>
    </tr>
    <tr>
        <td></td>
        <td style='text-align:right'><strong>Invoice Amount (in
                words)</strong><br>{{inword}}</td>
    </tr>
    <tr>
        <td height='10px;'></td>
    </tr>
    <tr>
        <td height='10px;'></td>
    </tr>
    <tr>
        <td></td>
        <td style='text-align:right'><strong>AUTHORISED SIGNATORY FOR</strong></td>
    </tr>
    <tr>
        <td></td>
        <td style='text-align:right'> {{DetailSaleInvoice[0].FromDivName}}</td>
    </tr>
</table>

</div>


<br>
<br>

<div  style="text-align: center;"> <button class="btn btn-info noprint" id="printbtn"  (click)="print_page(DetailSaleInvoice[0].SaleInvoiceNumber)">Print&nbsp;&nbsp;<i class="fa fa-print" style="font-size:32px"></i></button></div>
  
    

<!-- https://stackblitz.com/edit/angular-ivy-phgr36?file=src%2Fapp%2Fapp.component.html -->