import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
// import { jsPDF } from 'jspdf-invoice-template';
// import toDataURL from 'canvas-background';
// import html2canvas from 'html2canvas';
// import { jsPDF } from "jspdf";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-generateinvoice',
  templateUrl: './generateinvoice.component.html',
  styleUrls: ['./generateinvoice.component.css']
})
export class GenerateinvoiceComponent implements OnInit {

  visibleloader:any=true;
@ViewChild('content',{static:false})el!: ElementRef;
  constructor() { }

  ngOnInit(): void {
  }



  
 
  public convertToPDF()
  {
  let data = document.getElementById("content"); 
  html2canvas(data).then(canvas => {
   const contentDataURL = canvas.toDataURL('image/png')
  let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
  var width = pdf.internal.pageSize.getWidth();
  var height = canvas.height * width / canvas.width;
  pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height)
  pdf.save('AISECT-202205-LVT-2.pdf'); // Generated PDF
  });
  }

}
