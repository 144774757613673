import { Component, OnInit,ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';

import { Router } from '@angular/router';
@Component({
  selector: 'app-category-registration',
  templateUrl: './category-registration.component.html',
  styleUrls: ['./category-registration.component.css']
})
export class CategoryRegistrationComponent implements OnInit {
  //Create FormGroup 


  // organizaionlist: Billdesk[];
  statekey:any;
  stateList:Billdesk[];

  cityList:Billdesk[];


  type: string;
  constructor(private fb: FormBuilder,private http:HttpClient,private route : Router,private billdeskService:BilldeskService,private elementRef: ElementRef) {
 
//  this.billdeskService.getOrganization().then(res => this.organizaionlist = res);
    this.billdeskService.getState().then(res => this.stateList = res);
 
  }
  
  form: FormGroup;
  OrgID:any;
  Key:any;
  
  ngOnInit() {
    this.form = this.fb.group({ 
      
      ItemCategoryName: [null, Validators.required],
      CategoryDisplayName:[null, Validators.required],
      Description: [null, Validators.required],
      // ,
      
      // CategoryIcon: [null, Validators.required],
      
      // ImageUrl: [null, Validators.required],
      // CategoryUrl:[null, Validators.required],
      // CategoryImageUrl: [null, Validators.required],
      
      // Comments: [null, Validators.required],


      ActiveStatus: [null, Validators.required]
      // OrderBy: [null, Validators.required],
      // EstStartDate: [null, Validators.required],
      
      // EstEndDate: [null, Validators.required],
      // ActualStartDate: [null, Validators.required],
      // ActualEndDate: [null, Validators.required] 
    });
  }
 
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
 
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  changeOrganization(e) {
    this.OrgID.setValue(e.target.value, {
      onlySelf: true
    })
  }

  changeState(e) {
  this.billdeskService.getCity(20).then(res => this.cityList = res);
  }

  
 
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => { 
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset(){
    this.form.reset();
  }



  onSubmit() {
    var obj1 = 
   {
       
"flag":"MSTItemCatagory_INSERT",
"ItemCategoryID":"0",
"UpdatedBy":"1", 
"UpdatedOn":"10/22/2021", 
"CreatedBy":"1", 
"CreatedOn":"", 
"CategoryIcon": "",
      
"ImageUrl": "",
"CategoryUrl":"",
"CategoryImageUrl": "",
"Comments": "",


"ActiveStatus": "",
"OrderBy": "",
"EstStartDate": "",
  
"EstEndDate": "",
"ActualStartDate": "",
"ActualEndDate": "" 
   };
var obj2 = this.form.value;
var form_data = Object.assign(obj1, obj2);
 
const datarequest={
 "CheckSum": "",
  "lead_id": "",
"ActionFor": "Insert",            
"dataobject":[
 
 form_data

]
}
;
 

if (this.form.valid) { 
  let registerurl= "/itemcategory/itemcategoryregistration";
this.billdeskService.RegisterData(registerurl,datarequest).subscribe((res:any)=>{
  
  if(res.itemcategoryregistrationResult.Message=='Sucess'){
   
   this.route.navigate(['/Category-List'])
   localStorage.setItem('insertdata', 'Success');
 }else{
  //  console.log('Please Try Again Later');
 }
 });


} else {
  this.validateAllFormFields(this.form);
}
 }


 }
 