import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PurchaseListComponent } from '../purchase-list/purchase-list.component';

import { BrandListComponent } from '../brand-list/brand-list.component';
import { RoleGuard } from 'src/app/guard/role.guard';
import { PriceTypeListComponent } from '../price-type-list/price-type-list.component';
import { UnitListComponent } from '../unit-list/unit-list.component';
 


import { EditBrandComponent } from '../edit-brand/edit-brand.component';

import { BrandEntryComponent } from '../brand-entry/brand-entry.component';
import { UnitEntryComponent } from '../unit-entry/unit-entry.component';
import { PriceTypeEntryComponent } from '../price-type-entry/price-type-entry.component';
import { PurchaseEntryComponent } from '../purchase-entry/purchase-entry.component';
import { SalesListComponent } from '../sales-list/sales-list.component';
import { SalesEntryComponent } from '../sales-entry/sales-entry.component';
import { StockListComponent } from '../stock-list/stock-list.component';
import { ExportStockComponent } from '../export-stock/export-stock.component';
import { InstocksummaryComponent } from '../instocksummary/instocksummary.component';
const routes: Routes = [ ];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryManagementRoutingModule { }





