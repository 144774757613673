 <div class="container">


    <div class="row"> 
         <div class="col-md-12">
             <input type="text" class="form-control"  [(ngModel)]="filteredString"/>
             </div>
    </div>

    <div class="row">


        <div class="col-md-12">


            <div *ngFor="let user of users" class="my-3">

                <div>Name: {{user.name|shorten:10}}</div>
                
                <div>Joined Date: {{user.joinedDate|date:'fullDate'|uppercase}}</div>
            </div>
        </div>
    </div>
 </div>