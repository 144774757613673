import { Component, OnInit, Inject } from '@angular/core';
import { Billdesk } from '../billdesk';
import { BilldeskService } from '../billdesk.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer";

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Router } from '@angular/router';
export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor: string;
}
@Component({
  selector: 'app-department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.css']
})
export class DepartmentListComponent implements OnInit {

  productDialog: boolean;

  products: Billdesk[];
  productsbyid: Billdesk = <Billdesk>{}

  data: number = 0;

  dialogdata: string;

  organizationList: Billdesk[];

  product: Billdesk;
  billdesk: any;
  selectedProducts: any;
  insertdata: any;
  updatedata: any;
  submitted: boolean;
  //   OrganizationListResult:any[];
  statuses: any[];
  cities: City[];
  customers: Customer[];
  successDialog: boolean;
  insertDialog: boolean;
  representatives: Representative[];
  updateDialog: boolean;
  loading: boolean = true;
  inventoryStatus: Representative[];
  selectedCities: City[];
  ActiveStatus: any;
  //   OrganizationListResult:any;
  constructor(private billdeskService: BilldeskService, private messageService: MessageService, private confirmationService: ConfirmationService, public dialog: MatDialog, private route: Router) {

    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];

  }

  

  statusupdate(evt, DeptID) {
    var name = evt.target.name;
    var value = evt.target.value;

    let updateurl = "/department/departmentregistrationupdatestatus";
    if (name == 'radio') {

      var activestatus = 1;
    }
    else {
      var activestatus = 0;

    }
    var datarequest = {
      "dataobject": [{
        "DeptID": DeptID,
        "ActiveStatus": activestatus
      }]
    };
    this.billdeskService.UpdateData(updateurl, datarequest).subscribe((res: any) => {
      if (res.departmentregistrationupdatestatusResult.Message == 'Sucess') {

        window.location.reload();
        this.route.navigate(['/Department-List'])
        localStorage.setItem('updatedata', 'Success');
      } else {
        console.log('Please Try Again Later');
      }
    });
  }

  ngOnInit() {




    this.insertdata = localStorage.getItem('insertdata');

    this.updatedata = localStorage.getItem('updatedata');
    console.log("deat");
    console.log(this.insertdata);

    console.log(this.updatedata);
    console.log("sgsg");


    if (this.insertdata == 'Success') {

      this.organizationRegister();
      console.log("this.insertdata");
      console.log(this.insertdata);
      console.log("this.insertdata");

    }

    if (this.updatedata == 'Success') {

      this.organizationUpdate();
      console.log("this.updatedata");
      console.log(this.updatedata);
      console.log("this.updatedata");

    }



    console.log(this.billdeskService.getDepartment().then(res => this.products = res));

    this.representatives = [
      { inventoryStatus: "INSTOCK", image: "INSTOCK" },
      { inventoryStatus: "LOWSTOCK", image: "LOWSTOCK" },
      { inventoryStatus: "OUTOFSTOCK", image: "OUTOFSTOCK" },

    ];



    // this.statuses = [

    //   { label: "Pending", value: "Pending" },
    //   { label: "Success", value: "Success" } 

    // ];

    this.statuses = [

      { label: "In Active", value: "0", IfValue: "In Active" },
      { label: "Active", value: "1", IfValue: "Active" }

    ];
  }

  openNew() {
    // this.product = {};
    this.submitted = false;
    this.productDialog = true;
  }

  deleteSelectedProducts() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected products?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.products = this.products.filter(val => !this.selectedProducts.includes(val));
        this.selectedProducts = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
      }
    });
  }

  //   editProduct(product: Billdesk ) {
  //       this.Billdesk = {...product};
  //       this.productDialog = true;
  //   }

  viewProduct(DeptID: Billdesk) {
    // this.product = {...product};
    this.productDialog = true;

    
    this.billdeskService.getDepartmentByid(DeptID).then((res: any) => {  
      let detailObj = res && res[0] ? res[0] : {};
      detailObj['ActiveStatus'] = this.billdeskService.isActive(detailObj.ActiveStatus || '');

      this.productsbyid = {...detailObj};

      // console.log('this.productsbyid',this.productsbyid)
    });
  }

  successButton() {

    this.successDialog = true;
  }









  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Department", Detail: "Register Successfully", Backgroundcolor: "#47c9a2" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }


  openDialogUpdate(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Department", Detail: "Update Successfully", Backgroundcolor: "#137c95" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }


  openDialogDelete(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Organization", Detail: "Delete Successfully", Backgroundcolor: "#ff4141" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      window.location.reload();
      this.dialogdata = result;
    });
  }



  organizationRegister() {
    localStorage.removeItem('insertdata');
    console.log('remove');// this code for open dialog after 5 second
    setTimeout(() => {
      // this.insertDialog = true;
      this.openDialog();

    }, 500);
  }
  organizationUpdate() {
    localStorage.removeItem('updatedata');
    console.log('remove');// this code for open dialog after 5 second
    setTimeout(() => {
      // this.insertDialog = true;
      this.openDialogUpdate();

    }, 500);
  }




  statusupdateButton() {
    this.updateDialog = true;
  }



  deleteProduct(product: Billdesk) {

    console.log(product);
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + product.OrgID + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {


        console.log(this.billdeskService.DeleteOrganizationByid(product.OrgID).then(res => this.products = res));

        // this.successButton();

        this.openDialogDelete();

      }
    });
  }

  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  saveProduct() {
    this.submitted = true;

    if (this.product.name.trim()) {
      if (this.product.id) {
        this.products[this.findIndexById(this.product.id)] = this.product;
        //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
        this.statusupdateButton();
      }
      else {
        this.product.id = this.createId();
        this.product.image = 'product-placeholder.svg';
        this.products.push(this.product);
        //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
      }

      //   this.products = [...this.products];
      this.productDialog = false;
      // this.product = {};
    }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }
}
interface City {
  name: string,
  code: string
}



// same sa new page

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private route: Router) { }

  onNoClick(): void {
    this.dialogRef.close();

    this.route.navigate(['/User-Registration'])

  }

}