<style>
    @media print {
        @page {
            size: A4 landscape;
            overflow: scroll;
        }

        .printbutton {
            display: none !important;
        }
    }

    #content {
        zoom: 80%;
    }
</style>
<p-toast></p-toast>






<div class="card" style=" overflow-x: scroll;">


    <!-- <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="[
    'VendorName',
    'VendorMobileNo',
    'VendorEmailID',
    'AuthPersonName',
    'AuthPersonMobileNo',
    'GSTN',
    'CIN',
    'PANNo',
    'BankName',
    'BankBranchName',
    'BankAcNo',
    'IFSCCode'
    ]" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"> -->

        <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="[
        'VendorName',
        'VendorMobileNo',
        'VendorEmailID',
        'AuthPersonName',
        'AuthPersonMobileNo',
        'GSTN',
        'CIN',
        'PANNo',
        'BankName',
        'BankBranchName',
        'BankAcNo',
        'IFSCCode'
            ]" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"    styleClass="p-datatable-customers"    responsiveLayout="scroll"   > 

        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1
                    style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">
                    VENDOR LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />
                    &nbsp; &nbsp; <a [routerLink]="['/Vendor-Registration']" class="btn btn-success">Add <i
                            class="fa fa-plus" aria-hidden="true"></i></a>
                </span>
            </div>

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->

        </ng-template>


        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="rating" style="width: 150px"> Action<p-sortIcon field="rating"></p-sortIcon></th>

                <th pSortableColumn="ActiveStatus" style="width: 150px">Active Status<p-sortIcon
                        field="ActiveStatus"></p-sortIcon></th>
                <th pSortableColumn="VendorName" style="width: 150px">Vendor Name<p-sortIcon
                        field="VendorName"></p-sortIcon></th>
                <th pSortableColumn="VendorMobileNo" style="width: 150px">Mobile No <p-sortIcon
                        field="VendorMobileNo"></p-sortIcon></th>
                <th pSortableColumn="VendorEmailID" style="width: 150px">Email ID <p-sortIcon
                        field="VendorEmailID"></p-sortIcon></th>
                <th pSortableColumn="AuthPersonName" style="width: 150px">Contact Person Name <p-sortIcon
                        field="AuthPersonName"></p-sortIcon></th>
                <th pSortableColumn="AuthPersonMobileNo" style="width: 150px"> Contact No <p-sortIcon
                        field="AuthPersonMobileNo"></p-sortIcon></th>
                <th pSortableColumn="GSTN" style="width: 150px"> GISTN<p-sortIcon field="GSTN"></p-sortIcon></th>
                <th pSortableColumn="CIN" style="width: 150px"> CIN<p-sortIcon field="CIN"></p-sortIcon></th>
                <th pSortableColumn="PANNo" style="width: 150px"> PANCard<p-sortIcon field="PANNo"></p-sortIcon></th>
                <th pSortableColumn="BankName" style="width: 150px"> Bank Name <p-sortIcon
                        field="BankName"></p-sortIcon></th>

                <th pSortableColumn="BankBranchName" style="width: 150px"> Bank Branch<p-sortIcon
                        field="BankBranchName"></p-sortIcon></th>
                <th pSortableColumn="BankAcNo" style="width: 150px"> Account No<p-sortIcon
                        field="BankAcNo"></p-sortIcon></th>
                <th pSortableColumn="IFSCCode" style="width: 150px"> IFSCCode<p-sortIcon field="IFSCCode"></p-sortIcon>
                </th>

            </tr>


            <tr>
                <th>

                </th>

                <th>
                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')"
                        styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>
                </th>

                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorName' ,'contains')"
                        placeholder="Search VendorName" />

                </th>

                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorMobileNo' ,'contains')"
                        placeholder="Search VendorMobileNo" />
                </th>

                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorEmailID' ,'contains')"
                        placeholder="Search VendorEmailID" />
                </th>

                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'AuthPersonName' ,'contains')"
                        placeholder="Search AuthPersonName" />
                </th>


                <th>

                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'AuthPersonMobileNo' ,'contains')"
                        placeholder="Search AuthPersonMobileNo" />


                </th>



                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'GSTN' ,'contains')"
                        placeholder="Search GSTN" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'CIN' ,'contains')"
                        placeholder="Search CIN" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'PANNo' ,'contains')"
                        placeholder="Search PANNo" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'BankName' ,'contains')"
                        placeholder="Search BankName" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'BankBranchName' ,'contains')"
                        placeholder="Search BankBranchName" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'BankAcNo' ,'contains')"
                        placeholder="Search BankAcNo" />
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'IFSCCode' ,'contains')"
                        placeholder="Search IFSCCode" />
                </th>



            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">
                <td>
                    <!-- <span class="p-column-title">Action:</span>
                    <a     [routerLink]="['/Edit-Vendor',product.UserID]"><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"   style="margin-right: 4px;" ></button>
                    </a> 
        <button pButton pRipple icon="pi pi-eye"   class="p-button-rounded p-button-info" (click)="viewProduct(product.UserID)"></button> 
                      
     -->
                    <span class="p-column-title">Action:</span>
                    <span style="display: flex;align-items: center;justify-content: center;">
                        <a [routerLink]="['/Edit-Vendor',product.UserID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                style='color: #2459a6;' pTooltip="Edit Vendor Detail"></i></a>
                        <span pTooltip="View Vendor Detail"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
            border: 2px solid;
            border-radius: 5px;' (click)="viewProduct(product.UserID)"></i> </span>
                    </span>

                </td>


                <td>
                    <span class="p-column-title">Status:</span>
                    <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
                    <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>
                </td>

                <td> <span class="p-column-title">Vendor Name:</span>{{product.VendorName}}</td>
                <td><span class="p-column-title">Mobile No:</span>{{product.VendorMobileNo}}</td>
                <td><span class="p-column-title">Email ID :</span>{{product.VendorEmailID}}</td>
                <td><span class="p-column-title">Contact Person Name :</span>{{product.AuthPersonName}}</td>
                <td><span class="p-column-title">Contact No:</span>{{product.AuthPersonMobileNo}} </td>
                <td><span class="p-column-title">GISTN:</span>{{product.GSTN}} </td>
                <td><span class="p-column-title">CIN:</span>{{product.CIN}} </td>
                <td><span class="p-column-title">PANCard :</span>{{product.PANNo}} </td>
                <td><span class="p-column-title">Bank Name :</span>{{product.BankName}} </td>
                <td><span class="p-column-title">Bank Branch :</span>{{product.BankBranchName}} </td>
                <td><span class="p-column-title">Account No :</span>{{product.BankAcNo}} </td>
                <td><span class="p-column-title">IFSCCode :</span>{{product.IFSCCode}} </td>


            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>
<p-dialog [(visible)]="productDialog" [style]="{width: '600px'}" header="Vendor Details" [modal]="true"
    styleClass="p-fluid" id="content" #content>
    <ng-template pTemplate="content">


        <br>
        <div class="container">
            <table class="table table-striped">

                <tbody>
                    <tr>
                        <td>
                            <label for="Organization_Name" class="col-form-label">Vendor Id :</label>
                        </td>
                        <td>
                            <label for="OrgID" class="col-form-label"> {{productsbyid.VendorID}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="Organization_Name" class="col-form-label">User ID:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.UserID}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="PANCard" class="col-form-label">Vendor Name:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.VendorName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="GSTN" class="col-form-label">GSTN:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.GSTN}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="Contact_Number" class="col-form-label">PAN No:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.PANNo}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="Emailid" class="col-form-label">Vendor Email ID </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.VendorEmailID}}
                            </label></td>

                    </tr>
                    <tr>
                        <td> <label for="Address" class="col-form-label">Vendor Mobile No </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.VendorMobileNo}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="City" class="col-form-label">Vendor City </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.VendorCity}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td><label for="State" class="col-form-label">Vendor State </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.VendorState}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="PinCode" class="col-form-label">Bank Holder Name</label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.BankHolderName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="ContactPersonName" class="col-form-label">Bank Name </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.BankName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <label for="accountname" class="col-form-label">Bank Branch Name</label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.BankBranchName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="BanckAccountNo" class="col-form-label">IFSC Code </label></td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.IFSCCode}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="bankname" class="col-form-label">Auth Person Name</label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.AuthPersonName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="BanckBranch" class="col-form-label">Auth Person Email ID</label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.AuthPersonEmailID}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Auth Person Mobile No</label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.AuthPersonMobileNo}}
                            </label>
                        </td>

                    </tr>

                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Created Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.CreatedDate}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Created By </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.CreatedBy}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Updated Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.UpdatedDate}}
                            </label>
                        </td>

                    </tr>

                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Updated By </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.UpdatedBy}}
                            </label>
                        </td>

                    </tr>

                </tbody>
            </table>
        </div>





    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
        <span style="float: left;padding-top: 2%;" *ngIf="productsbyid">

            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">

                    Status
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" checked="!checked" name="radio"
                        [(ngModel)]="productsbyid.ActiveStatus" (change)="handleChange($event,productsbyid.UserID)"
                        [value]="1">active
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                        [(ngModel)]="productsbyid.ActiveStatus" (change)="handleChange($event,productsbyid.UserID)"
                        [value]="0">Deactive
                </label>
            </div>
        </span>
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"
            style="float: right;">
        </button>

    </ng-template>
</p-dialog>


<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>