<br>

<br>

<div *ngIf="visibleloader"  style="position: fixed;left: 0px;top: 0px;width: 100%;height: 100%;margin-left: 37%;margin-top: 10%; z-index: 9999;" > <img src="../../../assets/images/loading.gif" ></div>


<div class="container" style="background-color: #ffffff;" id="content" #content>
	<p-toast></p-toast>
	<div class="row">
		<div class="col-xs-12">
			<h2><span class="pull-right"><span style="border: 1px dotted black;">PURCHASE ENTRY
					</span>
					
					<!-- &nbsp;&nbsp;<span>TAX INVOICE</span>  -->
				
				</span></h2>
		</div>
	</div>


	<span>

		 
		 
		 <label>Please Select Purchase From</label>
	
 
</span>
<br>
	<span style="display:inline-flex;" >

		 
		 
				
				<p-radioButton name="groupname" value="Vendor" [(ngModel)]="PurchaseFrom"></p-radioButton>&nbsp;Vendor&nbsp;&nbsp;
				<p-radioButton name="groupname" value="Organization" [(ngModel)]="PurchaseFrom"></p-radioButton>&nbsp;Organization
				
		 
	</span>
 
	<div class="row">


		<form [formGroup]="purchaseform" (ngSubmit)="onSubmit()">
			<div class="col-xs-12">

				<div class="invoice-title">



                     <div *ngIf="PurchaseFrom==='Vendor'">
					<div class="position-relative  form-group col-sm-6" style="padding-left:0;">


				
						<label for="VendorID" class="col-form-label">Vendor Name<span class="mandatefield">*</span></label>
						<div>

							<p-dropdown [options]="vendorlist" [(ngModel)]="selectedVendorId" optionLabel="VendorName"
								[filter]="true" filterBy="VendorName" [showClear]="true"
								[placeholder]="selectedVendorName" (onChange)="changeVendor($event)" id="VendorID"
								formControlName="VendorID" [style]="{ width: '100%' }">

								<ng-template let-country pTemplate="item">
									<div class="country-item">
										<div>{{country.VendorName}}</div>
									</div>
								</ng-template>
							</p-dropdown>

							<app-field-error-display [displayError]="isFieldValid('VendorID')"
								errorMsg="Please Select Vendor Name" style="background-color: #f8d7da;
						border-color: #f5c6cb;" class="alert-danger">
							</app-field-error-display>

							<h1 style="font-weight: 700;" *ngIf="VendorShow">{{VendorName}}</h1>


						</div>
					</div>

					<h3 class="pull-right"> <img src="..\assets\images\3.png" width="150" /></h3>
					<strong>Seller</strong>
					<h4>{{VendorName}} <br>{{VendorBillingAddress}} {{VendorCity}}-{{VendorPinCode}},<br>
						India GSTIN-{{GSTN}}, {{VendorState}}<br>
						E-mail:{{VendorEmailID}}, Mobile No-{{VendorMobileNo}}</h4>

					</div>

					<div  *ngIf="PurchaseFrom=='Organization'">
					<div class="row" >


						<div class="position-relative  form-group col-sm-4">
							<label for="City" class="col-form-label">Organization<span class="mandatefield">*</span></label>
							<div class="">
	
								<p-dropdown [options]="organizaionlist" [(ngModel)]="selectedOrgId" optionLabel="OrgName"
									[filter]="true" filterBy="OrgName" [showClear]="true" [placeholder]="selectedOrgName"
									(onChange)="getDivisionByOrg($event)" id="UserOrgID" formControlName="UserOrgID"
									[style]="{ width: '100%' }">
	
									<ng-template let-country pTemplate="item">
										<div class="country-item">
											<div>{{country.OrgName}}</div>
										</div>
									</ng-template>
								</p-dropdown>
	
								<app-field-error-display [displayError]="isFieldValid('UserOrgID')"
									errorMsg="Please Select Organization" style="background-color: #f8d7da;
							border-color: #f5c6cb;" class="alert-danger">
								</app-field-error-display>
	
							</div>
						</div>
						<div class="position-relative  form-group col-sm-4">
							<label for="DivID" class="col-form-label">Division Name<span class="mandatefield">*</span></label>
							<div class="">
	
	
								<p-dropdown [options]="divisionlist" [(ngModel)]="selectedDiv" optionLabel="DivName"
									[filter]="true" filterBy="DivName" [showClear]="true" [placeholder]="selectedDivName"
									(onChange)="changeDivision($event)" id="UserDivID" formControlName="UserDivID"
									[style]="{ width: '100%' }">
	
									<ng-template let-country pTemplate="item">
										<div class="country-item">
											<div>{{country.DivName}}</div>
										</div>
									</ng-template>
								</p-dropdown>
	
								<app-field-error-display [displayError]="isFieldValid('UserDivID')"
									errorMsg="Please Select Division" style="background-color: #f8d7da;
							border-color: #f5c6cb;" class="alert-danger">
								</app-field-error-display>
	
							</div>
						</div>
						<div class="position-relative  form-group col-sm-4">
							<label for="DeptID" class="col-form-label">Department Name<span class="mandatefield">*</span></label>
							<div class="">
	
								<p-dropdown [options]="departmentlist" [(ngModel)]="selectedDeptID" optionLabel="DeptName"
									[filter]="true" filterBy="DeptName" [showClear]="true" [placeholder]="selectedDeptName"
									(onChange)="changeDepartment($event)" id="UserDeptID" formControlName="UserDeptID"
									[style]="{ width: '100%' }">
	
									<ng-template let-country pTemplate="item">
										<div class="country-item">
											<div>{{country.DeptName}}</div>
										</div>
									</ng-template>
								</p-dropdown>
								<app-field-error-display [displayError]="isFieldValid('UserDeptID')"
									errorMsg="Please Select Deparment" style="background-color: #f8d7da;
							border-color: #f5c6cb;" class="alert-danger">
								</app-field-error-display>
	
							</div>
						</div>
	
					</div>
					<div class="row"  >
						<div class="position-relative  form-group col-sm-4">
					 <strong>Seller</strong><br>
					 <h4>	<ng-container *ngIf="departmentdetailbyselection && departmentdetailbyselection[0]">
								{{departmentdetailbyselection[0].DeptName}}<br>
								{{departmentdetailbyselection[0].DeptAddress}}
							</ng-container>
							<ng-container *ngIf="departmentdetailbyselection && departmentdetailbyselection[0]">
								GSTIN: {{departmentdetailbyselection[0].GSTN}}<br>
							</ng-container>


							</h4>
						</div>
						<div class="position-relative  form-group col-sm-4"></div>
	
						<div class="position-relative  form-group col-sm-4"></div>
					</div>
					</div>
                    
				
				</div>
				<div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div>

		 
				<div class="row">


					<div class="position-relative  form-group col-sm-4">
						<label for="City" class="col-form-label">Organization<span class="mandatefield">*</span></label>
						<div class="">
							<input
							type="text"
							style="width: 100%;"
							pInputText
							[disabled]="true" class="form-select"
							value="{{UserOrgName}}"/>
							<!-- <p-dropdown [options]="organizaionlist" [(ngModel)]="selectedToOrgId" optionLabel="OrgName"
								[filter]="true" filterBy="OrgName" [showClear]="true" [placeholder]="selectedToOrgName"
								(onChange)="getDivisionByToOrg($event)" id="ToOrgID" formControlName="ToOrgID"
								[style]="{ width: '100%' }">

								<ng-template let-country pTemplate="item">
									<div class="country-item">
										<div>{{country.OrgName}}</div>
									</div>
								</ng-template>
							</p-dropdown>

							<app-field-error-display [displayError]="isFieldValid('ToOrgID')"
								errorMsg="Please Select Organization" style="background-color: #f8d7da;
						border-color: #f5c6cb;" class="alert-danger">
							</app-field-error-display> -->

 

						</div>
					</div>
					<div class="position-relative  form-group col-sm-4">
						<label for="DivID" class="col-form-label">Division Name<span class="mandatefield" >*</span></label>
						<div class="">
				
							<input
							type="text"
							style="width: 100%;"
							pInputText
							[disabled]="true" class="form-select"
							value="{{UserDivName}}"/>

							<!-- <p-dropdown [options]="divisionlist" [(ngModel)]="selectedToDiv" optionLabel="DivName"
								[filter]="true" filterBy="DivName" [showClear]="true" [placeholder]="selectedToDivName"
								(onChange)="changeToDivision($event)" id="ToDivID" formControlName="ToDivID"
								[style]="{ width: '100%' }">

								<ng-template let-country pTemplate="item">
									<div class="country-item">
										<div>{{country.DivName}}</div>
									</div>
								</ng-template>
							</p-dropdown>

							<app-field-error-display [displayError]="isFieldValid('ToDivID')"
								errorMsg="Please Select Division" style="background-color: #f8d7da;
						border-color: #f5c6cb;" class="alert-danger">
							</app-field-error-display> -->

						</div>
					</div>
					<div class="position-relative  form-group col-sm-4">
						<label for="DeptID" class="col-form-label">Department Name<span class="mandatefield">*</span></label>
						<div class="">

							<!-- <p-dropdown [options]="departmentlist" [(ngModel)]="selectedToDeptID" optionLabel="DeptName"
								[filter]="true" filterBy="DeptName" [showClear]="true" [placeholder]="selectedToDeptName"
								(onChange)="changeToDepartment($event)" id="ToDeptID" formControlName="ToDeptID"
								[style]="{ width: '100%' }">

								<ng-template let-country pTemplate="item">
									<div class="country-item">
										<div>{{country.DeptName}}</div>
									</div>
								</ng-template>
							</p-dropdown>
							<app-field-error-display [displayError]="isFieldValid('ToDeptID')"
								errorMsg="Please Select Deparment" style="background-color: #f8d7da;
						border-color: #f5c6cb;" class="alert-danger">
							</app-field-error-display> -->
							<input
							type="text"
							style="width: 100%;"
							pInputText
							[disabled]="true" class="form-select"
							value="{{UserDeptName}}"/>
				
						</div>
					</div>

				</div>



				<div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div>
				<div class="row">
					<div class="position-relative  form-group col-sm-4">
						<br><br><br>

						<strong>BILL TO:</strong>
						<ng-container *ngIf="departmentdetail && departmentdetail[0]">
<br>
							{{departmentdetail[0].DivName}}<br>
							{{departmentdetail[0].DeptName}}<br>
							{{departmentdetail[0].DeptAddress}}
						</ng-container>
						<ng-container *ngIf="departmentdetail && departmentdetail[0]">
							GSTIN: {{departmentdetail[0].GSTN}}<br>
						</ng-container>
					</div>
					<div class="position-relative  form-group col-sm-4"></div>

					<div class="position-relative  form-group col-sm-4"></div>
				</div>

				<div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div>
				<div class="row">
					<div class="position-relative  form-group col-sm-3">
						<address>

							<span style="display:inline"><strong> Invoice No<span class="mandatefield">*</span>:</strong>


								<input type="text" class="form-control" formControlName="invno"></span>

							<app-field-error-display [displayError]="isFieldValid('invno')"
								errorMsg="Please Enter Invoice No." style="background-color: #f8d7da;
								border-color: #f5c6cb;" class="alert-danger">
							</app-field-error-display>


						</address>

					</div>



					<div class="position-relative  form-group col-sm-3">

						<span style="display:inline"><strong>Upload Invoice<span class="mandatefield">*</span>:</strong>  </span>

						<div class="">

							<input type="file" #Image accept="*" (change)="onFileChange($event)"
								accept="application/pdf,image/jpeg,image/jpg, image/png" name="InvoiceAttechmentURL"
								id="InvoiceAttechmentURL" placeholder="Please Enter InvoiceAttechmentURL"
								class="form-control" formControlName="InvoiceAttechmentURL">
							<span style="color:red;" [hidden]="imageerror">Only JPEG,JPG,PNG,PDF is Allowed</span>

<!-- <span>{{imageerror}}</span> -->
							<app-field-error-display [displayError]="isFieldValid('InvoiceAttechmentURL')"
								errorMsg="Please Upload Invoice" style="background-color: #f8d7da;
							border-color: #f5c6cb;" class="alert-danger">
							</app-field-error-display>

						</div>

					</div>
					<div class="position-relative  form-group col-sm-3">

						<span style="display:inline"><strong> Purchase Type  <span class="mandatefield">*</span> :</strong> </span>
						<select class="form-control  form-select" formControlName="purchasetype">
							<option selected disabled [value]="null">Please Select Purchase Type</option>
							<option [value]="1">Purchase</option>
							<option [value]="2">Purchase Order</option>
							<option [value]="3">Opening Stock</option>
						</select>

						<app-field-error-display [displayError]="isFieldValid('purchasetype')"
							errorMsg="Please Select Purchase Type" style="background-color: #f8d7da;
					border-color: #f5c6cb;" class="alert-danger">
						</app-field-error-display>

					</div>
					<div class="position-relative  form-group col-sm-3">
						<strong>Invoice Date <span class="mandatefield">*</span>:</strong>

						<input type="date" class="form-control" formControlName="invdate">

						<app-field-error-display [displayError]="isFieldValid('invdate')"
							errorMsg="Please Select Invoice Date" style="background-color: #f8d7da;
					border-color: #f5c6cb;" class="alert-danger">
						</app-field-error-display>


					</div>
				</div>





			</div>

		</form>
		<div class="row">


			<div *ngIf="!dynamicArray?.length">
				<div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div>
				<h1 style="color:red!important;text-align:center">Container is empty please add new product !</h1>
			</div>
			<div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a"></div>
			<div class="row">
				<div class="col-md-12">
					<div class="panel panel-default">
						<div class="panel-body">
							<div *ngFor="let dynamic of dynamicArray; let i = index" class="card"
								style="padding: 15px;margin: 15px;">

								<div class="row">

									<div class="col-md-1">
										<label>Product ID:</label>
									</div>

									<div class="col-md-2">



										{{dynamicArray[i].ProductID}}
									</div>

 

									
									<div class="col-md-1">
										<label>Product Name:</label>


									</div>

									<div class="col-md-2">

										{{dynamicArray[i].ProductName}}

									</div>

									<div class="col-md-1">
										<label>Purchase Qty:</label>


									</div>

									<div class="col-md-2">

										{{dynamicArray[i].PurchaseQuantity}}

									</div>

									<div class="col-md-1">

										<label>Purchase Price:</label>
									</div>
									<div class="col-md-2">


										{{dynamicArray[i].PurchasePrice}}



									</div>
						
								 

								</div>

								<div class="row">



									<div class="col-md-1">



										<label>Sale Price:</label>
									</div>
									<div class="col-md-2">

										{{dynamicArray[i].SalePrice}}
									</div>

									<div class="col-md-1">

										<label>MRP:</label>

									</div>


									<div class="col-md-2">


										{{dynamicArray[i].MRP}}

									</div>

									<div class="col-md-1">

										<label>Description:</label>

									</div>

									<div class="col-md-2">

										{{dynamicArray[i].Description}}

									</div>


									<div class="col-md-1">

										<label>Whole sale Price:</label>
									</div>

									<div class="col-md-1">


										{{dynamicArray[i].WholesalePrice}}
									</div>
									<div class="col-md-1">
										<i class="fa fa-trash" style="color:red;font-size:18px;"
											(click)="deleteRow(i)"></i>

									</div>

								</div>


								<div class="row">
									<div class="col-md-1">
										<label>Tax:</label>
									</div>
									<div class="col-md-2">

										{{dynamicArray[i].TaxPercentage}}
									</div>


									<div class="col-md-1">



										<label>Purchase Price Type:</label>

									</div>

									<div class="col-md-2">

										{{dynamicArray[i].PurchasePriceType}}

									</div>
									<div class="col-md-1">

										<label>TaxAmount:</label>

									</div>

									<div class="col-md-2">

										{{dynamicArray[i].TaxAmount}}
									</div>
									<div class="col-md-1">

										<label>Total Amount:</label>

									</div>

									<div class="col-md-1">

										{{dynamicArray[i].TotalAmount}}
									</div>



									<div class="col-md-1">


									</div>
								</div>

								<div class="row">
									

									
									<div class="col-md-1">
										<label>Packed/Manufacturing  Date:</label>
									</div>
									<div class="col-md-2">

										{{dynamicArray[i].PackedDate}}
									</div>

									<div class="col-md-1">
										<label>Expiry/Warranty Date:</label>

									</div>
									<div class="col-md-2">
										{{dynamicArray[i].ExpiryDate}}
									</div>
									<div class="col-md-1">

										<label>Brand ID:</label>

									</div>
									<div class="col-md-2">

										{{dynamicArray[i].BrandID}}
									</div>
									<div class="col-md-1">

										<label>Brand Name:</label>

									</div>
									<div class="col-md-2">

										{{dynamicArray[i].BrandName}}
									</div>


								</div>



							</div>
						</div>
					</div>
				</div>

			</div>


			<div class="row" style="margin-top:2%;margin-bottom:10%;">
				<div class="col-md-2">

					<button class=" btn fa fa-plus btn-warning" (click)="AddItem()" style="color:#fff;"> &nbsp;
						Add New</button>

				</div>

				<div class="col-md-2"></div>
				<div class="col-md-2"></div>
				<div class="col-md-2"></div>
				<div class="col-md-2"></div>

				<div class="col-md-2">

					<!--  -->
					<button   *ngIf="dynamicArray?.length" type="submit" class="btn btn-success"  (click)="onSubmit()" [disabled]="isDisabled"> <i
							class="fa fa-floppy-o" aria-hidden="true"></i>
						&nbsp;&nbsp;Submit</button>
				</div>

			</div>



			<p-sidebar [(visible)]="sidebarVisible2" position="right" style="z-index: 0 !important;">



				<h2>Add Purchase Item</h2>

      
				<!-- <div *ngFor="let dynamic of NewdynamicArray; let i = index"> -->
				<label  class="gapclass">Product<span class="mandatefield">*</span></label>

				<!-- <select class="form-control mb" [(ngModel)]="ProductID" data-placeholder="Please Select Product">
					<option selected value="" disabled>Please Select Product</option>
					<optgroup *ngFor='let grp of itemcategorylist' label="{{grp.ItemCategoryName}}">
						<option *ngFor='let item of grp.SubMenuList' [value]="item.ItemID"
							data-category="item.ItemCategoryID">{{item.ItemName}} </option>
					</optgroup>
				</select> -->
   <div style="display:flex;">
				<p-dropdown [options]="ProductListByGroup"  placeholder="Please Select Product" [group]="true" [filter]="true"   filterBy="label"  [(ngModel)]="ProductID"  [autoDisplayFirst]="false" optionLabel="label"    >

				</p-dropdown>
				<button type="button" class="btn btn-primary" (click)="ProductDialogButton()">
					<i class="fa fa-plus"></i>
				  </button>
				   
			</div>

				<label  class="gapclass">Brand<span class="mandatefield">*</span></label>

				
<!-- 
				<select class="form-control mb" [(ngModel)]="BrandID" data-placeholder="Please Select Product">
					<option selected value="" disabled>Please Select Brand</option>
					<option *ngFor='let item of  brandlist' [value]="item.BrandID">
						{{item.BrandName}} </option>

				</select> -->
 
				<div style="display:flex;"> 
				<p-dropdown [options]="brandlist" [(ngModel)]="BrandID" optionLabel="BrandName"
				[filter]="true" filterBy="BrandName" [showClear]="true" [placeholder]="selectBrandIDname"
				(onChange)="changeBrand($event)" id="BrandID"   
				[style]="{ width: '100%' }">

				<ng-template let-country pTemplate="item">
					<div class="country-item">
						<div>{{country.BrandName}}</div>
					</div>
				</ng-template>
			</p-dropdown>
		 

			<button type="button" class="btn btn-primary" (click)="BrandDialogButton()">
				<i class="fa fa-plus"></i>
			  </button>
			   
</div>
<label  class="gapclass">Description<span class="mandatefield">*</span></label>

				<input name="{{ Description}}" [(ngModel)]="Description" type="text" class="form-control mb" />

				<label  class="gapclass">Packed/Manufacturing  Date</label>
				<input name="{{ PackedDate}}" [(ngModel)]="PackedDate" type="date" class="form-control mb" />


				<label  class="gapclass">Expiry/Warranty Date</label>

				<input name="{{ ExpiryDate}}" [(ngModel)]="ExpiryDate" type="date" class="form-control mb" />

				<label  class="gapclass">Purchase Unit<span class="mandatefield">*</span></label>
              
				<select class="form-control mb form-select" [(ngModel)]=" PurchaseUnit">

					<option selected [value]="undefined" disabled>Please Select Unit</option>

					<option *ngFor='let item of unitlist' [value]="item.UnitID">
						{{item.UnitName}}</option>

				</select>


				<label  class="gapclass">MRP<span class="mandatefield">*</span></label>

				<input name="{{MRP}}" [(ngModel)]="MRP" type="number" class="form-control mb" />



				<label  class="gapclass">Purchase Quantity<span class="mandatefield">*</span></label>

				<input name="{{PurchaseQuantity}}" [(ngModel)]="PurchaseQuantity" type="number"
					class="form-control mb" />



					
				<label  class="gapclass"> Purchase Price Type<span class="mandatefield">*</span></label>
				<select class="form-control mb form-select" [(ngModel)]="PurchasePriceType">

					<option selected [value]="undefined"  disabled>Please Select Price Type</option>

					<option *ngFor='let item of pricetypelist' [value]="item.PriceTypeID">
						{{item.PriceTypeName}}</option>

				</select>
				

			
			

				<label  class="gapclass">Purchase Price<span class="mandatefield">*</span></label>

				<input name="{{ PurchasePrice}}" [(ngModel)]="PurchasePrice" type="number" class="form-control mb" />
				<label  class="gapclass">Sale Price </label>

				<input name="{{ SalePrice}}" [(ngModel)]="SalePrice" type="number" class="form-control mb" />


			

				<label  class="gapclass">Whole sale Price</label>



				<input name="{{ WholesalePrice}}" [(ngModel)]="WholesalePrice" type="number" class="form-control mb" />

				<label  class="gapclass">Tax Percentage<span class="mandatefield">*</span></label>

				<select name="IGSTPer" id="IGSTPer" class="form-control mb form-select" [(ngModel)]="TaxPercentage"
					(ngModelChange)="getValueform(PurchaseQuantity,PurchasePrice,TaxPercentage,i)">


					<option  selected disabled [value]="undefined" >Please Select Tax (%)</option>
					<option *ngFor="let gstdetails of gstdetail" [value]="gstdetails">
						{{gstdetails}} </option>

				</select>

				<label  class="gapclass">Tax Amount</label>

				<input name="{{ TaxAmount }}" [(ngModel)]="TaxAmount" type="text" class="form-control mb" />
				<label  class="gapclass">Taxable Amount</label>
				<input name="{{ TaxableAmount }}" [(ngModel)]="TaxableAmount" type="text" class="form-control mb"  readonly/>




				<label  class="gapclass">Total Amount</label>


				<input name="{{ TotalAmount}}" [(ngModel)]="TotalAmount" type="number" class="form-control mb" />


				<button class="btn-info form-control mb" (click)="SubmitItemDetail()"> <i class="fa fa-floppy-o"
						aria-hidden="true"></i> &nbsp;&nbsp;
					Add</button>&nbsp;&nbsp;
				<br> <br> <br> <br> <br>
				 <br> <br> <br> <br> <br>


			</p-sidebar>
 
		</div>



		<p-dialog [(visible)]="ProductDialog" [style]="{width: '800px'}" header="Product  Registration" [modal]="true"
styleClass="p-fluid">
<ng-template pTemplate="content">


    <form class="form-horizontal" [formGroup]="productform"> 

        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">PRODUCT REGISTRATION</h1>
        <div class="card-body" style="background-color:#fff; ">
    
    
    
    
            <h1 class="card-title">
               Product Registration
            </h1>
            <div class="row">
              
        
        
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="ItemName" class="col-form-label">Product Name  <span>*</span></label>
                    <div class="">
                        <input type="text" name="ItemName" id="ItemName" placeholder="Please Enter Product Name" class="form-control" formControlName="ItemName"  (input)="valueChanged($event.target.value)">
             <!-- <app-field-error-display [displayError]="isFieldValid('ItemName')" errorMsg="Please Enter Item Name"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
               </app-field-error-display> 
     -->
               <!-- {{boxvalue}} -->
                    </div>
                </div>
                
             
                <div class="position-relative  form-group col-sm-6">
                    <label for="ItemCategoryID" class="col-form-label">Product Category Name<span>*</span></label>

              
                    <div class="" >


                        <!-- {{selectedItemCategoryID}} -->
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <select  class="form-control form-select" name="ItemCategoryID" id="ItemCategoryID"    formControlName="ItemCategoryID"   [ngModel]="selectedItemCategoryID"> 
                            
                             
                            <option [value]="undefined"   disabled selected >Choose Product Category</option>
                            
                            <!-- <option value="88" >Test</option> -->
                            <option *ngFor="let list of itemcategorylist"    [value]="list.ItemCategoryID">{{list.ItemCategoryName}}</option>
                        
                        </select>
                    
<!--                     
                        <app-field-error-display [displayError]="isFieldValid('ItemCategoryID')" errorMsg="Please Select Category Name"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display> -->
                    
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
                    <div class="">
    
            <select name="ActiveStatus" id="ActiveStatus" class="form-control form-select" formControlName="ActiveStatus"  >
				<option [value]="null">Please Select Status</option>
                <option [value]="1">Active</option>
                <option [value]="0">InActive</option></select>
                         <!-- <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display> -->
                    </div>
                </div>
        
 
              
        
                <div class="position-relative  form-check col-sm-6 offset-sm-6">
                    <div class=""  >
                        <button class="btn btn-primary"   (click)="ProductRegister()">Submit</button>
                    </div>
                </div>
           
        </div>
        
        

        </div>
    </form>


</ng-template>

<ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">

</ng-template>
</p-dialog>


<p-dialog [(visible)]="BrandDialog" [style]="{width: '800px'}" header="Brand Registration" [modal]="true"
styleClass="p-fluid">
<ng-template pTemplate="content">


	<form class="form-horizontal" [formGroup]="brandform">


        
        <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">BRAND ENTRY</h1>
        <div class="card-body" style="background-color:#fff; ">
        
        
        
              
            <!-- <h1 class="card-title" >
                Brand  Detail
            </h1> -->
         
        <div class="row">


          
                <div class="position-relative  form-group col-sm-6">
                    <label for="BrandName" class="col-form-label">Brand Name <span>*</span></label>
                    <div   [ngClass]="displayFieldCssBrand('BrandName')">
                     
                     
                        <input name="BrandName" id="BrandName" placeholder="Enter Brand Name" type="text" class="form-control" formControlName="BrandName">
                     
                        
                         
                        <app-field-error-display [displayError]="isFieldValidBrand('BrandName')" errorMsg="Please Enter Brand Name"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            
        
        
                    <div class="position-relative  form-group col-sm-6">
                        <label for="IFSCCode" class="col-form-label">Active Status <span>*</span></label>
                        <div class="">
                            <!-- <input type="text" name="IFSCCode" id="IFSCCode" class="form-control" formControlName="IFSCCode"> -->
        
                <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus">
                    <option [ngValue]="null" disabled selected >Please  Select Status</option>
                    <option [ngValue]="1">Active</option><option [ngValue]="0">InActive</option></select>
                             <app-field-error-display [displayError]="isFieldValidBrand('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                 
                     
            
                   
            
                    </div>
<div class="row">

	<div class="col-sm-4"></div>
	<div class="col-sm-4">
		<button class="btn btn-primary" (click)="BrandRegister()">Submit</button>
	</div>
	<div class="col-sm-4"></div>
</div>
			 
				</div>


				</form>


</ng-template>

<ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">

</ng-template>
</p-dialog>
