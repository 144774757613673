
<form class="form-horizontal" [formGroup]="form"> 
    <div class="card-body" style="background-color:#fff; margin:100px;">
        <h1 class="card-title">
           Role Menu Assigning 
        </h1>
        <div class="row">
           
            <div class="position-relative  form-group col-sm-6"  >
                <label for="UserID" class="col-form-label">User<span>*</span></label>
                <div class="">
 
                    <p-dropdown [options]="menulist"  [(ngModel)]="selectedMenuId" optionLabel="MenuName" [filter]="true" filterBy="MenuName"
                    [showClear]="true" [placeholder]="selectedMenuName"  (onChange)="changeMenu($event)" name="UserID" id="UserID"     formControlName="UserID"  [style]="{ width: '100%' }" >
                 
                    <ng-template let-country pTemplate="item">
                        <div class="country-item">
                            <div>{{country.UserFullName}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                
                    <app-field-error-display [displayError]="isFieldValid('UserID')" errorMsg="Please Select User"   style="background-color: #f8d7da;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                
                </div>
            </div>
            

       <div class="position-relative  form-group col-sm-6">
            <label for="RoleTypeID" class="col-form-label">User Role<span>*</span></label>
            <div class="">
            

                <p-dropdown [options]="rolelist"  [(ngModel)]="selectedRoleID" optionLabel="DeptName" [filter]="true" filterBy="DeptName"
                [showClear]="true" [placeholder]="selectedRoleName"  (onChange)="changeRole($event)" name="RoleTypeID" id="RoleTypeID"     formControlName="RoleTypeID"  [style]="{ width: '100%' }" >
             
                <ng-template let-country pTemplate="item">
                    <div class="country-item">
                        <div>{{country.RoleTypeName}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
                <app-field-error-display [displayError]="isFieldValid('RoleTypeID')" errorMsg="Please Select Role"   style="background-color: #791820;
                border-color: #f5c6cb;" class="alert-danger">
       </app-field-error-display> 
            </div>
        </div>


 
            <div class="position-relative  form-group col-sm-6">
                <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
                <div class="">
                     

        <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus">
            <option [ngValue]="null" selected>Please Select Status</option>
            <option value="1">Active</option><option value="0">InActive</option></select>
                     <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div>

          
           




             <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    