import { Component, OnInit, ElementRef, VERSION, Inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Billdesk } from '../../billdesk';
import { BilldeskService } from '../../billdesk.service';
 

import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
//import * as $ from "jquery";
declare var $: any;

import { ProductJsonForBox, BrandList } from '../../model/coman.model';
import { Router } from '@angular/router';
 
@Component({
  selector: 'app-box-making',
  templateUrl: './box-making.component.html',
  styleUrls: ['./box-making.component.css']
})
export class BoxMakingComponent implements OnInit {

  BoxName:any;
  BoxDescription:any;
  addForm: FormGroup;

  rows: FormArray;
  itemForm: FormGroup;
  visibleloader=false;  
  itemcategorylist: Billdesk[];
  
  // unit: Billdesk[];
  unitlist:any;
  OrganizationID:number;
  InstitutionID:number;
  DepartmentID:number;
  // form = new FormGroup({
  //   UnitID:new FormControl('')
 
  // });

  BrandDetail: BrandList[] = [
    {
      BrandID:0,
      BrandName:'',
      ActiveStatus:'',
      CreatedBy:'',
      UpdatedBy:''
    },
  ];

    dynamicArray:any = [
    { 
      BrandDetail:this.BrandDetail 
    },
  ];
  constructor( 
    private billdeskService: BilldeskService ,private fb: FormBuilder,private route: Router
  ) {
   

    this.addForm = this.fb.group({
      items: [null, Validators.required],
      items_value: ['no', Validators.required],
      BoxName:[null, Validators.required],
      BoxDescription:[null, Validators.required]
    });
    this.rows = this.fb.array([]);
    this.OrganizationID=9;
    this.InstitutionID=35;
    this.DepartmentID=618;
    const userdetail = '?OrgID='+this.OrganizationID +'&DivID=' +this.InstitutionID+'&DeptID='+this.DepartmentID;
    this.billdeskService.getItemAvailableQuantityItemInventoryCombinelist(userdetail).then((res: any) => { 
        this.itemcategorylist = res;
      }); 
       this.billdeskService.getUnitListDLL().then((res) => (this.unitlist = res));
  }
  ngOnInit() {
    this.rows.push(this.createItemFormGroup());

    this.addForm.get("items_value").setValue("yes");
    this.addForm.addControl('rows', this.rows);
 
  }
 
  get addDynamicRow() {
    return this.addForm.get('rows') as FormArray;
  }

  onAddRow() {
    this.rows.push(this.createItemFormGroup());
  }

  onRemoveRow(rowIndex:number){
    this.rows.removeAt(rowIndex);
  }

  createItemFormGroup(): FormGroup {
    return this.fb.group({
      ItemID:null,
      BrandID:null,
      UnitID:null,
      Quantity:null 
    });
  }
 
 onSubmit() {  
 console.log('formgroup',this.addForm)
  console.log('this.BoxDescriptionf',this.BoxDescription)
    console.log(this.dynamicArray) 
    console.log(this.BoxName,'boxname')
    
    console.log(this.BoxDescription,'BoxDescription')
    let registerurl = '/boxmaking/save';
    let obj =
        {
          "dataobject": [
            {
              "BoxID": 0,
              "BoxName": this.addForm.value.BoxName,
              "BoxDescription": this.addForm.value.BoxDescription,
              "BoxMakingProductJson":this.rows.value
      }
          ]
      }
      
    

    console.log('obj',obj)
    this.billdeskService.RegisterData(registerurl, obj).subscribe((res: any) => {
        console.log('res');
        console.log(res);
        console.log('res');
        if (res.boxmakingregistrationResult.Message == 'Sucess') {
          this.visibleloader = false;  
          this.route.navigate(['/box-list'])
          localStorage.setItem('insertdata', 'Success');
        } else {
          // console.log('Please Try Again Later');
        }
      });
  }

  setBrand(e,i){ 
    console.log('e',e,'i,',i)
  }

 

  
  setProductName(event,j){
    console.log('eventtarge',event)

         this.dynamicArray.push({   
          BrandDetail:''
        });  
    this.dynamicArray[j].BrandDetail = []; 
    this.billdeskService.getBrandListItemId(event).then((res: any) => {    
      this.BrandDetail=res;
      console.log('res',res)
      if (res != '' && res != undefined && res != null) { 
        for (var i = 0; i < res.length; i++) {    
           this.dynamicArray[j].BrandDetail.push(res[i]);
        }
        console.log('thieis batch detail ', this.dynamicArray[j].BrandDetail);
      } 
    });

 }

 
 
}
 



// https://billdeskapi.aisect.org/api/billdesk/stock/stockproductlist?OrgID=9&DivID=35&DeptID=618&ts=1725521372385
// https://billdeskapi.aisect.org/api/billdesk/stock/stockproductlist?OrgID=9&DivID=35&DeptID=61&ts=1725521331476

// [ { "ProductID": "238", "ProductName": "" }, { "ProductID": "239", "ProductName": "" }, { "ProductID": "240", "ProductName": "" }, { "ProductID": "237", "ProductName": "" } ]