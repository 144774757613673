
<style>

.center{
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 25%;
  left: 50%;
  margin: -100px 0 0 -150px; 
}


::ng-deep .mat-drawer-content{
   background-color: black; 
   }


   h1 {
color: red;
}

h6{
color: red;
text-decoration: underline;
}

</style>
<div class="w3-display-middle center">
    <h1   style="text-align: center;font-size:45px;"><code>Access Denied</code></h1>
    <hr   style="margin:auto;width:50%;text-align: center;">
    <h3   style="text-align: center;color:#fff;"  >You don't have permission to view this page.</h3>
    <h3   style="text-align: center;font-size:30px;"  >🚫🚫🚫🚫</h3>
    <h6    style="text-align: center;font-size:20px;" >401 Unauthorized Access</h6>
</div>