export class datatable{
qty?:any;
mrp?:any;
rate?:any;
}


export interface SaleinvoiceEntry {
    ProductID?:number,
    StockID?:number,
    BrandName?: string,
    StockQuantity?: number,
    BatchNo?: string,
    BrandID?:number,
    SaleUnit?: string,
    PackedDate?: string,
    ExpiryDate?: string,
    Description?: string,
    SalePriceType?: string,
    SaleQuantity?:number,
    SalePrice?:number,
    MRP?:number,
    WholesalePrice?:number,
    TaxableAmount?:number,
    TaxPercentage?:any,
    TaxAmount?:number,
    TotalAmount?:number,
    Batchlist?: any
    }
    