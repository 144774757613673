
<br>

<br>
<div class="container" style="background-color: #ffffff;"  id="content"  #content> 

	<div class="row"><div class="col-xs-12"><h2><span class="pull-right"><span style="border: 1px dotted black;">ORIGINAL FOR RECEIPIENT </span>&nbsp;&nbsp;<span>TAX INVOICE</span> </span></h2></div></div>
    <div class="row">
        <div class="col-xs-12">
    		<div class="invoice-title">
    			<h1 style="font-weight: 700;">AISECT LTD</h1>
		
                <h3 class="pull-right"> <img src="..\assets\images\3.png" width="150"/></h3>

                <h4>Scope Campus,NH-12 Near MIsrod, Hoshangabad Road, BHopal-462047,<br>
                    India GSTIN-07AABCE283411ZQ, State Name-MP Code 07<br>
                E-mail:aisect@aisect.org, Mobile NO-7617223344</h4>
    		</div>
    		<div style="border-bottom: 5px inset ;width: 100%;border-color: #9a9a9a;"></div>
    		<div class="row" >
    			<div class="col-sm-4">
    				<address>

                        <span><strong> Invoice No:</strong> LVT-2</span><br><br>

    				<strong>BILL TO:</strong>
                    <br>
    					Eduvantage Private Limited<br>
                        1107,1108,1109, Hemkunt Chanbers, Nehru Place,<br>
                        Delhi-110019
    				</address>
    			</div>
    			<div class="col-sm-4">
    				<address>

                        <span><strong> Invoice Date:</strong>: 24-03-2022</span><br><br>
        		 
    					GSTIN/UIN:23AAFCA7441P1ZF<br>
    					State Name: Madhya Pradesh,<br> 
                         Code: 23
    				</address>
    			</div>

                <div class="col-sm-4">
    				<address>
        			<span><strong> Due Date:</strong> 31-03-2022</span><br>
    				 
    				</address>
    			</div>
    		</div>
  
    	</div>
    </div>
    
    <div class="row">
    	<div class="col-md-12">
    		<div class="panel panel-default">
    			 
    			<div class="panel-body">
    				<div class="table-responsive">
    					<table class="table table-condensed">
    						<thead>
                                <tr style="border-top: 9px inset ;">
        							<td class="text-center"><strong>S.NO.</strong></td>
        							<td class="text-center"><strong>ITEMS</strong></td>
        							<td class="text-center"><strong>HSN/SAC</strong></td>
        							<td class="text-right"><strong>QTY.</strong></td>
                                    <td class="text-right"><strong>MRP</strong></td>
                                    <td class="text-right"><strong>RATE</strong></td>
                                    <td class="text-right"><strong>TAX</strong></td>
                                    <td class="text-right"><strong>AMOUNT</strong></td>
                                </tr>
    						</thead>
    						<tbody>
    							<!-- foreach ($order->lineItems as $line) or some such thing here -->
    							<tr>
    								<td class="text-center">1</td>
    								<td class="text-center">Eduvantage Domain Renewal</td>
    								<td class="text-center">998314</td>
    								<td class="text-right">2.0</td>
                                    <td class="text-right">0.0</td>
                                    <td class="text-right">677.97</td>
                                    <td class="text-right">244.07(18.0%)</td>
                                    <td class="text-right">1600.0</td>
    							</tr>
                                <tr>
                                    <td class="text-center">1</td>
    								<td class="text-center">Eduvantage Hosting</td>
    								<td class="text-center">998315</td>
    								<td class="text-right">2.0 PCS</td>
                                    <td class="text-right">3000.0</td>
                                    <td class="text-right">2118.64</td>
                                    <td class="text-right">762.71(18.0%)</td>
                                    <td class="text-right">5000.0</td>
    							</tr>
                                
    							<tr style="border-bottom: 9px inset ;">
    								<td class="text-center">Subtotal</td>
									
    								<td  class="text-center" > </td>
    								<td  class="text-center"> </td>
    								<td class="text-center" > </td>
                                    <td class="text-center" > </td>
    								<td class="text-center"> </td>
    								<td class="text-center"><strong>10006.78</strong></td>
    								<td class="text-center">6600</td>
    							</tr>
    							<tr>
    								<td class="no-line" colspan="3"><strong>TERMS AND CONDITIONS: </strong></td>
    	 
    						  
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"> TAXABLE AMOUNT</td>
    								<td class="no-line text-right">Rs. 5593.22</td>
    							</tr>
    							<tr>
                                    <td class="no-line" colspan="3">1. Goods once sold will not be taken back or exchanged </td>
    							 
    						 
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"> CGST@9.0% </td>
    								<td class="no-line text-right">Rs. 503.39</td>
    							</tr>
                                <tr>
                                    <td class="no-line" colspan="3">2. All disputes are subject to bhopal jurisdiction only</td>
    							 
    						 
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"> SGST@9.0% </td>
    								<td class="no-line text-right">Rs. 503.39</td>
    							</tr>

                                <tr>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong> </strong></td>
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"> FC </td>
    								<td class="no-line text-right">Rs. 200</td>
    							</tr>
                                <tr>
                                    <td class="no-line"><strong>Company's PAN: AABCE284J</strong></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong> </strong></td>
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"> Discount </td>
    								<td class="no-line text-right">Rs. -136</td>
    							</tr>
                                <tr>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong> </strong></td>
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong><b>TOTAL</b></strong></td>
    								<td class="no-line text-right">Rs. 6164</td>
    							</tr>
                                <tr>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong> </strong></td>
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center">Received Amount </td>
    								<td class="no-line text-right">Rs. 500</td>
    							</tr>

                                <tr>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong> </strong></td>
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong>BALANCE</strong></td>
    								<td class="no-line text-right">Rs. 6164</td>
    							</tr>


                                <tr>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong> </strong></td>
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"> Previous Balance </td>
    								<td class="no-line text-right">Rs. 10950.0</td>
    							</tr>

                                <tr>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong> </strong></td>
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"> Current  Balance </td>
    								<td class="no-line text-right">Rs. 17114.0</td>
    							</tr>
                                
                                <tr>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong> </strong></td>
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td colspan="2" class="no-line text-center"> <strong> Invoice Amount (in words)</strong><br>Six thousand six hundred sixty four rupees </td>
    								 
    							</tr>

                                        
                                <tr class="no-line">
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong> </strong></td>
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td colspan="2" class="no-line text-center"><strong> AUTHORISED SIGNATORY FOR </strong></td>
    								 
    							</tr>
                                <tr>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td class="no-line text-center"><strong> </strong></td>
    								<td class="no-line text-right"> </td>
                                    <td class="no-line"></td>
    								<td class="no-line"></td>
    								<td colspan="2" class="no-line text-center">  AISECT LTD  </td>
    								 
    							</tr>


                                
    						</tbody>

    					</table>
    				</div>
    			</div>
    		</div>



    	</div>
    </div>
</div>

 <!-- <button class="btn btn-info" (click)="makePDF()"><img src="..\assets\images\pdf.png" width="80"/> </button> -->
 
	 
	<img    class="buttonpdf"   title ="Download Invoice" (click)="convertToPDF()" src="..\assets\images\pdf.png" width="80"/>  
 