
<form class="form-horizontal" [formGroup]="form"> 


    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">USER ROLE REGISTRATION</h1>


    <div class="card-body" style="background-color:#fff;">
        <h1 class="card-title">
           User Role Detail  
        </h1>
        <div class="row">
           
    
            <div class="position-relative  form-group col-sm-6">
                <label for="RoleTypeName" class="col-form-label">Role Type Name  <span>*</span></label>
                <div class="">
                    <input type="text" name="RoleTypeName" id="RoleTypeName" class="form-control" formControlName="RoleTypeName">
         <app-field-error-display [displayError]="isFieldValid('RoleTypeName')" errorMsg="Please Enter Role Type Name"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
           
            <div class="position-relative  form-group col-sm-6">
                <label for="RoleDesc" class="col-form-label">Role Type Description  <span>*</span></label>
                <div class="">
                    <input type="text" name="RoleDesc" id="RoleDesc" class="form-control" formControlName="RoleDesc">
         <app-field-error-display [displayError]="isFieldValid('RoleDesc')" errorMsg="Please Enter Description"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
             <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    
    