 
<p-toast></p-toast>






<div class="card" style=" overflow-x: scroll;">
    <!-- <p-toolbar styleClass="p-mb-4"> -->
        <!-- <ng-template pTemplate="left"> -->
            <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button> -->
        <!-- </ng-template> -->

        <!-- <ng-template pTemplate="right"> -->

            <!-- <p-multiSelect style="width:200px;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
            <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
        <!-- </ng-template> -->
    <!-- </p-toolbar> -->

    <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="[
    'HeadName',
'HeadDesc',
'IsActive',
'CreatedDate']"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"   [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]">

        
        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">INVOICE PURPOSE  CATEGORY LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                    &nbsp; &nbsp;  <a     [routerLink]="['/Invoice-Heads-Registration']" class="btn btn-success">Add <i class="fa fa-plus" aria-hidden="true"></i></a>
                </span>
            </div>

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
  
        </ng-template>
   
     
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="rating" class="actionclass">	Action<p-sortIcon field="rating"></p-sortIcon></th>
              
                <th pSortableColumn="IsActive" style="width: 150px">IsActive<p-sortIcon field="IsActive"></p-sortIcon></th>
                <th pSortableColumn="HeadName" style="width: 150px">Invoice Purpose Head Category Name<p-sortIcon field="HeadName"></p-sortIcon></th>
                 <th pSortableColumn="HeadDesc" style="width: 150px">  Description<p-sortIcon field="HeadDesc"></p-sortIcon></th>
                          <th pSortableColumn="CreatedDate" style="width: 150px">	Created Date<p-sortIcon field="CreatedDate"></p-sortIcon></th>
                          <th pSortableColumn="UpdatedDate" style="width: 150px">	Updated  Date<p-sortIcon field="UpdatedDate"></p-sortIcon></th>
                
            </tr>


            <tr>
                <th>

                </th>

                <th>    
                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'IsActive', 'contains')" styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                      <ng-template let-option pTemplate="item">
                          <span [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                      </ng-template>
                    </p-dropdown> 
                  </th>
<th>
    <input pInputText type="text" (input)="dt.filter($event.target.value,'HeadName' ,'contains')" placeholder="Search Head Name" />

</th>
 
<th>
<input pInputText type="text" (input)="dt.filter($event.target.value,'HeadDesc' ,'contains')" placeholder="Search Head Desc" />
</th>
 

<th>  
<input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')" placeholder="Search CreatedDate" />
</th>
 
<th>  
    <input pInputText type="text" (input)="dt.filter($event.target.value,'UpdatedDate' ,'contains')" placeholder="Search UpdatedDate" />
    </th>
     
   
                                                                                    

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">
                <td>
                    <span class="p-column-title">Action:</span>  
 
                       <span style="display: flex;align-items: center;justify-content: center;">
                        <a [routerLink]="['/Edit-Invoice-Head-Category',product.InvoiceHeadCategoryID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                style='color: #2459a6;' pTooltip="Edit Invoice Purpose Head Category  Detail"></i></a>
                        <span pTooltip="View Invoice Purpose Head Category Detail"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                        border: 2px solid;
                        border-radius: 5px;' (click)="viewProduct(product.InvoiceHeadCategoryID)"></i> </span>
                    </span>                    
                    
                    
                    </td>

                        <td>
                            <span class="p-column-title">Status:</span> 
                            <span *ngIf="product.IsActive=='1'" class="statusactive">Active</span>
                             <span *ngIf="product.IsActive!='1'" class="statusinactive">Deactive</span>
                             </td>
     
                <td>  <span class="p-column-title">Invoice Purpose Head Category Name:</span> {{product.HeadName}}</td> 
                <td><span class="p-column-title">Description</span>{{product.HeadDesc}}</td>
                <!-- <td>{{product.IsActive}}</td> -->
                 <td><span class="p-column-title">CreatedDate</span> {{product.CreatedDate}}</td> 
               
                
                 <td><span class="p-column-title">Updated Date</span>  {{product.UpdatedDate}}</td> 
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '600px'}" header="INVOICE PURPOSE  DETAILS"  [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <br>
        <div class="container">
            <table class="table table-striped">

                <tbody>
                    <tr>
                        <td>
                            <label for="Organization_Name" class="col-form-label">Head ID:</label>
                        </td>
                        <td>
                            <label for="OrgID" class="col-form-label"> {{productsbyid.InvoiceHeadCategoryID}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="Organization_Name" class="col-form-label">Head Name:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.HeadName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="PANCard" class="col-form-label">Head Desc:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.HeadDesc}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="GSTN" class="col-form-label">Is Active:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid.IsActive}} </label>
                        </td>

                    </tr>
               
                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Created Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.CreatedDate}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Created By </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.CreatedBy}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Updated Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.UpdatedDate}}
                            </label>
                        </td>

                    </tr>

                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Updated By </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid.UpdatedBy}}
                            </label>
                        </td>

                    </tr>

                </tbody>
            </table>
        </div>
 
        
         
    </ng-template>
    
  
    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
   

        <span style="float: left;padding-top: 2%;" *ngIf="productsbyid">
    
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
    
                    Status
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <!-- {{productsbyid.ActiveStatus}} -->
                   <input type="radio" class="form-check-input" checked="!checked" name="radio"
                        [(ngModel)]="productsbyid.IsActive" (change)="handleChange($event,productsbyid.InvoiceHeadCategoryID)"
                        [value]="1">active
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                        [(ngModel)]="productsbyid.IsActive" (change)="handleChange($event,productsbyid.InvoiceHeadCategoryID)"
                        [value]="0">Deactive
                </label>
            </div>
    
        </span>
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" style="float: right;"></button>
            <!-- <button style="color: white;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                (click)="saveProduct()"></button>  this is unused -->
    
    
    
  </ng-template>
</p-dialog>


<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


