
<form class="form-horizontal" [formGroup]="form"> 

    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">INVOICE PURPOSE   HEAD REGISTRATION</h1> 
    <div class="card-body" style="background-color:#fff; ">
        <h1 class="card-title">
           Invoice Purpose  Head Category Registration
        </h1>
        <div class="row">
          
    
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="HeadName" class="col-form-label">Invoice Purpose Category Name  <span>*</span></label>
                <div class="">
                    <input type="text" name="HeadName" id="HeadName" class="form-control" formControlName="HeadName">
         <app-field-error-display [displayError]="isFieldValid('HeadName')" errorMsg="Please Enter Head Name  "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            
            <div class="position-relative  form-group col-sm-6">
                <label for="HeadDesc" class="col-form-label">Desc<span>*</span></label>
                <div class="">
                    <input type="text" name="HeadDesc" id="HeadDesc" class="form-control" formControlName="HeadDesc">
         <app-field-error-display [displayError]="isFieldValid('HeadDesc')" errorMsg="Please Enter Head Desc"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
    
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
        <div class="">

                <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus" >
                        <option value="1">Active</option>
                        <option value="0">InActive</option></select>
                     <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div> -->

            
    
            
          
    
            <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    
    