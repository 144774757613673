 
 
   
   <form class="form-horizontal" [formGroup]="form" *ngFor="let data of products">

    <h1 style="text-align:center;margin-top: 20px;margin-bottom: 20px;font-weight: bold;color:rebeccapurple;">EDIT ORGANIZATION</h1>
        <div class="card-body" style="background-color:#fff; ">
        
        
        
              
            <h1 class="card-title">
                Organization  Detail
            </h1>
         
        <div class="row">


          
                <div class="position-relative  form-group col-sm-6">
                    <label for="OrgName" class="col-form-label">Organization Name  <span>*</span></label>
                    <div   [ngClass]="displayFieldCss('OrgName')">
                     
                     
                        <input name="OrgName" id="OrgName" placeholder="" type="text" class="form-control" formControlName="OrgName" [(ngModel)]="data.OrgName"  >
                     
                        
                         
                        <app-field-error-display [displayError]="isFieldValid('OrgName')" errorMsg="Please Enter Organization  Name"   style="background-color: #791820;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="PANNo" class="col-form-label">PANCard<span>*</span></label>
                    <div class="">
                        <input type="text" name="PANNo" id="PANNo" class="form-control" formControlName="PANNo"  [(ngModel)]="data.PANNo">
                        <app-field-error-display [displayError]="isFieldValid('PANNo')" errorMsg="Please Enter Pancard Number"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
 
        
        
                <div class="position-relative  form-group col-sm-6">
                    <label for="GSTN" class="col-form-label">GSTN<span>*</span></label>
                    <div class="">
                        <input type="text" name="GSTN" id="GSTN" class="form-control" formControlName="GSTN"  [(ngModel)]="data.GSTN">
        
                        <app-field-error-display [displayError]="isFieldValid('GSTN')" errorMsg="Please Enter GSTN"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>

       

<div class="position-relative  form-group col-sm-6"  *ngIf="approverlist">
                    <label for="GSTN" class="col-form-label">Approver Name<span>*</span></label>
                    <div class="">
 
                        <p-dropdown [options]="approverlist" [(ngModel)]="data.AllotedOrgID"
                                [autoDisplayFirst]="false" optionLabel="UserFullName" formControlName="AllotedOrgID"
                                [placeholder]="data.AllotedOrgName" optionValue="OrgID" [filter]="true"
                                filterBy="UserFullName"   >
                            </p-dropdown>
                <app-field-error-display [displayError]="isFieldValid('AllotedOrgID')" errorMsg="Please Enter GSTN"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            </div>
        
           
        
        </div>
        
            <div class="card-body" style="background-color:#fff; ">
                <h1 class="card-title">
                    Contact Detail
                </h1>
            <div class="row">
                <div class="position-relative form-group col-sm-6">
                    <label for="DeskMobileNo" class="col-form-label">Contact Number<span>*</span></label>
                    <div class="">
                        <input type="text" name="DeskMobileNo"  maxlength="10"      id="DeskMobileNo" placeholder=""  class="form-control"  pattern="[1-9]{1}[0-9]{9}"   formControlName="DeskMobileNo" [(ngModel)]="data.DeskMobileNo">
                        <app-field-error-display [displayError]="isFieldValid('DeskMobileNo')" errorMsg="Please Enter Contact Number"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div> 
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="DeskEmailID" class="col-form-label">Email<span>*</span></label>
                    <div class="">
                        <input type="email"  name="DeskEmailID" id="DeskEmailID" class="form-control" formControlName="DeskEmailID" [(ngModel)]="data.DeskEmailID">
         
                        <app-field-error-display [displayError]="isFieldValid('DeskEmailID')" errorMsg="Please Enter Email Id"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                
        
                
                <div class="position-relative  form-group col-sm-6">
                    <label for="BillingAddress" class="col-form-label">Address <span>*</span></label>
                    <div class="">
                        <textarea name="BillingAddress" id="BillingAddress" class="form-control" formControlName="BillingAddress" [(ngModel)]="data.BillingAddress"> </textarea>
         
         
                        <app-field-error-display [displayError]="isFieldValid('BillingAddress')" errorMsg="Please Enter Address"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <!-- <div class="position-relative  form-group col-sm-6">
                    <label for="City" class="col-form-label">City<span>*</span></label>
                    <div class="">
                         <select  class="form-control" name="City" id="City"  formControlName="City"  ><option   disabled selected>Please Select State</option><option [(ngModel)]="data.City">{{data.City}}</option> </select>
                    
                    
                        <app-field-error-display [displayError]="isFieldValid('City')" errorMsg="Please Select City"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="State" class="col-form-label">State <span>*</span></label>
                    <div class="">
                       
                        <select  class="form-control" name="State" id="State"  formControlName="State" ><option   disabled selected> Please Select State</option><option [(ngModel)]="data.State">{{data.State}}</option></select>
                  
                        <app-field-error-display [displayError]="isFieldValid('State')" errorMsg="Please Select State"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div> -->



                
                <div class="position-relative  form-group col-sm-6">
                    <label for="State" class="col-form-label">State  <span>*</span></label>
                    <div class="">
                             <!-- <select  class="form-control" name="State" id="State"  (ngModelChange)="changeState($event)"   formControlName="State" [ngModel]="data.State"> 
                            
                                <option [ngValue]="null" disabled selected >Please Select State</option>
                            
                                <option *ngFor="let statelist of stateList"      [value]="statelist.StateID" >{{statelist.StateName}}</option>
                           </select> -->

                           <div *ngIf="stateList">


                            <p-dropdown [options]="stateList" [(ngModel)]="data.State"
                                [autoDisplayFirst]="false" optionLabel="StateName" formControlName="State"
                                [placeholder]="data.StateName" optionValue="StateID" [filter]="true" (ngModelChange)="changeState($event)" 
                                filterBy="StateName">
                            </p-dropdown>
    
                        </div>

                    <app-field-error-display [displayError]="isFieldValid('State')" errorMsg="Please Select State"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="City" class="col-form-label">City<span>*</span></label>
                    <div class="">
                        <!-- <input type="text" name="City" id="City" class="form-control"> -->
        
                        <!-- <select  class="form-control" name="City" id="City"    formControlName="City"  [ngModel]="data.City">
                            <option [ngValue]="null" disabled selected >Please Select City</option>
                            <option *ngFor="let citylist of cityList"       [value]="citylist.DistrictID"       >{{citylist.DistrictName}}</option>
                          
                        </select> -->

                        

                        
                        <div *ngIf="cityList">


                            <p-dropdown [options]="cityList" [(ngModel)]="data.City"
                                [autoDisplayFirst]="false" optionLabel="DistrictName" formControlName="City"
                                [placeholder]="data.DistrictName" optionValue="DistrictID" [filter]="true"  
                                filterBy="DistrictName">
                            </p-dropdown>

                        </div>
                    
                        <app-field-error-display [displayError]="isFieldValid('City')" errorMsg="Please Select City"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    
                    </div>
                </div>


                <div class="position-relative  form-group col-sm-6">
                    <label for="PinCode" class="col-form-label">PinCode</label>
                    <div class="">
                        <input type="text" name="PinCode" id="PinCode" class="form-control" formControlName="PinCode" [(ngModel)]="data.PinCode">
        
                        <app-field-error-display [displayError]="isFieldValid('PinCode')" errorMsg="Please Enter PinCode"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
              
                 
                <div class="position-relative  form-group col-sm-6">
                    <label for="AuthPersonName" class="col-form-label">Contact Person Name<span>*</span></label>
                    <div class="">
                        <input type="text" name="AuthPersonName" id="AuthPersonName" class="form-control"  formControlName="AuthPersonName" [(ngModel)]="data.AuthPersonName">
        
                        <app-field-error-display [displayError]="isFieldValid('AuthPersonName')" errorMsg="Please Enter  ContactPersonName"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
                <div class="position-relative  form-group col-sm-6">
                    <label for="AuthPersonMobileNo" class="col-form-label">Contact Person No<span>*</span></label>
                    <div class="">
                        <input type="number" name="AuthPersonMobileNo" id="AuthPersonMobileNo" class="form-control"  formControlName="AuthPersonMobileNo" [(ngModel)]="data.AuthPersonMobileNo">
                        <app-field-error-display [displayError]="isFieldValid('AuthPersonMobileNo')" errorMsg="Please Enter  ContactPersonNo"   style="background-color: #f8d7da;
                        border-color: #f5c6cb;" class="alert-danger">
                        </app-field-error-display>
                    </div>
                </div>
            
        
                
                </div>
                </div>
        
                   
        
                <div class="card-body" style="background-color:#fff; ">
                    <h1 class="card-title">
                        Bank Account Detail
                    </h1>
                <div class="row">
                     
                    <div class="position-relative  form-group col-sm-6">
                        <label for="BankAcName" class="col-form-label">Account Name<span>*</span></label>
                        <div class="">
                            <input type="text" name="BankAcName" id="BankAcName" class="form-control"  formControlName="BankAcName" [(ngModel)]="data.BankAcName"> 
                            <app-field-error-display [displayError]="isFieldValid('BankAcName')" errorMsg="Please Enter  Account Name"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="BankAcNo" class="col-form-label">Banck Account No <span>*</span></label>
                        <div class="">
                            <input type="number" name="BankAcNo" id="BankAcNo" class="form-control"  formControlName="BankAcNo" [(ngModel)]="data.BankAcNo">
                            <app-field-error-display [displayError]="isFieldValid('BankAcNo')" errorMsg="Please Enter  Bank Account"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
        
        
                    <div class="position-relative  form-group col-sm-6">
                        <label for="BankName" class="col-form-label">Bank Name<span>*</span></label>
                        <div class="">
                            <input type="text" name="BankName" id="BankName" class="form-control" formControlName="BankName"  [(ngModel)]="data.BankName">
        
                            
                            <app-field-error-display [displayError]="isFieldValid('BankName')" errorMsg="Please Enter  Bank Name"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
        
                    <div class="position-relative  form-group col-sm-6">
                        <label for="BankBranchName" class="col-form-label">Banck Branch<span>*</span></label>
                        <div class="">
                            <input type="text" name="BankBranchName" id="BankBranchName" class="form-control" formControlName="BankBranchName" [(ngModel)]="data.BankBranchName">
                            <app-field-error-display [displayError]="isFieldValid('BankBranchName')" errorMsg="Please Enter  Bank Branch"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        
                        </div>
                    </div>
                    <div class="position-relative  form-group col-sm-6">
                        <label for="IFSCCode" class="col-form-label">Bank IFSC Code <span>*</span></label>
                        <div class="">
                            <input type="text" name="IFSCCode" id="IFSCCode" class="form-control" formControlName="IFSCCode" [(ngModel)]="data.IFSCCode">
        
        
                             <app-field-error-display [displayError]="isFieldValid('IFSCCode')" errorMsg="Please Enter  Bank IFSC Code"   style="background-color: #f8d7da;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                    
                    <div class="position-relative  form-group col-sm-6">
                        <label for="ActiveStatus" class="col-form-label">Active Status <span>*</span></label>
                        <div class="">
 
                <select name="ActiveStatus" id="ActiveStatus" class="form-control" formControlName="ActiveStatus"  [(ngModel)]="data.ActiveStatus">
                    <option value="1">Active</option>
                    <option value="0">InActive</option></select>
                             <app-field-error-display [displayError]="isFieldValid('ActiveStatus')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                            border-color: #f5c6cb;" class="alert-danger">
                            </app-field-error-display>
                        </div>
                    </div>
                     
            
                    <div class="position-relative  form-check col-sm-6 offset-sm-6 ">
                        <div class="">
        
                            <br>
                            <br>
                            <br>
                            <button class="btn btn-primary btn-lg" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>  
            
                    </div>
           
          
                