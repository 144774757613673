import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Product } from '../product';
import { ProductService } from '../productservice';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../customer";
import { CustomerService } from "../CustomerService";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BilldeskService } from '../billdesk.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { environment } from '../../environments/environment';



import { printDiv } from './print-div';

export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor: string;
}
@Component({
  selector: 'app-invoicedetail',
  templateUrl: './invoicedetail.component.html',
  styleUrls: ['./invoicedetail.component.css']
})
export class InvoicedetailComponent implements OnInit {
  visibleloader: any = false;
  empList: any;
  productDialog: boolean;
  stagelist: any;
  products: Product[];
  InvoiceID: any = '';
  product: Product;
  id: any;
  stagename: any;
  buttonhide = true;
  status: any;
  paymentupdateDialog: boolean;
  statusupdateDialog: boolean;
  form: FormGroup;
  statusform: FormGroup;
  paymentform: FormGroup;
  otpform: FormGroup;
  description: any;
  selectedProducts: any;
  yearsdetail: any;
  dialogdata: any;
  trackingdata: any;
  submitted: boolean;

  isOtpInvalidError = true;

  isOtpEmptyError = true;
  //   InvoiceID:any;
  PayInvoiceDate: any;
  buttonhidenew: any;
  statuses: any[];
  cities: City[];
  TrackingID1: any;
  customers: Customer[];
  successDialog: boolean;
  representatives: Representative[];
  updateDialog: boolean;
  loading: boolean = true;
  inventoryStatus: Representative[];
  selectedCities: City[];
  selectedStageId: any;
  UserTypeID: any;
  UserID: any;
  selectedStageName = "Please Select Stage";
  buttonaccess: any;
  RespTransactionID: any;
  StageID1: any;
  StageName1: any;
  id1: any;
  id2: any;
  id3: any;
  TrackingID: any;
  StageName: any;
  StageID: any;
  // session=true;
  session = true;
  localValue: any;
  createddata: any;
  otpDialog: boolean;

  otpverify:any;
  ServiceUrl = environment.baseUrl;
  constructor(private fb: FormBuilder, private productService: ProductService, private http: HttpClient, private messageService: MessageService, private _Activatedroute: ActivatedRoute, private confirmationService: ConfirmationService, private billdeskService: BilldeskService, private route: Router, public dialog: MatDialog) {
    this._Activatedroute.paramMap.subscribe((params: any) => {
      this.id = params.params.id;
      this.id1 = params.params.id1;
      this.id2 = params.params.id2;
      this.id3 = params.params.id3;
    });
  }

  ngOnInit() { 



this.isOtpInvalidError=true;

        
this.isOtpEmptyError=true;
    // this.id= invoice id 
    //this.id1= userid
    // this.id2= usertypeid
    // this.id3=timestamp
    this.otpverify=localStorage.getItem('otpverify'); 
console.log("this.isOtpInvalidError",this.isOtpInvalidError);
 
    console.log(this.otpverify);
    if(this.otpverify==null){ 
      this.otpDialog = true;
    }
    else if(this.otpverify=='false'){ 
      this.otpDialog = true;
    }
    else{ 
      this.otpDialog = false;
    }
    this.buttonhidenew = false;
    this.createddata = true;

    this.statusform = this.fb.group({
      StageID: [null, Validators.required],
      Message: [null, Validators.required]
    });


    this.otpform = this.fb.group({
      first: [null, Validators.required],
      second: [null, Validators.required],
      third: [null, Validators.required],
      fourth: [null, Validators.required]
    });
    this.statuses = [
      { label: "Pending", value: "Pending" },
      { label: "Success", value: "Success" }
    ];

    if (this.id == '') {
      this.route.navigate(['/']);
    }

    if (this.id3 == '') {
      this.route.navigate(['/']);
    }

    if (this.id2 == '1' || this.id2 == '2' || this.id2 == '4') {
      this.buttonaccess = true;
    }


    this.billdeskService.getInvoiceByidopen(this.id).then((res: any) => {
      this.products = res;
      this.stagename = res[0].StageName;

      if (this.id2 == 1 && (res[0].StageID == 1 || res[0].StageID == 8)) {
        this.buttonhidenew = true;
      }

      if (this.id2 == 2 && res[0].StageID == 2) {
        this.buttonhidenew = true;
      }

      if (this.id2 == 5) {
        this.buttonhidenew = true;

        this.buttonhidenew = true;
        if (res[0].StageID == 6) {
          this.buttonhidenew = false;
        }
        if (res[0].StageID == 7) {
          this.buttonhidenew = false;
        }
      }

      if (this.stagename == 'PO Batch List Created') {
        this.createddata = false;
      }
      // button hide code



      this.billdeskService.getStageByRoleType(this.id2, res[0].StageID).then(res => this.stagelist = res);
    });



    this.http.get(this.ServiceUrl + '/invoicetracking/invoicetrackinglistopen?InvoiceID=' + this.id + '&RoleTypeID=' + this.id2).subscribe((profileres: any) => {
      this.StageID = (profileres.invoicetrackinglistopenResult.dataobject[1] || {}).StageID || '';
      this.StageName = (profileres.invoicetrackinglistopenResult.dataobject[1] || {}).StageName || '';
      this.TrackingID = (profileres.invoicetrackinglistopenResult.dataobject[1] || {}).TrackingID || '';
      this.StageID1 = (profileres.invoicetrackinglistopenResult.dataobject[2] || {}).StageID || '';
      this.StageName1 = (profileres.invoicetrackinglistopenResult.dataobject[2] || {}).StageName || '';
      this.TrackingID1 = (profileres.invoicetrackinglistopenResult.dataobject[2] || {}).TrackingID || '';

  
    });


    // setTimeout(function(){
    //   localStorage.removeItem('otpverify');
    //   }, 
    //   1 * 1000);


  }//EOF ngoninit

// ngOnDestroy(): void {
//     localStorage.removeItem('otpverify'); 
//  }


 
  openNew() {
    this.product = {};
    this.submitted = false;
    this.productDialog = true;
  }//EOF openNew

  statusUpdateDialog() {
    this.statusupdateDialog = true;
  }
  deleteSelectedProducts() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected products?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.products = this.products.filter(val => !this.selectedProducts.includes(val));
        this.selectedProducts = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
      }
    });
  }
  paymentUpdateDialog() {
    this.paymentupdateDialog = true;
  }

  editProduct(product: Product) {
    this.product = { ...product };
    this.productDialog = true;
  }

  viewProduct(InvoiceId: any) {
    this.billdeskService.getTrackingStatusbyEmail(InvoiceId, this.id2).then(res => this.trackingdata = res);
    this.productDialog = true;
  }

  successButton() {
    this.successDialog = true;
  }


  statusupdateButton() {
    this.updateDialog = true;
  }


  deleteProduct(product: Product) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + product.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.successButton();
      }
    });
  }

  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
    this.statusupdateDialog = false;
  }






  move(a: any, b: any, c: any, d: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');
    if (length == maxlength) {
      if (d != "") {
        d.focus();
      }
    }
    if (a.key == 'Backspace') {
      b.focus();
    }
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }






  onClicklogin() {



    var otp = this.otpform.get('first').value;

    var otp1 = this.otpform.get('second').value;
    var otp2 = this.otpform.get('third').value;
    var otp3 = this.otpform.get('fourth').value;




    if (otp == null) {
      this.isOtpEmptyError = false;
      return;
    }


    if (otp1 == null) {
      this.isOtpEmptyError = false;
      return;
    }

    if (otp2 == null) {
      this.isOtpEmptyError = false;
      return;
    }


    if (otp3 == null) {
      this.isOtpEmptyError = false;
      return;
    }
    let totalotp = [otp, otp1, otp2, otp3].join('');

    console.log(totalotp);
 this.http.get(this.ServiceUrl + '/mailerpin/checkmailerpinopen?UserID=' + this.id1 + '&UserPin='+totalotp+'&RoleTypeID=' + this.id2+'&InvoiceID='+this.id).subscribe((result: any) => {
 
console.log(result);
console.log(result.checkmailerpinopenResult.Message)
      if (result.checkmailerpinopenResult.Message == 'Sucess') {
        // this.otpDialog = false;
        localStorage.setItem('otpverify', 'true'); 
        window.location.reload();
      }
      else {
        // this.otpDialog = true;
  console.log('False')
        localStorage.setItem('otpverify', 'false'); 
        this.isOtpInvalidError = false;
        this.isOtpEmptyError = true;


        
// this.isOtpInvalidError=true;
      }
    });




  }


  //EOF onClicklogin


  saveProduct() {
    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id) {
        this.products[this.findIndexById(this.product.id)] = this.product;
        this.statusupdateButton();
      }
      else {
        this.product.id = this.createId();
        this.product.image = 'product-placeholder.svg';
        this.products.push(this.product);
      }
      this.productDialog = false;
      this.product = {};
    }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }


  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }


  StatusUpdatePopup() {
    localStorage.removeItem('statusupdate');
    setTimeout(() => {
      this.openDialogStatusUpdate();
      this.hideDialog();
    }, 500);
  }



  openDialogStatusUpdate(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "Invoice", Detail: "Status Update Successfully", Backgroundcolor: "#0288d1" }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }

  statusupdate(InvoiceID: any, VendorInvoiceNo: any) {
    var obj2 = this.statusform.value;
    var obj1 =
    {
      "flag": "MSTItemCatagory_INSERT",
      "UserID": this.id1,
      "UserRoleTypeID": this.id2,
      "InvoiceID": this.id,
      "VendorInvoiceNo": VendorInvoiceNo,
      "CreatedBy": "1",
      "CreatedByID": "2",
      "UpdatedDate": "",
      "UpdatedBy": "",
    };

    var obj2 = this.statusform.value;

    var form_data = Object.assign(obj1, obj2);

    const datarequest = {
      "CheckSum": "",
      "lead_id": "",
      "ActionFor": "Insert",
      "dataobject": [
        form_data
      ]
    };

    if (this.statusform.valid) {
      this.visibleloader = true;
      let url = this.ServiceUrl + "/invoicetracking/invoicetrackingregistrationopen";
      this.http.post(url, datarequest).toPromise().then((data: any) => {
        if (data.invoicetrackingregistrationopenResult.Message == 'Sucess') {

          this.visibleloader = false;
          this.StatusUpdatePopup();
          localStorage.setItem('statusupdate', 'Success');
          setTimeout(() => {
            setTimeout(() => {
              this.route.navigate(['/InvoiceList']);
            });
          }, 2000);

        } else {
          console.log('Please Try Again Later');
        }
      });
    }
    else {
      this.validateAllFormFields(this.statusform);
    }
  }


  print_page() {
    setTimeout(() => printDiv('content'), 3000);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}


interface City {
  name: string,
  code: string
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
    window.location.reload();
  }

}