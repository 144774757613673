<div class="card-body" style="background-color:#fff; margin:50px;">
    <h1 class="card-title"  style="font-weight:bold;">
        PO Batch Details
    </h1>
    <form class="row">
        <div class="position-relative  form-group col-sm-6">
            <label for="Organization_Name" class="col-form-label">PO Batch Name <span>*</span></label>
            <div class="">
                <input name="Organization_Name" id="Organization_Name" placeholder="PO Batch Name" type="text" class="form-control">
            </div>
        </div>
        <div class="position-relative form-group col-sm-6">
            <label for="Contact_Number" class="col-form-label">PO Batch NUmber<span>*</span></label>
            <div class="">
                <input name="Contact_Number" id="Contact_Number" placeholder="PO Batch NUmber" type="number" class="form-control">
            </div>
        </div>
         
 
    </form>
</div>




<div class="card-body" style="background-color:#fff; margin:50px;">
    <h1 class="card-title"  style="font-weight:bold;">
       Invoice Summary
    </h1>
    <form class="row">

        <div class="position-relative form-group col-sm-6">
            <label for="Contact_Number" class="col-form-label" style="font-weight:bold;font-size:17px;">Total invoices in this batch:  </label>
            
        </div> 
        <div class="position-relative  form-group col-sm-6">
            <label for="Organization_Name" class="col-form-label"  style="font-weight:bold;font-size:17px;">Total invoice amount : 254525  </label>
             
        </div>
        
      
    </form>
</div>





<div class="card-body" style="background-color:#fff; margin:50px;">
    <h1 class="card-title"  style="font-weight:bold;">
        Selected Invoices  List
    </h1>
      
<p-toast></p-toast>






<div class="card" style=" overflow-x: scroll;">
    <!-- <p-toolbar styleClass="p-mb-4"> -->
        <!-- <ng-template pTemplate="left"> -->
            <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button> -->
        <!-- </ng-template> -->

        <!-- <ng-template pTemplate="right"> -->

            <!-- <p-multiSelect style="width:200px;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
            <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
        <!-- </ng-template> -->
    <!-- </p-toolbar> -->

    <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="['name','country.name','inventoryStatus']"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">

        
        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
            <div class="p-d-flex p-ai-center p-jc-between">
                <h5 class="p-m-0">Manage Products</h5>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                </span>
            </div>

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->
  
        </ng-template>
   
     
        <ng-template pTemplate="header">
            <tr>
             
                <th pSortableColumn="name" style="width: 150px">BIll Id <p-sortIcon field="name"></p-sortIcon></th>
                <th pSortableColumn="price" style="width: 150px"> Institute Name <p-sortIcon field="price"></p-sortIcon></th>
                <th pSortableColumn="category" style="width: 150px">Vendor Name <p-sortIcon field="category"></p-sortIcon></th>
                <th pSortableColumn="inventoryStatus" style="width: 150px">Domain Name <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
                <th pSortableColumn="inventoryStatus" style="width: 150px">Invoice No <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
                <th pSortableColumn="inventoryStatus" style="width: 150px">Invoice Date <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
                
                <th pSortableColumn="rating" style="width: 150px">Invoice Status <p-sortIcon field="rating"></p-sortIcon></th>
               
            </tr>


            <tr>
                <td>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'id' ,'contains')" placeholder="Search Bill Id" />

                </td>
                <td>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'name' ,'contains')" placeholder="Search name" />

                </td>
<td>

    <input pInputText type="text" (input)="dt.filter($event.target.value,'price' ,'contains')" placeholder="Vendor Search" />
</td>
<td>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'price' ,'contains')" placeholder="Searchprice" />
</td>
<td>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'category' ,'contains')" placeholder="Search category" />
</td>
<td>
    
<input pInputText type="text" (input)="dt.filter($event.target.value,'email' ,'contains')" placeholder="Search date" />
</td>

 
<th>	
        
  

    <p-columnFilter field="inventoryStatus" matchMode="in" [showMenu]="false">
        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)"
                placeholder="Any" [showClear]="true" >
                <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge inventoryStatus-' + option.value">{{option.label}}</span>
                </ng-template>
            </p-multiSelect>
        </ng-template>
    </p-columnFilter>

 

</th>
 
      


            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td> 
                    {{product.id}}

                    
                </td>
                <td>{{product.name}}</td>
                 <td>{{product.description }}</td>
                <td>{{product.price  }}</td>
                <td>{{product.category}}</td>
              
                <td>{{product.date}}</td>
                <!-- <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                <td>{{product.inventoryStatus}}</td>
                <!-- <td> -->
                    <!-- <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProduct(product)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProduct(product)"></button> -->
                <!-- </td> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '450px'}" header="Product Details" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image">
        <div class="p-field">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" [(ngModel)]="product.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !product.name">Name is required.</small>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea id="description" pInputTextarea [(ngModel)]="product.description" required rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="inventoryStatus">Inventory Status</label>
            <p-dropdown [(ngModel)]="product.inventoryStatus" inputId="inventoryStatus" [options]="statuses" placeholder="Select">
                <ng-template let-option pTemplate="item">
                    <span [class]="'product-badge status-' + option.value">{{option.label}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="p-field">
            <label class="p-mb-3">Category</label>
            <div class="p-formgrid p-grid">
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category1" name="category" value="Accessories" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category1">Accessories</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category2" name="category" value="Clothing" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category2">Clothing</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category3" name="category" value="Electronics" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category3">Electronics</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                    <p-radioButton id="category4" name="category" value="Fitness" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category4">Fitness</label>
                </div>
            </div>
        </div>

        <div class="p-formgrid p-grid">
            <div class="p-field p-col">
                <label for="price">Price</label>
                <p-inputNumber id="price" [(ngModel)]="product.price" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
            </div>
            <div class="p-field p-col">
                <label for="quantity">Quantity</label>
                <p-inputNumber id="quantity" [(ngModel)]="product.quantity"></p-inputNumber>
            </div>
        </div>
    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>



</div>





<div class="card-body" style="background-color:#fff; margin:50px; ">
    <a   style="   
   
    margin-left: auto;
    margin-right: auto;
    width: 40%;" routerLink="/PO-List"   class="btn btn-info btn-lg" onclick="confirm('Po Batch Created Successfully')">  Create PO Batch  </a>
   
</div>
 

