
<form class="form-horizontal" [formGroup]="form"  *ngFor="let data of products"> 
    <div class="card-body" style="background-color:#fff; margin:100px;">
        <h1 class="card-title">
        Edit   Invoice Purpose Head Category
        </h1>
        <div class="row">
          
    
    
    
            <div class="position-relative  form-group col-sm-6">
                <label for="HeadName" class="col-form-label">Invoice Purpose Head Category Name  <span>*</span></label>
                <div class="">
                    <input type="text" name="HeadName" id="HeadName" class="form-control" formControlName="HeadName" [(ngModel)]="data.HeadName" >
         <app-field-error-display [displayError]="isFieldValid('HeadName')" errorMsg="Please Enter Head Name  "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
            
            <div class="position-relative  form-group col-sm-6">
                <label for="HeadDesc" class="col-form-label">Desc<span>*</span></label>
                <div class="">
                    <input type="text" name="HeadDesc" id="HeadDesc" class="form-control" formControlName="HeadDesc" [(ngModel)]="data.HeadDesc">
         <app-field-error-display [displayError]="isFieldValid('HeadDesc')" errorMsg="Please Enter Head Desc"   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div>
    
    
            
            <!-- <div class="position-relative  form-group col-sm-6">
                <label for="IsActive" class="col-form-label">Is Active<span>*</span></label>
                <div class="">
                 
         
         
         <select  name="IsActive" id="IsActive" class="form-control" formControlName="IsActive"  [ngModel]="data.IsActive">
            <option [ngValue]="null" disabled selected >Select Status</option>
<option value="1">Active</option>
<option value="0">In Active</option>

         </select>
         <app-field-error-display [displayError]="isFieldValid('IsActive')" errorMsg="Please Enter Is Active "   style="background-color: #791820;
                    border-color: #f5c6cb;" class="alert-danger">
           </app-field-error-display> 
                </div>
            </div> -->
    
    
            
                      <!-- <div class="position-relative  form-group col-sm-6">
                <label for="IsActive" class="col-form-label">Active Status <span>*</span></label>
                <div class="">

        <select name="IsActive" id="IsActive" class="form-control" formControlName="IsActive"  [(ngModel)]="data.IsActive">
            <option value="1">Active</option>
            <option value="0">InActive</option></select>
                     <app-field-error-display [displayError]="isFieldValid('IsActive')" errorMsg="Please Enter  Active Status"   style="background-color: #cf3542;
                    border-color: #f5c6cb;" class="alert-danger">
                    </app-field-error-display>
                </div>
            </div> -->

            
    
            
            
    
            
          
    
            <div class="position-relative  form-check col-sm-6 offset-sm-6">
                <div class="">
                    <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
                </div>
            </div>
       
    </div>
    
    