import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-selection-examplee',
  templateUrl: './single-selection-examplee.component.html',
  styleUrls: ['./single-selection-examplee.component.css']
})
export class SingleSelectionExampleeComponent implements OnInit {
  filteredString:string='';

  users=[{
    name:'Leela',
    joinedDate:new Date(15,2,2016)
  },{
    name:'Suit',
    joinedDate:new Date(12,2,2017)
  },{
    name:'Rama',
    joinedDate:new Date(10,2,2019)
  },]
  constructor() { }

  ngOnInit(): void {
  }

}
