import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import{ HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import{ReactiveFormsModule,FormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component'; 
import { HomeComponent } from './home/home.component';  
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from  "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule} from "@angular/material/toolbar";
import {MatMenuModule} from '@angular/material/menu';
import  { MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatGridListModule} from '@angular/material/grid-list';
import { ChartModule } from 'primeng/chart';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion'; 
import { LoginComponent } from './login/login.component';
import { MatCardModule } from '@angular/material/card';
import { InvoiceRegistrationComponent } from './invoice-registration/invoice-registration.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceHistoryListComponent } from './invoice-history-list/invoice-history-list.component';
import { UserListComponent } from './user-list/user-list.component';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import {  MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatSortModule } from "@angular/material/sort";
import { MatCheckboxModule } from '@angular/material/checkbox';  
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ProductService } from './productservice'; 
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InvoicehistoryComponent } from './invoicehistory/invoicehistory.component';
import { EditinvoiceregistrationComponent } from './editinvoiceregistration/editinvoiceregistration.component';
import { PocreationComponent } from './pocreation/pocreation.component';
import { PocreationpartComponent } from'./pocreationpart/pocreationpart.component';
import {PoListComponent} from './po-list/po-list.component';
import {PoDetailsComponent} from './po-details/po-details.component';
import { OrganizationlistComponent} from './organizationlist/organizationlist.component';
import{ InstituteRegistrationComponent } from './institute-registration/institute-registration.component';
import{ InstituteListComponent } from './institute-list/institute-list.component';
import{ DepartmentRegistrationComponent } from './department-registration/department-registration.component';
import{ DepartmentListComponent } from './department-list/department-list.component';
import{ DomainRegistrationComponent } from './domain-registration/domain-registration.component';
import{ DomainListComponent } from './domain-list/domain-list.component';
import{ VendorRegistrationComponent } from './vendor-registration/vendor-registration.component';
import{VendorListComponent} from './vendor-list/vendor-list.component';
import{InvoiceHeadsRegistrationComponent} from './invoice-heads-registration/invoice-heads-registration.component';
import{InvoiceHeadsListComponent} from './invoice-heads-list/invoice-heads-list.component';
import{CategoryRegistrationComponent} from './category-registration/category-registration.component';
import{CategoryListComponent} from './category-list/category-list.component';
import{UserRegistrationComponent} from './user-registration/user-registration.component';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { EditorganizationComponent } from './editorganization/editorganization.component';
import { InnvoicedetailComponent } from './innvoicedetail/innvoicedetail.component';
import { CountdownModule } from 'ngx-countdown';
import { OrganizationregistrationdetailComponent } from './organizationregistrationdetail/organizationregistrationdetail.component';
import { StageRegistrationComponent } from './stage-registration/stage-registration.component';
import { StageListComponent } from './stage-list/stage-list.component';
 
import { InvoiceForRegistrationComponent } from './invoice-for-registration/invoice-for-registration.component';
import { InvoiceForListComponent } from './invoice-for-list/invoice-for-list.component';
import { UserRoleComponent } from './user-role/user-role.component';
import { UserRoleRegistrationComponent } from './user-role-registration/user-role-registration.component';
import { ReportSearchInvoiceComponent } from './report-search-invoice/report-search-invoice.component';
import { InstituewisesummaryComponent } from './instituewisesummary/instituewisesummary.component';
import { MstmenuregistrationComponent } from './mstmenuregistration/mstmenuregistration.component';
import { MstmenulistComponent } from './mstmenulist/mstmenulist.component';
import { UserrolerightmappingComponent } from './userrolerightmapping/userrolerightmapping.component';
import { UserrolerightmappinglistComponent } from './userrolerightmappinglist/userrolerightmappinglist.component';
import { RolemenumappingComponent } from './rolemenumapping/rolemenumapping.component';
import { RolemenumappinglistComponent } from './rolemenumappinglist/rolemenumappinglist.component';
import { PriorityregisterComponent } from './priorityregister/priorityregister.component';
import { PrioritylistComponent } from './prioritylist/prioritylist.component';
import { InvoicedetailComponent } from './invoicedetail/invoicedetail.component';
import { InvoiceheaditemregistrationComponent } from './invoiceheaditemregistration/invoiceheaditemregistration.component';
import { InvoiceheaditemlistComponent } from './invoiceheaditemlist/invoiceheaditemlist.component';
import { ReportMonthWiseComponent } from './report-month-wise/report-month-wise.component'; 
import { ReportInvoiceYearlyComponent } from './report-invoice-yearly/report-invoice-yearly.component';
import { ReportVendorWiseComponent } from './report-vendor-wise/report-vendor-wise.component';
import { ReportDepartmentWiseComponent } from './report-department-wise/report-department-wise.component';
import { ReportMonthVendorWiseComponent } from './report-month-vendor-wise/report-month-vendor-wise.component';
import { ReportInstituteWiseComponent } from './report-institute-wise/report-institute-wise.component';
import { ReportVendorInstituteWiseComponent } from './report-vendor-institute-wise/report-vendor-institute-wise.component';
import { ReportVendorServiceWiseComponent } from './report-vendor-service-wise/report-vendor-service-wise.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EditdivisionComponent } from './editdivision/editdivision.component';
import { EditdepartmentComponent } from './editdepartment/editdepartment.component';
import { EdititemcategoryComponent } from './edititemcategory/edititemcategory.component';
import { EdititemComponent } from './edititem/edititem.component';
import { EditinvoiceforComponent } from './editinvoicefor/editinvoicefor.component';
import { EditinvoiceheadcategoryComponent } from './editinvoiceheadcategory/editinvoiceheadcategory.component';
import { EditinvoiceheaditemComponent } from './editinvoiceheaditem/editinvoiceheaditem.component';
import { EditvendorComponent } from './editvendor/editvendor.component';
import { EditstageComponent } from './editstage/editstage.component';
import { EditpriorityComponent } from './editpriority/editpriority.component';
import { EdituserComponent } from './edituser/edituser.component';
import { EditroleComponent } from './editrole/editrole.component';
import { EditmenuComponent } from './editmenu/editmenu.component';
import { GenerateinvoiceComponent } from './Invoice/generateinvoice/generateinvoice.component';
import { DropdownSearchComponent } from './table/hello';
import {APP_BASE_HREF} from '@angular/common';
import { PoDetailFullpageComponent } from './po-detail-fullpage/po-detail-fullpage.component';
import { PolistByMonthComponent } from './polist-by-month/polist-by-month.component';
import { PoListByMonthYearComponent } from './po-list-by-month-year/po-list-by-month-year.component';  
import { InterpreterService } from './interpreter.service';
import { BillInvoiceRegistrationComponent } from './bill-invoice-registration/bill-invoice-registration.component';
import { SingleSelectionExampleeComponent } from './single-selection-examplee/single-selection-examplee.component';
// import { ChartsModule } from 'ng2-charts';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { InvoicegenerationprocessedComponent } from './invoicegenerationprocessed/invoicegenerationprocessed.component';
import { TableComponent } from './table/table.component';


import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { SearchdrowpdownComponent } from './searchdrowpdown/searchdrowpdown.component';

import { MasterService } from './service/master.service'; 
import { PobathlistyearandmonthComponent } from './pobathlistyearandmonth/pobathlistyearandmonth.component';
import { PoSummaryComponent } from './po-summary/po-summary.component';
import { PosummarysheetagroupbyComponent } from './posummarysheetagroupby/posummarysheetagroupby.component';
import { Pobatchlistsheetb2Component } from './pobatchlistsheetb2/pobatchlistsheetb2.component';
import { TableShowComponent } from './table-show/table-show.component';
import { PaginatorModule } from 'primeng/paginator';

import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { UnauthrizedComponent } from './NewModule/unauthrized/unauthrized.component';
import { CustomModule } from './pages/custom/custom.module';
 
import { SearchDropdown } from './table/search'; 
import { QRCodeModule } from 'angularx-qrcode';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { PurchaseListComponent } from './inventory/purchase-list/purchase-list.component';
import { BrandListComponent } from './inventory/brand-list/brand-list.component';
import { UnitListComponent } from './inventory/unit-list/unit-list.component';
import { PriceTypeListComponent } from './inventory/price-type-list/price-type-list.component';   

import { InventoryManagementModule } from './inventory/inventory-management/inventory-management.module'; 
import { BrandEntryComponent } from './inventory/brand-entry/brand-entry.component';
import { UnitEntryComponent } from './inventory/unit-entry/unit-entry.component'; 
import { EditBrandComponent } from './inventory/edit-brand/edit-brand.component';
import { EditPriceTypeComponent } from './inventory/edit-price-type/edit-price-type.component';
import { PriceTypeEntryComponent } from './inventory/price-type-entry/price-type-entry.component';
import { CarecterhidePipe } from './login/carecterhide.pipe';

import { showcharacterComponent } from './login/showcharacter.component';
import { PurchaseEntryComponent } from './inventory/purchase-entry/purchase-entry.component';

import { SidebarModule } from 'primeng/sidebar';

import { CardModule } from 'primeng/card';
import { SalesEntryComponent } from './inventory/sales-entry/sales-entry.component';
import { SalesListComponent } from './inventory/sales-list/sales-list.component';
import { StockListComponent } from './inventory/stock-list/stock-list.component';
import { ExportStockComponent } from './inventory/export-stock/export-stock.component';
import { InstocksummaryComponent } from './inventory/instocksummary/instocksummary.component';
import { AutofocusDirective } from './login/autofocus.directive';
import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';
import { ProductcreatebyitemComponent } from './inventory/productcreatebyitem/productcreatebyitem.component';
import { ProductcreatebyitemlistComponent } from './inventory/productcreatebyitemlist/productcreatebyitemlist.component';
import { GroupbysaleComponent } from './inventory/groupbysale/groupbysale.component';
import { ColumnhideinvoicelistComponent } from './columnhideinvoicelist/columnhideinvoicelist.component';
import { YearwisesummaryComponent } from './yearwisesummary/yearwisesummary.component';
import { SaleinvoiceComponent } from './inventory/saleinvoice/saleinvoice.component';
import { NumberpipePipe } from './inventory/numberpipe.pipe';
import { OnlinestatusComponent } from './onlinestatus/onlinestatus.component';
import { EditUnitComponent } from './inventory/edit-unit/edit-unit.component';
import { HinglishTypingDirective } from './common/hinglish-typing.directive';
import { SaletypelistComponent } from './saletypelist/saletypelist.component';
import { SaletyperegistrationComponent } from './saletyperegistration/saletyperegistration.component';
import { SalereportdatewiseComponent } from './inventory/salereportdatewise/salereportdatewise.component';
import { BoxMakingComponent } from './inventory/box-making/box-making.component';
import { BoxMakingListComponent } from './inventory/box-making-list/box-making-list.component'; 
import { BoxStockInComponent } from './inventory/box-stock-in/box-stock-in.component';
import { BoxStockOutComponent } from './inventory/box-stock-out/box-stock-out.component';
import { BoxsalelistComponent } from './inventory/boxsalelist/boxsalelist.component';
 
@NgModule({
    declarations: [
        AppComponent,
        showcharacterComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        LoginComponent,
        DropdownSearchComponent,
        SearchDropdown,
        InvoiceRegistrationComponent,
        InvoiceListComponent,
        InvoiceHistoryListComponent,
        UserListComponent,
        InvoicehistoryComponent,
        EditinvoiceregistrationComponent,
        PocreationComponent,
        PocreationpartComponent,
        PoListComponent,
        PoDetailsComponent,
        OrganizationlistComponent, InstituteRegistrationComponent, InstituteListComponent,
        DepartmentRegistrationComponent,
        DepartmentListComponent,
        DomainRegistrationComponent,
        DomainListComponent,
        VendorRegistrationComponent,
        InvoiceHeadsRegistrationComponent,
        InvoiceHeadsListComponent,
        CategoryRegistrationComponent,
        CategoryListComponent,
        UserRegistrationComponent,
        VendorListComponent,
        FieldErrorDisplayComponent,
        EditorganizationComponent,
        InnvoicedetailComponent,
        OrganizationregistrationdetailComponent,
        StageRegistrationComponent,
        StageListComponent,
        InvoiceForRegistrationComponent,
        InvoiceForListComponent,
        UserRoleComponent,
        UserRoleRegistrationComponent,
        ReportSearchInvoiceComponent,
        InstituewisesummaryComponent,
        MstmenuregistrationComponent,
        MstmenulistComponent,
        UserrolerightmappingComponent,
        UserrolerightmappinglistComponent,
        RolemenumappingComponent,
        RolemenumappinglistComponent,
        PriorityregisterComponent,
        PrioritylistComponent,
        InvoicedetailComponent,
        InvoiceheaditemregistrationComponent,
        InvoiceheaditemlistComponent,
        ReportMonthWiseComponent,
        ReportInvoiceYearlyComponent,
        ReportVendorWiseComponent,
        ReportDepartmentWiseComponent,
        ReportMonthVendorWiseComponent,
        ReportInstituteWiseComponent,
        ReportVendorInstituteWiseComponent,
        ReportVendorServiceWiseComponent,
        EditdivisionComponent,
        EditdepartmentComponent,
        EdititemcategoryComponent,
        EdititemComponent,
        EditinvoiceforComponent,
        EditinvoiceheadcategoryComponent,
        EditinvoiceheaditemComponent,
        EditvendorComponent,
        EditstageComponent,
        EditpriorityComponent,
        EdituserComponent,
        EditroleComponent,
        EditmenuComponent,
        GenerateinvoiceComponent,
        PoDetailFullpageComponent,
        PolistByMonthComponent,
        PoListByMonthYearComponent,
        BillInvoiceRegistrationComponent,
        SingleSelectionExampleeComponent,
        InvoicegenerationprocessedComponent,
        TableComponent,
        PobathlistyearandmonthComponent, PoSummaryComponent, PosummarysheetagroupbyComponent, Pobatchlistsheetb2Component, TableShowComponent, UnauthrizedComponent, PurchaseListComponent, BrandListComponent, UnitListComponent, PriceTypeListComponent, BrandEntryComponent, UnitEntryComponent, EditBrandComponent, EditPriceTypeComponent, PriceTypeEntryComponent, CarecterhidePipe, PurchaseEntryComponent, SalesEntryComponent, SalesListComponent, StockListComponent, ExportStockComponent, InstocksummaryComponent, AutofocusDirective, SearchDropdownComponent, ProductcreatebyitemComponent, ProductcreatebyitemlistComponent, GroupbysaleComponent, ColumnhideinvoicelistComponent, YearwisesummaryComponent, SaleinvoiceComponent, NumberpipePipe, OnlinestatusComponent, EditUnitComponent,HinglishTypingDirective, SaletypelistComponent, SaletyperegistrationComponent, SalereportdatewiseComponent, BoxMakingComponent, BoxMakingListComponent, BoxStockInComponent, BoxStockOutComponent, BoxsalelistComponent
    ],
    exports: [
        MatSortModule,
    ],
    providers: [MessageService, ConfirmationService
        // ,ProductService,{provide: APP_BASE_HREF, useValue: '/my/app'}],
        ,
        ProductService, { provide: APP_BASE_HREF, useValue: '/' }, { provide: HTTP_INTERCEPTORS, useClass: InterpreterService, multi: true }, MasterService],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        NgbModule,
        HttpClientModule,
        QRCodeModule,
        FormsModule,
        ReactiveFormsModule, 
        AppRoutingModule,
        InventoryManagementModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        FlexLayoutModule,
        MatDialogModule,
        SidebarModule,
        MatMenuModule,
        MatExpansionModule,
        MatCardModule,
        DlDateTimeDateModule,
        DlDateTimePickerModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatInputModule, MatProgressSpinnerModule,
        MatSortModule, MatCheckboxModule, CalendarModule, TableModule,
        MatAutocompleteModule,
        ToastModule,
        CalendarModule,
        SliderModule,
        DialogModule,
        MultiSelectModule,
        ContextMenuModule,
        DropdownModule,
        ButtonModule,
        ToastModule,
        InputTextModule,
        ProgressBarModule,
        HttpClientModule,
        FileUploadModule,
        ToolbarModule,
        RatingModule,
        RadioButtonModule,
        InputNumberModule,
        ConfirmDialogModule,
        InputTextareaModule,
        NgxMatSelectSearchModule,
        CardModule,
        CountdownModule, ProgressSpinnerModule, ChartModule, MatGridListModule, BrowserAnimationsModule, PaginatorModule, VirtualScrollerModule, CustomModule 
    ]
}) 
export class AppModule { }
