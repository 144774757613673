import { Component, OnInit, Inject,ViewChild,ElementRef } from '@angular/core';
import { Product } from '../../product';
import { ProductService } from '../../productservice';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Customer, Representative } from "../../customer"; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'; 
import { BilldeskService } from '../../billdesk.service';
import { printDiv } from 'src/app/print/print_container';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 

import { environment } from '../../../environments/environment';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-salereportdatewise',
  templateUrl: './salereportdatewise.component.html',
  styleUrls: ['./salereportdatewise.component.css']
})
export class SalereportdatewiseComponent implements OnInit {
  products: Product[];
  Totalamount:any;
  Totalinvoice:any;
  empList:any[];
  UserID:any;
  UserTypeID:any;
  DiscountAmount:any;
  TDSInAmount:any;
  TotInvoice:any;
  invoiceCount:any[];
  product: Product;
  id:any;
  i:any=0;
  sumNumber:any;
  sumnoofinvoicearray:any; 
  sumigstamountarray:any;
  sumdiscountdetailarray:any;
  sumtotalnetpayarray:any;
  selectedProducts: Product[];
  sumnoofinvoice:any;  
  sumdiscountdetail:any;
  sumtotalnetpay:any;
  isHidden=false;
  ServiceUrl=environment.baseUrl;
  TotalTdsinamount:any;
  TotalDiscountAmount:any;
  PoBatchNo:any;
  // ServiceUrl=environment.baseUrl;
  CreatedDate:any;
  productDialog: boolean;
 
  dialogdata: string;
 
  compleatearray:any; 

  submitted: boolean;

  statuses: any[];
 
  customers: Customer[];
  insertdata:any;
  updatedata:any;
  representatives: Representative[];

  loading: boolean = true;
 
  yearsdetail:any;
  months:any;
  newArr:any;
  paymentform: FormGroup;
  MonthShow:any;
  YearShow:any;
  TotBIllAmount:any
TotTdsAmount:any
TotDiscount:any
TotNetPayble:any
  showmonth=false;
  array:any;
  groupArr:any;
  cols: any[];
  exportColumns: any[];
  constructor(private http:HttpClient,private fb: FormBuilder,private productService: ProductService, private messageService: MessageService, private confirmationService: ConfirmationService,public dialog: MatDialog,public billdeskservice:BilldeskService) { 
 
    this.UserTypeID=localStorage.getItem('UserTypeID'); 
 
   this.UserID=localStorage.getItem('UserID'); 
  
  }
 
  ngOnInit(): void {


    this.cols = [  
      { field: "StockID", header: "Stock ID" },
      { field: "ProductName", header: "Product Name" },
      { field: "ItemCategoryName", header: "Category" },
      { field: "BrandName", header: "Brand" },
      { field: "SalePrice", header: "Sale Price" },
      { field: "SaleOnline", header: "Sale Online" },
      { field: "SaleOffline", header: "Sale Offline" },
      { field: "TotalSale", header: "Total Sale" },
      { field: "TotalAmount", header: "Total Amount" }
    ];

    this.exportColumns = this.cols.map(col => ({
      title: col.header,
      dataKey: col.field
    }));
      this.paymentform = this.fb.group({ 
      InvoiceYear: [null, Validators.required],
      InvoiceMonth: [null, Validators.required]
      }); 

    this.yearsdetail=["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"];
    this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    } 

  
  print_page(){
    this.isHidden=true; 
    const title =  "Sheet-B-II-"+this.YearShow+"_TO_"+this.MonthShow;
    //  const title="myfiledownload";
    setTimeout(() =>printDiv('content',title), 3000); 
    } 
    getSaleReport(){ 
      delete this.products; 
      this.TotInvoice=0;  
      this.TotBIllAmount=0;  
      this.TotTdsAmount=0;  
      this.TotDiscount=0; 
      this.TotNetPayble=0; 
      var todate =this.paymentform.get('InvoiceMonth').value; 
      var fromdate =this.paymentform.get('InvoiceYear').value; 
      this.showmonth=true;
      this.YearShow=fromdate;
      this.MonthShow=todate;
    
      const url = '/sale/salereportdatewise?FromDate='+fromdate+'&ToDate='+todate;
      this.billdeskservice.getList(url).then((res: any) => {
      console.log('rest', res);  
      if (res.salereportdatewiseResult.Message == 'Sucess') {
        this.products = res.salereportdatewiseResult.dataobject;  
        let sumTotalAmount: number = 0;
      this.products.forEach((a:any) => sumTotalAmount += parseInt(a.TotalAmount));
      console.log('test',sumTotalAmount);
      this.sumtotalnetpay=sumTotalAmount;
      }  
    }); 
    } 
 
    exportExcel() {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.products);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "products");
      });
   }

   saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}

}
