<div class="card" style=" overflow-x: scroll;">


    <p-table #dt [value]="products" [rows]="50" [paginator]="true" [globalFilterFields]="['OrgID','DivName',
    'DivGSTN',
    'StateName',
    'DistrictName',
    'DivDeskMobileNo',
    'DivDeskEmailID',
    'DivAuthPersonName',
    'DivAuthPersonMobileNo']" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]">


        <ng-template pTemplate="caption">

            <!-- <span style="display: inline;"></span> -->
            <div class="p-d-flex p-ai-center p-jc-between">
                <h1 style="text-align:center;margin-top: 10px;margin-bottom: 20px;font-weight: bold;color:#2459a6;">
                    DIVISION LIST</h1>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />
                    &nbsp; &nbsp; <a [routerLink]="['/Division-Registration']" class="btn btn-success">Add <i
                            class="fa fa-plus" aria-hidden="true"></i></a>

                </span>
            </div>

            <!-- <p-multiSelect   style="width: 14% !important;" [options]="cities" [(ngModel)]="selectedCities" optionLabel="name"></p-multiSelect> -->

        </ng-template>


        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width: 50px">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th> -->


                <!-- heading name get from here  -->
                <th pSortableColumn="rating"  class="actionclass"> Action</th>

                <th pSortableColumn="ActiveStatus" style="width: 150px">Status <p-sortIcon
                        field="ActiveStatus"></p-sortIcon></th>
                <th pSortableColumn="OrgID" style="width: 150px"> Organization Name <p-sortIcon
                        field="OrgID"></p-sortIcon></th>

                <th pSortableColumn="DivName" style="width: 150px"> Division Name <p-sortIcon
                        field="DivName"></p-sortIcon></th>

                <th pSortableColumn="DivGSTN" style="width: 150px"> GSTN <p-sortIcon field="DivGSTN"></p-sortIcon></th>
                <th pSortableColumn="StateName" style="width: 150px">State <p-sortIcon field="StateName"></p-sortIcon>
                </th>
                <th pSortableColumn="DistrictName" style="width: 150px"> City <p-sortIcon field="DistrictName"></p-sortIcon></th>

                <th pSortableColumn="DivDeskMobileNo" style="width: 150px"> Contact No. <p-sortIcon
                        field="DivDeskMobileNo"></p-sortIcon></th>
                <th pSortableColumn="DivDeskEmailID" style="width: 150px"> Email Id<p-sortIcon
                        field="DivDeskEmailID"></p-sortIcon></th>
                <th pSortableColumn="DivAuthPersonName" style="width: 150px"> Contact Person <p-sortIcon
                        field="DivAuthPersonName"></p-sortIcon></th>
                <th pSortableColumn="DivAuthPersonMobileNo" style="width: 150px"> Contact Person No <p-sortIcon
                        field="DivAuthPersonMobileNo"></p-sortIcon></th>


            </tr>


            <tr>
                <th>

                </th>
                <!-- <th  style=" 
                position: sticky;
                left: 0;
                z-index: 2;background:#ffffff;"> 

                </th> -->
                <th>



                    <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')"
                        styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span
                                [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                        </ng-template>
                    </p-dropdown>



                </th>

                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'OrgName' ,'contains')"
                        placeholder="Search OrgName" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DivName' ,'contains')"
                        placeholder="Search Name" />
                </th>


                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DivGSTN' ,'contains')"
                        class="fullwidth" placeholder="Search GSTN" />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'StateName' ,'contains')"
                        class="fullwidth" placeholder="Search State" />
                </th>


                <th>


                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DistrictName' ,'contains')"
                        class="fullwidth" placeholder="Search City" />







                </th>


                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DivDeskMobileNo' ,'contains')"
                        class="fullwidth" placeholder="Search Contact No." />
                </th>
                <th>

                    <input pInputText type="text" (input)="dt.filter($event.target.value,'DivDeskEmailID' ,'contains')"
                        placeholder="Search Email Id" />
                </th>
                <th>

                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'DivAuthPersonName' ,'contains')"
                        placeholder="Contact Person" />
                </th>
                <th>

                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value,'DivAuthPersonMobileNo' ,'contains')" class="fullwidth"
                        placeholder="Contact Person No." />
                </th>




            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="responsivecss">
                <td style=" 
                position: sticky;
                left: 0;
                z-index: 2;background:#ffffff;">
                    <!-- edited by me -->

                    <!-- <a     [routerLink]="['/Edit-Division',product.DivID]"><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"   style="margin-right: 4px;" ></button>
                   </a>    
                 -->
                    <!-- <button pButton pRipple icon="pi pi-eye"   class="p-button-rounded p-button-info" (click)="viewProduct(product.DivID)"></button>  -->

                    <span class="p-column-title">Action:</span>
                    <span style="display: flex;align-items: center;justify-content: center;">
                        <a [routerLink]="['/Edit-Division',product.DivID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                style='color: #2459a6;' pTooltip="Edit Division Detail"></i></a>
                        <span pTooltip="View Division Detail"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                           border: 2px solid;
                           border-radius: 5px;' (click)="viewProduct(product.DivID)"></i> </span>
                    </span>

                </td>
                <td>
                    <!-- edited by me -->
                    <span class="p-column-title">Status:</span>
                    <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
                    <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>


                    <!-- {{product.ActiveStatus}} -->

                </td>

                <td>
                    <span class="p-column-title">Organization:</span>
                    {{product.OrgName}}
                </td>
                <td>
                    <span class="p-column-title">Division:</span>
                    {{product.DivName}}
                </td>

                <td>
                    <span class="p-column-title">GSTN:</span>
                    {{product.DivGSTN}}
                </td>


                <td>
                    <span class="p-column-title">State:</span>
                    {{product.StateName}}
                </td>
                <td>
                    <span class="p-column-title">City:</span>
                    {{product.DistrictName}}
                </td>



                <td>
                    <span class="p-column-title">Contact:</span>
                    {{product.DivDeskMobileNo}}
                </td>
                <td>
                    <span class="p-column-title">Email:</span>
                    {{product.DivDeskEmailID}}
                </td>
                <td>
                    <span class="p-column-title">Contact Person:</span>
                    {{product.DivAuthPersonName}}
                </td>
                <td>
                    <span class="p-column-title">Contact Person:</span>
                    {{product.DivAuthPersonMobileNo}}
                </td>



            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{products ? products.length : 0 }} products.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '800px'}" header="Division Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
<br>

        <div class="container">
            <table class="table table-striped">

                <tbody>
                    <tr>
                        <td>
                            <label for="Organization_Name" class="col-form-label">Division Id :</label>
                        </td>
                        <td>
                            <label for="OrgID" class="col-form-label"> {{productsbyid[0].DivID}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="Organization_Name" class="col-form-label">Division Name:</label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <label for="PANCard" class="col-form-label">PANCard:</label>

                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivPANNo}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="GSTN" class="col-form-label">GSTN:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivGSTN}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="Contact_Number" class="col-form-label">Contact Number:</label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivDeskMobileNo}}
                            </label></td>

                    </tr>
                    <tr>
                        <td>

                            <label for="Emailid" class="col-form-label">Email </label>
                        </td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivDeskEmailID}}
                            </label></td>

                    </tr>
                    <tr>
                        <td> <label for="Address" class="col-form-label">Address </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivBillingAddress}}
                            </label></td>

                    </tr>
                    <tr>
                        <td><label for="City" class="col-form-label">City </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DistrictName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td><label for="State" class="col-form-label">State </label></td>
                        <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].StateName}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="PinCode" class="col-form-label">PinCode</label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivPinCode}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="ContactPersonName" class="col-form-label">Contact Person Name </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivAuthPersonName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <label for="accountname" class="col-form-label">Account Name </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label">
                                {{productsbyid[0].DivAuthPersonMobileNo}} </label>
                        </td>

                    </tr>
                    <tr>
                        <td> <label for="BanckAccountNo" class="col-form-label">Banck Account No </label></td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivBankAcNo}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="bankname" class="col-form-label">Bank Name </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivBankName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>

                            <label for="BanckBranch" class="col-form-label">Banck Branch </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivBankBranchName}}
                            </label>
                        </td>

                    </tr>
                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Bank IFSC Code </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DivIFSCCode}}
                            </label>
                        </td>

                    </tr>

                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Created Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedDate}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Created By </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedBy}}
                            </label>
                        </td>

                    </tr>


                    <tr>
                        <td>


                            <label for="BankIFSCCode" class="col-form-label">Updated Date </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedDate}}
                            </label>
                        </td>

                    </tr>

                    <tr>
                        <td>

                            <label for="BankIFSCCode" class="col-form-label">Updated By </label>
                        </td>
                        <td>
                            <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedBy}}
                            </label>
                        </td>

                    </tr>

                </tbody>
            </table>
        </div>

        <!-- <div class="form-check-inline">
            <label class="form-check-label">

                Status
            </label>
        </div>
        <div class="form-check-inline">
            <label class="form-check-label"> 
                <input type="radio" class="form-check-input" checked="!checked" name="radio"
                    [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].DivID)"
                    [value]="1">active
            </label>
        </div>
        <div class="form-check-inline">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                    [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].DivID)"
                    [value]="0">Deactive
            </label>
        </div> -->


    </ng-template>

    <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
        <span style="float: left;padding-top: 2%;">

            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
    
                    Status
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <!-- {{productsbyid[0].ActiveStatus}} -->
                    <input type="radio" class="form-check-input" checked="!checked" name="radio"
                        [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                        [value]="1">active
                </label>
            </div>
            <div class="form-check-inline" style="color:#fff">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                        [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                        [value]="0">Deactive
                </label>
            </div>
    
        </span>
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" style="float: right;"></button>
            <!-- <button style="color: white;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                (click)="saveProduct()"></button>  this is unused -->
    

        <!-- <button style="color: white;" pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideDialog()"></button> -->
        <!-- edited by me -->

        <!--         
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button> -->
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Organization Deleted Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>


<p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>

                </div>
                <div class="modal-body text-center">
                    <h4>Success!</h4>
                    <p>Status Update Successfully.</p>
                </div>
            </div>
        </div>
    </ng-template>


</p-dialog>