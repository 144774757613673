import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { BilldeskService } from '../billdesk.service';
import { HttpClient} from "@angular/common/http"; 
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-yearwisesummary',
  templateUrl: './yearwisesummary.component.html',
  styleUrls: ['./yearwisesummary.component.css']
})
export class YearwisesummaryComponent implements OnInit {
  isApprover:any;
  title='BIlldesk';
   homeslider:any;
   dialogdata: any;
   testdata:any;
   localValue:any;
   LoginID:any;
   UserID:any;
   UserTypeID:any;

   visibleloader:any;
   InstitutionID:any;
   UserType:any;
   DepartmentID:any;
   UserFullName:any
  //  changeText:any
  OrganizationID:any;
  UserDeptName:any;
  UserDivName:any;
  UserOrgName:any;
  RoleTypeName:any;
   responsedata:any; 
   UserMobile:any;
YearData:any;

statusupdateDialog: boolean;
   UserEmail:any;
   dashboardcountData:any;
   ServicesData:any;
   InstituteData:any;
   VendorData:any;
registerd_inv:any;
Tot_Registration:any;
Tot_Reg_Amount:any;
approve_inv:any;
dashboardcountyearmonthwiseinvoicesummarylistResult:any;

dashboardcountyearorganizationwiseinvoicesummarylistResult:any;

dashboardcountyearvendornamewiseinvoicesummarylistResult:any;


dashboardcountyearservicewiseinvoicesummarylistResult:any;

dashboardcountyearmonthvendornamewiseinvoicesummarylistResult:any;
paid_inv:any;

pending_inv:any;
   Token:any;
   InstituteDataLoaded:any;
   VendorDataLoaded:any;
   ServicesDataLoaded:any;
 
 isLoaded:boolean=false;
 
 isvendor:boolean=true;
 isOtpInvalidError = true;

 isOtpEmptyError = true;
 ServiceUrl = environment.baseUrl;
 PinForm: FormGroup;

 yearsdetail:any;
  constructor(private Http: HttpClient,private BilldeskService:BilldeskService,private fb: FormBuilder,private http:HttpClient,public dialog: MatDialog) {}

  ngOnInit(): void {  
    this.yearsdetail=["2017-2018", "2018-2019", "2019-2020", "2020-2021", "2021-2022", "2022-2023", "2023-2024", "2024-2025"];
 

    this.PinForm = this.fb.group({
      first: [null, Validators.required],
      second: [null, Validators.required],
      third: [null, Validators.required],
      fourth: [null, Validators.required]
    });


   this.isApprover=false;





    this.isLoaded=false;
    this.InstituteDataLoaded=false;
    this.VendorDataLoaded=false;
    this.ServicesDataLoaded=false;
    this.BilldeskService.getMonthDetail().then(data =>(this.YearData = data));

 
    this.BilldeskService.getVendorList().then((data)=>{
      this.VendorData = data; 
      this.VendorDataLoaded=true;  
    });

    
 
 

    this.BilldeskService.getInstituteList().then((data)=>{
      this.InstituteData = data; 
      this.InstituteDataLoaded=true;
       
    });


    this.BilldeskService.getServicesList().then((data)=>{
      this.ServicesData = data; 
      this.ServicesDataLoaded=true;
 
    });





    // this.BilldeskService.getDashboardCountDetail().then((data)=>{
    //   this.dashboardcountData = data; 
    //   this.isLoaded=true;
    // });

    


    this.BilldeskService.dashboardcountregisteredinvoicelistResult().then((data)=>{
      this.registerd_inv = data[0]; 
      this.isLoaded=true;
    });


    



    this.BilldeskService.dashboardcountapprovedinvoicelistResult().then((data)=>{
      this.approve_inv = data[0]; 
      this.isLoaded=true;
    });



    this.BilldeskService.dashboardcountpaidinvoicelistResult().then((data)=>{
      this.paid_inv = data; 
      this.isLoaded=true;
    });


    this.BilldeskService.dashboardcountpendinginvoicelistResult().then((data)=>{
      this.pending_inv = data; 
      this.isLoaded=true;
    });



    


    




    

    this.localValue=  localStorage.getItem('Login_local_session');
 

    
    this.LoginID=  localStorage.getItem('LoginID');
 

    this.DepartmentID=  localStorage.getItem('DepartmentID');
   


    this.UserID=localStorage.getItem('UserID'); 
 
 
 
    this.Token=localStorage.getItem('Token'); 
    this.UserDeptName=localStorage.getItem('UserDeptName'); 
 
    this.UserDivName=localStorage.getItem('UserDivName'); 
   
    this.UserOrgName=localStorage.getItem('UserOrgName'); 
  

    this.InstitutionID=localStorage.getItem('InstitutionID'); 
   
    
    this.OrganizationID=localStorage.getItem('OrganizationID'); 
   
 
    
    this.UserTypeID=localStorage.getItem('UserTypeID'); 
 
    console.log("this.UserTypeID");
    console.log(this.UserTypeID);

    if(this.UserTypeID==2){
     this.isApprover=true;
    }
    this.RoleTypeName=localStorage.getItem('RoleTypeName'); 
  
 

    this.UserEmail=localStorage.getItem('UserEmail'); 
  
    
     
    this.UserFullName=localStorage.getItem('UserFullName'); 
 
 
        
    this.UserMobile=localStorage.getItem('UserMobile'); 
 
 
    if(this.UserTypeID==4){

      this.isvendor=false;
    }
 
 
 
 
  }




  getDetail(e){
 
    console.log(e);
     
 this.BilldeskService.summary_year(e).then((data)=>{
        this.dashboardcountyearmonthwiseinvoicesummarylistResult = data; 
        this.isLoaded=true;
        });
 this.BilldeskService.summary_year_organization(e).then((data)=>{
      this.dashboardcountyearorganizationwiseinvoicesummarylistResult = data; 
      console.log('year month wise',this.dashboardcountyearorganizationwiseinvoicesummarylistResult)
      this.isLoaded=true;
    });



this.BilldeskService.summary_year_vendor(e).then((data)=>{
  this.dashboardcountyearvendornamewiseinvoicesummarylistResult = data; 
  this.isLoaded=true;
});



this.BilldeskService.summary_year_month_vendor(e).then((data)=>{
  this.dashboardcountyearmonthvendornamewiseinvoicesummarylistResult = data; 
  this.isLoaded=true;
});




this.BilldeskService.summary_year_service(e).then((data)=>{
  this.dashboardcountyearservicewiseinvoicesummarylistResult = data; 
  this.isLoaded=true;
}); 


    }

  showall(){
 
 
      var e=0 
   this.BilldeskService.summary_year(e).then((data)=>{
          this.dashboardcountyearmonthwiseinvoicesummarylistResult = data; 
          this.isLoaded=true;
          });
   this.BilldeskService.summary_year_organization(e).then((data)=>{
        this.dashboardcountyearorganizationwiseinvoicesummarylistResult = data; 
        console.log('year month wise',this.dashboardcountyearorganizationwiseinvoicesummarylistResult)
        this.isLoaded=true;
      });
  
  
  
  this.BilldeskService.summary_year_vendor(e).then((data)=>{
    this.dashboardcountyearvendornamewiseinvoicesummarylistResult = data; 
    this.isLoaded=true;
  });
  
  
  
  this.BilldeskService.summary_year_month_vendor(e).then((data)=>{
    this.dashboardcountyearmonthvendornamewiseinvoicesummarylistResult = data; 
    this.isLoaded=true;
  });
  
  
  
  
  this.BilldeskService.summary_year_service(e).then((data)=>{
    this.dashboardcountyearservicewiseinvoicesummarylistResult = data; 
    this.isLoaded=true;
  }); 
  
  
      }

  move(a: any, b: any, c: any, d: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');
    if (length == maxlength) {
      if (d != "") {
        d.focus();
      }
    }
    if (a.key == 'Backspace') {
      b.focus();
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
 


  PinUpdate() {



    var otp = this.PinForm.get('first').value;

    var otp1 = this.PinForm.get('second').value;
    var otp2 = this.PinForm.get('third').value;
    var otp3 = this.PinForm.get('fourth').value;




    if (otp == null) {
      this.isOtpEmptyError = false;
      return;
    }


    if (otp1 == null) {
      this.isOtpEmptyError = false;
      return;
    }

    if (otp2 == null) {
      this.isOtpEmptyError = false;
      return;
    }


    if (otp3 == null) {
      this.isOtpEmptyError = false;
      return;
    }
    let totalotp = [otp, otp1, otp2, otp3].join('');

    console.log(totalotp);
 


    const datarequest = {
      "CheckSum": "",
      "lead_id": "",
      "ActionFor": "Insert",
      "dataobject": [
        {
          "UserPin": totalotp
      }
      ]
    };

    if (this.PinForm.valid) {
      this.visibleloader = true;
      let url = this.ServiceUrl + "/mailerpin/mailerpinregistration";
      this.http.post(url, datarequest).toPromise().then((data: any) => {
        if (data.mailerpinregistrationResult.Message == 'Sucess') {

          this.visibleloader = false;
          this.StatusUpdatePopup();
          localStorage.setItem('statusupdate', 'Success');
          

        } else {
          console.log('Please Try Again Later');
        }
      });
    }
    else {
      this.validateAllFormFields(this.PinForm);
    }



  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  StatusUpdatePopup() {
    localStorage.removeItem('statusupdate');
    setTimeout(() => {
      this.openDialogStatusUpdate();
      // this.hideDialog();
    }, 500);
  }
  //EOF onClicklogin


  

  openDialogStatusUpdate(): void { 
    this.statusupdateDialog = false;
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { Service: "PIN", Detail: "Generation  Successfully", Backgroundcolor: "#0288d1" }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dialogdata = result;
    });
  }
 
  hideDialog() {
      this.statusupdateDialog = false;
  }


 
  statusUpdateDialog() {
    console.log('callfunction');
    this.statusupdateDialog = true;
  }
  isBilldesk
  // showError() {
  //                this.messageService.add({severity:'error', summary: 'Error', detail: 'Message Content'}); 
  //           }

}


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../alertbox/success_alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
    window.location.reload();
  }

}


export interface DialogData {
  Service: string;
  Detail: string;
  Backgroundcolor: string;
}