 
<div class="card" style=" overflow-x: scroll;">
    <p-table #dt [value]="products" [rows]="10" [paginator]="true"
          [globalFilterFields]="['OrgName','GSTN','StateName','DistrictName','DeskMobileNo','DeskEmailID','AuthPersonName','AuthPersonMobileNo']"
          [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10,25,50,100,200,250,300,350,400]"  styleClass="p-datatable-striped" >
          <!-- <p-table #dt [value]="products" [(selection)]="selectedProducts" dataKey="id" styleClass="p-datatable-customers" [rowHover]="true"
   
              [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
           [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [filterDelay]="0" [globalFilterFields]="['name','country.name','representative.name','status']"> -->
  
          <!-- <p-table [value]="products" [tableStyle]="{'min-width': '50rem'}"> -->
  
          <ng-template pTemplate="caption">
              <div class="p-d-flex p-ai-center p-jc-between">
                  <h1 class="headingclass">
                      SALES LIST</h1>
                  <span class="p-input-icon-left">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search..." />
                      &nbsp; &nbsp; <a [routerLink]="['/sales_entry']" class="btn btn-success">Add <i
                              class="fa fa-plus" aria-hidden="true"></i></a>
                  </span>
              </div>
          </ng-template>
  
  
          <ng-template pTemplate="header">
  
  
  
              <tr class="padding-head">
                  <!-- <th style="width: 50px">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th> -->
                  <!-- <th pSortableColumn="rating"  class="actionclass"> Action  </th> -->
  
                  <th pSortableColumn="ActiveStatus">Status <p-sortIcon field="ActiveStatus"></p-sortIcon></th>
  
                  <th pSortableColumn="SaleID"> Sales ID <p-sortIcon field="SaleID"></p-sortIcon></th>  
                  
                  <th pSortableColumn="SaleInvoiceNumber">Sales Inv. No. <p-sortIcon field="SaleInvoiceNumber"></p-sortIcon></th>
                  <th pSortableColumn="SaleID">Invoice. <p-sortIcon field="SaleID"></p-sortIcon></th>
                  
                  <th pSortableColumn="SaleInvoiceDate"> Sales Invoice Date <p-sortIcon field="SaleInvoiceDate"></p-sortIcon></th>
  
                  <th pSortableColumn="VendorName"> Vendor Name. <p-sortIcon field="VendorName"></p-sortIcon></th>
                  <th pSortableColumn="GrandTotal">Grand Total<p-sortIcon field="GrandTotal"></p-sortIcon></th>
                  <th pSortableColumn="CreatedDate">CreatedDate <p-sortIcon field="CreatedDate"></p-sortIcon>
                  </th>
           
  
              </tr>
              <tr class="padding-head">
                  <!-- <td>
  
                  </td> -->
                  <!-- <th style="position: sticky;
                  left: 0;
                  z-index: 2;background:#ffffff;"> -->
  
                  <!-- <th></th> -->
                  <th>
  
  
  
                      <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'ActiveStatus', 'contains')"
                          styleClass="p-column-filter" placeholder="PLease Select" [showClear]="true">
                          <ng-template let-option pTemplate="item">
                              <span
                                  [class]="'customer-badge status-' + option.value">&#123;&#123;option.IfValue&#125;&#125;</span>
                          </ng-template>
                      </p-dropdown>
  
  
                  </th>
  
  
                  <th style="width: 150px !important;" >
  
                      <input pInputText type="text" (input)="dt.filter($event.target.value,'SaleID' ,'contains')"
                          placeholder="SalesID"  style="width: 50% !important;" />
                  </th>
  
  
           
                  <th>
  
                      <input pInputText type="text" (input)="dt.filter($event.target.value,'SaleInvoiceNumber' ,'contains')"
                          placeholder="Search" class="fullwidth" />
                  </th>
                  <th>
  
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'SaleID' ,'contains')"
                        placeholder="Search " class="fullwidth" />
                </th>

  
                  <th>
  
  
                      <input pInputText type="text" (input)="dt.filter($event.target.value,'SaleInvoiceDate' ,'contains')"
                          placeholder="Search " class="fullwidth" />
  
  
  
  
  
  
  
                  </th>
  
  
                  <th>
  
                      <input pInputText type="text" (input)="dt.filter($event.target.value,'VendorName' ,'contains')"
                          placeholder="Search" class="fullwidth" />
                  </th>
                  <th>
  
                      <input pInputText type="text" (input)="dt.filter($event.target.value,'GrandTotal' ,'contains')"
                          placeholder="Search" />
                  </th>
                  <th>
  
                      <input pInputText type="text" (input)="dt.filter($event.target.value,'CreatedDate' ,'contains')"
                          placeholder="Search" />
                  </th>
                 
  
  
  
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
              <tr class="responsivecss">
                  <!-- <td style="position: sticky;
                  left: 0;
                  z-index: 2;background:#ffffff;">
                      <span class="p-column-title">Action:</span>
                      <span style="display: flex;align-items: center;justify-content: center;"> -->
                          <!-- <a [routerLink]="['/Edit-Organization',product.SalesID]" class="mr-1 fs-2"><i class='fa fa-edit'
                                  style='color: #2459a6;' pTooltip="Edit Organization Detail"></i></a> -->

                                  
                          <!-- <span pTooltip="View Sales Invoice"> <i class='fa fa-eye' style='color: #f09639; zoom: 1.1;
                          border: 2px solid;
                          border-radius: 5px;' (click)="viewProduct(product.SalesID)"></i> </span> -->
                      <!-- </span>
                  </td> -->
                  <td>
  
  
                      <span class="p-column-title">Status:</span>
                      <span *ngIf="product.ActiveStatus=='1'" class="statusactive">Active</span>
                      <span *ngIf="product.ActiveStatus!='1'" class="statusinactive">Deactive</span>
  
  
  
                      <!-- <i class="pi-file-edit" style="font-size: 2rem"></i> -->
                  </td>
                  <td>
  
                      <span class="p-column-title">Sales ID:</span>
                      {{product.SaleID}}
                  </td>
  
           
   
                  <td>
                    <span class="p-column-title">Sales Invoice No:</span>
                      {{product.SaleInvoiceNumber}}
                  </td>
  
  


                  
                  <td>
                    <a [routerLink]="['/SaleInvoice',product.SaleID]" class="mr-1 fs-2"><i class='fa fa-file-pdf-o'
                        style='color: #ec5729;' pTooltip="View Sales Invoice"></i></a>
                  </td>
  
  
  
                  <td>
  
                      <span class="p-column-title">Sales Invoice Date:</span>
                      {{product.SaleInvoiceDate|date:'d/M/YYYY'}}
                  </td>
                  <td>
  
                      <span class="p-column-title">Vendor Name:</span>
                      {{product.ToVendorName}}
                  </td>
                  <td>
  
                      <span class="p-column-title">Grand Total:</span>
                      {{product.GrandTotal| number : '1.2-2' }}
                  </td>
                  <td>
  
                      <span class="p-column-title">Created Date:</span>
                      {{product.CreatedDate }}
                  </td>
  
  
  
              </tr>
          </ng-template>
          <ng-template pTemplate="summary">
              <div class="p-d-flex p-ai-center p-jc-between">
                  In total there are {{products ? products.length : 0 }} products.
              </div>
          </ng-template>
      </p-table>
  </div>
  
  <p-dialog [(visible)]="productDialog" [style]="{width: '800px'}" header="Organization Details" [modal]="true"
      styleClass="p-fluid">
      <ng-template pTemplate="content">
          <br>
          <div class="container">
              <table class="table table-striped">
  
                  <tbody>
                      <tr>
                          <td>
                              <label for="Organization_Name" class="col-form-label">Organization Id :</label>
                          </td>
                          <td>
                              <label for="OrgID" class="col-form-label"> {{productsbyid[0].OrgID}} </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td>
  
                              <label for="Organization_Name" class="col-form-label">Organization Name:</label>
                          </td>
                          <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].OrgName}} </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td> <label for="PANCard" class="col-form-label">PANCard:</label></td>
                          <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].PANNo}} </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td>
  
                              <label for="GSTN" class="col-form-label">GSTN:</label>
                          </td>
                          <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].GSTN}} </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td> <label for="Contact_Number" class="col-form-label">Contact Number:</label></td>
                          <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DeskMobileNo}}
                              </label></td>
  
                      </tr>
                      <tr>
                          <td><label for="Emailid" class="col-form-label">Email </label></td>
                          <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].DeskEmailID}}
                              </label></td>
  
                      </tr>
                      <tr>
                          <td> <label for="Address" class="col-form-label">Address </label></td>
                          <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BillingAddress}}
                              </label></td>
  
                      </tr>
                      <tr>
                          <td><label for="City" class="col-form-label">City </label></td>
                          <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].City}} </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td><label for="State" class="col-form-label">State </label></td>
                          <td><label for="Organization_Name" class="col-form-label"> {{productsbyid[0].State}} </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td>
  
                              <label for="PinCode" class="col-form-label">PinCode</label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].PinCode}} </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td>
  
                              <label for="ContactPersonName" class="col-form-label">Contact Person Name </label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].AuthPersonName}}
                              </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td>
                              <label for="accountname" class="col-form-label">Account Name </label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankAcName}}
                              </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td> <label for="BanckAccountNo" class="col-form-label">Banck Account No </label></td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankAcNo}} </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td>
  
                              <label for="bankname" class="col-form-label">Bank Name </label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankName}} </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td>
  
                              <label for="BanckBranch" class="col-form-label">Banck Branch </label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].BankBranchName}}
                              </label>
                          </td>
  
                      </tr>
                      <tr>
                          <td>
  
  
                              <label for="BankIFSCCode" class="col-form-label">Bank IFSC Code </label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].IFSCCode}} </label>
                          </td>
  
                      </tr>
  
                      <tr>
                          <td>
  
  
                              <label for="BankIFSCCode" class="col-form-label">Created Date </label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedDate}}
                              </label>
                          </td>
  
                      </tr>
  
  
                      <tr>
                          <td>
  
                              <label for="BankIFSCCode" class="col-form-label">Created By </label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].CreatedBy}}
                              </label>
                          </td>
  
                      </tr>
  
  
                      <tr>
                          <td>
  
  
                              <label for="BankIFSCCode" class="col-form-label">Updated Date </label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedDate}}
                              </label>
                          </td>
  
                      </tr>
  
                      <tr>
                          <td>
  
                              <label for="BankIFSCCode" class="col-form-label">Updated By </label>
                          </td>
                          <td>
                              <label for="Organization_Name" class="col-form-label"> {{productsbyid[0].UpdatedBy}}
                              </label>
                          </td>
  
                      </tr>
  
                  </tbody>
              </table>
          </div>
  
          <!-- <div class="form-check-inline">
              <label class="form-check-label">
  
                  Status
              </label>
          </div>
          <div class="form-check-inline">
              <label class="form-check-label"> 
                  <input type="radio" class="form-check-input" checked="!checked" name="radio"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="1">active
              </label>
          </div>
          <div class="form-check-inline">
              <label class="form-check-label">
                  <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="0">Deactive
              </label>
          </div> -->
  
  
  
      </ng-template>
  
      <ng-template pTemplate="footer" style="color:#fff;background: #ec5729;height:20px;">
      <span style="float: left;padding-top: 2%;">
  
          <div class="form-check-inline" style="color:#fff">
              <label class="form-check-label">
  
                  Status
              </label>
          </div>
          <div class="form-check-inline" style="color:#fff">
              <label class="form-check-label">
                  <!-- {{productsbyid[0].ActiveStatus}} -->
                  <input type="radio" class="form-check-input" checked="!checked" name="radio"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="1">active
              </label>
          </div>
          <div class="form-check-inline" style="color:#fff">
              <label class="form-check-label">
                  <input type="radio" class="form-check-input" checked="!checked" name="radio1"
                      [(ngModel)]="productsbyid[0].ActiveStatus" (change)="handleChange($event,productsbyid[0].OrgID)"
                      [value]="0">Deactive
              </label>
          </div>
  
          
      </span>
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" style="float: right;"></button>
          <!-- <button style="color: white;" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
              (click)="saveProduct()"></button>  this is unused -->
      </ng-template>
  </p-dialog>
  
  <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
  
  <p-dialog [(visible)]="successDialog" header="Message" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="content">
          <div class="modal-dialog modal-confirm">
              <div class="modal-content">
                  <div class="modal-header justify-content-center">
                      <div class="icon-box">
                          <i class="material-icons">&#xE876;</i>
                      </div>
  
                  </div>
                  <div class="modal-body text-center">
                      <h4>Success!</h4>
                      <p>Organization Deleted Successfully.</p>
                  </div>
              </div>
          </div>
      </ng-template>
  
  
  </p-dialog>
  
  
  <p-dialog [(visible)]="updateDialog" header="Message" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="content">
          <div class="modal-dialog modal-confirm">
              <div class="modal-content">
                  <div class="modal-header justify-content-center">
                      <div class="icon-box">
                          <i class="material-icons">&#xE876;</i>
                      </div>
  
                  </div>
                  <div class="modal-body text-center">
                      <h4>Success!</h4>
                      <p>Status Update Successfully.</p>
                  </div>
              </div>
          </div>
      </ng-template>
  
  
  </p-dialog>